import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fines-rep',
  templateUrl: './fines-rep.component.html',
  styleUrls: ['./fines-rep.component.css']
})
export class FinesRepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    
  }

}
