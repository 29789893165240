<ds-loading *ngIf="showLdr"></ds-loading>




<div class="page-header">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-12 col-md-12 mg-a">
                <h1 *ngIf="clntDtls && clntDtls.length > 0">
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>

        </div>

    </div>
</div>



<div class="pg-drvrdshbrd">

<div class="fltrbg">
    <div class="row">

      <div class="col-lg-3 col-md-3 crdCl">
        <p class="slctlbl">ULB</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
          nzPlaceHolder="Select ULB" [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
          <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
        </nz-select>
      </div>
      <div class="col-lg-3 col-md-3 crdCl" *ngIf="ShowClap">
        <p class="slctlbl">Vehicle Vendor</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
          nzPlaceHolder="Select Vendor" [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0"  *ngIf="!showVndr"></nz-option>
          <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
        </nz-select>
      </div>

      <div class="col-lg-3 col-md-3 crdCl">
        <p class="slctlbl">Select Driver</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedrvr" (ngModelChange)="ondrvrSelect($event)"
          nzPlaceHolder="Select Driver" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let d of drvrslst" [nzValue]="d.crw_id" [nzLabel]="d.crw_nm"></nz-option>
        </nz-select>
      </div>


    </div>


    <div class="pg">
        <div class="row pg" style="width: 100%;">
            <div class="col-lg-12 col-md-12">
                <div class="row wrapper">
                    <div id="slide">
                        <span>
                            <img src="../../../../../assets/images/driver.png">
                        </span>
                        <span class="crwDtl_crd">
                            <h1>Mr.{{crwDtls.crw_nm}}</h1>
                            <h2><span><i nz-icon [nzType]="'phone'" [nzTheme]="'outline'"></i>&nbsp;<b>Phone No:</b>&nbsp;{{(!crwDtls.crw_ph|| crwDtls.crw_ph == 0 || crwDtls.crw_ph == null) ? 'Mobile Number Not Provided':crwDtls.crw_ph}}</span></h2> 
                            <h2><span><i nz-icon [nzType]="'idcard'" [nzTheme]="'outline'"></i>&nbsp;<b>Licence No:</b>&nbsp;{{(!crwDtls.crw_lcnse_nu ||crwDtls.crw_lcnse_nu == "undefined" || crwDtls.crw_lcnse_nu == null) ? 'Licence Not Provided':crwDtls.crw_lcnse_nu}}</span></h2> 
                                <h2><span><i nz-icon [nzType]="'car'" [nzTheme]="'outline'"></i>&nbsp;<b>Vehicle No:</b>&nbsp;{{(!crwDtls.asrt_nm|| crwDtls.asrt_nm == 0 || crwDtls.asrt_nm == null) ? 'Vehicle Not Provided':crwDtls.asrt_nm}}<b *ngIf="crwDtls.asrt_sts_nm" [ngStyle]="{'background-color': crwDtls.bg_clr_cd, 'color': crwDtls.clr_cd}" style="margin-left: 3px;">&nbsp;{{crwDtls.asrt_sts_nm}}</b></span></h2> 
                                <h2 *ngIf="clntDtls[0].clnt_id==7"><span><i nz-icon [nzType]="'environment'" [nzTheme]="'outline'"></i>&nbsp;<b>Sachivalayam:</b>&nbsp;{{(!crwDtls.svm_nm|| crwDtls.svm_nm == 0 || crwDtls.svm_nm == null) ? 'Sachivalayam Not Provided':crwDtls.svm_nm}}</span></h2> 
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row bg-w chart-card">
            <div class="col-lg-12 col-md-12 crd">
                <h1>Last 7days 24/7 Distance covered by driver</h1>
                <div id="hepachartdiv"></div>
            </div>
        </div>
        <div class="row bg-w chart-card">
            <div class="col-lg-6 col-md-6 w-50 crd" style="margin-right: 24px;">
                <h1>Last 7Days Distance Travelled</h1>
                <div>
                    <div id="lastSevenDaysDistanceChart"></div>
                </div>
                <div *ngIf="drvrDtls && drvrDtls.length <= 0 " style="text-align: center;">
                    Distance not found  for the vehicle assigned to the driver
                </div>
            </div>
            <div class="col-lg-6 col-md-6 w-50 crd">
                <h1>Last 7Days Trips Travelled</h1>
                <div>
                    <div id="lastSevenDaysChart"></div>
                </div>
                <div *ngIf="drvrDtls && drvrDtls.length <= 0 " style="text-align: center;">
                    No trips defined for the vehicle assigned to this driver
                </div>
            </div>
        </div>
        <div class="row bg-w chart-card">
            <div class="col-lg-6 col-md-6 w-50 crd bxshw_n" style="margin-right: 24px;">
                <h1>Operational Details In Last 7Days</h1>
                <div *ngIf="drvrOptnlDtls && drvrOptnlDtls.length>0">
                    <table class="cstm_tbl">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Vehicle No.</th>
                                <th>Vehicle Category</th>
                                <th>Operational Hours</th>
                                <th>Travelled(km)</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let o of drvrOptnlDtls">
                                <td>{{o.dt}}</td>
                                <td>{{o.asrt_nm}}</td>
                                <td>{{o.asrt_ctgry_nm}}</td>
                                <td>{{o.oprtn_hrs_ct}}</td>
                                <td>{{o.dst}}</td>
                                <td *ngIf="o.operated == 1" style="color: #009688;">Operated</td>
                                <td *ngIf="o.operated == 0" style="color: #9c27b0;">Not-Operated</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row NtfctnCrd"  *ngIf="oprtnlhrsMsg">
                    <h5 style="text-align: center;">{{oprtnlhrsMsg}}</h5>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 w-50 crd bxshw_n">
                <h1 style="padding-left: 20px;">Last 7Days Speed Violation</h1>
                <div class="row" *ngIf="drvrSpdDtls && drvrSpdDtls.length>0">
                    <table class="cstm_tbl">
                        <thead>
                            <tr>
                                <th>Recorded Date</th>
                                <th>Vehicle No.</th>
                                <th>Vehicle Category</th>
                                <th>Speed(km)</th>
                                <th>Track Vehicle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of drvrSpdDtls">
                                <td>{{t.dt}}</td>
                                <td>{{t.asrt_nm}}</td>
                                <td>{{t.asrt_ctgry_nm}}</td>
                                <td>{{t.speed}}</td>
                                <td><a href="https://www.google.com/maps/search/?api=1&query={{t.r_lat}},{{t.r_lng}}"  target="_blank"><i nz-icon nzType="node-index" nzTheme="outline"></i>Track</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row NtfctnCrd"  *ngIf="fuelDtaMsg">
                    <h5 style="text-align: center;">{{fuelDtaMsg}}</h5>
                </div>
            </div>
        </div>
    </div>
</div>