import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppsService } from '../../../shared/services/apps.service';
import { ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import * as _moment from 'moment';
const moment = _moment;
import * as _ from 'lodash';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
// import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { options } from '@amcharts/amcharts4/core';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import * as saveAs from 'file-saver';
import { Workbook } from 'exceljs';

@Component({
  selector: 'app-custom-report',
  templateUrl: './custom-report.component.html',
  styleUrls: ['./custom-report.component.scss'],
  providers: [DatePipe]
})
export class CustomReportComponent implements OnInit {


  @Input() shwRptHeader;
  @Input() shwRptDesc;

  displayfilters = false;
  filterslabel = "Show Filters";
  searchData = '';
  selectOff: any;
  offList: any; months: any; year: any;
  fltrsdata: any = [];
  selectfeeCtgy: any;
  selectfnsCtgy: any; selectbsnsCtgy: any; selecttlCtgy: any; selectstats: any; selectSrce: any;
  mblNum: number;
  dlyDys: number;
  ctznnm: any;
  mtchdMblNum: any;
  mtchdDrNum: any;
  drNum: string;
  selectSbCtgy: any;
  feectgyList: any;
  fnsctgyList: any; fnsdtlslist = []; usrdtlslist = [];
  bsnsctgyList: any; tlctgyList: any;
  slctdyear: any;
  slctdmnth: any;
  phnum: any;
  fltrDate: any;
  indicatorUrl;
  pagination;
  loader: boolean;
  excl_ind: any;
  hdrsView: boolean; drpDwndata: boolean = false;
  fromDate: any; rowData = []; rowexceldata = []; rowpdfdata: any; TableHeaders: any = [];
  toDate: any; msg; jsondata: boolean = false;
  TableData: any = []; tabledata: any = []; cloumdata: any = [];
  date = new Date();
  acessUser: boolean;
  rpt_id: any;
  rptParamDta: any;
  isLoadPanelVisible: boolean = false;
  shwpgHdr: boolean;
  wrdList: number[] = [];
  selectWrd: any;
  fromdate: any;
  todate: any; count;
  columnDefs; columnDeffs;
  csmtList; pstdetails: any = []
  selectUsr: any; selectULB: any; selectVndr: any;
  frmDt; toDt; selwrd = []; offnm = []; categoryfee = []; categoryfns = [];
  dateFormat = 'dd-MM-yyyy'; categorybsns = []; categorytl = [];
  apple: boolean = false; usrclmndefs = []; fnsclmndefs = [];
  sh_in; ttl_clctd_amnt; fns_tbl_shw: boolean = false; usr_tbl_shw: boolean = false;
  usramount = []; finesamount = []; usroffcrs = []; finesoffcrs = [];
  noofusroff; nooffnoff;
  shwBtn: boolean;
  selectedHeaderColor; tablenm;
  usroff_ttl_amt; fnsoff_ttl_amt;
  range: any; dateTime: any = [];
  paramstrng: any;
  rpt_url;
  shwmsg: boolean;
  griavanceSts;
  fileSts;
  griavanceSrc: any;
  drlDwnFrmAnthrPge: boolean = false; datafield: any;
  rowselected: any;
  selectedkeys: { type: string; value: any; compare: any }[]; selectedParamskeys: { type: string; value: any; compare: any }[];
  drlSelected = [];
  prmsdta: any[]; pdrpdwndata: any[]; slctdpartner: any;
  tcktCtgryLst;
  fletcktCtgryLst;
  tcktSbCtgryLst;
  CityAssosiCtgryLst;
  wardsLst: any[]; usrLst: any[];
  // eRqstCtgryLst;
  tntLst: any = [];
  clntDtls: any;
  selectedtenant;
  vendorList: any = [];
  ulbLst: any = [];
  selectedasrt: any;
  asrtList: any = [];
  rowDataDup: any = [];
  columnDefsDup: any = [];
  asrtStsList: any = [];
  userDtls: any;
  showVndr: boolean = false;
  showUlbFltr: boolean = false;
  alrtCatLst: any = [];
  selectalrtCat;
  allcolumnDefs: any = [];
  strtLoc; endLoc;

  tripLoctns: any = [];
  tripEndLoctns: any = [];
  dstrtLst: any = [];
  slctdDstrct = 0;
  srlno: number;
  asrtCtgryLst: any = [];
  selectedCtgry: any;
  time = new Date();
  timeFormat = 'HH';
  cnstyList: any = [];
  slctCnsty: any;
  slctPlngStn: any;
  plngStnList: any = [];
  cnstyID: any;
  dstrtId: any;
  selectedvehcls=[]
  asrtGrps: any=[];
  group;
  rpt_nm;
  constructor(private appsServ: AppsService, private route: ActivatedRoute, private location: Location, public datepipe: DatePipe, private router: Router,
    private themeService: ThemeConstantService) {

    this.months = [{ mnth_nm: 'January', mnth_id: 1 }, { mnth_nm: 'February', mnth_id: 2 },
    { mnth_nm: 'March', mnth_id: 3 }, { mnth_nm: 'April', mnth_id: 4 }, { mnth_nm: 'May', mnth_id: 5 },
    { mnth_nm: 'June', mnth_id: 6 }, { mnth_nm: 'July', mnth_id: 7 }, { mnth_nm: 'August', mnth_id: 8 },
    { mnth_nm: 'September', mnth_id: 9 }, { mnth_nm: 'October', mnth_id: 10 },
    { mnth_nm: 'November', mnth_id: 11 }, { mnth_nm: 'December', mnth_id: 12 }];
    this.year = [{ yr_nm: 2017, yr_id: 2017 }, { yr_nm: 2018, yr_id: 2018 }, { yr_nm: 2019, yr_id: 2019 }, { yr_nm: 2020, yr_id: 2020 }, { yr_nm: 2021, yr_id: 2021 },
    { yr_nm: 2022, yr_id: 2022 }, { yr_nm: 2023, yr_id: 2023 }, { yr_nm: 2024, yr_id: 2024 }, { yr_nm: 2025, yr_id: 2025 }, { yr_nm: 2026, yr_id: 2026 }, { yr_nm: 2027, yr_id: 2027 },
    { yr_nm: 2028, yr_id: 2028 }, { yr_nm: 2029, yr_id: 2029 }, { yr_nm: 2030, yr_id: 2030 }, { yr_nm: 2031, yr_id: 2031 }, { yr_nm: 2032, yr_id: 2032 }, { yr_nm: 2033, yr_id: 2033 }
      , { yr_nm: 2034, yr_id: 2034 }, { yr_nm: 2035, yr_id: 2035 }, { yr_nm: 2036, yr_id: 2036 }, { yr_nm: 2037, yr_id: 2037 }, { yr_nm: 2038, yr_id: 2038 }, { yr_nm: 2039, yr_id: 2039 }]


    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    // this.selectedtenant = this.tntLst[0].tnt_id;
    this.selectedtenant = 0;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));


  }

  ngOnInit() {
    this.getDistricts();
    this.getVendors();
    this.getULbs();
    this.getAsrts();
    this.getasrtStsbyUlb();
    this.getAlrtCtgry();
    this.getAsrtCtgry();
    this.getTripLocations();
    this.getAsrtgrps()
    this.slctdyear = new Date().getFullYear()
    this.slctdmnth = new Date().getMonth() + 1
    this.route.params.subscribe(params => {
      this.paramstrng = params.rpturltx.toString();
      this.rpt_url = this.paramstrng.split("-")
      this.rpt_id = this.rpt_url[1]
      this.shwmsg = false;
      this.shwRptHeader = true;
      this.shwRptDesc = true;
      this.drlDwnFrmAnthrPge = true;
      let url = '/internal/reports/list/custom/' + this.paramstrng;
      console.log(url)
      this.location.replaceState(url);
      let postdata = {
        rpt_id: this.rpt_id
      }
      const rte = `reports/getStatusrprt`;
      this.appsServ.create(postdata, rte).subscribe((res) => {
      })

      this.getdata(this.rpt_id);
    })
  }

  onMblSrch(event: any) {
    const rte = `reports/userRCtzn/mblNumList/${this.mblNum}`;
    this.appsServ.get(rte).subscribe((res) => {
      this.mtchdMblNum = res['data']
    })
  }
  onDrNumSrch(event: any) {
    const rte = `reports/Ctzn/drNumList/${this.drNum}`;
    this.appsServ.get(rte).subscribe((res) => {
      this.mtchdDrNum = res['data']
    })
  }
  getbsnsctgry() {
    const rte = `reports/business/ctgryLst`;
    this.appsServ.get(rte).subscribe((res) => {
      this.bsnsctgyList = res['data']
    })
  }

  gettlctgry() {
    const rte = `reports/tradelicense/ctgryLst`;
    this.appsServ.get(rte).subscribe((res) => {
      this.tlctgyList = res['data']
    })
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    console.log('onExporting',e);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Employees');
  
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.rpt_nm +'.xlsx');
        });
      });
    }
  
  getDistricts() {
    const rte = `elections/districts`;
    this.appsServ.get(rte).subscribe((res) => {
      this.dstrtLst = res['data'];
      console.log(this.dstrtLst.length)
      if (this.dstrtLst && this.dstrtLst.length == 1){
        this.slctdDstrct = this.dstrtLst[0].dstrt_id
      }
    })
  }
  getULbs() {
    const rte = `reports/ulb/getTenants`;
    this.appsServ.get(rte).subscribe((res) => {
      this.ulbLst = res['data'];
      if (this.ulbLst.length > 1) {
        this.showUlbFltr = true;
      } else {
        this.showUlbFltr = false;
        this.selectedtenant = this.ulbLst[0].tnt_id;
      }
    })
  }
  getfeecat() {
    const rte = `reports/usrFee/ctgryLst`;
    this.appsServ.get(rte).subscribe((res) => {
      this.feectgyList = res['data']
    })
  }
  getofcrlst() {
    const rte = `reports/usrFee/clctnagnts/1`;
    this.appsServ.get(rte).subscribe((res) => {
      this.offList = res['data']
    })
  }

  getfnscat() {
    const rte = `/reports/usrFns/ctgryLst`;
    this.appsServ.get(rte).subscribe((res) => {
      this.fnsctgyList = res['data']
    })
  }

  onCnstyChange(dstrt_id) {
    console.log(dstrt_id);
    this.dstrtId = dstrt_id;
    if (this.dstrtId && this.dstrtId > 0) {
      this.getCnstyLst();
    }
  }
  getCnstyLst() {
    const rte = `elections/cnsty/${this.dstrtId}`;
    console.log(rte);
    this.appsServ.get(rte).subscribe((res) => {
      console.log(res);
      this.cnstyList = res['data'];
    })
  }
  onPlngStnChange(e) {
    console.log(e);
    this.cnstyID = e;
    this.getPlngStnLst();
  }
  getPlngStnLst() {
    const rte = `elections/plng/stn/${this.cnstyID}`;
    this.appsServ.get(rte).subscribe((res) => {
      console.log(res);
      this.plngStnList = res['data'];
    })
  }

  getHeaderDtls() {
    this.shwpgHdr = true;
    return { "title": this.fltrsdata[0].rpt_nm, "icon": "list_alt", "desc": this.fltrsdata[0].rpt_desc }
  }
  createPdf() {
    var logotx;
    var wtrMrkTx;
    var rpt_hdr_tx
    console.log(this.clntDtls[0], this.userDtls)
    let head = [];
    let data = [];
    this.cloumdata.filter((c) => {
      if (c.visible == 1) {
        head.push(c.headerName)
      }

    })
    this.rowData.filter((k) => {
      let row = [];
      this.cloumdata.filter((c) => {
        if (c.visible == 1) {
          row.push(k[`${c.field}`]);
        }

      })
      data.push(row)
    });

    var pdfsize;var pageMode;var reqfontSize;
    if (this.fltrsdata[0].pg_sze_tx != undefined) {
      pdfsize = this.fltrsdata[0].pg_sze_tx;
    } else {
      pdfsize = 'a4';
    }
    if(pdfsize == 'a4'){pageMode="p";reqfontSize=8;}
    else{ pageMode="l";reqfontSize=14}
    var doc = new jsPDF(pageMode, "mm", pdfsize);
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    var ovrall_wdth = 30;
    var ovrall_hgt = 25;
    // if (this.clntDtls[0].spr_admn_in != 1) {
    //   logotx = "assets/images/logo/swatchAndraLgo.png";
    //   rpt_hdr_tx = 'SWACHHA ANDHRA CORPORATION';
    //   doc.addImage(logotx, 'PNG', 15, 5, ovrall_wdth, ovrall_hgt);
    // } else {
    //   if (this.clntDtls[0].clnt_id == 7) {

    //     rpt_hdr_tx = this.clntDtls[0].tnts[0].tnt_dsply_nm + ' Municipal corporation';
    //   } else {
    //     rpt_hdr_tx = 'Nagar Nigam ' + this.clntDtls[0].tnts[0].tnt_dsply_nm;
    //   }

    // }
    console.log(this.userDtls, this.clntDtls[0])
    if (this.userDtls.clap_in == 1&& this.clntDtls[0].tnts != 2000439) {
      console.log('swachandra',)
      logotx = "assets/images/logo/swatchAndraLgo.png";
      // wtrMrkTx = "assets/images/logo/swch_wtrmrk.png";
      rpt_hdr_tx = 'SWACHHA ANDHRA CORPORATION';
    }
    else if ( (this.userDtls.clap_in == 0   || this.userDtls.clap_in == 2 ) &&  this.clntDtls[0].tnts != 2000439) {
      console.log(this.clntDtls[0].tnts[0].tnt_lgo_url_tx, this.clntDtls[0].tnts[0].tnt_dsply_nm)
      logotx = this.clntDtls[0].tnts[0].tnt_lgo_url_tx
      wtrMrkTx = this.clntDtls[0].tnts[0].tnt_wtr_mrk_lgo
      rpt_hdr_tx = this.clntDtls[0].tnts[0].tnt_dsply_nm + ' Municipal corporation'
    }
    else {
      console.log('nagar nigam')
      // logotx = "assets/images/logo/swatchAndraLgo.png";
      rpt_hdr_tx = 'SWACHHA ANDHRA CORPORATION'
    }
    doc.setTextColor("brown");
    doc.setFontSize(16);
    var fontSize = doc.getFontSize();
    var pageWidth = doc.internal.pageSize.width;
    var pageHeight = doc.internal.pageSize.height
    // var txtWidth = doc.getStringUnitWidth(rpt_hdr_tx) * fontSize / doc.internal.scaleFactor;
    // txtWidth = doc.getStringUnitWidth(rpt_hdr_tx) * fontSize / doc.internal.scaleFactor;
    // Calculate text's x coordinate
    var x = (pageWidth) / 2;
    doc.text(rpt_hdr_tx, x, 14, { align: "center" });
    doc.setTextColor("black");
    doc.setFontSize(12);
    if(logotx){
    doc.addImage(logotx, 'PNG', 15, 5, ovrall_wdth, ovrall_hgt);
    }

    var rpt_ftr_txt = "***This Copy Digitally Signed by Dreamstep Software Innovations Pvt Ltd ***"
    var footerY = doc.internal.pageSize.height - 10;
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(11);
    
    doc.text(this.rpt_nm, x, 20, { align: "center" });
    doc.setTextColor("blue");
    // doc.setFontSize(11);
    doc.setTextColor("blue");
    (doc as any).autoTable({
      head: [head],
      body: data,
      theme: 'grid',
      startY: 31,
      headStyles: { fillColor: [57, 50, 126], fontSize: reqfontSize },
      bodyStyles: { fontSize: reqfontSize },
      styles: {
        overflow: 'linebreak',
    },
      didDrawPage: data => {
        doc.setTextColor("black");
        doc.text(rpt_ftr_txt, x, footerY, { align: "center" });
        // doc.addImage(wtrMrkTx, 'PNG', (pageWidth) / 2 - 40, (pageHeight) / 2 - 20, 80, 80);

      }

    })
    // Open PDF document in new tab
    doc.output('dataurlnewwindow')
    // Download PDF document  
    doc.save(this.rpt_nm + ".pdf");
  }

  gotorpt() {
    this.router.navigate(['internal/reports/list'])
  }


  getdata(rptid) {

    this.hdrsView = false;
    this.route.queryParams.subscribe(params => {
      if (params && params.paramsdata) {
        this.rptParamDta = JSON.parse(params.paramsdata);
        console.log(this.rptParamDta)
      }
    });
    let postdata = {
      rpt_id: rptid,
      rpt_url: this.paramstrng,
      rpt_params_data: this.rptParamDta ? this.rptParamDta : 0,
    }
    console.log(postdata)
    this.loader = true;
    const rte = `reports/getallrptdetails`;
    this.appsServ.create(postdata, rte).subscribe((res) => {
      this.loader = false;
      this.fltrsdata = [];
      this.tabledata = [];
      this.cloumdata = '';

      if (res['data'][0] == 'NO' && res['data'][1] == 'NO' && res['data'][2] == 'NO') {
        this.acessUser = false;
        this.shwmsg = true;
        this.msg = "You Don't Have Access To View This Report";
      }
      else {
        this.acessUser = true;
        if (res['status'] == 200) {
          console.log(res['data']);

          this.fltrsdata = [];
          this.tabledata = [];
          this.excl_ind = res['data'][0].excel_in == 1 ? true : false;
          this.fltrsdata = res['data'][0];
          this.tabledata = res['data'][1];
          this.cloumdata = res['data'][2];
          this.jsondata = true;
          var d = new Date();

          if (this.fltrsdata) {
            this.hdrsView = true;
          }
        } else {
          this.acessUser = false;
          this.shwmsg = true;
          this.msg = "You Don't Have Access To View This Report";
        }

        if (this.fltrsdata.length == undefined || (this.fltrsdata.length == 1 && this.fltrsdata[0].fltr_id == null)) {
          this.drpDwndata = false;
        } else {
          this.drpDwndata = true;
        }
        if (this.tabledata.length == undefined) {
        }
        else {
          if (this.tabledata) {
            let d = this.tabledata;
            let h = 0, hdr = [];
            for (let j in d[0]) {
              hdr[h] = j;
              h++;
            }
            this.TableHeaders = hdr;
            this.TableData = this.tabledata;
            if (this.rpt_id == 272) {
              var resArr = [];
              var count;
              for (var k = 0; k < this.TableData.length; k++) {
                resArr[k] = { "sno": this.TableData[k].sno, "mnth": this.TableData[k].mnth, "asrt_id": this.TableData[k].asrt_id, "asrt_nm": this.TableData[k].asrt_nm, "tnt_nm": this.TableData[k].tnt_nm };
                for (var i = 1; i <= 31; i++) {
                  count = 0;
                  for (var j = 0; j < this.asrtStsList.length; j++) {
                    if (this.TableData[k]['vmusts_' + i] == this.asrtStsList[j].asrt_sts_id) {
                      count++;
                      resArr[k]['vmusts_' + i] = this.asrtStsList[j].asrt_sts_nm;

                    }
                  }
                  if (count == 0) {
                    resArr[k]['vmusts_' + i] = 'NA';
                  }
                }
              }
              this.rowData = resArr;
              this.rowpdfdata = this.rowData;
              this.rowexceldata = this.rowData;
            } else if (this.rpt_id == 2000011) {
              var resArr = []; this.srlno = 0;
              for (k = 0; k < this.TableData.length; k++) {
                this.srlno = k + 1;
                this.TableData[k].sno = this.srlno;
                this.srlno++;
                resArr[k] = {
                  "sno": this.TableData[k].sno, "asrt_id": this.TableData[k].asrt_id, "asrt_nm": this.TableData[k].asrt_nm
                  , "mnth": this.TableData[k].mnth, "tnt_nm": this.TableData[k].tnt_nm
                };
                for (var i = 1; i <= 31; i++) {
                  count = 0;
                  if (this.TableData[k]['dst_' + i] == 0) {
                    for (j = 0; j < this.asrtStsList.length; j++) {
                      if (this.TableData[k]['vmusts_' + i] == this.asrtStsList[j].asrt_sts_id) {
                        count++;
                        resArr[k]['dst_' + i] = this.asrtStsList[j].asr_sts_clr_cd;
                        break;
                      }
                    }
                  } else {
                    resArr[k]['dst_' + i] = this.TableData[k]['dst_' + i];
                  }
                }
                if (count == 0) {
                  // resArr[k]['dst_' + i] = 'NA';
                }
              }
              console.log(resArr);
              this.rowData = resArr;
              this.rowpdfdata = this.rowData;
              this.rowexceldata = this.rowData;
            }
            // else if(this.rpt_id==98){
            //   this.rowData = this.TableData[4];
            //   this.rowpdfdata = this.TableData[4];
            //   this.rowexceldata = this.TableData[4];
            // }
            else {
              console.log('elseeeeeeeeeeeeeeeee', this.TableData)
              this.rowData = this.TableData;
              this.rowpdfdata = this.TableData;
              this.rowexceldata = this.TableData;
            }

            this.columnDefs = this.cloumdata;
            console.log(this.columnDefs)
          } else {
            this.TableData = [];
          }
        }
      }
      
      this.rpt_nm = '';
      console.log(this.range)
    if (((this.fltrsdata[0] && this.fltrsdata[0].fltr_id == 8) ||
      (this.fltrsdata[1] && this.fltrsdata[1].fltr_id == 8) || (this.fltrsdata[2] && this.fltrsdata[2].fltr_id == 8) || (this.fltrsdata[3] && this.fltrsdata[3].fltr_id == 8)
      || (this.fltrsdata[4] && this.fltrsdata[4].fltr_id == 8)) || ((this.fltrsdata[0] && this.fltrsdata[0].fltr_id == 7) ||
      (this.fltrsdata[1] && this.fltrsdata[1].fltr_id == 7) || (this.fltrsdata[2] && this.fltrsdata[2].fltr_id == 7) || (this.fltrsdata[3] && this.fltrsdata[3].fltr_id == 7)
      || (this.fltrsdata[4] && this.fltrsdata[4].fltr_id == 7))) {
      const date = new Date(2009, this.slctdmnth - 1, 10);  // 2009-11-10
      // const month = date.toLocaleString('default', { this.slctdmnth: 'long' });
      let month=this.months[this.slctdmnth-1].mnth_nm;
      this.rpt_nm = this.fltrsdata[0].rpt_nm + '(' + month + '-' + this.slctdyear + ')';
    }
    else if (((this.fltrsdata[0] && this.fltrsdata[0].fltr_id == 6) ||
      (this.fltrsdata[1] && this.fltrsdata[1].fltr_id == 6) || (this.fltrsdata[2] && this.fltrsdata[2].fltr_id == 6) || (this.fltrsdata[3] && this.fltrsdata[3].fltr_id == 6)
      || (this.fltrsdata[4] && this.fltrsdata[4].fltr_id == 6)) && (this.range != undefined && this.range[0] != undefined && this.range[1] != undefined)) {
      this.rpt_nm = this.fltrsdata[0].rpt_nm + '(' + moment(this.range[0]).format('DD-MM-YYYY') + ' to ' + moment(this.range[1]).format('DD-MM-YYYY') + ')';
      
      
    } else {
      this.rpt_nm = this.fltrsdata[0].rpt_nm;
      
    }
    console.log(this.rpt_nm);

    }, (error) => {
      this.acessUser = false;
      this.shwmsg = true;
      this.msg = "You Don't Have Access To View This Report";
    });

  }
  goBack() {
    this.location.back();
  }
  getRports() {
    console.log(this.selectedvehcls.toString())
    if (((this.fltrsdata[0] && this.fltrsdata[0].fltr_id == 6) ||
      (this.fltrsdata[1] && this.fltrsdata[1].fltr_id == 6) || (this.fltrsdata[2] && this.fltrsdata[2].fltr_id == 6) || (this.fltrsdata[3] && this.fltrsdata[3].fltr_id == 6)
      || (this.fltrsdata[4] && this.fltrsdata[4].fltr_id == 6) || (this.fltrsdata[0] && this.fltrsdata[0].fltr_id == 4) ||
      (this.fltrsdata[1] && this.fltrsdata[1].fltr_id == 4) || (this.fltrsdata[2] && this.fltrsdata[2].fltr_id == 4) || (this.fltrsdata[3] && this.fltrsdata[3].fltr_id == 4)
      || (this.fltrsdata[4] && this.fltrsdata[4].fltr_id == 4))) {
      let gtngrptdtls = {};
      var d = new Date();
      console.log(this.selectedtenant);
      gtngrptdtls = {
        DATE_0: this.range[0] != undefined ? moment(this.range[0]).format('YYYY-MM-DD') : 0,
        DATE_1: this.range[1] != undefined ? moment(this.range[1]).format('YYYY-MM-DD') : 0,
        DATE_2: this.range[0] != undefined ? moment(this.range[0]).format('YYYY-MM-DD') : 0,
        DATE_3: this.range[1] != undefined ? moment(this.range[1]).format('YYYY-MM-DD') : 0,
        DATE_4: this.range[0] != undefined ? moment(this.range[0]).format('YYYY-MM-DD') : 0,
        DATE_5: this.range[1] != undefined ? moment(this.range[1]).format('YYYY-MM-DD') : 0,
        DATE_6: this.range[0] != undefined ? moment(this.range[0]).format('YYYY-MM-DD') : 0,
        DATE_7: this.range[1] != undefined ? moment(this.range[1]).format('YYYY-MM-DD') : 0,
        DATE_8: this.range[0] != undefined ? moment(this.range[0]).format('YYYY-MM-DD') : 0,
        DATE_9: this.range[1] != undefined ? moment(this.range[1]).format('YYYY-MM-DD') : 0,
        DATE_10: this.range[0] != undefined ? moment(this.range[0]).format('YYYY-MM-DD') : 0,
        DATE_11: this.range[1] != undefined ? moment(this.range[1]).format('YYYY-MM-DD') : 0,
        DATE_12: this.range[0] != undefined ? moment(this.range[0]).format('YYYY-MM-DD') : 0,
        DATE_13: this.range[1] != undefined ? moment(this.range[1]).format('YYYY-MM-DD') : 0,
        DATE_14: this.range[0] != undefined ? moment(this.range[0]).format('YYYY-MM-DD') : 0,
        DATE_15: this.range[1] != undefined ? moment(this.range[1]).format('YYYY-MM-DD') : 0,
        TENANTE_ID_0: this.selectedtenant != undefined ? this.selectedtenant : 0,
        ULB_0: this.selectULB != undefined ? this.selectULB : 0,
        VENDOR_0: this.selectVndr != undefined ? this.selectVndr : 0,
        VEHICLE_0: this.selectedasrt != undefined ? this.selectedasrt : 0,
        ALRT_CTGRY_0: this.selectalrtCat != undefined ? this.selectalrtCat : 0,
        FNCE_ID_0: this.strtLoc != undefined ? this.strtLoc : 0,
        FNCE_ID_1: this.endLoc != undefined ? this.endLoc : 0,
        FNCE_ID_2: this.strtLoc != undefined ? this.strtLoc : 0,
        FNCE_ID_3: this.endLoc != undefined ? this.endLoc : 0,
        DISTRICT_0: this.slctdDstrct != undefined ? this.slctdDstrct : 0,
        ASRT_CTGRY_0: this.selectedCtgry != undefined ? this.selectedCtgry : 0,
        TIME_0: this.time != undefined ? moment(this.time).format('HH') : 0,
        TIME_1: this.time != undefined ? moment(this.time).format('HH') : 0,
        TIME_2: this.time != undefined ? moment(this.time).format('HH') : 0,
        CONSTITUENCY_0: this.slctCnsty != undefined ? this.slctCnsty : 0,
        POLLING_STN_0: this.slctPlngStn != undefined ? this.slctPlngStn : 0,
        VEHICLES_0:this.selectedvehcls != undefined ? this.selectedvehcls.toString():0,
        ASRT_GRP_0:this.group != undefined ? this.group :0
      }
      console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv', gtngrptdtls)
      this.loader = true;
      this.pstdetails[0] = this.rpt_id;
      this.pstdetails[1] = Object.keys(gtngrptdtls).map(key => ({ type: key, value: gtngrptdtls[key] }));
      this.pstdetails[2] = this.selectedtenant != undefined ? this.selectedtenant : 0;

      const rte = `reports/postTodefaultValues`;
      this.appsServ.create(this.pstdetails, rte).subscribe((res) => {
        if (res['status'] == 200) {
          this.loader = false;
          this.rowData = res['data'][0];
          this.rowpdfdata = res['data'][0];
          this.rowexceldata = res['data'][0];
          this.allcolumnDefs = res['data'][1];
          this.allcolumnDefs.forEach(element => {
            if (element.vsble_in == 1) {
              this.columnDefs.push(element);
            }
          });
          this.jsondata = true;
        }
      })
    } else {
      this.loader = true;
      var postData = {
        YEAR_0: this.slctdyear != undefined ? this.slctdyear : 0,
        YEAR_1: this.slctdyear != undefined ? this.slctdyear : 0,
        MONTH_0: this.slctdmnth != undefined ? this.slctdmnth : 0,
        MONTH_1: this.slctdmnth != undefined ? this.slctdmnth : 0,
        AGENT_0: this.slctdpartner != undefined ? this.slctdpartner : 0,
        WARD_0: this.selectWrd != undefined ? this.selectWrd : 0,
        SOURCE_0: this.selectSrce != undefined ? this.selectSrce : 0,
        CTGRY_0: this.selectfnsCtgy != undefined ? this.selectfnsCtgy : 0,
        SUBCTGRY_0: this.selectSbCtgy != undefined ? this.selectSbCtgy : 0,
        STATUS: this.selectstats != undefined ? this.selectstats : 0,
        MOBILE_0: this.mblNum != undefined ? this.mblNum : 0,
        DNO_0: this.drNum != undefined ? this.drNum : '',
        USER_0: this.selectUsr != undefined ? this.selectUsr : 0,
        DLYDYS_0: this.dlyDys != undefined ? this.dlyDys : 0,
        CTZNNM_0: this.ctznnm != undefined ? this.ctznnm : 0,
        PHONE_0: 0,
        TENANTE_ID_0: this.selectedtenant != undefined ? this.selectedtenant : 0,
        ULB_0: this.selectULB != undefined ? this.selectULB : 0,
        VENDOR_0: this.selectVndr != undefined ? this.selectVndr : 0,
        VEHICLE_0: this.selectedasrt != undefined ? this.selectedasrt : 0,
        DATE_TIME_0: this.dateTime[0] != undefined ? moment(this.dateTime[0]).format('YYYY-MM-DD HH:mm:ss') : 0,
        DATE_TIME_1: this.dateTime[1] != undefined ? moment(this.dateTime[1]).format('YYYY-MM-DD HH:mm:ss') : 0,
        DISTRICT_0: this.slctdDstrct != undefined ? this.slctdDstrct : 0,
        ASRT_CTGRY_0: this.selectedCtgry != undefined ? this.selectedCtgry : 0,
        CONSTITUENCY_0: this.slctCnsty != undefined ? this.slctCnsty : 0,
        POLLING_STN_0: this.slctPlngStn != undefined ? this.slctPlngStn : 0,
        VEHICLES_0:this.selectedvehcls != undefined ? this.selectedvehcls.toString():0,
        ASRT_GRP_0:this.group != undefined ? this.group :0

      }
      console.log(postData)
      this.pstdetails[0] = this.rpt_id;
      this.pstdetails[1] = Object.keys(postData).map(key => ({ type: key, value: postData[key] }));
      this.pstdetails[2] = this.selectedtenant != undefined ? this.selectedtenant : 0;
      const rte = `/reports/postTodefaultValues`;
      this.appsServ.create(this.pstdetails, rte).subscribe((res) => {
        if (res['status'] == 200) {
          if (this.rpt_id == 272) {
            this.loader = false;
            this.rowDataDup = res['data'][0];
            var resArr = [];
            var count;
            for (var k = 0; k < this.rowDataDup.length; k++) {
              resArr[k] = { "sno": this.rowDataDup[k].sno, "mnth": this.rowDataDup[k].mnth, "asrt_id": this.rowDataDup[k].asrt_id, "asrt_nm": this.rowDataDup[k].asrt_nm, "tnt_nm": this.rowDataDup[k].tnt_nm };
              for (var i = 1; i <= 31; i++) {
                count = 0;
                for (var j = 0; j < this.asrtStsList.length; j++) {
                  if (this.rowDataDup[k]['vmusts_' + i] == this.asrtStsList[j].asrt_sts_id) {
                    count++;
                    resArr[k]['vmusts_' + i] = this.asrtStsList[j].asrt_sts_nm;

                  }
                }
                if (count == 0) {
                  resArr[k]['vmusts_' + i] = 'NA';
                }
              }
            }
            this.rowData = resArr;
            this.rowpdfdata = this.rowData;
            this.rowexceldata = this.rowData;
            this.allcolumnDefs.forEach(element => {
              if (element.vsble_in == 1) {
                this.columnDefs.push(element);
              }
            });
          } else if (this.rpt_id == 2000011) {
            this.loader = false;
            this.rowDataDup = res['data'][0];
            var resArr = []; this.srlno = 0;
            for (k = 0; k < this.rowDataDup.length; k++) {
              this.srlno = k + 1;
              this.rowDataDup[k].sno = this.srlno;
              this.srlno++;
              resArr[k] = {
                "sno": this.rowDataDup[k].sno, "asrt_id": this.rowDataDup[k].asrt_id, "asrt_nm": this.rowDataDup[k].asrt_nm,
                "mnth": this.rowDataDup[k].mnth, "tnt_nm": this.rowDataDup[k].tnt_nm
              };
              for (var i = 1; i <= 31; i++) {
                count = 0;
                if (this.rowDataDup[k]['dst_' + i] == 0) {
                  for (j = 0; j < this.asrtStsList.length; j++) {
                    if (this.rowDataDup[k]['vmusts_' + i] == this.asrtStsList[j].asrt_sts_id) {
                      count++;
                      resArr[k]['dst_' + i] = this.asrtStsList[j].asr_sts_clr_cd;
                      break;
                    }
                  }
                } else {
                  resArr[k]['dst_' + i] = this.rowDataDup[k]['dst_' + i];
                }
              }
              if (count == 0) {
                // resArr[k]['dst_' + i] = 'NA';
              }
            }
            console.log(resArr)
            this.rowData = resArr;
            this.rowpdfdata = this.rowData;
            this.rowexceldata = this.rowData;
            this.allcolumnDefs.forEach(element => {
              if (element.vsble_in == 1) {
                this.columnDefs.push(element);
              }
            });
            // } else if(this.rpt_id == 98){
            //   console.log(res['data'][0])
            //   this.loader = false; ``
            //   this.rowData = res['data'][0][4];
            //   this.rowpdfdata = res['data'][0][4];
            //   this.rowexceldata = res['data'][0][4];
            //   this.allcolumnDefs.forEach(element => {
            //     if (element.vsble_in == 1) {
            //       this.columnDefs.push(element);
            //     }
            //   });
            //   this.jsondata = true;
          }
          else {
            this.loader = false; ``
            this.rowData = res['data'][0];
            this.rowpdfdata = res['data'][0];
            this.rowexceldata = res['data'][0];
            this.allcolumnDefs.forEach(element => {
              if (element.vsble_in == 1) {
                this.columnDefs.push(element);
              }
            });
            this.jsondata = true;

          }
        }
      })
    }
    this.rpt_nm = this.fltrsdata[0].rpt_nm + '(' + moment(this.range[0]).format('DD-MM-YYYY') + ' to ' + moment(this.range[1]).format('DD-MM-YYYY') + ')';

  }


  usrchrgdtls(data) {
    this.sh_in = 1;
    this.fns_tbl_shw = false;
    let postdata = {
      rpt_id: 44,
      rpt_params_data: this.rptParamDta ? this.rptParamDta : 0,
    }
    const rte = `reports/fines/fnsDetails/${data.data.usr_id}/1`;
    this.appsServ.get(rte).subscribe((res) => {
      this.usrdtlslist = res['data'];
      const rte = `reports/getallrptdetails`;
      this.appsServ.create(postdata, rte).subscribe((res) => {
        this.cloumdata = '';
        this.cloumdata = res['data'][2];
        this.columnDeffs = this.cloumdata.filter(item => item.headerName !== "Officer Name");
      })
      this.usr_tbl_shw = true;
      this.tablenm = data.values[0] + " - User Charges Details Report"
    })
  }


  fnsdtls(data) {
    this.sh_in = 2;
    this.usr_tbl_shw = false;
    let postdata = {
      rpt_id: 2,
      rpt_params_data: this.rptParamDta ? this.rptParamDta : 0,
    }
    const rte = `reports/fines/fnsDetails/${data.data.usr_id}/2`;
    this.appsServ.get(rte).subscribe((res) => {
      this.fnsdtlslist = res['data'];
      const rte = `reports/getallrptdetails`;
      this.appsServ.create(postdata, rte).subscribe((res) => {
        this.cloumdata = '';
        this.cloumdata = res['data'][2];
        this.columnDeffs = this.cloumdata.filter(item => item.headerName !== "Officer Name");
      })
      this.fns_tbl_shw = true;
      this.tablenm = data.values[0] + " - Fines Details Report"
    })
  }

  devCellclk(devclmns, dvclkent) {
    this.shwBtn = true;
    this.onCellClick(devclmns, dvclkent);
  }
  onCellClick(clmns, event) {
    this.rowselected = event.data;
    if (this.drlDwnFrmAnthrPge == true) {
      this.prmsdta = [];
      this.drlSelected = [];
      if ((event.column.dataField == 'tot_vndrs' && this.rpt_id == 27) || (event.column.dataField == 'tot_trds' && this.rpt_id == 31)) {
        this.drlSelected.push(
          {
            type: 'YEAR_0',
            value: this.slctdyear != undefined ? this.slctdyear : 0,
            compare: "YEAR_0",
          }, {
          type: 'MONTH_0',
          value: this.slctdmnth != undefined ? this.slctdmnth : 0,
          compare: "MONTH_0",
        })
        this.prmsdta.push(this.drlSelected[0], this.drlSelected[1])

        for (var m = 0; m < clmns.length; m++) {
          if ((event.column.dataField == clmns[m].field) && clmns[m].reporturl != "" && clmns[m].reporturl != null) {
            this.router.navigate(['/reports/list/custom/' + clmns[m].reporturl], { queryParams: { "paramsdata": JSON.stringify(this.prmsdta) }, skipLocationChange: true })
          }

        }
      } else if ((event.column.dataField == 'trp_sqnc_nu' && this.rpt_id == 1000019)) {
        console.log(this.rowselected)
        this.drlSelected.push(
          {
            type: 'DATE_TIME_0',
            value: this.rowselected.start_tm != undefined ? this.rowselected.start_tm : 0,
            compare: "DATE_TIME_0",
          }, {
          type: 'DATE_TIME_1',
          value: this.rowselected.end_tm != undefined ? this.rowselected.end_tm : 0,
          compare: "DATE_TIME_1",
        },
          {
            type: 'VEHICLE_0',
            value: this.rowselected.asrt_id != undefined ? this.rowselected.asrt_id : 0,
            compare: "VEHICLE_0",
          },
          {
            type: 'VEHICLE_1',
            value: this.rowselected.asrt_id != undefined ? this.rowselected.asrt_id : 0,
            compare: "VEHICLE_1",
          },
          {
            type: 'RTE_ID_0',
            value: this.rowselected.rte_id != undefined ? this.rowselected.rte_id : 0,
            compare: "RTE_ID_0",
          }
        )
      } this.prmsdta.push(this.drlSelected[0], this.drlSelected[1], this.drlSelected[2], this.drlSelected[3], this.drlSelected[4]);
      for (var m = 0; m < clmns.length; m++) {
        console.log(event.column.dataField)

        if ((event.column.dataField == clmns[m].field) && clmns[m].reporturl != "" && clmns[m].reporturl != null) {
          console.log(clmns[m], clmns[m].reporturl)

          this.router.navigate(['/internal/reports/list/custom/' + clmns[m].reporturl], { queryParams: { "paramsdata": JSON.stringify(this.prmsdta) }, skipLocationChange: true })
        }

      }

      console.log(this.prmsdta)

    }

    if (this.rowselected && this.drlDwnFrmAnthrPge != true) {
      this.datafield = event.column.dataField;
      this.selectedkeys = Object.keys(this.rowselected).map(key => ({ type: key, value: this.rowselected[key], compare: '' }));
      this.prmsdta = [];
      this.pdrpdwndata = [];
      for (var c = 0; c < clmns.length; c++) {
        if (event.column.dataField == clmns[c].field) {

          if (clmns[c].reporturl != "" && clmns[c].reporturl != null) {
            if (clmns[c].reportparms != "" && clmns[c].reportparms != null) {
              var str_array = clmns[c].reportparms.split(',');
              var str_array_one = clmns[c].reportFilterparms.split(',');
              for (var k = 0; k < this.selectedkeys.length; k++) {
                for (var s = 0; s < str_array.length; s++) {
                  if (str_array[s].replace(/ +/g, "") == this.selectedkeys[k].type) {
                    this.selectedkeys[k].compare = str_array_one[s]
                    this.prmsdta.push(this.selectedkeys[k]);
                  }
                  if (s + 1 == str_array.length) {
                    if (k + 1 == this.selectedkeys.length) {
                      this.router.navigate(['/reports/list/custom/' + clmns[c].reporturl], { queryParams: { "paramsdata": this.prmsdta }, skipLocationChange: true })
                    }
                  }
                }
              }
            }
            else {
              this.router.navigate(['/reports/list/custom/' + clmns[c].reporturl], { queryParams: {}, skipLocationChange: true })
            }
          }
        }
      }
    }
  }

  onCellPrepared(colDef, e) {
    if (colDef.length > 0) {
      colDef.filter((o) => {
        if (o.drwn_in == 1 && o.drwn_txt == true) {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }
      })
    }
  }


  getVendors() {
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectVndr = this.userDtls.vndr_id;
      this.showVndr = true;
      this.appsServ.get(`reports/vendors/list/${this.selectVndr}/byId`).subscribe((res) => {
        if (res['status'] == 200) {
          this.vendorList = res['data'];
        }
      }, (err) => {
      })
    } else {
      this.appsServ.get(`reports/ulb/${this.selectedtenant}/vendors/list`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
        }
      }, (err) => {
        // console.log('In errorr');
      });
    }
  }
  getAsrts() {
    this.appsServ.get(`reports/ulb/${this.selectedtenant}/Vehicles/list`).subscribe((res) => {
      if (res['status'] === 200) {
        this.asrtList = res['data'];
      }
    }, (err) => {
      // console.log('In errorr');
    });
  }
  getAsrtgrps() {
    this.appsServ.get(`reports/ulb/${this.selectedtenant}/groups`).subscribe((res) => {
      console.log(this.selectedtenant)
      if (res['status'] === 200) {
        this.asrtGrps = res['data'];
      }
    }, (err) => {
      // console.log('In errorr');
    });
  }
  onULBChange(value) {
    this.selectedtenant = value;
    this.getasrtStsbyUlb();
    this.getAsrts();
    this.getVendors();
    this.getAsrtCtgry();
    this.getAsrtgrps()
  }
  getasrtStsbyUlb() {
    this.appsServ.get(`reports/ulb/${this.selectedtenant}/Vehicles/status`).subscribe((res) => {
      if (res['status'] === 200) {
        this.asrtStsList = res['data'];
        console.log(this.asrtStsList)
      }
    }, (err) => {
      console.log('In errorr');
    });
  }
  onVndrChange(v) {
    this.selectVndr = v;
    if ((this.fltrsdata[0] && this.fltrsdata[0].fltr_id == 26) ||
      (this.fltrsdata[1] && this.fltrsdata[1].fltr_id == 26) || (this.fltrsdata[2] && this.fltrsdata[2].fltr_id == 26) || (this.fltrsdata[3] && this.fltrsdata[3].fltr_id == 26)
      || (this.fltrsdata[4] && this.fltrsdata[4].fltr_id == 26)) {
      this.getULbsbyVndr_id(this.selectVndr);
      this.getASrtsbyVndr(this.selectVndr);
    }

  }
  getASrtsbyVndr(vndr_id) {
    this.appsServ.get(`reports/getvehicles/byVndr/${vndr_id}`).subscribe((res) => {
      if (res['status'] === 200) {
        this.asrtList = res['data'];
      }
    }, (err) => {
      // console.log('In errorr');
    });
  }
  getULbsbyVndr_id(vndr_id) {
    this.ulbLst = [];
    this.appsServ.get(`reports/getUlb/byVndr/${vndr_id}`).subscribe((res) => {
      if (res['status'] === 200) {
        this.ulbLst = res['data'];
      }
    }, (err) => {
      // console.log('In errorr');
    });
  }
  getAlrtCtgry() {
    this.appsServ.get(`reports/alrtCtgry`).subscribe((res) => {
      if (res['status'] === 200) {
        this.alrtCatLst = res['data'];
      }
    }, (err) => {
      // console.log('In errorr');
    });
  }
  getAsrtCtgry() {
    this.appsServ.get(`reports/ulb/${this.selectedtenant}/Vehicles/category`).subscribe((res) => {
      if (res['status'] === 200) {
        this.asrtCtgryLst = res['data'];
        console.log(this.asrtCtgryLst)
      }
    }, (err) => {
      console.log('In errorr');
    });
  }
  getimage(data) {
    var a = data.data;
    if (a.bfr_img_tx) {
      window.open(a.bfr_img_tx);
    }
  }
  getimage2(data) {
    var a = data.data;
    if (a.aftr_img_tx) {
      window.open(a.aftr_img_tx);
    }
  }
  getTripLocations() {
    var resData = [];
    let rte = `reports/getTripLctns`;
    this.appsServ.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        resData = res['data']
        // console.log(resData)
        for (var i = 0; i <= resData.length; i++) {
          if (resData[i] != undefined) {

            if (resData[i].fnc_ky == 1) {
              this.tripLoctns.push({ strt_fnce_id: resData[i].fnce_id, strt_fnce_nm: resData[i].fnce_nm });
            } else {
              this.tripEndLoctns.push({ end_fnce_id: resData[i].fnce_id, end_fnce_nm: resData[i].fnce_nm });
            }
          }
        }

      }
    })
  }
}
