import { Component, OnInit } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import { AppsService } from 'src/app/shared/services/apps.service';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { Router } from '@angular/router';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end
@Component({
  selector: 'app-district-dashboard',
  templateUrl: './district-dashboard.component.html',
  styleUrls: ['./district-dashboard.component.scss']
})
export class DistrictDashboardComponent implements OnInit {
  slctdDt = new Date();
  today = new Date();
  curDate;
  curTblDate;
  dateFormat = 'dd/MM/yyyy';
  clntDtls: any;
  userDtls: any;
  clap_in: any;
  ShowClap: boolean = false;
  rdrctUlbDtls: any = [];
  selectdDstrt;
  dstrctLst: any = [];
  showLdr = false;
  selectdDstrtId;
  selectdDstrtNm;
  tdyCoveredTrips: any[];
  postslctdDt: any;
  dbrd_ky = 'ulb';
  ttlTrpsCnt: any = [];
  nodata: string;
  curYr: string;
  vehclesTble: any = [];
  vehclesTblecolumnDefs: any = [];
  tripsTrvldDta: any[];
  tripsTrvldcolumnDefs: any = [];


  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.rdrctUlbDtls = [];
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.rdrctUlbDtls = this.TransfereService.getLoclData('ulbDta');
    console.log(this.rdrctUlbDtls);
    this.selectdDstrtId = this.rdrctUlbDtls.dstrct_id;
    this.selectdDstrtNm = this.rdrctUlbDtls.dstrct_nm;
    this.ondstrctSelect(this.rdrctUlbDtls.dstrct_id);
    // this.getDisrtcts();
    // if (this.rdrctUlbDtls) {
    //   this.ondstrctSelect(this.rdrctUlbDtls.dstrct_id);
    // } else {
    //   let rte = `admin/districts`;
    //   this.apiServ.get(rte).subscribe((res) => {
    //     this.dstrctLst = res['data'];
    //     this.selectdDstrtId = this.dstrctLst[0].dstrct_id;
    //     this.selectdDstrtNm = this.dstrctLst[0].dstrct_nm;
    //   });
    // }
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
  }

  ngOnInit(): void {
    this.curYr = moment(this.today).format('YYYY');
    this.slctdDt = new Date();
    this.getDisrtcts();
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, this.selectdDstrtId);
    this.get_totalTripsCount(this.slctdDt, this.selectdDstrtId);
    this.get_lst7DaysTrpsDta(this.slctdDt, this.selectdDstrtId);
    this.get_lst7DaysDistanceDta(this.slctdDt, this.selectdDstrtId);
    this.get_lst10Days_ntOprdTrpsDta(this.slctdDt, this.selectdDstrtId);
    this.getVehicleswiseData(this.slctdDt, this.selectdDstrtId);
    this.get_Trips_trvalledDetails(this.slctdDt, this.selectdDstrtId);
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  onChange(e): void {
    // console.log('onChange: ',e);
    this.slctdDt = e;
    // console.log('slctdDt: ', this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    // console.log(this.curDate);
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, this.selectdDstrtId);
    this.get_totalTripsCount(this.slctdDt, this.selectdDstrtId);
    this.get_lst7DaysTrpsDta(this.slctdDt, this.selectdDstrtId);
    this.get_lst7DaysDistanceDta(this.slctdDt, this.selectdDstrtId);
    this.get_lst10Days_ntOprdTrpsDta(this.slctdDt, this.selectdDstrtId);
    this.getVehicleswiseData(this.slctdDt, this.selectdDstrtId);
    this.get_Trips_trvalledDetails(this.slctdDt, this.selectdDstrtId);

  }
  getDisrtcts() {

    let rte = `admin/districts`;
    this.apiServ.get(rte).subscribe((res) => {
      this.dstrctLst = res['data'];
    });
  }
  ondstrctSelect(value) {
    if (value == 0) {
      this.selectdDstrtId = 0;
      this.selectdDstrtNm = "All Districts's Selected";
    }
    for (let i = 0; i < this.dstrctLst.length; i++) {
      console.log('in');
      if (this.dstrctLst[i].tnt_id == value) {
        this.selectdDstrtId = this.dstrctLst[i].dstrct_id;
        this.selectdDstrtNm = this.dstrctLst[i].dstrct_nm;
      }

    }
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, this.selectdDstrtId);
    this.get_totalTripsCount(this.slctdDt, this.selectdDstrtId);
    this.get_lst7DaysTrpsDta(this.slctdDt, this.selectdDstrtId);
    this.get_lst7DaysDistanceDta(this.slctdDt, this.selectdDstrtId);
    this.get_lst10Days_ntOprdTrpsDta(this.slctdDt, this.selectdDstrtId);
    this.getVehicleswiseData(this.slctdDt, this.selectdDstrtId);
    this.get_Trips_trvalledDetails(this.slctdDt, this.selectdDstrtId);

  }
  get_today_Ulb_CoveredTripsCount(funDt?, slctDstrt?): any {
    this.tdyCoveredTrips = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      slctd_dstrt: slctDstrt
    }
    let rte = `dashboard/enterprise/district/get_dstrct_vhcle_sts_cnts`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.tdyCoveredTrips = res['data'];
          console.log(this.tdyCoveredTrips)
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  get_totalTripsCount(funDt?, slctDstrt?): any {
    this.ttlTrpsCnt = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_dstrt: slctDstrt
    }
    let rte = `dashboard/enterprise/district/get_trips_cnts`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      var res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.ttlTrpsCnt = res['data'];
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  // lst7DayDstnc;
  get_lst7DaysDistanceDta(funDt?, slctDstrt?): any {
    let chart = am4core.create("distanceTavelledChart", am4charts.XYChart);
    chart.data = [];
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_dstrt: slctDstrt
    }
    let rte = `dashboard/enterprise/district/get_lst7DaysDistanceChrt`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      chart.data = res['data'];
      // this.lst7DayDstnc = this.chart.data;
      // console.log(chart.data);

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "dt";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = ` ----- Date(${this.curYr})----- `;
      // categoryAxis.renderer.labels.template.rotation = -45;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = " ----- Operated % ----- ";

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "opper";
      series.dataFields.categoryX = "dt";
      series.name = "Date";
      series.columns.template.tooltipText = "Distance travelled in {categoryX}: [bold]{valueY}[/]";
      series.columns.template.fillOpacity = .8;
      series.columns.template.fill = am4core.color("#afb42b");
      series.columns.template.stroke = am4core.color("#d4e157");
      let columnTemplate1 = series.columns.template;
      columnTemplate1.strokeWidth = 1;
      // columnTemplate1.strokeOpacity = 1;
      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{opper}";
      valueLabel.label.fontSize = 15;

      // columnTemplate1.width = 60;
      columnTemplate1.column.cornerRadiusTopLeft = 5;
      columnTemplate1.column.cornerRadiusTopRight = 5;
      columnTemplate1.strokeOpacity = 1;
    });
  }
  // lst7DayTrps;
  get_lst7DaysTrpsDta(funDt?, slctDstrt?): any {
    // this.lst7DayTrps = [];
    let chart2 = am4core.create('ctgrytripsTavelledChart', am4charts.XYChart);
    let chart = am4core.create('chartdiv', am4charts.XYChart);
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_dstrt: slctDstrt
    }
    let rte = `dashboard/enterprise/distrct/get_lst7DaysTripsChrt`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      chart2.data = res['data'].results;
      // this.heatChrt = res['data'];
      console.log(chart2.data);
      if (res['data'].msg == 0) {
        this.nodata = 'No trips defined for the vehicle(s)';
      } else {
        this.nodata = '';
      }
      let categoryAxis2 = chart2.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis2.dataFields.category = 'dt';
      categoryAxis2.renderer.grid.template.location = 0;
      categoryAxis2.renderer.minGridDistance = 10;
      categoryAxis2.title.text = ` ----- Date (${this.curYr}) ----- `;
      // categoryAxis2.renderer.labels.template.rotation = -45;

      let valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.renderer.inside = true;
      // valueAxis2.renderer.labels.template.disabled = true;
      // valueAxis2.min = 0;
      valueAxis2.title.text = ' -----  Trips % ----- ';

      // Create series
      function createSeries(field, name) {

        // Set up series
        let series2 = chart2.series.push(new am4charts.ColumnSeries());
        series2.name = name;
        series2.dataFields.valueY = field;
        series2.dataFields.categoryX = 'dt';
        series2.sequencedInterpolation = true;

        // Make it stacked
        series2.stacked = true;

        // Configure columns
        series2.columns.template.width = am4core.percent(60);
        series2.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
        // Add label
        let labelBullet = series2.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = '{valueY}';
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;

        return series2;

      }

      // createSeries('tot_trps', 'Total Trips');
      // createSeries('prmry_trps', 'Primary');
      // createSeries('scndry_trps', 'Secondary');
      // createSeries('othr_trps', 'Others');
      createSeries('trvl_per', 'Travelled %');
      // createSeries('fogAts_trps', 'Fog-Autos');
      // createSeries('swpngMchns_trps', 'Sweeping Machines');
      // Legend
      // chart2.legend = new am4charts.Legend();
    });
  }
  // lst10DaystrpsDta;
  get_lst10Days_ntOprdTrpsDta(funDt?, slctDstrt?): any {
    // this.lst10DaystrpsDta = [];
    let chart5 = am4core.create("lst10DysNtoprdTrpsChart", am4charts.XYChart);
    chart5.data = []
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_dstrt: slctDstrt
    }
    let rte = `dashboard/enterprise/district/getDstrt_lst10DaysNtOprdTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      chart5.data = res['data'];
      // console.log(chart5.data);

      let categoryAxis5 = chart5.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis5.dataFields.category = "dt";
      categoryAxis5.renderer.grid.template.location = 0;
      categoryAxis5.renderer.minGridDistance = 30;
      categoryAxis5.title.text = ` ----- Date(${this.curYr})----- `;
      // categoryAxis5.renderer.labels.template.rotation = -45;

      let valueAxis5 = chart5.yAxes.push(new am4charts.ValueAxis());
      valueAxis5.title.text = " ----- Trips ----- ";

      // Create series
      let series5 = chart5.series.push(new am4charts.ColumnSeries());
      series5.dataFields.valueY = "trps";
      series5.dataFields.categoryX = "dt";
      series5.name = "Category";
      series5.columns.template.tooltipText = "Not-operated in {categoryX}: [bold]{valueY}[/]";
      series5.columns.template.fillOpacity = .8;
      series5.columns.template.fill = am4core.color("#ef5350");
      series5.columns.template.stroke = am4core.color("#ef9a9a");
      let columnTemplate5 = series5.columns.template;
      columnTemplate5.strokeWidth = 1;
      let valueLabel = series5.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{trps}";
      valueLabel.label.fontSize = 15;

      // columnTemplate5.width = 60;
      columnTemplate5.column.cornerRadiusTopLeft = 5;
      columnTemplate5.column.cornerRadiusTopRight = 5;
      columnTemplate5.strokeOpacity = 1;

    });
  }
  getVehicleswiseData(funDt?, slctDstrt?) {
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      slctd_dstrt: slctDstrt
    }
    let rte = `dashboard/enterprise/district/getMndlVehicleSts`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.vehclesTble = res['data'];
        this.vehclesTblecolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
          { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 140 },
          { headerName: 'Mandal', field: 'tnt_nm', alignment: 'left', width: 140 },
          { headerName: 'Panchayati', field: 'asrt_grp_nm', alignment: 'left', width: 140 },
          { headerName: 'Vehicle Number', field: 'asrt_nm', alignment: 'left', width: 200 },
          // { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', width: 200 },
          // { headerName: 'Sachivalayam Code', field: 'svm_apfs_cd', alignment: 'left', width: 'auto' },
          { headerName: 'Driver Name', field: 'crw_nm', alignment: 'left' },
          { headerName: 'Driver Contact', field: 'crw_ph', alignment: 'left' },
          // { headerName: 'Sanitary Secratory', field: 'ss_nm', alignment: 'left', width: 'auto' },
          // { headerName: 'Sanitary Secratory Contact', field: 'ss_cntct', alignment: 'left', width: 'auto' },
          { headerName: 'Assigned Trips', field: 'asgnd_trps', alignment: 'center' },
          { headerName: 'Today Travelled Trips', field: 'tdy_trvl_trps', alignment: 'center' },
          // { headerName: 'Yesterday Travelled Trips', field: 'yst_dy_trvl_trps', alignment: 'center' },
          { headerName: 'Today Travelled Distance in Kms', field: 'tdy_trvl_dst', alignment: 'center' },
          // { headerName: 'Yesterday Travelled Distance in Kms', field: 'yst_dy_trvl_dst',  alignment: 'center' },
        ]
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  get_Trips_trvalledDetails(funDt?, slctDstrt?): any {
    this.tripsTrvldDta = []
    this.showLdr = true;
    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    this.postslctdDt = {
      dt: this.curDate,
      slctd_dstrt: slctDstrt
    }
    let rte = `dashboard/enterprise/district/get_tdyTripDetails`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        console.log(res_data)
        if (res_data && res_data.length > 0) {
          this.tripsTrvldDta = res['data'];
          console.log(this.tripsTrvldDta)
          this.tripsTrvldcolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, cellClass: 'pm-grid-number-cell', search: false },
            { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 140 },
            { headerName: 'Mandal', field: 'tnt_nm', alignment: 'left', width: 140 },
            { headerName: 'Vehicle registration Number', alignment: 'left', width: 130, field: 'asrt_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Vehicle Group', field: 'asrt_grp_nm', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Trip No.', field: 'trp_sqnc_nu', width: 80, alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Trip Type', field: 'trp_typ_nm', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true, groupIndex: '0', },
            { headerName: 'From', alignment: 'center', width: 130, field: 'strt_fnce_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'To', field: 'end_fnce_nm', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Actual Start', alignment: 'center', width: 130, field: 'actl_strt_ts', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Actual End', field: 'actl_end_ts', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Trip Status', field: 'trp_status', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Travelled Distance', alignment: 'center', width: 130, field: 'actl_trvl_dstne_ct', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Travelled Time', field: 'actl_trvl_tm', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Route Deviation', field: 'rt_dev', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },

            { headerName: 'Lat', field: 'lat', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'lng', alignment: 'center', width: 130, field: 'lng', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },
          ]
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  onCellPrepared(colDef, e) {

    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'Vehicle No') {
      e.cellElement.style.color = '#ff0000';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
      e.cellElement.style.background = 'rgba(243, 191, 176, 0.2784313725490196)';
      e.cellElement.style.backgroundClip = 'content-box';
      e.cellElement.style.cursor = 'pointer';
    }

  }
  gotoUlb(event) {
    console.log(event.row.data);
    this.TransfereService.setLoclData('ulbDta', event.row.data)
    this.route.navigate([`internal/dashboard/mandal-dashboard`]);
  }
}
