<ds-loading *ngIf="showLdr"></ds-loading>

<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" nzTitle="Vehicle Dashboard"
    (nzOnClose)="visible = false" class="dashbSlider">
    <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
</nz-drawer>

<div class="row hdrBar">
    <div class="col-lg-5 col-md-5 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0 && clntDtls[0].clnt_id==7">{{hdrLbl}} Dashboard - Clean Andhra
            Pradesh(CLAP) <span *ngIf="selectedtenantNm" class="tntTxt">({{selectedtenantNm}})</span></h1>
        <h1 *ngIf="clntDtls && clntDtls.length > 0 && clntDtls[0].clnt_id!=7">{{hdrLbl}} Dashboard <span
                *ngIf="selectedtenantNm" class="tntTxt">({{selectedtenantNm}})</span></h1>
    </div>
    <div class="col-lg-2 col-md-2 mg-a" style="text-align: right;" *ngIf="tntLst.length>1">
        <span>Select {{hdrLbl}}:</span>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
            [ngModelOptions]="{standalone: true}">
            <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
            <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
        </nz-select>
    </div>
    <div class="col-lg-2 col-md-2 mg-a" style="text-align: right;" *ngIf="tntLst.length==1">
        <span>{{hdrLbl}}:</span>
        <span class="disbledtxt">{{tntLst[0].tnt_nm}}</span>
    </div>
    <div class="col-lg-2 col-md-2 mg-a">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
    <div class="col-lg-1 col-md-1 mg-l" *ngIf="button_in">
        <button nz-button nzType="primary" (click)="gotoback()">Go Back</button>
    </div>
    
    <!-- <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <span>Select Date:</span>
        <nz-date-picker   [nzDisabledDate]="disabledDate"   [nzFormat]="dateFormat" [(ngModel)]="slctdDt" (ngModelChange)="onChange($event)"></nz-date-picker>
    </div> -->
</div>

<div class="main">
    <div class="row">
        <ul class="cards">
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <!-- <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Total Vehicles</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="car" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title">{{d.ttl}}</h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-8 col-md-8">
                                <h1>
                                    Operated
                                </h1>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <h1 class="card_sub_title">{{d.operated === null ? "NA" : d.operated}}</h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-8 col-md-8">
                                <h1>
                                   Not-Operated
                                </h1>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <h1 class="card_sub_title">{{d.notoperated}}</h1>
                            </div>
                        </div>
                    </div> -->
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Vehicles</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.actv_veh_ct === null ? "0" : d.actv_veh_ct}}
                                </h1>
                                <h1 class="card_sub_title">Active</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.inact_veh_cnt === null ? "0" : d.inact_veh_cnt}}
                                </h1>
                                <h1 class="card_sub_title">Spare</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #7f75e5 0%,#4e49b9 100%);"
                    (click)="cardTwo()" [ngClass]="crdTwo == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Operational Status</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.u_operated === null ? "0" : d.u_operated}}
                                </h1>
                                <h1 class="card_sub_title">Operated</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.u_opper === null ? "0" : d.u_opper}} %
                                </h1>
                                <h1 class="card_sub_title">Operated %</h1>
                            </div>
                        </div>
                        <div class="row">
                            <p class="crdFtr">VEHICLES TRAVELLED > 500 Meters IS CONSIDERED AS OPERATED</p>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of ttlTrpsCnt">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);">
                    <!-- <div class="row">
                        <h1 class="crdHdr">Trips covered</h1>
                    </div>
                    <div class="row">
                        <div class="col card_prt">
                            <h1 class="crdHdr_sb_sm">
                                {{d.tdy_trps === null ? 0 : d.tdy_trps}}
                            </h1>
                            <h1 class="card_sub_title">Today</h1>
                        </div>
                        <div class="col card_prt">
                            <h1 class="crdHdr_sb_sm">
                                {{d.ysd_trps === null ? 0 : d.ysd_trps}}
                            </h1>
                            <h1 class="card_sub_title">Yesterday</h1>
                        </div>
                    </div> -->
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Trips Travelled</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.def_trps === null ? 0 : d.def_trps}}
                                </h1>
                                <h1 class="card_sub_title">Defined Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.tdy_trvl_trps === null ? 0 : d.tdy_trvl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Travelled</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.tdy_per === null ? 0 : d.tdy_per}}
                                </h1>
                                <h1 class="card_sub_title">Travelled %</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #b1ea4d 0%,#459522 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Primary Trips</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title"  *ngIf="prmryTrpsCnt && prmryTrpsCnt.length && shwzrwErr == false">{{prmryTrpsCnt[0].prmy_trps}}</h1>
                                <h1 class="card_sub_title"  *ngIf="shwzrwErr == true">0</h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-8 col-md-8">
                                <h1>
                                    Running
                                </h1>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <h1 class="card_sub_title"  *ngIf="prmryTrpsCnt && prmryTrpsCnt.length && shwzrwErr == false">{{prmryTrpsCnt[0].prmy_runnig_trps}}</h1>
                                <h1 class="card_sub_title"  *ngIf="shwzrwErr == true">0</h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-8 col-md-8">
                                <h1>
                                   Yet to Start
                                </h1>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <h1 class="card_sub_title"  *ngIf="prmryTrpsCnt && prmryTrpsCnt.length && shwzrwErr == false">{{prmryTrpsCnt[0].prmy_yet_trps}}</h1>
                                <h1 class="card_sub_title"  *ngIf="shwzrwErr == true">0</h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-8 col-md-8">
                                <h1>
                                   Completed
                                </h1>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <h1 class="card_sub_title"  *ngIf="prmryTrpsCnt && prmryTrpsCnt.length && shwzrwErr == false">{{prmryTrpsCnt[0].prmy_cmpltd_trps}}</h1>
                                <h1 class="card_sub_title"  *ngIf="shwzrwErr == true">0</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #f36265 0%,#b42b70 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Secondary Trips</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title"  *ngIf="scndryTrpsCnt && scndryTrpsCnt.length && shwScndryzrwErr == false">{{scndryTrpsCnt[0].scndry_trps}}</h1>
                                <h1 class="card_sub_title"  *ngIf="shwScndryzrwErr == true">0</h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-8 col-md-8">
                                <h1>
                                    Running
                                </h1>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <h1 class="card_sub_title"  *ngIf="scndryTrpsCnt && scndryTrpsCnt.length && shwScndryzrwErr == false">{{scndryTrpsCnt[0].scndry_runnig_trps}}</h1>
                                <h1 class="card_sub_title"  *ngIf="shwScndryzrwErr == true">0</h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-8 col-md-8">
                                <h1>
                                   Yet to Start
                                </h1>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <h1 class="card_sub_title"  *ngIf="scndryTrpsCnt && scndryTrpsCnt.length && shwScndryzrwErr == false">{{scndryTrpsCnt[0].scndry_yet_trps}}</h1>
                                <h1 class="card_sub_title"  *ngIf="shwScndryzrwErr == true">0</h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-8 col-md-8">
                                <h1>
                                   Completed
                                </h1>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <h1 class="card_sub_title"  *ngIf="scndryTrpsCnt && scndryTrpsCnt.length && shwScndryzrwErr == false">{{scndryTrpsCnt[0].scndry_cmpltd_trps}}</h1>
                                <h1 class="card_sub_title"  *ngIf="shwScndryzrwErr == true">0</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>  -->

        </ul>

    </div>

    <!-- <div class="abs_cntr">
    <div *ngIf="ctgryWseCnts && ctgryWseCnts.length > 0">
       <ul>
            <li>
                <h1><b>Operated</b>/<b>Total</b></h1>
                <h2>Vehicle Category</h2>
            </li>
        </ul>
        <ul class="hscrl">
            <li *ngFor="let c of ctgryWseCnts">
                <h1><b>{{c.oprtd}}</b>/<b>{{c.c_tot}}</b></h1>
                <h2>{{c.asrt_ctgry_dsply_nm}}</h2>
            </li>
        </ul>
    </div>
    
    </div> -->

    <div class="row bg-w">
        
        <div class="col-lg-6 col-md-6">
            <h1>Vehicles Operation in Last 7 Days ({{ttl_vhcls}}) </h1>
            <div id="distanceTavelledChart"></div>
            <div>
                <span>
                    <span class="oprr" style="margin-left: 80px;"></span> Operated Vehicles
                </span>
                <span>
                    <span class="nt_oprr" style="margin-left: 100px;"></span> Not Operated Vehicles (Vehicles Travel
                    Less than 2KMS)
                </span>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <h1>Travelled Trips % Last 7 Days </h1>
            <!-- <div *ngIf="!nodata"> -->
            <div id="ctgrytripsTavelledChart"></div>
            <!-- </div> -->
            <div *ngIf="nodata" style="text-align: center;">
                {{nodataMsg}}
            </div>
        </div>
    </div>

    <!-- <div class="row bg-w">
        <div class="col-lg-6 col-md-6">
            <h1>Category Wise Trips Travelled</h1>
            <div id="tripsCtgryWseChart"></div>
        </div>
        <div class="col-lg-6 col-md-6">
            <h1>Category Wise Distance Travelled</h1>
            <div id="distanceCtgryWseChart"></div>
        </div>
    </div> -->
    <div class="row bg-wp">
        <h1>Vehicles </h1>
        <div class="ds-card tbl grid">

            <dx-data-grid class="p-8" [dataSource]="vehclesTble" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="true" (onExporting)="onExporting1($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <!--<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>-->
                <dxo-pager [visible]="vehclesTble.length>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ulb wise operated Trips">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of vehclesTblecolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>



    <div class="row bg-wp">
        <h1>Trips Travelled {{curTblDate}}</h1>

        <div class="ds-card grid tbl">
            <dx-data-grid class="p-8" [dataSource]="tripsTrvldDta" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="true" (onToolbarPreparing)="true"
                (onCellPrepared)="onCellPrepared(tripsTrvldcolumnDefs,$event)" (onRowClick)="getVehDashSldr($event)" (onExporting)="onExporting2($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="tripsTrvldDta.length>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" >
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Trips Travelled
               Data"></dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of tripsTrvldcolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoPlaybck(data)" *ngIf="data">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>Playback</p>
                        </div>
                    </div>
                </dxi-column>
                <dxo-summary>
                    <dxi-group-item column="asrt_nm" name="Total Vehicles" summaryType="count" [alignByColumn]="true"
                        [showInGroupFooter]="true"></dxi-group-item>
                    <dxi-group-item column="trp_sqnc_nu" name="Total Trips" summaryType="count" [alignByColumn]="true"
                        [showInGroupFooter]="true">
                    </dxi-group-item>
                    <dxi-group-item column="actl_trvl_dstne_ct" name="Total Distance" summaryType="sum"
                        [alignByColumn]="true" [showInGroupFooter]="true"></dxi-group-item>
                    <dxi-group-item column="actl_trvl_tm" name="Total Hours" summaryType="sum" [alignByColumn]="true"
                        [showInGroupFooter]="true">
                    </dxi-group-item>
                </dxo-summary>
            </dx-data-grid>
        </div>
    </div>

    <!-- <div class="row bg-wp" *ngIf="ShowClap">
        <h1>Ward wise Vehicle Operational Details</h1>
        <div class="ds-card tbl grid">

            <dx-data-grid class="p-8" [dataSource]="VndrwseopratedvehclesTble" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="true">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="VndrwseopratedvehclesTble>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ulb wise operated Trips">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of VndrwseopratedvehclesTblecolumnDefs" [visible]="!f.hide"
                    [dataField]="f.field" [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width"
                    [allowResizing]="true">
                </dxi-column>

            </dx-data-grid>
        </div>
    </div> -->
    <!--      <div class="row bg-wp">
            <h1>Route Deviated Trips</h1>
        
            <div class="ds-card grid tbl">
                <dx-data-grid class="p-8" [dataSource]="rtDvtdTripsDta" [remoteOperations]="false"  
                  [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                  [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                  (onToolbarPreparing)="true">
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [visible]="rtDvtdTripsDta.length>10" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>
                  <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                  <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                  <dxo-group-panel [visible]="true"></dxo-group-panel>
                  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                  <dxo-column-chooser [enabled]="true" mode="select">
                  </dxo-column-chooser>
                  <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Route Deviated Trips Data"></dxo-export>
                  <dxo-filter-row [visible]="false"></dxo-filter-row>
                  <dxo-summary>
                    <dxi-total-item column="asrt_nm" name="Total Vehicles" summaryType="count"></dxi-total-item>
                    <dxi-total-item column="trp_sqnc_nu" name="Total Trips" summaryType="count"></dxi-total-item>
                     <dxi-total-item column="dst" name="Total Distance" summaryType="sum"></dxi-total-item>
                    <dxi-total-item column="trvl_hr" name="Total Hours" summaryType="sum"></dxi-total-item>
                  </dxo-summary>
                  <dxi-column *ngFor="let f of rtDvtdTripscolumnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
                    [alignment]="f.alignment" [width]="f.width" [allowResizing]="true"></dxi-column>
                </dx-data-grid>
              </div>
        
         </div>
  -->
    <!-- <div class="row bg-wp">
        <h1>Last 10 Days Not-operated Vehicles<span>(Vehicles Travel Less than 2KMS)</span></h1>
        <div id="lst10DysNtoprdTrpsChart"></div>
    </div> -->
    <!-- <div class="col-lg-6 col-md-6">
            <h1>Sweepers Working Time</h1>
            <div class="ds-card grid tbl">
                <dx-data-grid class="p-8" [dataSource]="swprsWrkngTmDta" [remoteOperations]="false"  
                  [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                  [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                  (onToolbarPreparing)="true">
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>
                  <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                  <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                  <dxo-group-panel [visible]="true"></dxo-group-panel>
                  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                  <dxo-column-chooser [enabled]="true" mode="select">
                  </dxo-column-chooser>
                  <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="ULB Trips Data"></dxo-export>
                  <dxo-filter-row [visible]="false"></dxo-filter-row>
                  <dxi-column *ngFor="let f of swprsWrkngTmcolumnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
                    [alignment]="f.alignment" [width]="f.width" [allowResizing]="true"></dxi-column>
                  <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons"
                    [width]="100" caption="Edit" cellTemplate="editTemplate">
                  </dxi-column>
                  <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons"
                    [width]="100" caption="Delete" cellTemplate="delTemplate">
                  </dxi-column>
                </dx-data-grid>
              </div>
        </div> -->


    <!-- <div class="row bg-wp">
        <div class="row hdrBar">
            <div class="col-lg-8 col-md-8 mg-a">
                <h1>Solid Waste Management</h1>
            </div>
            <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
                <div class="inptCls">
                    <span>Primary<input class="prmry" disabled="true"></span>
                    <span>Secondary<input class="scndry" disabled="true"></span>
                </div>
            </div>
        </div>
        <div class="row crdsFixedHts" *ngIf="cvrgDta && cvrgDta.length>0">

            <div class="crdPdng col-lg-12 col-md-12 mg-a">
                <div class="row">
                    <ul class="cards">
                        <li class="cards_item wdt-11"></li>
                        <li class="cards_item">
                            <div class="card_sw_g" style="background:linear-gradient(135deg, #40d99d 0%,#3bb2b8 100%);">
                                <div class="card_content">
                                    <div class="row">
                                        <h1 class="crdHdr">Transfer Points</h1>
                                    </div>
                                    <div class="row crdHdr_sb">
                                        <div class="crdPdng col-lg-6 col-md-6">
                                            <h1>
                                                <i nz-icon nzType="branches" nzTheme="outline"></i>
                                            </h1>
                                        </div>
                                        <div class="crdPdng col-lg-6 col-md-6" *ngIf="trnsfrPnts">
                                            <h1 class="card_sub_title"
                                                (click)="getDtls(trnsfrPnts,'t');scroll(target);">
                                                {{trnsfrPnts.tot_fnce}}</h1>
                                        </div>
                                        <div class="crdPdng col-lg-6 col-md-6" *ngIf="!trnsfrPnts">
                                            <h1 class="card_sub_title">NA</h1>
                                        </div>
                                    </div>
                                    <div class="row crdHdr_sb_sm">
                                        <div class="crdPdng col-lg-8 col-md-8">
                                            <h1>
                                                Covered
                                            </h1>
                                        </div>
                                        <div class="crdPdng col-lg-4 col-md-4" *ngIf="trnsfrPnts">
                                            <h1 class="card_sub_title"
                                                (click)="getDtls(trnsfrPnts,'c');scroll(target);">
                                                {{trnsfrPnts.cvrd_ct}}</h1>
                                        </div>
                                        <div class="crdPdng col-lg-4 col-md-4" *ngIf="!trnsfrPnts">
                                            <h1 class="card_sub_title">NA</h1>
                                        </div>
                                    </div>
                                    <div class="row crdHdr_sb_sm">
                                        <div class="crdPdng col-lg-8 col-md-8">
                                            <h1>
                                                Not-Covered
                                            </h1>
                                        </div>
                                        <div class="crdPdng col-lg-4 col-md-4" *ngIf="trnsfrPnts">
                                            <h1 class="card_sub_title"
                                                (click)="getDtls(trnsfrPnts,'nc');scroll(target);">
                                                {{trnsfrPnts.tot_fnce - trnsfrPnts.cvrd_ct}}</h1>
                                        </div>
                                        <div class="crdPdng col-lg-4 col-md-4" *ngIf="!trnsfrPnts">
                                            <h1 class="card_sub_title">NA</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="row">

                    <ul class="cards">
                        <li class="cards_item">
                            <ul class="topArrow">
                                <li class="topTxtSpn">
                                    <h4>20</h4>
                                    <p>Total</p>
                                </li>
                                <li class="topTxtSpn">
                                    <h4>50</h4>
                                    <p>Running</p>
                                </li>
                                <li class="topTxtSpn">
                                    <h4>80</h4>
                                    <p>Completed</p>
                                </li>
                                <li>
                                    <span class="arrow"></span>
                                </li>
                            </ul>
                            <div class="card_sw_g" style="background:linear-gradient(135deg, #ab89f7 0%,#7367f0 100%);">
                                <div class="card_content">
                                    <div class="row">
                                        <h1 class="crdHdr">Houses</h1>
                                    </div>
                                    <div class="row crdHdr_sb">
                                        <div class="crdPdng col-lg-6 col-md-6">
                                            <h1>
                                                <i nz-icon nzType="home" nzTheme="outline"></i>
                                            </h1>
                                        </div>
                                        <div class="crdPdng col-lg-6 col-md-6" *ngIf="HsDta">
                                            <h1 class="card_sub_title">{{HsDta.tot_gtes}}</h1>
                                        </div>
                                        <div class="crdPdng col-lg-6 col-md-6" *ngIf="!HsDta">
                                            <h1 class="card_sub_title">NA</h1>
                                        </div>
                                    </div>
                                    <div class="row crdHdr_sb_sm">
                                        <div class="crdPdng col-lg-8 col-md-8">
                                            <h1>
                                                Scanned Gates
                                            </h1>
                                        </div>
                                        <div class="crdPdng col-lg-4 col-md-4" *ngIf="HsDta">
                                            <h1 class="card_sub_title">{{HsDta.tot_scnd_gtes}}</h1>
                                        </div>
                                        <div class="crdPdng col-lg-4 col-md-4" *ngIf="!HsDta">
                                            <h1 class="card_sub_title">NA</h1>
                                        </div>
                                    </div>
                                    <div class="row crdHdr_sb_sm">
                                        <div class="crdPdng col-lg-8 col-md-8">
                                            <h1>
                                                Not-Scanned Gates
                                            </h1>
                                        </div>
                                        <div class="crdPdng col-lg-4 col-md-4" *ngIf="HsDta">
                                            <h1 class="card_sub_title">{{HsDta.tot_gtes - HsDta.tot_scnd_gtes}}</h1>
                                        </div>
                                        <div class="crdPdng col-lg-4 col-md-4" *ngIf="!HsDta">
                                            <h1 class="card_sub_title">NA</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="cards_item wdt-10">
                            <ul class="rightArrow_prmryTp">
                                <li class="rgtTpTxtArw">
                                    <span class="Tparrow"></span>
                                </li>
                               
                            </ul>
                        </li>
                        <li class="cards_item lft-13">
                            <ul class="btmArrow">
                                <li>
                                    
                                    <span class="arrow"></span>
                                </li>
                                <li class="btmTxtSpn">
                                    <h4>20</h4>
                                    <p>Total</p>
                                </li>
                                <li class="btmTxtSpn">
                                    <h4>12</h4>
                                    <p>Running</p>
                                </li>
                                <li class="btmTxtSpn">
                                    <h4>8</h4>
                                    <p>Completed</p>
                                </li>
                            </ul>
                            <div class="card_sw_g" style="background:linear-gradient(135deg, #f36265 0%,#b42b70 100%);">
                                <div class="card_content">
                                    <div class="row">
                                        <h1 class="crdHdr">Transfer Stations</h1>
                                    </div>
                                    <div class="row crdHdr_sb">
                                        <div class="txtCntr" *ngIf="trnsfrStns">
                                            <h3 class="card_sub_title"
                                                (click)="getDtls(trnsfrStns,'t');scroll(target);">
                                                {{trnsfrStns.tot_fnce}}</h3>
                                        </div>
                                        <div class="txtCntr" *ngIf="!trnsfrStns">
                                            <h3 class="card_sub_title">NA</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="cards_item wdt-10">
                            <ul class="rightArrow_scndryTp">
                                <li class="rgtTpTxtArw">
                                   
                                    <span class="Tparrow"></span>
                                </li>
                            </ul>
                            <ul class="rightArrow_scndryBt">
                                <li class="rgtBtTxtArw">
                                   
                                    <span class="Btarrow"></span>

                                </li>
                            </ul>
                        </li>
                        <li class="cards_item lft-13">

                            <div class="card_sw_g smCrd_m"
                                style="background:linear-gradient(135deg, #f6ab84 0%,#f38381 100%);">
                                <div class="card_content">
                                    <div class="row">
                                        <h1 class="crdHdr">Wet Processing Areas</h1>
                                    </div>
                                    <div class="row crdHdr_sb">
                                        <div class="crdPdng col-lg-6 col-md-6">
                                            <h1>
                                                <i nz-icon nzType="home" nzTheme="outline"></i>
                                            </h1>
                                        </div>
                                        <div class="crdPdng col-lg-6 col-md-6" *ngIf="prcngPnts">
                                            <h1 class="card_sub_title" (click)="getDtls(prcngPnts,'t');scroll(target);">
                                                {{prcngPnts.tot_fnce}}</h1>
                                        </div>
                                        <div class="crdPdng col-lg-6 col-md-6" *ngIf="!prcngPnts">
                                            <h1 class="card_sub_title">NA</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card_sw_g" style="background:linear-gradient(135deg, #b1ea4d 0%,#459522 100%)">
                                <div class="card_content">
                                    <div class="row">
                                        <h1 class="crdHdr">Dry Processing Areas</h1>
                                    </div>
                                    <div class="row crdHdr_sb">
                                        <div class="crdPdng col-lg-6 col-md-6">
                                            <h1>
                                                <i nz-icon nzType="home" nzTheme="outline"></i>
                                            </h1>
                                        </div>
                                        <div class="crdPdng col-lg-6 col-md-6" *ngIf="mrfPnts">
                                            <h1 class="card_sub_title" (click)="getDtls(mrfPnts,'t');scroll(target);">
                                                {{mrfPnts.tot_fnce}}</h1>
                                        </div>
                                        <div class="crdPdng col-lg-6 col-md-6" *ngIf="!mrfPnts">
                                            <h1 class="card_sub_title">NA</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>


                    </ul>
                </div>
            </div>
        </div>
        <br><br>
        <div class="row bg-wp" #target *ngIf="cnt_dtls && cnt_dtls.length>0">
            <h1>{{cnt_dtls[0].fnce_grp_nm}}&nbsp;<b *ngIf="cnt_dtls[0].cvrt_ct == 0">Not Covered</b><b
                    *ngIf="cnt_dtls[0].cvrt_ct > 0">Covered</b>Details</h1>
            <div class="ds-card grid tbl">
                <dx-data-grid class="p-8" [dataSource]="cnt_dtls" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                    [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                    (onToolbarPreparing)="true">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                        [showInfo]="true"></dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                    <dxo-group-panel [visible]="true"></dxo-group-panel>
                    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                    <dxo-column-chooser [enabled]="true" mode="select">
                    </dxo-column-chooser>
                    <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="ULB Trips Data">
                    </dxo-export>
                    <dxo-filter-row [visible]="false"></dxo-filter-row>
                    <dxi-column *ngFor="let f of cnt_dtlscolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                        [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                    </dxi-column>
                    <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons" [width]="100"
                        caption="Edit" cellTemplate="editTemplate">
                    </dxi-column>
                    <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons" [width]="100"
                        caption="Delete" cellTemplate="delTemplate">
                    </dxi-column>
                </dx-data-grid>
            </div>
        </div>
    </div> -->

</div>