import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppsService } from 'src/app/shared/services/apps.service';
// import {ToastrService } from 'ngx-toastr';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-assert-status',
  templateUrl: './assert-status.component.html',
  styleUrls: ['./assert-status.component.scss']
})
export class AssertStatusComponent implements OnInit {

  statusForm: any;
  shwschvlymDelDiv
  shwschvlymEditDiv
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs
  mainMessage
  loader
  gridData
  shwSidebar
  sidebarHeading
  delete: boolean;
  delData: any;
  editdata: any;
  shwLdng
  constructor(private location:Location, private apiSrv:AppsService,private nzMessageService: NzMessageService ) { }

  ngOnInit() {
    this.statusForm = new FormGroup({
      sts_nm: new FormControl(''),
      vndr_ph: new FormControl(''),
      dscn_tx: new FormControl(''),
      sts_cd:new FormControl(''),
      
    });
    this.getGridData();
  }
  getGridData(){

      // console.log('grid data called')
      this.mainMessage = null;
      this.loader = true;
      this.usrAcsErrMsg = false;
      this.apiSrv.get('asset/tracking/status').subscribe((res) => {
        console.log(res)
        this.loader = false;
        if (res['status'] == 404) {
          this.usrAcsErrMsg = true;
        } else if (res['status'] == 200) {
          this.USER_PERMISSIONS = res['perm'][0];
          if (this.USER_PERMISSIONS.slct_in === 1) {
            // console.log(res['data'])
            // this.user.permissions = (res['perm'] === undefined) ? this.user.permissions : res['perm'][0];
            if (res['data'].length === 0) { this.mainMessage = 'No entries found in the database.'; }
  
            this.gridData = res['data'];
            // console.log(this.gridData)
            var cont=1
            this.gridData.forEach(e => {
              e['sno']=cont++
            });
  
            this.columnDefs = [
             
              { headerName: 'S.no', field: 'sno', alignment: 'center',width:100,cellClass: 'pm-grid-number-cell' },
              { headerName: 'Status Name', field: 'sts_nm', alignment: 'left',width:250, cellClass: 'pm-grid-number-cell' },
              { headerName: 'Status Code', field: 'sts_cd', alignment: 'center',width:250, cellClass: 'pm-grid-number-cell', ortable: true, filter: true },
            ];
          
          } else {
            this.usrAcsErrMsg = true;
          }
        }else {
       }
      }, (err) => {
        this.loader = false;
      });
  }
  goBack()
  {
    this.location.back();
  }
  onToolbarPreparing(e) {
    // console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Vendor',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(opt, data)
  {
    this.shwSidebar = true;
  
    if(opt == 'edit')
    {
      this.shwschvlymEditDiv = true;
      this.shwschvlymDelDiv = false;
      this.sidebarHeading = 'Edit Vendor';
      this.statusForm.get('sts_nm').setValue(data.sts_nm)
      this.statusForm.get('sts_cd').setValue(data.sts_cd)
   
    }
    else if(opt == 'delete')
    {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = true;
      this.sidebarHeading = 'Delete Type';
    }
    else
    {
      this.shwschvlymEditDiv=false
      this.shwschvlymDelDiv=false
      this.sidebarHeading = 'Add New type';
      this.delete=false
     
    }
  }
  onDelete2(event)
  {
    this.delete = true;
    this.delData=event.data
    this.openSideBar("delete", this.delData);
  }
  onEdit2(event)
  {
    this.delete=false
    this.editdata = event.data;
    this.openSideBar("edit", this.editdata);
  }
  oncancel(): void {
    this.nzMessageService.info('Type Not Deleted');
  }
  cancel()
  {
    this.statusForm.reset()
    this.shwSidebar=false;
    this.getGridData();
  }
  saveVndr(){
    console.log(this.statusForm.value)
    let data={
      sts_nm:this.statusForm.value.sts_nm,
      sts_cd:this.statusForm.value.sts_cd

    }
    console.log(data)
    if(data.sts_nm !="" )
    {
      this.shwLdng=true
      this.apiSrv.post(data, `asset/tracking/add/status`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Type Created Successfully', '', {
          //   timeOut: 3000,
          // });
          this.cancel()
        } else {
          // this.toastr.error(res['message'], '', {
          //   timeOut: 3000
          // });
        }
      }, (err) => {
        this.shwLdng = false;
        // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
        //   timeOut: 3000
        // });
      })
    }
    else
    {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
    }
    console.log(data)

  }
  saveEditVndr(){
    let data={
      sts_nm:this.statusForm.value.sts_nm,
      sts_cd:this.statusForm.value.sts_cd
    }
    console.log(this.editdata)
    console.log(data)
    if(data.sts_nm !="" )
    {
      this.shwLdng=true
      this.apiSrv.update(data, `asset/tracking/status/${this.editdata.sts_id}`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Type Updated Successfully', '', {
          //   timeOut: 3000,
          // });
          this.cancel()
        } else {
          // this.toastr.error(res['message'], '', {
          //   timeOut: 3000
          // });
        }
      }, (err) => {
        this.shwLdng = false;
        // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
        //   timeOut: 3000
        // });
      })
    }
    else
    {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
    }

  }
  saveDeleteVndr(){
    this.shwLdng=true
    this.apiSrv.delete(`asset/tracking/status/${this.delData.sts_id}`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {
        // this.toastr.success('Type Deleted Successfully', '', {
        //   timeOut: 3000,
        // });
        this.cancel()
      } else {
        // this.toastr.error(res['message'], '', {
        //   timeOut: 3000
        // });
      }
    }, (err) => {
      this.shwLdng = false;
      // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
      //   timeOut: 3000
      // });
    })

  }


}
