<!-- <div class="page-header">
    <div class="row align-items-md-center">
            <div class="col-md-12">
                 
                    <div class="row hdrBar">
                            <div class="col-lg-12 col-md-12 mg-a">
                                <h1 *ngIf="clntDtls && clntDtls.length > 0" >{{header_name.name}}</h1>
                            </div>
                        </div>
            </div>
            <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;" >
                <span class="slctDteLbl">Select Date:</span>
                <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                    (ngModelChange)="onChange($event)" class="dte_picker"></nz-date-picker>


            </div>


            <div class>
    </div>
</div> -->


<div>{{header_name.name}}</div>