import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppsService } from 'src/app/shared/services/apps.service';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-vhcle-sachivalayam-asgnmnt',
  templateUrl: './vhcle-sachivalayam-asgnmnt.component.html',
  styleUrls: ['./vhcle-sachivalayam-asgnmnt.component.scss']
})
export class VhcleSachivalayamAsgnmntComponent implements OnInit {
  header_label = {
    name:"Vehicle sachivalayam assignment"
  }

  clntDtls: any;
  tntLst: any = [];
  selectedtenant: any;
  vendorList: any = [];
  selectedvndr: any;
  userDtls: any;
  clap_in: any;
  ShowClap: boolean;
  vhcleslst: any = [];
  showLdr: boolean;
  vhclslstcolumnDefs: any = [];
  asrtList: any;
  opensldr: boolean;
  svmLst: any[];
  svm_id: any;
  svm_apfs_cd: any;
  asrt_id: any;
  asrt_nm: any;
  slctdDt= new Date();
  curDate: string;
  svm_nm: any;
  svmRteDtlsData: any;
  constructor(private apiSrvc: AppsService,private message: NzMessageService,private modal: NzModalService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
      this.selectedvndr = 0;
      this.getvehicles();
    }
  }

  ngOnInit(): void {
    this.getvehicles();
    this.getSachivalaymList();
  }
  getSachivalaymList() {
    this.svmLst = [];
    this.apiSrvc.get(`tripschedule/tenant/${this.selectedtenant}/svmLst`).subscribe((res) => {
      if (res['status'] == 200) {
        this.svmLst = res['data'];
      }
    }, (err) => {
      console.log("In errorr");
    })
  }
  ontntSelect(val){
    this.selectedtenant=val;
    this.getSachivalaymList();
    this.getvehicles();
  }
  onsvmSelect(svm){
    this.svm_id=svm;
    this.svmLst.forEach(e => {
      if(e.svm_id==svm){
        this.svm_nm=e.svm_nm
      }
    });
    this.getsachivalayamTrips();
  }
  getsachivalayamTrips(){
    this.svmRteDtlsData=[];
    var postData = {
      svm_id: this.svm_id,
      tnt_id: this.selectedtenant,
      asrt_id: 0
        }
    this.apiSrvc.post(postData, `tripschedule/sachivalayam/trips/getRteDtls`).subscribe((res) => {
      if (res['status'] == 200) {
        this.svmRteDtlsData = res['data'];
console.log(this.svmRteDtlsData)

      }
    });
  }
  getvehicles() {
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: this.selectedtenant
    }
    this.showLdr=true;
    let rte = `drivers/vehicles/svm/list`;
    this.apiSrvc.post(postslctdDt, rte).subscribe((res) => {
      if (res['status']===200) {
        this.vhcleslst = res['data'];
        this.asrtList=this.vhcleslst;
        this.showLdr=false;
        let counter = 0;
          this.vhcleslst.filter((k) => {
            k['sno'] = ++counter;
          });
        this.vhclslstcolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
          { headerName: 'ULB Name', alignment: 'left', field: 'tnt_nm' },
          { headerName: 'Vehicle Registration Number', alignment: 'center', field: 'asrt_nm' },
          { headerName: 'IMEI Number', alignment: 'center', field: 'dev_imei_nu' },
          { headerName: 'Sachivalayam', alignment: 'left', field: 'svm_nm' },

        ]
      }
    }, (err) => {
    });
  }
  openSlider(d) {
    this.asrt_id=d.data.asrt_id;
    this.asrt_nm=d.data.asrt_nm;
    this.opensldr = true;
  }
  closeSideBar() {
    this.opensldr = false;
    this.getvehicles();
  }
  saveConfirm(){
    this.modal.confirm({
      nzTitle: 'Are you sure Do you Want to assign Trips?',
      nzContent:this.svm_nm,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => this.savevhcleTrips(),
      nzCancelText: 'No',
      nzOnCancel: () => this.cancel(),
    });
  }
  saveasgnmnt(){
    var postData = {
      asrt_id:this.asrt_id,
      tnt_id: this.selectedtenant,
      svm_id: this.svm_id,
      
    }
    this.apiSrvc.post(postData, 'drivers/vehicles/chngVhcleSachivalayam').subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Vehicle successfully Updated');
        if(this.svmRteDtlsData.length>0){
          this.saveConfirm();
        }
        this.getvehicles();
      }
      });

  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  cancel(): void {
    this.message.info('Request Canceled');
  }
  savevhcleTrips(){
    var postData = {
      nw_asrt_id:this.asrt_id,
      data:this.svmRteDtlsData,
      tnt_id:this.selectedtenant
      
    }
    this.apiSrvc.post(postData, 'drivers/vehicles/asgnSvmTripstoAsrt').subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Trips successfully Updated to Vehicle');
        this.getvehicles();
      }
      });
  }
  deletePopup(d){
    var postData = {
      asrt_id:d.data.asrt_id,
      svm_id:d.data.svm_id,
      tnt_id:this.selectedtenant
      
    }
    console.log(postData)
    this.apiSrvc.post(postData, 'drivers/vehicles/rmvSvmAsrtRel').subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'sachivalayam unassigned for vehicle');
        this.getvehicles();
      }
      });
  }
  onExporting(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Sachivalayam assigned Vehicles Data.xlsx');
      });
    });
  }
}

