<!-- <app-vehicle-slider (formEvent)="sliderEvent($event)"></app-vehicle-slider> -->
<ds-loading *ngIf="showLdr"></ds-loading>
<div class="page-header">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-12 col-md-12 mg-a">
                <h1 *ngIf="clntDtls && clntDtls.length > 0">
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>

        </div>

    </div>
</div>
<div class="pg-drvrdshbrd">
   
    <div class="main row">
        <div class="col-lg-10 col-md-10 col-sm-10 vehDsbrd">

            <div class="row fltr">
                <!-- <div class="col-lg-3 col-md-3 col-sm-3 mg-a">
                    <p class="slctlbl">Select Date</p>
                    <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                        (ngModelChange)="onChange($event)"></nz-date-picker>
                </div> -->
                <div class="col-lg-3 col-md-3 col-sm-3 mg-a" *ngIf="tntLst && tntLst.length>1">
                    <p class="slctlbl">Select {{title}}</p>
                    <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" nzPlaceHolder="Select ULB" 
                        (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}">
                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                        <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
                    </nz-select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 mg-a" *ngIf="tntLst && tntLst.length==1">
                    <p class="slctlbl">ULB</p>
                    <p class="slctlbl dsblTxt">{{tntLst[0].tnt_nm}}</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 mg-a" *ngIf="ShowClap && vendorList && vendorList.length>1">
                    <p class="slctlbl">Vehicle Vendor</p>
                    <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr"
                        (ngModelChange)="onvndrSelect($event)" [ngModelOptions]="{standalone: true}">
                        <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
                        <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
                    </nz-select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 mg-a" *ngIf="ShowClap && vendorList && vendorList.length==1">
                    <p class="slctlbl">Vehicle Vendor</p>
                    <p class="slctlbl dsblTxt">{{vendorList[0].vndr_nm}}</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 mg-a" *ngIf="vehCtgrys && vehCtgrys.length>1">
                    <p class="slctlbl">Select Vehicle Category</p>
                    <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedCtgry" nzPlaceHolder="Select Category"
                        (ngModelChange)="selectCategory($event)" [ngModelOptions]="{standalone: true}">
                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                        <nz-option *ngFor="let c of vehCtgrys" [nzLabel]=c.asrt_ctgry_nm [nzValue]=c.asrt_ctgry_id>
                        </nz-option>
                    </nz-select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 mg-a" *ngIf="vehCtgrys && vehCtgrys.length==1">
                    <p class="slctlbl">Vehicle Category</p>
                    <p class="slctlbl dsblTxt">{{vehCtgrys[0].asrt_ctgry_nm}}</p>
                </div>
            </div>


            <div class="row bg-w chart-card">
                <div class="col-lg-6 col-md-6 col-sm-6 crdBg crd">
                    <!-- <h2>Vehicle Filter</h2>
                          <br> -->
                    <h1><span>Vehicle Info</span><span>&nbsp;</span></h1>
                    <!-- <h5>({{currentFrmtDate}})</h5> -->

                    <div class="veList">
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Vehicle Registration Number</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{vehnm === null ? "NA": vehnm}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Vehicle Category</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{ctgrynm === null ? "NA": ctgrynm}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Vehicle Status</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{asrt_sts_nm === null ? "NA": asrt_sts_nm}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Distance Travelled</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{CrntDyDst === null ? "0": CrntDyDst}} Kms</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Driver Name</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4 *ngIf="VhcleDetails">{{VhcleDetails.crw_nm === null ? "Driver not assigned":
                                    VhcleDetails.crw_nm}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Driver Phone Number</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4 *ngIf="VhcleDetails">{{VhcleDetails.crw_ph === null ? "Contact Number not Provided":
                                    VhcleDetails.crw_ph}}</h4>
                            </div>
                        </div>
                        <div *ngIf="clntDtls && clntDtls[0].clnt_id==7">
                            <div class="row">
                                <div class="col-lg-5 col-md-5">
                                    <h3>Sachivalayam</h3>
                                </div>
                                <div class="col-lg-1 col-md-1 w2">:</div>
                                <div class="col-lg-5 col-md-5">
                                    <h4 *ngIf="VhcleDetails">{{VhcleDetails.svm_nm === null ? "Sachivalayam not
                                        assigned":
                                        VhcleDetails.svm_nm}}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-5 col-md-5">
                                    <h3>Secratory</h3>
                                </div>
                                <div class="col-lg-1 col-md-1 w2">:</div>
                                <div class="col-lg-5 col-md-5">
                                    <h4 *ngIf="VhcleDetails">{{VhcleDetails.ss_nm == null ? "Secratory not
                                        assigned":
                                        VhcleDetails.ss_nm}} <span *ngIf="VhcleDetails.ss_cntct"> -
                                            {{VhcleDetails.ss_cntct ==
                                            null ? "":
                                            VhcleDetails.ss_cntct}}
                                        </span></h4>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Vehicle Odometer Reading</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{vehOdmtr === null ? "0": vehOdmtr}} km</h4>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Device Odometer Reading</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{devOdmtr === null ? "0": devOdmtr}} km</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Current Speed</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{speed === null ? 0: speed}} km/Hr</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Data Last Received on</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{data_lst_rcvd === null ? '': data_lst_rcvd}}</h4>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <h3>Distance Travelled today</h3>
                            </div>
                            <div class="col-lg-1 col-md-1 w2">:</div>
                            <div class="col-lg-5 col-md-5">
                                <h4>{{dst_tvl_tdy === null ? '': dst_tvl_tdy}}</h4>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-lg-5 col-md-5" *ngIf="vehRts && vehRts.length>0 && vehRts[0].frnt_cmra_url">
                                <div class="first_tooltip">Front Camera
                                    <a target="_blank" [href]=vehRts[0].frnt_cmra_url>
                                        <i nz-icon nzType="camera" nzTheme="outline" class="icnHvr"> </i>

                                    </a>
                                    <span class="first_tooltip_text">Front Camera</span>
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-1 w2"></div>
                            <div class="col-lg-5 col-md-5" *ngIf="vehRts && vehRts.length>0 && vehRts[0].bck_cmra_url">
                                <div class="first_tooltip">Rear Camera
                                    <a target="_blank" [href]=vehRts[0].bck_cmra_url>
                                        <i nz-icon nzType="camera" nzTheme="outline" class="icnHvr"> </i>

                                    </a>
                                    <span class="first_tooltip_text">Rear Camera</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 crdBg">
                    <div id="vehicleMap"></div>
                </div>
            </div>

            <div class="row bg-w chart-card">
                <div class="col-lg-12 col-md-12 crd">
                    <h1>Last 7days 24/7 Distance covered by vehicle</h1>
                    <div id="hepachartdiv"></div>
                </div>
            </div>

            <div class="row bg-w chart-card">
                <div class="col-lg-6 col-md-6 w-50 crd" style="margin-right: 24px;">
                    <h1>Last 7Days Distance Travelled</h1>
                    <div *ngIf="!chart1Msg">
                        <div id="lastSevenDaysDistanceChart"></div>
                    </div>
                    <div *ngIf="chart1Msg" style="text-align: center;">
                        Distance not found for this vehicle
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 w-50 crd">
                    <h1>Last 7Days Trips Travelled</h1>
                    <div *ngIf="!chart2Msg">
                        <div id="lastSevenDaysChart"></div>
                    </div>
                    <div *ngIf="chart2Msg" style="text-align: center;">
                        No trips defined for this vehicle
                    </div>
                </div>
            </div>

            <!-- ---------------------------------------------------------------------------------------- -->



            <div class="row bg-w chart-card" style="border-top: 2px solid #f1f1f1;">
                <div class="col-lg-6 col-md-6 w-50 crd bxshw_n" style="margin-right: 24px;">
                    <h1>Operational Details In Last 7Days</h1>
                    <div *ngIf="oprtnlhrs && oprtnlhrs.length>0">
                        <table class="cstm_tbl">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Vehicle No.</th>
                                    <th>Vehicle Category</th>
                                    <th>Operational Hours</th>
                                    <th>Travelled(km)</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let o of oprtnlhrs">
                                    <td>{{o.dt}}</td>
                                    <td>{{o.asrt_nm}}</td>
                                    <td>{{o.asrt_ctgry_nm}}</td>
                                    <td>{{o.oprtn_hrs_ct}}</td>
                                    <td>{{o.dst}}</td>
                                    <td *ngIf="o.operated == 1" style="color: #009688;">Operated</td>
                                    <td *ngIf="o.operated == 0" style="color: #9c27b0;">Not-Operated</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row NtfctnCrd" *ngIf="oprtnlhrsMsg">
                        <h5 style="text-align: center;">{{oprtnlhrsMsg}}</h5>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 w-50 crd bxshw_n">
                    <h1 style="padding-left: 20px;">Last 7Days Speed Violation</h1>
                    <div class="row" *ngIf="ovrSpeed && ovrSpeed.length>0">
                        <table class="cstm_tbl">
                            <thead>
                                <tr>
                                    <th>Recorded Date</th>
                                    <th>Vehicle No.</th>
                                    <th>Vehicle Category</th>
                                    <th>Speed Limit</th>
                                    <th>Speed(km)</th>
                                    <th>Track Vehicle</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let t of ovrSpeed">
                                    <td>{{t.dt}}</td>
                                    <td>{{t.asrt_nm}}</td>
                                    <td>{{t.asrt_ctgry_nm}}</td>
                                    <td>{{t.max_spd_lmt_ct}}</td>
                                    <td>{{t.speed}}</td>
                                    <td><a href="https://www.google.com/maps/search/?api=1&query={{t.f_lat}},{{t.f_lng}}"
                                            target="_blank"><i nz-icon nzType="node-index"
                                                nzTheme="outline"></i>Track</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row NtfctnCrd" *ngIf="ovrSpeedMsg">
                        <h5 style="text-align: center;">{{ovrSpeedMsg}}</h5>
                    </div>
                </div>
            </div>

            <div class="row bg-w chart-card" style="border-top: 2px solid #f1f1f1;">
                <div class="col-lg-6 col-md-6 w-50 crd bxshw_n" style="margin-right: 24px;">
                    <h1>Last 7Days Trips List <b *ngIf="eachVeh && eachVeh.length">{{eachVeh[0].asrt_nm}}</b></h1>
                    <div *ngIf="eachVeh && eachVeh.length>0">
                        <table class="cstm_tbl">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>No. of Trips</th>
                                    <th>Driver</th>
                                    <th>Travelled(km)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let e of eachVeh">
                                    <td>{{e.dt}}-{{curYr}}</td>
                                    <td>{{e.trp_cnt}}</td>
                                    <td>{{e.crw_nm ? o.crw_nm : 'Driver not Assigned'}}</td>
                                    <td>{{e.trp_dst}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row NtfctnCrd" *ngIf="eachVeh && eachVeh.length==0">
                        <h5 style="text-align: center;">{{chart2Msg}}</h5>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 w-50 crd bxshw_n">
                    <h1 style="padding-left: 20px;">Last 10 Vehicle Events <b
                            *ngIf="previousNotifications && previousNotifications.length>0">{{previousNotifications[0].asrt_nm}}</b>
                    </h1>
                    <div class="row" *ngIf="previousNotifications && previousNotifications.length>0">
                        <table class="cstm_tbl">
                            <thead>
                                <tr>
                                    <th>Fence</th>
                                    <th>Fence Group</th>
                                    <th>Fence Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let t of previousNotifications">
                                    <td>{{t.fnce_nm}}</td>
                                    <td>{{t.fnce_grp_nm}}</td>
                                    <td>{{t.fnce_out_ts}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row NtfctnCrd" *ngIf="previousNotificationsMsg">
                        <h5 style="text-align: center;">{{previousNotificationsMsg}}</h5>
                    </div>
                </div>
            </div>

            <div class="row bg-w chart-card" style="border-top: 2px solid #f1f1f1;">
                <div class="col-lg-12 col-md-12 crd bxshw_n" style="margin-right: 24px;">
                    <h1>Recently visited locations</h1>
                    <div *ngIf="vstdLctns && vstdLctns.length>0">
                        <table class="cstm_tbl">
                            <thead>
                                <tr>
                                    <th>Location name</th>
                                    <th>Location Group</th>
                                    <!-- <th>Category</th> -->
                                    <th>Time-In</th>
                                    <th>Time-Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let o of vstdLctns">
                                    <td>{{o.fnce_nm}}</td>
                                    <td>{{o.fnce_grp_nm}}</td>
                                    <!-- <td>{{o.fnce_ctgry_nm}}</td> -->
                                    <td>{{o.time_in}}</td>
                                    <td>{{o.time_out}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row NtfctnCrd" *ngIf="vstdLctns && vstdLctns.length==0">
                        <h5 style="text-align: center;">{{vstdLctnsMsg}}</h5>
                    </div>
                </div>

            </div>
            <!-- ---------------------------------------------------------------------------------------- -->
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 vhLstscrl">
            <p class="hdrVeh">Vehicle List<b *ngIf="ttl_veh && ttl_veh.length"> - {{ttl_veh.length}}</b></p>
            <div class="list-container vehLstCard" nz-scroll style=" max-height:100%; overflow-y: auto; ">
                <nz-input-group>
                    <input type="text" class="srchInpt" nz-input [(ngModel)]="searchText" placeholder="Search here"
                        [ngModelOptions]="{standalone: true}" />
                </nz-input-group>
                <nz-list nzItemLayout="horizontal" [nzLoading]="loading" class="list_section">
                    <nz-list-item *ngFor="let item of ttl_veh | filterasrt:searchText" (click)="selectVehicle(item)"
                        [ngClass]="item.apply_actv == 1 ? 'list_item_actv' : 'list_item'">
                        <div class="row" style="margin: 0px;width: 100%;">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <h1>{{item.asrt_nm}}</h1>
                                <h2>{{item.ulb_nm}}</h2>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <h3>{{item.asrt_sts_nm}}</h3>
                            </div>
                        </div>
                    </nz-list-item>
                    <nz-list-empty *ngIf="ttl_veh && ttl_veh.length === 0"></nz-list-empty>
                </nz-list>
                <div class="row NtfctnCrd" *ngIf="ttl_vehMsg">
                    <h5 style="text-align: center;">{{ttl_vehMsg}}</h5>
                </div>
            </div>

        </div>
    </div>
</div>