<div class="m-grid__item m-grid__item--fluid m-wrapper">
	<div class="m-subheader ">
		<div class="d-flex align-items-center">
			<div class="mr-auto">
				<h3 class="m-subheader__title m-subheader__title--separator rptHdng rptHsrLblTxt">
					User Fee Report
				</h3>

				<div class="btn-group rptBtnGrp">
					<button type="button" class="btn-sm rptfltrCrdBtn shwFltrBtn" (click)="shw_fltrs()" >
						{{filterslabel}}</button>
					<button type="button" class="btn-sm rptfltrCrdBtn exclBtn" ng-csv="excelDownload"
						filename="USER_FEE_RPT.csv">Excel</button>
					<button type="button" class="btn-sm rptfltrCrdBtn pdfBtn" >PDF</button>
				</div>
			</div>
		</div>
	</div>
	<div class="m-content rpts_m-content">
		<div class="row">
			<div class=" rptFltrsCrd" *ngIf ="displayfilters">
				<div class="row">
					<div class="col-xl-11 col-lg-12 col-md-12 col-sm-11 col-11">
						<div class="row">
							<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
								<div class="item item-divider lblPos  ">Officer Name</div>

								<div class="rptDrpDwn shdlIntrfcDrpDwn" search-filter="clctionagntFilter"
									ng-dropdown-multiselect="" options="colctnagcnts" selected-model="clctnagntModel"
									extra-settings="clctnagntSettings" translation-texts="clntagntTextSettings"
									type="text">
								</div>
							</div>

							<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
								<div class="item item-divider lblPos  ">Category</div>
								<div class="rptDrpDwn shdlIntrfcDrpDwn" search-filter="apptypeFilter"
									ng-dropdown-multiselect="" options="apdntyps" selected-model="apptypeModel"
									extra-settings="apptypeSettings" translation-texts="apptypeTextSettings"
									type="text">
								</div>
							</div>
							<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
								<div class="item item-divider lblPos  ">Offender phone number</div>
								<input class="form-control m-input flInpt ng-valid ng-dirty" type="text" ng-model="phno"
									placeholder="Enter 10 Digit Phone Number">
							</div>

							<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
								<div class="item item-divider lblPos  ">Wards</div>

								<div class="rptDrpDwn shdlIntrfcDrpDwn" search-filter="wardFilter"
									ng-dropdown-multiselect="" options="wrds" selected-model="wardModel"
									extra-settings="wardSettings" translation-texts="wardTextSettings" type="text">
								</div>
							</div>

							<!-- <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
											<div class="item item-divider lblPos ">Wards</div>
											<select ui-select2="select2Options" class="execCstmrFrmDrpDwn htMpdrpDwn slctCntnrLt vehMstrDataInput" data-placeholder="Select Group"
											 ng-model="slctdWrd" class="form-control input-sm">
												<option value='0'>All</option>
												<option ng-repeat="w in wrds" value={{w}}>{{w}}</option>
											</select>
										</div> -->
							<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
								<div class="item item-divider lblPos">From Date</div>
								<div class="input-group date">
									<input type="text" id="m_datepicker_2" name="efft1"
										class="form-control m-input flInpt flFrmDtInpt" ng-model="dtFrom" readonly
										placeholder="Select date" />
									<div class="input-group-append"></div>
								</div>
							</div>
							<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
								<div class="item item-divider lblPos">To Date</div>
								<div class="input-group date">
									<input type="text" id="m_datepicker_3" name="efft2"
										class="form-control m-input flInpt flFrmDtInpt" ng-model="dtTo" readonly
										placeholder="Select date" />
									<div class="input-group-append"></div>
								</div>
							</div>










						</div>
					</div>
					<div class="col-xl-1 col-lg-12 col-md-12 col-sm-12 col-12">
						<div class="row rptBtnRw">
							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<button type="button" class="btn-sm rptfltrCrdBtn rptBtnGtDta"
									(click)="getData()">Get Data</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="card rptCrd">
				<div class="ntAvlb" ng-if="ntAvlb">
					<!-- <img src="{{ntAvlbMsg}}"> -->
					<p class="rptNoDtaTxt">No distance recorded</p>
				</div>
				<div class="card-header rptCrdHdr" ng-if="!ntAvlb">
					<div class="row">
						<div class="col-xl-10 col-lg-10 col-md-10 col-sm-9 col-12"></div>
						<div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-12">
							<div class="inputs">
								<div class="portlet-input input-inline input-small ">
									<div class="input-icon right">
										<div class="m-input-icon m-input-icon--right">
											<input type="text"
												class="form-control form-control-solid input-circle rptCrdSrchLbl"
												placeholder="Search..." ng-model="filter.term">
											<span class="m-input-icon__icon m-input-icon__icon--right">
												<span>
													<i class="la la-search m--font-brand rptCrdSrchIcn"></i>
												</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<!-- <div class="card-body locInOtrptCrdBdy">
					<div ng-show="showLoader">
						<div
							class="btn m-btn btn-success m-btn--custom m-loader m-loader--light m-loader--center rptLdrImg">
						</div>
					</div> -->
					<div class="table-responsive">
						<!-- <p class="ttlCss">&nbsp;&nbsp;Total Collected Amount : <span style="color: #d92997">{{ttl_clctd_amnt}}  &#x20b9;</span></p> -->
						<table ng-table="tableFilter" class="table table-bordered table-hover rptTbl" ng-if="!ntAvlb">
							<tr class="rptTblHdngRw">
								<th>Sno</th>
								<th>Date</th>
								<th>Ward Number</th>
								<th>Micro Pocket No</th>
								<th>House No</th>
								<th>Officer Name</th>
								<th>Receipt Number </th>
								<th>Name of Waste Generator</th>
								<th>Category</th>
								<th>User Fee Amount</th>
								<th>Phone Number</th>
								<th>Address</th>
								<th>Challan No</th>
								<th>Challan Date</th>
							</tr>
							<tr *ngFor = "let dtls of usrfeedtls" class="rptTblDtaRw">
								<!-- <td data-title="'Sno'" class="rptTblDtaSrlNo">
									{{ ((tableFilter.page() * tableFilter.count()) + $index+1) - tableFilter.count() }}
								</td> -->
								<td>{{dtls.fn_date}}</td>
								<td>{{dtls.wrd_nu}}</td>

								<td>{{dtls.micro_pkt}}</td>
								<td>{{dtls.hse_nu}}</td>

								<td>{{dtls.ofcr_nm}}</td>
								<td>{{dtls.recept_no}}</td>
								<td>{{dtls.nme }}</td>
								<td>{{dtls.ctgry_nm}}</td>
								<td>{{dtls.amnt}}</td>
								<td>{{dtls.mble_ph}}</td>
								<td>{{dtls.address}}</td>
								<td>{{dtls.chalana_no}}</td>
								<td>{{dtls.chalana_dt}}</td>
							</tr>
							<tfoot style="    background: #8cacbb;
							font-weight: bold;
							color: black;">
								<tr>
									<td colspan="8" style="font-weight: bold; font-size: 14px">
										Total No.of User Charges: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<span style="color: #9a0d38;
										font-size: 14px"></span>
									</td>
									<td style="font-weight: bold; font-size: 14px">Total Amount:</td>
									<td style="font-weight: bold; color: #9a0d38;
									font-size: 14px;"> &#x20b9;</td>
									<td colspan="5" style="font-weight: bold; font-size: 14px">Total Collected Amount
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<span style="color: #9a0d38;
										font-size: 14px;"> &#x20b9;</span>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<p class="fnsRptNoDtaMsg" ng-if="getfncnts.length==0">No Data Available</p>
				</div>
			</div>
		</div>
	</div>
