<nz-drawer [nzWidth]="'90%'" [nzClosable]="true" [nzVisible]="visibleDriverSldr" nzPlacement="right"
  nzTitle="Driver Dashboard" (nzOnClose)="visibleDriverSldrclse()" class="dashbSlider">
  <ng-container *nzDrawerContent>
    <app-driver-slider [driver]="selectedDriver" *ngIf="selectedDriver"></app-driver-slider>
  </ng-container>
</nz-drawer>

<ds-loading *ngIf="showLdr"></ds-loading>

<div class="page-header">
  <div class=" align-items-md-center">
 <div class="row hdrBar">
                          <div class="col-lg-8 col-md-8">
                              <h1 *ngIf="clntDtls && clntDtls.length > 0" >
                                  <app-common-header [header_name] ="header_label" ></app-common-header>
                              </h1>
                          </div>
                          <div class="col-lg-4 col-md-4" style="text-align: right;" >
                            <button nz-button nzType="primary" class="adddrvrBtn" (click)="opendrvrfrm()">Add New Driver</button>
                           </div>
                      </div>
          
</div>
</div>


<div class="maindv">
  <div class="fltrbg">
    <div class="row">

      <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst.length>1">
        <p class="slctlbl">ULB</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
          nzPlaceHolder="Select ULB" [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
          <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
        </nz-select>
      </div>
      <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst && tntLst.length==1">
        <p class="slctlbl">ULB</p>
        <p class="slctlbl dsblTxt">{{tntLst[0].tnt_nm}}</p>
      </div>
      <div class="col-lg-2 col-md-2 crdCl" *ngIf="ShowClap">
        <p class="slctlbl">Vehicle Vendor</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
          [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
          <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
        </nz-select>
      </div>
      <div class="col-lg-2 col-md-2 crdCl">
        <p class="slctlbl">Search Driver</p>
        <nz-input-group>
          <input type="text"
            style="height: 32px;border-radius: 3px !important; background: linear-gradient(135deg, #ffffff 0%, #e7eaec 100%);border: 1px solid #d9d9d9;"
            nz-input [(ngModel)]="searchText" placeholder="Search here" [ngModelOptions]="{standalone: true}" />
        </nz-input-group>
      </div>
      <div class="col-lg-2 col-md-2 crdCl">
        <p class="slctlbl">Change View</p>
        <div class="crdndtblVw"> <span>Cards View</span>
          <nz-switch [(ngModel)]="switchValue" [ngModelOptions]="{standalone: true}" (click)="gridview()"
            class="tgleswtch"></nz-switch>
          <span>Table View</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 crdCl">
        <p class="slctlbl">Export from here</p>
        <div class="btnsgrp">
          <button nz-button nzType="default" class="excelbtn" (click)="export()"><i nz-icon nzType="file-excel"
              nzTheme="outline"></i>Excel</button>
          <!-- <button nz-button nzType="default" class="pdfbtn"><i nz-icon nzType="file-pdf"
              nzTheme="outline"></i>Pdf</button> -->
        </div>
      </div>
    </div>
  </div>

  <div style="padding: 5px;">
    <div class="row" *ngIf="crdsview">
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6" *ngFor="let d of drvrslst | filter:searchText">
        <div class="card_g">
          <div class="card_content">
            <div class="row">
              <div class="col-lg-11 col-md-11" style="padding: 0px;">
                <h1 class="crdHdr" [ngStyle]="d.clr_obj.clr ? {'color': d.clr_obj.clr} : {'color': '#424242'}">
                  {{d.crw_nm}}</h1>
              </div>
              <div class="col-lg-1 col-md-1" style="padding: 0px;" (click)="opendrvrlst(d)">
                <i nz-icon nzType="edit" nzTheme="outline" class="icnHvr"></i>
              </div>
            </div>
            <div class="row crdHdr_sb_sm">
              <div class="col-lg-3 col-md-3 usr"
                [ngStyle]="d.clr_obj.clr ? {'background': d.clr_obj.bgClr} : {'background': '#f5f5f5'}">
                <i nz-icon nzType="user" nzTheme="outline"
                  [ngStyle]="d.clr_obj.clr ? {'color': d.clr_obj.clr} : {'color': '#424242'}"></i>
              </div>
              <div class="col-lg-9 col-md-9 usrDtls">
                <div class="row">
                  <div class="col-lg-2 col-md-2 mg-a">
                    <i nz-icon [nzType]="'idcard'" [nzTheme]="'outline'"></i>
                  </div>
                  <div class="col-lg-10 col-md-10 mg-a">
                    <h1 class="card_sub_title">{{(!d.crw_lcnse_nu || d.crw_lcnse_nu == "undefined" || d.crw_ph == null)
                      ? 'Licence Not Provided': d.crw_lcnse_nu}}</h1>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-2 col-md-2 mg-a">
                    <i nz-icon [nzType]="'calendar'" [nzTheme]="'outline'"></i>
                  </div>
                  <div class="col-lg-10 col-md-10 mg-a">
                    <h1 class="card_sub_title">{{(!d.doj || d.doj == "00-00-0000" || d.doj == null) ? 'DOJ Not Provided'
                      : d.doj}}
                    </h1>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-2 col-md-2 mg-a">
                    <i nz-icon [nzType]="'phone'" [nzTheme]="'outline'" [nzRotate]="90"></i>
                  </div>
                  <div class="col-lg-10 col-md-10 mg-a">
                    <h1 class="card_sub_title">{{(!d.crw_ph || d.crw_ph == 0 || d.crw_ph == null) ? 'Mobile Number Not
                      Provided': d.crw_ph}}
                    </h1>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-2 col-md-2 mg-a">
                    <i nz-icon [nzType]="'car'" [nzTheme]="'outline'"></i>
                  </div>
                  <div class="col-lg-10 col-md-10 mg-a">
                    <h1 class="card_sub_title">{{(!d.asrt_nm || d.asrt_nm == "undefined" || d.asrt_nm == null)
                      ? 'No Vehicle Assigned For this Driver': d.asrt_nm}}
                    </h1>
                  </div>
                </div>

                <div class="row" (click)="gotoDrvrSldr(d)" style="cursor: pointer;">
                  <div class="col-lg-2 col-md-2 mg-a">
                    <i nz-icon [nzType]="'dashboard'" [nzTheme]="'outline'"></i>
                  </div>
                  <div class="col-lg-10 col-md-10 mg-a">
                    <h1 class="card_sub_title">Quick view</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


















        <!-- <div class="driverCrds">
          <div (click)="opendrvrlst(d)">
            <div class="drvrName">{{d.crw_nm}}</div>
            <div class="inrcntnt">
              <div class="row">
                <div class="col-md-9">
                  <div class="licenceno">
                    <i nz-icon [nzType]="'idcard'" [nzTheme]="'outline'"></i>
                    Licence No: <span class="bldFnt">{{d.crw_lcnse_nu == "undefined" ? '': d.crw_lcnse_nu}}</span>
                  </div>
                  <div class="dteofjn">
                    <i nz-icon [nzType]="'calendar'" [nzTheme]="'outline'"></i>
                    DOJ:<span class="bldFnt">{{d.doj == "00-00-0000" ? '' : d.doj}}</span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <div class="drvrimg" [ngStyle]="{'background': getRandomColor()}" style="opacity: .5;">
                    <img src="assets/images/delivery-man.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ftrcntnt">
            <div class="row">
              <div class="col-md-6 brdrgt">
                <div class="vhcleDshbrd">
                  <a href="internal/dashboard"> <i nz-icon [nzType]="'dashboard'" [nzTheme]="'outline'"></i> Drivers
                    Dashboard</a>
                </div>
              </div>
              <div class="col-md-6">
                <div class="drvrphno">
                  <i nz-icon [nzType]="'phone'" [nzTheme]="'outline'" [nzRotate]="90"></i>
                  {{d.crw_ph == 0 ? '': d.crw_ph}}
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="380" [nzVisible]="visible"
        nzTitle="Driver Details" (nzOnClose)="closedrvrlst()">
        <!-- [nzFooter]="footerTpl" -->
        <ng-container *nzDrawerContent>
        <form nz-form [formGroup]="driverform" class="drvrDtlsfrm">
          <div nz-row [nzGutter]="8">
            <div nz-col nzSpan="24">
              <nz-form-label>Driver Staff ID</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input formControlName="drvr_stf_id" placeholder="staff ID" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-label>Driver Name</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input formControlName="drvr_nm" placeholder="Driver name" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-label>Driver Phone No</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input formControlName="drvr_phn_no" placeholder="Phone Number" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-label>Licence Number</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input formControlName="drvr_lcnce_nm" placeholder="Licence Number" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-label>Date of Join</nz-form-label>
              <nz-form-item>
                <nz-date-picker formControlName="dt_of_jn" nzFormat="dd-MM-yyyy"></nz-date-picker>
              </nz-form-item>
            </div>

          </div>



        </form>
      </ng-container>
        <!-- <ng-template #footerTpl>
        <div > -->
        <div class="btngrp">
          <button nz-button style="margin-right: 8px;" (click)="closedrvrlst()" nzType="primary"
            nzDanger>Cancel</button>
          <button nz-button (click)="subDrvrlst()" class="sbmtbtn">Submit</button>
        </div>
        <!-- </div>
      </ng-template> -->
      </nz-drawer>


    </div>



    <!-- <div class="row" *ngIf="tblview">
      <div class="col-md-12">
        <nz-table #middleTable nzSize="middle" class="drvrlstTbl" [nzData]="drvrslst" [nzPageSize]="20">
          <thead>
            <tr>
              <th>Sno</th>
              <th>Driver Name</th>
              <th>Licence No</th>
              <th>DOJ</th>
              <th>Mobile No</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of drvrslst | filter:searchText let i=index">
              <td>{{i + 1}}</td>
              <td>{{d.crw_nm}}</td>
              <td>{{d.crw_lcnse_nu}}</td>
              <td>{{d.doj}}</td>
              <td>{{d.crw_ph}}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div> -->


    <div class="ds-card grid tbl dwntbl drvrsCmpnt" *ngIf="tblview">
      <dx-data-grid class="p-8" [dataSource]="drvrslst" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true"
        [showBorders]="true" style="width:100%" (onToolbarPreparing)="true">
        <dxo-paging [pageSize]="10" [enabled]="true"></dxo-paging>
        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
          [showInfo]="true"></dxo-pager>

        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Drivers List Data"></dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxi-column *ngFor="let d of drvrslstcolumnDefs" [visible]="!d.hide" [dataField]="d.field"
          [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" [allowResizing]="true"></dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
          cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="tblEdtBtn" (click)="gotoSldr(data)">
              <i nz-icon [nzType]="'dashboard'" [nzTheme]="'outline'" style="font-size: 11px;"></i>
              &nbsp;&nbsp;<span style="font-size: 11px;text-transform:capitalize; font-weight: 500;">View</span>
            </div>
          </div>
        </dxi-column>
        <!-- <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons"
          [width]="100" caption="Edit" cellTemplate="editTemplate">
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons"
          [width]="100" caption="Delete" cellTemplate="delTemplate">
        </dxi-column> -->
      </dx-data-grid>
    </div>






  </div>



  <!-- sidebar service -->
  <div class="adnewdrvr" *ngIf="shwdrvrfrm">
    <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="380" [nzVisible]="visible"
      nzTitle="Driver Details" (nzOnClose)="closedrvrlst()">
      <!-- [nzFooter]="footerTpl" -->
      <form nz-form [formGroup]="addrvrfrm" class="drvrDtlsfrm">
        <div nz-row [nzGutter]="8">
          <div nz-col nzSpan="24">
            <nz-form-label>Name</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <input nz-input formControlName="drvrnm" placeholder="Enter Driver Name" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-label>Phone Number</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <input nz-input formControlName="phnmb" placeholder="Enter Phone Number" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="24">
            <nz-form-label>ID Number</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <input nz-input formControlName="drvrid" placeholder="Enter Driver Id" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="24">
            <nz-form-label>Licence Number</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <input nz-input formControlName="lcncnmb" placeholder="Enter Driver Licence Id" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="24">
            <nz-form-label>Date of Join</nz-form-label>
            <nz-form-item>
              <nz-date-picker formControlName="doj" nzFormat="dd-MM-yyyy"
                placeholder="Select Date of Joining"></nz-date-picker>
            </nz-form-item>
          </div>

        </div>



      </form>

      <!-- <ng-template #footerTpl>
          <div > -->

      <div class="btngrp">
        <button nz-button style="margin-right: 8px;" (click)="bckbtn()" nzType="primary" nzDanger>Cancel</button>
        <button nz-button (click)="addDrvr()" class="sbmtbtn">Save Driver</button>
      </div>
      <!-- </div>
        </ng-template> -->
    </nz-drawer>
  </div>



</div>