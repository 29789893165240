import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as _ from 'lodash'
import { filter, takeUntil } from 'rxjs/operators';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  header_label = {
    name : "Reports"
  }
  // searchText = '';
  rptCtgryLst = [];
  breakpoint: number;
  empGrdData: any;
  ctgrylst = [];
  rptLst = [];
  smryRpts = [];
  othrRpts = [];
  rptGteLst = [];
  chckd = [];
  usrLgnDtls: any;
  hdrDta: any;
  searchText = '';
  loader: boolean;
  headerDtls
  filter;
  getHeaderDtls = function () { return { "title": "MIS-REPORTS", "icon": "list_alt" } }
  mrcht_usr_id: number;
  isFolded: boolean ;
  isSideNavDark: boolean;
  isExpand: boolean;
  selectedHeaderColor;
  constructor(private apiSrvc: AppsService, private router: Router, private themeService: ThemeConstantService) {
   }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getCtgryLst();
    // this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
    this.getReprtLst();
    
  }

  // onResize(event) {
  //   this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  // }

  getCtgryLst = () => {
    this.apiSrvc.get('reports/ctgrylst').subscribe((res) => {
      this.ctgrylst = res['data']
   //   console.log(this.ctgrylst)
      this.ctgrylst.filter((k) => {
        k.chk_ind = true;
      })
    }, (err) => {
    // console.log(err);
    })
  }

  changeCtgry(e){
 //   console.log(e);
 //   console.log( this.rptLst);
    this.ctgrylst.filter((k) => {
      if(k.rpt_ctgry_id == e.rpt_ctgry_id){
        k.chk_ind = true;
      }
      else{
        k.chk_ind = false;
      }
      this.rptLst.filter((g) => {
        if (g.opts) {
          g.opts.filter((r) => {
            if (k.rpt_ctgry_id == r.rpt_ctgry_id) {
              r.rpt_shw_in = k.chk_ind;
            }
          })
        }
      })
    })
    _.orderBy(this.rptLst, ['grp_nm', 'grp_nm'], ['asc', 'asc']);
 //   console.log( this.rptLst);
  }

  getReprtLst = () => {
    this.loader = true;
    // this.mrcht_usr_id=1;
    const rte = `reports/rprtlst`;
    this.apiSrvc.get(rte).subscribe((res) => {
    // console.log(res)
      if (res['status'] == 200) {
        this.loader = false;
        var obj = { "opts": [] };
        for (let i = 0; i < res['data'].length; i++) {
          for (let j = 0; j < res['data'][i].opts.length; j++) {
            res['data'][i].opts[j]['grp_nm'] = res['data'][i].opts[j].rpt_nm;
          }
        }
        this.rptLst = res['data'];
        this.rptLst.filter((k) => {
          if (k.opts) {
            k.opts.filter((r) => {
              r.rpt_shw_in = true;
              _.assign(r, obj);
            })
          }
        })
     //   console.log(this.rptLst);
        // _.orderBy(this.rptLst, ['grp_nm', 'grp_nm'], ['asc', 'asc']);
        // this.rptLst.filter((k) => {
        //   k.opts = _.orderBy(k.opts, ['grp_nm', 'cmplt_in'], ['desc', 'desc']);
        // })
      }
    }, (err) => {
   //   console.log(err);
    })
  }

  uniqueArray(arrArg) {
  // console.log(arrArg)
    return arrArg.filter(function (elem, pos, arr) {
      return arr.indexOf(elem) === pos;
    });
  }

  gotoRpt = function (rp, data) {
    
  console.log(rp);
  console.log(data)
  // console.log(rp.rpt_id);
  console.log('/internal/reports/list/custom/' + rp.rpt_url_tx)
      this.router.navigate(['/internal/reports/list/custom/' + rp.rpt_url_tx], { queryParams: {} })

      // this.router.navigate(['reports/custom/' + rp.rpt_id], { queryParams: {} })
    

    // else if (rp.rpt_typ_id == 3) {
    //   this.router.navigate(['/admin/custom-monthly/' + data.rpt_id + '/'], { queryParams: { "prmsdata": data }, skipLocationChange: true });
    //   window.history.pushState('', '', '/admin/custom-monthly/' + data.rpt_id + '/');
    // }
  }


}
