import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';


@Component({
  selector: 'app-houses',
  templateUrl: './houses.component.html',
  styleUrls: ['./houses.component.scss']
})
export class HousesComponent implements OnInit {

  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  hseLst: any;
  columnDefs;
  shwSidebar = false;
  hseNm: string | null = null;
  shwstsAlrt = false;
  shwEditDiv = false;
  gteId: any;
  hseDt: any = {};
  sidebarHeading: string;
  shwDelDiv: boolean;
  shwLdg: boolean;
  houseForm;
  schvlymlst: any;
  ulb_id: string;
  svm_id: any;
  clusterlst: any;
  hseCtgry: any;
  hseStatus: any;
  gteData: any;
  gte_id: any;
  gte_nm;
  wards: any;
  filteredsvmOptions: any = [];
  svms: any = [];
  editdata;
  deldata;
  ulbs: any = [];
  shwalert: boolean = false;
  hseId: any;
  cntactForm;
  shwEditCntctDiv: boolean = false;
  shwDelCntctDiv: boolean = false;
  // addDtls:boolean= false;
  edtHse: boolean = false;
  Ownerdta: any = [];
  editCntctdata;
  delCntctdata;




  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.getHseLst();

    this.houseForm = new FormGroup({
      gte_nm: new FormControl(''),
      ple_ct: new FormControl(''),
      ulb_id: new FormControl(''),
      svm_id: new FormControl(''),
      tu_id: new FormControl(''),
      dr_nu: new FormControl(''),
      flr_nu: new FormControl(''),
      hse_sts_id: new FormControl(''),
      hse_ctgry_id: new FormControl(''),
      // asmnt_nu:new FormControl('')

    });
    this.cntactForm = new FormGroup({
      frst_nm: new FormControl(''),
      lst_nm: new FormControl(''),
      mbl_nu: new FormControl(''),
      ownr: new FormControl(''),


    });
    this.getUlbs();
    this.getOwnDtls();

  }

  getHseLst() {
    this.shwLdg = true;
    const rte = 'admin/houses';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
          this.shwLdg = false;
          if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.hseLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 80, filter: false },
            // { headerName: 'House Number', field: 'gte_hse_nu', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'Door Number', field: 'dr_nu', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Floor Number', field: 'flr_nu', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 120 },
            { headerName: 'Total Population', field: 'ple_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 120 },
            { headerName: 'ULB', field: 'ulb_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            // { headerName: 'Ward', field: 'wrd_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            // { headerName: 'Street', field: 'strt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 250 },
            { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'Territorial Unit', field: 'tu_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'House Type', field: 'hse_ctgry_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'House Status', field: 'hse_sts_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'Head of the house', field: 'fst_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'contact Number', field: 'mble_ph', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150 },
            // { headerName: 'Lattitude', field: 'lat', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            // { headerName: 'Longitude', field: 'lng', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    });
  }


  getUlbs() {
    this.apiSrv.get('/admin/ulbs/list').subscribe((res) => {
      this.ulbs = res['data'];
    })
    this.getscvlym();
    this.gethseCtgry();
    this.gethseStatus();

  }
  getGateid() {
    var gte_nm = this.houseForm.value.gte_nm;
    //   console.log(gte_nm);
    if (this.gte_nm !== '') {

      const rte = `/admin/gate/by-name/${gte_nm}`
      this.apiSrv.get(rte).subscribe((res) => {
        this.gteData = res['data'];
        if (res['data'].length != 0) {
          this.shwalert = false;
          this.gteId = this.gteData[0].gte_id;
          this.hseDt.gte_id = this.gteId;
        } else {
          this.shwalert = true;
        }
      });
    }

  }

  getWards() {
    this.ulb_id = this.houseForm.value.ulb_id;
    this.shwLdg = true;
    const rte = `wards/list/${this.ulb_id}`;
    this.apiSrv.get(rte).subscribe(res => {
      this.wards = res['data'];
    });
  }
  getscvlym() {
    this.ulb_id = this.houseForm.value.ulb_id;
    if (this.ulb_id !== '') {
      let rte = `sachivalayams/` + this.ulb_id;
      this.apiSrv.get(rte).subscribe((res) => {
        this.schvlymlst = res['data'];
        // console.log(this.schvlymlst);
        this.getcluster();

      });
    }


  }

  getcluster() {
    this.svm_id = this.houseForm.value.svm_id;
    if (this.svm_id !== '') {
      //   console.log(this.houseForm.value.svm_id);
      let rte = `sachivalayams/block/` + this.svm_id;
      this.apiSrv.get(rte).subscribe((res) => {
        this.clusterlst = res['data'];
        // console.log(this.clusterlst);
      });
    }
  }

  gethseCtgry() {
    const rte = 'gethousetypes';
    this.apiSrv.get(rte).subscribe(res => {
      this.hseCtgry = res['data'];
    });

  }

  gethseStatus() {
    const rte = 'admin/house/status';
    this.apiSrv.get(rte).subscribe(res => {
      this.hseStatus = res['data'];
    });
  }


  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New House',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
    // this.addDtls=true;

  }

  openSideBar(opt, data) {
    this.shwSidebar = true;
    // console.log(data);
    if (opt === 'edit') {
      this.shwEditDiv = true;
      this.shwDelDiv = false;
      this.shwEditCntctDiv = false;
      this.shwDelCntctDiv = false;

      // this.edtHse=true;
      this.sidebarHeading = 'Edit House';
      this.houseForm.get('gte_nm').setValue(data.gte_nm)
      this.houseForm.get('ulb_id').setValue(data.ulb_id);
      this.houseForm.get('svm_id').setValue(data.svm_id);
      this.houseForm.get('tu_id').setValue(data.tu_id);
      this.houseForm.get('ple_ct').setValue(data.ple_ct);
      this.houseForm.get('dr_nu').setValue(data.dr_nu);
      this.houseForm.get('flr_nu').setValue(data.flr_nu);
      this.houseForm.get('hse_sts_id').setValue(data.hse_sts_id);
      this.houseForm.get('hse_ctgry_id').setValue(data.hse_ctgry_id);
      this.getUlbs();
      this.getGateid();
      this.getscvlym();
      this.getcluster();
    } else if (opt === 'delete') {
      this.edtHse = true;
      this.shwEditDiv = false;
      this.shwDelDiv = true;
      this.shwEditCntctDiv = false;
      this.shwDelCntctDiv = false;
      this.sidebarHeading = 'Delete House';
      this.houseForm.get('gte_nm').setValue(data.gte_nm);
      this.houseForm.get('ulb_id').setValue(data.ulb_id);
      this.houseForm.get('svm_id').setValue(data.svm_id);
      this.houseForm.get('tu_id').setValue(data.tu_id);
      this.houseForm.get('ple_ct').setValue(data.ple_ct);
      this.houseForm.get('dr_nu').setValue(data.dr_nu);
      this.houseForm.get('flr_nu').setValue(data.flr_nu);
      this.houseForm.get('hse_sts_id').setValue(data.hse_sts_id);
      this.houseForm.get('hse_ctgry_id').setValue(data.hse_ctgry_id);
      this.getUlbs();
      this.getGateid();
      this.getscvlym();
      this.getcluster();
    }
    else if (opt === 'editContact') {
      this.sidebarHeading = 'Edit House Contact';
      this.shwEditDiv = false;
      this.shwDelDiv = false;
      this.shwEditCntctDiv = true;
      this.shwDelCntctDiv = false;
      this.cntactForm.get('frst_nm').setValue(data.fst_nm)
      this.cntactForm.get('lst_nm').setValue(data.lst_nm);
      this.cntactForm.get('mbl_nu').setValue(data.mble_ph);
      this.cntactForm.get('ownr').setValue(data.type_id);
    }
    else if (opt === 'deleteContact') {
      this.sidebarHeading = 'Delete House Contact';
      this.shwEditDiv = false;
      this.shwDelDiv = false;
      this.shwEditCntctDiv = false;
      this.shwDelCntctDiv = true;
      this.cntactForm.get('frst_nm').setValue(data.fst_nm)
      this.cntactForm.get('lst_nm').setValue(data.lst_nm);
      this.cntactForm.get('mbl_nu').setValue(data.mble_ph);
      this.cntactForm.get('ownr').setValue(data.type_id);
    }
    else {
      this.shwEditDiv = false;
      this.shwDelDiv = false;
      this.shwEditCntctDiv = false;
      this.shwDelCntctDiv = false;
      this.hseDt.gte_hse_nu = '';
      this.sidebarHeading = 'Add New House';
      // this.addDtls=true;
      this.getUlbs();
      this.getGateid();
      this.getscvlym();
      this.getcluster();
    }
  }
  onEdit(event) {
    this.edtHse = true;
    //   console.log(event)
    this.editdata = event.data
    this.openSideBar("edit", this.editdata);
    // console.log(this.editdata);
    // this.saveEdit(1);
  }
  onDelete(event) {
    this.edtHse = true;
    //   console.log(event)
    this.deldata = event.data
    // console.log(this.deldata);
    this.openSideBar("delete", this.deldata)
    // this.saveDelete(1);

  }
  closeSideBar(): void {
    this.shwSidebar = false;
    this.hseDt = {};
    this.houseForm.reset();
    this.cntactForm.reset();
  }
  cancelbtn() {
    this.shwSidebar = false;
    this.houseForm.reset();
    this.cntactForm.reset();
  }

  // onCellClick(opt, edtData){
  //   if (opt === 'edit'){
  //     this.shwEditDiv = true;
  //     // this.hsestsNm = edtData.data.hse_sts_nm;
  //     this.hseId = edtData.data.hse_id;
  //     this.openSideBar(opt,edtData);
  //   }
  //   if (opt === 'delete'){
  //     this.shwEditDiv = false;
  //     this.shwDelDiv = true;
  //     this.hseId = edtData.data.hse_id;
  //     this.openSideBar(opt,edtData);
  //   }
  // }
  saveNew() {
    const postdata = [];
    const hseData = this.houseForm.value;
    const cntctData = this.cntactForm.value;
    postdata.push(hseData, cntctData);
    // const hseData =_.merge(this.houseForm.value,this.cntactForm.value) ;
    const rte = 'admin/houses';
    this.apiSrv.create(postdata, rte).subscribe(res => {
      // console.log(res);
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('success', 'House Successfully Added');
        this.getHseLst();
      }
    });
  }

  saveEdit() {
    const hseEdtData = {
      hse_id: this.editdata.hse_id,
      ple_ct: this.houseForm.value.ple_ct,
      gte_nm: this.houseForm.value.gte_nm,
      hse_sts_id: this.houseForm.value.hse_sts_id,
      hse_ctgry_id: this.houseForm.value.hse_ctgry_id,
      tu_id: this.houseForm.value.tu_id,
      svm_id: this.houseForm.value.svm_id,
      gte_id: this.editdata.gte_id
    }
    const rte = 'admin/houses/'+this.editdata.hse_id;
    this.apiSrv.update(hseEdtData, rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('info', 'House Successfully Updated');
        this.getHseLst();
      }
    });
  }

  saveDelete() {
    const hseDelData = {
      id: this.deldata.hse_id
    };

    const rte = 'admin/houses/'+this.deldata.hse_id;
    this.apiSrv.delete(rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('warning', 'House Successfully Deleted');
        this.getHseLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('House Not Deleted');
  }


  onEditCntct(event) {
    // this.edtHse=false;
    // this.cntctEdit=true;
    this.editCntctdata = event.data;
    this.openSideBar("editContact", this.editCntctdata);
    //   console.log(this.editCntctdata);


  }
  onDeleteCntct(event) {
    // this.edtHse=false;
    // this.cntctEdit=true;
    this.delCntctdata = event.data;
    this.openSideBar("deleteContact", this.delCntctdata);
    //   console.log(this.delCntctdata);

  }
  getOwnDtls() {
    this.Ownerdta = [
      {
        id: 1,
        type: "Owner"
      },
      {
        id: 0,
        type: "Tenant"
      }
    ]
  }
  saveEditCntct() {
    const hseCntctEdtData = {
      hse_id: this.editCntctdata.hse_id,
      type_id: this.cntactForm.value.ownr,
      fst_nm: this.cntactForm.value.frst_nm,
      lst_nm: this.cntactForm.value.lst_nm,
      mble_ph: this.cntactForm.value.mbl_nu,
      cntct_id: this.editCntctdata.cntct_id
    }
    //   console.log(hseCntctEdtData);
    // console.log(this.cntactForm.value);
    const rte = 'admin/houses/'+this.editCntctdata.hse_id+'/contacts';
    this.apiSrv.update(hseCntctEdtData, rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('info', 'Contact Successfully Updated');
        this.getHseLst();
      }
    });
  }
  saveDeleteCntct() {

    const hseCntctDelData = {
      hse_id: this.editCntctdata.hse_id,
      cntct_id: this.editCntctdata.cntct_id
    };
    // console.log(hseCntctDelData); 
    const rte = 'admin/houses/'+this.editCntctdata.hse_id+'/contacts/'+this.editCntctdata.cntct_id;
    this.apiSrv.delete(rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('warning', 'Contact Successfully Deleted');
        this.getHseLst();
      }
    });
  }
}
