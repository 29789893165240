import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';
import { TransferService } from 'src/app/shared/services/transfer.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Workbook } from 'exceljs';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-aler-ddashboard',
  templateUrl: './aler-ddashboard.component.html',
  styleUrls: ['./aler-ddashboard.component.scss']
})
export class AlerDdashboardComponent {
  showLdr: boolean=true;
  visible = false;
  alrtcunt: any;
  ttl_vcls: void;
  count_dep: any;
  count_arvl: any;
  grdta: any;
  tripsTrvldcolumnDefs:any=[] 
  indctr: any;
  tripData: any;
  asrt_nm: any;
  fltrsdata: any;
  
 
  constructor(public apiServ: AppsService, public TransfereService: TransferService,public route: Router,) { }
  ngOnInit(): void {
    this.getalertcunt();
    this.getgrdata(1);
  }

  initialize = function () {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
      this.routemap.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 13);
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: ''
      });
      tiles.addTo(this.routemap);

    } 
getalertcunt(){
  this.showLdr = true;
  this.apiServ.get(`dashboard/enterprise/getalrtcunts`).subscribe((res) => {
    if(res['status'] == 200){
      this.alrtcunt = res['data'];
      console.log(this.alrtcunt)
      this.showLdr = false;
    }
    console.log(this.alrtcunt);
  })
}
getgrdata(e){
  this.showLdr = true;
console.log(e)
this.indctr=e
let body ={
indctr:this.indctr
}
console.log(body)
  this.apiServ.post(body,`dashboard/enterprise/getagrddta`).subscribe((res) => {
    if(res['status'] == 200){
      this.grdta = res['data'];
      console.log(this.grdta )
      this.showLdr = false;
      if(e==1){
        this.tripsTrvldcolumnDefs = [
          { headerName: 'S.No', field: 'Sno', alignment: 'center', width: 80, filter: false,allowFiltering: false },
          { headerName: 'Vechile Number ', field: 'asrt_nm', alignment: 'center' },
          { headerName: 'Status ',field: 'asrt_sts_nm', alignment: 'left'},
          { headerName: ' category Name  ', field: 'asrt_ctgry_nm', alignment: 'left', },
          { headerName: 'Vechile Start Time', field: 'actl_strt_ts', alignment: 'center', },
          { headerName: 'Vechile End Time', field: 'actl_end_ts', alignment: 'center', filter: true},
          { headerName: 'Driver Name', field: 'crw_nm', alignment: 'center' , filter: true},
          { headerName: 'Mobile Number', field: 'crw_ph', alignment: 'center', filter: true},
          { headerName: 'Sanitary Inspector', field: 'fst_nm', alignment: 'center' },
          { headerName: 'Mobile Number', field: 'mobile_nu', alignment: 'center', filter: true},
          { headerName: 'Sachivalayam Name', field: 'svm_nm', alignment: 'center', filter: true},
        ]
      }
     else if(e==2){
      this.tripsTrvldcolumnDefs = [
        { headerName: 'S.No', field: 'Sno', alignment: 'center', width: 80, filter: false, search: false },
        { headerName: 'Vechile Number ', field: 'asrt_nm', alignment: 'center' , filter: true},
        { headerName: ' Vechile category ', field: 'asrt_ctgry_nm', alignment: 'left' },
        { headerName: 'Schedule Date ',field: 'trvl_dt', alignment: 'left' },
        { headerName: 'Schedule Time', field: 'sch_strt_tm', alignment: 'center' },
        { headerName: 'Actual Start Time', field: 'actl_strt_ts', alignment: 'center', filter: true},
        { headerName: 'Driver Name', field: 'crw_nm', alignment: 'center' , filter: true},
        { headerName: 'Mobile Number', field: 'crw_ph', alignment: 'center', filter: true},
        { headerName: 'Sanitary Inspector', field: 'fst_nm', alignment: 'center' },
        { headerName: 'Mobile Number', field: 'mobile_nu', alignment: 'center', filter: true},
        { headerName: 'Sachivalayam Name', field: 'svm_nm', alignment: 'center', filter: true},
      ]
     }
     else if(e==3){
      this.tripsTrvldcolumnDefs = [
        { headerName: 'S.No', field: 'Sno', alignment: 'center', width: 80, filter: false, search: false },
        { headerName: 'Vechile Number ', field: 'asrt_nm', alignment: 'center' , filter: true},
        { headerName: ' Vechile category ', field: 'asrt_ctgry_nm', alignment: 'left' },
        { headerName: 'Schedule Date ',field: 'trvl_dt', alignment: 'left' },
        { headerName: 'Schedule End Time', field: 'sch_end_tm', alignment: 'center' },
        { headerName: 'Actual End Time', field: 'actl_end_ts', alignment: 'center', filter: true},
        { headerName: 'Driver Name', field: 'crw_nm', alignment: 'center' , filter: true},
        { headerName: 'Mobile Number', field: 'crw_ph', alignment: 'center', filter: true},
        { headerName: 'Sanitary Inspector', field: 'fst_nm', alignment: 'center' },
        { headerName: 'Mobile Number', field: 'mobile_nu', alignment: 'center', filter: true},
        { headerName: 'Sachivalayam Name', field: 'svm_nm', alignment: 'center', filter: true},
      ]
     }
     else if(e==4){
      this.tripsTrvldcolumnDefs = [
        { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, search: false },
        { headerName: 'Vechile Number ', field: 'asrt_nm', alignment: 'center' , filter: true},
        { headerName: ' Vechile category ', field: 'asrt_ctgry_nm', alignment: 'left' },
        { headerName: 'Trip No',field: 'trp_sqnc_nu', alignment: 'left' },
        { headerName: 'Schedule Date ',field: 'trvl_dt', alignment: 'left' },
        { headerName: 'Schedule Time', field: 'sch_strt_tm', alignment: 'center' },
        { headerName: 'Route Deviated Time', field: 'strt_ts', alignment: 'center', filter: true},
        { headerName: 'Driver Name', field: 'crw_nm', alignment: 'center' , filter: true},
        { headerName: 'Mobile Number', field: 'crw_ph', alignment: 'center', filter: true},
        { headerName: 'Sanitary Inspector', field: 'fst_nm', alignment: 'center' },
        { headerName: 'Mobile Number', field: 'mobile_nu', alignment: 'center', filter: true},
        { headerName: 'Sachivalayam Name', field: 'svm_nm', alignment: 'center', filter: true},
      ]
     }
     else if(e==5){
      this.tripsTrvldcolumnDefs = [
        { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, search: false },
        { headerName: 'Vechile Number ', field: 'asrt_nm', alignment: 'center' , filter: true},
        { headerName: ' Vechile category ', field: 'asrt_ctgry_nm', alignment: 'left' },
        { headerName: 'Trip No',field: 'trp_sqnc_nu', alignment: 'left' },
        { headerName: 'Schedule Date ',field: 'trvl_dt', alignment: 'left' },
        { headerName: ' Stoppage  end  Time', field: 'asrt_stp_strt_ts', alignment: 'center' },
        { headerName: 'Stoppage start Time', field: 'asrt_stp_end_ts', alignment: 'center', filter: true},
        { headerName: 'Stoppage Time', field: 'diff', alignment: 'center', filter: true},
        { headerName: 'Driver Name', field: 'crw_nm', alignment: 'center' , filter: true},
        { headerName: 'Mobile Number', field: 'crw_ph', alignment: 'center', filter: true},
        { headerName: 'Sanitary Inspector', field: 'fst_nm', alignment: 'center' },
        { headerName: 'Mobile Number', field: 'mobile_nu', alignment: 'center', filter: true},
        { headerName: 'Sachivalayam Name', field: 'svm_nm', alignment: 'center', filter: true},
        { headerName: 'latitude', field: 'lat', alignment: 'center', filter: true},
        { headerName: 'longitude', field: 'lng', alignment: 'center', filter: true},
      ]
     }
     else if(e==6){
      this.tripsTrvldcolumnDefs = [
        { headerName: 'S.No', field: 'Sno', alignment: 'center', width: 80, filter: false, search: false },
        { headerName: 'Vechile Number ', field: 'asrt_nm', alignment: 'center' },
        { headerName: 'Status ',field: 'asrt_sts_nm', alignment: 'left'},
        { headerName: ' category Name', field: 'asrt_ctgry_nm', alignment: 'left', },
        { headerName: 'Driver Name', field: 'crw_nm', alignment: 'center' , filter: true},
        { headerName: 'Mobile Number', field: 'crw_ph', alignment: 'center', filter: true},
        { headerName: 'Sanitary Inspector', field: 'fst_nm', alignment: 'center' },
        { headerName: 'Mobile Number', field: 'mobile_nu', alignment: 'center', filter: true},
        { headerName: 'Sachivalayam Name', field: 'svm_nm', alignment: 'center', filter: true},
      ]
     }
    }
    console.log(this.grdta);
    
  })
}
gotoPlaybck(e,cond){
if(cond==5){
  console.log(e)
  var latitude = e.data.lat;
  var longitude = e.data.lng;
  console.log(latitude,longitude)
  var mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  window.open(mapUrl, '_blank');
}else if(cond==4){
console.log(e);
this.TransfereService.setLoclData('grdta', e.row.data)
  this.route.navigate([`internal/dashboard/routeDeviation-dashboard`]);
}
}



onExporting(e: DxDataGridTypes.ExportingEvent) {
  console.log('onExporting',e);
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        var name=''
        if(this.indctr==1){
           name='Total Vehicles'
        }
       else if(this.indctr==2){
        name='Late Departure Vehicles'
       }
       else if(this.indctr==3){
        name='Late Arrival Vehicles'
       }
       else if(this.indctr==4){
        name='Route Deviated Vehicles'
       }
       else if(this.indctr==5){
        name='Unauthorized Stoppage Vehicles'
       }
       else if(this.indctr==6){
        name='Vehicles Not Started from vechicle Yard'
       }
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), name +'.xlsx');
      });
    });
  }

}
