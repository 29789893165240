<ds-loading *ngIf="showLdr"></ds-loading>
<nz-drawer [nzWidth]="'80%'" [nzClosable]="true"
[nzVisible]="visible" nzPlacement="right" nzTitle="Trip Dashboard" (nzOnClose)="visible = false" class="dashbSlider">
<app-trips-slider [trip]="selectedTrip" *ngIf="selectedTrip"></app-trips-slider>
</nz-drawer>
<div class="row hdrBar">
  <div class="col-lg-12 col-md-12 mg-a">
    <h1>Command Control Centre (CCC)</h1>
  </div>
  <div class="fltrbg">
    <div class="abs_cntr">
      <div *ngIf="ntfsDta && ntfsDta.length > 0">
        <div class="row">
          <div class="col-lg-2 col-md-2 crd-hding">
            <div><b>Open</b>/<b>Closed</b>&nbsp;&nbsp;<b>(Total)</b></div>
            <div class="crd-sb-hding">Category</div>
          </div>
          <div class="col-lg-2 col-md-2 crd-hding">
            <div><b>{{opn_ttl}}</b>/<b>{{clsd_ttl}}</b>&nbsp;&nbsp;<b>({{totl}})</b></div>
            <div class="crd-sb-hding">Total</div>
          </div>
          <div *ngFor="let n of ntfsDta" class="col-lg-2 col-md-2 crd-hding">
            <div><b>{{n.opn}}</b>/
              <b>{{n.clsd}}</b>&nbsp;&nbsp;
              <b>({{n.ttl}})</b>
            </div>
            <div class="crd-sb-hding">{{n.ntfcn_ctgry_nm}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="fltrbg">
  <div class="row">
    <div class="col-lg-3 col-md-3">
      <nz-form-label>Status</nz-form-label>
      <nz-form-item>
        <nz-form-control>
          <nz-radio-group [(ngModel)]="filterData.tab_id" (ngModelChange)="getAllNotifications()">
            <label *ngFor="let tab of tabs" nz-radio-button nzValue="{{tab.id}}">
              <span>{{ tab.name }}</span>
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

    </div>
    <div class="col-lg-2 col-md-2">
      <nz-form-label>ULB</nz-form-label>
      <nz-form-item>
        <nz-form-control>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select ULB" style="width: 100%;"
            [(ngModel)]="filterData.ulb_id" (ngModelChange)="getAllNotifications()">
            <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
            <nz-option [nzLabel]="c.tnt_nm" [nzValue]="c.tnt_id" *ngFor="let c of ulbDta"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-2 col-md-2" *ngIf="ShowClap">
      <nz-form-label>Vendor</nz-form-label>
      <nz-form-item>
        <nz-form-control>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vendor" style="width: 100%;"
            [(ngModel)]="filterData.vendor_id" (ngModelChange)="getAllNotifications()">
            <nz-option [nzLabel]="'All'" [nzValue]="0"   *ngIf="!showVndr"></nz-option>
            <nz-option [nzLabel]="c.vndr_nm" [nzValue]="c.vndr_id" *ngFor="let c of vndrDta"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-2 col-md-2">
      <nz-form-label>Category</nz-form-label>
      <nz-form-item>
        <nz-form-control>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Category" style="width: 100%;" [nzMaxTagCount]="1"
            nzMode="multiple" [(ngModel)]="filterData.categories" (ngModelChange)="getAllNotifications()">
            <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
            <nz-option [nzLabel]="c.ntfcn_ctgry_nm" [nzValue]="c.ntfcn_ctgry_id" *ngFor="let c of ctgryDta">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-2 col-md-2">
      <nz-form-label>Date</nz-form-label>
      <nz-form-item>
        <nz-form-control>
          <nz-date-picker class="fullwidth" [(ngModel)]="filterData.date" [ngModelOptions]="{standalone: true}"
            (ngModelChange)="getAllNotifications();" nzFormat="dd-MM-yyyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row">
    <div class="pmg-20" style="width: 100%;">
      <div class="row bg-wp">
        <div class="ds-card grid tbl">
          <p class="crd-gp-hrd">{{tableTitle}} ({{dateName}})</p>
          <dx-data-grid class="p-8" [dataSource]="allNotifications" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%; max-width: 100%;"
            (onToolbarPreparing)="true" (onCellPrepared)="onCellPrepared(openNotificationscolumnDefs,$event)" (onCellClick)="getSldr($event)">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
              [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-filter-panel [visible]="false"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Route Deviated Trips Data">
            </dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-summary>
              <dxi-total-item column="Status" name="Total Tickets" summaryType="count"></dxi-total-item>
            </dxo-summary>
            <dxi-column *ngFor="let f of openNotificationscolumnDefs" [visible]="!f.hide" [dataField]="f.field"
              [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="Status"
              cellTemplate="editTemplate">
              <div *dxTemplate="let data of 'editTemplate'">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Status" style="width: 100%;"
                  [(ngModel)]="data.data.ntfcn_sts_id" (ngModelChange)="changeStatus(data)">
                  <nz-option [nzLabel]="c.ntfcn_sts_nm" [nzValue]="c.ntfcn_sts_id" *ngFor="let c of statuses">
                  </nz-option>
                </nz-select>
              </div>
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
              cellTemplate="editTemplate1">
              <div *dxTemplate="let data of 'editTemplate1'">
                <div class="tblEdtBtn" (click)="gotoTctDtls(data)">
                  <i nz-icon nzType="eye" nzTheme="outline"></i>
                  <p>view</p>
                </div>
              </div>
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="520"
  [nzVisible]="openSidebar ? category_cd == 'RD' : false" nzTitle="Route deviated Ticket Details"
  (nzOnClose)="closeSidebar()">
  <nz-tabset>
    <nz-tab nzTitle="Trip Details">
      <div class="row">
        <div class="col-md-12">
          <div class="ds-form-header">
            Trip Details
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="row">
          <div class="col-md-6 ds-form-subheader">
            Vehicle Number
          </div>
          <div class="col-md-6">
            {{formData.vehicle_nu}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 ds-form-subheader">
            Trip Number
          </div>
          <div class="col-md-6">
            {{formData.trip_squence_nu}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 ds-form-subheader">
            Driver Name
          </div>
          <div class="col-md-6">
            {{formData.driver_nm}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 ds-form-subheader">
            Driver Contact number
          </div>
          <div class="col-md-6">
            {{formData.driver_contact_nu}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 ds-form-subheader">
            Issued Date
          </div>
          <div class="col-md-6">
            {{formData.date}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="ds-form-header">
            Playback
          </div>
        </div>
      </div>
      <div id="playbackmap"></div>
    </nz-tab>
    <nz-tab nzTitle="Previous Trips">
      <div class="ds-card" *ngFor="let c of previousNotifications">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row ds-card-row" style="padding-top:5px ;">
              <div class="col-lg-7 col-md-7 col">
                <p class="ds-card-ltext ds-card-lheader">{{c.asrt_nm}}</p>
                <p class="ds-card-ltext ds-card-lheader-sm">{{c.drvr_nm}}</p>
              </div>
              <div class="col-lg-5 col-md-5 col mg-a">
                <p class="ds-card-ltext ds-card-rheader-md">{{c.ntfcn_ctgry_nm}}</p>
                <p class="ds-card-ltext ds-card-rheader">Trip - {{c.trp_sqnc_nu}}</p>
                <p class="ds-card-ltext ds-card-rheader-sm">{{c.ntfcn_sts_nm}}</p>
              </div>
            </div>

            <div class="row ds-card-row">

              <div class="col-lg-2 col-md-2 col">
                <p class="ds-card-ltext">From</p>
              </div>
              <div class="col-lg-1 col-md-1 col">
                <p class="ds-card-rtext text-center">:</p>
              </div>
              <div class="col-lg-9 col-md-9 col">
                <p class="ds-card-rtext">{{c.strt_fnce_nm}}</p>
              </div>

            </div>

            <div class="row ds-card-row">

              <div class="col-lg-2 col-md-2 col">
                <p class="ds-card-ltext">To</p>
              </div>
              <div class="col-lg-1 col-md-1 col">
                <p class="ds-card-rtext text-center">:</p>
              </div>
              <div class="col-lg-9 col-md-9 col">
                <p class="ds-card-rtext">{{c.end_fnce_nm}}</p>
              </div>

            </div>
          </div>
        </div>


      </div>
    </nz-tab>
  </nz-tabset>
</nz-drawer>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="380"
  [nzVisible]="openSidebar ? category_cd == 'TT' : false" nzTitle="Ticket Details" (nzOnClose)="closeSidebar()">
  <nz-tabset>
    <nz-tab nzTitle="Trip Details">
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="ds-form-header">
              Trip Details
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Vehicle Number
            </div>
            <div class="col-md-6">
              {{formData.vehicle_nu}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Trip Number
            </div>
            <div class="col-md-6">
              {{formData.trip_squence_nu}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Driver Name
            </div>
            <div class="col-md-6">
              {{formData.driver_nm}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Driver Contact number
            </div>
            <div class="col-md-6">
              {{formData.driver_contact_nu}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Scheduled start time
            </div>
            <div class="col-md-6">
              {{formData.sdate}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Scheduled end time
            </div>
            <div class="col-md-6">
              {{formData.edate}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Issued Date
            </div>
            <div class="col-md-6">
              {{formData.date}}
            </div>
          </div>
        </div>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Previous Trips">
      <div class="ds-card" *ngFor="let c of previousNotifications">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row ds-card-row" style="padding-top:5px ;">
              <div class="col-lg-7 col-md-7 col">
                <p class="ds-card-ltext ds-card-lheader">{{c.asrt_nm}}</p>
                <p class="ds-card-ltext ds-card-lheader-sm">{{c.drvr_nm}}</p>
              </div>
              <div class="col-lg-5 col-md-5 col mg-a">
                <p class="ds-card-ltext ds-card-rheader-md">{{c.ntfcn_ctgry_nm}}</p>
                <p class="ds-card-ltext ds-card-rheader">Trip - {{c.trp_sqnc_nu}}</p>
                <p class="ds-card-ltext ds-card-rheader-sm">{{c.ntfcn_sts_nm}}</p>
              </div>
            </div>

            <div class="row ds-card-row">

              <div class="col-lg-2 col-md-2 col">
                <p class="ds-card-ltext">From</p>
              </div>
              <div class="col-lg-1 col-md-1 col">
                <p class="ds-card-rtext text-center">:</p>
              </div>
              <div class="col-lg-9 col-md-9 col">
                <p class="ds-card-rtext">{{c.strt_fnce_nm}}</p>
              </div>

            </div>

            <div class="row ds-card-row">

              <div class="col-lg-2 col-md-2 col">
                <p class="ds-card-ltext">To</p>
              </div>
              <div class="col-lg-1 col-md-1 col">
                <p class="ds-card-rtext text-center">:</p>
              </div>
              <div class="col-lg-9 col-md-9 col">
                <p class="ds-card-rtext">{{c.end_fnce_nm}}</p>
              </div>

            </div>
          </div>
        </div>


      </div>
    </nz-tab>
  </nz-tabset>
</nz-drawer>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="380"
  [nzVisible]="openSidebar ? category_cd == 'SV' : false" nzTitle="Ticket Details" (nzOnClose)="closeSidebar()">
  <nz-tabset>
    <nz-tab nzTitle="Trip Details">
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="ds-form-header">
              Trip Details
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Vehicle Number
            </div>
            <div class="col-md-6">
              {{formData.vehicle_nu}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Trip Number
            </div>
            <div class="col-md-6">
              {{formData.trip_squence_nu}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Driver Name
            </div>
            <div class="col-md-6">
              {{formData.driver_nm}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Driver Contact number
            </div>
            <div class="col-md-6">
              {{formData.driver_contact_nu}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Issued Date
            </div>
            <div class="col-md-6">
              {{formData.date}}
            </div>
          </div>
        </div>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Previous Trips">
      <div class="ds-card" *ngFor="let c of previousNotifications">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row ds-card-row" style="padding-top:5px ;">
              <div class="col-lg-7 col-md-7 col">
                <p class="ds-card-ltext ds-card-lheader">{{c.asrt_nm}}</p>
                <p class="ds-card-ltext ds-card-lheader-sm">{{c.drvr_nm}}</p>
              </div>
              <div class="col-lg-5 col-md-5 col mg-a">
                <p class="ds-card-ltext ds-card-rheader-md">{{c.ntfcn_ctgry_nm}}</p>
                <p class="ds-card-ltext ds-card-rheader">Trip - {{c.trp_sqnc_nu}}</p>
                <p class="ds-card-ltext ds-card-rheader-sm">{{c.ntfcn_sts_nm}}</p>
              </div>
            </div>

            <div class="row ds-card-row">

              <div class="col-lg-2 col-md-2 col">
                <p class="ds-card-ltext">From</p>
              </div>
              <div class="col-lg-1 col-md-1 col">
                <p class="ds-card-rtext text-center">:</p>
              </div>
              <div class="col-lg-9 col-md-9 col">
                <p class="ds-card-rtext">{{c.strt_fnce_nm}}</p>
              </div>

            </div>

            <div class="row ds-card-row">

              <div class="col-lg-2 col-md-2 col">
                <p class="ds-card-ltext">To</p>
              </div>
              <div class="col-lg-1 col-md-1 col">
                <p class="ds-card-rtext text-center">:</p>
              </div>
              <div class="col-lg-9 col-md-9 col">
                <p class="ds-card-rtext">{{c.end_fnce_nm}}</p>
              </div>

            </div>
          </div>
        </div>


      </div>
    </nz-tab>
  </nz-tabset>
</nz-drawer>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="380"
  [nzVisible]="openSidebar ? category_cd == 'DA' : false" nzTitle="Ticket Details" (nzOnClose)="closeSidebar()">
  <nz-tabset>
    <nz-tab nzTitle="Trip Details">
      <div>

        <div class="row">
          <div class="col-md-12">
            <div class="ds-form-header">
              Assign Driver
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Vehicle Number
            </div>
            <div class="col-md-6">
              {{formData.vehicle_nu}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Trip Number
            </div>
            <div class="col-md-6">
              {{formData.trip_squence_nu}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 ds-form-subheader">
              Issued Date
            </div>
            <div class="col-md-6">
              {{formData.date}}
            </div>
          </div>
        </div>
        <form nz-form>
          <div nz-row [nzGutter]="8">
            <div nz-col nzSpan="24">
              <nz-form-label>Select Driver</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select driver" [(ngModel)]="formData.crw_id"
                    [ngModelOptions]="{standalone: true}">
                    <nz-option *ngFor="let v of drivers" [nzLabel]=v.crw_nm [nzValue]="v.crw_id"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
        <div class="btngrp">
          <button nz-button style="margin-right: 8px;" (click)="closeSidebar()" nzType="primary"
            nzDanger>Cancel</button>
          <button nz-button (click)="saveasgnmnt()" class="sbmtbtn">Submit</button>
        </div>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Previous Trips">
      <div class="ds-card" *ngFor="let c of previousNotifications">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row ds-card-row" style="padding-top:5px ;">
              <div class="col-lg-7 col-md-7 col">
                <p class="ds-card-ltext ds-card-lheader">{{c.asrt_nm}}</p>
                <p class="ds-card-ltext ds-card-lheader-sm">{{c.drvr_nm}}</p>
              </div>
              <div class="col-lg-5 col-md-5 col mg-a">
                <p class="ds-card-ltext ds-card-rheader-md">{{c.ntfcn_ctgry_nm}}</p>
                <p class="ds-card-ltext ds-card-rheader">Trip - {{c.trp_sqnc_nu}}</p>
                <p class="ds-card-ltext ds-card-rheader-sm">{{c.ntfcn_sts_nm}}</p>
              </div>
            </div>

            <div class="row ds-card-row">

              <div class="col-lg-2 col-md-2 col">
                <p class="ds-card-ltext">From</p>
              </div>
              <div class="col-lg-1 col-md-1 col">
                <p class="ds-card-rtext text-center">:</p>
              </div>
              <div class="col-lg-9 col-md-9 col">
                <p class="ds-card-rtext">{{c.strt_fnce_nm}}</p>
              </div>

            </div>

            <div class="row ds-card-row">

              <div class="col-lg-2 col-md-2 col">
                <p class="ds-card-ltext">To</p>
              </div>
              <div class="col-lg-1 col-md-1 col">
                <p class="ds-card-rtext text-center">:</p>
              </div>
              <div class="col-lg-9 col-md-9 col">
                <p class="ds-card-rtext">{{c.end_fnce_nm}}</p>
              </div>

            </div>
          </div>
        </div>


      </div>
    </nz-tab>
  </nz-tabset>
</nz-drawer>