<pg-header title="Roles And Permissions" ticon="idcard" bckbtn=true bckurl="/setup"></pg-header>

<div *ngIf="usrAcsErrMsg == false">

    <div class="ds-card grid">
        <dx-data-grid id="gridContainer" [dataSource]="roledta" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
            [showBorders]="true" style="width:100%" (onToolbarPreparing)="(USER_PERMISSIONS.insrt_in==1)?onToolbarPreparing($event,'true'):onToolbarPreparing($event,'false')"
            [allowColumnResizing]="true">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
            </dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
            <dxo-export [allowExportSelectedData]="true" [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" fileName="LMO Details"></dxo-export>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
                [width]="f.width" [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
                [allowHeaderFiltering]="f.filter"></dxi-column>
            <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" [width]="100" caption="Edit"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="openSideBar('edit',data)">
                        Edit
                    </div>
                </div>
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" [width]="100"
                caption="Edit Permissions" cellTemplate="editPrmTemplate">
                <div *dxTemplate="let data of 'editPrmTemplate'">
                    <div class="tblDelBtn" (click)="openSideBar('Edit Permissions',data)">
                        Edit Permissions
                    </div>
                </div>
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" [width]="100" caption="Delete"
                cellTemplate="deleteTemplate">
                <div *dxTemplate="let data of 'deleteTemplate'">
                    <div class="tblDelBtn" (click)="delete(data)">
                        Delete
                    </div>
                </div>
            </dxi-column>
        </dx-data-grid>
    </div>
    <ds-loading *ngIf="loader"></ds-loading>

    <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
        [nzMaskClosable]="false" [nzWidth]="550" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
        (nzOnClose)="closeSideBar()">
        <ng-container *ngIf="!permismdl">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <form nz-form [formGroup]="RolesForm" style="width:100%" class="mt-12">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div class="form-label">Enter Role Name</div>
                        <nz-form-item class="ant-form-item">
                            <nz-form-control style="width: 100%;">
                                <input type="text" autocomplete="off" nz-input formControlName="rle_nm"
                                    placeholder="Rolename" />
                                    <div *ngFor="let validation of account_validation_messages.rolename" style="margin-top: 1.5%;
                                    color: red;">
                                        <ng-container *ngIf="RolesForm.get('rle_nm').hasError(validation.type) && (RolesForm.get('rle_nm').dirty || RolesForm.get('rle_nm').touched)">
                                            {{validation.message}}
                                        </ng-container>
                                    </div>
                            </nz-form-control>
                        </nz-form-item>

                    </div>


                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div class="form-label">Enter Role Description</div>
                        <nz-form-item class="ant-form-item">
                            <textarea nz-input placeholder="Role Description" formControlName="rle_dscn_nm"></textarea>
                        </nz-form-item>
                    </div>
                </form>
                <div class="row" fxLayoutAlign="end center">
                    <div class="col-6" style="text-align:right">
                        <button nz-button nzType="primary" [disabled]="RolesForm.invalid" class="m-r-10" (click)="saveRole()">Save</button>
                    </div>
                    <div class="col-6">
                        <button nz-button nzType="primary" class="m-r-10" (click)="closeSideBar();">Cancel</button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="permismdl">
            <div class="font-size-17 font-weight-500 pt-8 mb-0 pl-8 pb-8 red-800-fg"
                style="color: #a52907;font-weight: 500;margin: auto;">Standard Permissions</div>
            <table style="width: 100%;margin-top: 3%;">
                <tr style="font-weight: 500;text-transform: capitalize;letter-spacing: 0.7px;">
                    <th></th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Update</th>
                    <th>Delete</th>
                    <th>Export</th>
                </tr>
                <tr *ngFor="let obj of rlepermdta"
                    style="font-weight: 500;text-transform: capitalize;letter-spacing: 0.7px;">
                    <td>
                        <p class="pmdlcs">{{obj.objct_nm}}</p>
                    </td>
                    <td><label nz-checkbox [(ngModel)]="obj.slct_in"></label></td>
                    <td><label nz-checkbox [(ngModel)]="obj.insrt_in"></label></td>
                    <td><label nz-checkbox [(ngModel)]="obj.updt_in"></label></td>
                    <td><label nz-checkbox [(ngModel)]="obj.dlte_in"></label></td>
                    <td><label nz-checkbox [(ngModel)]="obj.exprt_in"></label></td>
                </tr>
            </table>
            <div class="m-0 p-20" fxLayout="row" style="text-align: right;" fxLayoutAlign="end center">
                <button nz-button nzType="primary" (click)="createPermson()" class="m-r-10">Save</button>
            </div>
        </ng-container>
    </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
    <nz-result nzStatus="error" nzTitle="Access Denied"
        nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    </nz-result>
</div>