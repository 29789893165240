<ds-loading *ngIf="showLdr"></ds-loading>
<div class="row hdrBar">
    <div class="col-lg-6 col-md-6 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">{{svmNm}} Sachivalayam</h1>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <span>Select Sachivalayam:</span>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedsvm" (ngModelChange)="onsvmSelect($event)"
            [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let t of svmLst" [nzValue]="t.svm_apfss_cd" [nzLabel]="t.svm_nm"></nz-option>
        </nz-select>
    </div>
        <div class="col-md-2" style="margin: auto;">
            <div class=" row align-items-md-center">
            <div class="text-md-right m-v-10">
                <button nz-button nzType="default" (click)="goBack()" style="box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
                color: #1969cd;
                font-weight: 500;
                float: right;"><i nz-icon nzType="left"></i> Go back</button>
            </div>
        </div>
      </div>
   
</div>
<div class="main">
    <nz-radio-group class="btnsgrp" [(ngModel)]="actvtbleNm" nzButtonStyle="solid">
        <label nz-radio-button nzType="primary" nzValue="crdOne" class="svmBtn" (click)="cardOne()">DOOR-TO-DOOR
            COLLECTION</label>
        <label nz-radio-button nzType="primary" nzValue="crdTwo" class="svmBtn" (click)="cardTwo()">ATTENDANCE</label>
        <label nz-radio-button nzType="primary" nzValue="crdThree" class="svmBtn" (click)="cardThree()">VEHICLE
            TRACKING</label>
        <label nz-radio-button nzType="primary" nzValue="crdFour" class="svmBtn" (click)="cardFour()">USER
            CHARGES</label>
    </nz-radio-group>
    <div class="row bg-wp" *ngIf="crdOne==true">
        <app-svm-door-to-door-clctn [svm]="svmData"></app-svm-door-to-door-clctn>
    </div>
    <div class="row bg-wp" *ngIf="crdTwo==true">
        <app-svm-attendance [svm]="svmData"></app-svm-attendance>
    </div>
    <div class="row bg-wp" *ngIf="crdThree==true">
        <app-svm-vehicle-tracking [svm]="svmData"></app-svm-vehicle-tracking>
    </div>
    <div class="row bg-wp" *ngIf="crdFour==true">
        <app-svm-usercharges [svm]="svmData"></app-svm-usercharges>
    </div>
</div>