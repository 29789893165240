import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';


@Component({
  selector: 'app-help-doc-pages',
  templateUrl: './help-doc-pages.component.html',
  styleUrls: ['./help-doc-pages.component.scss']
})
export class HelpDocPagesComponent implements OnInit {
  pagesLst;
  visible = false;
  newPgeGrp;
  selectedGroupId
  pagesDtls;
  rowData;
  columnDefs;
  pagesdtls;
  shwSidebar = false;
  heading;
  loader: boolean;
  editdata;
  editClicked = false;
  appPrfls;
  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
  permissions = { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 }

  validateForm!: FormGroup;
  validatepgaeForm!: FormGroup;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(public apiSrv: AppsService, private fb: FormBuilder, private modal: NzModalService, public router: Router) { }

  ngOnInit() {
    this.validateForm = this.fb.group({
      pge_grp_nm: [null, [Validators.required]],
      prfle_id: [null, [Validators.required]],
      pge_grp_dscn_tx: [null],
    });
    this.validatepgaeForm = this.fb.group({
      pge_nm: [null, [Validators.required]],
      pge_grp_id: [null, [Validators.required]],
      pge_url_tx: [null, [Validators.required]],
      pge_dscn_tx: [null],
    });
    this.getAllpages();
  }
  getAllpages() {
    let rte = `kb/pagegroups`;
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          //   //   console.log(res)
          this.pagesLst = res['data'];
          this.selectedGroupId = 1;
          this.getAppPrfls();
          this.onPgeGrpSelection()
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    })
  }
  getAppPrfls() {
    let rte = `kb/app-profiles`;
    this.apiSrv.get(rte).subscribe(res => {
      //   //   console.log(res)
      this.appPrfls = res['data'];
    })
  }
  createNwgrp() {
    //   console.log("create");
    this.visible = true;

  }
  submit() {
    let rte = `kb/page-groups`;
    //   console.log(this.validateForm.value);
    this.validateForm.value['sqnce_id'] = this.pagesLst.length + 1
    this.apiSrv.create(this.validateForm.value, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.close();
        this.modal.success({
          nzTitle: 'New Page Group Creation',
          nzContent: 'Sucessfully created'
        });
        this.getAllpages();
      }
    })
  }
  onPgeGrpSelection() {
    //   console.log(this.selectedGroupId)
    this.pagesdtls = []
    let rte = `kb/pages/${this.selectedGroupId}`;
    this.apiSrv.get(rte).subscribe(res => {
      //   //   console.log(res)
      if (res['data'].length > 0) {
        this.pagesdtls = res['data'];
        this.rowData = this.pagesdtls;
      }
      else {
        this.pagesdtls = [];
        this.rowData = this.pagesdtls;
      }
    })
    this.columnDefs = [
      { headerName: 'Sno', field: 'sno', alignment: 'center', width: 100, filter: false },
      { headerName: 'Page Name', alignment: 'left', width: 200, field: 'pge_nm' },
      { headerName: 'Page Group', alignment: 'left', width: 200, field: 'pge_grp_nm' },
      { headerName: 'Page Url Text', field: 'pge_url_tx', width: 200, alignment: 'center' },
    ]
  }
  onToolbarPreparing(e) {
    //   console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(key, data) {
    this.shwSidebar = true;
    //   console.log(key)
    //   console.log(data)
    if (key == 'new') {
      this.heading = "Create New page";
      this.editClicked = false;
      this.validatepgaeForm.get('pge_nm').setValue('');
      this.validatepgaeForm.get('pge_grp_id').setValue('');
      this.validatepgaeForm.get('pge_url_tx').setValue('');
      this.validatepgaeForm.get('pge_dscn_tx').setValue('');
    }
    else if (key == 'edit') {
      this.heading = "Edit page Details";
      this.editClicked = true;
      this.editdata = data
      this.validatepgaeForm.get('pge_nm').setValue(data.pge_nm);
      this.validatepgaeForm.get('pge_grp_id').setValue(data.pge_grp_id);
      this.validatepgaeForm.get('pge_url_tx').setValue(data.pge_url_tx);
      this.validatepgaeForm.get('pge_dscn_tx').setValue(data.pge_dscn_tx);

    }
  }
  closeSideBar() {
    this.shwSidebar = false
  }
  submitpge() {
    let rte = `kb/pages`;
    this.apiSrv.create(this.validatepgaeForm.value, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.close();
        this.modal.success({
          nzTitle: 'New Page Creation',
          nzContent: 'Sucessfully created'
        });
        this.getAllpages();
        this.closeSideBar();
      }
    })
  }
  onCellClick(e) {
    //   console.log(e)
    if (e.rowType == 'data' && e.column.dataField === "pge_nm") {
      this.router.navigate([`setup/helpdoc`], { queryParams: { pgeId: e.row.data.pge_id } })
    }
  }
  onCellPrepared(colDef, e: any) {
    //   console.log(e.column)
    if (e.column.dataField === 'pge_nm') {
      //   console.log("sravani")
      e.cellElement.style.color = '#337ab7';
      e.cellElement.style.cursor = "pointer";
    }
  }
  onDelete2(event) {
    let data = event.data
    this.modal.confirm({
      nzTitle: 'Are you sure delete this section?',
      nzContent: `<b style="color: red;">${data.pge_nm}</b>`,
      nzOkText: 'Yes',
      // nzOkType: 'danger',
      nzOnOk: () => this.pagedelete(data),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }
  pagedelete(data) {
    //   console.log(data)
    let rte = `kb/pages/`+data.pge_id;
    this.apiSrv.delete( rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.close();
        this.modal.success({
          nzTitle: 'delete Page',
          nzContent: 'Sucessfully deleted'
        });
        this.getAllpages();
      }
    })
  }
  onEdit2(event) {
    this.editdata = event.data
    this.openSideBar("edit", this.editdata)
  }
  updatepge() {
    let rte = `kb/pages/`+this.editdata.pge_id;
    this.validatepgaeForm.value['pge_id'] = this.editdata.pge_id;

    this.apiSrv.update(this.validatepgaeForm.value, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.close();
        this.modal.success({
          nzTitle: 'New Page Creation',
          nzContent: 'Sucessfully created'
        });
        this.getAllpages();
      }
    })
  }
}
