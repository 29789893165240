import { Component } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as _moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as L from 'leaflet';
const moment = _moment;
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-communication-portal',
  templateUrl: './communication-portal.component.html',
  styleUrls: ['./communication-portal.component.scss']
})
export class CommunicationPortalComponent {
  tabs: any;
  openNotifications = [];
  filterData: any = { tab_id: 1, vendor_id: 0, ulb_id: 0, categories: [0], date: moment().format('YYYY-MM-DD') };
  tableTitle: string = '';
  openSidebar = false;
  formData: any = { date: moment() };
  totl = 0; opn_ttl = 0; clsd_ttl = 0;
  showLdr = false;
  ctgryDta: any = [];
  statuses: any = [];
  vndrDta: any = [];
  dateName: string = '';
  allNotifications;
  openNotificationscolumnDefs;
  ulbDta: any = [];
  vehicles: any = [];
  vendors: any = [];
  drivers: any = [];
  previousNotifications: any = [];
  trips: any = [];
  ntfsDta: any;
  category_cd: string = '';
  clientDetails: any = [];
  playbackmap: any;
  route_Polyline: any;
  startPBMarker1: any;
  endPBMarker1: any;
  polylinePoints: any;
  actl_Polyline: any;
  startPBMarker: any;
  endPBMarker: any;
  visible = false;
  userDtls: any;
  showVndr: boolean;
  clap_in: number;
  clntDtls: any;
  ShowClap: boolean;
  constructor(public apiServ: AppsService, private modal: NzModalService, public datepipe: DatePipe) {
    this.tabs = [
      {
        id: 1,
        name: 'All',
        icon: 'folder-open'
      },
      {
        id: 2,
        name: 'Open Issues',
        icon: 'folder-open'
      },
      {
        id: 3,
        name: 'Closed Issues',
        icon: 'issues-close'
      }
    ];
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
  }
  closeSidebar() {
    this.filterData.vehicle_id = null;
    this.openSidebar = false;
  }
  ngOnInit() {
    this.get_notificatioCountsDta();
    this.getDropdownData();
    this.getFormData();
    this.getAllNotifications();
  }
  getDropdownData(): any {
    let clients = localStorage.getItem('wto_clients') ? JSON.parse(localStorage.getItem('wto_clients')) : []
    this.ulbDta = clients[0].tnts;
    this.apiServ.get(`dashboard/enterprise/notifications/status/list`).subscribe((res) => { this.statuses = res['data']; });
    // this.apiServ.get(`playback/vendors`).subscribe((res) => { this.vndrDta = res['data']; });
    if (this.userDtls.dsgns_id == 1000032) {
      this.filterData.vendor_id = this.userDtls.vndr_id;
      this.showVndr = true;
      this.apiServ.get(`billing/vendors/list/${this.filterData.vendor_id}/byId`).subscribe((res) => {
        if (res['status'] == 200) {
          this.vndrDta = res['data'];
        }
      }, (err) => {
      })
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vndrDta = [];
      this.filterData.vendor_id = 0;
    } else {
      this.apiServ.get(`billing/vehicle-wise/vendors/list/${this.filterData.vendor_id}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vndrDta = res['data'];
          this.filterData.vendor_id = this.vndrDta[0].vndr_id;
        }
      }, (err) => {
      });
    }

    this.apiServ.get(`dashboard/enterprise/get_categories`).subscribe((res) => { this.ctgryDta = res['data']; });
  }
  getFormData(): any {
    this.apiServ.get(`drivers/vehicles`).subscribe((res) => { this.vehicles = res['data']; });
    // this.apiServ.get(`playback/vendors`).subscribe((res) => { this.vendors = res['data']; });
    if (this.userDtls.dsgns_id == 1000032) {
      this.filterData.vendor_id = this.userDtls.vndr_id;
      this.showVndr = true;
      this.apiServ.get(`billing/vendors/list/${this.filterData.vendor_id}/byId`).subscribe((res) => {
        if (res['status'] == 200) {
          this.vndrDta = res['data'];
        }
      }, (err) => {
      })
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vndrDta = [];
      this.filterData.vendor_id = 0;
    } else {
      this.apiServ.get(`billing/vehicle-wise/vendors/list/${this.filterData.vendor_id}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vndrDta = res['data'];
          this.filterData.vendor_id = this.vndrDta[0].vndr_id;
        }
      }, (err) => {
      });
    }

  }
  changeStatus(data) {
    this.apiServ.put({ status_id: data.data.ntfcn_sts_id }, `dashboard/enterprise/notification/` + data.data.ntfcn_id + `/status`).subscribe((res) => {
      if (res['status'] == 200)
        console.log('Status Updated');
    });
  }
  getDrivers(): any {
    this.apiServ.post({ asrt_id: this.formData.vehicle_id }, `drivers/drivers`).subscribe((res) => { this.drivers = res['data']; });
  }
  get_notificatioCountsDta(): any {
    this.showLdr = true;
    const rte = `dashboard/enterprise/get_notificatioCounts`;
    this.apiServ.get(rte).subscribe((res) => {
      this.ntfsDta = res['data'];
      this.showLdr = false;
      console.log(this.ntfsDta);
      for (let i = 0; i < this.ntfsDta.length; i++) {
        if (this.ntfsDta[i].ttl) {
          this.totl = this.totl + this.ntfsDta[i].ttl;
        }
        if (this.ntfsDta[i].opn) {
          this.opn_ttl = this.opn_ttl + this.ntfsDta[i].opn
        }
        if (this.ntfsDta[i].clsd) {
          this.clsd_ttl = this.clsd_ttl + this.ntfsDta[i].clsd
        }
      }
      console.log(this.totl, this.opn_ttl, this.clsd_ttl);

    });
  }
  dateToFromNowDaily(myDate) {
    if (myDate == null) return 'Till Now';
    // get from-now for this date
    // var fromNow = moment(myDate).fromNow();
    var dt = moment(myDate).format('DD-MM-YYYY');

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      // when the date is further away, use from-now functionality             
      sameElse: function () {
        return "[" + dt + "]";
      }
    });
  }
  getAllNotifications(): any {
    if (this.filterData.tab_id == 1) {
      this.tableTitle = 'All notifications';
    } else if (this.filterData.tab_id == 2) {
      this.tableTitle = 'Open notifications';
    } else if (this.filterData.tab_id == 3) {
      this.tableTitle = 'Closed notifications';
    }
    if (this.filterData.categories.length)
      this.filterData.categories.map(element => {
        if (element == 0) {
          if (this.ctgryDta.length)
            this.filterData.categories = [];
          this.ctgryDta.map((val) => {
            this.filterData.categories.push(val['ntfcn_ctgry_id']);
          })
        }
      });
    this.dateName = this.dateToFromNowDaily(this.filterData.date);
    this.showLdr = true;
    const rte = `dashboard/enterprise/notifications/list`;
    this.apiServ.post(this.filterData, rte).subscribe((res) => {
      this.showLdr = false;
      if (res['status'] == 200) {
        let data = res['data'];
        this.allNotifications = data;
        console.log(this.allNotifications);
        this.openNotificationscolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, cellClass: 'pm-grid-number-cell', search: false },
          { headerName: 'Issued Date', alignment: 'center', field: 'issued_dt_tm', cellClass: 'pm-grid-number-cell' },
          { headerName: 'Vehicle No', alignment: 'center', field: 'asrt_nm', cellClass: 'pm-grid-number-cell' },
          { headerName: 'Trip No', field: 'trp_sqnc_nu', alignment: 'center', cellClass: 'pm-grid-number-cell' },
          { headerName: 'Driver Name', field: 'drvr_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
          { headerName: 'Category Name', field: 'ntfcn_ctgry_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
          { headerName: 'Route', alignment: 'left', field: 'rte_nm', cellClass: 'pm-grid-number-cell' },
          { headerName: 'Description', field: 'ntfcn_dscrptn_txt', alignment: 'left', cellClass: 'pm-grid-number-cell' }
        ]
      }
    }, (error) => {
      this.showLdr = false;

    });
  }

  gotoTctDtls(data) {
    this.openSidebar = true;
    this.category_cd = data.data.ntfcn_ctgry_cd;
    this.formData.vehicle_id = data.data.asrt_id;
    this.formData.vehicle_nu = data.data.asrt_nm;
    this.formData.trip_id = data.data.trp_rn_id;
    this.formData.date = data.data.issued_dt;
    this.formData.trip_squence_nu = data.data.trp_sqnc_nu;
    this.formData.driver_nm = data.data.drvr_nm;
    this.formData.driver_contact_nu = data.data.drvr_ph;
    this.filterData.vehicle_id = data.data.asrt_id;

    this.getPreviousNotifications();
    if (this.category_cd == 'DA') {
      this.getDrivers();
    } else if (this.category_cd == 'RD') {
      this.initialize();
      this.getTrips(() => {
        this.getTripMap(this.formData);
      });
    } else if (this.category_cd == 'TT') {
      this.getTrips(() => { });
    }
  }
  getTrips(clbk) {
    let postdata = {
      asrt_id: this.formData.vehicle_id,
      trpdate: this.formData.date
    };
    this.apiServ.post(postdata, `playback/vehicle/trips`).subscribe((res) => {
      this.trips = res['data'];
      for (var l = 0; l < this.trips.length; l++) {
        if (this.formData.trip_id == this.trips[l].trp_rn_id) {
          this.formData.rtplydata = this.trips[l].rte_arc_lne_tx;
          this.formData.sdate = this.trips[l].actl_strt_ts;
          this.formData.edate = this.trips[l].actl_end_ts;
        }
      }
      clbk();
    }, (err) => {
      // console.log(err);
    });
  }
  getPreviousNotifications() {

    this.showLdr = true;
    const rte = `dashboard/enterprise/notifications/list`;
    this.apiServ.post(this.filterData, rte).subscribe((res) => {
      this.showLdr = false;
      if (res['status'] == 200) {
        let data = res['data'];
        this.previousNotifications = data;
      }
    }, (error) => {
      this.showLdr = false;
    })
  }
  initialize() {
    this.clientDetails = JSON.parse(localStorage.getItem('wto_clients'));
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {
      this.playbackmap = L.map('playbackmap', {
        center: [this.clientDetails[0].lat, this.clientDetails[0].lng],
        zoom: 3
      });
      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        // maxZoom: 18,
        // minZoom: 3,
        attribution: ''
      });
      tiles.addTo(this.playbackmap);
      this.playbackmap.setView([this.clientDetails[0].lat, this.clientDetails[0].lng], 13);
    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }

  }

  getTripMap(c) {

    console.log(c);
    this.polylinePoints = JSON.parse(c.rtplydata);

    if (this.route_Polyline) {
      this.playbackmap.removeLayer(this.route_Polyline);
    }
    if (this.startPBMarker) {
      this.playbackmap.removeLayer(this.startPBMarker);
    }

    if (this.endPBMarker) {
      this.playbackmap.removeLayer(this.endPBMarker);
    }

    if (this.actl_Polyline) {
      this.playbackmap.removeLayer(this.actl_Polyline);

    }
    if (this.startPBMarker1) {
      this.playbackmap.removeLayer(this.startPBMarker1);
    }

    if (this.endPBMarker1) {
      this.playbackmap.removeLayer(this.endPBMarker1);
    }
    // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
    var startIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/startPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    this.startPBMarker = L.marker(this.polylinePoints[0], {
      icon: startIcon
    }).addTo(this.playbackmap);
    // ----------Fence END Area (flag position) -- Leaflet OS ----------//
    var endIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/endPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    var k = this.polylinePoints.length - 1
    this.endPBMarker = L.marker(this.polylinePoints[k], {
      icon: endIcon
    }).addTo(this.playbackmap);
    if (c) {
      this.route_Polyline = L.polyline(JSON.parse(c.rtplydata), {
        color: '#008000',
        weight: 5,
        lineCap: 'butt',
        opacity: 0.6,
        smoothFactor: 10.0,
        lineJoin: 'round'
      }).addTo(this.playbackmap);
    }
    this.playbackmap.fitBounds(this.route_Polyline.getBounds());

    let postslctdDt = {
      dt: this.formData.date,
      data: c
    }
    const rte = `dashboard/enterprise/get_tripTrvlRoute`;
    this.showLdr = true;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      this.showLdr = false;

      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {

          let rteDta = res['data'];
          console.log(rteDta)
          var restr = '';
          var tmp;
          for (var i = 0; i < rteDta.length; i++) {
            restr += (restr == "") ? "[" + rteDta[i].lat + "," + rteDta[i].lng + "]" : ",[" + rteDta[i].lat + "," + rteDta[i].lng + "]";
          }
          // console.log("[" + restr + "]");

          // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
          var startIcon1 = new L.Icon({
            iconUrl: '/assets/images/markers/playback/startPoint.png',
            iconSize: [42, 45],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
          this.startPBMarker1 = L.marker([rteDta[0].lat, rteDta[0].lng], {
            icon: startIcon1
          }).addTo(this.playbackmap);
          // ----------Fence END Area (flag position) -- Leaflet OS ----------//
          var endIcon1 = new L.Icon({
            iconUrl: '/assets/images/markers/playback/endPoint.png',
            iconSize: [42, 45],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
          var k1 = rteDta.length - 1
          this.endPBMarker1 = L.marker(rteDta[k], {
            icon: endIcon1
          }).addTo(this.playbackmap);


          this.actl_Polyline = L.polyline(JSON.parse("[" + restr + "]"), {
            color: 'red',
            weight: 4,
            lineCap: 'butt',
            opacity: 0.5,
            smoothFactor: 10.0,
            lineJoin: 'round'
          }).addTo(this.playbackmap);


        }
      }
    }, (err) => {
      this.showLdr = false;
      console.log(err);
    });

  }
  asgndrvrs: any = [];
  asgnervrs() {
    this.apiServ.get('drivers/drivers/asgnedrvrs/' + this.formData.vehicle_id).subscribe((res) => {
      this.asgndrvrs = res['data'];
    }, (err) => {
    });
  }
  saveasgnmnt() {
    if (this.formData.vehicle_nu !== undefined && this.formData.date !== undefined && this.formData.trip_squence_nu !== undefined && this.formData.crw_id !== undefined) {
      let postdata = {
        vhclnm: this.formData.vehicle_nu,
        dte: this.formData.date,
        trp_num: this.formData.trip_squence_nu,
        crw_id: this.formData.crw_id
      };
      this.apiServ.put(postdata, 'drivers/drivers/wbtripassign').subscribe((res) => {
        if (res['status'] === 200) {
          this.modal.success({
            nzTitle: 'Driver Details',
            nzContent: 'Sucessfully Updated'
          });
        }
      });
    } else {
    }
  }
  onCellPrepared(colDef, e) {
    // console.log(e);
    if (e.rowType === 'data' && e.data.ntfcn_ctgry_nm == `${e.key.ntfcn_ctgry_nm}` && e.column.dataField == 'ntfcn_ctgry_nm') {
      e.cellElement.style.color = `${e.data.ntfcn_ctgry_actv_clr_cd_tx}`;
      e.cellElement.style.background = `${e.data.ntfcn_ctgry_clr_cd_tx}`;
    }
    if (e.rowType === 'data' && e.data.sts == 'OPEN' && e.column.dataField == 'sts') {
      e.cellElement.style.color = `#e64a19`;
    }
  }

  formSubmit() {
    console.log(this.formData);
  }
  selectedTrip;
  getSldr(evnt) {
    console.log(evnt)
    if (evnt.rowType == 'data' && evnt.column.dataField == "trp_sqnc_nu") {
      console.log('Trippp');
      this.selectedTrip = evnt.data;
      this.visible = true;
    }
  }
}
