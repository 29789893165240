<nz-layout>
  <nz-layout class="mt-40">
    <nz-content>
      <div class="row hdrBar">
        <div class="col-lg-12 col-md-12 mg-a">
          <h1>Trips</h1>
        </div>
      </div>
      <div class="ds-card grid frmSec">
        <form nz-form [formGroup]="topfltrForm">
          <div class="row">

            <div class="col-lg-2 col-md-2" *ngIf="!showLbl">
              <nz-form-label>Select ULB</nz-form-label>
              <nz-form-item>

                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please select ULB" formControlName="tnt_id"
                    (ngModelChange)="onTenantChange($event)" [(ngModel)]="tnt_id">
                    <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-2 col-md-2" *ngIf="showLbl">
              <nz-form-label>ULB</nz-form-label>
              <div>{{tnt_nm}}</div>
            </div>
            <div class="col-lg-2 col-md-2">
              <nz-form-label>Select Trip Type</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select Trip Type"
                    formControlName="trp_typ_id" (ngModelChange)="onTrpTypSelected($event)" [(ngModel)]="trp_typ_id">
                    <nz-option *ngFor="let a of tripTypeLst" [nzValue]="a.trp_typ_id" [nzLabel]="a.trp_typ_nm">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-2 col-md-2" *ngIf="showSchvlym">
              <nz-form-label>Select Sachivalayam</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select sachivalayam"
                    formControlName="slctedSvm_id" (ngModelChange)="onsvmgetSelected($event)" [(ngModel)]="svm_id">
                    <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                    <nz-option *ngFor="let a of svmLst" [nzValue]="a.svm_id" [nzLabel]="a.svm_nm">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-2 col-md-2">
              <nz-form-label>Select Vehicle</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select Vehicle"
                    formControlName="slctedasrt" (ngModelChange)="onasrtgetSelected($event)" [(ngModel)]="asrt_id">
                    <nz-option *ngFor="let a of vehclesLst" [nzValue]="a.asrt_id" [nzLabel]="a.asrt_nm">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
        <div class="row" style="padding: 4px;" *ngIf="showSchvlym">Please Select Sachivalayam and Vehicle Registration Number for assigning Trips </div>
        <div class="row" style="padding: 4px;" *ngIf="!showSchvlym">Please Select  Vehicle Registration Number for assigning Trips </div>

        
      </div>


      <div class="bg-wp">
        <nz-steps [nzCurrent]="current">
          <nz-step nzTitle="How to Create a Route" [nzDisabled]="isEdit==true"></nz-step>
          <nz-step nzTitle="Route Creation" [nzDisabled]="isEdit==true"></nz-step>
          <nz-step nzTitle="Assign Timings" [nzDisabled]="isEdit==true"></nz-step>
          <nz-step nzTitle="Edit" [nzDisabled]="isEdit==true"></nz-step>
          <nz-step nzTitle="Review"></nz-step>
        </nz-steps>
        <div class="steps-action">
          <button nz-button nzType="primary" class="prev_cls" (click)="pre()" *ngIf="current > 0 && isEdit==false && current!=4">
            <span>Previous</span>
          </button>
          <button nz-button nzType="primary" class="prev_cls" (click)="pre()" *ngIf="current==4">
            <span>Edit</span>
          </button>
          <button nz-button nzType="primary" class="next_cls" (click)="next()" *ngIf="current < 4">
            <span>Next</span>
          </button>
          <button nz-button nzType="primary" class="dne_cls" (click)="done()" *ngIf="current === 4 && isEdit==false && shwTrp==true">
            <span>Save</span>
          </button>
        </div>
        <div class="steps-content" *ngIf="index=='zero-content'">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <ul>
                <p class="trp_hdr_cls">Trip - 1</p>
                <li>Starts at Vehicle Yard.</li>
                <li *ngIf="showSchvlym">Goes to Sachivalayam/Micropocket and does door-to-door collection.</li>
                <li *ngIf="!showSchvlym">Goes and does door-to-door collection.</li>
                <li>Goes to Transfer Point/Transfer Station/Unloading point with dry and wet waste.</li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6">
              <img class="img_class" src="assets/images/primary-1.png">
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <ul>
                <p class="trp_hdr_cls">Trip - 2(Might Repeat in Different Routes)</p>
                <li>Starts at Transfer Point after Previous Trip Unloading.</li>
                <li *ngIf="showSchvlym">Goes to Sachivalayam/Micropocket and does door-to-door collection.</li>
                <li *ngIf="!showSchvlym">Goes and does door-to-door collection.</li>
                <li>Goes to Transfer Point/Transfer Station/Unloading point with dry and wet waste.</li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6">
              <img class="img_class" src="assets/images/primary-2.png">
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <ul>
                <p class="trp_hdr_cls">Trip - n (Final Trip)</p>
                <li>Starts at Transfer Point after Previous Trip Unloading.</li>
                <li>Goes back to Vehicle
                  yard.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6">
              <img class="img_class" src="assets/images/primary-3.png">
            </div>
          </div>
        </div>
        <div class="steps-content" *ngIf="index=='First-content'">
          <div class="row mat-caption">
            <div class="col-lg-6 col-md-6">
              <form nz-form [formGroup]="tripFnceForm">

                <div class="row">
                  <div class="col-lg-5 col-md-5">
                    <nz-form-label>Select Home location / vehicle yard</nz-form-label>
                  </div>
                  <div class="col-lg-5 col-md-5">
                    <nz-form-item style="width: 100%;">
                      <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Home location / vehicle yard"
                          formControlName="strt_fnce_id" (ngModelChange)="frmfnceSelected($event)" style="width: 100%;">
                          <nz-option *ngFor="let f of frmfnceFltrOptns" [nzValue]="f.fnce_id" [nzLabel]="f.fnce_nm">
                          </nz-option>
                        </nz-select>
                        <div>{{frm_fnce_grp_nm}}</div>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <div class="row">
                  <!-- Home location / vehicle yard:: -->
                  This is the location where the vehicle starts every day(ex:Vehilce Yard or Vehicle Shed) and this is
                  location where the vehicle stays after the completion of all trips.
                </div>
                <div class="row brder_cntr"><i nz-icon nzType="arrow-down" nzTheme="outline"></i></div>
                <div class="row">
                  <div class="col-lg-5 col-md-5">
                    <nz-form-label>Select Unloading location</nz-form-label>
                  </div>
                  <div class="col-lg-5 col-md-5">
                    <nz-form-item style="width: 100%;">
                      <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Unloading location"
                          formControlName="end_fnce_id" (ngModelChange)="endfnceSelected($event)" style="width: 100%;">
                          <nz-option *ngFor="let f of tofnceFltrOptns" [nzValue]="f.fnce_id" [nzLabel]="f.fnce_nm">
                          </nz-option>
                        </nz-select>
                        <div>{{end_fnce_grp_nm}}</div>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <div class="row">
                  <!-- Unloading location:: -->
                  This is the Location where the vehicle discharges the collected waste(ex:Dump Yard or Transfer
                  Station).
                </div>
              </form>
              <div class="row" style="border-top:1px dotted rgb(206, 248, 248);margin-top:10px;">
                <a (click)="opensideBar('addFncePanel','')">Click here</a> &nbsp;to add loctions if your prefered location
                is not there in locations list.
              </div>
              <div class="row">
                A location point is said to be covered by a vehicle when the vehicle stays around the location point
                within 40
                meters of radius
              </div>
            </div>
            <div
              class="col-lg-6 col-md-6                                                                                                                                                                                            ">

              <div id="map"></div>

            </div>
          </div>
   
        </div>
        <div class="steps-content" *ngIf="index=='Second-content'">
          <div *ngIf="shwTrp==true">

            <form nz-form [formGroup]="routeForm">
              <div>

                <div formArrayName="routes" class="fulwdth">
                  <div class="brdr_btmsprt" *ngFor="let r of routeForm.get('routes').controls; let i = index">
                    <!-- <legend>
                    <h3>routes {{ i + 1 }}:</h3>
                  </legend> -->
                    <div [formGroupName]="i">
                      <!-- <label>routes Name: </label>
                    <input formControlName="routes" /><span
                      ><button (click)="deleteroutes(i)">Delete routes</button>
                      <button (click)="addNewroutes(i)">Add new routes</button><br /><br />
          </span> -->
                      <div class="col-lg-12 col-md-12" *ngIf="i==0">First trip Starts at Vehicle Yard and Starts
                        Collection from
                        door-to-door and goes to Unloading Point</div>
                      <div class="col-lg-12 col-md-12" *ngIf="i== routeForm.get('routes').controls.length-1">Final trip
                        Starts at
                        Unloading point and directly reaches to Starting Location/Home Fence</div>
                      <div class="col-lg-12 col-md-12">
                        <div class="row">

                          <div class="pt-12">{{i+1}}</div>

                          <div class="col-lg-2 col-md-2">
                            <nz-form-label>Trip Starting Location</nz-form-label>
                            <nz-form-item>
                              <nz-form-control>
                                <nz-select class="slctDsble" nzShowSearch nzAllowClear
                                  nzPlaceHolder="Select Starting Location" formControlName="strt_fnce_id" nzDisabled>
                                  <nz-option *ngFor="let f of fnceFltrOptns" [nzValue]="f.fnce_id"
                                    [nzLabel]="f.fnce_nm">
                                  </nz-option>
                                </nz-select>
                                <!-- <div class="row dsbleBox"> {{strt_fnce_nm}}</div> -->
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                          <div class="col-lg-2 col-md-2">
                            <nz-form-label>Trip Ending Location</nz-form-label>
                            <nz-form-item>
                              <nz-form-control>
                                <nz-select class="slctDsble" nzShowSearch nzAllowClear
                                  nzPlaceHolder="Select Ending Location" formControlName="end_fnce_id" nzDisabled>
                                  <nz-option *ngFor="let f of fnceFltrOptns" [nzValue]="f.fnce_id"
                                    [nzLabel]="f.fnce_nm">
                                  </nz-option>
                                </nz-select>
                                <!-- <div class="row dsbleBox">{{end_fnce_nm}}</div> -->
                              </nz-form-control>
                            </nz-form-item>
                          </div>

                          <div class="col-lg-2 col-md-2">
                            <nz-form-label>Scheduled start Time</nz-form-label>
                            <nz-form-item>
                              <nz-form-control>
                                <nz-time-picker formControlName="arvl_ts" [nzFormat]="timeFormat"
                                  (ngModelChange)="strtedtime($event,i)" [nzDisabled]="isEdit==true">
                                </nz-time-picker>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                          <div class="col-lg-2 col-md-2">
                            <nz-form-label>Scheduled end Time</nz-form-label>
                            <nz-form-item>
                              <nz-form-control>
                                <nz-time-picker formControlName="dprtr_ts" [nzFormat]="timeFormat"
                                  (ngModelChange)="endtime($event,i)" [nzDisabled]="isEdit==true">
                                </nz-time-picker>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                          <div class="col-lg-1 col-md-1" (click)="addRoute(i,routeForm.controls.routes);"
                            *ngIf="i < routeForm.get('routes').controls.length-1 && i>0">
                            <i nz-icon nzType="plus-square" nzTheme="outline" class="plsBtn mg-tp"></i><span>Add
                              Trip</span>
                          </div>
                          <div class="col-lg-1 col-md-1" (click)="deleteRoute(routeForm.controls.routes, i)"
                            *ngIf="i>1 && i < routeForm.get('routes').controls.length-1">
                            <i nz-icon nzType="delete" nzTheme="outline" class="delBtn mg-tp"></i><span>Delete
                              Trip</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="row">
              Click&nbsp; <i nz-icon nzType="plus-square" nzTheme="outline" class="plsBtn"></i> &nbsp;
              to&nbsp;<span style="font-weight:bold">Add</span> &nbsp; the trip same
              as the intermediate trip between first and last trips
            </div>
            <div class="row">
              Click&nbsp; <i nz-icon nzType="delete" nzTheme="outline" class="delBtn"></i> &nbsp; to&nbsp;<span
                style="font-weight:bold">Delete</span> &nbsp; an intermediate trip between first and last trips
            </div>
          </div>
          <div *ngIf="shwTrp==false">
            <div class="row">Please Select Home location / vehicle yard and Unloading location</div>
          </div>

          <!-- <pre>{{ routeForm.value | json }}</pre> -->

        </div>
        <div class="steps-content" *ngIf="index=='third-content'">
          <div *ngIf="shwTrp==true">
            <form nz-form [formGroup]="routeForm">
              <div>
                <div formArrayName="routes" class="fulwdth">
                  <div class="brdr_btmsprt" *ngFor="let r of routeForm.get('routes').controls; let i = index"
                    [formGroupName]="i">
                    
                      <div class="col-md-12">
                      <h5>Trip {{i+1}}</h5>
                      </div>
                    
                    <div class="row">
                      <div class="col-lg-3 col-md-3" *ngIf="isEdit==false">
                        <nz-form-label>Trip Starting Location</nz-form-label>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select class="slctDsble" nzShowSearch nzAllowClear
                              nzPlaceHolder="Select Starting Location" formControlName="strt_fnce_id"
                              (ngModelChange)="svmStrtfnceSelected($event,i)" nzDisabled>
                              <nz-option *ngFor="let f of fnceFltrOptns" [nzValue]="f.fnce_id" [nzLabel]="f.fnce_nm">
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div class="col-lg-2 col-md-2" *ngIf="isEdit==true">
                        <nz-form-label>Trip Starting Location</nz-form-label>
                        <div>{{r.value.strt_fnce_nm}}</div>
                      </div>
                      <div class="col-lg-2 col-md-2" *ngIf="isEdit==false">
                        <nz-form-label>Trip Ending Location</nz-form-label>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select *ngIf="i < routeForm.get('routes').controls.length-1" nzShowSearch nzAllowClear
                              nzPlaceHolder="Select Stop" formControlName="end_fnce_id"
                              (ngModelChange)="svmEndfnceSelected($event,i,routeForm.controls.routes)"
                              [nzDisabled]="isEdit==true">
                              <nz-option *ngFor="let f of fnceFltrOptns" [nzValue]="f.fnce_id" [nzLabel]="f.fnce_nm">
                              </nz-option>
                            </nz-select>
                            <nz-select *ngIf="i == routeForm.get('routes').controls.length-1" nzShowSearch nzAllowClear
                              nzPlaceHolder="Select Stop" formControlName="end_fnce_id"
                              (ngModelChange)="svmEndfnceSelected($event,i,routeForm.controls.routes)" nzDisabled>
                              <nz-option *ngFor="let f of fnceFltrOptns" [nzValue]="f.fnce_id" [nzLabel]="f.fnce_nm">
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div class="col-lg-2 col-md-2" *ngIf="isEdit==true">
                        <nz-form-label>Trip Ending Location</nz-form-label>
                        <div>{{r.value.end_fnce_nm}}</div>
                      </div>
                      <div class="col-lg-2 col-md-2" *ngIf="isEdit==false">
                        <nz-form-label>Scheduled start Time</nz-form-label>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-time-picker formControlName="arvl_ts" [nzFormat]="timeFormat"
                              (ngModelChange)="strtedtime($event,i)" nzDisabled>
                            </nz-time-picker>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div class="col-lg-2 col-md-2" *ngIf="isEdit==true">
                        <nz-form-label>Schedule start Time</nz-form-label>
                        <div [hidden]="r.value.shwEdtStrt==true">{{r.value.arvl_ts | date: 'HH:mm'}} <span> 
                          <i nz-icon nzType="edit" nzTheme="outline" (click)="showStrtEdit(i,routeForm.controls.routes)"></i></span></div>
                        <nz-form-control [hidden]="r.value.shwEdtStrt==false">
                          <nz-time-picker formControlName="arvl_ts" [nzFormat]="timeFormat"
                          (ngModelChange)="edtstrtedtime($event,i,routeForm.controls.routes)">
                          </nz-time-picker>
                        </nz-form-control>
                      </div>
                      <div class="col-lg-2 col-md-2" *ngIf="isEdit==false">
                        <nz-form-label>Scheduled end Time</nz-form-label>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-time-picker formControlName="dprtr_ts" [nzFormat]="timeFormat"
                              (ngModelChange)="endtime($event,i)" nzDisabled>
                            </nz-time-picker>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div class="col-lg-2 col-md-2" *ngIf="isEdit==true">
                        <nz-form-label>Schedule end Time</nz-form-label>
                        <div [hidden]="r.value.shwEdtEnd==true">{{r.value.dprtr_ts | date: 'HH:mm'}}<span>
                          <i nz-icon nzType="edit" nzTheme="outline" (click)="showEndEdit(i,routeForm.controls.routes)"></i>
                      </span></div>
                      <nz-form-control [hidden]="r.value.shwEdtEnd==false">
                        <nz-time-picker formControlName="dprtr_ts" [nzFormat]="timeFormat"
                        (ngModelChange)="edtEndtime($event,i,routeForm.controls.routes)">
                        </nz-time-picker>
                      </nz-form-control>
                      </div>
                      <div class="col-lg-2 col-md-2" style="margin-top: 35px;" *ngIf="isEdit==true">
                        <button nz-button nzType="primary" class="prev_cls"
                          (click)="showIpForm(routeForm.controls.routes,i,i)">
                          <span>Intermediate Locations</span>
                        </button>
                      </div>
                      <div class="col-lg-1 col-md-1" style="margin-top: 35px;" *ngIf="isEdit==true">
                        <button nz-button nzType="primary" style="background-color: #ed5757;" class="prev_cls"
                          (click)="showConfirmDelete(i,routeForm.controls.routes)">
                          <span>Delete Trip</span>
                        </button>

                      </div>
                    </div>
                    <div *ngIf="isEdit==true">
                    <div class="innerFrm" [hidden]="r.value.shwIpadd == false" >
                      <h5>Intermediate Locations</h5>
                      <div formArrayName="points" class="fulwdth" >
                        <div>
                          <div *ngFor="let point of r.get('points').controls;let j = index">
                            <div [formGroupName]="j">
                              <div class="row">
                                <!-- <div class="pt-12">{{j+1}}</div> -->
                                <div class="col-lg-4 col-md-4">
                                  <nz-form-label>Intermediate Location {{j+1}}</nz-form-label>
                                  <nz-form-item>
                                    <nz-form-control>
                                      <nz-select class="slctDsble" nzShowSearch nzAllowClear style="width:100%;"
                                        nzPlaceHolder="Select Intermediate Location" formControlName="ip_fnce_id" (ngModelChange)="onIpSelected($event,j,r.controls.points)">
                                        <nz-option *ngFor="let f of interfnceFltrOptns" [nzValue]="f.fnce_id"
                                          [nzLabel]="f.fnce_nm">
                                        </nz-option>
                                      </nz-select>
                                    </nz-form-control>
                                  </nz-form-item>
                                </div>

                                <div class="col-lg-4 col-md-4" (click)="addIpinRoute(j,r.controls.points);"
                                  *ngIf="j>=0">
                                  <i nz-icon nzType="plus-square" nzTheme="outline" class="plsBtn mg-tp"></i><span>Add
                                    Intermediate Location</span>
                                </div>
                                <div class="col-lg-4 col-md-4" (click)="deleteIpinRoute(r.controls.points, j)"
                                  *ngIf="j>=0">
                                  <i nz-icon nzType="delete" nzTheme="outline" class="delBtn mg-tp"></i><span>Delete
                                    Intermediate
                                    Location</span>
                                </div>
                              </div>
                            </div>
                            <!-- </fieldset> -->
                          </div>
                          <!-- <button (click)="addNewpoint(r.controls.points)" *ngIf="current==3">
                        Add new point
                      </button> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="row" *ngIf="isEdit==true">
                          <div class="col-lg-4 col-md-4"> </div>
                          <div class="col-lg-4 col-md-4">
                            <button nz-button nzType="primary" class="dne_cls" (click)="saveIps(r.controls.points,r.controls)">
                              <span>Update</span>
                            </button>
                          </div>
                          <div class="col-lg-4 col-md-4"> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="innerFrm"  *ngIf="isEdit==false">
                      <h5>Intermediate Locations</h5>
                      <div formArrayName="points" class="fulwdth">
                        <div>
                          <div *ngFor="let point of r.get('points').controls;let j = index">
                            <div [formGroupName]="j">
                              <div class="row">
                                <div class="col-lg-4 col-md-4">
                                  <nz-form-label>Intermediate Location {{j+1}}</nz-form-label>
                                  <nz-form-item>
                                    <nz-form-control>
                                      <nz-select class="slctDsble" nzShowSearch nzAllowClear style="width:100%;"
                                        nzPlaceHolder="Select Intermediate Location" formControlName="ip_fnce_id" (ngModelChange)="onIpSelected($event,j,r.controls.points)">
                                        <nz-option *ngFor="let f of interfnceFltrOptns" [nzValue]="f.fnce_id"
                                          [nzLabel]="f.fnce_nm">
                                        </nz-option>
                                      </nz-select>
                                    </nz-form-control>
                                  </nz-form-item>
                                </div>

                                <div class="col-lg-4 col-md-4" (click)="addIpinRoute(j,r.controls.points);"
                                  *ngIf="j>=0">
                                  <i nz-icon nzType="plus-square" nzTheme="outline" class="plsBtn mg-tp"></i><span>Add
                                    Intermediate Location</span>
                                </div>
                                <div class="col-lg-4 col-md-4" (click)="deleteIpinRoute(r.controls.points, j)"
                                  *ngIf="j>=0">
                                  <i nz-icon nzType="delete" nzTheme="outline" class="delBtn mg-tp"></i><span>Delete
                                    Intermediate
                                    Location</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="row" *ngIf="isEdit==true">
                          <div class="col-lg-4 col-md-4"> </div>
                          <div class="col-lg-4 col-md-4">
                            <button nz-button nzType="primary" class="dne_cls" (click)="saveIps(r.controls.points)">
                              Update
                            </button>
                          </div>
                          <div class="col-lg-4 col-md-4"> </div>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>

              </div>
            </form>
            </div>
          <div *ngIf="shwTrp==false">
            <div class="row">Please Select Home location / vehicle yard and Unloading location</div>
          </div>
        </div>
        <div class="steps-content" *ngIf="index=='fourth-content'">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="ds-card grid">
                <dx-data-grid class="p-8" id="gridContainer" [dataSource]="tableData" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                    [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%" 
                    [wordWrapEnabled]="true"
                    [masterDetail]="{ enabled: true, template: 'detail' }" >
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                        [showInfo]="true"></dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                    <dxo-group-panel [visible]="true"></dxo-group-panel>
                    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                    <dxo-column-chooser [enabled]="true" mode="select">
                    </dxo-column-chooser>
                    <dxo-export [enabled]="true" [allowExportSelectedData]="true"
                        fileName="Holiday Profiles"></dxo-export>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field"
                        [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                    </dxi-column>
                    <dxi-column [visible]="true"
                        type="buttons" [width]="100" caption="view" cellTemplate="viewTemplate">
                        <div *dxTemplate="let data of 'viewTemplate'">
                            <div class="tblEdtBtn" (click)="viewMap(data)">
                                Map
                            </div>
                        </div>
                    </dxi-column>
                   
                    <div *dxTemplate="let entCust of 'detail' index as i">
                         <dx-data-grid id="gridContainer" [dataSource]="entCust.data.points" style="width:100%" [wordWrapEnabled]="true">
                            <dxi-column *ngFor="let f of columnDefs2" [visible]="!f.hide" [dataField]="f.field"
                                [caption]="f.headerName" [width]="f.width" [allowResizing]="true"
                                [alignment]="f.alignment">
                            </dxi-column>
      
                        </dx-data-grid>
                    </div>
                </dx-data-grid>
            </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div id="map2"></div>
            </div>
          </div>
         
        </div>
      </div>
    </nz-content>
    <ds-loading *ngIf="showLdr"></ds-loading>

  </nz-layout>
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" *ngIf="opnSdebar == true" class="sdr">
    <div *ngIf="ShowAddFnce == true">
      <div class="row hdr">
        <div class="col-lg-10 col-md-10">
          <h1 class="sdrttl"> Add Location</h1>
        </div>
        <div class="col-lg-2 col-md-2 mg-a" (click)="closeSider();">
          <i nz-icon nzType="close-circle" nzTheme="outline" class="clseicn"></i>
        </div>
      </div>
      <app-location-add [tntId]="selctdtenant" *ngIf="tnt_id"></app-location-add>
    </div>
  </nz-sider>
</nz-layout>