<ds-loading *ngIf="showLdr"></ds-loading>
<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" nzTitle="Vehicle Dashboard"
    (nzOnClose)="visible = false" class="dashbSlider">
    <app-vehicle-slider></app-vehicle-slider>
</nz-drawer>

<div class="row hdrBar">
    <div>
        <h1> Alert Dashboard<span class="tntTxt"></span></h1>
    </div>
</div>
<div class="main" >
    <div class="row" *ngFor="let cunts of alrtcunt">
        <ul class="cards">
            <li class="cards_item" >
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);" >
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Total Vehicles</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt" (click)="getgrdata(1)">
                                <h1 class="crdHdr_sb_sm">{{cunts.ttl_vcls}}
                                </h1>
                                <h1 class="card_sub_title">Total Vehicles</h1>
                            </div>
                            <div class="col card_prt" (click)="getgrdata(6)">
                                <h1 class="crdHdr_sb_sm">
                                    {{cunts.ntstr_cnt}}
                                </h1>
                                <h1 class="card_sub_title">Vehicles not started</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" >
                <div class="card_g" style="background:linear-gradient(135deg, #7f75e5 0%,#4e49b9 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Delayed Vehicles</h1>
                        </div>
                        <div class="row" >
                            <div class="col card_prt" nz-tooltip nzTooltipTitle="Vehicles Late Departure From Vechile Yard"  (click)="getgrdata(2)">
                                <h1 class="crdHdr_sb_sm">{{cunts.count_dep}}
                                </h1>
                                <h1 class="card_sub_title">Late Departure</h1>
                            </div>
                            <div class="col card_prt" nz-tooltip nzTooltipTitle="Vehicles Late Arrival From Vechile Yard" (click)="getgrdata(3)">
                                <h1 class="crdHdr_sb_sm">
                                    {{cunts.count_arvl}}
                                </h1>
                                <h1 class="card_sub_title">Late Arrival</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #7f75e5 0%,#4e49b9 100%);">
                    <div class="card_content" nz-tooltip nzTooltipTitle="Route Deviated vechiles " (click)="getgrdata(4)">
                        <div class="row">
                            <h1 class="crdHdr">Route Deviated</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{cunts.count_rte_dvt}}
                                </h1>
                                <h1 class="card_sub_title">Vehicles</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <div class="card_content" nz-tooltip nzTooltipTitle="Vehicles Stoppage more than 5 minutes" (click)="getgrdata(5)">
                        <div class="row">
                            <h1 class="crdHdr"> Unauthorized Stoppage</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{cunts.stpg_cnt}}
                                </h1>
                                <h1 class="card_sub_title">Vehicles</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

    </div>
    <div class="row bg-wp">
        <div class="heading">
        <h1 *ngIf="indctr==1" style="color: red;">Total Vehicles</h1>
        <h1 *ngIf="indctr==2" style="color: red;">Late Departure Vehicles</h1>
        <h1 *ngIf="indctr==3" style="color: red;">Late Arrival Vehicles</h1>
        <h1 *ngIf="indctr==4" style="color: red;">Route Deviated Vehicles</h1>
        <h1 *ngIf="indctr==5" style="color: red;">Unauthorized Stoppage Vehicles</h1>
        <h1 *ngIf="indctr==6" style="color: red;">Vehicles Not Started from vechicle Yard</h1>
        
    </div>
        <div class="ds-card tbl grid">
            <dx-data-grid class="p-8" [dataSource]="grdta"[remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
            [wordWrapEnabled]="true" (onToolbarPreparing)="true" (onExporting)="onExporting($event)" >
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>
                <dxo-pager [visible]="" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
              </dxo-search-panel> 
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ulb wise operated Trips">
                </dxo-export>
                <!-- <dxo-export [enabled]="true" [allowExportSelectedData]="false" [fileName]="excelName">
                </dxo-export> -->
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of tripsTrvldcolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
                <dxi-column  [showInColumnChooser]="false" [visible]="indctr==4 || indctr==5 ? true : false" type="buttons" [width]="100" caption="View"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="gotoPlaybck(data,indctr)" >
                        <i nz-icon nzType="node-index" nzTheme="outline"  *ngIf="indctr==4"></i>
                        <i nz-icon nzType="environment" nzTheme="outline" *ngIf="indctr==5"></i>
                        <p *ngIf="indctr==5">Map</p>
                        <p *ngIf="indctr==4">Playback</p>
                    </div>
                </div>
            </dxi-column>
            </dx-data-grid>
        </div>
    </div>
    <div>
    </div>
</div>
