

<div>
    <h2>{{ title }}</h2>
    <p>{{ subtitle }}</p>
          
    <button  (click)="modalRef.destroy()">NO</button>
    <button  (click)="delete()">YES</button>

  </div>
  <!-- <div >
    <div [hidden]="!flag" >
        <h1>Your item was successfully deleted !</h1>
    </div>

</div>
<div >
    <div [hidden]="!flag1" >
        <h1>Unsuccessfully deleted !</h1>
        <button (click)="close()">OK</button>
    </div>

</div> -->