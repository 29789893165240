<div class="dashboard" #fullScreen>

    <div class="page-header">
        <div class="row align-items-md-center">
            <div class="col-md-8">
                <div class="media m-v-10">
                    <div class="media-body m-l-15 header-border">
                        <h6 class="mb-0 header-label" *ngIf="togglefilter == true">Sachivalayam Dashboard</h6>
                        <h6 class="mb-0 header-label" *ngIf="togglefilter == false">{{svm_nm}} Dashboard</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <nz-button-group style="float: right;margin-top: -5px;">
                    <button (click)="openFullscreen()" nz-button nzType="primary"><i nz-icon
                            nzType="expand"></i></button>
                    <button nz-button class="filterbutton" nzType="primary" (click)="toggle()"
                        *ngIf="togglefilter">Filters</button>
                </nz-button-group>
            </div>

        </div>
    </div> <!-- Header End -->
    <ds-loading *ngIf="showLdr"></ds-loading>
    <div class="filtercard" *ngIf="filtermore">
        <div class="row" style="background-color: white;padding-bottom: 10px;">
            <div class="col-lg-4 col-md-12">
                <nz-form-label>
                    Select Ward
                </nz-form-label>
                <nz-select [(ngModel)]="wardobj" [nzShowSearch]="true" nzPlaceHolder="Please select Ward"
                    (ngModelChange)="onWrdChng();">
                    <nz-option [nzValue]="value.wrd_id" [nzLabel]="value.wrd_nm" *ngFor="let value of areasLst">
                    </nz-option>
                </nz-select>
            </div>
            <div class="col-lg-4 col-md-12">
                <nz-form-label>
                    Select Sachivalayam
                </nz-form-label>
                <nz-select [(ngModel)]="svmobj" [nzShowSearch]="true" nzPlaceHolder="Please select Ward">
                    <nz-option [nzValue]="value.svm_id" [nzLabel]="value.svm_nm" *ngFor="let value of svmLst">
                    </nz-option>
                </nz-select>
            </div>

            <div class="col-lg-4 col-md-12" style="margin-top: 25px">
                <div class="text-center">
                    <button nz-button [nzSize]="'large'" nzType="primary" (click)="getdata()">Get Data</button>
                </div>
            </div>
        </div>
    </div>

    <div class="row" [className]="crd_Class" *ngIf="usrAcsErrMsg == false">

        <div class="col-md-4" *ngIf="CrdList && CrdList.length>0">
            <a routerLink="/internal/grievance/dashboard">
                <p
                    style="margin-bottom: 7px !important;text-transform: uppercase;letter-spacing: 2px;font-weight: 500;">
                    e-Complaints
                    <i nz-icon nzType="right-square" nzTheme="twotone"></i></p>
            </a>
            <div nz-row [nzGutter]="8"
                style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);background-image: linear-gradient(to right,rgb(202 111 62), rgb(255 87 0));">
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Total</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{CrdList[0].tl_opn_ct || 0}}</p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Open
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">
                        Yesterday</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{CrdList[0].ystr_tkt_ct || 0}} / {{CrdList[0].ystr_clsd_ct || 0}}</p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Created / Closed
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Today</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{CrdList[0].dy_tkt_ct || 0}} / {{CrdList[0].dy_clsd_ct || 0}} </p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Created / Closed
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Escalated</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{CrdList[0].lvl1_escld_ct || 0}}</p>

                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Open
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="RqstCrdList && RqstCrdList.length>0">
            <a routerLink="/internal/request/dashboard">
                <p
                    style="margin-bottom: 7px !important;text-transform: uppercase;letter-spacing: 2px;font-weight: 500;">
                    e-Requests
                    <i nz-icon nzType="right-square" nzTheme="twotone"></i></p>
            </a>
            <div nz-row [nzGutter]="8"
                style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);background-image: linear-gradient(to right, rgb(122 103 231), rgb(79 51 245));">
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Total</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{RqstCrdList[0].tl_opn_ct || 0}}</p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Open
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">
                        Yesterday</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{RqstCrdList[0].ystr_tkt_ct || 0}} / {{RqstCrdList[0].ystr_clsd_ct || 0}}</p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Created / Closed
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Today</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{RqstCrdList[0].dy_tkt_ct || 0}} / {{RqstCrdList[0].dy_clsd_ct || 0}} </p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Created / Closed
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Escalated</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{RqstCrdList[0].lvl1_escld_ct || 0}}</p>

                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Open
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="insiCrdList && insiCrdList.length>0">
            <a routerLink="/internal/incidentmanagement/dashboard">
                <p
                    style="margin-bottom: 7px !important;text-transform: uppercase;letter-spacing: 2px;font-weight: 500;">
                    CCTV - Incident Management
                    <i nz-icon nzType="right-square" nzTheme="twotone"></i></p>
            </a>
            <div nz-row [nzGutter]="8"
                style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);background-image: linear-gradient(to right, rgb(65 147 57), rgb(25 177 10));">
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Total</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{insiCrdList[0].tl_opn_ct || 0}}</p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Open
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">
                        Yesterday</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{insiCrdList[0].ystr_tkt_ct || 0}} / {{insiCrdList[0].ystr_clsd_ct || 0}}</p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Created / Closed
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Today</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{insiCrdList[0].dy_tkt_ct || 0}} / {{insiCrdList[0].dy_clsd_ct || 0}} </p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Created / Closed
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Escalated</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{insiCrdList[0].lvl1_escld_ct || 0}}</p>

                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Open
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" [className]="crd_Class" *ngIf="usrAcsErrMsg == false">
        <div class="col-md-4" *ngIf="FileCrdList && FileCrdList.length>0">
            <p
                style="margin-bottom: 7px !important;text-transform: uppercase;letter-spacing: 2px;font-weight: 500;margin-top: 7px;">
                E-File Tracking
            </p>
            <div nz-row [nzGutter]="8"
                style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);background-image: linear-gradient(to right, rgb(155 63 77), rgb(179 38 59));">
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Total</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{FileCrdList[0].tl_opn_ct || 0}}</p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Open
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">
                        Yesterday</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{FileCrdList[0].ystr_tkt_ct || 0}} / {{FileCrdList[0].ystr_clsd_ct || 0}}</p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Created / Closed
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Today</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{FileCrdList[0].dy_tkt_ct || 0}} / {{FileCrdList[0].dy_clsd_ct || 0}} </p>
                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Created / Closed
                    </p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0">Escalated</p>
                    <p class="text-center font-size-20 font-weight-400 text-white mt-0 mb-0">
                        {{FileCrdList[0].lvl1_escld_ct || 0}}</p>

                    <p class="text-center font-size-10 font-weight-400 text-white mt-0 mb-8">Open
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <a routerLink="/internal/swm-charges/dashboard">
                <p
                    style="margin-bottom: 7px !important;text-transform: uppercase;letter-spacing: 2px;font-weight: 500;margin-top: 7px;">
                    Collect And Pay<i nz-icon nzType="right-square" nzTheme="twotone"></i></p>
            </a>
            <div class="row" [className]="crd_Class" *ngIf="clctn_dtls && clctn_dtls.length>0">
                <div class="col-lg-6 col-md-12">
                    <div style="background-color: #8848fa !important;text-align: center;padding: 2px;height: 96px;">
                        <div class="row">
                            <div style="display: flex;width: 100%;flex-wrap: wrap;justify-content: space-around;">
                                <div *ngFor="let data of clctn_dtls;">
                                    <div class="text-center font-size-14 font-weight-400 text-white mt-2 mb-0"></div>
                                    <div class="text-center font-size-20 font-weight-400 text-white mt-2 mb-0">
                                        &#x20b9;{{data.ttl_clctn}}
                                    </div>
                                    <div class="text-center font-size-10 font-weight-400 text-white mt-2 mb-8">
                                        {{data.clctn_type_nm}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div style="background-color: #6db01f !important;text-align: center;padding: 2px">

                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="card-count">{{CrdList[0].mm_tkt_ct || 0}}</div>
                                <div class="card-label">Shuttle </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="card-count">{{CrdList[0].mm_clsd_ct || 0}}</div>
                                <div class="card-label">Tennis</div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="card-count">{{CrdList[0].mm_opn_ct || 0}}</div>
                                <div class="card-label">Scate</div>
                            </div>
                        </div>
                        <div class="inrSctn">
                        </div>
                        <div class="cardOnehdng">
                            Asset Booking
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <a routerLink="/internal/owms/dashboard" *ngIf="usrAcsErrMsg == false">
        <p
            style="margin-bottom: 7px !important;text-transform: uppercase;letter-spacing: 2px;font-weight: 500;margin-top: 7px;">
            OWMS<i nz-icon nzType="right-square" nzTheme="twotone"></i></p>
    </a>
    <div class="row" [className]="crd_Class" *ngIf="(usrAcsErrMsg == false) && (CrdList && CrdList.length>0)">
        <div class="col-lg-4 col-md-12 mgnb-2 p-8">
            <div class="dashboard-graph-card ">

                <div class="gates-card-bg-color border-radius-3">
                    <p class="text-center font-size-18 font-weight-600 text-white  mt-0 mb-0">Door to Door waste
                        Collection
                    </p>
                    <p class="text-center font-size-30 font-weight-600 text-white mt-0 mb-0">
                        {{cvrdCrdData.scnd_gts}}/{{cvrdCrdData.tot_gts}}</p>
                    <p class="text-center font-size-12 font-weight-600 text-white mt-0 mb-0">Scanned Gates vs Total
                        Gates today</p>
                    <div>
                        <div class="m--space-10"></div>
                        <div class="progress">
                            <div class="progress-bar m-progress--sm prgsBrGte" role="progressbar"
                                [ngStyle]="getWdth(cvrdCrdData.tot_scnd_prcnt)" aria-valuenow="75" aria-valuemin="0"
                                aria-valuemax="100">
                                {{cvrdCrdData.tot_scnd_prcnt}}%
                            </div>
                        </div>
                        <div>
                            <p class="pdngRgt_4 ulbdshbrdOptns txtClrWhte ng-binding">{{cvrdCrdData.tot_scnd_prcnt}}%
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mgnb-2 p-8" style="margin-top:3%">
                    <p class="crd-gp-hrd" style="color:#41abb3">Gates Scanned Last 7 Days </p>
                    <div style="display: block;" style="margin-top: 1.5%;">
                        <canvas baseChart class="hmechrt" [datasets]="barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions" [colors]="barChartColors" [plugins]="barChartPlugins"
                            [legend]="barChartLegend" [chartType]="barChartType">
                        </canvas>
                    </div>
                </div>



            </div>
        </div>
        <div class="col-lg-4 col-md-12 mgnb-2 p-8">
            <div class="dashboard-graph-card">


                <div class="attendance-card-bg-color border-radius-3">
                    <p class="text-center font-size-18 font-weight-600 text-white  mt-0 mb-0">Attendance</p>
                    <p class="text-center font-size-30 font-weight-600 text-white mt-0 mb-0">
                        {{attndncCrdData.prsnt_emp}}/{{attndncCrdData.tot_emp}}</p>
                    <p class="text-center font-size-12 font-weight-600 text-white mt-0 mb-0">Total Present vs Total
                        Members today</p>
                    <div>
                        <div class="m--space-10"></div>
                        <div class="progress">
                            <div class="progress-bar m-progress--sm prgsBrGte" role="progressbar"
                                [ngStyle]="getAWdth(attdPer)" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                {{attdPer}}%
                            </div>
                        </div>
                        <div>
                            <p class="pdngRgt_4 ulbdshbrdOptns txtClrWhte ng-binding">{{attdPer}}%</p>
                        </div>


                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mgnb-2 p-8" style="margin-top:3%">
                    <p class="crd-gp-hrd" style="color:#5f56bf">Attendance Last 7 Days</p>
                    <div style="display: block;" style="margin-top: 1.5%;">
                        <canvas baseChart class="hmechrt" [labels]="lineChartLabels" [colors]="lineChartColors"
                            [options]="lineChartOptions" [legend]="lineChartLegend" [chartType]="lineChartType"
                            [plugins]="lineChartPlugins" [datasets]="lineChartData">
                        </canvas>
                    </div>
                </div>


            </div>
        </div>

        <div class="col-lg-4 col-md-12 mgnb-2 p-8">
            <div class="dashboard-graph-card">


                <div class="attendance-card-bg-color border-radius-3">
                    <p class="text-center font-size-18 font-weight-600 text-white  mt-0 mb-0">Sanitation Vehicles</p>
                    <p class="text-center font-size-30 font-weight-600 text-white mt-0 mb-0">
                        {{attndncCrdData.prsnt_emp}}</p>
                    <p class="text-center font-size-12 font-weight-600 text-white mt-0 mb-0">Total trips today</p>
                    <div>
                        <div class="m--space-10"></div>
                        <div class="progress">
                            <div class="progress-bar m-progress--sm prgsBrGte" role="progressbar"
                                [ngStyle]="getAWdth(attdPer)" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                {{attdPer}}%
                            </div>
                        </div>
                        <div>
                            <p class="pdngRgt_4 ulbdshbrdOptns txtClrWhte ng-binding">{{attdPer}}%</p>
                        </div>


                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mgnb-2 p-8" style="margin-top:3%">
                    <p class="crd-gp-hrd" style="color:#5f56bf">Attendance Last 7 Days</p>
                    <div style="display: block;" style="margin-top: 1.5%;">
                        <canvas baseChart class="hmechrt" [labels]="lineChartLabels" [colors]="lineChartColors"
                            [options]="lineChartOptions" [legend]="lineChartLegend" [chartType]="lineChartType"
                            [plugins]="lineChartPlugins" [datasets]="lineChartData">
                        </canvas>
                    </div>
                </div>


            </div>
        </div>

    </div>
    <div *ngIf="usrAcsErrMsg == true" class="permMsg">
        <nz-result nzStatus="error" nzTitle="Access Denied"
            nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
        </nz-result>
    </div>

</div>