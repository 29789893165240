import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-new-fine-amounts',
  templateUrl: './new-fine-amounts.component.html',
  styleUrls: ['./new-fine-amounts.component.scss']
})
export class NewFineAmountsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  finesLst: any;
  columnDefs;
  shwSidebar: boolean;
  shwDprtEditDiv: boolean;
  shwDprtDelDiv: boolean;
  sidebarHeading: string;
  fneCtgry;
  filteredOptions = [];
  fneCtgryLst = [];
  fineAmt: any;
  shwLdg: boolean;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

        this.getFinesList();
        this.fineCtgrys();
  }

  fineCtgrys() {
    const rte = `fines/fine/categorys`;
    this.apiSrv.get(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      this.fneCtgryLst = res['data'];
      this.filteredOptions = this.fneCtgryLst;
    });
  }
  onChange(value): void {
    this.filteredOptions = this.fneCtgryLst.filter(option => option.ctgry_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getFinesList() {
    this.shwLdg = true;
    let ct = 0;
    const rte = 'fines/fines/list';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      this.shwLdg = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
      this.finesLst = res['data'];
      this.finesLst.filter(k => {
        // tslint:disable-next-line:no-string-literal
        k['sno'] = ++ct;
      });
      this.columnDefs = [
        { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
        { headerName: 'Fine Category', field: 'ctgry_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Child Category Name', field: 'chld_cat_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Amount', field: 'amnt', alignment: 'right', cellClass: 'pm-grid-number-cell' }
      ];
    } else {
      this.usrAcsErrMsg = true;
    }
  } else {

  }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Fine Amount',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwDprtEditDiv = true;
      this.shwDprtDelDiv = false;
      this.sidebarHeading = 'Edit Fine Amount';
    } else if (opt === 'delete') {
      this.shwDprtEditDiv = false;
      this.shwDprtDelDiv = true;
      this.sidebarHeading = 'Delete Fine Amount';
    } else {
      this.shwDprtEditDiv = false;
      this.shwDprtDelDiv = false;
      // this.dprtNm = '';
      this.sidebarHeading = 'Add New Fine Amount';
    }
  }

  closeSideBar(): void {
    this.shwSidebar = false;
  }

  saveFines() {
    let fneCtgryId;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.fneCtgryLst.length; i++) {
      if (this.fneCtgry === this.fneCtgryLst[i].ctgry_nm) {
        fneCtgryId = this.fneCtgryLst[i].ctgry_id;
      }
    }
    const fineData = {
      id: fneCtgryId,
      amt: this.fineAmt
    };

    const rte = 'fines/new/fine/amounts';
    this.apiSrv.create(fineData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.createMessage('success', 'New Fine Amount Successfully Added');
        this.getFinesList();
      }
    });
  }

  onCellClick(opt, edtData) {
    if (opt === 'edit') {
      this.shwDprtEditDiv = true;
      this.fneCtgry = edtData.data.ctgry_nm;
      this.fineAmt = edtData.data.amnt;
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwDprtEditDiv = false;
      this.shwDprtDelDiv = true;
      this.fneCtgry = edtData.data.ctgry_nm;
      this.fineAmt = edtData.data.amnt;
      this.openSideBar(opt);
    }
  }

  saveEditFines() {
    let fneCtgryId;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.fneCtgryLst.length; i++) {
      if (this.fneCtgry === this.fneCtgryLst[i].ctgry_nm) {
        fneCtgryId = this.fneCtgryLst[i].ctgry_id;
      }
    }
    const fineEdtData = {
      id: fneCtgryId,
      amt: this.fineAmt
    };

    const rte = 'fines/update/fine/amounts';
    this.apiSrv.create(fineEdtData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.createMessage('info', 'Fine Amount Successfully Updated');
        this.getFinesList();
      }
    });
  }

  saveDeleteFines() {
    let fneCtgryId;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.fneCtgryLst.length; i++) {
      if (this.fneCtgry === this.fneCtgryLst[i].ctgry_nm) {
        fneCtgryId = this.fneCtgryLst[i].ctgry_id;
      }
    }
    const fineDelData = {
      id: fneCtgryId,
      amt: this.fineAmt
    };

    const rte = 'fines/delete/fine/amounts';
    this.apiSrv.create(fineDelData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.createMessage('warning', 'Fine Amount Successfully Deleted');
        this.getFinesList();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('Fine Amount Not Deleted');
  }
}
