<ds-loading *ngIf="showLdr"></ds-loading>
<!-- <div class="row hdrBar">
    <div class="col-lg-12 col-md-12 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">Trips Dashboard</h1>
    </div>
</div> -->

<app-common-header [header_name] = "header_label"></app-common-header>
<div class="main">

    <div class="row">
        <div class="col-lg-3 col-md-3">
            <div class="drpLbl">Select Date</div>
            <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                (ngModelChange)="onChange($event)"></nz-date-picker>
        </div>
        <div class="col-lg-3 col-md-3" *ngIf="tntLst.length>1">
            <div class="drpLbl">Select ULB</div>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
                [ngModelOptions]="{standalone: true}">
                <!-- <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option> -->
                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
            </nz-select>
        </div>
        <div class="col-lg-3 col-md-3" *ngIf="tntLst.length==1">
            <div class="drpLbl">ULB</div>
            <div class="slctBx disbledtxt">{{tntLst[0].tnt_nm}}</div>
        </div>
        <div class="col-lg-2 col-md-2 crdCl" *ngIf="ShowClap">
            <p class="slctlbl">Vehicle Vendor</p>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
              [ngModelOptions]="{standalone: true}">
              <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
              <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
            </nz-select>
          </div>
    </div>

    <div class="row">
        <ul class="cards" *ngIf="rdCnts && rdCnts.length>0">
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #1f694b 0%,#257175 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Trips</h1>
                        </div>
                        <div class="row ">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(null,'trips')">{{rdCnts[0].ttl_trps === null ?
                                    "0" :
                                    rdCnts[0].ttl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(1,'trips')">{{rdCnts[0].prmry_trps === null ?
                                    "0" :
                                    rdCnts[0].prmry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Primary</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(0,'trips')">{{rdCnts[0].scndry_trps === null ?
                                    "0" :
                                    rdCnts[0].scndry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Secondary</h1>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title">{{rdCnts[0].ttl_rd_trps === null ? 0 : rdCnts[0].ttl_rd_trps}}</h1>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>

            <!-- <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #557025 0%,#275513 100%);">
                    <div class="card_content" >
                        <div class="row">
                            <h1 class="crdHdr">Primary Route Deviated Trips</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title">{{rdCnts[0].prmry_rd_trps  === null ? 0 : rdCnts[0].prmry_rd_trps }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li> -->
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #557025 0%,#275513 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">travelled Trips</h1>
                        </div>
                        <div class="row ">

                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(null,'trvl')">{{rdCnts[0].trvl_ttl_trps ===
                                    null ? "0" :
                                    rdCnts[0].trvl_ttl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(1,'trvl')">{{rdCnts[0].trvl_prmry_trps ===
                                    null ? "0" :
                                    rdCnts[0].trvl_prmry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Primary</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(0,'trvl')">{{rdCnts[0].trvl_scndry_trps ===
                                    null ? "0" :
                                    rdCnts[0].trvl_scndry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Secondary</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #803334 0%,#771c4a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Route Deviated Trips</h1>
                        </div>
                        <div class="row ">

                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(null,'rd')">{{rdCnts[0].ttl_rd_trps === null ?
                                    "0" :
                                    rdCnts[0].ttl_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(1,'rd')">{{rdCnts[0].prmry_rd_trps === null ?
                                    "0" :
                                    rdCnts[0].prmry_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Primary</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(0,'rd')">{{rdCnts[0].scndry_rd_trps === null ?
                                    "0" :
                                    rdCnts[0].scndry_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Secondary</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #335b80 0%,#251c77 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Not Travelled Trips</h1>
                        </div>
                        <div class="row ">

                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(null,'nt')">{{rdCnts[0].nt_trvl_ttl_trps ===
                                    null ? "0" :
                                    rdCnts[0].nt_trvl_ttl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(1,'nt')">{{rdCnts[0].nt_trvl_prmry_trps ===
                                    null ? "0" :
                                    rdCnts[0].nt_trvl_prmry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Primary</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(0,'nt')">{{rdCnts[0].nt_trvl_scndry_trps ===
                                    null ? "0" :
                                    rdCnts[0].nt_trvl_scndry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Secondary</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

    </div>





    <div class="bg-wp">
        <div class="row" style="padding-left: 12px;">
            <div class="col-xl-3 col-lg-3 col-md-3">
                <div class="drpLbl" style="float: left;">Search Vehicle</div>
                <nz-input-group>
                    <input type="text"
                        style="height: 32px;border-radius: 3px !important; background: linear-gradient(135deg, #ffffff 0%, #e7eaec 100%);border: 1px solid #d9d9d9;"
                        nz-input [(ngModel)]="searchText" placeholder="Search here"
                        [ngModelOptions]="{standalone: true}" />
                </nz-input-group>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3">
                <div class="drpLbl" style="float: left;">Select Category</div>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Category" [(ngModel)]="selectedCtgry"
                    (ngModelChange)="selectCategory($event)">
                    <nz-option nzLabel='All' nzValue='0'></nz-option>
                    <nz-option *ngFor="let c of vehCtgrys" [nzLabel]=c.asrt_ctgry_nm [nzValue]=c.asrt_ctgry_id>
                    </nz-option>
                </nz-select>
            </div> 
            <div class="col-xl-3 col-lg-3 col-md-3">
                <div class="drpLbl" style="float: left;">Select Ward</div>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Ward" [(ngModel)]="slctdWrd"
                    (ngModelChange)="selectWard($event)">
                    <nz-option nzLabel='All' nzValue='0'></nz-option>
                    <nz-option *ngFor="let c of wrds" [nzLabel]=c.wrd_nm [nzValue]=c.wrd_id>
                    </nz-option>
                </nz-select>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 mg-a">
                <div class="inptCls">
                    <span>Scheduled Route<input class="prmry" disabled="true"></span>
                    <span>Travelled Route<input class="scndry" disabled="true"></span>



                </div>
                <!-- <button *ngIf="showsetRte==true" nz-button nzType="primary"  class="sbmtbtn" (click)="addRoute();">
                    Set as master Route
                </button> -->
            </div>
        </div>


        <div class="row" style="width: 100%;height: 600px;overflow: hidden;margin: 0px;">
            <div class="col-lg-4 col-md-4 htscrl" *ngIf="!shwNodata">
                <div class="serchCard" *ngFor="let c of vehTrpLst | filter:searchText"
                    [ngClass]="c.isSelected == true ? 'card_Selected' : 'card_not_selected'">
                    <div class="row">
                        <div class="col-lg-11 col-md-11">
                            <div class="row serchCardPdng" style="padding-top:5px ;">

                                <div class="col-lg-7 col-md-7 col">
                                    <p class="serchCardLeftTxt srchCrdHdr">{{c.asrt_nm}}</p>
                                    <p class="serchCardLeftTxt srchCrdHdr" style="    font-size: 11px;
                                    font-weight: 500;
                                    line-height: 8px;
                                    color: #3f51b5;">{{c.asrt_ctgry_nm}}<span *ngIf="ShowClap"> | {{c.vndr_nm}}</span> | {{c.wrd_nm}}</p>
                                </div>
                                <div class="col-lg-5 col-md-5 col mg-a">
                                    <p *ngIf="c.rd_in" class="serchCardLeftTxt srchCrdHdr" style="text-align: right;
                                    font-size: 13px;
                                    font-weight: 600;
                                    line-height: 10px;
                                    color: #f55;">Route Deviated</p>
                                    <p class="serchCardLeftTxt srchCrdHdr" style="text-align: right;">Trip -
                                        {{c.trp_sqnc_nu}}</p>
                                    <p class="serchCardLeftTxt srchCrdHdr" style="text-align: right;    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 8px;
                                    color: #3f51b5;">{{c.trip_sts}}</p>
                                </div>
                            </div>

                            <div class="row serchCardPdng" style="padding-top:10px ;">

                                <div class="col-lg-2 col-md-2 col">
                                    <p class="serchCardLeftTxt">From</p>
                                </div>
                                <div class="col-lg-1 col-md-1 col">
                                    <p class="serchCardRightTxt" style="text-align: center;">:</p>
                                </div>
                                <div class="col-lg-9 col-md-9 col">
                                    <p class="serchCardRightTxt">{{c.strt_fnce_nm}}</p>
                                    <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">Schedule Start Time:{{c.sch_strt_tm}}</p>
                                    <p class="serchCardRightTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">Start Time:{{c.actl_strt_ts}}</p>
                                </div>

                            </div>

                            <div class="row serchCardPdng" style="padding-top:10px ;">

                                <div class="col-lg-2 col-md-2 col">
                                    <p class="serchCardLeftTxt">To</p>
                                </div>
                                <div class="col-lg-1 col-md-1 col">
                                    <p class="serchCardRightTxt" style="text-align: center;">:</p>
                                </div>
                                <div class="col-lg-9 col-md-9 col">
                                    <p class="serchCardRightTxt">{{c.end_fnce_nm}}</p>
                                    <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">Schedule End Time:{{c.sch_end_tm}}</p>
                                    <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 8px;">End Time:{{c.actl_end_ts}}</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1" style="margin: auto;padding: 0px;cursor: pointer;">
                            <p class="serchCardLeftTxt" style=" font-size: 15px;text-align: center; margin: auto;"
                                (click)="getTripMap(c,slctdDt)">
                                <i nz-icon nzType="right" nzTheme="outline"></i>
                            </p>
                        </div>
                    </div>

                    <div class="row" style="margin: auto;">
                        <div class="col-lg-2 col-md-2"></div>
                        <div class="col-lg-4 col-md-4">
                            <!-- <button nz-button nzType="primary" class="sbmtbtn" (click)="editRoute(c,'nw');"
                            *ngIf="c.shwAddBtn "> -->
                            <!-- && c.rte_arc_lne_tx==undefined -->
                            <!-- Add
                        </button>
                            <button nz-button nzType="primary" class="sbmtbtn" (click)="editRoute(c,'edt');"
                            *ngIf="c.shwAddBtn && c.rte_arc_lne_tx!=undefined">
                            Edit
                        </button> -->
                        </div>
                        <div class="col-lg-6 col-md-6">

                            <button nz-button nzType="primary" class="sbmtbtn" (click)="addRoute();"
                                *ngIf="c.shwAddBtn">
                                Set as master Route
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 htscrl" style="padding: 49px;
            font-weight: 600;" *ngIf="shwNodata">
                No Trips Found for Selected Option(s)
            </div>
            <div class="col-lg-8 col-md-8 htscrl">
                <div id="routeDevtmap"></div>
            </div>
        </div>
        <nz-modal [(nzVisible)]="isVisible"  (nzOnCancel)="handleCancel()"  nzWidth="1000">
            <ng-container *nzModalContent>
                <div
                [ngClass]="{'map-no-sidebar col-md-8': map_stng.show_build_sidebar == true, 'map-with-sidebar col-md-12': map_stng.show_build_sidebar != true }">
        
                <div leaflet style="height: 1200px; width:140%; margin: 0; padding: 0;" leafletDraw
                  (leafletMapReady)="onMapReady($event)"
                  [leafletOptions]="leafletOptions" [leafletLayers]="layers" 
                  [leafletDrawOptions]="drawOptions" [leafletDrawLocal]="drawLocal" 
                  (leafletDrawCreated)="onDrawCreated($event)"
                  (leafletDrawStart)="onDrawStart($event)" (leafletDrawEditStart)="onDrawStart($event)"
                  (leafletDrawEditStop)="onDrawStart($event)" (leafletDrawDeleteStart)="onDrawStart($event)"
                  (leafletDrawDeleteStop)="onDrawStart($event)">
        
                  <!-- Add the drawnItems featureGroup to the map -->
                  <div [leafletLayer]="drawnItems"></div>
                  <div *ngFor="let m of markers" [leafletLayer]="m"></div>
                </div>
        
              </div>
            </ng-container>
          </nz-modal>
    </div>