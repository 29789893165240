<!-- <div>
  <i nz-icon nzType="database" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">Houses</b>
</div> -->
<pg-header title="Houses" ticon="database" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">
  <div>
    <div class="ds-card grid">
      <ds-loading *ngIf="shwLdg"></ds-loading>
      <dx-data-grid id="gridContainer" [dataSource]="hseLst" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true"
        style="width:100%" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
        <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true" fileName="house list"></dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [width]="f.width" [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
          [visible]="f.visible" [allowHeaderFiltering]="f.filter"></dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" type="buttons" [width]="100" caption="Edit"
          cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <!-- <div class="cursor-pointer tblEdtBtn" (click)="onCellClick('edit',data)">
            Edit
          </div> -->
            <div class="tblEdtBtn" (click)="onEdit(data)">
              Edit House
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" type="buttons" [width]="100" caption="Edit"
          cellTemplate="editTemplate2">
          <div *dxTemplate="let data of 'editTemplate2'">
            <div class="tblEdtBtn" (click)="onEditCntct(data)">
              Edit Contact
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons" [width]="100" caption="Delete"
          cellTemplate="delTemplate">
          <div *dxTemplate="let data of 'delTemplate'">
            <div class="tblDelBtn" (click)="onDelete(data)">
              Delete House
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons" [width]="100" caption="Delete"
          cellTemplate="delTemplate2">
          <div *dxTemplate="let data of 'delTemplate2'">
            <div class="tblDelBtn" (click)="onDeleteCntct(data)">
              Delete Contact
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>

  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="350" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
    (nzOnClose)="closeSideBar()">

    <form nz-form [formGroup]="houseForm" *ngIf="shwEditCntctDiv==false && shwDelCntctDiv==false">

      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
          <div class="inptLbl">Gate Name</div>
          <nz-input-group [nzSuffix]="inputClearTpl">
            <input type="text" nz-input placeholder="Enter house number" formControlName="gte_nm"
              (blur)="getGateid()" />
          </nz-input-group>
          <div nz-row *ngIf="shwalert == true" style="margin-top: 10px;" class="blksMrgn">
            <nz-alert [nzCloseable]="true" nzType="error" nzMessage="Please enter valid Gate"></nz-alert>
          </div>
          <div class="inptLbl">Door Number</div>
          <nz-input-group [nzSuffix]="inputClearTpl">
            <input type="text" nz-input placeholder="Enter door number" formControlName="dr_nu" />
          </nz-input-group>
          <div class="inptLbl">Floor Number</div>
          <nz-input-group [nzSuffix]="inputClearTpl">
            <input type="number" nz-input placeholder="Enter floor number" formControlName="flr_nu" />
          </nz-input-group>

          <div class="inptLbl">Total Population</div>
          <nz-input-group [nzSuffix]="inputClearTpl">
            <input type="number" nz-input placeholder="Enter total population" formControlName="ple_ct" />
          </nz-input-group>
        </div>
      </div>

      <!-- <div class="mb-20">
  <div class="inptLbl">ULB</div>
  <input placeholder="Select ULBs" nz-input [(ngModel)]="hseDt.ulb_nm" (ngModelChange)="onUlbChange($event)" formControlName="ulb_id"
    [nzAutocomplete]="auto" />
  <nz-autocomplete #auto>
    <nz-auto-option *ngFor="let d of filteredULBOptions" [nzValue]="d.ulb_nm" (click)="getULBID(d.ulb_id);">{{d.ulb_nm}}</nz-auto-option>
  </nz-autocomplete>
</div> -->
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
          <div class="inptLbl">ULB</div>
          <nz-select nzShowSearch style="width: 100%;" nzPlaceHolder="Select ULB" formControlName="ulb_id"
            (ngModelChange)="getscvlym()">
            <nz-option *ngFor="let u of ulbs" [nzValue]="u.ulb_id" [nzLabel]="u.ulb_nm">
            </nz-option>
          </nz-select>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="inptLbl">Ward</div>
    <nz-select nzShowSearch style="width: 100%;" nzPlaceHolder="Select Ward" formControlName="wrd_id"  [(ngModel)]="hseDt.wrd_nm">
      <nz-option *ngFor="let w of wards" [nzValue]="w.wrd_id" [nzLabel]="w.wrd_nm">
      </nz-option>
    </nz-select> -->


      <div class="inptLbl">Sachivalayam Name</div>
      <nz-select nzShowSearch style="width: 100%;" nzPlaceHolder="Select Sachivalayam" formControlName="svm_id"
        (ngModelChange)="getcluster()">
        <nz-option *ngFor="let s of schvlymlst" [nzValue]="s.svm_id" [nzLabel]="s.svm_nm">
        </nz-option>
      </nz-select>

      <div class="inptLbl">Cluster Name</div>
      <nz-select nzShowSearch style="width: 100%;" nzPlaceHolder="Select Cluster" formControlName="tu_id"
        (ngModelChange)="gethseCtgry()">
        <nz-option *ngFor="let c of clusterlst" [nzValue]="c.tu_id" [nzLabel]="c.tu_nm">
        </nz-option>
      </nz-select>



      <div class="inptLbl">House Type</div>
      <nz-select nzShowSearch style="width: 100%;" nzPlaceHolder="Select House Type" formControlName="hse_ctgry_id"
        (ngModelChange)="gethseStatus()">
        <nz-option *ngFor="let c of hseCtgry" [nzValue]="c.hse_ctgry_id" [nzLabel]="c.hse_ctgry_nm">
        </nz-option>
      </nz-select>

      <div class="inptLbl">House Status</div>
      <nz-select nzShowSearch style="width: 100%;" nzPlaceHolder="Select House Status" formControlName="hse_sts_id">
        <nz-option *ngFor="let s of hseStatus" [nzValue]="s.hse_sts_id" [nzLabel]="s.hse_sts_nm">
        </nz-option>
      </nz-select>
    </form>

    <!-- <div *ngIf="addDtls==true"> -->
    <!-- <div>Add contact person Details</div> -->

    <form nz-form [formGroup]="cntactForm" *ngIf="shwEditDiv==false && shwDelDiv==false">
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
          <div class="inptLbl">Contact Person first Name</div>
          <nz-input-group [nzSuffix]="inputClearTpl">
            <input type="text" nz-input placeholder="Enter First Name" formControlName="frst_nm" />
          </nz-input-group>

          <div class="inptLbl">Contact Person Last Name</div>
          <nz-input-group [nzSuffix]="inputClearTpl">
            <input type="text" nz-input placeholder="Enter Last Name" formControlName="lst_nm" />
          </nz-input-group>
          <div class="inptLbl">Contact Number</div>
          <nz-input-group [nzSuffix]="inputClearTpl" nzErrorTip="MaxLength is 10">
            <input type="Number"nz-input placeholder="Enter Mobile number" formControlName="mbl_nu"/>
          </nz-input-group>
          <div class="inptLbl">Is Owner of House</div>
          <nz-select nzShowSearch style="width: 100%;" nzPlaceHolder="Select House Type" formControlName="ownr">
            <nz-option *ngFor="let o of Ownerdta" [nzValue]="o.id" [nzLabel]="o.type">
            </nz-option>
          </nz-select>

        </div>
      </div>
    </form>
    <!-- </div> -->
    <!-- <div *ngIf="addDtls==true"> -->
    <ng-template #inputClearTpl><i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
        *ngIf="hseDt.gte_hse_nu" (click)="hseDt = {}"></i></ng-template>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveNew()"
      *ngIf="shwEditDiv==false && shwDelDiv==false && shwEditCntctDiv==false && shwDelCntctDiv==false">Submit</button>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEdit()"
      *ngIf="shwEditDiv==true && shwDelDiv==false">Update</button>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="shwEditDiv==false && shwDelDiv==true" nz-popconfirm
      nzPopconfirmTitle="Are you sure delete this house?" nzPopconfirmPlacement="bottom" [nzIcon]="iconTpl"
      nzOkType="danger" nzOkText="YES" nzCancelText="NO" (nzOnConfirm)="saveDelete()" (nzOnCancel)="cancel()">
      Delete
    </button>
    <button nz-button nzType="default" class="cnclBtn" (click)="cancelbtn()">Cancel</button>

    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEditCntct()"
      *ngIf="shwEditCntctDiv==true && shwDelCntctDiv==false">Update Contact</button>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="shwEditCntctDiv==false && shwDelCntctDiv==true"
      nz-popconfirm nzPopconfirmTitle="Are you sure delete this house?" nzPopconfirmPlacement="bottom"
      [nzIcon]="iconTpl" nzOkType="danger" nzOkText="YES" nzCancelText="NO" (nzOnConfirm)="saveDeleteCntct()"
      (nzOnCancel)="cancel()">
      Delete Contact
    </button>


    <ng-template #iconTpl>
      <i nz-icon nzType="question-circle-o" style="color: red;"></i>
    </ng-template>
    <!-- </div> -->


  </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
  </nz-result>
</div>