
<div class="common-layout {{ 'is-' + selectedHeaderColor }}" [ngClass]="{
    'is-folded': isFolded,
    'is-side-nav-dark' : isSideNavDark,
    'is-expand' : isExpand
  }">

    <div class="page-container">
      <div class="main-content">
        <app-loader></app-loader>
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>