import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-mandals',
  templateUrl: './mandals.component.html',
  styleUrls: ['./mandals.component.scss']
})
export class MandalsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  columnDefs;
  shwSidebar = false;
  shwmndlAlrt = false;
  shwmndlEditDiv = false;
  sidebarHeading: string;
  shwmndlDelDiv: boolean;
  shwLdg: boolean;
  mndlLst: any;
  mndlId: any;
  mndlNm: string | null = null;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService,
    public apiSrv: AppsService,
    private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getMndlLst();
  }
  getMndlLst() {
    this.shwLdg = true;
    const rte = 'admin/mandals';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
          this.shwLdg = false;
          if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.mndlLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'District Name', field: 'dstrt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 200 },
            { headerName: 'Mandal Name', field: 'mndl_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 265 }
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    });
  }

  onCellClick(opt, edtData) {
    if (opt === 'edit') {
      this.shwmndlEditDiv = true;
      this.mndlNm = edtData.data.mndl_nm;
      this.mndlId = edtData.data.mndl_id;
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwmndlEditDiv = false;
      this.shwmndlDelDiv = true;
      this.mndlId = edtData.data.mndl_id;
      this.mndlNm = edtData.data.mndl_nm;
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Mandal',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwmndlEditDiv = true;
      this.shwmndlDelDiv = false;
      this.sidebarHeading = 'Edit Mandal';
    } else if (opt === 'delete') {
      this.shwmndlEditDiv = false;
      this.shwmndlDelDiv = true;
      this.sidebarHeading = 'Delete Mandal';
    } else {
      this.shwmndlEditDiv = false;
      this.shwmndlDelDiv = false;
      this.mndlNm = '';
      this.sidebarHeading = 'Add New Mandal';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  savemndl() {
    const mndlData = {
      name: this.mndlNm
    };

    const rte = 'admin/mandals';
    this.apiSrv.create(mndlData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwmndlAlrt = true;
        this.createMessage('success', 'Mandal Successfully Added');
        this.getMndlLst();
      }
    });
  }

  saveEditmndl() {
    const mndlEdtData = {
      id: this.mndlId,
      name: this.mndlNm
    };

    const rte = 'admin/mandals/'+this.mndlId;
    this.apiSrv.update(mndlEdtData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwmndlAlrt = true;
        this.createMessage('info', 'Mandal Successfully Updated');
        this.getMndlLst();
      }
    });
  }

  saveDeletemndl() {
    // console.log('in del');
    const mndlDelData = {
      id: this.mndlId
    };

    const rte = 'admin/mandals/'+this.mndlId;
    this.apiSrv.delete(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwmndlAlrt = true;
        this.createMessage('warning', 'Mandal Successfully Deleted');
        this.getMndlLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('Mandal Not Deleted');
  }
}
