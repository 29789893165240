import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-micropockets',
  templateUrl: './micropockets.component.html',
  styleUrls: ['./micropockets.component.scss']
})
export class MicropocketsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  mcrptLst: any;
  columnDefs;
  shwSidebar = false;
  // mcrptNm: string | null = null;
  mcrptDt: any = {};
  shwMcrptAlrt = false;
  shwMcrptEditDiv = false;
  mcrptId: any;
  sidebarHeading: string;
  shwMcrptDelDiv: boolean;
  shwLdg: boolean;
  wards: any;
  clntDtls: any;
  iscncl: boolean = false;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));

    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.getMcrptsLst();
    this.getUlbs();
    // this.getWards();
  }

  getMcrptsLst() {
    this.shwLdg = true;
    const rte = 'micropockets/list';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      this.shwLdg = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.mcrptLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'Micropocket Name', field: 'mcrpt_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150 },
            // { headerName: 'Micropocket Api ID', field: 'mcrpt_api_id', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'Micropocket Number', field: 'mcrpt_nu', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'Ward Name', field: 'wrd_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'ULB', field: 'ulb_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 120 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    });
  }

  onCellClick(opt, edtData) {
    //   console.log(edtData);
    this.mcrptDt = edtData.data;
    if (opt === 'edit') {
      this.shwMcrptEditDiv = true;
      this.iscncl = true;
      this.getUlbs();
    }
    if (opt === 'delete') {
      this.shwMcrptEditDiv = false;
      this.shwMcrptDelDiv = true;
      this.iscncl = true;
    }
    this.openSideBar(opt);
  }
  filteredOptions: any = [];
  onChange(value): void {
    this.filteredOptions = this.ulbs.filter(option => option.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getULBID(id) {
    this.mcrptDt.ulb_id = id;
    this.getWards();
  }

  onWardChange(value): void {
    this.filteredOptions = this.wards.filter(option => option.wrd_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getWardID(wid) {
    this.mcrptDt.wrd_id = wid;
  }

  getWards() {

    if (this.mcrptDt.ulb_id !== '') {
      // const rte = 'wards/list';
      var ulbid = this.mcrptDt.ulb_id;
      const rte = `wards/ulb/${ulbid}`;
      this.apiSrv.get(rte).subscribe(res => {
        if (res['status'] === 200) {
          this.wards = res['data'];
        }
      });
    }
  }
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Micropocket',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwMcrptEditDiv = true;
      this.shwMcrptDelDiv = false;
      this.sidebarHeading = 'Edit Micropocket';
    } else if (opt === 'delete') {
      this.shwMcrptEditDiv = false;
      this.shwMcrptDelDiv = true;
      this.sidebarHeading = 'Delete Micropocket';
    } else {
      this.shwMcrptEditDiv = false;
      this.shwMcrptDelDiv = false;
      this.mcrptDt = {};
      this.sidebarHeading = 'Add New Micropocket';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  ulbs: any = [];
  getUlbs() {
    const rte = 'admin/ulbs/list';
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] === 200) {
        this.ulbs = res['data'];
      }
    });
    this.getWards();
  }
  saveMcrpt() {

    const rte = 'admin/micropockets';
    this.apiSrv.create(this.mcrptDt, rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwMcrptAlrt = true;
        this.createMessage('success', 'Micropocket Successfully Added');
        this.getMcrptsLst();
      }
    });
  }

  saveEditMcrpt() {
    // console.log(this.mcrptDt);
    const rte = 'admin/micropockets/'+this.mcrptDt.mcrpt_id;
    this.apiSrv.update(this.mcrptDt, rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwMcrptAlrt = true;
        this.createMessage('info', 'Micropocket Successfully Updated');
        this.getMcrptsLst();
      }
    });
  }

  saveDeleteMcrpt() {
    const mcrptDelData = {
      id: this.mcrptDt.mcrpt_id
    };

    const rte = 'admin/micropockets/'+this.mcrptDt.mcrpt_id;
    this.apiSrv.delete(rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwMcrptAlrt = true;
        this.createMessage('warning', 'Micropocket Successfully Deleted');
        this.getMcrptsLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('Micropocket Not Deleted');
  }
}
