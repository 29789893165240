<pg-header title="Setup" ticon="book" sbtitle="Master data and configurations" bckbtn=false></pg-header>
<!-- <ds-loading></ds-loading> -->
<div *ngIf="shwLdg">
  <div class="setup-card" *ngFor="let i of [0,1,2]">
    <div class="p-20" style="box-shadow: 0 2px 0 0 rgba(115, 143, 147, 0.12);">
      <nz-skeleton-element nzType="input" [nzActive]="true" [nzSize]="'small'" style="width:200px">
      </nz-skeleton-element>
    </div>
    <div class="p-20">
      <nz-skeleton-element nzType="input" class="p-l-10 p-r-10" [nzActive]="true" [nzSize]="'small'"
        style="width:200px;" *ngFor="let i of [0,1,2,3]">
      </nz-skeleton-element>
    </div>
    <div class="p-l-20 p-r-20 p-b-20">
      <nz-skeleton-element nzType="input" class="p-l-10 p-r-10" [nzActive]="true" [nzSize]="'small'" style="width:200px"
        *ngFor="let i of [0,1,2,3]">
      </nz-skeleton-element>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-xl-1 col-lg-1 col-md-1"></div>
  <div class="col-xl-10 col-lg-10 col-md-10">
    <div class="form-label" style="color:#000">Enter Group Category</div>
          <nz-input-group>
                  <input type="text" style="padding: 8px;
                               border-radius: 4px;
                               box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);" nz-input [(ngModel)]="searchText"
                           placeholder="Search an option"
                          [ngModelOptions]="{standalone: true}" />
          </nz-input-group>
  </div>
  <div class="col-xl-1 col-lg-1 col-md-1"></div>
</div>

<nz-divider></nz-divider>

<div class="setup-card" *ngFor="let stpGrps of setup | filtergrp:searchText; let i = index">
  <div class="std-card-title">{{stpGrps.grp_nm}}</div>
  <div class="row p-20">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let stpOpt of stpGrps.opts; let i = index">
      <div class="setup-card-option setupItms" (click)="gotoStpOptPge(stpOpt.stp_opt_url_tx)">
        <i nz-icon [nzType]="stpOpt.stp_opt_icn_tx" nzTheme="outline"></i>
        {{stpOpt.opt_nm}}
      </div>
    </div>
  </div>
</div>


<!-- <div class="cnt"> -->
<!-- <mat-card class="mtcrd" *ngFor="let stpGrps of setup | filterGrp:searchText ; let i = index">
    <mat-card-content class="totContent">
      <p class="ptxt">{{stpGrps.grp_nm}}</p>
      <mat-grid-list [cols]="breakpoint" rowHeight="60" gutterSize="6px" (window:resize)="onResize($event)"
        class="totList">
        <mat-grid-tile *ngFor="let stpOpt of stpGrps.opts | filter:searchText; let i = index"
          (click)="btnClick(stpOpt.stp_opt_url_tx);">
          <img src="{{stpOpt.stp_opt_icn_tx}}" width="33">
          <span class="hmtxt setupItms">{{stpOpt.opt_nm}}</span>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card> -->
<!-- </div> -->