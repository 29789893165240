<nz-layout>
<nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
    <app-side-menu (ClickEvent)="getEvent($event)"></app-side-menu>
  </nz-sider>
  <nz-layout>
  <nz-header >
    <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="isCollapsed = !isCollapsed"></i>
  </nz-header>
</nz-layout>
</nz-layout>
