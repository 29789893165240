<!-- <div>
    <i nz-icon nzType="folder" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">Create Help videos</b>
</div> -->
<pg-header title="Create Help videos" ticon="folder" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">

  <div class="m-24">

    <div>
      <div>
        <div class="input-row" nz-row>
          <div nz-col nzXs="16" nzSm="16" nzMd="6" nzLg="6" nzXl="11">
            <div class="inptLbl">video Groups</div>
            <nz-select required [(ngModel)]="selectedGroupId" (ngModelChange)="onvideoGrpSelection()">
              <nz-option *ngFor="let vg of videogrps" [nzValue]="vg.vdo_grp_id" [nzLabel]="vg.vdo_grp_nm">
              </nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>

    <div nz-row class="gridTop" *ngIf="(permissions.slct_in==1)?true:false">
      <ds-loading *ngIf="loader"></ds-loading>
      <div class="ds-card grid">
        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="rowData" [remoteOperations]="false"
          [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
          [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
          (onToolbarPreparing)="(USER_PERMISSIONS.insrt_in==1)?onToolbarPreparing($event):false">
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
            [showInfo]="true"></dxo-pager>
          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
          <dxo-filter-panel [visible]="true"></dxo-filter-panel>
          <dxo-group-panel [visible]="true"></dxo-group-panel>
          <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
          <dxo-column-chooser [enabled]="true" mode="select">
          </dxo-column-chooser>
          <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
            fileName="Change Log"></dxo-export>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
            [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [allowFiltering]="f.filter"></dxi-column>
          <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" type="buttons"
            [width]="100" caption="Edit" cellTemplate="editTemplate">
            <div *dxTemplate="let data of 'editTemplate'">
              <div class="tblEdtBtn" (click)="onEdit2(data)">
                Edit
              </div>
            </div>
          </dxi-column>
          <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons"
            [width]="100" caption="Delete" cellTemplate="delTemplate">
            <div *dxTemplate="let data of 'delTemplate'">
              <div class="tblDelBtn" (click)="onDelete2(data)">
                Delete
              </div>
            </div>
          </dxi-column>
        </dx-data-grid>
      </div>
    </div>

  </div>

  <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="true" [nzWidth]="370" [nzVisible]="shwSidebar"
    nzTitle="Create new page" (nzOnClose)="closeSideBar()">
    <form nz-form [formGroup]="validateForm">
      <!-- <nz-form-item>
          <nz-form-label [nzSpan]="8">video Name</nz-form-label>
          <nz-form-control [nzSpan]="16">
            <input formControlName="vdo_nm" nz-input placeholder="please enter video name" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8">video embedded url</nz-form-label>
          <nz-form-control [nzSpan]="16">
            <input formControlName="vdo_embd_tx" nz-input placeholder="please enter Answer" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8">video description</nz-form-label>
          <nz-form-control [nzSpan]="16">
            <input formControlName="vdo_dscn_tx" nz-input placeholder="please enter Answer" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8">video thumbline image url</nz-form-label>
          <nz-form-control [nzSpan]="16">
            <input formControlName="thmbl_imge_url_tx" nz-input placeholder="please enter Answer" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="8">Select Group</nz-form-label>
            <nz-form-control [nzSpan]="16">
              <nz-select required nzPlaceHolder="Select Type" formControlName="vdo_grp_id">
                <nz-option *ngFor="let v of videogrps" [nzValue]="v.vdo_grp_id" [nzLabel]="v.vdo_grp_nm">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item> -->


      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
          <nz-form-item>
            <nz-form-control>
              <div class="inptLbl">video Name</div>
              <input formControlName="vdo_nm" nz-input placeholder="please enter video name" />
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
          <nz-form-item>
            <nz-form-control>
              <div class="inptLbl">video embedded url</div>
              <input formControlName="vdo_embd_tx" nz-input placeholder="please enter Answer" />
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>


      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
          <nz-form-item>
            <nz-form-control>
              <div class="inptLbl">video description</div>
              <input formControlName="vdo_dscn_tx" nz-input placeholder="please enter Answer" />
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>


      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
          <nz-form-item>
            <nz-form-control>
              <div class="inptLbl">video thumbline image url</div>
              <input formControlName="thmbl_imge_url_tx" nz-input placeholder="please enter Answer" />
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>




      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
          <div class="inptLbl">Select Group</div>
          <nz-form-item>
            <nz-form-control>
              <nz-select required nzPlaceHolder="Select Type" formControlName="vdo_grp_id">
                <nz-option *ngFor="let v of videogrps" [nzValue]="v.vdo_grp_id" [nzLabel]="v.vdo_grp_nm">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>









      <div style="float: right">
        <button nz-button style="margin-right: 8px;" (click)="closeSideBar()">Cancel</button>
        <button nz-button nzType="primary" (click)="submit()" *ngIf="!editClicked"
          [disabled]="!validateForm.valid">Submit</button>
        <button nz-button nzType="primary" (click)="update()" *ngIf="editClicked"
          [disabled]="!validateForm.valid">Save</button>

      </div>
    </form>
  </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
  </nz-result>
</div>