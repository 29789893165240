import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { MessageDialogComponent } from '../../../shared/components/message-dialog/message-dialog.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-designations',
  templateUrl: './designations.component.html',
  styleUrls: ['./designations.component.scss']
})
export class DesignationsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  gridData: any;
  isedit: boolean = false;
  isadd: boolean = false;
  isdel: boolean = false;
  editid: any;
  delid: any;
  subtl: any;
  myform: FormGroup;
  deldata: any[] = [];
  shwLdg: boolean;
  name = new FormControl('');

  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, public atmSrv: AtomServiceService, private message: NzMessageService, private modal: NzModalService) {
    this.initdata = {};

  }
  columnDefs: any;

  initdata: any;
  loader: boolean;
  visible = false;
  gridApi;

  user: any = {
    permissions: { 'slct_in': 1, 'insrt_in': 1, 'updt_in': 1, 'dlte_in': 1, 'exprt_in': 1 },
    'perm_url': 'user/permissions/Desginations Creation'
  }
  permissions;
  mainMessage: any = null;
  // tslint:disable-next-line:member-ordering
  public fields: any[] = [
    { type: 'pk', name: 'dsgns_id', label: '', isCustom: false, customData: {}, required: false, hidden: true },
    {
      type: 'text', name: 'dsgns_nm', label: 'Designation', isCustom: false, customData: {
        'route': null, 'input_key': null, 'key': null,
        // tslint:disable-next-line:quotemark
        "label_key": null, "label": null, "table": null
      }, multiline: false, rowcount: 6, value: '', required: false, hidden: false
    }
  ];
  formDetails: any = {
    fnctns: {},
    initdata: {},
    fields: this.fields,
    key_field: ['dsgns_id'],
    stngs: {
      'style': 'mat',
      'saveBtn': true,
      'saveAsBtn': false,
      'closeBtn': true,
      'model_style': 'right',
      'form_title': ' Add New Designation',
      'deleteBtn': false,
      'show_lables': false,
      'oper_mode': 'new'
    }
  };
  getRowHeight = function () { return 40; };
  getHeaderDtls = function () { return { 'title': this.formDetails.stngs.form_title + ' List', 'icon': 'receipt' }; };

  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getGridData();
  }

  getGridData() {
    this.shwLdg = true;
    this.mainMessage = null;
    this.loader = true;
    this.usrAcsErrMsg = false;
    this.apiSrv.get('org/designations').subscribe((res) => {
          this.shwLdg = false;
          this.loader = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.shwLdg = false;
          this.gridData = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: false },
            { headerName: 'Designation', field: 'dsgns_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 400, sortable: true, filter: true },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true }
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    }, err => {
      this.loader = false;
      this.shwLdg = false;
    });
  }

  addNewEntry() {
    this.isadd = true;
    if (this.isadd == true) { this.name = new FormControl(''); }
    this.formDetails.stngs.oper_mode = 'new';
    this.formDetails.initdata = {};
    this.formDetails.stngs.deleteBtn = false;
    this.formDetails.stngs.saveBtn = true;
    this.initdata = {};
    this.visible = true;

  }

  onEdit2(event) {
    this.isedit = true;
    if (this.isedit == true) { this.name = new FormControl(event.data.dsgns_nm); }
    this.formDetails.initdata = event.data;
    this.initdata = event.data;
    this.editid = this.initdata.dsgns_id;
    this.formDetails.stngs.deleteBtn = false;
    this.formDetails.stngs.saveBtn = true;
    this.formDetails.stngs.oper_mode = 'edit';
    this.visible = true;
  }
  onDelete2(event) {
    this.isdel = true;
    if (this.isdel == true) { this.name = new FormControl(event.data.dsgns_nm); }
    this.subtl = event.data.dsgns_nm
    this.formDetails.initdata = event.data;
    this.initdata = event.data;
    this.delid = this.initdata.dsgns_id;
    this.formDetails.stngs.deleteBtn = true;
    this.formDetails.stngs.saveBtn = false;
    this.formDetails.stngs.oper_mode = 'delete';
    this.visible = true;

  }

  close(): void {
    this.visible = false;
  }

  frmcntl() {
    this.name = new FormControl('');
  }


  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: this.formDetails.stngs.form_title,
        onClick: this.addNewEntry.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }

  saveFormData = function(data_got) {
    if (this.formDetails.stngs.oper_mode === 'new') {
      var data = {
        dsgns_nm :data_got
      }
      this.apiSrv.create(data, 'org/designations').subscribe(res => {
        if (res['status'] === 200) {
          this.message.success('New Designation Sucessfully Added', {
            nzDuration: 2000
          });
          this.visible = false;
          this.getGridData();
        } else if (res['status'] === 404) {
          this.message.error('You do not have permissions to do this operation.Please contact Admin.', {
            nzDuration: 4000
          });
          this.visible = false;
        } else {
          this.message.error(res['message'], {
            nzDuration: 2000
          });
        }
      })
    }
    else if (this.formDetails.stngs.oper_mode === "edit") {
      //   console.log(data_got)

      var dsgnEdtData = {
        dsgn_nm: data_got
      };
      this.apiSrv.update(dsgnEdtData, 'org/designations/' +  this.editid).subscribe(res => {

        if (res['status'] == 200) {
          this.message.success('Designation Sucessfully Updated', {
            nzDuration: 2000
          });
          this.visible = false;
          this.getGridData();

        } else if (res['status'] == 404) {
          this.message.error('You do not have permissions to do this operation.Please contact Admin.', {
            nzDuration: 4000
          });
          this.visible = false;

        } else {
          this.message.error(res['message'], {
            nzDuration: 2000
          });
        }
      })



    }
  }

  // delData = function (data_got) {
  //    var dsgnDelData = {
  //     id: this.delid,
  //   };
  //   this.deldata.push(dsgnDelData);
  //   const modal: NzModalRef = this.modal.create({
  //     nzTitle: '',
  //     nzContent: MessageDialogComponent,
  //     nzComponentParams: {
  //       title: 'Are you sure deleting this item ?',
  //       subtitle: this.subtl ,
  //     },
  //     nzFooter: null,

  //   });

  // }

  cancel() {
    this.message.info('Designation Not Deleted', {
      nzDuration: 2000
    });
  }
  cancelbtn() {
    this.visible = false;
  }


  confirm(data) {
    var dsgnDelData = {
      id: this.delid,
    };
    this.apiSrv.delete('org/designations/' + dsgnDelData.id).subscribe((res) => {
      if (res['status'] === 200) {
        this.message.success('Designation Sucessfully Deleted', {
          nzDuration: 2000
        });
        this.visible = false;
        this.getGridData();
      }else if (res['status'] == 404) {
        this.message.error('You do not have permissions to do this operation.Please contact Admin.', {
          nzDuration: 4000
        });
        this.visible = false;

      } else {
        this.message.error(res['message'], {
          nzDuration: 2000
        });
      }
    });
  }

}
