<div class="dashboard">
    <pg-header title="MicroPocket Dashboard for {{mcrpktParamDta.mcrpt_nm}}" [bckbtn]="false" ticon="database"></pg-header>
    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <nz-card class="shadowcard mcrpktdbrdgrph">
                <div style="display: block;" style="margin-top: 1.5%;">
                    <!-- <canvas baseChart class="hmechrt" [datasets]="barChartDataBig" [labels]="barChartLabelsBig"
                        [options]="barChartOptionsBig" [colors]="barChartColorsBig" [plugins]="barChartPlugins"
                        [legend]="barChartLegendBig" [chartType]="barChartType" style="height:100%;width:100%">
                    </canvas> -->

                </div>
            </nz-card>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <nz-card class="shadowcard mcrpktdbrdgrph">Map of MIcropocket</nz-card>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
            <nz-card class="shadowcard" style="width:100%; height:auto;">
                <div nz-row [nzGutter]="8" class="shadowcard">
                    <div nz-col [nzSpan]="12" class="attnBdrRght">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Residential</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{mcrpktParamDta.hh_scnd_gtes}} /{{mcrpktParamDta.hh_tot_gtes}}</p>

                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">
                            Residential&Commercial</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{mcrpktParamDta.rc_scnd_gtes}} /{{mcrpktParamDta.rc_tot_gtes}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12" class="gtsBdrRght brdertop">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Apartments</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{mcrpktParamDta.a_scnd_gtes}} /{{mcrpktParamDta.a_tot_gtes}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12" class="brdertop">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Bulk Commercial
                        </p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{mcrpktParamDta.bc_scnd_gtes}} /{{mcrpktParamDta.bc_tot_gtes}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                </div>
                <div nz-row [nzGutter]="8" class="shadowcard" style="margin-top:4%">
                    <div nz-col [nzSpan]="12" class="attnBdrRght">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">PH Workers</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{mcrpctEmpCrdCont[0]?.ph_prsnt}} /{{mcrpctEmpCrdCont[0]?.ph_tot}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Present vs Total
                            Members</p>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">
                            Transfer Points</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{transferPtCrdCnt?.covered_tp}} /{{transferPtCrdCnt?.tot_tp}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Covered vs Total
                            Transfer Points</p>
                    </div>
                </div>

            </nz-card>
        </div>
    </div>
    <div class="dashboard-card">
        <nz-card>
            <nz-tabset>
                <nz-tab nzTitle="Gates">
                    <div nz-row [nzGutter]="8" style="margin-bottom: 10px;">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="shadowcard gtsgrph verScroll">
                                <p class="crd-gp-hrd" style="color: #9e1644;
                                padding-left: 10px;
                                padding-top: 10px;
                                letter-spacing: 0px;">Not Scanned Gates</p>
                                <div class="row gridTop" id="target">
                                    <div class="col-lg-12 col-md-12 mgnb-2 p-8">
                                        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="notScndGts"
                                            [remoteOperations]="false" [allowColumnReordering]="true"
                                            [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                                            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true"
                                            style="width:100%">
                                            <dxo-paging [pageSize]="5"></dxo-paging>
                                            <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                                                [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                                            </dxo-pager>
                                            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                                            </dxo-search-panel>
                                            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                                            <dxo-group-panel [visible]="true"></dxo-group-panel>
                                            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                                            <dxo-column-chooser [enabled]="true" mode="select">
                                            </dxo-column-chooser>
                                            <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                                            <dxo-export [enabled]="true" fileName="Sachivalayam Data"></dxo-export>
                                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                                            <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field"
                                                [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width"
                                                allowResizing="true">
                                            </dxi-column>
                                        </dx-data-grid>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="shadowcard gtsgrph" >
                                <p class="crd-gp-hrd" style="color: #9e1644;
                                padding-left: 10px;
                                padding-top: 10px;
                                letter-spacing: 0px;">Gates Scanned Last 7 Days </p>
                                <div style="display: block;" style="margin-top: 1.5%;">
                                    <!-- <canvas baseChart  class="hmechrt" [datasets]="barChartData" [labels]="barChartLabels"
                                        [options]="barChartOptions" [colors]="barChartColors" [plugins]="barChartPlugins"
                                        [legend]="barChartLegend" [chartType]="barChartType">
                                    </canvas> -->
            
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-tab>
                <nz-tab nzTitle="Attendance">
                    <div nz-row [nzGutter]="8"  style="margin-bottom:10px;">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="shadowcard gtsgrph verScroll">
                                <p class="crd-gp-hrd" style="color: #9e1644;padding-left: 10px;
                                padding-top: 10px;
                                letter-spacing: 0px;">Employees Absent List</p>
                                <div class="row gridTop" id="target">
                                    <div class="col-lg-12 col-md-12 mgnb-2 p-8">
                                        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="mcrpctEmpNtPrsnt"
                                            [remoteOperations]="false" [allowColumnReordering]="true"
                                            [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                                            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true"
                                            style="width:100%">
                                            <dxo-paging [pageSize]="5"></dxo-paging>
                                            <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                                                [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                                            </dxo-pager>
                                            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                                            </dxo-search-panel>
                                            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                                            <dxo-group-panel [visible]="true"></dxo-group-panel>
                                            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                                            <dxo-column-chooser [enabled]="true" mode="select">
                                            </dxo-column-chooser>
                                            <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                                            <dxo-export [enabled]="true" fileName="Sachivalayam Data"></dxo-export>
                                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                                            <dxi-column *ngFor="let d of columnDefs1" [dataField]="d.field"
                                                [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width"
                                                allowResizing="true">
                                            </dxi-column>
                                        </dx-data-grid>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="shadowcard gtsgrph">
                                <p class="crd-gp-hrd" style="color: #9e1644; padding-left: 10px;
                                padding-top: 10px;
                                letter-spacing: 0px;">Attendance in Last 7 Days</p>
                                <div style="display: block;" style="margin-top: 1.5%;">
                                    <!-- <canvas baseChart class="hmechrt" [datasets]="barChartData1" [labels]="barChartLabels"
                                        [options]="barChartOptions" [colors]="barChartColors" [plugins]="barChartPlugins"
                                        [legend]="barChartLegend" [chartType]="barChartType">
                                    </canvas> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-tab>
                <nz-tab nzTitle="Weighment">
                    <div nz-row [nzGutter]="8" style="margin-bottom:10px;">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="shadowcard gtsgrph verScroll">
                                <p class="crd-gp-hrd" style="color:#9e1644;  padding-left: 10px;
                                padding-top: 10px;
                                letter-spacing: 0px;">Day Weight in Tons</p>
                                <div class="row gridTop" id="target">
                                    <div class="col-lg-12 col-md-12 mgnb-2 p-8">
                                        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="dayWmntInTons"
                                            [remoteOperations]="false" [allowColumnReordering]="true"
                                            [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                                            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true"
                                            style="width:100%">
                                            <dxo-paging [pageSize]="5"></dxo-paging>
                                            <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                                                [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                                            </dxo-pager>
                                            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                                            </dxo-search-panel>
                                            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                                            <dxo-group-panel [visible]="true"></dxo-group-panel>
                                            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                                            <dxo-column-chooser [enabled]="true" mode="select">
                                            </dxo-column-chooser>
                                            <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                                            <dxo-export [enabled]="true" fileName="Sachivalayam Data"></dxo-export>
                                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                                            <dxi-column *ngFor="let d of columnDefs2" [dataField]="d.field"
                                                [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width"
                                                allowResizing="true">
                                            </dxi-column>
                                        </dx-data-grid>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="shadowcard gtsgrph">
                                <p class="crd-gp-hrd" style="color:#9e1644;  padding-left: 10px;
                                padding-top: 10px;
                                letter-spacing: 0px;">Weighment in Last 7 Days</p>
                                <div style="display: block;" style="margin-top: 1.5%;">
                                    <!-- <canvas baseChart class="hmechrt" [datasets]="barChartData2" [labels]="barChartLabels"
                                        [options]="barChartOptions" [colors]="barChartColors" [plugins]="barChartPlugins"
                                        [legend]="barChartLegend" [chartType]="barChartType">
                                    </canvas> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-tab>
            </nz-tabset>
        </nz-card>
    </div>
    <div>
        <div class="dashboard-card">
            <div class="row border-bottom">
                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="card-heading p-8">Street Wise Houses Coverage on <span
                            style="color: #321da4;">{{today.split("-").reverse().join("-")}}</span></div>

                </div>
                <!-- <div class="col-lg-2 col-md-2">
              <div id="monthbox"></div>
            </div> -->

                <div class="house-paid block mt-08prcnt"></div>
                <div class="col-xl-1 col-lg-2 col-md-2 house-indicator-div mt-08prcnt">
                    <div>Gates Scanned</div>
                </div>
                <div class="house-not-paid block mt-08prcnt"></div>
                <div class="col-xl-1 col-lg-3 col-md-3 house-indicator-div mt-08prcnt">
                    <div>Gates Not Scanned</div>
                </div>
                <div class="bg-bulk-comrl block mt-08prcnt"></div>
                <div class="col-xl-1 col-lg-3 col-md-3 house-indicator-div mt-08prcnt">
                    <div>Bulk Commerical</div>
                </div>
                <div class="bg-apartment block mt-08prcnt"></div>
                <div class="col-xl-1 col-lg-3 col-md-3 house-indicator-div mt-08prcnt">
                    <div>Apartments</div>
                </div>
                <div class="bg-residential block mt-08prcnt"></div>
                <div class="col-xl-1 col-lg-3 col-md-3 house-indicator-div mt-08prcnt">
                    <div>Residential</div>
                </div>
                <div class="bg-cmrl-res block mt-08prcnt"></div>
                <div class="col-xl-2 col-lg-2 col-md-2 house-indicator-div mt-08prcnt">
                    <div>Commercial & Residential</div>
                </div>
                <!-- <div id="monthbox"></div> -->
            </div>
            <div>
                <div class="row border-bottom" *ngFor="let s of strtWsegtsScndLst">
                    <div class="col-lg-3 col-md-3 col-12 street-name">
                        {{s.strt_nm}}
                    </div>
                    <div class="col-lg-9 col-md-9 col-12 street-house-padding" >
                        <div class="row">
                            <i nz-icon nzType="home" nzTheme="fill" *ngFor="let h of s.gates"
                                [ngClass]="{'color-green':h.isScanned === 1, 'color-red':h.isScanned === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential',
                   'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Residential Commercial','bg-bulk-comrl': h.gte_ctgry_nm ===  'Bulk Commercial'}"
                                style="padding: 6px;margin: 2px;" (click)="open(h)"></i>

                            <!-- <i nz-icon nzType="home" nzTheme="fill" style="background-color: #F9EAEA;color: red;padding: 6px;
                  margin: 2px;"></i>
                  <i nz-icon nzType="home" nzTheme="fill" style="background-color: #FBEDBC;color: green;padding: 6px;
                  margin: 2px;"></i> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<nz-drawer
      [nzClosable]="true"  
      [nzWidth]="500"
      [nzVisible]="visible"
      nzTitle="House Info"
      (nzOnClose)="close()"
    >
      <nz-list>
        <nz-list-item nz-row style="background-color: #FFEDD0;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Gate Number</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{gateNu}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Gate Name</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{gateNm}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: #FFEDD0;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Street Name</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12"></div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">No.of Residentials</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{numOfRs}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: #FFEDD0;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">No.of People</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{numOfPl}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">scanned on {{today.split("-").reverse().join("-")}}</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{scanned}}</div>
        </nz-list-item>
        <nz-list-item nz-row>
            <div id="map"> </div>
        </nz-list-item>
        <nz-list-item nz-row>
            <div>Day wise gate Scanned for month {{mnthName}}</div>
            <div nz-row style="float:right;margin-top:15px;"><div nz-col style="height:20px;width:20px;background-color: #00ce00ad;"></div><span nz-col style="padding-top: 5px;">&nbsp;&nbsp;Gates Scanned</span></div>
            <div style="    display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 15px;">
                <div *ngFor="let obj of houseInfo">
                    <div>
                    <div style="height: 40px;
                    width: 60px;
                    margin: 2.5px;
                    text-align: center;
                    vertical-align: center;
                    border:1px solid black;
                    padding-top: 15px;
                    color: black;
                    font-weight: 500;
                    font-size: 25px;" [ngStyle]="{'background-color': (obj.cvrd_sts=='Covered') ?  '#00ce00ad' : '#FFEDD0'}">{{obj.day_dt}}</div>
                    </div>
                </div>
            </div>
        </nz-list-item>
      </nz-list>
      
    </nz-drawer>