<ds-loading *ngIf="showLdr"></ds-loading>
<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" nzTitle="Vehicle Dashboard"
    (nzOnClose)="visible = false" class="dashbSlider">
    <ng-container *nzDrawerContent>
    <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
    </ng-container>
</nz-drawer>
<div class="row hdrBar">
    <div class="col-lg-6 col-md-6 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">Sachivalayam Dashboard <span *ngIf="selectedtenantNm"
                class="tntTxt">({{selectedtenantNm}})</span></h1>
    </div>
    <div class="col-lg-3 col-md-3 mg-a" style="text-align: right;" *ngIf="tntLst.length>1">
        <span>Select ULB:</span>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
            [ngModelOptions]="{standalone: true}">
            <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
            <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
        </nz-select>
    </div>
    <div class="col-lg-3 col-md-3 mg-a" style="text-align: right;" *ngIf="tntLst && tntLst.length==1">
        <span>ULB:</span>
        <span class="disbledtxt">{{tntLst[0].tnt_nm}}</span>
    </div>
    <div class="col-lg-3 col-md-3 mg-a">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
</div>
<div class="row bg-wp" *ngIf="vehclesTble && vehclesTble.length>0">
    <div class="col-lg-3 col-md-3 col-12">
        <h1 style="text-align: left;">Sachivalayam Analysis</h1>
    </div>
    <div class="col-lg-9 col-md-9 col-12">
        <div class="row">
            <div style="height: 21px;
            width: 25px;
            margin: 2.5px;
            text-align: center;
            padding-top: 6px;
            color: black;
            font-weight: 600;
            font-size: 14px;
            background-color: rgb(228 230 193);
            border: 1px solid #cfb3ff;"></div>
            <div>Trips Not assigned for Sachivalayam</div>
            <div style="height: 21px;
            width: 25px;
            margin: 2.5px;
            text-align: center;
            padding-top: 6px;
            color: black;
            font-weight: 600;
            font-size: 14px;
            background-color: rgb(160 214 213);
            border: 1px solid #cfb3ff;"></div>
            <div>No Trips Travelled Today</div>
            <div style="height: 21px;
              width: 25px;
              margin: 2.5px;
              text-align: center;
              padding-top: 6px;
              color: black;
              font-weight: 600;
              font-size: 14px;
              background-color: rgb(230 209 193);
              border: 1px solid #cfb3ff;"></div>
            <div>Distance travelled Less than 2 Kms</div>
        </div>
    </div>

    <div class="ds-card tbl grid">

        <dx-data-grid class="p-8" [dataSource]="vehclesTble" [remoteOperations]="false" [allowColumnReordering]="true"
            [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true"
            [showBorders]="true" style="width:100%" [wordWrapEnabled]="true"
            (onCellPrepared)="onCellPrepared(columnDefs,$event)" (onCellClick)="devCellclk(columnDefs,$event)">
            <dxo-paging [pageSize]="100"></dxo-paging>
            <dxo-pager [visible]="vehclesTble.length>100" [showPageSizeSelector]="true"
                [allowedPageSizes]="[100,150,200]" [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
            </dxo-search-panel>
            <dxo-filter-panel [visible]="false"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ulb wise operated Trips">
            </dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-sorting
            mode="single">
        </dxo-sorting>
            <!-- <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
                [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
            </dxi-column> -->
            <dxi-column dataField="sno" headerCellTemplate="Sno" alignment='center'></dxi-column>
            <dxi-column dataField="svm_nm" headerCellTemplate="Sachivalayam Name" alignment='left' [width]="180">
            </dxi-column>
            <dxi-column dataField="asrt_nm" headerCellTemplate="Vehicle Registration Number" alignment='left' [width]="100" > </dxi-column>
            <dxi-column dataField="crw_nm" headerCellTemplate="Driver" alignment='left' [width]="80"> </dxi-column>
            <dxi-column dataField="ss_nm" headerCellTemplate="Sanitary Secratory" alignment='left' [width]="80">
            </dxi-column>
            <dxi-column dataField="asgnd_trps" headerCellTemplate="Assigned Trips" alignment='center' >
            </dxi-column>
            <dxi-column dataField="tdy_trvl_trps" headerCellTemplate="Today Travelled Trips" alignment='center' > </dxi-column>
            <dxi-column dataField="tdy_trvl_dst" headerCellTemplate="Today Travelled Distance in Kms" alignment='left' > </dxi-column>
            <dxi-column caption="Trip 1" alignment='center'>
                <dxi-column caption="Total " dataField="t1_ttl" format="fixedPoint" alignment='right'></dxi-column>
                <dxi-column caption="covered " dataField="t1_cvrd" format="fixedPoint" alignment='right'></dxi-column>
                <dxi-column caption="status " dataField="t1_sts" alignment='center'>
                </dxi-column>
            </dxi-column>
            <dxi-column caption="Trip 2" alignment='center'>
                <dxi-column caption="Total " dataField="t2_ttl" format="fixedPoint" alignment='right'></dxi-column>
                <dxi-column caption="covered " dataField="t2_cvrd" format="fixedPoint" alignment='right'></dxi-column>
                <dxi-column caption="status " dataField="t2_sts" alignment='center'>
                </dxi-column>
            </dxi-column>
            <dxi-column caption="Trip 3" alignment='center'>
                <dxi-column caption="Total " dataField="t3_ttl" format="fixedPoint" alignment='right'></dxi-column>
                <dxi-column caption="covered " dataField="t3_cvrd" format="fixedPoint" alignment='right'></dxi-column>
                <dxi-column caption="status " dataField="t3_sts" alignment='center'>
                </dxi-column>
            </dxi-column>
            <dxi-column caption="Trip 4" alignment='center'>
                <dxi-column caption="Total " dataField="t4_ttl" format="fixedPoint" alignment='right'></dxi-column>
                <dxi-column caption="covered " dataField="t4_cvrd" format="fixedPoint" alignment='right'></dxi-column>
                <dxi-column caption="status " dataField="t4_sts" alignment='center'>
                </dxi-column>
            </dxi-column>
            <dxo-summary>
                <dxi-total-item column="asgnd_trps" summaryType="sum" name="Assigned Trips">
                </dxi-total-item>
                <dxi-total-item column="tdy_trvl_trps" summaryType="sum" name="Today Travelled Trips">
                </dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
    <div class="row" style="font-weight: 400;">&nbsp;&nbsp;Click on Sachivalayam to get Sachivalayam wise Analysis</div>
</div>
<div class="row bg-wp" *ngIf="vehclesTble.length<=0" style="text-align:center;">
    <div>Sachivalayam Data not Provided for the ULB</div>
</div>
<nz-drawer [nzWidth]="'90%'" [nzClosable]="true" [nzVisible]="visibleDriverSldr" nzPlacement="right"
    nzTitle="Driver Dashboard" (nzOnClose)="visibleDriverSldr = false" class="dashbSlider">
    <ng-container *nzDrawerContent>
    <app-driver-slider [driver]="selectedDriver" *ngIf="selectedDriver"></app-driver-slider>
    </ng-container>
</nz-drawer>
<nz-drawer [nzWidth]="'90%'" [nzClosable]="true" [nzVisible]="trpSldr" nzPlacement="right"
    nzTitle="Trips Dashboard" (nzOnClose)="visibleTripSldr = false" class="dashbSlider">
    <ng-container *nzDrawerContent>
    <app-trips-slider [trip]="selectedtrip" *ngIf="selectedtrip"></app-trips-slider>
    </ng-container>
</nz-drawer>