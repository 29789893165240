import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-streets',
  templateUrl: './streets.html',
  styleUrls: ['./streets.scss']
})
export class StreetsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  strtLst: any;
  columnDefs;
  shwSidebar = false;
  strtDt: any = {};
  shwStrtAlrt = false;
  shwStrtEditDiv = false;
  strtId: any;
  sidebarHeading: string;
  shwStrtDelDiv: boolean;
  shwLdg: boolean;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.getStrtsLst();
    this.getMicropockets();
    this.getUlbs();
  }

  getStrtsLst() {
    this.shwLdg = true;
    const rte = 'streets/list';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      this.shwLdg = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.strtLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'Street Name', field: 'strt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 280 },
            { headerName: 'Micropocket', field: 'mcrpt_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'ULB', field: 'ulb_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 150 },
            // { headerName: 'Description', field: 'strt_dscn_tx', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            // { headerName: 'Start Gate number', field: 'strt_gt_nu', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Gates count', field: 'estmd_gte_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Household count', field: 'estmd_hse_hld_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },

          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    });
  }

  onCellClick(opt, edtData) {
    this.strtDt = edtData.data;
    if (opt === 'edit') {
      this.shwStrtEditDiv = true;
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwStrtEditDiv = false;
      this.shwStrtDelDiv = true;
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Street',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwStrtEditDiv = true;
      this.shwStrtDelDiv = false;
      this.sidebarHeading = 'Edit Street';
    } else if (opt === 'delete') {
      this.shwStrtEditDiv = false;
      this.shwStrtDelDiv = true;
      this.sidebarHeading = 'Delete Street';
    } else {
      this.shwStrtEditDiv = false;
      this.shwStrtDelDiv = false;
      this.strtDt = {};
      this.sidebarHeading = 'Add New Street';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  micropockets: any = [];
  getMicropockets() {
    this.apiSrv.get('/micropockets/list').subscribe((res) => {
      this.micropockets = res['data'];
    })
  }
  ulbs: any = [];
  getUlbs() {
    const rte = 'admin/ulbs/list';
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] === 200) {
        this.ulbs = res['data'];
      }
    });
  }
  getULBID(id) {
    this.strtDt.ulb_id = id;
  }
  filteredOptions: any = [];
  onulbChange(value): void {
    this.filteredOptions = this.ulbs.filter(option => option.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  saveStrt() {

    const rte = 'streets';
    this.apiSrv.create(this.strtDt, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwStrtAlrt = true;
        this.createMessage('success', 'Street Successfully Added');
        this.getStrtsLst();
      }
    });
  }

  saveEditStrt() {
    //   console.log(this.strtDt);
    const rte = 'update/streets';
    this.apiSrv.create(this.strtDt, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwStrtAlrt = true;
        this.createMessage('info', 'Street Successfully Updated');
        this.getStrtsLst();
      }
    });
  }

  saveDeleteStrt() {
    // console.log('in del');
    const strtDelData = {
      id: this.strtDt.strt_id
    };

    const rte = 'delete/streets';
    this.apiSrv.create(strtDelData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwStrtAlrt = true;
        this.createMessage('warning', 'Street Successfully Deleted');
        this.getStrtsLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('Street Not Deleted');
  }
}
