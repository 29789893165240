import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-menu-profile',
  templateUrl: './menu-profile.component.html',
  styleUrls: ['./menu-profile.component.scss']
})
export class MenuProfileComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  mnuprflLst: any;
  columnDefs;
  editdata;
  deldata;
  idtodel;
  mnuprflForm;
  shwSidebar: boolean;
  shwschvlymEditDiv: boolean
  shwschvlymDelDiv: boolean;
  sidebarHeading;
  user: any = {
    permissions: { 'slct_in': 1, 'insrt_in': 1, 'updt_in': 1, 'dlte_in': 1, 'exprt_in': 1 },
    'perm_url': 'user/permissions/Desginations Creation'
  }
  cmpnt_lst: any;
  fnlMnuOptLst: any;
  mnuSelectedOptions: any;
  mnuLst = [];
  mnuoptns = []
  editClicked: boolean;
  deleteClicked: boolean;
  slctdOpts = []
  edtslctdOpts = []
  mnuoptnlst: any;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = null;
  constructor(private fb: FormBuilder, private themeService: ThemeConstantService, private modal: NzModalService, public apiSrv: AppsService,
    public msg: NzMessageService, private notification: NzNotificationService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.mnuprflForm = new FormGroup({
      prfle_nm: new FormControl('', Validators.required),
      prfle_dscrn_tx: new FormControl(''),
      cmpnt_nm: new FormControl(''),
    });
    this.getmenuprofiles();
    this.getCmpntData();
  }
  getmenuprofiles() {
    const rte = 'auth2/admin/menu/profiles';
    let ct = 0;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.usrAcsErrMsg = false;
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.mnuprflLst = res['data'].mnuitems;
          //   console.log(this.mnuprflLst)
          this.mnuprflLst.filter(k => {
            k['sno'] = ++ct;
          });
          //   console.log(this.mnuprflLst)
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'Menu Profile Name', field: 'mnu_prfle_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Menu Profile Discription', field: 'prfle_dscrn_tx', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Creation Time', field: 'crtd_tmstmp', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'upd_tmstmp', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crtd_usr_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'upd_usr_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true }
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      }
    });
  }
  onToolbarPreparing(e) {
    //   console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Menu-Profile',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(opt, data) {
    this.shwSidebar = true;
    //   console.log(opt);
    //   console.log(data);
    this.fnlMnuOptLst = data


    if (opt == 'edit') {
      this.shwschvlymEditDiv = true;
      this.shwschvlymDelDiv = false;
      this.editClicked = true;
      this.deleteClicked = false;
      this.sidebarHeading = 'Edit Menu-Profile';

      // this.getmndls(data1);

      this.mnuprflForm.get('prfle_nm').setValue(data.mnu_prfle_nm)
      this.mnuprflForm.get('prfle_dscrn_tx').setValue(data.prfle_dscrn_tx)
      this.mnuprflForm.get('cmpnt_nm').setValue(data.cmpnt_id)
      this.mnuSelectedOptions = [];
      if (this.fnlMnuOptLst.menuItms) {
        for (let r = 0; r < this.fnlMnuOptLst.menuItms.length; r++) {
          for (let m = 0; m < this.fnlMnuOptLst.menuItms[r].mnuitemList.length; m++) {
            if (this.fnlMnuOptLst.menuItms[r].mnuitemList[m].a_in == 1) {
              this.fnlMnuOptLst.menuItms[r].mnuitemList[m]["isActive"] = true
              this.mnuSelectedOptions.push(this.fnlMnuOptLst.menuItms[r].mnuitemList[m].mnu_itm_nm);
            }
          }
        }
      }
      this.mnuoptns = this.fnlMnuOptLst.menuItms
      //   console.log(this.mnuoptns)
      //   console.log(this.mnuSelectedOptions)
      this.mnuitmdata()

    } else if (opt == 'delete') {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = true;
      this.deleteClicked = true;
      this.editClicked = false;
      this.sidebarHeading = 'Delete Menu-Profile';
      // this.getmndls(data);

      this.mnuprflForm.get('prfle_nm').setValue(data.mnu_prfle_nm)
      this.mnuprflForm.get('prfle_dscrn_tx').setValue(data.prfle_dscrn_tx)
      this.mnuprflForm.get('cmpnt_nm').setValue(data.cmpnt_id)
      this.mnuSelectedOptions = [];
      if (this.fnlMnuOptLst.menuItms) {
        for (let r = 0; r < this.fnlMnuOptLst.menuItms.length; r++) {
          for (let m = 0; m < this.fnlMnuOptLst.menuItms[r].mnuitemList.length; m++) {
            if (this.fnlMnuOptLst.menuItms[r].mnuitemList[m].a_in == 1) {
              this.mnuSelectedOptions.push(this.fnlMnuOptLst.menuItms[r].mnuitemList[m].mnu_itm_nm);
            }
          }
        }
      }
      //   console.log(this.mnuSelectedOptions)
    } else {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = false;
      this.editClicked = false;
      this.deleteClicked = false;
      this.mnuprflForm.get('prfle_nm').setValue('')
      this.mnuprflForm.get('prfle_dscrn_tx').setValue('')
      this.mnuprflForm.get('cmpnt_nm').setValue('')
      this.sidebarHeading = 'Add New Menu-Profile';
      this.mnuSelectedOptions = [];
      if (this.fnlMnuOptLst.menuItms) {
        for (let r = 0; r < this.fnlMnuOptLst.menuItms.length; r++) {
          for (let m = 0; m < this.fnlMnuOptLst.menuItms[r].mnuitemList.length; m++) {
            if (this.fnlMnuOptLst.menuItms[r].mnuitemList[m].a_in == 1) {
              this.fnlMnuOptLst.menuItms[r].mnuitemList[m]["isActive"] = false
              this.mnuSelectedOptions.push(this.fnlMnuOptLst.menuItms[r].mnuitemList[m].mnu_itm_nm);
            }
          }
        }
      }
      //   console.log(this.mnuSelectedOptions)
      this.mnuitmdata()
      this.slctdOpts = []
    }

  }
  mnuitmdata() {
    const rte = `auth2/admin/menu/items`;
    this.apiSrv.get(rte).subscribe((res) => {
      this.mnuoptnlst = []
      this.mnuoptnlst = res['data'];
      // console.log(this.mnuoptnlst)
      this.mnuoptnlst.filter((g) => {

        g['isActive'] = false

      })
      // console.log(this.mnuoptnlst)
      // console.log(this.editClicked)
      if (this.editClicked == true) {
        //   console.log(this.editdata)
        this.mnuoptnlst.filter((s) => {

          this.editdata.menuItms.filter((d) => {
            d.mnuitemList.filter((i) => {
              if (i.mnu_itm_id == s.mnu_itm_id) {
                s["isActive"] = true
              }
            })
          })

        })
        //   console.log(this.mnuoptnlst)
      }
    }, (error) => {
      // console.log(error);
    });


  }
  ischeked(e, data) {
    //   console.log(data)
    if (this.editClicked == false) {
      if (e == true) {
        this.slctdOpts.push({
          prnt_mnu_itm_id: data.prnt_mnu_itm_id,
          mnu_itm_id: data.mnu_itm_id,
          slctdOpt: data.isActive,
          mnu_prfle_id: this.fnlMnuOptLst.mnu_prfle_id,
          isNew: true
        })
      }
      else {
        this.slctdOpts.push({
          prnt_mnu_itm_id: data.prnt_mnu_itm_id,
          mnu_itm_id: data.mnu_itm_id,
          slctdOpt: data.isActive,
          mnu_prfle_id: this.fnlMnuOptLst.mnu_prfle_id,
          isNew: false
        })
      }
    }
    else if (this.editClicked == true || this.deleteClicked == true) {
      if (e == true) {
        this.edtslctdOpts.push({
          prnt_mnu_itm_id: data.prnt_mnu_itm_id,
          mnu_itm_id: data.mnu_itm_id,
          slctdOpt: data.isActive,
          mnu_prfle_id: this.fnlMnuOptLst.mnu_prfle_id,
          isNew: true
        })
      }
      else {
        this.edtslctdOpts.push({
          prnt_mnu_itm_id: data.prnt_mnu_itm_id,
          mnu_itm_id: data.mnu_itm_id,
          slctdOpt: data.isActive,
          mnu_prfle_id: this.fnlMnuOptLst.mnu_prfle_id,
          isNew: false
        })
      }
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  onEdit2(event) {
    //   console.log(event)
    this.editdata = event.data
    this.openSideBar("edit", this.editdata)
  }
  onDelete2(event) {
    //   console.log(event.key)
    this.idtodel = event.key.mnu_prfle_id
    this.deldata = event.data
    this.openSideBar("delete", this.deldata)
  }

  deletemnufun() {
    //   console.log(this.idtodel)
    let rte = `auth2/admin/menu/profiles/${this.idtodel}`;
    let data = {
      id: this.idtodel
    }
    this.apiSrv.delete(rte).subscribe((res) => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createMessage('success', 'Profile Deleted Successfully');
        this.getmenuprofiles();
      }
    })
  }
  getCmpntData() {
    // menu/options
    const rte = `auth2/admin/menu/profile/cmpnt`;
    this.apiSrv.get(rte).subscribe((res) => {
      // this.mnuLst.push({ mnuitemList: res['data'] });
      this.cmpnt_lst = res['data'];
      //   console.log(this.cmpnt_lst);
      // this.editClicked = true;
      // this.deleteClicked = false;

    });
  }
  savemnuprfl() {
    let data = {
      prfle_nm: this.mnuprflForm.value.prfle_nm,
      prfle_dscrn_tx: this.mnuprflForm.value.prfle_dscrn_tx,
      cmpnt_id: this.mnuprflForm.value.cmpnt_nm,
      mnuOpts: this.slctdOpts,
      prfle_dshbd_url_tx: "/"
    }
    //   console.log(data)
    let rte = 'auth2/admin/menu/profiles'
    this.apiSrv.create(data, rte).subscribe((res) => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createMessage('success', 'Profile Added Successfully');
        this.getmenuprofiles();
      }
    })
  }
  updtmnuprfl() {
    let data = {
      mnu_prfle_nm: this.mnuprflForm.value.prfle_nm,
      mnu_prfle_dscrn_tx: this.mnuprflForm.value.prfle_dscrn_tx,
      cmpnt_id: this.mnuprflForm.value.cmpnt_nm,
      mnuOpts: this.edtslctdOpts,
      mnu_prfle_id: this.fnlMnuOptLst.mnu_prfle_id
    }
    //   console.log(data)
    let rte = 'auth2/admin/menu/profiles/' + this.fnlMnuOptLst.mnu_prfle_id
    this.apiSrv.update(data, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createMessage('info', 'Profile Updated Successfully');
        this.getmenuprofiles();
      }
    })
  }
  createMessage(type: string, msg: string): void {
    this.msg.create(type, msg);
  }

  cancelbtn() {
    this.shwSidebar = false;
  }

  cancel(): void {
    this.createMessage('info', 'Profile Not Deleted');
  }

}
