<ds-loading *ngIf="showLdr"></ds-loading>
<div class="vehDsbrd">

    <div class="row bg-w chart-card">
        <div class="col-lg-12 col-md-12 col-sm-12 ">


            <div class="veList">
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Vehicle Registration Number</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{vehnm == null ? "NA": vehnm}}</h4>
                    </div>
                </div>



                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Driver Name</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4 *ngIf="VhcleDetails">{{VhcleDetails.crw_nm === null ? "Driver not assigned":
                            VhcleDetails.crw_nm}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Driver Phone Number</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4 *ngIf="VhcleDetails">{{VhcleDetails.crw_ph === null ? "Driver not assigned":
                            VhcleDetails.crw_ph}}</h4>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="row bg-w chart-card">
        <div class="col-lg-6 col-md-6 col-sm-6 crdBg crd">
            <!-- <h2>Vehicle Filter</h2>
                      <br> -->
            <h2><span>Vehicle Info</span><span>&nbsp;({{currentFrmtDate}})</span></h2>
            <!-- <h5>({{currentFrmtDate}})</h5> -->

            <div class="veList">
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Vehicle Status</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{asrt_sts_nm == null ? "NA": asrt_sts_nm}}</h4>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Vehicle Category</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{ctgrynm == null ? "NA": ctgrynm}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Vehicle Group</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{grpnm == null ? "NA": grpnm}}</h4>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Distance Travelled</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{CrntDyDst == null ? "0": CrntDyDst}} Kms</h4>
                    </div>
                </div>
                <div *ngIf="clntDtls && clntDtls[0].clnt_id==7">
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Sachivalayam</h3>
                        </div>
                        <div class="col-lg-1 col-md-1 w2">:</div>
                        <div class="col-lg-5 col-md-5">
                            <h4 *ngIf="VhcleDetails">{{VhcleDetails.svm_nm == null ? "Sachivalayam not
                                assigned":
                                VhcleDetails.svm_nm}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Secratory</h3>
                        </div>
                        <div class="col-lg-1 col-md-1 w2">:</div>
                        <div class="col-lg-5 col-md-5">
                            <h4 *ngIf="VhcleDetails">{{VhcleDetails.ss_nm == null ? "Secratory not
                                assigned":
                                VhcleDetails.ss_nm}} <span *ngIf="VhcleDetails.ss_cntct"> - {{VhcleDetails.ss_cntct ==
                                    null ? "":
                                    VhcleDetails.ss_cntct}}
                                </span></h4>
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Bins Covered</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4><b>{{totCvrsBns == null ? 0: totCvrsBns}}</b>/<b>{{totBins == null ? 0: totBins}}</b></h4> -->
                <!-- <h4><b *ngIf="totCvrsBns">{{totCvrsBns}}</b><b *ngIf="!totCvrsBns">0</b>/<b *ngIf="totBins">{{totBins}}</b><b *ngIf="!totBins">0</b></h4> -->
                <!-- </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Fuel Issued</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{CrntDyFuel == null ? 0: CrntDyFuel}} Ltrs | {{CrntDyFlAmt == null ? 0: CrntDyFlAmt}}
                            Rs</h4>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Current Location</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4 *ngIf="vehRts && vehRts.length>0">{{vehRts[0].crnt_lctn_nm == null ? "NA": vehRts[0].crnt_lctn_nm}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Current Area</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                       <h4 *ngIf="vehRts && vehRts.length>0">{{vehRts[0].crnt_area_nm == null ? "NA": vehRts[0].crnt_area_nm}}</h4>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Vehicle Odometer Reading</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{vehOdmtr == null ? 0: vehOdmtr}} km</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Device Odometer Reading</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{devOdmtr == null ? 0: devOdmtr}} km</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>IMEI Number</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{imei == null ? '': imei}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Chasis Number</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{chasis == null ? 'Not provided': chasis}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Speed</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{speed == null ? 0: speed}} km/Hr</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Data Last Received on</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{data_lst_rcvd == null ? '': data_lst_rcvd}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Distance Travelled Today</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4>{{dst_tvl_tdy === null ? '0': dst_tvl_tdy}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <h3>Stoppage Time</h3>
                    </div>
                    <div class="col-lg-1 col-md-1 w2">:</div>
                    <div class="col-lg-5 col-md-5">
                        <h4 *ngIf="diffTimeInMin < 60"><span>&nbsp;{{diffTimeInMin}} </span><span>Min</span></h4>
                        <h4 *ngIf="diffTimeInMin >= 60 && diffTimeInHrs < 24"><span>&nbsp;{{diffTimeInHrs}}
                            </span><span>Hrs</span></h4>
                        <h4 *ngIf="diffTimeInMin >= 24 && diffTimeInDys > 0"><span>&nbsp;{{diffTimeInDys}}
                            </span><span>Days</span></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5" *ngIf="vehRts && vehRts.length>0 && vehRts[0].frnt_cmra_url">
                        <div class="first_tooltip">Front Camera
                            <a target="_blank" [href]=vehRts[0].frnt_cmra_url>
                                <i nz-icon nzType="camera" nzTheme="outline" class="icnHvr"></i>

                            </a>
                            <!-- <span class="first_tooltip_text">Front Camera</span> -->
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-1 w2"></div>
                    <div class="col-lg-5 col-md-5" *ngIf="vehRts && vehRts.length>0 && vehRts[0].bck_cmra_url">
                        <div class="first_tooltip">Rear Camera
                            <a target="_blank" [href]=vehRts[0].bck_cmra_url>
                                <i nz-icon nzType="camera" nzTheme="outline" class="icnHvr"> </i>

                            </a>
                            <!-- <span class="first_tooltip_text">Rear Camera</span> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 crdBg">
            <div id="vehicleMap"></div>
        </div>
    </div>

    <div class="row bg-w chart-card">
        <div class="col-lg-12 col-md-12 crd">
            <h1>Last 7days 24/7 Distance covered by vehicle</h1>
            <div id="hepachartdiv"></div>
        </div>
    </div>

    <div class="row bg-w chart-card">
        <div class="col-lg-6 col-md-6 w-50 crd" style="margin-right: 24px;">
            <h1>Last 7Days Distance Travelled</h1>
            <div>
                <div id="lastSevenDaysDistanceChart"></div>
            </div>
            <!-- <div *ngIf="chart1Msg" style="text-align: center;">
                Distance not found for this vehicle
            </div> -->
        </div>
        <div class="col-lg-6 col-md-6 w-50 crd">
            <h1>Last 7Days Trips Travelled</h1>
            <div>
                <div id="lastSevenDaysChart"></div>
            </div>
            <!-- <div *ngIf="tripsMsg" style="text-align: center;">
                No trips defined for this vehicle
            </div> -->
        </div>
    </div>
    <!-- ---------------------------------------------------------------------------------------- -->



    <div class="row bg-w chart-card" style="border-top: 2px solid #f1f1f1;">
        <div class="col-lg-6 col-md-6 w-50 crd bxshw_n" style="margin-right: 24px;">
            <h1>Operational Details In Last 7Days</h1>
            <div *ngIf="oprtnlhrs && oprtnlhrs.length>0">
                <table class="cstm_tbl">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Vehicle No.</th>
                            <th>Vehicle Category</th>
                            <th>Operational Hours</th>
                            <th>Travelled(km)</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let o of oprtnlhrs">
                            <td>{{o.dt}}</td>
                            <td>{{o.asrt_nm}}</td>
                            <td>{{o.asrt_ctgry_nm}}</td>
                            <td>{{o.oprtn_hrs_ct}}</td>
                            <td>{{o.dst}}</td>
                            <td *ngIf="o.operated == 1" style="color: #009688;">Operated</td>
                            <td *ngIf="o.operated == 0" style="color: #9c27b0;">Not-Operated</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row NtfctnCrd" *ngIf="oprtnlhrsMsg">
                <h5 style="text-align: center;">{{oprtnlhrsMsg}}</h5>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 w-50 crd bxshw_n">
            <h1 style="padding-left: 20px;">Last 7Days Speed Violation</h1>
            <div class="row" *ngIf="ovrSpeed && ovrSpeed.length>0">
                <table class="cstm_tbl">
                    <thead>
                        <tr>
                            <th>Recorded Date</th>
                            <th>Vehicle No.</th>
                            <th>Vehicle Category</th>
                            <th>Speed(km)</th>
                            <th>Track Vehicle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of ovrSpeed">
                            <td>{{t.dt}}</td>
                            <td>{{t.asrt_nm}}</td>
                            <td>{{t.asrt_ctgry_nm}}</td>
                            <td>{{t.speed}}</td>
                            <td><a href="https://www.google.com/maps/search/?api=1&query={{t.r_lat}},{{t.r_lng}}"
                                    target="_blank"><i nz-icon nzType="node-index" nzTheme="outline"></i>Track</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row NtfctnCrd" *ngIf="ovrSpeedMsg">
                <h5 style="text-align: center;">{{ovrSpeedMsg}}</h5>
            </div>
        </div>
    </div>

    <div class="row bg-w chart-card" style="border-top: 2px solid #f1f1f1;">
        <div class="col-lg-6 col-md-6 w-50 crd bxshw_n" style="margin-right: 24px;">
            <h1>Last 7Days Trips List <b *ngIf="eachVeh && eachVeh.length">{{VhcleDetails.asrt_nm}}</b></h1>
            <div *ngIf="eachVeh && eachVeh.length>0">
                <table class="cstm_tbl">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>No. of Trips</th>
                            <th>Driver</th>
                            <th>Travelled(km)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let o of eachVeh">
                            <td>{{o.dt}}-{{curYr}}</td>
                            <td>{{o.trp_cnt}}</td>
                            <td>{{o.crw_nm ? o.crw_nm : 'Driver not Assigned'}}</td>
                            <td>{{o.trp_dst}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row NtfctnCrd" *ngIf="tripsMsg">
                <h5 style="text-align: center;">{{tripsMsg}}</h5>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 w-50 crd bxshw_n">
            <h1 style="padding-left: 20px;">Last 10 Vehicle Events <b
                    *ngIf="previousNotifications && previousNotifications.length>0">{{previousNotifications[0].asrt_nm}}</b>
            </h1>
            <div class="row" *ngIf="previousNotifications && previousNotifications.length>0">
                <table class="cstm_tbl">
                    <thead>
                        <tr>
                            <th>Fence</th>
                            <th>Fence Group</th>
                            <th>Fence Out</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of previousNotifications">
                            <td>{{t.fnce_nm}}</td>
                            <td>{{t.fnce_grp_nm}}</td>
                            <td>{{t.fnce_out_ts}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row NtfctnCrd" *ngIf="previousNotificationsMsg">
                <h5 style="text-align: center;">{{previousNotificationsMsg}}</h5>
            </div>
        </div>
    </div>

    <div class="row bg-w chart-card" style="border-top: 2px solid #f1f1f1;">
        <div class="col-lg-12 col-md-12  crd bxshw_n" style="margin-right: 24px;">
            <h1>Recently visited locations</h1>
            <div *ngIf="vstdLctns && vstdLctns.length>0">
                <table class="cstm_tbl">
                    <thead>
                        <tr>
                            <th>Location name</th>
                            <th>Location Group</th>
                            <!-- <th>Category</th> -->
                            <th>Time-In</th>
                            <th>Time-Out</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let o of vstdLctns">
                            <td>{{o.fnce_nm}}</td>
                            <td>{{o.fnce_grp_nm}}</td>
                            <!-- <td>{{o.fnce_ctgry_nm}}</td> -->
                            <td>{{o.time_in}}</td>
                            <td>{{o.time_out}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row NtfctnCrd" *ngIf="vstdLctns && vstdLctns.length==0">
                <h5 style="text-align: center;">{{vstdLctnsMsg}}</h5>
            </div>
        </div>
    </div>
    <!-- ---------------------------------------------------------------------------------------- -->


    <!-- <div class="bg-wp"  *ngIf="vehTrpLst && vehTrpLst.length>0">
        <h1 style="padding: 15px 20px 0px 15px;
        text-align: left;">Vehicle Trip Details</h1>
        <div class="row">
            <div class="col-lg-4 col-md-4 htscrl" *ngIf="!vehTrpLstMsg>0">
                <div class="serchCard" *ngFor="let c of vehTrpLst">
                    <div class="row">
                        <div class="col-lg-11 col-md-11">
                            <div class="row serchCardPdng" style="padding-top:5px ;">

                                <div class="col-lg-7 col-md-7 col">
                                    <p class="serchCardLeftTxt srchCrdHdr">{{c.asrt_nm}}</p>
                                    <p class="serchCardLeftTxt srchCrdHdr" style="    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 8px;
                                    color: #3f51b5;">{{c.asrt_ctgry_nm}}</p>
                                </div>
                                <div class="col-lg-5 col-md-5 col mg-a">
                                    <p class="serchCardLeftTxt srchCrdHdr" style="text-align: right;">Trip -
                                        {{c.trp_sqnc_nu}}</p>
                                    <p class="serchCardLeftTxt srchCrdHdr" style="text-align: right;    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 8px;
                                    color: #3f51b5;">{{c.trip_sts}}</p>
                                </div>
                            </div>

                            <div class="row serchCardPdng" style="padding-top:6px ;">

                                <div class="col-lg-2 col-md-2 col">
                                    <p class="serchCardLeftTxt">From</p>
                                </div>
                                <div class="col-lg-1 col-md-1 col">
                                    <p class="serchCardRightTxt" style="text-align: center;">:</p>
                                </div>
                                <div class="col-lg-9 col-md-9 col">
                                    <p class="serchCardRightTxt">{{c.strt_fnce_nm}}</p>
                                    <p class="serchCardRightTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 8px;">{{c.start_tm}}</p>
                                </div>

                            </div>

                            <div class="row serchCardPdng" style="padding-top:6px ;">

                                <div class="col-lg-2 col-md-2 col">
                                    <p class="serchCardLeftTxt">To</p>
                                </div>
                                <div class="col-lg-1 col-md-1 col">
                                    <p class="serchCardRightTxt" style="text-align: center;">:</p>
                                </div>
                                <div class="col-lg-9 col-md-9 col">
                                    <p class="serchCardRightTxt">{{c.end_fnce_nm}}</p>
                                    <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 8px;">{{c.end_tm}}</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1" style="margin: auto;padding: 0px;">
                            <p class="serchCardLeftTxt" style=" font-size: 15px;text-align: center; margin: auto;"
                                (click)="getTripMap(c,slctdDt)">
                                <i nz-icon nzType="right" nzTheme="outline"></i>
                            </p>
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-4 col-md-4 htscrl" *ngIf="vehTrpLstMsg">
                <div class="row NtfctnCrd">
                    <h5 style="text-align: center;">{{vehTrpLstMsg}}</h5>
                </div>
            </div>
            <div class="col-lg-8 col-md-8"  style="width: 100%;height: 600px;overflow: hidden;">
                <img src="assets/images/logo/map.png">
            </div>
        </div>
    </div> -->

</div>