import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-svm-vehicle-tracking',
  templateUrl: './svm-vehicle-tracking.component.html',
  styleUrls: ['./svm-vehicle-tracking.component.scss']
})
export class SvmVehicleTrackingComponent implements OnChanges {
  @Input() svm;
  svm_id: number;
  vehcleTble: any[];
  columnDefs: any[];
  selectedVehicle: any;
  visible = false;
  userDtls: any;
  livemap;
  allVhcls: any;
  icon;
  markerData: any;
  svm_apfs_cd: number;

  constructor(private apiSrvc: AppsService, ) { }

//   ngOnInit(): void {
//     this.svm_id = 3;
//     // this.getSachivalayamFenceData();
//     this.getwsvmVehiclesDtls();
// this.getvehicleTripsData();

//   }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.svm.currentValue);
    this.svm_id=changes.svm.currentValue.svm_id;
    this.svm_apfs_cd=changes.svm.currentValue.svm_apfss_cd;

    this.getwsvmVehiclesDtls();
    this.getvehicleTripsData();
  }
  initialize = function () {
    this.userDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.userDtls);
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.livemap = L.map('livemap', {
        center: [this.userDtls[0].lat, this.userDtls[0].lng],
        zoom: 3
      });

      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   // maxZoom: 15,
      //   // minZoom: 3,
      //   attribution: ''
      // });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: ''
      });

      tiles.addTo(this.livemap);
      this.getAssertList();

    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }




  };
  getwsvmVehiclesDtls() {
    this.columnDefs = [];
    this.vehcleTble = [];
    console.log(this.svm_apfs_cd)
    this.apiSrvc.get(`drivers/vehicles/bysvm/${this.svm_apfs_cd}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.vehcleTble = res['data'];
        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'Vehicle', field: 'asrt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Vehicles Status', field: 'sts', alignment: 'center', },
          { headerName: 'Operated/not Operated', field: 'opr_sts', alignment: 'center', },
          { headerName: 'Driver Name', field: 'crw_nm', alignment: 'center', },
          { headerName: 'Driver Contact', field: 'crw_ph', alignment: 'center', },

        ]
        this.initialize();
        this.getAssertList();

      }
    });
  }
  gotovehcleDashboard(data) {
    this.selectedVehicle = data.data;
    this.visible = true;
  }
  // Sorting vehicle markers on selection of category type
  getAssertList() {

    // this.allVhcls = this.vehcleTble;
    if (this.markerData) {
      this.livemap.removeLayer(this.markerData);
    }
    // for (var i = 0; i < this.allVhcls.length; i++) {
      this.vehcleTble.forEach(data => {
        this.allVhcls=data
      if (this.allVhcls.asrtglblId == "1") {
        if (this.allVhcls.spd_ct <= '2') {
          var icon = "_p_m.png";
        } else {
          var icon = "_r_m.png";
        }
      } else {
        var icon = "_o_m.png";
      }
      let myIcon1 = L.icon({
        iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.allVhcls.icon_path + icon,
        iconSize: [30, null],
        iconAnchor: [10, 25],
        popupAnchor: [5, -24],
        shaowSize: [35, 35]
      });
      if (this.allVhcls.lat != null && this.allVhcls.lng != null) {
        this.livemap.setView([this.allVhcls.lat, this.allVhcls.lng], 12);
        this.markerData = L.marker([this.allVhcls.lat, this.allVhcls.lng], { icon: myIcon1 }).bindPopup(
          "<b><center>" + this.allVhcls.asrt_nm + "</center></b><b>" + this.allVhcls.asrt_grp_nm + "|" + this.allVhcls.asrt_ctgry_nm + "|" + this.allVhcls.asrt_sts_nm +  "</b><br><b>Speed:" + this.allVhcls.spd_ct + "</b><br>", { 'className': 'custom' }).addTo(this.livemap).openPopup();
      }
    });
  }
  getvehicleTripsData(){

  }
}
