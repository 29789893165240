<!-- <div id="distanceChart"></div> -->
<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" nzTitle="Vehicle Dashboard"
    (nzOnClose)="visible = false" class="dashbSlider">
    <ng-container *nzDrawerContent>
        <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
    </ng-container>
</nz-drawer>

<nz-layout class="rmv_mrgn">
    <nz-layout>
        <nz-content>
            <div class="inner-content">

                <button nz-button (click)="closeSidebar();" *ngIf="opnSdebar == true" class="sdNavBtn rt-4h">
                    <!-- <i nz-icon nzType="filter" nzTheme="outline"></i> -->
                    Close
                </button>
                <button nz-button (click)="openSidebar();" *ngIf="opnSdebar == false" class="sdNavBtn2 rt-40">
                    <i nz-icon nzType="filter" nzTheme="outline"></i>Filters</button>
                <div id="livemap">
                    <div class="vhcl-sts-card">
                        <ul>
                            <li>
                                <span class="greenCls" style="height: 12px; width: 12px; "></span> Running
                            </li>
                            <li>
                                <span class="blueCls" style="height: 12px; width: 12px; "></span> Stationed for < 5Min
                                    </li>
                            <li>
                                <span class="yellowCls" style="height: 12px; width: 12px; "></span> Stationed for 5Min -
                                15Min
                            </li>
                            <li>
                                <span class="redCls" style="height: 12px; width: 12px; "></span> Stationed for > 15Min
                            </li>
                            <li>
                                <span class="greyCls"></span> Offline Vehicles
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </nz-content>
    </nz-layout>


    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" *ngIf="opnSdebar == true" class="sdr">
        <h2 class="sdHdr">Live tracking
            <span nz-icon nzType="close-circle" nzTheme="outline" style="    float: right;
      margin-top: 3px;" (click)="closedrvrlst()"></span>
        </h2>

        <div *ngIf="shwMrkerDta" class="lvtFrm">
            <div class="row mg-b6">
                <div class="col-lg-4 col-md-4">
                    <nz-form-label>Vehicle Number</nz-form-label>
                </div>
                <div class="col-lg-1 col-md-1 hdemblvw">
                    <nz-form-label>:</nz-form-label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <nz-form-label>AP Z2 3533</nz-form-label>
                </div>
            </div>
            <div class="row mg-b6">
                <div class="col-lg-4 col-md-4">
                    <nz-form-label>Vehicle Group</nz-form-label>
                </div>
                <div class="col-lg-1 col-md-1 hdemblvw">
                    <nz-form-label>:</nz-form-label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <nz-form-label>Health</nz-form-label>
                </div>
            </div>
            <div class="row mg-b6">
                <div class="col-lg-4 col-md-4">
                    <nz-form-label>Vehicle Category</nz-form-label>
                </div>
                <div class="col-lg-1 col-md-1 hdemblvw">
                    <nz-form-label>:</nz-form-label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <nz-form-label>Compactor-Big (Running)</nz-form-label>
                </div>
            </div>
            <div class="row mg-b6">
                <div class="col-lg-4 col-md-4">
                    <nz-form-label>Current Area</nz-form-label>
                </div>
                <div class="col-lg-1 col-md-1 hdemblvw">
                    <nz-form-label>:</nz-form-label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <nz-form-label>NA</nz-form-label>
                </div>
            </div>
            <div class="row mg-b6">
                <div class="col-lg-4 col-md-4">
                    <nz-form-label>Current Location</nz-form-label>
                </div>
                <div class="col-lg-1 col-md-1 hdemblvw">
                    <nz-form-label>:</nz-form-label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <nz-form-label>NA</nz-form-label>
                </div>
            </div>
            <div class="row mg-b6">
                <div class="col-lg-4 col-md-4">
                    <nz-form-label>Driver Name</nz-form-label>
                </div>
                <div class="col-lg-1 col-md-1 hdemblvw">
                    <nz-form-label>:</nz-form-label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <nz-form-label>Not Assigned</nz-form-label>
                </div>
            </div>
            <div class="row mg-b6">
                <div class="col-lg-4 col-md-4">
                    <nz-form-label>Driver Phone number</nz-form-label>
                </div>
                <div class="col-lg-1 col-md-1 hdemblvw">
                    <nz-form-label>:</nz-form-label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <nz-form-label>Not Assigned</nz-form-label>
                </div>
            </div>
            <div class="row mg-b6">
                <div class="col-lg-4 col-md-4">
                    <nz-form-label>Stoppage Time</nz-form-label>
                </div>
                <div class="col-lg-1 col-md-1 hdemblvw">
                    <nz-form-label>:</nz-form-label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <nz-form-label>Not Assigned</nz-form-label>
                </div>
            </div>
            <!-- <div class="row mg-b6">
                <div class="col-lg-3 col-md-3"></div>
                <div class="col-lg-6 col-md-6">
                    <button nz-button nzType="primary" nzBlock>Get Directions</button> -->
            <!-- </div>
                <div class="col-lg-3 col-md-3"></div>
                
            </div> -->

            <div style="text-align:center;margin-top: 10px;">
                <h6>
                    ------ Daywise Distance Covered Status ------
                </h6>
                <div id="distanceChart"></div>
            </div>

            <div style="text-align:center;margin-top: 10px;">
                <h6>
                    ------ Daywise Trip Covered Status ------
                </h6>
                <div id="tripChart"></div>
            </div>

        </div>

        <div *ngIf="shwDta" class="lvtFrm">

            <form nz-form [formGroup]="livetrackform">

                <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Show ULB/Mandal</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select nzShowSearch class="slctBx" [(ngModel)]="selectedtenants"
                                    (ngModelChange)="ontntSelects($event)" [ngModelOptions]="{standalone: true}">
                                    <nz-option [nzLabel]="'Select All'" [nzValue]="0" *ngIf="showAll"></nz-option>
                                    <!-- <nz-option [nzLabel]="'Deselect All'" [nzValue]="1"></nz-option> -->
                                    <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Select {{grpLbl}}</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please select VehicleGroup"
                                    formControlName="vhcl" (ngModelChange)="getCatLists($event)">
                                    <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                                    <!-- <nz-option [nzLabel]="'Deselect All'" [nzValue]="'deselect'"></nz-option> -->
                                    <nz-option *ngFor="let v of assrtGroupList" [nzLabel]="v.asrt_grp_nm"
                                        [nzValue]="v.asrt_grp_id"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row mg-b6" *ngIf="ShowClap">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Show Vehicle Vendor</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr"
                                    (ngModelChange)="onvndrSelect($event)" [ngModelOptions]="{standalone: true}">
                                    <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
                                    <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>


                <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Show Location Group</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [nzMaxTagCount]="2" nzPlaceHolder="Please select Location group"
                                    [(ngModel)]="locModel" (ngModelChange)="onLocationSelect($event)"
                                    formControlName="lctn">
                                    <nz-option *ngFor="let l of activeGroups" [nzLabel]="l.fnce_grp_nm"
                                        [nzValue]="l.fnce_grp_id">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Show Locations</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [nzMaxTagCount]="2" nzPlaceHolder="Please select Location"
                                    [(ngModel)]="fnclocModel" (ngModelChange)="getLocOnMap()"
                                    formControlName="lctnFnce">
                                    <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                                    <nz-option [nzLabel]="'Deselect All'" [nzValue]="'deselect'"></nz-option>
                                    <nz-option *ngFor="let l of fncsLst" [nzLabel]="l.fnce_nm" [nzValue]="l.fnce_id">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>


                <div class="row mg-b6" *ngIf="clap_in==0">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Select Vehicle Category</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please select VehicleCategory"
                                    formControlName="ctrgy" (ngModelChange)="getTypeList($event)">
                                    <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                                    <!-- <nz-option [nzLabel]="'Deselect All'" [nzValue]="'deselect'"></nz-option> -->
                                    <nz-option *ngFor="let t of asrtCtgryList" [nzLabel]="t.asrt_ctgry_nm"
                                        [nzValue]="t.asrt_ctgry_id"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>


                <!-- <div class="input-row" nz-row> 
                <div nz-col nzFlex="2">
                   <nz-form-label>Select VehcileType</nz-form-label>
               </div>
               <div nz-col nzFlex="3">
                   <nz-form-item>
                       <nz-form-control>
                           <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please select Trip" formControlName="typ" (ngModelChange)="getAssertList()">
                               <nz-option *ngFor="let p of asrtTypeList" [nzLabel]="p.asrt_type_id" [nzValue]="p.asrt_type_nm"></nz-option>
                           </nz-select>
                       </nz-form-control>
                   </nz-form-item>
               </div>
           </div>  -->


            </form>

            <nz-tabset nzType="card" (nzSelectChange)="tabchange($event)" [nzSelectedIndex]=seletedIndex>
                <nz-tab *ngFor="let tab of tabs" [nzTitle]="titleTemplate">
                    <ng-template #titleTemplate>
                        <div class="tbDv"
                            [ngStyle]="tab.actv_in == 1 ? {'background': tab.actv_bg_clr, 'color': '#fff'} : {'background': tab.bg_clr, 'color': tab.clr}">
                            <p [ngStyle]="tab.actv_in == 1 ? {'color': '#fff'} : {'color': tab.clr}">{{ tab.cnt }}</p>
                            <i nz-icon [nzType]="tab.icon" nzTheme="outline"></i>{{ tab.name }}
                        </div>
                    </ng-template>
                </nz-tab>
            </nz-tabset>
            <!-- <div class="ds-card grid lvtrcktbl"> -->
                <dx-data-grid id="gridContainer" [dataSource]="stslist" [remoteOperations]="true" [showBorders]="true"
                    (onRowClick)="getEahVehicle($event)"  [rowAlternationEnabled]="true" [columnWidth]="100" class="lvetracktable"
                     [wordWrapEnabled]="true"  [showColumnLines]="true" [showRowLines]="true" [showBorders]="true">
                    <dxo-search-panel [visible]="true" [width]="436" placeholder="Search..." ></dxo-search-panel>
                    <dxo-pager [visible]="false"></dxo-pager>
                    <dxo-paging [pageSize]="10" [enabled]="false"></dxo-paging>
                     <!-- <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging> -->
                     <dxo-scrolling columnRenderingMode="horizontal"></dxo-scrolling>
                    <dxi-column dataField="sno" caption="S.No" width="45" alignment="center"></dxi-column>
                    <dxi-column dataField="asrt_nm" caption="Vehicle"  alignment="center"  width="90"></dxi-column>
                    <dxi-column dataField="asrt_sts_nm" dataType="string" caption="Status" 
                        cellTemplate="stsTemplate" alignment="center"> </dxi-column>
                    <dxi-column dataField="asrt_ctgry_nm" caption="Category" width="120" alignment="left">
                    </dxi-column>
                    <dxi-column dataField="tdy_dst" caption="Distance"  alignment="center"  width="80">
                    </dxi-column>
                    <div *dxTemplate="let d of 'stsTemplate'">
                        <div [ngStyle]="{'color': d.row.data.clr_cd}">
                            <!-- <span><i nz-icon [nzType]="d.row.data.icn_txt" nzTheme="outline"></i></span> -->
                            <!-- <span style="padding-left: 5px;text-transform: uppercase;">{{d.row.data.asrt_sts_nm}}</span> -->
                            <span><img src="assets/images/markers/mapIcons/m/sgnl_{{d.row.data.sgnl_strngth_ct}}.png" alt=""> </span>
                            <span><img src="assets/images/markers/mapIcons/m/igntn_{{d.row.data.igntn_in}}.png" alt=""> </span>
                            <span style="margin-right: 8px;"><img src="assets/images/markers/mapIcons/m/chrg_{{d.row.data.chrg_in}}.png" alt=""> </span>
                            <span
                                [ngClass]="(d.row.data.spd_ct > 0 && d.row.data.diffTimeInMin < 5) ? 'greenCls' : (d.row.data.spd_ct >= 0 && d.row.data.diffTimeInMin < 5) ? 'blueCls' : (d.row.data.spd_ct >= 0 && d.row.data.diffTimeInMin >=5 && d.row.data.diffTimeInMin < 15) ? 'yellowCls' : 'redCls'"></span>
                            <!-- <span *ngIf="diffTimeInMin >= 60 && diffTimeInHrs < 24" style="background-color: yellow;">&nbsp;{{diffTimeInMin}}<span>Min</span></span>
                            <span *ngIf="diffTimeInMin >= 24 && diffTimeInDys > 0" style="background-color: red;">&nbsp;{{diffTimeInMin}}<span>Min</span></span> -->
                        </div>
                    </div>

                </dx-data-grid>
            <!-- </div> -->
        </div>
    </nz-sider>
</nz-layout>