import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommunicationPortalComponent } from './enterprise/communication-portal/communication-portal.component';

import { MyTicketsComponent } from './my-tickets/my-tickets.component';


const routes: Routes = [
  { path: '', component: MyTicketsComponent },
  { path: '*', component: MyTicketsComponent },
  { path: 'tickets-assigned', component: MyTicketsComponent },
  { path: 'live-updates', component: CommunicationPortalComponent }

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupportRoutingModule { }
