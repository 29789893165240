<div class="page-header">
        <div class=" align-items-md-center">
            <div class="row hdrBar">
                <div class="col-lg-12 col-md-12 mg-a">
                    <h1 >
                        <app-common-header [header_name]="header_label"></app-common-header>
                    </h1>
                </div>
    
            </div>
    
        </div>
    </div>
<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <div  nz-col class="gutter-row text-center" [nzXXl]="3" [nzXl]="6" [nzLg]="6" [nzMd]="6" [nzSm]="6" [nzXs]="12"
        [nzSpan]="6" *ngFor='let c of ctgrylst; let i = index'>
        
        <!-- <button class="rptCtgryBtn" nz-button [(ngModel)]="c.chk_ind" (click)="changeCtgry(c)">
                <span class="rptCtgryIcn">{{c.rpt_ctgry_cd}}</span>
                <span>{{c.rpt_ctgry_nm}}</span>
        </button> -->
        <button class="rptCtgryBtn" nz-button (click)="changeCtgry(c)">
                <div class="row">
                        <div class="text-center col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">
                                <div class="rptCtgryIcn">{{c.rpt_ctgry_cd}}</div>
        
                        </div>
                        <div class="text-center col-xl-9 col-lg-9 col-md-10 col-sm-9 col-xs-9 col-9">
                                <div class="rptCtgryTxt">{{c.rpt_ctgry_nm}}</div>
                        </div>
                </div>
        </button>
       </div>
       <div  nz-col class="gutter-row text-center" [nzXXl]="3" [nzXl]="6" [nzLg]="6" [nzMd]="6" [nzSm]="6" [nzXs]="12"
        [nzSpan]="6">
       <button class="rptCtgryBtn" nz-button (click)="getReprtLst()">
        <div class="row">
                <div class="text-center col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">
                        <div class="rptCtgryIcn">*</div>

                </div>
                <div class="text-center col-xl-9 col-lg-9 col-md-10 col-sm-9 col-xs-9 col-9">
                        <div class="rptCtgryTxt">All Reports</div>
                </div>
        </div>
        </button>
        </div>
   
</div>
<nz-divider></nz-divider>

<div class="row">
        <div class="col-xl-1 col-lg-1 col-md-1"></div>
        <div class="col-xl-10 col-lg-10 col-md-10">
                <nz-input-group>
                        <input type="text" style="padding: 8px;
        border-radius: 4px;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);" nz-input [(ngModel)]="searchText"
                                 placeholder="Search report here"
                                [ngModelOptions]="{standalone: true}" />
                </nz-input-group>
        </div>
        <div class="col-xl-1 col-lg-1 col-md-1"></div>
</div>


<div class="row" style="margin-bottom: 20px;margin-top: 20px;" *ngFor="let d of rptLst | filter:searchText">
        <div class="col-xl-12 col-lg-12 col-md-12 crdbgrnd">
                <div class="report-grpnm">{{d.grp_nm}}</div>
                <!-- <div class="report-sub-text">{{ f.rpt_desc_txt }}</div> -->
                <div class="row">
                        <div *ngFor="let f of d.opts" [ngClass]="f.rpt_shw_in ? 'col-xl-4 col-lg-4 col-md-4' : 'd-n'">
                                <div class="dashboard-count-card cardOne"  (click)="gotoRpt(f, d)">
                                        <div class="report-label">{{f.rpt_nm}}</div>
                                        <div class="report-sub-text">{{ f.rpt_dscn_tx }}</div>
                                </div>
                        </div>
                </div>
        </div>
</div>

<div *ngIf="loader">
        <div class="row" style="margin-bottom: 20px;margin-top: 20px;" *ngFor="let i of [0,1,2]">
                <div class="col-xl-12 col-lg-12 col-md-12 crdbgrnd">
                        <div class="report-grpnm">
                                <nz-skeleton-element nzType="input" [nzActive]="true" [nzSize]="'small'"
                                        style="width:200px">
                                </nz-skeleton-element>
                        </div>
                        <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-4" *ngFor="let i of [0,1]">
                                        <div class="dashboard-count-card cardOne">
                                                <nz-skeleton-element nzType="input" [nzActive]="true" [nzSize]="'small'"
                                                        style="width:200px">
                                                </nz-skeleton-element>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
</div>