import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-report-profile',
  templateUrl: './report-profile.component.html',
  styleUrls: ['./report-profile.component.scss']
})
export class ReportProfileComponent implements OnInit {
  user: any = {
    permissions: { 'slct_in': 1, 'insrt_in': 1, 'updt_in': 1, 'dlte_in': 1, 'exprt_in': 1 },
    'perm_url': 'user/permissions/Desginations Creation'
  }
  rprtprflLst;
  columnDefs;
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  rprtprflForm;
  shwSidebar: boolean;
  fnlrprtOptLst: any;
  fnlRptOptLst: any;
  rprtoptns: any;
  rprtSelectedOptions: any;
  shwschvlymEditDiv: boolean
  shwschvlymDelDiv: boolean;
  sidebarHeading;
  editClicked: boolean;
  deleteClicked: boolean;
  slctdOpts = []
  edtslctdOpts = []
  editdata;
  deldata;
  cmpnt_lst: any;
  rptoptnlst: any;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private fb: FormBuilder, private themeService: ThemeConstantService, private modal: NzModalService, public apiSrv: AppsService, public msg: NzMessageService, private notification: NzNotificationService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.rprtprflForm = new FormGroup({
      prfle_nm: new FormControl('', Validators.required),
      prfle_dscrn_tx: new FormControl(''),
      cmpnt_nm: new FormControl(''),
    });
    this.getrprtprofiles();
    this.getCmpntData();
  }
  getrprtprofiles() {
    const rte = 'reports/profile';
    let ct = 0;
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.rprtprflLst = res['data'].reportitems;
          console.log(this.rprtprflLst)
          this.rprtprflLst.filter(k => {
            k['sno'] = ++ct;
          });
          console.log(this.rprtprflLst)
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'Report Profile Name', field: 'rpt_prfle_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Report Profile Discription', field: 'prfle_dscrn_tx', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Creation Time', field: 'crtd_tmstmp', alignment: 'center', width: 200 },
            { headerName: 'Updated Time', field: 'upd_tmstmp', alignment: 'center', width: 200 },
            { headerName: 'Created By', field: 'crtd_usr_nm', alignment: 'center', width: 200 },
            { headerName: 'Updated By', field: 'upd_usr_nm', alignment: 'center', width: 200 },

          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      }
    });
  }
  onToolbarPreparing(e) {
    console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Report-profile',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  rptitmdata() {
    const rte = `reports/items`;
    this.apiSrv.get(rte).subscribe((res) => {
      this.rptoptnlst = []
      this.rptoptnlst = res['data'];
      console.log(this.rptoptnlst)
      this.rptoptnlst.filter((g) => {

        g['isActive'] = false

      })
      // console.log(this.rptoptnlst)
      // console.log(this.editClicked)
      if (this.editClicked == true) {
        console.log(this.editdata)
        this.rptoptnlst.filter((s) => {

          this.editdata.reportops.filter((d) => {
            d.reportitemList.filter((i) => {
              if (i.rpt_id == s.rpt_id) {
                s["isActive"] = true
              }
            })
          })

        })
        console.log(this.rptoptnlst)
      }
    }, (error) => {
      // console.log(error);
    });


  }
  ischeked(e, data) {
    console.log(data)
    if (this.editClicked == false) {
      if (e == true) {
        this.slctdOpts.push({
          prnt_rpt_id: data.prnt_rpt_id,
          rpt_id: data.rpt_id,
          slctdOpt: data.isActive,
          rpt_prfle_id: this.fnlRptOptLst.rpt_prfle_id,
          isNew: true
        })
      }
      else {
        this.slctdOpts.push({
          prnt_rpt_id: data.prnt_rpt_id,
          rpt_id: data.rpt_id,
          slctdOpt: data.isActive,
          rpt_prfle_id: this.fnlRptOptLst.rpt_prfle_id,
          isNew: false
        })
      }
    }
    else if (this.editClicked == true || this.deleteClicked == true) {
      if (e == true) {
        this.edtslctdOpts.push({
          prnt_rpt_id: data.prnt_rpt_id,
          rpt_id: data.rpt_id,
          slctdOpt: data.isActive,
          rpt_prfle_id: this.fnlRptOptLst.rpt_prfle_id,
          isNew: true
        })
      }
      else {
        this.edtslctdOpts.push({
          prnt_rpt_id: data.prnt_rpt_id,
          rpt_id: data.rpt_id,
          slctdOpt: data.isActive,
          rpt_prfle_id: this.fnlRptOptLst.rpt_prfle_id,
          isNew: false
        })
      }
    }
  }
  openSideBar(opt, data) {
    this.shwSidebar = true;
    console.log(opt);
    console.log(data);
    this.fnlRptOptLst = data


    if (opt == 'edit') {
      this.shwschvlymEditDiv = true;
      this.shwschvlymDelDiv = false;
      this.editClicked = true;
      this.deleteClicked = false;
      this.sidebarHeading = 'Edit Report-profile';

      // this.getmndls(data1);

      this.rprtprflForm.get('prfle_nm').setValue(data.rpt_prfle_nm)
      this.rprtprflForm.get('prfle_dscrn_tx').setValue(data.prfle_dscrn_tx)
      this.rprtprflForm.get('cmpnt_nm').setValue(data.cmpnt_id)
      this.rprtSelectedOptions = [];
      if (this.fnlRptOptLst.reportops) {
        for (let r = 0; r < this.fnlRptOptLst.reportops.length; r++) {
          for (let m = 0; m < this.fnlRptOptLst.reportops[r].reportitemList.length; m++) {
            if (this.fnlRptOptLst.reportops[r].reportitemList[m].a_in == 1) {
              this.fnlRptOptLst.reportops[r].reportitemList[m]["isActive"] = true
              this.rprtSelectedOptions.push(this.fnlRptOptLst.reportops[r].reportitemList[m].rpt_nm);
            }
          }
        }
      }
      this.rprtoptns = this.fnlRptOptLst.reportops
      console.log(this.rprtoptns)
      console.log(this.rprtSelectedOptions)
      this.rptitmdata()

    } else {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = false;
      this.editClicked = false;
      this.deleteClicked = false;
      this.rprtprflForm.get('prfle_nm').setValue('')
      this.rprtprflForm.get('prfle_dscrn_tx').setValue('')
      this.rprtprflForm.get('cmpnt_nm').setValue('')
      this.sidebarHeading = 'Add New Report-profile';
      this.rprtSelectedOptions = [];
      if (this.fnlRptOptLst.reportops) {
        for (let r = 0; r < this.fnlRptOptLst.reportops.length; r++) {
          for (let m = 0; m < this.fnlRptOptLst.reportops[r].reportitemList.length; m++) {
            if (this.fnlRptOptLst.reportops[r].reportitemList[m].a_in == 1) {
              this.fnlRptOptLst.reportops[r].reportitemList[m]["isActive"] = false
              this.rprtSelectedOptions.push(this.fnlRptOptLst.reportops[r].reportitemList[m].rpt_nm);
            }
          }
        }
      }
      console.log(this.rprtSelectedOptions)
      this.rptitmdata()
      this.slctdOpts = []
    }

  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  onEdit2(event) {
    console.log(event)
    this.editdata = event.data
    this.openSideBar("edit", this.editdata)
  }
  onDelete2(event) {
    console.log(event)
    var idtodel = event.key.rpt_prfle_id
    this.modal.confirm({
      nzTitle: 'Are you sure delete this item?',
      nzContent: `${event.key.rpt_prfle_nm}`,
      nzOkText: 'Yes',
      // nzOkType: 'danger',
      nzOkDanger:true,
      nzOnOk: (event) => this.deletemnufun(idtodel),
      // nzOnOk: (event) => console.log("jo"),
      nzCancelText: 'No',
      nzOnCancel: () =>
        console.log("jo"),
    });
  }
  deletemnufun(e) {
    console.log(e)
    let rte = `auth2/admin/menu/profiles/${e}`;
    let data = {
      id: e
    }
    this.apiSrv.delete(rte).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createNotification('success', 'Profile Deleated Successfully');
        this.getrprtprofiles();
      }
    })
  }
  getCmpntData() {
    // menu/options
    const rte = `auth2/admin/menu/profile/cmpnt`;
    this.apiSrv.get(rte).subscribe((res) => {
      // this.mnuLst.push({ mnuitemList: res['data'] });
      this.cmpnt_lst = res['data'];
      console.log(this.cmpnt_lst);
      // this.editClicked = true;
      // this.deleteClicked = false;

    });
  }
  saverptprfl() {
    let data = {
      prfle_nm: this.rprtprflForm.value.prfle_nm,
      prfle_dscrn_tx: this.rprtprflForm.value.prfle_dscrn_tx,
      cmpnt_id: this.rprtprflForm.value.cmpnt_nm,
      mnuOpts: this.slctdOpts,
      prfle_dshbd_url_tx: "/"
    }
    console.log(data)
    let rte = 'reports/profile/add'
    this.apiSrv.create(data, rte).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createNotification('success', 'Profile Added Successfully');
        this.getrprtprofiles();
      }
    })
  }
  updtrptprfl() {
    let data = {
      rpt_prfle_nm: this.rprtprflForm.value.prfle_nm,
      mnu_prfle_dscrn_tx: this.rprtprflForm.value.prfle_dscrn_tx,
      cmpnt_id: this.rprtprflForm.value.cmpnt_nm,
      mnuOpts: this.edtslctdOpts,
      rpt_prfle_id: this.fnlRptOptLst.rpt_prfle_id
    }
    console.log(data)
    let rte = 'reports/profile/update'
    this.apiSrv.create(data, rte).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createNotification('info', 'Profile Updated Successfully');
        this.getrprtprofiles();
      }
    })
  }
  createNotification(type: string, msg: string): void {
    this.notification.create(
      type,
      msg,
      ''
    );
  }


}
