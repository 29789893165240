import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AmsVehiclesComponent } from './ams-vehicles.component';
import { AssignDeviceComponent } from './assign-device/assign-device.component';
import { DistnceAprvlComponent } from './distnce-aprvl/distnce-aprvl.component';
import { VehicleAsgnmentComponent } from './vehicle-asgnment/vehicle-asgnment.component';
import { VhcleSachivalayamAsgnmntComponent } from './vhcle-sachivalayam-asgnmnt/vhcle-sachivalayam-asgnmnt.component';
import { VhcleUlbAsgnmntComponent } from './vhcle-ulb-asgnmnt/vhcle-ulb-asgnmnt.component';
import { AddVechileComponent } from './vehicle-asgnment/add-vechile/add-vechile.component';
// import { MicropocketDashboardComponent } from '../ulb/micropocket-dashboard/micropocket-dashboard.component';
const routes: Routes = [

  { path: 'vehicles', component: AmsVehiclesComponent, pathMatch: 'full' },
  { path: 'assign', component: VehicleAsgnmentComponent, pathMatch: 'full' },
  { path: 'devices', component: AssignDeviceComponent, pathMatch: 'full' },
  { path: 'dstnce_aprvl', component: DistnceAprvlComponent, pathMatch: 'full' },
  { path: 'vhcle_asgnmnt', component: VhcleUlbAsgnmntComponent, pathMatch: 'full' },
  { path: 'vhcle_svmasgnmnt', component: VhcleSachivalayamAsgnmntComponent, pathMatch: 'full' },
  { path: 'add_newvehcile', component: AddVechileComponent, pathMatch: 'full' },
  

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AmsVehiclesRoutingModule { }
