import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DriversComponent } from './drivers.component';

// import { MicropocketDashboardComponent } from '../ulb/micropocket-dashboard/micropocket-dashboard.component';
const routes: Routes = [

  { path: 'list', component: DriversComponent , pathMatch: 'full' },
  { path: '**', redirectTo : '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriversRoutingModule { }
