import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-house-types',
  templateUrl: './house-types.component.html',
  styleUrls: ['./house-types.component.scss']
})
export class HouseTypesComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  hsetypLst: any;
  columnDefs;
  shwSidebar = false;
  hsetypNm: string | null = null;
  shwstsAlrt = false;
  shwEditDiv = false;
  hsetypId: any;
  sidebarHeading: string;
  shwDelDiv: boolean;
  shwLdg: boolean;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService,
    public apiSrv: AppsService,
    private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getHsestsLst();
  }

  getHsestsLst() {
    this.shwLdg = true;
    const rte = 'admin/house/type';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
          this.shwLdg = false;
          if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.hsetypLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: ' House Type Name', field: 'hse_ctgry_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 265 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },

          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    });
  }

  onCellClick(opt, edtData) {
    console.log(edtData)
    if (opt === 'edit') {
      this.shwEditDiv = true;
      this.hsetypNm = edtData.data.hse_ctgry_nm;
      this.hsetypId = edtData.data.hse_ctgry_id;
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwEditDiv = false;
      this.shwDelDiv = true;
      this.hsetypId = edtData.data.hse_ctgry_id;
      this.hsetypNm = edtData.data.hse_ctgry_nm;
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New  House Type',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwEditDiv = true;
      this.shwDelDiv = false;
      this.sidebarHeading = 'Edit House Type';
    } else if (opt === 'delete') {
      this.shwEditDiv = false;
      this.shwDelDiv = true;
      this.sidebarHeading = 'Delete House Type';
    } else {
      this.shwEditDiv = false;
      this.shwDelDiv = false;
      this.hsetypNm = '';
      this.sidebarHeading = 'Add New House Type';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  saveNew() {
    const hsetypData = {
      name: this.hsetypNm
    };

    const rte = 'admin/house/type';
    this.apiSrv.create(hsetypData, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('success', ' House Type Successfully Added');
        this.getHsestsLst();
      }
    });
  }

  saveEdit() {
    const hseEdtData = {
      id: this.hsetypId,
      name: this.hsetypNm
    };
    const rte = 'admin/house/type/'+this.hsetypId;
    this.apiSrv.update(hseEdtData, rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('info', ' House Type Successfully Updated');
        this.getHsestsLst();
      }
    });
  }

  saveDelete() {
    const rte = 'admin/house/type/'+this.hsetypId;
    this.apiSrv.delete(rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('warning', ' House Type Successfully Deleted');
        this.getHsestsLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info(' House Type Not Deleted');
  }

}


