<pg-header title="Designations" ticon="folder" bckbtn=true bckurl="/setup"></pg-header>

<div *ngIf="usrAcsErrMsg == false">
  <div>
    <div class="ds-card grid">
      <ds-loading *ngIf="shwLdg"></ds-loading>
      <dx-data-grid id="gridContainer" [dataSource]="gridData" [remoteOperations]="false" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
        [showRowLines]="true" [showBorders]="true" [showBorders]="true" style="width:100%"
        (onToolbarPreparing)="(USER_PERMISSIONS.insrt_in==1)?onToolbarPreparing($event):false">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
          [showInfo]="true">
        </dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
          fileName="Designations List"></dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [allowFiltering]="f.filter">
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false"
          [width]="100" caption="Edit" cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="cursor-pointer tblEdtBtn" (click)="onEdit2(data)">
              Edit
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons"
          [width]="100" caption="Delete" cellTemplate="delTemplate">
          <div *dxTemplate="let data of 'delTemplate'">
            <div class="tblDelBtn" (click)="onDelete2(data)">
              Delete
            </div>
          </div>
        </dxi-column>
        <!-- <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false"
                type="buttons" [width]="100" caption="Delete" cellTemplate="delTemplate">
                <div *dxTemplate="let data of 'delTemplate'">
                  <div class="cursor-pointer tblDelBtn">
                      <div nz-popconfirm nzPopconfirmTitle="Are you sure deleting this item?" [nzIcon]="iconTpl" nzPopconfirmPlacement="topLeft" (nzOnConfirm)="confirm(data)" nzOkType="danger"
                      nzOkText="YES" nzCancelText="NO" (nzOnCancel)="cancel()" >
                        Delete
                  </div>
                    </div>  
                </div>
                <ng-template #iconTpl>
                  <i nz-icon nzType="question-circle-o" style="color: red;"></i>
                </ng-template>
            </dxi-column> -->
      </dx-data-grid>
    </div>
  </div>




  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="350" [nzVisible]="visible" nzTitle="{{formDetails.stngs.form_title}}"
    (nzOnClose)="close()">

    <div class="inptLbl">Designation</div>
    <nz-input-group [nzSuffix]="inputClearTpl">
      <input nz-input [formControl]="name" placeholder="please enter designation" />
    </nz-input-group>
    <ng-template #inputClearTpl><i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
        *ngIf="formDetails.stngs.oper_mode == 'edit' || formDetails.stngs.oper_mode == 'delete'"
        (click)="frmcntl()"></i></ng-template>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="formDetails.stngs.oper_mode == 'new'"
      (click)="saveFormData(name.value)">Submit</button>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="formDetails.stngs.oper_mode == 'edit'"
      (click)="saveFormData(name.value)">Update</button>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="formDetails.stngs.oper_mode == 'delete'" nz-popconfirm
      nzPopconfirmTitle="Are you sure deleting this designation?" [nzIcon]="iconTpl" nzPopconfirmPlacement="left"
      [nzPopconfirmShowArrow]="false" (nzOnConfirm)="confirm(name.value)" nzOkType="danger" nzOkText="YES"
      nzCancelText="NO" (nzOnCancel)="cancel()">
      Delete
    </button>
    <button nz-button nzType="default" class="cnclBtn" (click)="cancelbtn()">Cancel</button>
    <ng-template #iconTpl>
      <i nz-icon nzType="question-circle-o" style="color: red;"></i>
    </ng-template>


  </nz-drawer>

</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
  </nz-result>
</div>
