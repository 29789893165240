<!-- <quill-editor [modules]="modules" [(ngModel)]="model"></quill-editor> -->

<!-- <div [innerHTML]="model"></div> -->
<div *ngIf="usrAcsErrMsg == false">
  <div class="row">
    <div class="col-6">
      <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item>
          Help
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>
          <a [routerLink]="routerLinkVariable">{{pgGrpNm}}</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>
          {{pageName}}
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </div>
    <div class="col-6">
      <nz-button-group style="float: right;">
        <button nz-button nzType="primary" (click)="onBack"><i nz-icon nzType="left"></i> Go back</button>
      </nz-button-group>
    </div>
  </div>
  <nz-layout class="layout">
    <nz-sider nzCollapsible nzBreakpoint="lg" [nzCollapsedWidth]="0" style="margin-top:1.5%;max-width: 500px;
    min-width: 250px;">
      <ul nz-menu nzTheme="light" nzMode="inline">
        <li style="font-size:12px;" nz-menu-item *ngFor="let s of sectnLst" [nzSelected]="s.sctn_id==selectdnav.sctn_id"
          (click)="selectedValue(s)">
          <span>{{s.sctn_nm}}</span>
        </li>
        <li style="text-align: center;"><button nz-button nzType="primary" (click)="Addsection()" *ngIf="USER_PERMISSIONS.insrt_in==1">ADD</button></li>
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-content *ngIf="newCntent==true">
        <div style="padding:30px;">
          <input nz-input placeholder="Basic usage" [(ngModel)]="selectdnav.sctn_nm" style="font-size: 19px;
          font-weight: 500;color: #000;border-top: none;border-left: none;margin-bottom: 2%;border-right: none;" />
          <editor [init]="init" [(ngModel)]="selectdnav.dscptn_txt"></editor>
        </div>
      </nz-content>
      <nz-content *ngIf="newCntent==false">
        <div class="inner-content">
          <div class="sectnmncss">
            <div class="row">
              <div class="col-10">
                {{value}}
              </div>
              <div class="col-1" style="text-align: right;cursor: pointer;" *ngIf="USER_PERMISSIONS.updt_in==1" (click)="editSection()">
                <i nz-icon nzType="edit" nzTheme="twotone"></i>
              </div>
              <div class="col-1" style="cursor: pointer;" *ngIf="USER_PERMISSIONS.dlte_in==1" (click)="deleteSection()">
                <i nz-icon nzType="delete" nzTheme="twotone"></i>
              </div>

            </div>
          </div>
          <div [innerHTML]="contentHtml"></div>
        </div>
      </nz-content>
      <div class="row">
        <div class="col-10"></div>
        <div class="col-1">
          <button nz-button nzType="primary" *ngIf="newCntent==true" (click)="cancelcontent()">cancel</button>
        </div>
        <div class="col-1">
          <button nz-button nzType="primary" *ngIf="newCntent==true" (click)="savecontent()">Save</button>
        </div>
      </div>
    </nz-layout>
  </nz-layout>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
  </nz-result>
</div>