import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';

import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
// import { Color, Label } from 'ng2-charts';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

@Component({
  selector: 'app-sachivalyam-home-dashboard',
  templateUrl: './sachivalyam-home-dashboard.component.html',
  styleUrls: ['./sachivalyam-home-dashboard.component.scss']
})
export class SachivalyamHomeDashboardComponent implements OnInit {
  List: any;
  CrdList: any;
  RqstCrdList: any;
  crd_Class;
  mnthcrnt;
  year;
  FileCrdList: any;
  postdata: any;
  insiCrdList: any;
  filtermore: boolean = false;
  wardobj: any;
  areasLst: any;
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  @ViewChild('fullScreen') divRef;
  public lineChartData: ChartDataset[] = [
    { data: [], label: 'PH Workers' },
    { data: [], label: 'Loaders' },
    { data: [], label: 'Drivers' },
    { data: [], label: 'Supervisors' },
  ];
  public lineChartDataComp: ChartDataset[] = [
    { data: [], label: 'Created' },
    { data: [], label: 'Closed' },
  ];
  public lineChartDataFile: ChartDataset[] = [
    { data: [], label: 'Created' },
    { data: [], label: 'Closed' },
  ];
  public lineChartDataRequest: ChartDataset[] = [
    { data: [], label: 'Created' },
    { data: [], label: 'Closed' },
  ];
  public lineChartDataIncident: ChartDataset[] = [
    { data: [], label: 'Created' },
    { data: [], label: 'Closed' },
  ];
  // public lineChartLabels: Label[] = [];
  // public lineChartLabels1: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'bottom' }},
    scales: { 
      x: {
        display: true,
        title: {
          display: true,
          text: 'Dates'
      }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'No.of members Attended'
        }
      }
    }
  }
  public lineChartOptions1: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'bottom' }},
    scales: { //you're missing this
      y: {
        display: false,
        ticks: {
          stepSize: 2
        },
        beginAtZero: false,
      },
      xAxes: {
        display: false,
        ticks: {
          stepSize: 2
        },
        beginAtZero: false
      }
    }
  }
  public barChartOptionsBig: ChartOptions = {
    responsive: true,
     plugins: {
    legend: { position: 'bottom' }},
    scales: { 
      x: {
        display: true,
        title: {
          display: true,
          text: 'Dates'
      }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Percentage'
        }
      }
    }
  }


  // public lineChartColors: Color[] = [
  //   {
  //     borderColor: '#16a086',
  //     backgroundColor: 'transparent',
  //   },
  //   {
  //     borderColor: '#e77e23',
  //     backgroundColor: 'transparent',
  //   },
  //   {
  //     borderColor: '#2dcc70',
  //     backgroundColor: 'transparent',
  //   },
  //   {
  //     borderColor: '#e84c3d',
  //     backgroundColor: 'transparent',
  //   },
  // ];
  // public lineChartColors1: Color[] = [
  //   {
  //     borderColor: '#16a086',
  //     backgroundColor: '#16a0863b',
  //   },
  //   {
  //     borderColor: '#e77e23',
  //     backgroundColor: '#e77e2329',
  //   },
  // ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];


  // public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  // public barChartColors: Color[] = [
  //   { backgroundColor: '#16a086' },
  //   { backgroundColor: '#e77e23' }

  // ]


  barChartData: ChartDataset[] = [
    { data: [], label: 'Not Scanned Gates', stack: 'a' },
    { data: [], label: 'Scanned Gates', stack: 'a' }
  ];

  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    let today = new Date();
    return differenceInCalendarDays(current, today) > 0;
  };

  showLdr: boolean;
  todayDate = new Date().toISOString().slice(0, 10);
  batChrtData: any = [];
  lineChrtData: any = [];
  barData1: any = [];
  barData2: any = [];
  barXaxis: any = [];
  act_PHWorker: any = [];
  act_Loader: any = [];
  act_Supervisor: any = [];
  act_Driver: any = [];
  lineXaxis: any = [];
  cvrdCrdData: any = {};
  attndncCrdData: any = {};
  attdPer: any;
  columnDefs: any = [];
  svmTbleData: any;
  mcrpctTbleData: any;
  columnDefs1: any = [];
  datePk: any = new Date();
  FileCrdGraph: any;
  compCrdGraph: any;
  incidentCrdGraph: any;
  created: any = [];
  closed: any = [];
  lineXaxis1: any = [];
  lineXaxis2: any = [];
  lineXaxis3: any = [];
  lineXaxis4: any = [];
  created1: any = [];
  closed1: any = [];
  created2: any = [];
  closed2: any = [];
  closed3: any = [];
  created3: any = [];
  svmLst: any;
  svmobj: any;
  clctn_dtls = [];
  wrdschDta: any;
  togglefilter : boolean = false;
  svm_nm: any;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private apiSrv: AppsService) {
    this.getLstSvnDysCvrdGts();
    this.getLstSvnDysAtndc();
    this.getCvrdCrdData();
    this.getAttndnceCrdData();
    this.getFleTrackCardGraph();
    this.getComplaintCardGraph();
    this.getIncidentmanagementCardGraph();
    this.getRequestCardGraph();
    this.year = (new Date()).getFullYear();
    this.mnthcrnt = this.monthNames[(new Date()).getMonth()];
    this.crd_Class = 'row clr-crds2';
  }

  ngOnInit() {
    this.divRef = document.documentElement;
    this.getwrdschdata();
    this.getwrds();
  }
  getwrdschdata(){
    this.apiSrv.get('admin/web/employee/ward/sachivalayam').subscribe(
      (d) => {
        if (d['status'] == 200)
        this.wrdschDta = d['data'];
        console.log(this.wrdschDta)
        if(this.wrdschDta[0].wrd_id != null){
           this.wardobj = this.wrdschDta[0].wrd_id;
           this.svmobj = this.wrdschDta[0].svm_id;
           this.togglefilter = false;
           this.svm_nm = this.wrdschDta[0].svm_nm;
           this.getCardCount();
        } else {
          this.togglefilter = true;
          this.getCardCount();
        }
      },
      (err) => {
        console.error('Error while fetching');
      }
    );
  }
  getdata() {
    this.getCardCount();
  }
  getLstSvnDysCvrdGts() {
    //this.showLdr=true;
    // let rte=`public/web/dashboard/sanitation/gates/scan/day-wise`;
    let rte='external/owms/gates-scan';
    this.apiSrv.get(rte).subscribe(res => {
      this.usrAcsErrMsg = false;
      if(res['status'] == 404){
        this.usrAcsErrMsg = true;
      }
      else if(res['status'] == 200){
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
      this.batChrtData = res['data'];
      for (let i = this.batChrtData.length - 1; i >= 0; i--) {
        this.barData1.push(this.batChrtData[i].tot_gts - this.batChrtData[i].scnd_gts);
        this.barData2.push(this.batChrtData[i].scnd_gts);
        this.barXaxis.push(this.batChrtData[i].dt);
      }
      this.barChartData = [
        { data: [...this.barData2], label: 'Scanned Gates', stack: 'a' },
        { data: [...this.barData1], label: 'Not Scanned Gates', stack: 'a' }

      ];
      // this.barChartLabels = [...this.barXaxis]
    }
    else{
      this.usrAcsErrMsg = true;
    }
    }
    })
  }
  getLstSvnDysAtndc() {
    //this.showLdr=true;
    // let rte = `public/web/dashboard/sanitation/employee/attendance/day-wise`;
    let rte = 'external/owms/attendance';
    this.apiSrv.get(rte).subscribe(res => {
      this.lineChrtData = res['data'];

      for (let j = this.lineChrtData.length - 1; j >= 0; j--) {
        this.act_PHWorker.push(this.lineChrtData[j].act_PHWorker);
        this.act_Loader.push(this.lineChrtData[j].act_Loader);
        this.act_Supervisor.push(this.lineChrtData[j].act_Supervisor);
        this.act_Driver.push(this.lineChrtData[j].act_Driver);
        this.lineXaxis.push(this.lineChrtData[j].dt);
      }
      this.lineChartData = [
        { data: [...this.act_PHWorker], label: 'PH Workers' },
        { data: [...this.act_Loader], label: 'Loaders' },
        { data: [...this.act_Driver], label: 'Drivers' },
        { data: [...this.act_Supervisor], label: 'Supervisors' },
      ];
      // this.lineChartLabels = [...this.lineXaxis];

    })
  }
  getCvrdCrdData() {

    // let rte=`public/web/dashboard/sanitation/gates/scan/overall-counts`;
    let rte=`external/owms/coverage/counts`;
    let postData = {
      "ulb_id": 74,
      "from_dt": this.todayDate,
      "to_dt": this.todayDate
    }
    this.apiSrv.post(postData, rte).subscribe(res => {
      this.cvrdCrdData = res['data'][0];
    })

  }
  getAttndnceCrdData() {
    // let rte=`public/web/dashboard/sanitation/employee/attendance/overall-counts`;
    let rte='external/owms/attendance/counts';
    let postData = {
      "ulb_id": 74,
      "from_dt": this.todayDate,
      "to_dt": this.todayDate
    }
    this.apiSrv.post(postData, rte).subscribe(res => {
      this.attndncCrdData = res['data'][0];
      this.attdPer = this.attndncCrdData.tot_attnd_prcnt.toFixed(2);
    })

  }
  getCardCount() {
    this.showLdr = true;
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
      'TYPE': 2,  
      indicator: '',
      id: '',
      DEPARTMENT:"",
      CATEOGRY:'',
SOURCE:0,
DEPRT:"",
    }
    console.log(this.postdata)
    let rte = `admin/web/home-dashboard/card/counts`
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      this.CrdList = res['data'];
      this.showLdr = false;
    })
    this.getFleTrackCardCount();
  }
  getComplaintCardGraph() {
    this.showLdr = true;
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
      'TYPE': 2
    }
    let rte = `admin/web/home-dashboard/graph/counts`
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      this.compCrdGraph = res['data'];
      this.showLdr = false;
      for (let j = 0; j < this.compCrdGraph.length; j++) {
        this.created.push(this.compCrdGraph[j].open);
        this.closed.push(this.compCrdGraph[j].close);
        this.lineXaxis1.push(this.compCrdGraph[j].dt);
      }
      this.lineChartDataComp = [
        { data: [...this.created], label: 'Created' },
        { data: [...this.closed], label: 'Closed' },
      ];
      // this.lineChartLabels1 = [...this.lineXaxis1];
    })

  }
  getcardsdta() {
    this.showLdr = true;
    if (this.wardobj == 0) {
      this.wardobj = ''
    }
    if (this.svmobj == 0) {
      this.svmobj = ''
    }
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
    }
    var rte = `admin/web/dashboard/collectandpay/dashboard`;
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'].data;
        // if (res_data.length < 4) {
        //   for (let i = res_data.length + 1; i <= 4; i++) {
        //     res_data.push({
        //       ttl_clctn: 0,
        //       clctn_type_nm: `Null`
        //     })
        //   }
        // }
        // if (res_data && res_data.length > 0) {
          this.clctn_dtls = res_data;
        // }
        this.showLdr = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getFleTrackCardCount() {
    this.showLdr = true;
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
      'TYPE': 1,
      indicator: '',
      id: '',
      DEPARTMENT:"",
      CATEOGRY:'',
SOURCE:0,
DEPRT:"",
    }
    let rte = `admin/web/home-dashboard/card/counts`
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      this.FileCrdList = res['data'];
      this.showLdr = false;
    })
    this.getRequestCardCount();

  }
  getFleTrackCardGraph() {
    this.showLdr = true;
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
      'TYPE': 1
    }
    let rte = `admin/web/home-dashboard/graph/counts`
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      this.FileCrdGraph = res['data'];
      this.showLdr = false;

      for (let j = 0; j < this.FileCrdGraph.length; j++) {
        this.created1.push(this.FileCrdGraph[j].open);
        this.closed1.push(this.FileCrdGraph[j].close);
        this.lineXaxis2.push(this.FileCrdGraph[j].dt);
      }
      this.lineChartDataFile = [
        { data: [...this.created1], label: 'Created' },
        { data: [...this.closed1], label: 'Closed' },
      ];
      // this.lineChartLabels1 = [...this.lineXaxis2];
    })

  }
  getRequestCardCount() {
    this.showLdr = true;
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
      'TYPE': 3,
      indicator: '',
      id: '',
      DEPARTMENT:"",
      CATEOGRY:'',

SOURCE:0,
DEPRT:"",

    }
    let rte = `admin/web/home-dashboard/card/counts`
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      this.RqstCrdList = res['data'];
      this.showLdr = false;
    })
    this.getincidentmanagementCardCount();
  }
  getRequestCardGraph() {
    this.showLdr = true;
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
      'TYPE': 3
    }
    let rte = `admin/web/home-dashboard/graph/counts`
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      this.requestCrdGraph = res['data'];
      this.showLdr = false;

      for (let j = 0; j < this.requestCrdGraph.length; j++) {
        this.created2.push(this.requestCrdGraph[j].open);
        this.closed2.push(this.requestCrdGraph[j].close);
        this.lineXaxis3.push(this.requestCrdGraph[j].dt);
      }
      this.lineChartDataRequest = [
        { data: [...this.created2], label: 'Created' },
        { data: [...this.closed2], label: 'Closed' },
      ];
      // this.lineChartLabels1 = [...this.lineXaxis3];
    })

  }
  requestCrdGraph(requestCrdGraph: any) {
    throw new Error("Method not implemented.");
  }
  getincidentmanagementCardCount() {
    this.showLdr = true;
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
      'TYPE': 5,
      indicator: '',
      id: '',
      DEPARTMENT:"",
      CATEOGRY:'',
SOURCE:0,
DEPRT:"",
    }
    let rte = `admin/web/home-dashboard/card/counts`
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      this.insiCrdList = res['data'];
      this.showLdr = false;
    })
    this.getcardsdta();
  }
  getIncidentmanagementCardGraph() {
    this.showLdr = true;
    this.postdata = {
      'WARD': this.wardobj,
      'SACHIVALAYAM': this.svmobj,
      'TYPE': 5
    }
    let rte = `admin/web/home-dashboard/graph/counts`
    this.apiSrv.post(this.postdata, rte).subscribe((res) => {
      this.incidentCrdGraph = res['data'];
      this.showLdr = false;

      for (let j = 0; j < this.incidentCrdGraph.length; j++) {
        this.created3.push(this.incidentCrdGraph[j].open);
        this.closed3.push(this.incidentCrdGraph[j].close);
        this.lineXaxis4.push(this.incidentCrdGraph[j].dt);
      }
      this.lineChartDataIncident = [
        { data: [...this.created3], label: 'Created' },
        { data: [...this.closed3], label: 'Closed' },
      ];
      // this.lineChartLabels1 = [...this.lineXaxis4];
    })

  }

  toggle() {
    this.filtermore = !this.filtermore;
    if (this.filtermore == true) {
      this.filtermore = true;
    }
    else {
      this.filtermore = false;
    }
  }
  getwrds() {
    // ----------- get Areas -----------
    this.apiSrv.get('open/wards').subscribe(
      (d) => {
        if (d['status'] == 200)
          d['data'].unshift({
            wrd_nm: "ALL",
            wrd_id: 0
          })
        this.areasLst = d['data'];
        this.wardobj = this.areasLst[0].wrd_id;
      },
      (err) => {
        console.error('Error while fetching');
      }
    );
  }
  onWrdChng() {
    this.apiSrv.post({ wrd_id: this.wardobj }, 'support/sachivalayams').subscribe(
      (d) => {
        if (d['status'] == 200) {
          d['data'].unshift({
            svm_nm: "ALL",
            svm_id: 0
          })
          this.svmLst = d['data'];
          this.svmobj = this.svmLst[0].svm_id;
        }
      },
      (err) => {
        console.error('Error while fetching Currencies');
      })
  }
  openFullscreen() {
    const elem = this.divRef.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }
  getAWdth(awdth) {
    return { 'width': awdth + '%' };
  }
  getWdth(wdth) {
    return { 'width': wdth + '%' };
  }

  getTWdth(twdth) {
    return { 'width': twdth + '%' };
  }

}

