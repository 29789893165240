import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'leaflet.heat/dist/leaflet-heat.js';
// import 'leaflet-fullscreen';

@Component({
  selector: 'app-scndry-trips-cvrge-dashboard',
  templateUrl: './scndry-trips-cvrge-dashboard.component.html',
  styleUrls: ['./scndry-trips-cvrge-dashboard.component.scss']
})
export class ScndryTripsCvrgeDashboardComponent implements OnInit {

  clntDtls: any;
  rdrctSvmDtls;
  tntLst: any;
  userDtls: any;
  today = new Date();
  slctdDt = new Date();
  curDate: string;
  tripsData: any[];
  showLdr: boolean;
  svm_id: any;
  columnDefs: ({ headerName: string; field: string; alignment: string; width: number; filter: boolean; } | { headerName: string; field: string; alignment: string; width?: undefined; filter?: undefined; })[];
  usrDtls: any;
  map: any;
  vehile_no: any;
  schdPolylineArry: any = [];
  crclArry: any = [];
  shwCvrge: boolean = true;
  shwplyback: boolean = false;
  trvld_Polyline: any;
  rteDta: any;
  schdPolyline: any;
  eachTrpData: null;
  heatData: any;
  circleMarker: any;
  tdyCoveredTrips: any[];
  dbrd_ky = 'veh';
  asrt_id: any;
  dateFormat = 'dd/MM/yyyy';
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router, public datepipe: DatePipe) {
    this.rdrctSvmDtls = [];
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.rdrctSvmDtls = this.TransfereService.getLoclData('svmDta');
    this.asrt_id = this.rdrctSvmDtls.asrt_id
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    
    this.slctdDt = this.rdrctSvmDtls.slctdDt;

  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  ngOnInit(): void {
    console.log(this.rdrctSvmDtls);
    this.getTripsData();
    this.initializeMap();
    // this.getPlayback();
    this.getCoveredTripsCount();
    this.onChkCoverage(true)
    // this.getHeatMap()
  }
  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    // this.getCoveredTripsCount();
    // this.getTripsData();

  }
  goBack() {
    this.TransfereService.ClearLocalData('svmDta');
    this.route.navigate([`internal/dashboard/scndrytripulbCvrge-dashboard`]);
  }
  getTripsData() {
    this.tripsData = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      asrt_id: this.asrt_id
    }
    console.log(postslctdDt)
    let rte = `dashboard/enterprise/vechile/getvchleCvrdTrpDetails`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tripsData = res['data'];
        console.log(this.tripsData)
        this.columnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB', field: 'ZONE', alignment: 'left' },
          // { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left' },
          { headerName: 'Vehicle', field: 'VEHICLE', alignment: 'Center' },
          { headerName: 'Trip Number', field: 'TRIP_NUM', alignment: 'Center' },
          { headerName: 'Source', field: 'SOURCE', alignment: 'left' },
          { headerName: 'Destination', field: 'DESTINATION', alignment: 'left' },
          { headerName: 'Start Time', field: 'ACTUAL_START', alignment: 'Center' },
          { headerName: 'End Time', field: 'ACTUAL_END', alignment: 'Center' },
          { headerName: 'Trip Status', field: 'TripStatus', alignment: 'left' },
          // { headerName: 'Coverage %', field: 'cvrg_prcntg_ct', alignment: 'Center' },
          { headerName: 'Route Deviated', field: 'Deviation', alignment: 'Center' },
        ]
      }
      this.showLdr = false;
    }, (err) => {
    });
  }
  initializeMap() {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.usrDtls)
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {
      if (!this.map) {
        this.map = L.map('map', {
          center: [this.usrDtls[0].lat, this.usrDtls[0].lng],
          zoom: 3,
          // fullscreenControl: {
          //   pseudoFullscreen: false // if true, fullscreen to page width and height
          // }
        });
        this.map.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 13);
        const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          minZoom: 13,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: ''
        });
        tiles.addTo(this.map);
        // Add fullscreen control to the map
        L.control.fullscreen({
          pseudoFullscreen: false // if true, fullscreen to page width and height
        }).addTo(this.map);
        // 
        // L.control.fullscreen().addTo(this.map);
      }
    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
  }
  onChkCoverage(evt) {
    console.log(evt)
    this.shwCvrge = evt;
    if (this.shwCvrge == true) {
      this.shwplyback = false;
      this.eachTrpData = null;
      this.getHeatMap();
    } else {
      if (this.crclArry) {
        for (let c = 0; c < this.crclArry.length; c++) {
          this.map.removeLayer(this.crclArry[c].crcls);
        }
      }
      this.shwplyback = true;
      this.getPlayback();

    }
  }
  onChkPlyback(evt) {
    console.log(evt)
    this.shwplyback = evt;
    if (this.shwplyback == true) {
      this.shwCvrge = false;
      this.getPlayback();

    } else {
      this.eachTrpData = null;
      this.shwCvrge = true;
      this.getHeatMap();
    }
  }
  getHeatMap() {
    console.log('lllllllllllllllllll')
    this.shwCvrge = true;
    this.shwplyback = false;
    if (this.schdPolylineArry) {
      for (let s = 0; s < this.schdPolylineArry.length; s++) {
        this.map.removeLayer(this.schdPolylineArry[s].plylns);
      }
    }
    if (this.trvld_Polyline) {
      this.map.removeLayer(this.trvld_Polyline);
    }
    this.heatData = [];
    if (this.crclArry) {
      for (let c = 0; c < this.crclArry.length; c++) {
        this.map.removeLayer(this.crclArry[c].crcls);
      }
    }
    // getting heat map data
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postData = {
      asrt_id: this.asrt_id,
      dt: this.curDate,
    };
    const rte = `dashboard/enterprise/cvrgeHeatMap`;
    this.apiServ.post(postData, rte).subscribe((res) => {
      this.showLdr = true;
      if (res['status'] == 200) {
        this.heatData = res['data'];
        this.showLdr = false;
        for (let h = 0; h < this.heatData.length; h++) {
          if (this.heatData[h].cvrg_ct == 0) {
            this.heatData[h].clr = '#e30505';
            this.heatData[h].fillColor = '#e36868';
          } else {
            this.heatData[h].clr = '#04c92b';
            this.heatData[h].fillColor = '#99eaa9';
          }
          this.circleMarker = L.circle([this.heatData[h].lat, this.heatData[h].lng], { stroke: false, radius: 55, weight: 0, fillOpacity: 0.7, fillColor: this.heatData[h].fillColor }).addTo(this.map);


          this.crclArry.push({ crcls: this.circleMarker });
          this.map.fitBounds(this.circleMarker.getBounds());
          this.map.setZoom(17);
        }
      }
    })
  }
  getCoveredTripsCount(): any {
    this.tdyCoveredTrips = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: this.rdrctSvmDtls.tnt_id,
      asrt_id: this.asrt_id
    }
    let rte = `dashboard/enterprise/scndry/getCoveredTripsCount/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tdyCoveredTrips = res['data'];
      }
      console.log(this.tdyCoveredTrips)
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  getPlayback() {
    console.log('hhhhhhhhhhhhhhhhhhhhhhh')
    this.shwCvrge = false;
    this.shwplyback = true;
    // console.log(this.schdPolylineArry)
    if (this.schdPolylineArry) {
      console.log('pppppppppp')
      for (let s = 0; s < this.schdPolylineArry.length; s++) {
        this.map.removeLayer(this.schdPolylineArry[s].plylns);

      }
    }
    if (this.trvld_Polyline) {
      this.map.removeLayer(this.trvld_Polyline);
    }
    if (this.crclArry) {
      for (let c = 0; c < this.crclArry.length; c++) {
        this.map.removeLayer(this.crclArry[c].crcls);
      }
    }

    // plotting schedule polyline on map
    for (let r = 0; r < this.tripsData.length; r++) {
      this.schdPolyline = L.polyline([JSON.parse(this.tripsData[r].rte_arc_lne_tx)], {
        color: '#008000',
        weight: 5,
        lineCap: 'butt',
        opacity: 0.6,
        smoothFactor: 10.0,
        lineJoin: 'round'
      }).addTo(this.map);
      this.schdPolylineArry.push({ plylns: this.schdPolyline });
      this.map.fitBounds(this.schdPolyline.getBounds());
    }


    let strt_ts = this.curDate + ' 00:00:00';


    let end_ts = this.curDate + ' 23:59:59';

    var postslctdDt = {
      dt: this.curDate,
      data: {
        dev_imei_nu: this.tripsData[0].dev_imei_nu,
        start_tm: this.datepipe.transform(strt_ts, 'yyyy-MM-dd HH:mm'),
        end_tm: this.datepipe.transform(end_ts, 'yyyy-MM-dd HH:mm')
      }
    }

    this.showLdr = true;
    const rte = `dashboard/enterprise/get_tripTrvlRoute`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {

        this.rteDta = res['data'];
        console.log(this.rteDta)
        var bnd_strng = [];
        for (let l = 0; l < this.rteDta.length; l++) {
          bnd_strng.push([this.rteDta[l].lat, this.rteDta[l].lng]);
        }
        this.showLdr = false;
        this.trvld_Polyline = L.polyline(([bnd_strng]), {
          color: 'red',
          weight: 4,
          lineCap: 'butt',
          opacity: 0.5,
          smoothFactor: 10.0,
          lineJoin: 'round',
        }).addTo(this.map);
        this.map.fitBounds(this.trvld_Polyline.getBounds());
        // }
      } else {
        this.showLdr = false;
      }
    });
  }
}
