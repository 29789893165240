import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as L from 'leaflet';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { AppsService } from 'src/app/shared/services/apps.service';
am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-svm-usercharges',
  templateUrl: './svm-usercharges.component.html',
  styleUrls: ['./svm-usercharges.component.scss']
})
export class SvmUserchargesComponent implements OnChanges {
  @Input() svm;
  svmId;
  latlng: any;
  mapData: any[];
  markerIcon1: { icon: L.Icon<L.IconOptions>; };
  markerIcon: { icon: L.Icon<L.IconOptions>; };
  map1: any;
  visible: boolean;
  map: any;
  cstmr_nm: any;
  cstmrMbl: any;
  bsnsNm: any;
  gateNu: any;
  gateCtgryNm: any;
  prvceBlns: any;
  clstrNm: any;
  lstInvAmt: any;
  clctd: string;
  houseInfo: any=[];
  ulbId: any;
  gateWiseClctnData: any = [];
  date = new Date();
  selectedmonth = this.date.getUTCMonth() + 1;
  selectedYear = this.date.getUTCFullYear();

  clctdDateWiseData: any;
  columnDefs: any = [];
  notClctd: any = [];
  invceSmryCts: any = {};
  sumtl_ct = 0;
  sumpd_ct = 0;
  sumnt_pd_ct = 0;
  sumtl_at = 0;
  sumtl_pd_at = 0;
  sumtl_un_pd_at = 0;
  cardData: any = [];
  admnCts: any = {};
  chartsData: any;
  month: number;
  year: number;
  mnth: number;
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  mnthName = this.monthNames[new Date().getMonth()]
  cpay_svm_id: any;
  cpy_ulb_id: number;
  svm_apfs_cd;
  constructor(public apiSrv: AppsService, ) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.svm.currentValue)
    this.ulbId = changes.svm.currentValue.ulb_id;
    // this.ulbId=74;
    this.svmId = changes.svm.currentValue.svm_id;
    this.cpay_svm_id = changes.svm.currentValue.cpay_svm_id;
    this.svm_apfs_cd=changes.svm.currentValue.svm_apfss_cd;
    // this.svm_apfs_cd=1064022;
    this.getSachivalayamFenceData();
    this.getSvmWideSummaryCardData();
    this.getNtClctdListData();
    this.getLstOneMonthDateWiseClctd();
    this.getGateWiseClctnData();
  }
  initializeMapOptions1() {
    var map1 = L.map('map1', {
    })
    map1.remove();


    this.map1 = L.map('map1').setView([16.708785, 81.108350], 14);
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    //   maxZoom: 19,

    // }).addTo(this.map1);
    L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 19,
      subdomains:['mt0','mt1','mt2','mt3'],
      attribution: ''
    }).addTo(this.map1);

    this.markerIcon1 = {
      icon: L.icon({
        iconSize: [30, 30],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: `../../../../assets/images/app-store/map`
      })
    };
    var latlngs = [[17.000045, 81.798557], [17.001451, 81.802967], [17.001985, 81.807849],
    [16.999133, 81.801809], [17.000045, 81.798557]];

    var polygon = L.polygon(this.mapData as [], { color: '#f17c7c' }).addTo(this.map1);
    // zoom the map to the polygon
    this.map1.fitBounds(polygon.getBounds());



  }
  getSachivalayamFenceData() {
    const rte = `external/owms/sachivalayams/fence/${this.svm_apfs_cd}`;
    this.apiSrv.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.latlng = res['data'][0]['fence'].substring(res['data'][0]['fence'].indexOf("((") + 2, res['data'][0]['fence'].indexOf("))")).split(",");
        this.mapData = [];
        for (let i = 0; i < this.latlng.length; i++) {
          let data = this.latlng[i].split(" ").reverse();
          data = data.filter(e => {
            return e != "";
          })
          this.mapData.push(data);
        }

        this.initializeMapOptions1();

      }

    });
  }
  close1(): void {
    this.visible = false;
    this.map1.off();
    this.map1.remove();
  }

  getSvmWideSummaryCardData() {
    var dateObj = new Date();
    this.month = dateObj.getUTCMonth() + 1; //months from 1-12
    this.year = dateObj.getUTCFullYear();
    var req_body = {
      wrd_id: null,
      svm_id: this.cpay_svm_id,
      svm_apfs_cd:this.svm_apfs_cd,
      mcrpt_id: null,
      tu_id: null,
      'WARD': '',
      'SACHIVALAYAM': '',
      'MONTH': this.month,
      'YEAR': this.year
    }
    let rte = `external/usrchrgs/state/ulb/${this.ulbId}/${this.selectedYear}/${this.selectedmonth}/card-summary/monthly`;
    this.apiSrv.post(req_body, rte).subscribe((res) => {
      console.log(res)
      this.cardData = res['data']['data']['invce_cts'];
      this.invceSmryCts = res['data']['data']['invce_smry_cts'][0];
      this.admnCts = res['data']['data']['admn_cts'][0];
      for (var i = 0; i < this.cardData.length; i++) {
        this.sumtl_ct = this.sumtl_ct + this.cardData[i].tl_ct;
        this.sumpd_ct = this.sumpd_ct + this.cardData[i].pd_ct;
        this.sumnt_pd_ct = this.sumnt_pd_ct + this.cardData[i].nt_pd_ct;
        this.sumtl_at = this.sumtl_at + this.cardData[i].tl_at;
        this.sumtl_pd_at = this.sumtl_pd_at + this.cardData[i].tl_pd_at;
        this.sumtl_un_pd_at = this.sumtl_un_pd_at + this.cardData[i].tl_un_pd_at;
      }
    });

    console.log(this.sumtl_ct)
  }
  getNtClctdListData() {
    var dateObj = new Date();
    this.month = dateObj.getUTCMonth() + 1; //months from 1-12
    this.year = dateObj.getUTCFullYear();
  
    let rte = `external/usrchrgs/state/ulb/${this.ulbId}/${this.selectedYear}/${this.selectedmonth}/sachivalayam/${this.svm_apfs_cd}/not-collected/monthly`
    this.apiSrv.get(rte).subscribe((res) => {
      this.notClctd = res['data']['data'];
      console.log(this.notClctd)
      this.columnDefs = [
        { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
        { headerName: 'Customer', field: 'cstmr_nm', alignment: 'left', width: 160, filter: false },
        { headerName: 'Amount', field: 'invce_at', alignment: 'right', width: 70, filter: false },
        { headerName: 'Door Number', alignment: 'center', width: 100, field: 'dr_nu' },
        // { headerName: 'Gate Code', alignment: 'center', width: 60, field: 'gte_cd' },
        { headerName: 'Gate Category', field: 'gte_ctgry_nm', width: 150, alignment: 'left', hide: true, filter: false }
      ]
    });
  }

  getLstOneMonthDateWiseClctd() {
    let rte = `external/usrchrgs/state/ulb/${this.ulbId}/sachivalayam/${this.svm_apfs_cd}/date-wise-collected/last-month`
    this.apiSrv.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.clctdDateWiseData = res['data'];
        console.log(this.clctdDateWiseData)
        this.chartsData = this.clctdDateWiseData;
        let chart = am4core.create('gatesChart', am4charts.XYChart);
        chart.data = this.chartsData;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.title.text = "Dates";
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.labels.template.rotation = -90;
        categoryAxis.renderer.labels.template.fontSize = 11;
        categoryAxis.renderer.labels.template.dx = -15;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = '-- Gates -- ';
        chart.legend = new am4charts.Legend();
        
        var series = chart.series.push(new am4charts.LineSeries());
        series.name = "Residencials";
        series.stroke = am4core.color("#185aad");
        series.fill = am4core.color("#185aad");
        series.strokeWidth = 2;
        series.tensionX = 0.8;
        series.dataFields.valueY = "pd_rns_ct";
        series.dataFields.categoryX = "date";
        series.smoothing = "monotoneX";
        series.bullets.push(new am4charts.CircleBullet());
        series.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';

        var series1 = chart.series.push(new am4charts.LineSeries());
        series1.name = "Residencials (Slum)";
        series1.stroke = am4core.color("#b8a605");
        series1.fill = am4core.color("#b8a605");
        series1.strokeWidth = 2;
        series1.tensionX = 0.8;
        series1.dataFields.valueY = "pd_rs_ct";
        series1.dataFields.categoryX = "date";
        series1.smoothing = "monotoneX";
        series1.bullets.push(new am4charts.CircleBullet());
        series1.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';

        var series2 = chart.series.push(new am4charts.LineSeries());
        series2.name = "Commercials";
        series2.stroke = am4core.color("#1b4606");
        series2.fill = am4core.color("#1b4606");
        series2.strokeWidth = 2;
        series2.tensionX = 0.8;
        series2.dataFields.valueY = "pd_cm_ct";
        series2.dataFields.categoryX = "date";
        series2.smoothing = "monotoneX";
        series2.bullets.push(new am4charts.CircleBullet());
        series2.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';

        var series3 = chart.series.push(new am4charts.LineSeries());
        series3.name = "Apartments";
        series3.stroke = am4core.color("#96013c");
        series3.fill = am4core.color("#96013c");
        series3.strokeWidth = 2;
        series3.tensionX = 0.8;
        series3.dataFields.valueY = "pd_ap_ct";
        series3.dataFields.categoryX = "date";
        series3.smoothing = "monotoneX";
        series3.bullets.push(new am4charts.CircleBullet());
        series3.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
      }

    })

  }

  getGateWiseClctnData() {
    let rte = `external/usrchrgs/state/ulb/${this.ulbId}/${this.selectedYear}/${this.selectedmonth}/sachivalayam/${this.svm_apfs_cd}/gates/invoice-summary/monthly`
    this.apiSrv.get(rte).subscribe((res) => {
      console.log(res)
      this.gateWiseClctnData = res['data']['data'];
    })
  }



  initializeMapOptions(house) {

    // this.map = L.map('map').setView([house.lat, house.lng], 14); 
    this.map = L.map('map').setView([16.318896554373254, 80.4224768051218], 14);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 19,

    }).addTo(this.map);

    this.markerIcon = {
      icon: L.icon({
        iconSize: [35, 38],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: `../../../../assets/images/app-store/home.png`
      })
    };

    // var marker = L.marker([house.lat, house.lng],this.markerIcon).addTo(this.map);
    var marker = L.marker([16.318896554373254, 80.4224768051218], this.markerIcon).addTo(this.map);


  }


  getHouseInfo(hse_id) {
    const rte = `external/usrchrgs/state/ulb/${this.ulbId}/house/${hse_id}/invoice-summary`;
    this.apiSrv.get(rte).subscribe((res) => {
      this.houseInfo = res['data']['data'];
    });

  }

  open(house): void {
    this.visible = true;
    this.cstmr_nm = house.cstmr_nm;
    this.cstmrMbl = house.mble_ph;
    this.bsnsNm = house.prdct_grp_nm;
    this.gateNu = house.dr_nu;
    this.gateCtgryNm = house.gte_ctgry_nm;
    this.prvceBlns = house.prvce_blnce_at;
    this.clstrNm = house.clstr_nm;
    this.lstInvAmt = house.invce_at;
    this.clctd = house.pd_in == 1 ? "YES" : (house.pd_in == 0 ? "NO" : "");
    this.getHouseInfo(house.hse_id);
    // if(this.map){
    //   this.map.removeLayer();
    // }
    this.initializeMapOptions(house);

  }

  close(): void {
    this.visible = false;
    this.map.off();
    this.map.remove();


  }


}
