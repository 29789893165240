<!-- <div>
              <i nz-icon nzType="folder" nzTheme="twotone" class="header-icon"></i>&nbsp;<b
                class="header-label">Setup-Profile</b>
            </div> -->
<pg-header title="Setup-Profile" ticon="folder" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">

  <div nz-row class="gridTop" *ngIf="(USER_PERMISSIONS.slct_in==1)?true:false">
    <ds-loading *ngIf="loader"></ds-loading>
    <div class="ds-card grid">
      <dx-data-grid id="gridContainer" [dataSource]="grdLst" [remoteOperations]="false" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
        [showRowLines]="true" [showBorders]="true" [showBorders]="true" style="width:100%"
        (onToolbarPreparing)="(USER_PERMISSIONS.insrt_in==1)?onToolbarPreparing($event):false">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
          [showInfo]="true">
        </dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
          fileName="Designations List"></dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [allowFiltering]="f.filter">
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false"
          [width]="100" caption="Edit" cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="tblEdtBtn" (click)="onEdit2(data)">
              Edit
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons"
          [width]="100" caption="Delete" cellTemplate="delTemplate">
          <div *dxTemplate="let data of 'delTemplate'">
            <div class="tblDelBtn" (click)="onDelete2(data)">
              Delete
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
    <!-- <div *ngIf="(USER_PERMISSIONS.slct_in==1)?false:true" class="permMsg">{{mainMessage}}</div> -->
  </div>

  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="heading" (nzOnClose)="closeSideBar()">
    <form nz-form [formGroup]="setupPrflForm">
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <div class="inptLbl">Enter Profile Name</div>
          <input type="text" nz-input formControlName="prfle_nm" />
        </div>
      </div>
    </form>
    <div class="ds-card grid">
      <div class="stpoptsmainhng">
        Setup Options
      </div>
      <ul class="stpopts" nz-list [nzDataSource]="stpGrpOptnLst" nzBordered>
        <div *ngFor="let g of stpGrpOptnLst">
          <nz-list-header class="stpopthdr">{{g.stp_grp_nm}}</nz-list-header>
          <li nz-list-item *ngFor="let item of g.setupitemList" nzNoFlex>
            <!-- <ul nz-list-item-actions>
                <nz-list-item-action>
                  <label nz-checkbox [(ngModel)]="item.isActive" (ngModelChange)="ischeked($event,item)"></label>
                </nz-list-item-action>
              </ul> -->
            <div class="input-row" nz-row>
              <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
                {{ item.stp_opt_nm }}
              </div>
              <div nz-col nzXs="2" nzSm="2" nzMd="2" nzLg="2" nzXl="2">
                <label nz-checkbox [(ngModel)]="item.isActive" (ngModelChange)="ischeked($event,item)"></label>
              </div>
            </div>
            <!-- </div> -->
          </li>
        </div>
      </ul>
    </div>

    <button nz-button nzType="primary" class="sbmtBtm" (click)="sveSetupPrfl()"
      *ngIf="editClicked==false && deleteClicked==false">Submit</button>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="updtSetupPrfl()"
      *ngIf="editClicked==true && deleteClicked==false">Update</button>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="editClicked==false && deleteClicked==true" nz-popconfirm
      nzPopconfirmTitle="Are you sure deleting this profile?" [nzIcon]="iconTpl" nzPopconfirmPlacement="left"
      [nzPopconfirmShowArrow]="false" (nzOnConfirm)="delSetupPrfl()" nzOkType="danger" nzOkText="YES" nzCancelText="NO"
      (nzOnCancel)="cancel()">
      Delete
    </button>
    <button nz-button nzType="default" class="cnclBtn" (click)="cancelbtn()">Cancel</button>
    <ng-template #iconTpl>
      <i nz-icon nzType="question-circle-o" style="color: red;"></i>
    </ng-template>

  </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                  <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
              </div> -->
  </nz-result>
</div>