import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monitoring-dashboard',
  templateUrl: './monitoring-dashboard.component.html',
  styleUrls: ['./monitoring-dashboard.component.scss']
})
export class MonitoringDashboardComponent implements OnInit {
  clntDtls: any;
  tntLst: any;
  tntNm: any;
  crdOne;
  crdTwo;
  crdThree;
  actvtbleNm;
  selectedtenant: any;
  showLdr;
  constructor() {  
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));

        this.tntLst=this.clntDtls[0].tnts;
        this.selectedtenant = this.tntLst[0].tnt_id;
        this.tntNm=this.tntLst[0].tnt_nm;
    }

  ngOnInit(): void {
    this.actvtbleNm = 'crdThree';
    this.cardOne();
  }
  cardOne() {
    this.actvtbleNm = 'crdOne';
    this.crdOne = true; this.crdTwo = false; this.crdThree = false; 
  }
  cardTwo() {
    this.actvtbleNm = 'crdTwo';
    this.crdOne = false; this.crdTwo = true; this.crdThree = false;
  }
  cardThree() {
    this.actvtbleNm = 'crdThree';
    this.crdOne = false; this.crdTwo = false; this.crdThree = true;
  }
}
