import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { MessageDialogComponent } from '../../../shared/components/message-dialog/message-dialog.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as _ from 'lodash';

@Component({
  selector: 'app-setup-profile',
  templateUrl: './setup-profile.component.html',
  styleUrls: ['./setup-profile.component.scss']
})
export class SetupProfileComponent implements OnInit {
  stpPrfls: any;
  usDtls: any;
  heading: string;
  editClicked: boolean;
  selectedOptions: any[];
  stpSelectedOptions = [];
  fnlStpOptLst: any;
  toggleSlctdStpOpts = [];
  columnDefs = [];
  editdata;
  slctdOpt: any;
  addNew: boolean;
  setupPrflForm: FormGroup;
  selectedGrp: any;
  getRowHeight;
  pagination: boolean = true;
  paginationPageSize = 10;
  ind: number;
  grdLst: any;
  stpGrpOptnLst: any;
  // setupPrflForm: FormGroup;
  deleteClicked: boolean;
  deletePrfleNm: any;
  deletePrfleId: any;
  stpOptList = false;
  selectedPfleId: any;
  sidebarHeading
  loader: boolean;
  hdrDta: { icn: string; ttl: string; widths: { l_lg: string; l_md: string; l_sm: string; r_lg: string; r_md: string; r_sm: string; }[]; };
  fnlStpExtraOptsLst = [];
  stpExtraSelectedOptions: any[];
  permissions;
  shwSidebar: boolean;
  slctdOpts = []
  edtslctdOpts = []
  deltedata = []
  /**
      * @param {DsSidebarService} _dsSidebarService
      */

  getHeaderDtls = function () { return { 'title': 'Setup Profile', 'icon': 'assignment' } }
  constructor(private fb: FormBuilder, private themeService: ThemeConstantService, public apiSrv: AppsService, public atmSrv: AtomServiceService, private message: NzMessageService, private modal: NzModalService, private notification: NzNotificationService) {
    let rowHeight = 40;
    this.getRowHeight = function () {
      return rowHeight;
    };
    // this.permissions = { 'slct_in': 1, 'insrt_in': 0, 'updt_in': 1, 'dlte_in': 1, 'exprt_in': 1 };
    // const permTxt = 'Setup Profile';
    // const prmeRte = `user/permissions/${permTxt}`;
    // this.apiSrv.get(prmeRte).subscribe((res) => {
    //   this.permissions = res['data'][0];
    //   // console.log(this.permissions);
    // });
  }
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  user: any = {
    permissions: { 'slct_in': 1, 'insrt_in': 1, 'updt_in': 1, 'dlte_in': 0, 'exprt_in': 1 },
    'perm_url': 'user/permissions/Desginations Creation'
  }
  ngOnInit() {
    this.getStpProfileData();
    // this.setupGrpData();
    // this.stpGrpOptnData();
    this.setupPrflForm = new FormGroup({
      prfle_nm: new FormControl('', Validators.required)
    })
  }

  getStpProfileData() {
    this.loader = true;
    const rte = `auth2/admin/setup/profile/groups`;
    this.apiSrv.get(rte).subscribe((res) => {
      this.loader = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {

          let counter = 0;
          this.grdLst = res['data'].setupitems;
          this.grdLst.filter((k) => {
            k['s_no'] = ++counter;
          });
          this.columnDefs = [
            { headerName: 'S.No', field: 's_no', alignment: 'center', width: 100, filter: false },
            { headerName: 'Setup Profile', field: 'stp_prfle_nm', alignment: 'left', filter: true },
            // { headerName: 'Setup Profile Description', field: 'prfle_dscrn_tx', width: 200, filter:true},
            { headerName: 'Creation Time', field: 'crtd_tmstmp', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'upd_tmstmp', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crtd_usr_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'upd_usr_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true }
          ];
        }
        else {
          this.usrAcsErrMsg = true;
        }
      }


    }, (error) => {
      // console.log(error);
      this.loader = false;
    });
  }
  closeSideBar() {
    this.shwSidebar = false
  }

  stpGrpOptnData() {
    const rte = `/auth2/admin/setup/items`;
    this.apiSrv.get(rte).subscribe((res) => {
      this.stpGrpOptnLst = res['data'];
      //   console.log(this.stpGrpOptnLst)
      this.stpGrpOptnLst.filter((g) => {
        g.setupitemList.filter((o) => {
          o['isActive'] = false
        })
      })
      if (this.editClicked == true) {
        //   console.log(this.editdata)
        this.stpGrpOptnLst.filter((s) => {
          s.setupitemList.filter((f) => {
            this.editdata.setupgroupnames.filter((d) => {
              d.setupitemList.filter((i) => {
                if (i.stp_opt_id == f.stp_opt_id) {
                  f["isActive"] = true
                }
              })
            })
          })
        })
      }
    }, (error) => {
      // console.log(error);
    });


  }

  onToolbarPreparing(e) {
    // console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add Setup Profile',
        onClick: this.opensideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }

  opensideBar(opt, data) {
    //   console.log(opt)
    this.shwSidebar = true;
    this.editdata = data;
    if (opt == 'edit') {
      this.fnlStpOptLst = []
      this.setupPrflForm.get('prfle_nm').setValue(data.stp_prfle_nm);
      this.editClicked = true;
      this.deleteClicked = false;
      this.addNew = false;
      this.ind = 1;
      this.heading = 'Edit' + ' ' + data.stp_prfle_nm + ' ' + 'Profile';
      this.stpGrpOptnData()
      this.stpExtraSelectedOptions = [];
      this.stpSelectedOptions = [];
      this.toggleSlctdStpOpts = [];
    } else if (opt == 'delete') {
      this.editClicked = false;
      this.deleteClicked = true;
      this.setupPrflForm.get('prfle_nm').setValue(data.stp_prfle_nm);
      this.fnlStpOptLst = data;
      this.deletePrfleNm = data.stp_prfle_nm;
      this.deletePrfleId = data.stp_prfle_id;
      this.addNew = false;
      this.ind = 1;
      this.heading = 'Delete' + ' ' + data.stp_prfle_nm + ' ' + 'Profile';
      this.stpGrpOptnData()
      this.stpExtraSelectedOptions = [];
      this.stpSelectedOptions = [];
      this.toggleSlctdStpOpts = [];
    } else if (opt == 'new') {
      this.fnlStpOptLst = []
      this.setupPrflForm.get('prfle_nm').setValue('');
      this.ind = 0;
      this.addNew = true;
      this.editClicked = false;
      this.deleteClicked = false;
      this.stpOptList = false;
      this.heading = 'Add New Setup Profile';
      this.stpGrpOptnData()
    }
  }

  onSelection(event) {
    this.slctdOpt = event.option._selected;
  }

  onEdit2(event) {
    // console.log(event)
    this.editdata = event.data
    this.opensideBar("edit", this.editdata)
  }
  onDelete2(event) {
    this.deltedata = event.data
    this.opensideBar("delete", this.deltedata)
  }

  sveSetupPrfl() {
    let data = {
      stp_prfle_nm: this.setupPrflForm.value.prfle_nm,
      set_optns: this.slctdOpts
    }
    let rte = 'auth2/admin/setup/profiles'
    this.apiSrv.create(data, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createMessage('success', 'Profile Added Successfully');
        this.slctdOpts = []
        this.getStpProfileData();

      }
    })
  }
  updtSetupPrfl() {
    const data = {
      stp_prfle_nm: this.setupPrflForm.value.prfle_nm,
      set_optns: this.edtslctdOpts,
      stp_prfle_id: this.editdata.stp_prfle_id
    };
    const rte = 'auth2/admin/setup/profiles';
    this.apiSrv.update(data, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createMessage('info', 'Profile Updated Successfully');
        this.edtslctdOpts = []
        this.getStpProfileData();
      }
    });
  }
  delSetupPrfl() {

    const rte = 'auth2/admin/setup/profiles/' + this.editdata.stp_prfle_id;
    this.apiSrv.delete(rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createMessage('info', 'Profile Deleted Successfully');
        this.edtslctdOpts = []
        this.getStpProfileData();
      }
    });
  }

  ischeked(e, data) {
    if (this.editClicked == false) {
      if (e == true) {
        this.slctdOpts.push({
          stp_grp_id: data.stp_grp_id,
          stp_opt_id: data.stp_opt_id,
          isActive: data.isActive,
          isNew: true
        })
      }
      else {
        this.slctdOpts.push({
          stp_grp_id: data.stp_grp_id,
          stp_opt_id: data.stp_opt_id,
          isActive: data.isActive,
          isNew: false
        })
      }
    }
    else if (this.editClicked == true || this.deleteClicked == true) {
      if (e == true) {
        this.edtslctdOpts.push({
          stp_grp_id: data.stp_grp_id,
          stp_opt_id: data.stp_opt_id,
          isActive: data.isActive,
          isNew: true
        })
      }
      else {
        this.edtslctdOpts.push({
          stp_grp_id: data.stp_grp_id,
          stp_opt_id: data.stp_opt_id,
          isActive: data.isActive,
          isNew: false
        })
      }
    }
  }

  cancelbtn() {
    this.shwSidebar = false;
  }

  cancel(): void {
    this.createMessage('info', 'Profile Not Deleted');
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
}
