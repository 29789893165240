import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppsService } from 'src/app/shared/services/apps.service';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-sachivalayam-dashboard',
  templateUrl: './sachivalayam-dashboard.component.html',
  styleUrls: ['./sachivalayam-dashboard.component.scss']
})
export class SachivalayamDashboardComponent implements OnInit {
  clntDtls: any = [];
  tntLst: any = [];
  userDtls: any = [];
  clap_in;
  ShowClap: boolean;
  selectedtenant: any;
  ulb_id: any;
  selectedtenantNm: any;
  showLdr;
  vehclesTble: any = [];
  columnDefs: any = [];
  postslctdDt;
  slctdDt = new Date();
  curDate;
  rowselected;
  trpsData: any = [];
  svmVhclData: any = [];
  tmpArr: any[];
  selectedVehicle: any;
  VhclSldr: boolean;
  pbckData: any;
  visible: boolean = false;
  selectedDriver: any;
  visibleDriverSldr: boolean;
  dateFormat = 'dd/MM/yyyy';
  today = new Date();
  selectedtrip: any;
  trpSldr;
  visibleTripSldr;
  
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    console.log(this.tntLst)
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.selectedtenantNm = this.tntLst[0].tnt_dsply_nm;
    this.ulb_id = this.tntLst[0].cpy_ulb_id;
  }

  ngOnInit(): void {
    this.getVehicleswiseData();
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  ontntSelect(value) {
    // console.log(value);
    if (value == 0) {
      this.selectedtenant = 0;
      this.selectedtenantNm = "All ULB's Selected";
      this.ulb_id = 0;
    }
    for (let i = 0; i < this.tntLst.length; i++) {
      // console.log('in');
      if (this.tntLst[i].tnt_id == value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
        this.selectedtenantNm = this.tntLst[i].tnt_dsply_nm;
        this.ulb_id = this.tntLst[i].cpy_ulb_id;
      }

    }

    // console.log(this.selectedtenantNm);
    this.getVehicleswiseData();
  }
  onChange(e): void {
    this.slctdDt = e;
    // this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    // this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.getVehicleswiseData();
  }
  getVehicleswiseData() {
    this.vehclesTble = [];
    this.svmVhclData = [];
    this.trpsData = [];
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.showLdr = true;
    this.postslctdDt = {
      dt: this.curDate,
      slctd_tnt: this.selectedtenant
    }
    let rte = `dashboard/enterprise/sachivalayam/vhcleAnlysis`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.showLdr = false;
        this.svmVhclData = res['data'];
        // console.log(this.svmVhclData);
        this.getTrpsData();

      }
    }, (err) => {
      // console.log(err);
    });

  }
  getTrpsData(){
    let rte1 = `dashboard/enterprise/sachivalayam/tripAnalysis`;
    this.apiServ.post(this.postslctdDt, rte1).subscribe((res) => {
      // let resp_data = res['data'];
      if (res['status'] == 200) {
        this.showLdr = false;
        this.trpsData = res['data'];
        console.log(this.trpsData,'trpsssssssssssssssssssssssssssssssssss')

        this.getVhclsDataTble();

      }

    }, (err) => {
      // console.log(err);
    });
    // this.getVhclsDataTble();

  }
  getVhclsDataTble() {
    this.tmpArr = [];
    if (this.svmVhclData) {
      for (var i = 0; i < this.svmVhclData.length; i++) {
        // console.log('11111111111111111111111111111111111111111111111111')
        this.tmpArr[i] = {
          'sno': this.svmVhclData[i].sno, 'svm_apfss_cd': this.svmVhclData[i].svm_apfss_cd,'svm_id': this.svmVhclData[i].svm_id, 'svm_nm': this.svmVhclData[i].svm_nm,
          'asrt_id': this.svmVhclData[i].asrt_id, 'asrt_nm': this.svmVhclData[i].asrt_nm,'crw_id': this.svmVhclData[i].crw_id, 'crw_nm': this.svmVhclData[i].crw_nm,
          'ss_nm': this.svmVhclData[i].ss_nm, 'asgnd_trps': this.svmVhclData[i].asgnd_trps, 'tdy_trvl_trps': this.svmVhclData[i].tdy_trvl_trps,
          'tdy_trvl_dst': this.svmVhclData[i].tdy_trvl_dst,'lat':this.svmVhclData[i].lat,'lng':this.svmVhclData[i].lng,'data_received_ts':this.svmVhclData[i].data_received_ts,
          'asrt_sts_id':this.svmVhclData[i].asrt_sts_id,'asrt_sts_nm':this.svmVhclData[i].asrt_sts_nm
        }
        if (this.trpsData) {
          for (var j = 0; j < this.trpsData.length; j++) {
            // console.log('22222222222222222222222222222222222222')
            if (this.svmVhclData[i].svm_id == this.trpsData[j].svm_id) {
              // console.log(this.svmVhclData[i].asrt_id, this.trpsData[j].asrt_id);
              // console.log('333333333333333333333333333333333333')
              this.tmpArr[i]['t1_ttl'] = this.trpsData[j].t1_ttl;
              this.tmpArr[i]['t2_ttl'] = this.trpsData[j].t2_ttl;
              this.tmpArr[i]['t3_ttl'] = this.trpsData[j].t3_ttl;
              this.tmpArr[i]['t4_ttl'] = this.trpsData[j].t4_ttl;
              this.tmpArr[i]['t1_cvrd'] = this.trpsData[j].t1_cvrd;
              this.tmpArr[i]['t2_cvrd'] = this.trpsData[j].t2_cvrd;
              this.tmpArr[i]['t3_cvrd'] = this.trpsData[j].t3_cvrd;
              this.tmpArr[i]['t4_cvrd'] = this.trpsData[j].t4_cvrd;
              this.tmpArr[i]['t1_sts'] = this.trpsData[j].t1_sts;
              this.tmpArr[i]['t2_sts'] = this.trpsData[j].t2_sts;
              this.tmpArr[i]['t3_sts'] = this.trpsData[j].t3_sts;
              this.tmpArr[i]['t4_sts'] = this.trpsData[j].t4_sts;

            } else {
              // console.log('444444444444444444444444444444')
              this.tmpArr[i]['t1_ttl'] = 0;
              this.tmpArr[i]['t2_ttl'] = 0;
              this.tmpArr[i]['t3_ttl'] = 0;
              this.tmpArr[i]['t4_ttl'] = 0;
              this.tmpArr[i]['t1_cvrd'] = 0;
              this.tmpArr[i]['t2_cvrd'] = 0;
              this.tmpArr[i]['t3_cvrd'] = 0;
              this.tmpArr[i]['t4_cvrd'] = 0;
              this.tmpArr[i]['t1_sts'] = 'N/A';
              this.tmpArr[i]['t2_sts'] = 'N/A';
              this.tmpArr[i]['t3_sts'] = 'N/A';
              this.tmpArr[i]['t4_sts'] = 'N/A';
            }
          }
        } else {
          // console.log("ertyuiofghjkfgfghjjjjjjjjjjjjjjjjjjjjjjjj")
          this.tmpArr[i]['t1_ttl'] = 0;
          this.tmpArr[i]['t2_ttl'] = 0;
          this.tmpArr[i]['t3_ttl'] = 0;
          this.tmpArr[i]['t4_ttl'] = 0;
          this.tmpArr[i]['t1_cvrd'] = 0;
          this.tmpArr[i]['t2_cvrd'] = 0;
          this.tmpArr[i]['t3_cvrd'] = 0;
          this.tmpArr[i]['t4_cvrd'] = 0;
          this.tmpArr[i]['t1_sts'] = 'N/A';
          this.tmpArr[i]['t2_sts'] = 'N/A';
          this.tmpArr[i]['t3_sts'] = 'N/A';
          this.tmpArr[i]['t4_sts'] = 'N/A';
        }

      }
      // console.log('consoleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', this.tmpArr)
      this.vehclesTble = this.tmpArr;
      this.columnDefs = [
        { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
        // { headerName: 'ULB', field: 'tnt_nm', alignment: 'left', width: 200 },
        // { headerName: 'Sachivalayam Code', field: 'svm_apfss_cd', alignment: 'left', width: 'auto' },
        { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', width: 200, filter: true },
        { headerName: 'Vehicle Registration Number', field: 'asrt_nm', alignment: 'left', filter: true },
        { headerName: 'Driver', field: 'crw_nm', alignment: 'left', filter: true },
        // { headerName: 'Driver Contact', field: 'crw_ph', alignment: 'left', width: 'auto' },
        { headerName: 'Sanitary Secratory', field: 'ss_nm', alignment: 'left', filter: true },
        // { headerName: 'Sanitary Secratory Contact', field: 'ss_cntct', alignment: 'left', width: 'auto' },
        { headerName: 'Assigned Trips', field: 'asgnd_trps', alignment: 'center', filter: true },
        { headerName: 'Today Travelled Trips', field: 'tdy_trvl_trps', alignment: 'center', filter: true },
        { headerName: 'Today Travelled Distance in Kms', field: 'tdy_trvl_dst', alignment: 'center', filter: true },
        { headerName: 'Trip 1 total/covered locations', field: 'trp_1', alignment: 'center' },
        { headerName: 'Trip 2 total/covered locations', field: 'trp_2', alignment: 'center' },
        { headerName: 'Trip 3 total/covered locations', field: 'trp_3', alignment: 'center' },
        { headerName: 'Trip 4 total/covered locations', field: 'trp_4', alignment: 'center' },
      ]
    }
  }
  onCellPrepared(colDef, e) {
    // console.log(colDef)
    if (colDef.length && colDef.length > 0) {
      colDef.filter((o) => {
        if (o.field == "svm_nm") {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }
        if (o.field == "asrt_nm") {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }
        if (o.field == "crw_nm") {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }
        // console.log(e)
        if (o.field == "tdy_trvl_dst") {
          if (e.data && e.data.tdy_trvl_dst != undefined && e.data.tdy_trvl_dst < 2 && e.column.dataField == o.field) {
            e.cellElement.style.background = 'rgb(230 209 193)';
            e.cellElement.style.cursor = "pointer";
          }
        }
        if (o.field == "asgnd_trps") {
          if (e.data && e.data.asgnd_trps != undefined && e.data.asgnd_trps == 0 && e.column.dataField == o.field) {
            e.cellElement.style.background = 'rgb(228 230 193)';
            e.cellElement.style.cursor = "pointer";
          }
        }
        if (o.field == "tdy_trvl_trps") {
          if (e.data && e.data.tdy_trvl_trps != undefined && e.data.tdy_trvl_trps == 0 && e.column.dataField == o.field) {
            e.cellElement.style.background = 'rgb(160 214 213)';
            e.cellElement.style.cursor = "pointer";
          }
        }

      })
    }
  }
  devCellclk(devclmns, dvclkent) {
    console.log(dvclkent);
        if(dvclkent.columnIndex==1){
        if (dvclkent.data.svm_nm != undefined) {
          this.rowselected = dvclkent.data;
          this.TransfereService.setLoclData('svmDta', this.rowselected)
          this.route.navigate([`internal/dashboard/svm-dashboard`]);
        }
      }
        if(dvclkent.columnIndex==2){
          if (dvclkent.data && dvclkent.data.asrt_nm != undefined) {
            this.gotovehcleDashboard(dvclkent)
          }
        }
        if(dvclkent.columnIndex==3){
          if (dvclkent.data && dvclkent.data.asrt_nm != undefined) {
            this.gotoDrvrSldr(dvclkent)
          }
        }
        if(dvclkent.columnIndex==8){
          if (dvclkent.data && dvclkent.data.t1_sts != undefined) {
            var data={trp_rn_id:dvclkent.data.t1_trp_rn_id,
              dt:this.slctdDt,
              trp_schde_id:dvclkent.data.t1_schde
            }
            this.gototripSldr(data)
          } 
        }
        if(dvclkent.columnIndex==11){
          if (dvclkent.data && dvclkent.data.t2_sts != undefined) {
            var data={trp_rn_id:dvclkent.data.t2_trp_rn_id,
              dt:this.slctdDt,
              trp_schde_id:dvclkent.data.t2_schde
            }
            this.gototripSldr(data)
          } 
        }
        if(dvclkent.columnIndex==14){
          if (dvclkent.data && dvclkent.data.t3_sts != undefined) {
            var data={trp_rn_id:dvclkent.data.t3_trp_rn_id,
              dt:this.slctdDt,
              trp_schde_id:dvclkent.data.t3_schde
            }
            this.gototripSldr(data)
          } 
        }
        if(dvclkent.columnIndex==17){
          if (dvclkent.data && dvclkent.data.t4_sts != undefined) {
            var data={trp_rn_id:dvclkent.data.t4_trp_rn_id,
              dt:this.slctdDt,
              trp_schde_id:dvclkent.data.t4_schde
            }
            this.gototripSldr(data)
          } 
        }
  }
  gotovehcleDashboard(data) {
    this.selectedVehicle = data.data;
    this.visible = true;
  }
  gotoDrvrSldr(evnt) {
    console.log(evnt)
    this.selectedDriver = evnt;
    this.visibleDriverSldr = true;
  }
  gototripSldr(evnt) {
    console.log(evnt)
    this.visibleDriverSldr = evnt;
    this.visibleDriverSldr = true;
  }
}
