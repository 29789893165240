import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  columnDefs;
  shwSidebar = false;
  shwstateAlrt = false;
  shwstateEditDiv = false;
  sidebarHeading: string;
  shwstateDelDiv: boolean;
  shwLdg: boolean;
  stateLst: any;
  stateId: any;
  stateNm: string | null = null;

  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService,
    public apiSrv: AppsService,
    private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getStateLst();
  }

  getStateLst() {
    this.shwLdg = true;
    const rte = 'admin/states';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      this.shwLdg = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.stateLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'State Name', field: 'ste_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 265 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    });
  }

  onCellClick(opt, edtData) {
    if (opt === 'edit') {
      this.shwstateEditDiv = true;
      this.stateNm = edtData.data.ste_nm;
      this.stateId = edtData.data.ste_id;
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwstateEditDiv = false;
      this.shwstateDelDiv = true;
      this.stateId = edtData.data.ste_id;
      this.stateNm = edtData.data.ste_nm;
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New State',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwstateEditDiv = true;
      this.shwstateDelDiv = false;
      this.sidebarHeading = 'Edit State';
    } else if (opt === 'delete') {
      this.shwstateEditDiv = false;
      this.shwstateDelDiv = true;
      this.sidebarHeading = 'Delete State';
    } else {
      this.shwstateEditDiv = false;
      this.shwstateDelDiv = false;
      this.stateNm = '';
      this.sidebarHeading = 'Add New State';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  savemndl() {
    const stateData = {
      name: this.stateNm
    };

    const rte = 'admin/states';
    this.apiSrv.create(stateData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstateAlrt = true;
        this.createMessage('success', 'State Successfully Added');
        this.getStateLst();
      }
    });
  }

  saveEditstate() {
    const stateEdtData = {
      id: this.stateId,
      name: this.stateNm
    };

    const rte = 'admin/states/'+this.stateId;
    this.apiSrv.update(stateEdtData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstateAlrt = true;
        this.createMessage('info', 'State Successfully Updated');
        this.getStateLst();
      }
    });
  }

  saveDeletestate() {
    // console.log('in del');
    const stateDelData = {
      id: this.stateId
    };

    const rte = 'admin/states/'+this.stateId;
    this.apiSrv.delete(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstateAlrt = true;
        this.createMessage('warning', 'State Successfully Deleted');
        this.getStateLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('State Not Deleted');
  }


}
