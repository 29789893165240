import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportRoutingModule } from './support-routing.module';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { SideMenuComponent } from './side-menu/side-menu.component';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';

import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule, DxNumberBoxModule,
  // tslint:disable-next-line:max-line-length
  DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule, DxChartModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule, DxDiagramModule
} from 'devextreme-angular';
import { CommunicationPortalComponent } from './enterprise/communication-portal/communication-portal.component';
// import { TripsSliderModule } from '../dashboards/enterprise/trips-slider/trips-slider.modules';


@NgModule({
  declarations: [MyTicketsComponent, SideMenuComponent, CommunicationPortalComponent],
  imports: [
    CommonModule,
    SupportRoutingModule,
     // --  DXmodules -- //
     DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule,
     DxNumberBoxModule, DxChartModule, DxSelectBoxModule, DxCheckBoxModule,
     DxTabPanelModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule,
     SharedModule,
     // -- NZModules --//
     NzIconModule, NzDrawerModule, NzPopconfirmModule,NzSkeletonModule,
     NzButtonModule, NzCardModule, NzAvatarModule, NzRateModule, NzBadgeModule,
     NzProgressModule, NzRadioModule,  NzDropDownModule, NzTimelineModule, NzTabsModule,
     NzTagModule, NzListModule, NzCalendarModule, NzToolTipModule, NzFormModule, NzModalModule,
     NzSelectModule, NzUploadModule, NzInputModule, NzPaginationModule, NzDatePickerModule,
     NzCheckboxModule, NzMessageModule, NzLayoutModule, NzGridModule, NzDividerModule,ReactiveFormsModule, FormsModule,NzDescriptionsModule,NzTimePickerModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SupportModule { }
