import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-vhcle-ulb-asgnmnt',
  templateUrl: './vhcle-ulb-asgnmnt.component.html',
  styleUrls: ['./vhcle-ulb-asgnmnt.component.scss']
})
export class VhcleUlbAsgnmntComponent implements OnInit {
  clntDtls: any;
  tntLst: any = [];
  selectedtenant: any;
  vendorList: any = [];
  selectedvndr: any;
  userDtls: any;
  clap_in: any;
  ShowClap: boolean;
  showVndr: boolean;
  vhcleslst: any = [];
  showLdr: boolean;
  vhclslstcolumnDefs: any = [];
  asrtList: any;
  opensldr: boolean;
  asrt_id: any;
  asrt_nm: any;
  tnt_id: any;
  asrt_ctgry_id;
  asrt_grp_id;
  asrt_sts_id;
  vhcleGrp: any;
  vehicleSts: any;
  vehctgryLst: any;
  asrt_ctgry_nm: any;
  asrt_grp_nm: any;
  prv_tnt: any;
  constructor(private apiSrvc: AppsService,private message: NzMessageService,) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = 0;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
      // this.getvehicles();
    }else{
      this.selectedvndr = 0;
    }
   }

  ngOnInit(): void {
    this.getVendors();
    this.getvehicles();
  }
  getVendors() {
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      if (this.selectedvndr && this.showVndr) {
        this.apiSrvc.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
          if (res['status'] == 200) {
            this.vendorList = res['data'];
          }
        }, (err) => {
        })
      }
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
      this.selectedvndr = 0;
      this.getvehicles();

    } else {
      this.apiSrvc.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          this.selectedvndr = this.vendorList[0].vndr_id;
          this.getvehicles();
        }
      }, (err) => {
      });
    }
  }
  onvndrSelect(value) {
    this.selectedvndr = value;
    this.getvehicles();

  }
  getvehicles() {
    var fltrData = [{
      selectedtenant: this.selectedtenant,
      selectedvndr: this.selectedvndr,
      selectedctgry: 0
    }]
    this.showLdr = true;
    this.apiSrvc.post(fltrData, 'drivers/vehicles/asgndvehicles').subscribe((res) => {
      if (res['status'] == 200) {
        this.vhcleslst = res['data'];
        this.asrtList = this.vhcleslst;
        this.showLdr = false;
        let counter = 0;
        this.vhcleslst.filter((k) => {
          // counter1 = 0;
          k['sno'] = ++counter;
        });
        this.vhclslstcolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
          { headerName: 'ULB Name', alignment: 'left', field: 'ulb_nm' },
          // { headerName: 'District', alignment: 'left', field: 'dstrct_nm' },
          { headerName: 'Vehicle Registration Number', alignment: 'left', field: 'asrt_nm' },
          { headerName: 'IMEI Number', alignment: 'left', field: 'dev_imei_nu' },
          { headerName: 'Status', alignment: 'left', field: 'asrt_sts_nm' },

          // { headerName: 'Category', field: 'asrt_ctgry_nm', alignment: 'left' },
          // { headerName: 'Group', alignment: 'left', field: 'asrt_grp_nm' },
          // { headerName: 'Assigned User Name', alignment: 'left', field: 'usr_nm' },
          // { headerName: 'Contact Number', alignment: 'center', field: 'mobile_nu' },
          // { headerName: 'Assigned On', alignment: 'center', field: 'effct_ts' },
          // { headerName: 'End Fence', field: 'endFence', alignment: 'center' }
        ]
      }
    }, (err) => {
    });
  }
  openSlider(d) {
    // console.log(d.data)
    this.asrt_id=d.data.asrt_id;
    this.asrt_nm=d.data.asrt_nm;
    this.prv_tnt=d.data.tntId;
    this.opensldr = true;
  }
  closeSideBar() {
    this.opensldr = false;
    this.getvehicles();
  }
  ontntSelect(value) {
    this.tnt_id = value;
    this.getvhcleGrp();
this.getVhcleCtgry();
  }
  getvhcleGrp() {
    this.apiSrvc.get(`drivers/vehicles/group/${this.tnt_id}`).subscribe((res) => {
      this.vhcleGrp = res['data'];
    }, (err) => {
    });
    this.apiSrvc.get(`drivers/vehicles/status/${this.tnt_id}`).subscribe((res) => {
      this.vehicleSts = res['data'];
    }, (err) => {
    });
  }
  getVhcleCtgry = () => {
    this.apiSrvc.get(`drivers/vehicles/category/${this.tnt_id}`).subscribe((res) => {
      this.vehctgryLst = res['data'];
    }, (err) => {
    });
  }
  onctgrySelect(ctgry){
    this.asrt_ctgry_id=ctgry;
    this.vehctgryLst.forEach(e => {
      if(e.asrt_ctgry_id==this.asrt_ctgry_id){
        this.asrt_ctgry_nm=e.asrt_ctgry_nm
      }
      
    });
  }
  onvehGrpSelect(grp){
    this.asrt_grp_id=grp;
    console.log(this.asrt_grp_id,"gggggggggggggggggggggggggggggggggggg")
    this.vhcleGrp.forEach(e => {
      console.log(e)
      if(e.asrt_grp_id==this.asrt_grp_id){
        this.asrt_grp_nm=e.asrt_grp_nm
      }
      
    });
  }
  onstatusChange(sts){
    console.log("stssssssssssssssssssss",sts)
    this.asrt_sts_id=sts;
  }
  saveasgnmnt(){
    var postData = {
      asrt_id:this.asrt_id,
      tnt_id: this.tnt_id,
      vndr_id: this.selectedvndr,
      asrt_ctgry_id: this.asrt_ctgry_id,
      asrt_ctgry_nm:this.asrt_ctgry_nm,
      asrt_grp_id:this.asrt_grp_id,
      asrt_grp_nm:this.asrt_grp_nm,
      asrt_sts_id:this.asrt_sts_id,
      prev_tnt_id:this.prv_tnt
    }
console.log(postData)
    this.apiSrvc.post(postData, 'drivers/vehicles/chngVhcleULB').subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Vehicle successfully Updated');
      }
      });
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
}
