import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';
import * as L from 'leaflet';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DrawEvents, featureGroup, FeatureGroup, icon, latLng, tileLayer } from 'leaflet';
import { circle, geoJSON, MapOptions, Layer, marker, Marker, polygon } from 'leaflet';

import { LeafletLayersDemoModel } from 'src/app/apps/maps/map-base/layers.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import 'src/app/apps/maps/leaflet-ruler.js';
import { truncateWithEllipsis } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { ThemeService } from 'ng2-charts';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
// import '../lib/leaflet-search/leaflet-search.js'
import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
import { delay, timeout } from 'rxjs/operators';
import * as turf from '@turf/turf';
import { truncate } from 'fs';
import { TransferService } from 'src/app/shared/services/transfer.service';
@Component({ 
  selector: 'app-route-deviation-dashboard',
  templateUrl: './route-deviation-dashboard.component.html',
  styleUrls: ['./route-deviation-dashboard.component.scss']
})
export class RouteDeviationDashboardComponent implements OnInit {
  header_label={
    name:"Trips Dashboard"
  }
  filters:boolean = true
  @ViewChild('pdfSctn') pdfSctn: ElementRef;
  dateFormat = 'dd/MM/yyyy';
  monthFormat = 'yyyy/MM';
  selectedValue = null;
  showLdr = false; vehCtgrys;
  wrds; postslctdDt;
  selectedCtgry = 0;
  slctdWrd = 0;
  slctdDt = new Date(); curDate; curTblDate; rteDta; rdCnts;
  vehTrpLst: any;
  clntDtls: any;
  today = new Date();
  routemap: any = [];
  route_Polyline: any = [];
  tntLst: any = [];
  selectedtenant = 0;
  searchText;
  schdStartPBMarker: any = [];
  schdEndPBMarker: any = [];
  startPBMarker1: any;
  endPBMarker1: any;
  polylinePoints: any;
  actl_Polyline: any;
  ipMarker: any;
  popup;
  content;
  ipIcon = new L.Icon({
    iconUrl: '/assets/images/markers/mapIcons/m/colctn_pnt_nt_cvrd_m.png',
    iconSize: [42, 45],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  ipPts: any = [];
  mrkerPts: any;
  rtrstrng: string;
  shwAddBtn: boolean;
  rte_id: any;
  rte_nm: any;
  trp_sqnc_nu: any;
  fncDtls: any;
  cvrdIps: any = [];
  ipMarker1: any;
  strngarc: string;
  showsetRte: boolean;
  vehDupTrpLst: any = [];
  trp_schde_id: any;
  shwNodata: boolean;
  rte_plyln_id: any;
  vendorList: any[];
  selectedvndr: any;
  userDtls: any;
  showVndr: boolean = false;
  clap_in: any;
  ShowClap: boolean = false;
  wardLst: any;
  wrdfncepolygon: L.Polygon<any>;
  wrdplypoint: any = [];
  schdStartMarker: any;
  schdEndMarker: any;
  checked: boolean = false;
  trvlDst;
  openSlider;
  slctdDtls: any = {};
  latlangs: any;
  dvtnForm: FormGroup;
  rdchked: boolean = false;
  timeFormat = 'h:mm a';
  slctdstrt_tm; svmChkd: boolean = false;
  // for routemap
  startIcon = new L.Icon({
    iconUrl: '/assets/images/markers/playback/startPoint.png',
    iconSize: [33, 33],
    iconAnchor: [16, 28],
  });
  endIcon = new L.Icon({
    iconUrl: '/assets/images/markers/playback/endPoint.png',
    iconSize: [33, 33],
    iconAnchor: [16, 28],
  });
  startIcon1 = new L.Icon({
    iconUrl: '/assets/images/markers/playback/startPointbrwn.png',
    iconSize: [42, 45],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  endIcon1 = new L.Icon({
    iconUrl: '/assets/images/markers/playback/endPointbrwn.png',
    iconSize: [42, 45],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  shwAlWrds: boolean;
  allSchduleRtes: any = [];
  allTrvlRtes: any = [];
  allsched_Polyline: any;
  alltrvl_Polyline: any;
  // map polygon edit variables
  data = {
    toolbar: []
  }
  that;
  map: any;
  optionsSpec: any = {
    layers: [{ url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', attribution: 'Open Street Map' }],
    zoom: 5,
    center: [46.879966, -121.726909]
  };

  LAYER_OSM = {
    id: 'openstreetmap',
    name: 'Open Street Map',
    enabled: true,
    layer: tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: 'Open Street Map'
    })
  };

  LAYER_COUNTRIES = {
    id: 'Countries',
    name: 'Countries',
    enabled: true,
    layer: tileLayer('http://{s}.tiles.mapbox.com/v3/milkator.press_freedom/{z}/{x}/{y}.png', {
      maxZoom: 5,
      attribution: 'Map data &copy; 2013 Natural Earth'
    })
  };

  settings = {
    popover: { "sec_title": "", "sec_dscn": "" }
  }
  isVisible: boolean;
  rteSaveData: any;
  edtPolyline: any;
  updtPlyline: any;
  strngarcEdt: any;
  rtrstrngEdt: any;
  colors;
  editPlylneList: any = [];
  SlctdWrdPolyData: any = [];
  shwChckbox: boolean;
  addedNwLAyer: any;
  SearchItems: any[];
  asrt_id: any;
  strt_loc: any;
  end_loc: any;
  tripLoctns = [];
  tripEndLoctns: any = [];
  svmLst: any[];
  slctdSvm;
  SlctdSvmPolyData: any = [];
  svmfncepolygon: L.Polygon<any>;
  svmplypoint: any = [];
  svmBndsLst = [];
  stoppageTime: number;
  finalSpdMrkrs: any = [];
  shwRdCks: boolean;
  rdTrips: any = [];
  date = new Date();
  Pbstrt_tm;
  Pbend_tm;
  pbRtData: any = [];
  startPBMarker: any;
  endPBMarker: any;
  PlyBk_Polyline: any;
  hoursDiff;
  timeDiff;
  tiles;
  schduleMarkers: any = [];
  schduleMarkersEnd: any = [];
  slctdend_tm: string;
  openOprtnSlider: boolean = false;
  oprtnsData: any = {};
  vhcleslst: any;
  selectedVehicle: any;
  chngdVhcle: any;
  tripSvm: any;
  trpsvmBndsLst: any = [];
  svmplypoint2: any = [];
  svmfncepolygon2: L.Polygon<any>;
  strtMrkr: any;
  endMrkr: any;
  stpgDtls = false;
  stpdta: any;
  trvl = false;
  newArray: any = [];
  points: any = [];
  travelDist: string;
  stpgPnts: any = [];
  columnDefs: any[];
  prkngOpn: boolean;
  vehicle_nu: any;
  slctdTrpCrd: any;

  OnHoverOption(optn) {
    // console.log("In OnHoverOption")
    // console.log(optn)
    this.settings.popover.sec_dscn = optn.dscn_tx
    this.settings.popover.sec_title = optn.optn_nm
  }

  map_stng: any = {
    "fnce_grp_hndlr_tx": "fences"
    , "show_sidebar": false
    , "show_detail_sidebar": false
    , "show_drill_sidebar": false
    , "show_grid": false
    , "show_build_sidebar": false
    , "undo_flag": false
    , "show_coordinates": true

  };
  map_data: any = {
    "sidebar_hdng": ""
    , "sidebar_detail_hdng": ""
    , "sidebar_drill_hdng": ""
    , "selected_toolbar_hndr_tx": ""
    , "fences_groups": []
    , "fence_list": []
    , "map_data.mrkers_list_list": []
  }
  edit_fence: any = {
    "fnce_type": "",
    "crtd_fnce_type": "",
    "fnce_nm": "",
    "dscn_tx": "",
    "imge_url_tx": "",
    Opacity: "30",
    "FillOpacity": "30",
    "stroke": 2,
    "weight": 2,
    "dashArray": '2,2',
    "color": '#8c90f2',
    "fill": true,
    "fillColor": '#8c90f2',
    "stroke_type": "DIRECTED_ROUTE",
    "marker_id": 1,
    "marker_nm": "RED_MARKER",
    "marker_icn_url": "007-placeholder.svg",
    "lat": null,
    "lng": null,
    "latlng_array": [],
    "radius": null
  }
  fnce_drawer = {
    "circleDrawer": null,
    "polylineDrawer": null,
    "polygonDrawer": null,
    "markerDrawer": null
  };
  master_data: any = {
    marker_icons: []
  }
  layers: Layer[];
  model = new LeafletLayersDemoModel(
    [this.LAYER_OSM],
    this.LAYER_OSM.id,

  );
  leafletOptions = {
    center: latLng(17.004393, 81.783325),
    zoom: 15,
    maxZoom: 19,

  }
  poly = [];


  centerMap(e) {
    // console.log("e.latlng")
    // console.log(e.latlng)
    // console.log("this.options.center")
    // console.log(this["options"].center)
    this["options"].center = e.latlng;
    // console.log(this)
    //this.map.panTo(e.latlng);
  }

  // zoomIn(e) {
  //   this.map.zoomIn();
  // }

  // zoomOut(e) {
  //   this.map.zoomOut();
  // }
  editablLayers = new FeatureGroup();
  tileLayers = [{
    lyr_id: 1,
    lyr_nm: 'Street',
    lyr_url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    lyrSlct: true
  }, {
    lyr_id: 2,
    lyr_nm: 'Satellite',
    lyr_url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
    lyrSlct: false
  }]
  constructor(public apiServ: AppsService, private message: NzMessageService, private modal: NzModalService, public datepipe: DatePipe,public TransfereService: TransferService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    // console.log(this.userDtls);
   
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
      this.selectedvndr = 0;
    }
    if (this.selectedtenant != 7) {
      this.shwAlWrds = true;
    } else {
      this.shwAlWrds = false;
    }
    this.colors = [{ id: 1, clr: '#9b329b' }, { id: 2, clr: '#104576' }, { id: 3, clr: 'brown' }, { id: 4, clr: '#d78d07' }, { id: 5, clr: '#515e56' }, { id: 6, clr: 'indigo' }, { id: 7, clr: '#8f058f' }, { id: 8, clr: 'maroon' }, { id: 9, clr: 'navy' }, { id: 10, clr: 'olive' },
    { id: 11, clr: 'olive' }, { id: 12, clr: '#5a045a' }, { id: 13, clr: '#30D5C8' }, { id: 14, clr: '#efefef' }, { id: 15, clr: 'teal' }, { id: 16, clr: '#303f9f' }, { id: 17, clr: '#df4f68' }]
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  ngOnInit() {
    this.initialize();
    this.slctdDt = new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.get_vehCategories();
    this.get_wards();
    if (this.selectedtenant != 7) {
      this.selectWard(0, this.curDate);
    }
    this.getVendors();
    this.getTripLocations();
    this.getSachivalayams();
    if (this.userDtls.rle_id != 37) {
      this.selectCategory(0, this.curDate);
      this.get_rtDvtdTrips(this.curDate);
    }
    this.dvtnForm = new FormGroup({
      drvr_cntctd_in: new FormControl(''),
      rte_dvtn_acpt_in: new FormControl(''),
      dvtn_rsn_tx: new FormControl(''),

    });
    this.getVehicles()
    this.slctdTrpCrd = this.TransfereService.getLoclData('grdta');
    console.log(this.slctdTrpCrd);
    this.TransfereService.ClearLocalData('grdta');
  }
  ontntSelect(value) {
    // console.log(value);
    if (value === 0) {
      this.selectedtenant = 0;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tntLst.length; i++) {
      if (this.tntLst[i].tnt_id === value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
      }
    }
    // this.get_rtDvtdTrips();
    this.getSachivalayams();
    this.get_rtDvtdTrips(this.curDate);
    this.selectCategory(0, this.curDate);
    this.getVehicles();
  
  }

  initialize = function () {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    // console.log(this.usrDtls);
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.routemap = L.map('routeDevtmap', {
        center: [this.usrDtls[0].lat, this.usrDtls[0].lng],
        zoom: 3,
      });
      this.routemap.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 13);

      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   // maxZoom: 15,
      //   // minZoom: 3,
      //   attribution: ''
      // });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: ''
      });
      tiles.addTo(this.routemap);

    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
    // this.setVehicleMarkers();
  }
  chklyrSlct(evt) {
    this.tileLayers.forEach(t => {
      if (t.lyr_id == evt.lyr_id) {
        t.lyrSlct = true;
        if (this.tiles) {
          this.map.removeLayer(this.tiles);
        }
        this.tiles = L.tileLayer(t.lyr_url, {
          maxZoom: 24,
          minZoom: 3,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: ''
        });
        this.tiles.addTo(this.map);
      } else {
        t.lyrSlct = false;
      }
    });
  }
  get_vehCategories(): any {
    // this.showLdr = true;
    this.apiServ.get(`dashboard/enterprise/get_vehCtgrys`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.vehCtgrys = res['data'];
          // console.log(this.vehCtgrys)
        }
        // this.showLdr = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  get_wards(): any {
    // this.showLdr = true;
    this.apiServ.get(`drivers/vehicles/wards/${this.selectedtenant}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.wrds = res['data'];
          // console.log(this.wrds)
        }
        // this.showLdr = false;
      }
    }, (err) => {
      console.log(err);
    });

  }
  getSachivalayams() {
    // this.showLdr = true;
    this.svmLst = [];
    this.apiServ.get(`tripschedule/tenant/${this.selectedtenant}/svmLst`).subscribe((res) => {
      if (res['status'] == 200) {
        // this.showLdr = false;
        this.svmLst = res['data'];
        if (this.userDtls.rle_id == 37) {
          this.slctdSvm = this.svmLst[0].svm_id;
          this.selectSachvalayam(this.slctdSvm, this.curDate);
          this.get_rtDvtdTrips(this.curDate);
          // this.selectCategory(0, this.curDate);
        } else {
          this.slctdSvm = 0;
        }
      }
    }, (err) => {
      console.log("In errorr");
    })
  }
  get_rtDvtdTrips(funDt?): any {
    // this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    // console.log(this.selectedtenant);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      rte_dev_in: 0,
      selectedtenant: this.selectedtenant,
      vndr_id: this.selectedvndr,
      wrd_id: this.slctdWrd,
      svm_id: this.slctdSvm,
    };
    // console.log(this.postslctdDt);
    let rte = `dashboard/enterprise/get_rdTripsCnt`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        // this.showLdr = false;
        if (res_data && res_data.length > 0) {
          this.rdCnts = res['data'];
          // console.log(this.rdCnts)
        }
      }
    }, (err) => {
      console.log(err);
    });
  }



  onChange(e): void {
    // console.log('onChange: ', e);
    this.slctdDt = e;
    // console.log('slctdDt: ', this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    // console.log(this.curDate, this.selectedCtgry);
    this.selectCategory(0, this.curDate);
    this.selectWard(0, this.curDate);
    this.get_rtDvtdTrips(this.curDate);
  }

  selectCategory(evnt, dt?) {
    this.showLdr = true;
    this.vehTrpLst = [];
    this.vehDupTrpLst = [];
    // console.log(evnt, this.curDate);
    this.selectedCtgry = evnt;
    this.postslctdDt = {
      dt: this.curDate,
      id: evnt,
      rte_dev_in: 0,
      tnt_id: this.selectedtenant,
      vndr_id: this.selectedvndr,
      wrd_id: this.slctdWrd,
      strt_fnce_id: this.strt_loc,
      end_fnce_id: this.end_loc,
      svm_id: this.slctdSvm,

    };
    // console.log(this.postslctdDt);
    const rte = `dashboard/enterprise/get_vehTrpsLst`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.shwNodata = false;
          this.vehTrpLst = res['data'];
          console.log(this.vehTrpLst)

          this.vehDupTrpLst = this.vehTrpLst;
          if (this.slctdTrpCrd && this.slctdTrpCrd.trp_rn_id != undefined) {
            this.vehDupTrpLst.forEach(d => {
              if (d.trp_rn_id == this.slctdTrpCrd.trp_rn_id) {
                this.vehTrpLst = [d];
              }

            });
          }

          // console.log(this.vehTrpLst);
          // this.getTripMap(this.vehTrpLst[0],this.slctdDt);
          for (let v = 0; v < this.vehTrpLst.length; v++) {
            this.vehTrpLst[v].grp_nm = this.vehTrpLst[v].asrt_nm;
          }
          this.showLdr = false;
        } else {
          this.showLdr = false;
          this.shwNodata = true;
        }

      }
    }, (err) => {
      console.log(err);
      this.showLdr = false;
    });
  }

  selectWard(evnt, dt?) {
    this.clearRouteMapLayers();
    // this.showLdr = true;
    this.vehTrpLst = [];
    this.vehDupTrpLst = [];
    if (evnt > 0) {
      this.shwChckbox = true;
    } else {
      this.shwChckbox = false;
    }
    // console.log(evnt, this.curDate);
    this.slctdWrd = evnt;
    this.postslctdDt = {
      dt: this.curDate,
      id: this.selectedCtgry,
      rte_dev_in: 0,
      tnt_id: this.selectedtenant,
      vndr_id: this.selectedvndr,
      wrd_id: evnt,
      strt_fnce_id: this.strt_loc,
      end_fnce_id: this.end_loc,
      svm_id: this.slctdSvm,
    };
    // console.log(this.postslctdDt)
    const rte = `dashboard/enterprise/get_vehTrpsLst`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        // console.log(res_data)
        if (res_data && res_data.length > 0) {
          this.showLdr = false;
          this.shwNodata = false;
          this.vehTrpLst = res['data'];
          this.vehDupTrpLst = this.vehTrpLst;
          this.vehTrpLst.forEach((element, index) => {
            element['clr_obj'] = element['clr_obj'] = this.colors[(index % this.colors.length)].clr;
          });
          for (let v = 0; v < this.vehTrpLst.length; v++) {
            this.vehTrpLst[v].grp_nm = this.vehTrpLst[v].wrd_nm;

          }
          if (this.checked == true) {
            this.getWrdbyAsrt(this.vehTrpLst[0].asrt_id)

          }

        } else {
          // this.showLdr = false;
          this.shwNodata = true;
        }

      }
    }, (err) => {
      console.log(err);
    });
  }
  selectSachvalayam(evnt, dt?) {
    this.clearRouteMapLayers();
    this.showLdr = true;
    this.vehTrpLst = [];
    this.vehDupTrpLst = [];
    if (evnt > 0) {
      this.shwChckbox = true;
    } else {
      this.checked = false;
      this.shwChckbox = false;
      this.SlctdWrdPolyData = [];
    }
    this.slctdSvm = evnt;
    this.postslctdDt = {
      dt: this.curDate,
      id: this.selectedCtgry,
      rte_dev_in: 0,
      tnt_id: this.selectedtenant,
      vndr_id: this.selectedvndr,
      wrd_id: this.slctdWrd,
      strt_fnce_id: this.strt_loc,
      end_fnce_id: this.end_loc,
      svm_id: evnt,
    };
    // console.log(this.postslctdDt);
    this.getSvmBounds(evnt);

    const rte = `dashboard/enterprise/get_vehTrpsLst`;
    this.apiServ.post(this.postslctdDt, rte).pipe(delay(2000)).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        // console.log(res_data)
        if (res_data && res_data.length > 0) {
          this.showLdr = false;
          this.shwNodata = false;
          this.vehTrpLst = res['data'];
          this.vehDupTrpLst = this.vehTrpLst;
          this.vehTrpLst.forEach((element, index) => {
            element['clr_obj'] = element['clr_obj'] = this.colors[(index % this.colors.length)].clr;
          });
          for (let v = 0; v < this.vehTrpLst.length; v++) {
            this.vehTrpLst[v].grp_nm = this.vehTrpLst[v].wrd_nm;

          }

          // if (this.checked == true) {
          //   this.getSvmBounds(this.slctdSvm);

          // }

        } else {
          this.showLdr = false;
          this.shwNodata = true;
        }

      }
    }, (err) => {
      console.log(err);
    });
  }
  getSvmBounds(svm_id) {
    this.svmBndsLst = [];
    if (this.svmplypoint) {
      for (var k = 0; k < this.svmplypoint.length; k++) {
        this.routemap.removeLayer(this.svmplypoint[k].ply);
      }
    }
    // console.log(this.slctdSvm)
    // this.apiServ.get(`playback/svm/bounds/${this.slctdSvm}`).subscribe((res) => {
    //   if (res['status'] === 200) {
    this.svmLst.forEach(s => {
      if (s.svm_id == svm_id) {
        this.svmBndsLst.push(s);
      }
    });

    // console.log(this.svmBndsLst)
    for (var l = 0; l < this.svmBndsLst.length; l++)
      if (this.svmBndsLst[l].fnce_arc_lne_tx) {
        this.svmfncepolygon = L.polygon(JSON.parse(this.svmBndsLst[l].fnce_arc_lne_tx) as []
          , { color: this.svmBndsLst[l].fncs_clr_cd }).addTo(this.routemap).bindTooltip(this.svmBndsLst[l].svm_nm, { permanent: true, opacity: 0.7 }).openTooltip();
        this.svmplypoint.push({ ply: this.svmfncepolygon, svm_id: this.svmBndsLst[l].svm_id });
        this.routemap.fitBounds(this.svmfncepolygon.getBounds());
      }
    //   }
    // }, (err) => {
    //   console.log('In errorr');
    // });
  }
  getTripMap(c, dt) {
    // console.log(c)
    this.rte_nm = '';
    this.showLdr = true;
    // console.log(c, dt);
    // console.log(this.schdStartPBMarker)
    this.rte_nm = c.rte_nm;
    this.trp_sqnc_nu = c.trp_sqnc_nu;
    this.trp_schde_id = c.trp_schde_id;
    this.rte_plyln_id = c.rte_plyln_id;
    this.asrt_id = c.asrt_id;
    if (this.clntDtls[0].clnt_id != 7) {
      this.getWrdbyAsrt(c.asrt_id)
      // }else{
      //   this.getSvmBounds(c.svm_id);
    }

    this.vehTrpLst.forEach(ele => {
      ele.shwAddBtn = false;
      this.showsetRte = false;
      if (ele.trp_schde_id != c.trp_schde_id) {
        ele.isSelected = false;
      } else {
        ele.isSelected = true;
      }
    });
    this.vehTrpLst.forEach(ele => {

      if (ele.rte_id == c.rte_id && ele.trp_rn_id == c.trp_rn_id) {
        // console.log(ele.trp_rn_id, c.trp_rn_id, ele.rte_id, c.rte_id)
        ele.shwEdtBtn = true;
        // console.log(ele.shwEdtBtn)

      } else {
        ele.shwEdtBtn = false;
      }
    });

    if (c.rte_arc_lne_tx && c.rte_arc_lne_tx != null && c.rte_arc_lne_tx != undefined) {
      this.polylinePoints = JSON.parse(c.rte_arc_lne_tx);
    } else {
      // this.showLdr = false;
    }
    this.getCvrdFncs(c);

    if (this.checked == false) {

      this.SlctdWrdPolyData = [];
      this.SlctdSvmPolyData = [];
      if (this.schdStartMarker) {
        this.routemap.removeLayer(this.schdStartMarker);
      }

      if (this.schdEndMarker) {
        this.routemap.removeLayer(this.schdEndMarker);
      }

      // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
      var startIcon = new L.Icon({
        iconUrl: '/assets/images/markers/mapIcons/m/location.png',
        iconSize: [33, 33],
        iconAnchor: [16, 28],
      });

      this.schdStartMarker = L.marker([c.strt_fnce_lat, c.strt_fnce_lng], {
        icon: startIcon
      }).addTo(this.routemap).bindTooltip(c.strt_fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();

      // ----------Fence END Area (flag position) -- Leaflet OS ----------//
      var endIcon = new L.Icon({
        iconUrl: '/assets/images/markers/mapIcons/m/location.png',
        iconSize: [33, 33],
        iconAnchor: [16, 28],
      });

      if (c.end_fnce_lat && c.end_fnce_lng) {
        this.schdEndMarker = L.marker([c.end_fnce_lat, c.end_fnce_lng], {
          icon: endIcon
        }).addTo(this.routemap).bindTooltip(c.end_fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();

      }


      if (this.route_Polyline != undefined) {
        this.routemap.removeLayer(this.route_Polyline);
      }
      // });

      if (this.schduleMarkers) {
        for (let s = 0; s < this.schduleMarkers.length; s++) {
          this.routemap.removeLayer(this.schduleMarkers[s].strtMrkr);

        }
      }
      if (this.schduleMarkersEnd) {
        for (let s = 0; s < this.schduleMarkersEnd.length; s++) {
          this.routemap.removeLayer(this.schduleMarkersEnd[s].endMrkr);
        }
      }

      if (this.schdStartPBMarker) {
        this.routemap.removeLayer(this.schdStartPBMarker);
      }

      if (this.schdEndPBMarker) {
        this.routemap.removeLayer(this.schdEndPBMarker);
      }
      // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
      var startIcon = new L.Icon({
        iconUrl: '/assets/images/markers/playback/startPoint.png',
        iconSize: [42, 45],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      if (this.polylinePoints && this.polylinePoints[0]) {
        // console.log(this.polylinePoints[0])
        this.schdStartPBMarker = L.marker(this.polylinePoints[0], {
          icon: startIcon
        }).addTo(this.routemap);
      }
      this.schduleMarkers.push({ strtMrkr: this.schdStartPBMarker });

      // ----------Fence END Area (flag position) -- Leaflet OS ----------//
      var endIcon = new L.Icon({
        iconUrl: '/assets/images/markers/playback/endPoint.png',
        iconSize: [42, 45],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      if (this.polylinePoints) {
        let k = this.polylinePoints.length - 1
        if (this.polylinePoints[k]) {
          this.schdEndPBMarker = L.marker(this.polylinePoints[k], {
            icon: endIcon
          }).addTo(this.routemap);
        }
      }
      this.schduleMarkersEnd.push({ endMrkr: this.schdEndPBMarker });

      if (c.rte_arc_lne_tx && c.rte_arc_lne_tx != null) {
        this.route_Polyline = L.polyline(JSON.parse(c.rte_arc_lne_tx), {
          color: '#008000',
          weight: 5,
          lineCap: 'butt',
          opacity: 0.6,
          smoothFactor: 10.0,
          lineJoin: 'round'
        }).addTo(this.routemap);
        this.routemap.fitBounds(this.route_Polyline.getBounds());
      }


      if (c.start_tm != '0000-00-00 00:00:00' && c.end_tm != '0000-00-00 00:00:00') {
        this.curDate = moment(dt).format('YYYY-MM-DD');
        this.rte_id = c.rte_id;
        let endTimep;
        if (c.actl_end_ts != null) {
          endTimep = c.actl_end_ts
        } else {
          endTimep = this.curDate + " " + moment().format('HH:mm');
        }
        this.postslctdDt = {
          dt: this.curDate,
          data: {
            dev_imei_nu: c.dev_imei_nu,
            start_tm: this.datepipe.transform(c.start_tm, 'yyyy-MM-dd HH:mm'),
            end_tm: this.datepipe.transform(endTimep, 'yyyy-MM-dd HH:mm')
          }
        }
        for (let k = 0; k < this.finalSpdMrkrs.length; k++) {
          this.routemap.removeLayer(this.finalSpdMrkrs[k]);
        }
        // Getting travelled route line arc
        const rte = `dashboard/enterprise/get_tripTrvlRoute`;
        this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
          this.showLdr = true;
          this.trvlDst = 0;
          if (res['status'] == 200) {
            var res_data = res['data'];
            if (res_data && res_data.length > 0) {
              this.showLdr = false;
              this.rteDta = res['data'];
              this.trvl = true
              // console.log(this.rteDta)
              // for stoppage time
              var pstrt;
              var pend;
              // console.log(this.rteDta)
              for (var m = 0; m < this.rteDta.length; m++) {
                var startTime;
                var endTime;
                this.trvlDst = Math.round(this.rteDta[this.rteDta.length - 1].odmtr_ct - this.rteDta[0].odmtr_ct);
                // console.log(this.trvlDst)
                if (this.rteDta[m].spd_ct == 0) {
                  // console.log("In speed");
                  if (this.rteDta[m].Signal == undefined) {
                    pstrt = this.rteDta[m + 1].Signal;

                  } else {
                    pstrt = this.rteDta[m].Signal;
                  }
                  if (this.rteDta[m] && (m + 1 < this.rteDta.length)) {
                    pend = this.rteDta[m + 1].Signal;
                  } else {
                    pend = this.rteDta[m].Signal;
                  }
                  this.stoppageTime = moment(pend, "DD-MM-YYYY HH:mm").diff(moment(pstrt, "DD-MM-YYYY HH:mm"));
                  this.rteDta[m]["stoppageTime"] = this.stoppageTime / 60000;
                  if (this.rteDta[m] && (m + 1 < this.rteDta.length)) {
                    this.rteDta[m]["lastTime"] = this.rteDta[m + 1].Signal;
                  } else {
                    this.rteDta[m]["lastTime"] = this.rteDta[m].Signal;

                  }
                }
                if (this.rteDta[m].stoppageTime !== undefined && this.rteDta[m].stoppageTime !== 0) {
                  // console.log(this.rteDta[m].stoppageTime);
                  var asrt_ParkIcon = new L.Icon({
                    iconUrl: '/assets/images/markers/playback/parking.png',
                    iconSize: [42, 45],
                    iconAnchor: [12, 31],
                    popupAnchor: [0, -14],
                    shadowSize: [41, 41],
                    // asrt_nm:c.asrt_nm
                  });
                  if (this.rteDta[m].stoppageTime >= 0) {
                    this.finalSpdMrkrs.push(L.marker([this.rteDta[m].lat, this.rteDta[m].lng], {
                      icon: asrt_ParkIcon
                    })
                      .bindPopup("<b>Vehicle No : " + c.asrt_nm + "<br>From : " + this.rteDta[m].Signal + "<br>To :  " + this.rteDta[m].lastTime + "<br>STOPPAGE : " + this.rteDta[m].stoppageTime + ' Mins' + "</b>")
                      .addTo(this.routemap));
                  }

                }
              }
              // for stoppage time
              // console.log(this.rteDta)
              var restr = '';
              this.rtrstrng = '';
              var arcrestr = '';

              var tmp;
              for (var i = 0; i < this.rteDta.length; i++) {
                restr += (restr == "") ? "[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]" : ",[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]";
                this.rtrstrng = restr;
                arcrestr += (arcrestr == "") ? this.rteDta[i].lng + " " + this.rteDta[i].lat : "," + this.rteDta[i].lng + " " + this.rteDta[i].lat;

              }

              var lstrng = arcrestr.split("[").join("");
              var lstrng2 = lstrng.split("]").join("");
              this.strngarc = lstrng2;
              this.vehTrpLst.forEach(ele => {
                if (ele.rte_id == c.rte_id && this.rtrstrng.length > 0 && ele.trp_rn_id == c.trp_rn_id) {
                  ele.shwAddBtn = true;

                  // ele.edtPlybckStrng = '[' + this.rtrstrng + ']';
                  this.showsetRte = true;
                } else {
                  ele.shwAddBtn = false;
                  // ele.shwTrvl = true
                  this.showsetRte = false;
                }
              });
              // console.log(this.vehTrpLst)
              if (this.actl_Polyline) {
                this.routemap.removeLayer(this.actl_Polyline);
              }
              if (this.startPBMarker1) {
                this.routemap.removeLayer(this.startPBMarker1);
              }
              if (this.endPBMarker1) {
                this.routemap.removeLayer(this.endPBMarker1);
              }
              // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
              var startIcon1 = new L.Icon({
                iconUrl: '/assets/images/markers/playback/startPointbrwn.png',
                iconSize: [42, 45],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
              });
              if (this.rteDta[0].lat && this.rteDta[0].lng) {
                this.startPBMarker1 = L.marker([this.rteDta[0].lat, this.rteDta[0].lng], {
                  icon: startIcon1
                }).addTo(this.routemap);

                this.startPBMarker1.bindPopup("<b>Vehicle No : " + c.asrt_nm + "<br>Starting Time : " + this.rteDta[0].Signal + "</b>")
                  .addTo(this.routemap);
              }

              // ----------Fence END Area (flag position) -- Leaflet OS ----------//
              if (this.rteDta[this.rteDta.length - 1].lat && this.rteDta[this.rteDta.length - 1].lng) {
                var endIcon1 = new L.Icon({
                  iconUrl: '/assets/images/markers/playback/endPointbrwn.png',
                  iconSize: [42, 45],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41]
                });
                var k1 = this.rteDta.length - 1
                this.endPBMarker1 = L.marker(this.rteDta[k1], {
                  icon: endIcon1
                }).addTo(this.routemap);

                this.endPBMarker1.bindPopup("<b>Vehicle No : " + c.asrt_nm + "<br>Ending Time : " + this.rteDta[k1].Signal + "</b>")
                  .addTo(this.routemap);
              }
              this.actl_Polyline = L.polyline(JSON.parse("[" + restr + "]"), {
                color: 'red',
                weight: 4,
                lineCap: 'butt',
                opacity: 0.5,
                smoothFactor: 10.0,
                lineJoin: 'round',
                dist: this.trvlDst
              }).addTo(this.routemap);
              this.routemap.fitBounds(this.actl_Polyline.getBounds());
              this.actl_Polyline.bindTooltip('Distance:' + this.trvlDst + 'Km', { permanent: false, opacity: 0.7 }).openTooltip();
            } else if (res_data.length == 0) {
              this.showLdr = false;
              if (this.actl_Polyline) {
                this.routemap.removeLayer(this.actl_Polyline);
              }
              if (this.startPBMarker1) {
                this.routemap.removeLayer(this.startPBMarker1);
              }
              if (this.endPBMarker1) {
                this.routemap.removeLayer(this.endPBMarker1);
              }
              this.createMessage('warning', 'Travelled Route not found!!!!');

            }
            else {
              this.showLdr = false;
              if (this.actl_Polyline) {
                this.routemap.removeLayer(this.actl_Polyline);
              }
              if (this.startPBMarker1) {
                this.routemap.removeLayer(this.startPBMarker1);
              }
              if (this.endPBMarker1) {
                this.routemap.removeLayer(this.endPBMarker1);
              }
            }
          }
        }, (err) => {
          // console.log(err);
        });
      } else {
        this.createMessage('warning', 'Travelled Route not found!!!!');

      }
    } else {
      if (c.rte_arc_lne_tx && c.rte_arc_lne_tx != null && c.rte_arc_lne_tx != undefined) {
        this.allsched_Polyline = L.polyline(JSON.parse(c.rte_arc_lne_tx), {
          color: '#008000',
          weight: 5,
          lineCap: 'butt',
          opacity: 0.6,
          smoothFactor: 10.0,
          lineJoin: 'round'
        }).addTo(this.routemap);
        this.allSchduleRtes.push(this.allsched_Polyline);
      } else {
        this.clearRouteMapLayers();
      }
      this.vehTrpLst.forEach(ele => {
        if (ele.rte_id == c.rte_id && ele.trp_rn_id == c.trp_rn_id) {
          ele.shwEdtBtn = true;

        } else {
          ele.shwEdtBtn = false;
        }
      });
      if (c.start_tm != '0000-00-00 00:00:00' && c.end_tm != '0000-00-00 00:00:00') {
        this.curDate = moment(dt).format('YYYY-MM-DD');
        this.rte_id = c.rte_id;
        this.postslctdDt = {
          dt: this.curDate,
          data: c
        }
        // Getting travelled route line arc
        console.log('Getting travelled route line arc')
        const rte = `dashboard/enterprise/get_tripTrvlRoute`;
        this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
          this.showLdr = true;

          if (res['status'] == 200) {
            var res_data = res['data'];
            if (res_data && res_data.length > 0) {
              this.showLdr = false;
              this.rteDta = res['data'];
              // console.log(this.rteDta)
              var restr = '';
              this.rtrstrng = '';
              var arcrestr = '';

              var tmp;
              for (var i = 0; i < this.rteDta.length; i++) {
                restr += (restr == "") ? "[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]" : ",[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]";
                this.rtrstrng = restr;
                arcrestr += (arcrestr == "") ? this.rteDta[i].lng + " " + this.rteDta[i].lat : "," + this.rteDta[i].lng + " " + this.rteDta[i].lat;

              }

              var lstrng = arcrestr.split("[").join("");
              var lstrng2 = lstrng.split("]").join("");
              this.strngarc = lstrng2;
              // console.log("LINESTRING(" + lstrng2 + ")");

              this.vehTrpLst.forEach(ele => {
                if (ele.rte_id == c.rte_id && this.rtrstrng.length > 0 && ele.trp_rn_id == c.trp_rn_id) {
                  ele.shwAddBtn = true;
                  // ele.edtPlybckStrng = '[' + this.rtrstrng + ']';
                  this.showsetRte = true;
                } else {
                  ele.shwAddBtn = false;
                  this.showsetRte = false;
                }

              });


              this.vehTrpLst.forEach(e => {
                if (e.rte_id == c.rte_id)
                  if (this.slctdSvm != 0) {
                    this.SlctdWrdPolyData.push(e);
                    // this.SlctdSvmPolyData.push(e);
                  } else {
                    this.checked = false;
                    this.SlctdWrdPolyData = [];
                    if (this.svmplypoint) {
                      for (var k = 0; k < this.svmplypoint.length; k++) {
                        this.routemap.removeLayer(this.svmplypoint[k].ply);
                      }
                    }
                    this.clearRouteMapLayers();
                  }
              });
              this.alltrvl_Polyline = L.polyline(JSON.parse("[" + restr + "]"), {
                color: c.clr_obj,
                weight: 4,
                lineCap: 'butt',
                opacity: 0.5,
                smoothFactor: 10.0,
                lineJoin: 'round'
              }).addTo(this.routemap);

              this.allTrvlRtes.push(this.alltrvl_Polyline);
              // this.routemap.fitBounds(this.actl_Polyline.getBounds());
              // if(this.checked==true){
              this.allTrvlRtes.push(this.alltrvl_Polyline);
              // }

            } else if (res_data.length == 0) {
              this.showLdr = false;


              this.createMessage('warning', 'Travelled Route not found!!!!');

            }
            else {
              this.showLdr = false;
              if (this.actl_Polyline) {
                this.routemap.removeLayer(this.actl_Polyline);
              }

              // this.createMessage('warning', 'No data found!!!!');
            }
          }
        }, (err) => {
          this.showLdr = false;
          console.log(err);
        });
      } else {
        this.showLdr = false;
        this.createMessage('warning', 'Travelled Route not found!!!!');

      }
    }

  }

  getTripLocations() {
    var resData = [];
    let rte = `dashboard/enterprise/getTripLctns`;
    this.apiServ.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        resData = res['data']
        // console.log(resData)
        for (var i = 0; i <= resData.length; i++) {
          if (resData[i] != undefined) {

            if (resData[i].fnc_ky == 1) {
              this.tripLoctns.push({ strt_fnce_id: resData[i].fnce_id, strt_fnce_nm: resData[i].fnce_nm });
            } else {
              this.tripEndLoctns.push({ end_fnce_id: resData[i].fnce_id, end_fnce_nm: resData[i].fnce_nm });
            }
          }
        }

      }
    })
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  setMasterRoute() {
    this.modal.confirm({
      nzTitle: '<i>Do you Want to Save this Route?</i>',
      nzOnOk: () => this.addRoute()
    });
  }
  addRoute() {
    var postData = {
      dt: this.curDate,
      rte_id: this.rte_id,
      rte_nm: this.rte_nm,
      rte: '[' + this.rtrstrng + ']',
      tnt_id: this.selectedtenant,
      trp_sqnc_nu: this.trp_sqnc_nu,
      rte_ln: "LINESTRING(" + this.strngarc + ")",
      trp_schde_id: this.trp_schde_id,
      rte_plyln_id: this.rte_plyln_id,
      asrt_id: this.asrt_id,
    }
    console.log(postData)
    if (this.rtrstrng != undefined) {
      const rte1 = `dashboard/enterprise/addPolylne`;
      this.apiServ.post(postData, rte1).subscribe((res) => {
        if (res['status'] == 200) {
          // this.createMessage('success', 'Schedule Route added Successfully and New route will reflect from tomorrow');
          this.createMessage('success', 'Schedule Route added Successfully');

          this.selectCategory(0, this.curDate);
        }
      });
    }
  }
  getCvrdFncs(c) {
    console.log(c)
    if (this.ipPts) {
      for (var x = 0; x < this.ipPts.length; x++) {
        this.routemap.removeLayer(this.ipPts[x]);
      }
    }
    if (this.cvrdIps) {
      for (var y = 0; y < this.cvrdIps.length; y++) {
        this.routemap.removeLayer(this.cvrdIps[y]);
      }
    }

    var postData = {
      asrt_id: c.asrt_id,
      rte_id: c.rte_id,
      tnt_id: this.selectedtenant,
      strt_tm: c.start_tm,
      end_tm: c.end_tm
    }
    const rte = `tripschedule/sachivalayam/trips/get_cvrd_fncs`;
    this.apiServ.post(postData, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.fncDtls = res['data'];
        if (this.fncDtls) {
          for (var i = 0; i < this.fncDtls.length; i++) {
            if (this.fncDtls[i].lat && this.fncDtls[i].lng) {
              if (this.fncDtls[i].fnce_cvr_sts == 1) {
                this.ipMarker = L.circleMarker([this.fncDtls[i].lat, this.fncDtls[i].lng], {
                  radius: 6,
                  fillColor: "#008000",
                  color: "#008000",
                  weight: 1,
                  opacity: 1,
                  fillOpacity: 1
                }).addTo(this.routemap).bindTooltip(this.fncDtls[i].fnce_nm, { permanent: false, opacity: 0.7 }).openTooltip();;

                this.ipPts.push(this.ipMarker);
              } else {
                this.ipMarker1 = L.circleMarker([this.fncDtls[i].lat, this.fncDtls[i].lng], {
                  radius: 6,
                  fillColor: "#e85339",
                  color: "#e85339",
                  weight: 1,
                  opacity: 1,
                  fillOpacity: 1
                }).addTo(this.routemap).bindTooltip(this.fncDtls[i].fnce_nm, { permanent: false, opacity: 0.7 }).openTooltip();

                this.cvrdIps.push(this.ipMarker1);
              }
            }
          }
        } else {
          if (this.ipPts) {
            for (var x = 0; x < this.ipPts.length; x++) {
              this.routemap.removeLayer(this.ipPts[x]);
            }
          }
          if (this.cvrdIps) {
            for (var y = 0; y < this.cvrdIps.length; y++) {
              this.routemap.removeLayer(this.cvrdIps[y]);
            }
          }
        }

      }
    });

  }
  getsort(id, type) {
    // console.log(id,type)
    this.vehTrpLst = [];
    this.rdchked = false;
    if (id == 1) {
      if (type == 'rd') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000 && e.rd_in == 1) {
            this.vehTrpLst.push(e);
            this.rdTrips.push(e)
            this.shwRdCks = true;
          }

        });
      } else if (type == 'trvl') {
        this.shwRdCks = false;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000 && e.trvl_sts == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'nt') {
        this.shwRdCks = false;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000 && e.trvl_sts == 0) {
            this.vehTrpLst.push(e)
          }

        });
      }
      else {
        this.shwRdCks = false;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000) {
            this.vehTrpLst.push(e)
          }

        });
      }

    } else if (id == 0) {
      if (type == 'rd') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001 && e.rd_in == 1) {
            this.vehTrpLst.push(e);
            this.rdTrips.push(e);
            this.shwRdCks = true;

          }

        });
      } else if (type == 'trvl') {
        this.shwRdCks = false;

        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001 && e.trvl_sts == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'nt') {
        this.shwRdCks = false;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001 && e.trvl_sts == 0) {
            this.vehTrpLst.push(e)
          }

        });
      } else {
        this.shwRdCks = false;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001) {
            this.vehTrpLst.push(e);
          }

        });
      }
    } else {
      if (type == 'rd') {
        this.vehDupTrpLst.forEach(e => {
          if (e.rd_in == 1) {
            this.vehTrpLst.push(e);
            this.rdTrips.push(e);
            this.shwRdCks = true;
          }

        });
      } else if (type == 'trvl') {
        this.shwRdCks = false;
        this.vehDupTrpLst.forEach(e => {
          if (e.trvl_sts == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'nt') {
        this.shwRdCks = false;
        this.vehDupTrpLst.forEach(e => {
          if (e.trvl_sts == 0) {
            this.vehTrpLst.push(e)
          }

        });
      } else {
        this.shwRdCks = false;
        this.vehTrpLst = this.vehDupTrpLst;
      }
    }
  }
  rdChkd(evt) {
    var rDtrips = []; var fnlRdTps = [];
    this.rdchked = evt;
    rDtrips = this.vehDupTrpLst;
    fnlRdTps = this.rdTrips;
    this.vehTrpLst = [];
    if (this.rdchked == true) {
      rDtrips.forEach(e => {
        if (e.drvr_cntctd_in == 1) {
          this.vehTrpLst.push(e)
        }

      })
    } else {
      this.vehTrpLst = fnlRdTps;
    }
  }
  getVendors() {
    // console.log(this.userDtls.dsgns_id)
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      if (this.selectedvndr && this.showVndr) {
        this.apiServ.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
          // console.log(res)
          if (res['status'] == 200) {
            this.vendorList = res['data'];
          }
        }, (err) => {
          // console.log("In errorr");
        })
      }
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
      this.selectedvndr = 0;
      this.selectCategory(0, this.curDate);

    } else {
      this.apiServ.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
          }
          this.selectCategory(0, this.curDate);
        }
      }, (err) => {
        // // console.log('In errorr
      });
    }
  }
  onvndrSelect(value) {
    this.selectedvndr = value;
    this.selectCategory(0, this.curDate);
    this.get_rtDvtdTrips();

  }
  getWrdbyAsrt(asrt_id) {
    if (this.wrdplypoint) {
      for (var k = 0; k < this.wrdplypoint.length; k++) {
        this.routemap.removeLayer(this.wrdplypoint[k].ply);
      }
    }
    this.apiServ.get(`playback/wards/byVhcle/${asrt_id}`).subscribe((res) => {
      if (res['status'] === 200) {
        this.wardLst = res['data'];
        // console.log(this.wardLst)
        for (var l = 0; l < this.wardLst.length; l++)
          if (this.wardLst[l].fnce_arc_lne_tx) {
            this.wrdfncepolygon = L.polygon(JSON.parse(this.wardLst[l].fnce_arc_lne_tx) as []
              , { color: this.wardLst[l].fncs_clr_cd }).addTo(this.routemap).bindTooltip(this.wardLst[l].wrd_nm, { permanent: true, opacity: 0.7 }).openTooltip();
            this.wrdplypoint.push({ ply: this.wrdfncepolygon, fnce_id: this.wardLst[l].fnce_id });
            this.routemap.fitBounds(this.wrdfncepolygon.getBounds());
          }
      }
    }, (err) => {
      console.log('In errorr');
    });

  }

  clearRouteMapLayers() {
    this.SlctdWrdPolyData = [];
    // this.SlctdSvmPolyData=[];
    if (this.ipPts) {
      for (var x = 0; x < this.ipPts.length; x++) {
        this.routemap.removeLayer(this.ipPts[x]);
      }
    }
    if (this.cvrdIps) {
      for (var y = 0; y < this.cvrdIps.length; y++) {
        this.routemap.removeLayer(this.cvrdIps[y]);
      }
    }
    if (this.allSchduleRtes) {
      for (var x = 0; x < this.allSchduleRtes.length; x++) {
        this.routemap.removeLayer(this.allSchduleRtes[x]);
      }
    }
    if (this.allTrvlRtes) {
      for (var x = 0; x < this.allTrvlRtes.length; x++) {
        this.routemap.removeLayer(this.allTrvlRtes[x]);
      }
    }
    if (this.edtPolyline) {
      this.map.removeLayer(this.edtPolyline);
    }
    if (this.actl_Polyline) {
      this.routemap.removeLayer(this.actl_Polyline);
    }
    if (this.startPBMarker1) {
      this.routemap.removeLayer(this.startPBMarker1);
    }
    if (this.endPBMarker1) {
      this.routemap.removeLayer(this.endPBMarker1);
    }

    if (this.schdStartPBMarker) {
      this.routemap.removeLayer(this.schdStartPBMarker);
    }
    if (this.schdStartMarker) {
      this.routemap.removeLayer(this.schdStartMarker);
    }

    if (this.schdEndMarker) {
      this.routemap.removeLayer(this.schdEndMarker);
    }
    if (this.schdEndPBMarker) {
      this.routemap.removeLayer(this.schdEndPBMarker);
    }
    if (this.route_Polyline != undefined) {
      this.routemap.removeLayer(this.route_Polyline);
    }

  }

  onInput(ev: any) {
    this.vehTrpLst = [];
    // Reset items back to all of the items
    this.vehDupTrpLst.forEach(val => {
      this.vehTrpLst.push(val);
    })
    // set val to the value of the search
    const val = ev;
    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      this.vehTrpLst = this.vehTrpLst.filter((item) => {
        return ((item.asrt_nm.toString().toLowerCase().indexOf(val.toLowerCase()) > -1))
        // ||(item.asrt_ctgry_nm.toString().toLowerCase().indexOf(val.toLowerCase()) > -1)
        // || (item.vndr_nm.toString().toLowerCase().indexOf(val.toLowerCase()) > -1) || (item.trip_sts.toString().toLowerCase().indexOf(val.toLowerCase()) > -1));
      })
    } else {
      this.vehTrpLst = this.vehDupTrpLst;
    }
  }

  deSelectChk(evt) {
    console.log(evt)
    // if (evt == false) {
    this.clearRouteMapLayers();
    // }
  }
  onselectStrtLctn(strtLoc) {
    this.strt_loc = strtLoc;
    this.selectCategory(0, this.curDate);
  }
  onselectEndLctn(endLoc) {
    this.end_loc = endLoc;
    this.selectCategory(0, this.curDate);
  }
  editRoute(c, val) {
    console.log(c)
    this.map_stng.show_grid = false;
    this.map_stng.show_sidebar = false;
    this.isVisible = true;
    this.svmChkd = false;
    this.rteSaveData = c;
    this.Pbstrt_tm = new Date(this.rteSaveData.start_tm);
    this.Pbend_tm = new Date(this.rteSaveData.end_tm);

    console.log(val)
    if (val == 'edt') {
      this.rteSaveData.isEdit = true;
    } else {
      this.rteSaveData.isEdit = false;
    }
    this.chklyrSlct(this.tileLayers[0])

  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
    this.svmChkd = false;
  }


  onSvmChk(evt) {
    this.svmChkd = evt;
    if (this.rteSaveData.svm_id && this.selectedtenant == 1000033 && this.svmChkd == true) {
      this.plotSvmBndsInMapWndw(this.rteSaveData.svm_id);
    } else if (this.svmChkd != true) {
      if (this.svmplypoint2) {
        for (var k = 0; k < this.svmplypoint2.length; k++) {
          this.map.removeLayer(this.svmplypoint2[k].ply);
        }
      }
    }
  }

  drawnItems: FeatureGroup = featureGroup();
  drawOptions = {
    position: 'topright',
    draw: {
      polyline: {
        shapeOptions: {
          color: 'red',
          weight: 2
        }
      },

    },
    edit: {
      featureGroup: this.drawnItems,
      // remove: false
    }
  };

  controldata: any;
  onMapReady(map: any) {
    // console.log("onMapReady",this.edtPolyline);
    // console.log("onMapReady",map);
    // console.log("onMapReady",this.drawnItems);
    if (this.drawnItems) {
      this.drawnItems.clearLayers();
    }

    this.map = [];
    this.map = map;
    // this.pointMap();
    this.that = this;
    const that: any = this;
    const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 24,
      minZoom: 3,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: ''
    });
    tiles.addTo(this.map);
    // console.log(this.clntDtls)
    this.map.setView([this.clntDtls[0].lat, this.clntDtls[0].lng], 13);
    // console.log(this.rteSaveData)
    // **************** for Route Edit *****************************//
    if (this.edtPolyline) {
      // console.log(this.edtPolyline)
      this.map.removeLayer(this.edtPolyline);
      this.drawnItems.removeLayer(this.edtPolyline);

    }
    if (this.rteSaveData.isEdit == true) {
      if (this.strtMrkr) {
        this.map.removeLayer(this.strtMrkr);
      }
      if (this.endMrkr) {
        this.map.removeLayer(this.endMrkr);
      }
      if (this.edtPolyline) {
        // console.log(this.edtPolyline)
        this.map.removeLayer(this.edtPolyline);
        this.drawnItems.removeLayer(this.edtPolyline);

      }
      // console.log(this.rteSaveData.edtPlybckStrng,'editable palback stringgggggggggggggggg')
      this.edtPolyline = L.polyline(JSON.parse(this.rteSaveData.rte_arc_lne_tx), {
        color: '#008000',
        weight: 5,
        lineCap: 'butt',
        opacity: 0.6,
        smoothFactor: 10.0,
        lineJoin: 'round'
      }).addTo(this.map);

      this.map.fitBounds(this.edtPolyline.getBounds());

      this.drawnItems.addLayer(this.edtPolyline);
      this.drawnItems.addTo(this.map)
      var linemarker = JSON.parse(this.rteSaveData.rte_arc_lne_tx);
      console.log(linemarker[0])
      this.schdStartPBMarker = L.marker(linemarker[0], {
        icon: this.startIcon
      }).addTo(this.map);      // this.editPlylneList.push(this.edtPolyline);

      this.schdEndPBMarker = L.marker(linemarker[linemarker.length - 1], {
        icon: this.endIcon
      }).addTo(this.map);

    } else {
      if (this.strtMrkr) {
        this.map.removeLayer(this.strtMrkr);
      }
      if (this.endMrkr) {
        this.map.removeLayer(this.endMrkr);
      }
      // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
      if (this.rteSaveData.strt_fnce_lat && this.rteSaveData.strt_fnce_lng) {
        this.strtMrkr = L.marker([this.rteSaveData.strt_fnce_lat, this.rteSaveData.strt_fnce_lng], {
          icon: this.startIcon1
        }).addTo(this.map);
      }
      // ----------Fence END Area (flag position) -- Leaflet OS ----------//
      if (this.rteSaveData.end_fnce_lat && this.rteSaveData.end_fnce_lng) {

        this.endMrkr = L.marker([this.rteSaveData.end_fnce_lat, this.rteSaveData.end_fnce_lng], {
          icon: this.endIcon1
        }).addTo(this.map);
      }
    }
    map.on('draw:editvertex', function (event) {
      this.poly = event.poly._latlngs;
      // console.log(this.poly)
    });

    // **************** for Route Edit *****************************//

    this.controldata = this.map.pm.addControls({
      position: 'topleft',
      drawCircle: false,
      drawCircleMarker: false,
      drawPolyline: !this.rteSaveData.isEdit,
      drawRectangle: false,
      drawPolygon: false,
      editMode: this.rteSaveData.isEdit,
      dragMode: false,
      cutPolygon: false,
      removalMode: false,
      drawMarker: false
    });
    this.map.on(L.Draw.Event.CREATED, function (e) {
      // console.log("Map draw event -------- " + e.layerType);
      // console.log(Object.keys(e.layer._latlngs).length);
      // console.log(typeof (e.layer._latlngs));
      Object.keys(e.layer._latlngs).forEach(key => {
        console.log(key, "Lat", e.layer._latlngs[key].lat);
        console.log(key, "Lng", e.layer._latlngs[key].lng);
      });

      that.edit_fence.fnce_type = "Polyline";
      that.edit_fence.crtd_fnce_type = that.edit_fence.fnce_type;
      that.edit_fence.fnce_type = '';
      const layer: any = (e as DrawEvents.Created).layer;
      this.editablLayers.addLayer(layer);
      console.log("layer.layer  :: ");
      console.log(JSON.parse(layer));
      this.addedNwLAyer = this.drawnItems.addLayer(layer);

    });



    this.showCoordinates();

    L.control.ruler({
      lengthUnit: {                 // You can use custom length units. Default unit is kilometers.
        display: 'km',              // This is the display value will be shown on the screen. Example: 'meters'
        decimal: 4,                 // Distance result will be fixed to this value. 
        factor: null,               // This value will be used to convert from kilometers. Example: 1000 (from kilometers to meters)  
        label: 'Distance:'
      },
      angleUnit: {
        display: '&deg;',           // This is the display value will be shown on the screen. Example: 'Gradian'
        decimal: 2,                 // Bearing result will be fixed to this value.
        factor: null,                // This option is required to customize angle unit. Specify solid angle value for angle unit. Example: 400 (for gradian).
        label: 'Bearing:'
      }
    }).addTo(this.map);


  }

  showCoordinates() {
    const Coordinates = L.Control.extend({
      onAdd: map => {
        const container = L.DomUtil.create("div");
        map.addEventListener("mousemove", e => {
          container.innerHTML = `
				<h6>Latitude  ${e.latlng.lat.toFixed(6)} <br>Longitude  ${e.latlng.lng.toFixed(6)}</h6>
				`;
        });
        return container;
      }
    });
    this.map.addControl(new Coordinates({ position: "topleft" }));
  }


  deleteLastVertex() {
    // L.Draw.Polyline.deleteLastVertex()
    console.log("In deleteLastVertex ======>>>")
    if (this.edit_fence.fnce_nm === 'Polyline' || this.edit_fence.fnce_nm === 'Polygon') {
      this.fnce_drawer.polylineDrawer.deleteLastVertex();
    }
  }
  saveFence() {
    console.log("In saveFence ====> creating fence ");
    console.log(this.edit_fence);



    this.clearFence();
  }
  clearFence() {
    console.log("In clearFence ===> clear fence ");
    this.edit_fence = {
      "fnce_type": "", "crtd_fnce_type": '',
      "fnce_nm": "", "dscn_tx": "", "imge_url_tx": "", Opacity: "30",
      "FillOpacity": "30", "stroke": 2, "weight": 2, "dashArray": '2,2',
      "color": '#8c90f2', "fill": true,
      "fillColor": '#8c90f2', "stroke_type": "DIRECTED_ROUTE",
      "marker_id": 1, "marker_nm": "RED_MARKER", "marker_icn_url": "007-placeholder.svg",
      "lat": null, "lng": null, "latlng_array": [], "radius": null
    }
  }




  public onDrawCreated(e: any) {
    // console.log(e.layer);
    // console.log(e.layer.options.color);
    // console.log(e.layer.options.clickable);
    // console.log(e.layer.options.fill);
    // console.log(e.layer.options.opacity);
    // console.log(e.layer.options.stroke);
    // console.log(e.layer.options.weight);

    // console.log(e.layer._latlngs)
    // console.log(e.layer._latlngs.lat);
    // console.log(e.layer._latlngs.lat);
    var restr = '';
    this.rtrstrng = '';
    var arcrestr = '';


    var latLngData = e.layer._latlngs
    for (var i = 0; i < latLngData.length; i++) {
      restr += (restr == "") ? "[" + latLngData[i].lat + "," + latLngData[i].lng + "]" : ",[" + latLngData[i].lat + "," + latLngData[i].lng + "]";
      this.rtrstrng = restr;
      arcrestr += (arcrestr == "") ? latLngData[i].lng + " " + latLngData[i].lat : "," + latLngData[i].lng + " " + latLngData[i].lat;

    }

    var lstrng = arcrestr.split("[").join("");
    var lstrng2 = lstrng.split("]").join("");
    this.strngarc = lstrng2;


    console.log('onDrawCreated =====> Draw Created Event!');
    Object.keys(e.layer._latlngs).forEach(key => {
      console.log(key, "CREATED==>Lat", e.layer._latlngs[key].lat);
      console.log(key, "CREATED==>Lng", e.layer._latlngs[key].lng);
    });
    // console.log(e)

    const layer = (e as DrawEvents.Created).layer;
    this.drawnItems.addLayer(layer);
    this.saveRouteAlert(this.strngarc, this.rtrstrng)

  }
  saveRouteAlert(strngarc, rtrstrng) {
    var data = {
      strngarc: strngarc,
      rtrstrng: rtrstrng
    }
    this.modal.confirm({
      nzTitle: '<i>Do you Want to Save this Route?</i>',
      nzOnOk: () => this.addnwRoute(data)
    });

  }
  addnwRoute(plyData) {
    var postData = {
      dt: moment(this.slctdDt).format('YYYY-MM-DD'),
      rte_id: this.rteSaveData.rte_id,
      rte_nm: this.rteSaveData.rte_nm,
      rte: '[' + plyData.rtrstrng + ']',
      tnt_id: this.selectedtenant,
      trp_sqnc_nu: this.rteSaveData.trp_sqnc_nu,
      rte_ln: "LINESTRING(" + plyData.strngarc + ")",
      trp_schde_id: this.rteSaveData.trp_schde_id,
      rte_plyln_id: this.rteSaveData.rte_plyln_id,
      asrt_id: this.rteSaveData.asrt_id,
    }
    // console.log(postData)
    if (this.rtrstrng != undefined) {
      const rte1 = `dashboard/enterprise/addPolylne`;
      this.apiServ.post(postData, rte1).subscribe((res) => {
        if (res['status'] == 200) {
          this.createMessage('success', 'Schedule Route added Successfully and New route will reflect from tomorrow');
          this.selectCategory(0, this.curDate);
        }
      });
    }
  }
  public onDrawEditStop(e: any) {
    // console.log("edit stopppp")
    // console.log(e);
    var restr = '';
    this.rtrstrng = '';
    var arcrestr = '';
    var latLngData = e.target.poly
    for (var i = 0; i < latLngData.length; i++) {
      // console.log(latLngData[0]);

      restr += (restr == "") ? "[" + latLngData[i].lat + "," + latLngData[i].lng + "]" : ",[" + latLngData[i].lat + "," + latLngData[i].lng + "]";
      this.rtrstrngEdt = restr;
      arcrestr += (arcrestr == "") ? latLngData[i].lng + " " + latLngData[i].lat : "," + latLngData[i].lng + " " + latLngData[i].lat;

    }

    var lstrng = arcrestr.split("[").join("");
    var lstrng2 = lstrng.split("]").join("");
    this.strngarcEdt = lstrng2;
    this.saveRouteAlert(this.strngarcEdt, this.rtrstrngEdt)
  }
  public onDrawStart(e: any) {
    console.log(e);
    console.log(e.layer)
    console.log('onDrawStart ======> Draw Started Event!');
    this.edit_fence.lat = null;
    this.edit_fence.lng = null;
    this.edit_fence.latlng_array = [];
    this.map_stng.undo_flag = true;

  }

  enablePolyline() {
    console.log('enablePolyline ======>  In enablePolyline');
    this.fnce_drawer.polylineDrawer = new L.Draw.Polyline(this.map, {
      shapeOptions: {
        color: this.edit_fence.color,
        opacity: Math.round(this.edit_fence.opacity / 100),
        stroke: this.edit_fence.stroke,
        dashArray: this.edit_fence.dashArray,
        fill: false
        //  ,weight: this.edit_fence.stroke
      }
    });
    this.fnce_drawer.polylineDrawer.enable();
  }
  addPolyline(p_latlngs: any, p_optns: any, p_route_path: any) {
    console.log(p_latlngs)
    const optns: any = { color: 'black', fill: false, fillColor: 'green', fillOpacity: 0.5, opacity: 1, stroke: 2, weight: 2, dashArray: '2,2' };

    if (!(p_optns.color === undefined)) { optns.color = p_optns.color; }
    if (!(p_optns.fill === undefined)) { optns.fill = p_optns.fill; }
    if (!(p_optns.fillColor === undefined)) { optns.fillColor = p_optns.fillColor; }
    if (!(p_optns.fillOpacity === undefined)) { optns.fillOpacity = p_optns.fillOpacity / 100; }
    if (!(p_optns.opacity === undefined)) { optns.opacity = p_optns.opacity / 100; }
    if (!(p_optns.stroke === undefined)) { optns.stroke = p_optns.stroke; }
    if (!(p_optns.weight === undefined)) { optns.weight = p_optns.weight; }
    if (!(p_optns.dashArray === undefined)) { optns.dashArray = p_optns.dashArray; }

    const latlngs: [number, number][] = p_latlngs;
    const l_ploly: any = L.polyline(latlngs, optns);

    // Direction in red
    if (p_route_path === 'DIRECTED_ROUTE') {
      l_ploly.setText('  \u25BA  ', { repeat: true, offset: 3, attributes: { fill: 'red' } });
    }
    // Railway track
    if (p_route_path === 'RAILWAY_TRACK') {
      optns.weight = 10;
      optns.color = 'grey';
      l_ploly.setText('| ', { repeat: true, offset: 3, attributes: { fill: 'black', 'font-weight': 'bold', 'font-size': '10' } });
    }

    this.drawnItems.addLayer(l_ploly);
  }
  clearEditablePolyline() {
    if (this.edtPolyline) {
      console.log(this.edtPolyline)
      this.map.removeLayer(this.edtPolyline);
      this.drawnItems.removeLayer(this.edtPolyline);

    }
    if (this.drawnItems) {
      this.drawnItems.clearLayers();
    }

    // if (this.schdStartPBMarker) {
    //   this.map.removeLayer(this.schdStartPBMarker);
    // }
    // if (this.schdEndPBMarker) {
    //   this.map.removeLayer(this.schdEndPBMarker);
    // }

  }
  // Route  Deviation slider

  deviateSlider(c) {
    this.openSlider = true;
    this.slctdDtls = c;
  }
  closeSideBar() {
    this.openSlider = false;
    this.dvtnForm.reset();
  }
  addDvtnRsn() {
    var postData = {
      trp_rn_id: this.slctdDtls.trp_rn_id,
      trvl_dt: this.slctdDtls.sch_strt_dt,
      drvr_cntctd_in: this.dvtnForm.value.drvr_cntctd_in,
      rte_dvtn_acpt_in: this.dvtnForm.value.rte_dvtn_acpt_in,
      dvtn_rsn_tx: this.dvtnForm.value.dvtn_rsn_tx,
      tnt_id: this.selectedtenant,
    }
    // console.log(postData)
    this.apiServ.post(postData, `dashboard/enterprise/route-deviation/reason`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Route Deviation Reason added successfully');
        this.openSlider = false;
        this.selectCategory(0, this.curDate);
      } else {
        this.createMessage('Warning', 'Please Try Again');
      }
    })
  }
  onselectPbStrtTime(dt) {
    this.Pbstrt_tm = new Date(dt);
    this.chckHrsDiff(this.Pbstrt_tm, this.Pbend_tm);
  }
  onselectPbEndTime(dt) {
    this.Pbend_tm = new Date(dt);
    this.chckHrsDiff(this.Pbstrt_tm, this.Pbend_tm);
  }
  chckHrsDiff(strt, end) {
    // Calculate the time difference in milliseconds
    const timeDiff = Math.abs(end - strt);
    // Convert milliseconds to hours
    this.hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

  }
  getPlayBack() {
    this.chckHrsDiff(this.Pbstrt_tm, this.Pbend_tm);

    var postslctdDt = {
      data: {
        dev_imei_nu: this.rteSaveData.dev_imei_nu,
        start_tm: this.datepipe.transform(this.Pbstrt_tm, 'yyyy-MM-dd HH:mm'),
        end_tm: this.datepipe.transform(this.Pbend_tm, 'yyyy-MM-dd HH:mm')
      }
    }

    if (this.hoursDiff <= 8) {

      // Getting travelled route line arc
      const rte = `dashboard/enterprise/get_tripTrvlRoute`;
      this.apiServ.post(postslctdDt, rte).subscribe((res) => {
        console.log(res)
        var restr = '';
        this.pbRtData = res['data'];
        for (let i = 0; i < this.pbRtData.length; i++) {
          restr += (restr == "") ? "[" + this.pbRtData[i].lat + "," + this.pbRtData[i].lng + "]" : ",[" + this.pbRtData[i].lat + "," + this.pbRtData[i].lng + "]";
        }
        if (this.PlyBk_Polyline) {
          this.map.removeLayer(this.PlyBk_Polyline);
        }
        if (this.startPBMarker) {
          this.map.removeLayer(this.startPBMarker);
        }
        if (this.endPBMarker) {
          this.map.removeLayer(this.endPBMarker);
        }
        // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
        this.startPBMarker = L.marker([this.pbRtData[0].lat, this.pbRtData[0].lng], {
          icon: this.startIcon1
        }).addTo(this.map);

        // ----------Fence END Area (flag position) -- Leaflet OS ----------//
        let k1 = this.pbRtData.length - 1
        this.endPBMarker = L.marker(this.pbRtData[k1], {
          icon: this.endIcon1
        }).addTo(this.map);

        this.PlyBk_Polyline = L.polyline(JSON.parse("[" + restr + "]"), {
          color: '#b157e9',
          weight: 4,
          lineCap: 'butt',
          opacity: 0.5,
          smoothFactor: 10.0,
          lineJoin: 'round',
          dist: this.trvlDst
        }).addTo(this.map);
        this.map.fitBounds(this.PlyBk_Polyline.getBounds());
      });
    } else {
      this.createMessage('warning', 'Please select the playback time with 8 hours Difference');
    }
  }

  onStrtTmEdt(s) {
    let sch_strt_tm = s.sch_strt_tm ? s.sch_strt_tm : '00:00:00';
    this.slctdstrt_tm = 'Wed Jun 21 2023 ' + sch_strt_tm + ' GMT+0530 (India Standard Time)';
    this.vehTrpLst.forEach(ele => {
      if (ele.trp_schde_id != s.trp_schde_id) {
        ele.shwStrtTmEdt = false;
      } else {
        ele.shwStrtTmEdt = true;
      }
    });
  }
  onselectStrtTime(tm, s) {
    this.slctdstrt_tm = moment(tm, ["h:mm A"]).format("HH:mm");
    var data = {
      trp_schde_id: s.trp_schde_id,
      schdle_strt_tm: this.slctdstrt_tm,
      key: 'strTm'
    }
    this.updateCnfrmMdl('Start Time', data)
  }
  onEndTmEdt(s) {
    let sch_end_tm = s.sch_end_tm ? s.sch_end_tm : '00:00:00';
    this.slctdend_tm = 'Wed Jun 21 2023 ' + sch_end_tm + ' GMT+0530 (India Standard Time)';
    this.vehTrpLst.forEach(ele => {
      if (ele.trp_schde_id != s.trp_schde_id) {
        ele.shwEndTmEdt = false;
      } else {
        ele.shwEndTmEdt = true;
      }
    });
  }
  onselectEndTime(tm, s) {
    this.slctdend_tm = moment(tm, ["h:mm A"]).format("HH:mm");
    var data = {
      trp_schde_id: s.trp_schde_id,
      schdle_end_tm: this.slctdend_tm,
      key: 'endTm'
    }
    this.updateCnfrmMdl('End Time', data)
  }
  updateCnfrmMdl(ele, data) {
    this.modal.confirm({
      nzTitle: `Are you sure Do You Want To Change ${ele}?`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.showLdr = true;
        this.apiServ.post(data, `tripschedule/trips/schedule/trips/chngeVehcle`).subscribe((res) => {
          if (res['status'] == 200) {
            this.showLdr = false;
            this.createMessage('Success', `${ele} successfully Updated`);
            this.selectCategory(0, this.curDate);

          }
        })
      },
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.closeSlct(data)
      },
    });
  }
  closeSlct(s) {
    this.vehTrpLst.forEach(ele => {
      if (ele.trp_schde_id == s.trp_schde_id) {
        ele.shwEndTmEdt = false;
        ele.shwStrtTmEdt = false;
      }
    })
  }
  openOprtsSldr(c) {
    this.oprtnsData = c;
    this.onStrtTmEdt(c);
    this.onEndTmEdt(c);
    this.openOprtnSlider = true;
    // console.log(this.oprtnsData);
  }
  closeOprtnSideBar() {
    this.openOprtnSlider = false;
    this.oprtnsData = {};
  }
  getVehicles() {
    var fltrData = [{
      selectedtenant: this.selectedtenant,
      selectedvndr: 0,
      selectedctgry: 0
    }]
    this.showLdr = true;
    this.apiServ.post(fltrData, 'drivers/vehicles/asgndvehicles').subscribe((res) => {
      if (res['status'] == 200) {
        this.vhcleslst = res['data'];
        this.selectedVehicle = this.vhcleslst[0].asrt_id;
        this.showLdr = false;
      }
    });
  }
  onvehcleSelect(evt) {
    this.chngdVhcle = evt;
  }
  clearTrip() {
    // clear Trip
    var postData = {
      tnt_id: this.selectedtenant,
      asrt_id: this.oprtnsData.asrt_id,
      dt: this.curDate,
      trp_schde_id: this.oprtnsData.trp_schde_id,
      trp_rn_id: this.oprtnsData.trp_rn_id
    }
    this.showLdr = true;

    if (this.oprtnsData.asrt_id) {
      this.apiServ.post(postData, `tripschedule/tripsDtls/clearTrip`).subscribe((res) => {
        if (res['status'] == 200) {
          this.showLdr = false;
          this.createMessage('Success', `Trip Cleared successfully`);
        }

      })
    }
  }
  StartTrip() {
    var postData = this.oprtnsData;
    this.showLdr = true;
    if (this.oprtnsData.asrt_id) {
      this.apiServ.post(postData, `tripschedule/tripsDtls/startTrip`).subscribe((res) => {
        if (res['status'] == 200) {
          this.showLdr = false;
          this.createMessage('Success', `Trip Started successfully`);
        }

      })
    }
  }
  cancelTrip() {
    var postData = this.oprtnsData;
    this.showLdr = true;
    if (this.oprtnsData.asrt_id) {
      this.apiServ.post(postData, `tripschedule/tripsDtls/cancelTrip`).subscribe((res) => {
        if (res['status'] == 200) {
          this.showLdr = false;
          this.createMessage('Success', `Trip Canceled successfully`);
        }

      })
    }
  }
  submitVehicle() {
    var postData = this.oprtnsData;
    postData.chngdasrt = this.chngdVhcle

    this.showLdr = true;

    if (this.oprtnsData.asrt_id) {
      this.apiServ.post(postData, `tripschedule/tripsDtls/changeVhclForTrip`).subscribe((res) => {
        if (res['status'] == 200) {
          this.showLdr = false;
          this.createMessage('Success', `Vehicle Assigned to Trip successfully`);
        }

      })
    }
  }

  plotSvmBndsInMapWndw(svm_id) {
    this.trpsvmBndsLst = [];
    if (this.svmplypoint2) {
      for (var k = 0; k < this.svmplypoint2.length; k++) {
        this.map.removeLayer(this.svmplypoint2[k].ply);
      }
    }
    this.svmLst.forEach(s => {
      if (s.svm_id == svm_id) {
        this.trpsvmBndsLst.push(s);
      }
    });
    // console.log(this.trpsvmBndsLst)
    for (var l = 0; l < this.trpsvmBndsLst.length; l++)
      if (this.trpsvmBndsLst[l].fnce_arc_lne_tx) {
        this.svmfncepolygon2 = L.polygon(JSON.parse(this.trpsvmBndsLst[l].fnce_arc_lne_tx) as []
          , { color: this.trpsvmBndsLst[l].fncs_clr_cd, fill: false }).addTo(this.map).bindTooltip(this.trpsvmBndsLst[l].svm_nm, { permanent: true, opacity: 0.7 }).openTooltip();
        this.svmplypoint2.push({ ply: this.svmfncepolygon2, svm_id: this.trpsvmBndsLst[l].svm_id });
        this.map.fitBounds(this.svmfncepolygon2.getBounds());
      }
  }
  downloadPdf() {
    var node: any = document.getElementById('map_pdf_section');
    node.style.width = '100%';
    node.style.height = 'auto';
    const options = {
      scale: 1, // Adjust the scale factor as needed
      useCORS: true, // Enable cross-origin resource sharing
      logging: true // Enable logging for debugging (optional)
    };

    // html2canvas(node, options).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF('l', 'mm', 'a4');
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    //   console.log(pdfWidth, pdfHeight)

    //   pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
    //   pdf.save('TripDetails.pdf');
    // });
  }
  // openStoppage(c, dt) {
  //   this.vehicle_nu=c.asrt_nm
  //   this.showLdr = true;
  //   this.stpgPnts = []
  //   this.points = []
  //   console.log(c)

  //   if (c.actl_strt_ts != null && c.actl_end_ts != null) {

  //     this.curDate = moment(dt).format('YYYY-MM-DD');
  //     this.rte_id = c.rte_id;
  //     this.postslctdDt = {
  //       "checkbit": "byVeh",
  //       "sdate": this.datepipe.transform(c.actl_strt_ts, 'yyyy-MM-dd HH:mm'),
  //       "edate": this.datepipe.transform(c.actl_end_ts, 'yyyy-MM-dd HH:mm'),
  //       "asrt_id": c.asrt_id,
  //       "trp_rn_id": c.trp_rn_id
  //     }
  //     this.apiServ.post(this.postslctdDt, 'playback/vehicle/playback').subscribe((res) => {
  //       this.stpgDtls = true
  //       var playData = res['data'];
  //       if (playData !== '' && playData.lat.length > 0) {
  //         this.showLdr = false;
  //         console.log(playData);
  //         for (var c = 0; c < playData.lat.length; c++) {

  //           if (playData.lat[c] != playData.lat[0] && playData.lng[c] != playData.lng[0]) {
  //             this.points.push({
  //               lat: playData.lat[c],
  //               lng: playData.lng[c],
  //               speed_ct: playData.mapPoints[c].speed_ct,
  //               Signal: playData.mapPoints[c].Signall,
  //               asrt_nm: playData.mapPoints[c].asrt_nm,
  //               drcn_ct: playData.mapPoints[c].drcn_ct
  //             });
  //           }
  //         }
  //         this.points.push({
  //           lat: playData.lat[playData.lat.length - 1],
  //           lng: playData.lng[playData.lat.length - 1],
  //           Signal: playData.mapPoints[playData.lat.length - 1].Signall != undefined ? playData.mapPoints[playData.lat.length - 1].Signall : '',
  //           asrt_nm: playData.mapPoints[playData.lat.length - 1].asrt_nm,
  //         });

  //         var trvlDstnce = playData.mapPoints[playData.mapPoints.length - 1].odmtr_ct - playData.mapPoints[0].odmtr_ct;
  //         var totDtnce = Math.ceil(trvlDstnce);
  //         this.travelDist = totDtnce + ' ' + 'Kms';

  //         var pstrt;
  //         var pend;
  //         var sno = 0;
  //         console.log(this.points)
  //         for (var m = 0; m < this.points.length; m++) {
  //           var startTime;
  //           var endTime;

  //           if (this.points[m].speed_ct == 0) {
  //             if (this.points[m].Signal == undefined) {
  //               pstrt = this.points[m + 1].Signal;

  //             } else {
  //               pstrt = this.points[m].Signal;
  //             }
  //             pend = this.points[m + 1].Signal;

  //             this.stoppageTime = moment(pend, "DD-MM-YYYY HH:mm").diff(moment(pstrt, "DD-MM-YYYY HH:mm"));
  //             this.points[m]["stoppageTime"] = this.stoppageTime / 60000;
  //             this.points[m]["lastTime"] = this.points[m + 1].Signal;
  //             this.points[m]["Timing"] = pstrt + " to " + pend
  //             if (this.stoppageTime != 0) {
  //               this.points[m]["Sno"] = ++sno;
  //               this.points[m]["Vehicle"] =this.vehicle_nu;
  //               this.points[m]["latlng"] = this.points[m].lat + "," + this.points[m].lng;
  //               this.points[m]["parkingpoint"] = "parking point" + "-" + sno;
  //               this.stpgPnts.push(this.points[m])
  //             }


  //           }
  //         }
  //         console.log(this.stpgPnts)
  //         this.columnDefs = [
  //           { headerName: 'S.No', field: 'Sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
  //           { headerName: 'vehicle', field: 'vehicle', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
  //           { headerName: 'Parking Point', field: 'parkingpoint', alignment: 'left', filter: false, cellClass: 'pm-grid-number-cell', search: true },
  //           { headerName: 'Stoppage Between', field: 'Timing', alignment: 'left', filter: false, cellClass: 'pm-grid-number-cell', search: true },
  //           { headerName: 'Latitude And Longitude', field: 'latlng', alignment: 'left', filter: false, cellClass: 'pm-grid-number-cell', search: true },
  //           { headerName: 'Stoppage Time (In MIN)', alignment: 'left', width: 150, field: 'stoppageTime', cellClass: 'pm-grid-number-cell' },
  //         ]
  //       } else {
  //         this.showLdr = false;
  //         this.createMessage('warning', 'Travelled Route not found!!!!');
  //       }
  //     })

  //   }
  //   else {
  //     this.showLdr = false;
  //     this.createMessage('warning', 'Travelled Route not found!!!!');
  //   }
  // }
  handleOk(): void {
    this.stpgDtls = false;
  }

  handlestoppage(): void {
    this.stpgDtls = false;
  }
  vewonMap(f) {
    console.log(f)
    var latitude = f.lat;
    var longitude = f.lng;
    var mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, '_blank');
  }
  dnldPrkngDta(c) {
    console.log(c);
    this.vehicle_nu = c.asrt_nm
    this.showLdr = true;
    this.stpgPnts = []
    this.points = []
    this.postslctdDt = {
      "checkbit": "byallTrips",
      "asrt_id": c.asrt_id,
      "device": c.dev_imei_nu,
      "date":this.slctdDt
    }
    // if (c.actl_strt_ts != null && c.actl_end_ts != null) {
      
      this.apiServ.post(this.postslctdDt, 'playback/vehicle/playback').subscribe((res) => {
        var playData = res['data'];
        this.stpgDtls=true
        this.showLdr = true;
        console.log(playData)
        if (playData !== '' && playData.lat.length > 0) {
          this.showLdr = false;
          console.log(playData);
          for (var c = 0; c < playData.lat.length; c++) {

            if (playData.lat[c] != playData.lat[0] && playData.lng[c] != playData.lng[0]) {
              this.points.push({
                lat: playData.lat[c],
                lng: playData.lng[c],
                speed_ct: playData.mapPoints[c].speed_ct,
                Signal: playData.mapPoints[c].Signall,
                asrt_nm: playData.mapPoints[c].asrt_nm,
                drcn_ct: playData.mapPoints[c].drcn_ct
              });
            }
          }
          this.points.push({
            lat: playData.lat[playData.lat.length - 1],
            lng: playData.lng[playData.lat.length - 1],
            Signal: playData.mapPoints[playData.lat.length - 1].Signall != undefined ? playData.mapPoints[playData.lat.length - 1].Signall : '',
            asrt_nm: playData.mapPoints[playData.lat.length - 1].asrt_nm,
          });

          var trvlDstnce = playData.mapPoints[playData.mapPoints.length - 1].odmtr_ct - playData.mapPoints[0].odmtr_ct;
          var totDtnce = Math.ceil(trvlDstnce);
          this.travelDist = totDtnce + ' ' + 'Kms';

          var pstrt;
          var pend;
          var sno = 0;
          console.log(this.points)
          for (var m = 0; m < this.points.length; m++) {
            var startTime;
            var endTime;

            if (this.points[m].speed_ct == 0) {
              if (this.points[m].Signal == undefined) {
                pstrt = this.points[m + 1].Signal;

              } else {
                pstrt = this.points[m].Signal;
              }
              pend = this.points[m + 1].Signal;

              this.stoppageTime = moment(pend, "DD-MM-YYYY HH:mm").diff(moment(pstrt, "DD-MM-YYYY HH:mm"));
              this.points[m]["stoppageTime"] = this.stoppageTime / 60000;
              this.points[m]["lastTime"] = this.points[m + 1].Signal;
              this.points[m]["Timing"] = pstrt + " to " + pend
              this.points[m]["Timing_strt"] = pstrt 
              this.points[m]["Timing_end"] = pend
              if (this.stoppageTime != 0) {
                this.points[m]["Sno"] = ++sno;
                this.points[m]["vehicle"] = this.vehicle_nu ;
                this.points[m]["latlng"] = this.points[m].lat + "," + this.points[m].lng;
                this.points[m]["parkingpoint"] = "parking point" + "-" + sno;
                this.stpgPnts.push(this.points[m])
              }


            }
          }
          console.log(this.stpgPnts)
          this.columnDefs = [
            { headerName: 'S.No', field: 'Sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
            { headerName: 'vehicle', field: 'vehicle', alignment: 'center', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
            { headerName: 'Parking Point', field: 'parkingpoint', alignment: 'left', filter: false, cellClass: 'pm-grid-number-cell', search: true },
            { headerName: 'Stoppage Between', field: 'Timing', alignment: 'left', filter: false, cellClass: 'pm-grid-number-cell', search: true },
            { headerName: 'Latitude And Longitude', field: 'latlng', alignment: 'left', filter: false, cellClass: 'pm-grid-number-cell', search: true },
            { headerName: 'Stoppage Time (In MIN)', alignment: 'left', width: 150, field: 'stoppageTime', cellClass: 'pm-grid-number-cell' },
          ]
        } else {
          this.showLdr = false;
          this.createMessage('warning', 'Travelled Route not found!!!!');
        }
      })

    // }
    // else {
    //   this.showLdr = false;
    //   this.createMessage('warning', 'Travelled Route not found!!!!');
    // }
  }
  closePrkng(){
    this.prkngOpn=false
  }

  fltrs(){
    this.filters = !this.filters
  }
}
