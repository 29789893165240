<!-- <p style="color: #090909;
font-weight: 500;">Ticket ID:</p> -->
<div>
    <div class="dsh-card ht-170 hd-alt prfcrd" nzSize="small">
        <div class=" justify-content-between align-items-center crd_inrCntnt">
            <div class="row cstmbgrnd ">
                <div class="col-md-12">
                    <div class="crd_bdy_cntnt">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="m-b-0 hd-tx">
                                    Ticket ID <span class="fltrgt">:</span>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <span class="m-b-0 prflblvlue">{{tcktId}}</span>
                            </div>

                        </div>
                    </div>
                </div>
                </div>
                <div class="row cstmbgrnd ">
                <div class="col-md-12">
                    <div class="crd_bdy_cntnt">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="m-b-0 hd-tx">Stage<span class="fltrgt">:</span>
                                </div>
                            </div>
                            <div class="col-md-7"><span class="m-b-0 prflblvlue">{{stge_nm}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row cstmbgrnd">
                <div class="col-md-12">
                    <div class="crd_bdy_cntnt">
                        <!-- <div class="pg-crd"> -->
                        <div class="row">
                            <div class="col-md-5">
                                <div class="m-b-0 hd-tx">Assigned Date<span class="fltrgt">:</span>
                                </div>
                            </div>
                            <div class="col-md-7"> <span class="m-b-0 prflblvlue">{{assgn_ts}}</span></div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="row cstmbgrnd ">
                <div class="col-md-12">
                    <div class="crd_bdy_cntnt">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="m-b-0 hd-tx">Service Category <span class="fltrgt">:</span>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <span class="m-b-0 prflblvlue">{{tckt_ctgry_nm}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row cstmbgrnd">
                <div class="col-md-12">
                    <div class="crd_bdy_cntnt">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="m-b-0 hd-tx">Service Type <span class="fltrgt">:</span>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <span class="m-b-0 prflblvlue">{{ise_type_nm}}</span>
                            </div>
                        </div>
                    </div>
                </div>
</div>
<div class="row cstmbgrnd ">
                <div class="col-md-12">
                    <div class="crd_bdy_cntnt">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="m-b-0 hd-tx">No. Of Assets <span class="fltrgt">:</span>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <span class="m-b-0 prflblvlue">{{asstCnt}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row cstmbgrnd">
                <div class="col-md-4 " >
                    <div class="crd_bdy_cntnt">
                        <div class="row">
                            <div class="col-md-5" >
                        <div class="m-b-0 hd-tx">Purchase date<span class="fltrgt">:</span>
                        </div>
                        </div>
                        <div class="col-md-7" >
                             <span class="m-b-0 prflblvlue">01-01-2024</span></div>
                    </div>
                    </div>
                </div>
                <div class="col-md-4 " >
                    <div class="crd_bdy_cntnt">
                        <div class="row">
                            <div class="col-md-5" >
                        <div class="m-b-0 hd-tx">Installation date<span class="fltrgt">:</span>
                        </div>
                        </div>
                        <div class="col-md-7" >
                             <span class="m-b-0 prflblvlue">01-01-2024</span></div>
                    </div>
                </div>
                </div>
            </div>
            <div class="row"> 
            </div>
            <div class="row">
            </div> -->
        </div>
    </div>
</div>
<nz-tabset>
    <nz-tab nzTitle="Timeline">
        <div style="margin-top:20px;margin-left: 10px;">

            <nz-timeline *ngFor="let s of timelineData; index as i">

                <nz-timeline-item [nzColor]="s.frwd_ts?'green':!s.frwd_ts && s.crnt_stg=='inact'?'#6b6969':s.crnt_stg=='actv' && !s.frwd_ts?'orange':'blue'">
                    <div>
                        <!-- <nz-ribbon nzText=Stage-{{i+1}}:-{{s.stge_nm}} nzColor="cyan" class="stgeRbn" style="margin-bottom: 10px;"> -->
                        <!-- <div class="row">
                                <div class="col-md-10"></div>
                                <div class="col-md-2"> -->
                        <!-- <span nz-icon nzType="cloud-download" nzTheme="outline" (click)="downloadbtn(srvr_url)" style="font-size: 21px;
                                    float: right;
                                    text-align: center;
                                    cursor: pointer;"></span> -->
                        <!-- </div>
            
                            </div> -->
                        <!-- </nz-ribbon> -->

                        <div class="row">
                            <!-- <div class="col-md-6"> -->
                            <!-- <div class="stglbl">Stage Name<span style="float:right">:</span></div> -->
                            <div class="col-md-12">
                                <div class="stageVal">{{s.stge_nm}}</div>
                            </div>

                            <!-- </div>
                            <div class="col-md-6">
                                <div class="stageVal">{{s.stge_nm}}</div>
                            </div> -->
                            <div class="col-md-4">
                                <div class="stglbl">Status<span style="float:right">:</span></div>
                            </div>
                            <div class="col-md-6">
                                <div *ngIf="!s.frwd_ts && s.crnt_stg=='inact'" class="sts" style="color: #6b6969;
                                padding-top: 4px;">Not
                                    completed
                                </div>
                                <div *ngIf="s.frwd_ts" class="stsc" style="color: green; padding-top: 4px;"> Completed</div>
                                <div *ngIf="s.crnt_stg=='actv' && !s.frwd_ts" class="stsc" style="color: orange;">
                                    Inprogress

                                </div>

                            </div>


                            <div class="col-md-4" *ngIf="s.assgn_ts">
                                <div class="stglbl">Assigned Date
                                    <span style="float:right">:</span>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="stageVal">{{s.assgn_ts}}</div>
                            </div>
                            <div class="col-md-4" *ngIf="s.frwd_ts">
                                <div class="stglbl">Forward Date
                                    <span style="float:right">:</span>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="stageVal">{{s.frwd_ts}}</div>
                            </div>



                            <div *ngIf="s.stg_cmnt_tx"
                                style="background: #f7f7fc; padding: 10px; font-size: 13px; margin: 8px;">
                                <div class="row">
                                    <div class="col-md-3">
                                        Stage commments
                                    </div>
                                    <div class="col-md-9">
                                        {{s.stg_cmnt_tx}}
                                    </div>


                                </div>

                            </div>


                        </div>

                    </div>

                </nz-timeline-item>
            </nz-timeline>

        </div>
    </nz-tab>
</nz-tabset>