<nz-layout  class="rmv_mrgn">
    <nz-layout>
      <nz-content>
        <div class="inner-content">
  
          <button nz-button (click)="closeSidebar();" *ngIf="opnSdebar == true" class="sdNavBtn rt-4h">
            <!-- <i nz-icon nzType="filter" nzTheme="outline"></i> -->
            Close</button>
          <button nz-button (click)="openSidebar();" *ngIf="opnSdebar == false" class="sdNavBtn2 rt-40">
            <i nz-icon nzType="filter" nzTheme="outline"></i>
            Filters</button>
          <div id="heatmap"></div>
        </div>
      </nz-content>
    </nz-layout>
  
  
    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null"  [nzWidth]="350" *ngIf="opnSdebar == true" class="sdr"> 
      <h2 class="sdHdr">HEATMAP
        <!-- <span nz-icon nzType="close-circle" nzTheme="outline" style="    float: right;
        margin-top: 3px;" (click) = "closedrvrlst()"></span> -->
      </h2>
      <form nz-form [formGroup]="heatform">

          <div class="row mg-b6">
              <div class="col-lg-6 col-md-6">
                  <nz-form-label nzRequired>Select {{title}}</nz-form-label>
              </div>
              <!-- <div class="col-lg-1 col-md-1">
                  <nz-form-label>:</nz-form-label>
              </div> -->
              <div class="col-lg-12 col-md-12">
                  <nz-form-item>
                      <nz-form-control>
                          <nz-select nzShowSearch [(ngModel)]="selectedtenants" (ngModelChange)="ontntSelects($event)" [ngModelOptions]="{standalone: true}" formControlName="ulbs">
                              <nz-option [nzLabel]="'Select All'" [nzValue]="0" *ngIf="showAll"></nz-option>
                              <!-- <nz-option [nzLabel]="'Deselect All'" [nzValue]="1"></nz-option> -->
                              <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
                          </nz-select>
                      </nz-form-control>
                  </nz-form-item>
              </div>
          </div>
        <div class="row mg-b6">
          <div class="col-lg-6 col-md-6">
            <nz-form-label nzRequired>Vehicle Group</nz-form-label>
          </div>
          <div class="col-lg-12 col-md-12">
            <nz-form-item>
              <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please select VehicleGroup"
                      formControlName="vhcl" (ngModelChange)="getCatLists($event)">
                      <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                      <nz-option *ngFor="let v of assrtGroupList" [nzLabel]="v.asrt_grp_nm"
                          [nzValue]="v.asrt_grp_id"></nz-option>
                  </nz-select>
              </nz-form-control>
          </nz-form-item>
          </div>
        </div>
        <div class="row mg-b6" *ngIf="clap_in==0">
          <div class="col-lg-6 col-md-6">
            <nz-form-label nzRequired>Vehicle Category</nz-form-label>
          </div>
          <div class="col-lg-12 col-md-12">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please select Vehicle" formControlName="vhcl" [(ngModel)]="asrtctry"
                 >
                  <nz-option *ngFor="let v of asrtCtgryList" [nzLabel]="v.asrt_ctgry_nm" [nzValue]="v.asrt_ctgry_id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
   
        <div class="row mg-b6">
          <div class="col-lg-6 col-md-6">
            <nz-form-label nzRequired>Date Range</nz-form-label>
          </div>
          <div class="col-lg-12 col-md-12">
            <nz-form-item>
              <nz-form-control>
                <nz-range-picker [(ngModel)]="date" [nzDisabledDate]="disabledDate" (ngModelChange)="onChange($event)" formControlName="dtrng"></nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <!-- <div class="row mg-b6">
          <div class="col-lg-4 col-md-4">
            <nz-form-label nzRequired>End Date</nz-form-label>
          </div>
          <div class="col-lg-8 col-md-8">
            <nz-form-item>
              <nz-form-control>
                <nz-date-picker  formControlName="endTm"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div> -->
        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn btnGrp">

            <!-- <button nz-button nzType="track" (click)=generatemap()>Generate Heat Map</button> -->
            <button nz-button nzType="primary" nzBlock (click)=generatemap()>Generate Heat Map</button>

          </div>
          <!-- <ds-loading *ngIf="showLdr"></ds-loading> -->
        </div>
  
  
  
      </form>
  
  
    </nz-sider>
  </nz-layout>
  
  
  
  
  
