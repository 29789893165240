<pg-header title="Users" ticon="idcard" sbtitle="User managmenet" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">


    <div class="ds-card grid">
        <dx-data-grid id="gridContainer" [dataSource]="usrsLst" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
            [showBorders]="true" style="width:100%" [allowColumnResizing]="true"
            (onToolbarPreparing)="onToolbarPreparing($event)">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
            </dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
            <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
                fileName="LMO Details"></dxo-export>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
                [width]="f.width" [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
                [allowHeaderFiltering]="f.filter"></dxi-column>
            <dxi-column [showInColumnChooser]="false" type="buttons"
                [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" [width]="100" caption="Edit"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="openSideBar('edit',data)">
                        Edit
                    </div>
                </div>
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" type="buttons"
                [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" [width]="100" caption="Delete"
                cellTemplate="deleteTemplate">
                <div *dxTemplate="let data of 'deleteTemplate'">
                    <div class="tblDelBtn" (click)="openSideBar('delete',data)">
                        Delete
                    </div>
                </div>
            </dxi-column>
        </dx-data-grid>
    </div>

    <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
        [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
        (nzOnClose)="closeSideBar()">

        <form nz-form [formGroup]="usrDtlsFrm" autocomplete="off">
            <div nz-row [nzGutter]="16">
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">First Name</div>
                    <input type="text" nz-input formControlName="fst_nm" class="br-4" />
                </div>
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Last Name</div>
                    <input type="text" nz-input formControlName="lst_nm" />
                </div>
            </div>
            <div nz-row [nzGutter]="16">
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Email</div>
                    <input type="email" nz-input formControlName="email" class="br-4" />
                </div>
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Mobile</div>
                    <input type="number" onKeyPress="if(this.value.length==10) return false;"  nz-input formControlName="mobile" (keyup)="phnoKeyUp()" autocomplete="off"/>
                    <span *ngIf="ermsg" class="nwErMsg">Please Select Valid Phone Number</span>
                </div>
            </div>
            <div nz-row [nzGutter]="16">
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Select Designation</div>
                    <nz-select style="width: 100%;" nzShowSearch nzAllowClear nzPlaceHolder="Select Designation"
                        formControlName="desgn">
                        <nz-option *ngFor="let d of dsgnsLst" [nzValue]="d.dsgns_id" [nzLabel]="d.dsgns_nm">
                        </nz-option>
                    </nz-select>
                </div>
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Select Department</div>
                    <nz-select style="width: 100%;" nzShowSearch nzAllowClear nzPlaceHolder="Select Department"
                        formControlName="dprt">
                        <nz-option *ngFor="let dp of dprtLst" [nzValue]="dp.dprt_id" [nzLabel]="dp.dprt_nm">
                        </nz-option>
                    </nz-select>
                </div>
            </div>
            <div nz-row [nzGutter]="16">
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Select Organisation</div>
                    <nz-select style="width: 100%;" nzShowSearch nzAllowClear nzPlaceHolder="Select Organisation"
                        formControlName="orgn">
                        <nz-option *ngFor="let o of orgnsLst" [nzValue]="o.orgn_id" [nzLabel]="o.orgn_nm">
                        </nz-option>
                    </nz-select>
                </div>
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Select Role</div>
                    <nz-select style="width: 100%;" nzShowSearch nzAllowClear nzPlaceHolder="Select Role"
                        formControlName="role">
                        <nz-option *ngFor="let r of rlesLst" [nzValue]="r.rle_id" [nzLabel]="r.rle_nm">
                        </nz-option>
                    </nz-select>
                </div>
            </div>
             <input type="password" name="someName" autocomplete="new-password" style="display:none" />
            <div nz-row [nzGutter]="16">
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">User Name</div>
                    <input type="text" nz-input formControlName="usernm" class="br-4" autocomplete="off" />
                </div>
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Password</div>
                    <input type="password" nz-input formControlName="pwd" (ngModelChange)="updateConfirmValidator()"
                        autocomplete="off" />
                </div>
            </div>
            <div *ngIf="invldPwd"><span>Password must have minimun 6 characters, at least one letter, one number and one special character.</span></div>
            <div nz-row [nzGutter]="16">
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn" *ngIf="!shwUsrEditDiv">
                    <div class="inptLbl">Confirm Password</div>
                    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl"
                        style="min-width: 100%;padding-left: 0px !important;padding-right: 0px !important;">
                        <input type="password" nz-input formControlName="cnfrmpwd" class="br-4" />
                        <ng-template #errorTpl let-control>
                            <ng-container *ngIf="control.hasError('confirm')">
                                Two passwords that you enter is inconsistent!
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </div>
                <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" class="usrmegn">
                    <div class="inptLbl">Image</div>
                    <div class="avatar-upload row">

                        <div class="avatar-preview">
                            <img class="avatar uploadImg" [src]="imageUrl" *ngIf="imageUrl != null">
                            <img class="avatar uploadImg" *ngIf="imageUrl == null"
                                src="assets/images/avatars/profile.jpg">
                            <!-- <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                            </div> -->
                        </div>
                        <label class="mt-2">
                            <input (change)="uploadFile($event)" type='file' id="imageUpload" accept=".png, .jpg, .jpeg"
                                #fileInput style="color: #1b75b6;" style="display:none" />
                            <span
                                class="btn btn-primary font-size-14 font-weight-600 ml-16 border-radius-3 uploadImgBtn">
                                <i nz-icon nzType="camera" nzTheme="fill" class="addBtn-icon-color"></i>
                                <!-- <mat-icon _ngcontent-qri-c39=""
                                    class="secondary-text s-24 mat-icon notranslate material-icons mat-icon-no-color vertical-align-text-bottom addBtn-icon-color"
                                    role="img" aria-hidden="true">add_a_photo</mat-icon> -->
                                Upload Image
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div nz-row [nzGutter]="16">
                <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                    <!-- <div class="inptLbl">Is Open on Saturday</div> -->
                    <label nz-checkbox formControlName="usr_msg_in" class="inptLbl">Need to send Login Cridentals to
                        user?</label>
                </div>
            </div>
            <button nz-button nzType="primary" (click)="saveUserData()" *ngIf="!shwUsrDelDiv"
                class="m-r-10">{{shwUsrEditDiv == true? 'Update' :'Submit'}}</button>
            <button nz-button nzType="primary" class="m-r-10" *ngIf="shwUsrEditDiv==false && shwUsrDelDiv==true"
                nz-popconfirm nzPopconfirmTitle="Are you sure delete this user?" nzPopconfirmPlacement="bottom"
                [nzIcon]="iconTpl" nzOkText="YES" nzCancelText="NO" (nzOnConfirm)="saveUserData()"
                (nzOnCancel)="cancel()">Delete</button>
            <ng-template #iconTpl>
                <i nz-icon nzType="question-circle-o" style="color: red;"></i>
            </ng-template>
            <button nz-button nzType="primary" (click)="closeSideBar()" nzDanger>Cancel</button>
        </form>
    </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
    <nz-result nzStatus="error" nzTitle="Access Denied"
        nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
        <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
    </nz-result>
</div>