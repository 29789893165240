import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppsService } from 'src/app/shared/services/apps.service';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent {
  @Input() header_name: { 
    name: string
  };
  // @Input() dte_pkr: { dtepkrlbl: boolean };
  clntDtls: any;
  userDtls: any;
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    // this.clap_in = this.userDtls.clap_in;
    // if (this.clap_in == 1) {
    //   this.ShowClap = true;
    // }
  }
}
