import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { CommonModule } from '@angular/common';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-ulb-trips-cvrge-dashboard',
  templateUrl: './ulb-trips-cvrge-dashboard.component.html',
  styleUrls: ['./ulb-trips-cvrge-dashboard.component.scss']
})
export class UlbTripsCvrgeDashboardComponent implements OnInit {
  value
  clntDtls: any;
  rdrctUlbDtls;
  tntLst: any = [];
  selectedtenant: any; 
  selectedtenantNm: any;
  ulb_id: any;
  userDtls: any;
  tdyCoveredTrips: any[];
  showLdr: boolean;
  curDate;
  slctdDt = new Date();
  today = new Date();
  dateFormat = 'dd/MM/yyyy';
  dbrd_ky = 'ULB'
  tripsData: any[];
  columnDefs: any = [];
  rowselected: any;
  curYr: string;
  toptripsData: any = [];
  topTripscolumnDefs: any = [];
  ttlColumnDefs: any[];
  cvrge_prcnt: number;
  avg_cvrg: string;
  rdrctTrpDtls
  tntCheck: any[];
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.rdrctUlbDtls = [];
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.rdrctUlbDtls = this.TransfereService.getLoclData('ulbDta');
    this.rdrctTrpDtls = this.TransfereService.getLoclData('trpDta')
    this.clntDtls[0].tnts.forEach(element => {
      this.tntLst.push(element);
    });
    if (this.rdrctUlbDtls) {
      this.ontntSelect(this.rdrctUlbDtls.tnt_id);
      this.slctdDt = this.rdrctUlbDtls.slctdDt;
    } else {
      this.selectedtenant = this.tntLst[0].tnt_id;
      this.selectedtenantNm = this.tntLst[0].tnt_dsply_nm;
    }
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    if (this.userDtls.rle_id == 37) {
      console.log(this.clntDtls)
      this.rowselected = {
        tnt_id: this.userDtls.tnt_id,
        slctdDt: new Date(),
        svm_apfs_cd: this.clntDtls[0].svm_apfs_cd,
        svm_id: this.clntDtls[0].svm_id
      }
      this.TransfereService.setLoclData('svmDta', this.rowselected);
      this.route.navigate([`internal/dashboard/svmtripCvrge-dashboard`]);
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  ngOnInit(): void {
    this.curYr = moment(this.today).format('YYYY');
    this.getCoveredTripsCount();
    this.getTripsData();
    setTimeout(() =>{
      this.get_lst7DaysDistanceDta();
    },1000)
    
    this.getTop5Trips();
  }
  ontntSelect(value) {
    if (value == 0) {
      this.selectedtenant = 0;
      this.selectedtenantNm = "All ULB's Selected";
      this.ulb_id = 0;
    }
    for (let i = 0; i < this.tntLst.length; i++) {
      console.log('in');
      if (this.tntLst[i].tnt_id == value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
        this.selectedtenantNm = this.tntLst[i].tnt_dsply_nm;
        // console.log(this.selectedtenant,this.selectedtenantNm  )
      }
    }
    this.setulbdta(value);
    this.getCoveredTripsCount();
    this.getTripsData();
    this.get_lst7DaysDistanceDta();
    this.getTop5Trips();
  }

  setulbdta(value){
    this.rdrctTrpDtls.forEach(val=>{
      if(value==val.tnt_id){
        this.TransfereService.ClearLocalData('ulbDta');
        this.TransfereService.setLoclData('ulbDta',val);
      }
    })
  }

  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.getCoveredTripsCount();
    this.getTripsData();
    this.get_lst7DaysDistanceDta();
    this.getTop5Trips();

  }
  getCoveredTripsCount(): any {
    this.tdyCoveredTrips = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: this.selectedtenant
    }
    let rte = `dashboard/enterprise/getCoveredTripsCount/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tdyCoveredTrips = res['data'];
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  getTripsData() {
    this.tntCheck = [2000006, 2000008, 2000009, 2000011, 1000101, 2000005, 2000010, 2000007]
    this.tripsData = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.ttlColumnDefs = [];
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: this.selectedtenant
    }
    let rte = `dashboard/enterprise/ulb/getCoveredTripsDetails`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tripsData = res['data'];

        this.cvrge_prcnt = 0
        this.tripsData.forEach(e => {
          this.cvrge_prcnt += e.coverage_per
        })
        this.avg_cvrg = (this.cvrge_prcnt / this.tripsData.length).toFixed(2)
        console.log(this.avg_cvrg)

        const checkCondition = this.tntCheck.every(tntId => {
          return this.clntDtls[0].tnts.some(tnt => tnt.tnt_id === tntId);
        })
        console.log(checkCondition)
        if (checkCondition) {
          this.columnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false, fixed: true },
            { headerName: 'ULB', field: 'ZONE', alignment: 'left', fixed: true },
            { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', fixed: true },
            { headerName: 'Ward', field: 'wrd_nm', alignment: 'left', fixed: true },
            { headerName: 'Vehicles', field: 'VEHICLES', alignment: 'center' },
            { headerName: 'Total Trips', field: 'TOTAL_TRIPS', alignment: 'center' },
            { headerName: 'Completed Trips', field: 'OPERATED', alignment: 'center' },
            { headerName: 'Started Trips', field: 'STARTED_TRIP', alignment: 'center' },
            { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', alignment: 'center', width: 150 },
            { headerName: 'Travle Distance(KM)', field: 'dist', alignment: 'center' },
            { headerName: 'Coverage %', field: 'coverage_per', alignment: 'left' },
          ]
        }
        else {
          this.columnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false, fixed: true },
            { headerName: 'ULB', field: 'ZONE', alignment: 'left', fixed: true },
            { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', fixed: true },
            // { headerName: 'Ward', field: 'wrd_nm', alignment: 'left', fixed: true },
            { headerName: 'Vehicles', field: 'VEHICLES', },
            { headerName: 'Total Trips', field: 'TOTAL_TRIPS',  alignment: 'center' },
            { headerName: 'Completed Trips', field: 'OPERATED',  alignment: 'center' },
            { headerName: 'Started Trips', field: 'STARTED_TRIP', },
            { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', },
            { headerName: 'Travle Distance(KM)', field: 'dist', },
            { headerName: 'Coverage %', field: 'coverage_per', },
          ]
        }
      }
      this.showLdr = false;
      this.ttlColumnDefs = [
        { headerName: 'Vehicles', field: 'TOT_VEHICLES', alignment: 'Center', summaryType: 'sum' },
        { headerName: 'Total Trips', field: 'TOTAL_TRIPS', alignment: 'Center', summaryType: 'sum' },
        { headerName: 'Completed Trips', field: 'OPERATED', alignment: 'Center', summaryType: 'sum' },
        { headerName: 'Started Trips', field: 'STARTED_TRIP', alignment: 'Center', summaryType: 'sum' },

        { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', alignment: 'Center', summaryType: 'sum' },
        { headerName: 'Travel Distance', field: 'dist', alignment: 'Center', summaryType: 'sum' },
        { headerName: 'Coverage %', field: 'coverage_per', alignment: 'Center', summaryType: 'avg', format: { type: 'fixedPoint', precision: 2 } },

      ]

    }, (err) => {
      // console.log(err);
    });
  }
  onCellPrepared(colDef, e) {

    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'svm_nm') {
      e.cellElement.style.color = 'rgb(0 142 255)';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
      e.cellElement.style.cursor = 'pointer';
    }

  }
  devCellclk(devclmns, dvclkent) {
    console.log(dvclkent);
    if (dvclkent.columnIndex == 2) {
      if (dvclkent.data.svm_nm != undefined) {
        this.rowselected = dvclkent.data;
        this.rowselected.slctdDt = this.slctdDt;
        console.log(this.rowselected)
        this.TransfereService.setLoclData('svmDta', this.rowselected);
        this.route.navigate([`internal/dashboard/svmtripCvrge-dashboard`]);
      }
    }
  }
  // lst7DayDstnc;
  get_lst7DaysDistanceDta(): any {
    let chart = am4core.create("coverageChart", am4charts.XYChart);
    chart.data = [];
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: this.selectedtenant
    }
    let rte = `dashboard/enterprise/trpsCvrgeLast7Days/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      chart.data = res['data'];
      console.log(chart.data);

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "dt";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.title.text = ` ----- Date(${this.curYr})----- `;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis.title.text = " ----- Covered % ----- ";
      chart.colors.list = [
        am4core.color("#f7babd"),
        // am4core.color("#7d64c4"),
      ]
      function createSeries(field, name) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "dt";
        series.name = name;
        series.columns.template.tooltipText = "{name} in {categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;
        series.columns.template.height = am4core.percent(100);
        series.sequencedInterpolation = false;

        let columnTemplate1 = series.columns.template;
        columnTemplate1.strokeWidth = 1;
        let valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{valueY}";
        valueLabel.label.fontSize = 15;
        valueLabel.label.dy = 10;
        columnTemplate1.column.cornerRadiusTopLeft = 5;
        columnTemplate1.column.cornerRadiusTopRight = 5;
        columnTemplate1.strokeOpacity = 1;

        let categoryLabel = series.bullets.push(new am4charts.LabelBullet());

      }

      createSeries("coverage_per", "Coverage");

    });
  }
  getTop5Trips() {
    this.toptripsData = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: this.selectedtenant
    }
    let rte = `dashboard/enterprise/getTop5Coverages/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.toptripsData = res['data'];

        this.topTripscolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB', field: 'ZONE', alignment: 'left', width: 200 },
          { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', width: 200 },
          { headerName: 'Coverage %', field: 'coverage_per', alignment: 'Center', width: 'auto' },
        ]
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });

  }

  onExporting1(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Ward Secreterat wise Trip Coverage Status.xlsx');
      });
    });
  }

  onExporting2(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Zone wise Top 10 Coverages.xlsx');
      });
    });
  }

  goBack() {
    this.TransfereService.ClearLocalData('ulbDta');
    this.TransfereService.ClearLocalData('trpDta');
    this.route.navigate([`internal/dashboard/tripCvrge-dashboard`]);
  }
}
