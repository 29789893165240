<ds-loading *ngIf="showLdr"></ds-loading>
<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visibleVhclSldr" nzPlacement="right"
  nzTitle="Vehicle Dashboard" (nzOnClose)="visibleVhclSldr = false" class="dashbSlider">
  <ng-container *nzDrawerContent>
    <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
  </ng-container>
</nz-drawer>
<div class="maindv">
  <!-- <div class="row hdrBar">
    <div class="col-lg-12 col-md-12 mg-a">
      <h1>Vehicles</h1>
    </div>
  </div> -->

  <!-- <app-common-header [header_name] = "header_label"></app-common-header> -->


  <div class="page-header">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-12 col-md-12 mg-a">
                <h1 *ngIf="clntDtls && clntDtls.length > 0">
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>

        </div>

    </div>
</div>

  <div class="row ctgrs_rw">
    <div class="col-md-12">
      <div class="categories_title">
        Categories
      </div>
    </div>
    <div class="col-md-12">
      <div class="categories_lst">
        <ul>
          <li *ngIf="allCategories" [ngClass]="allCategories[0].crdChnge == true ? 'brdr-bl' : 'brdr-none'"
            (click)="onCardSelect()">All Categories <span>{{allCategories[0].ct}}</span></li>
          <li *ngFor="let v of ctgryCardcntSummary" [ngClass]="v.crdChnge == true ? 'brdr-bl' : 'brdr-none'"
            (click)="onctgrySelect(v)">{{v.asrt_ctgry_nm}}
            <span>{{v.ct}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row crd_rw">
    <div class="col-md-4">
      <div class="statuscrd">
        <div class="crd_title">
          Vehicle Running Status
        </div>
        <div class="row">
          <div class="col-md-6 rgtbrdr">
            <div class="rungcnts" (click)="rnSlct()">
              <div class="cntsnbr">{{rn_veh_ct}}</div>
              <div class="cntstitle">Running</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="rungcnts" (click)="prkdSlct()">
              <div class="cntsnbr">{{prkd_veh_ct}}</div>
              <div class="cntstitle">Parked</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="lctncrd">
        <div class="crd_title">
          Locations
        </div>
        <div class="row" *ngIf="vhclLctnCnts">
          <div class="col-md-4 rgtbrdr">
            <div class="rungcnts" *ngFor="let v of vhclLctnCnts" (click)="onLctnSlct(v)">
              <div class="cntsnbr">{{vhclLctnCnts[0].Veh_Vhyrd}}</div>
              <div class="cntstitle">Vehicle Yard</div>
            </div>
          </div>
          <div class="col-md-4 rgtbrdr">
            <div class="rungcnts">
              <div class="cntsnbr">{{vhclLctnCnts[0].Veh_dmpyrd}}</div>
              <div class="cntstitle">Dumping Yard</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="rungcnts">
              <div class="cntsnbr">{{vhclLctnCnts[0].Veh_othr}}</div>
              <div class="cntstitle">Others</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


  <!-- <div class="fltrbg">
    <div class="row">

      <div class="col-lg-3 col-md-3 ">
        <p class="slctlbl">ULB</p>
        
      </div>
      <div class="col-lg-3 col-md-3">
        <p class="slctlbl">Vehicle Vendor</p>
       
      </div>

      <div class="col-lg-3 col-md-3">
        <p class="slctlbl">Vehicle Category</p>
       
      </div>
    
    </div>

  </div> -->

  <!-- cards counts -->
  <!-- <div class="row">
    <div class="col-md-12">
      <div class="vhclests">
        <ul>
         
          <ul>
            <li *ngIf = "shwttlvhcle">Total Vehicles<span class="stscount">{{totveh}}</span></li>
            <li *ngFor="let c of cardcntSummary">{{c.asrt_sts_nm}}<span>{{c.ct}}</span></li>
          </ul>



        </ul>
      </div>
    </div>
  </div> -->

  <div class="fltrbgCrd">
    <div class="abs_cntr">
      <div class="row jstfycls">
        <div class="col-lg-2 col-md-2" [ngClass]="c.crdChnge == true ? 'crd-hding-chnge' : 'crd-hding'"
          *ngFor="let c of cardcntSummary" (click)="cardchnge(c.asrt_sts_id)">
          <div><b class="cntsCls">{{c.ct}}</b></div>
          <div class="crd-sb-hding">{{c.asrt_sts_nm}}</div>
        </div>

      </div>
    </div>
  </div>

  <div class="fltrbg">
    <div class="row">

      <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst.length>1">
        <p class="slctlbl">{{hdrLbl}}</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
          [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
          <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
        </nz-select>
      </div>
      <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst && tntLst.length==1">
        <p class="slctlbl">{{hdrLbl}}</p>
        <p class="slctlbl dsblTxt">{{tntLst[0].tnt_nm}}</p>
      </div>
      <div class="col-lg-2 col-md-2 crdCl" *ngIf="ShowClap">
        <p class="slctlbl">Vehicle Vendor</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
          [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
          <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
        </nz-select>
      </div>
      <div class="col-lg-2 col-md-2 crdCl">
        <p class="slctlbl">Vehicle Category</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedctgry" (ngModelChange)="onctgrySelect($event)"
          [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
          <nz-option *ngFor="let v of vehctgryLst" [nzValue]="v.asrt_ctgry_id" [nzLabel]="v.asrt_ctgry_nm"></nz-option>
        </nz-select>
      </div>
      <div class="col-lg-2 col-md-2 crdCl">
        <p class="slctlbl">Search Vehicle</p>
        <nz-input-group>
          <input type="text"
            style="height: 32px;border-radius: 3px !important; background: linear-gradient(135deg, #ffffff 0%, #e7eaec 100%);border: 1px solid #d9d9d9;"
            nz-input [(ngModel)]="searchText" placeholder="Search here" [ngModelOptions]="{standalone: true}" />
        </nz-input-group>
      </div>
      <div class="col-lg-2 col-md-2 crdCl">
        <p class="slctlbl">Change View</p>
        <div class="crdndtblVw"> <span>Cards View</span>
          <nz-switch [(ngModel)]="switchValue" [ngModelOptions]="{standalone: true}" (ngModelChange)="gridview()"
            class="tgleswtch"></nz-switch>
      <span>Table View</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 crdCl">
        <p class="slctlbl">Export from here</p>
        <div class="btnsgrp">
          <button nz-button nzType="default" class="excelbtn" (click)="export()"><i nz-icon nzType="file-excel"
              nzTheme="outline"></i>Excel</button>
          <button nz-button nzType="default" class="pdfbtn" (click)="createPdf()"><i nz-icon nzType="file-pdf"
              nzTheme="outline"></i>Pdf</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row srchRw">
    <div class="col-xl-6 col-lg-6 col-md-6">
      <nz-input-group>
        <input type="text" style="padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);" nz-input [(ngModel)]="searchText"
          placeholder="Search here" [ngModelOptions]="{standalone: true}" />
      </nz-input-group>
    </div>
    <div class="col-xl-1 col-lg-1 col-md-1"></div>
    <div class="col-xl-3 col-lg-3 col-md-3">

      <div class="crdndtblVw"> <span>Cards View</span>
        <nz-switch [(ngModel)]="switchValue" [ngModelOptions]="{standalone: true}" (click)="gridview()"
          class="tgleswtch"></nz-switch>
        <span>Table View</span>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2">
      <div class="btnsgrp">
        <button nz-button nzType="default" class="excelbtn">Excel</button>
        <button nz-button nzType="default" class="pdfbtn">Pdf</button>
      </div>
    </div>
  </div> -->
  <div style="padding: 0px 10px;">
    <div class="row" *ngIf="crdsview">
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6" *ngFor="let v of vhcleslst | filterasrt:searchText">
        <div class="card_g">
          <div class="card_content">
            <div class="row">
              <div class="col-lg-8 col-md-8" style="padding: 0px;">
                <h1 class="crdHdr">{{v.asrt_nm}}
                  <img class="key" src="../../../assets/images/spark-green.png"
                    *ngIf="v.igntn_in == 1 || v.igntn_in ==  0">
                  <img class="key" src="../../../assets/images/spark-red.png" *ngIf="v.igntn_in == null">
                  <!-- <span [ngClass]="{'red-dot':v.igntn_in == null ,'green-dot':v.igntn_in == 1 || v.igntn_in ==  0}">Ignition</span> -->
                </h1>
                <h1 class="crdHdr_sb"><span class="pdnglft">{{v.ulb_nm}}</span><span>|</span><span
                    class="pdnglft">{{v.district}}</span><span>|</span><span
                    class="pdnglft">{{v.asrt_ctgry_nm}}</span><span>|</span><span
                    class="pdnglft">{{v.asrt_grp_nm}}</span><span>|</span>&nbsp;

                </h1>
                <!-- <h1 class="card_sub_title"><span class="pdnglft">{{v.ulb_nm}}</span><span>|</span><span
                  class="pdnglft">{{v.district}}</span><span>|</span><span
                  class="pdnglft">{{v.asrt_ctgry_nm}}</span><span>|</span><span class="pdnglft">{{v.asrt_grp_nm}}</span>
                </h1> -->
              </div>
              <!-- <div class="col-lg-1 col-md-1" (click)="openslider(v)">
                <div class="first_tooltip">
                  <i nz-icon nzType="rollback" nzTheme="outline" class="icnHvr"></i>
                  <span class="first_tooltip_text">Playback</span>
                </div>
              </div> -->
              <div class="col-lg-1 col-md-1" *ngIf="v.frnt_cmra_url">
                <div class="first_tooltip">
                  <a target="_blank" [href]=v.frnt_cmra_url>
                    <i nz-icon nzType="camera" nzTheme="outline" class="icnHvr"> </i>

                  </a>
                  <span class="first_tooltip_text">Front Camera</span>
                </div>
              </div>
              <div class="col-lg-1 col-md-1" *ngIf="v.bck_cmra_url">
                <div class="first_tooltip">
                  <a target="_blank" [href]=v.bck_cmra_url>
                    <i nz-icon nzType="camera" nzTheme="outline" class="icnHvr"> </i>
                  </a>
                  <span class="first_tooltip_text">Rear Camera</span>
                </div>
              </div>
              <div class="col-lg-1 col-md-1" (click)="openvhcldtlsfrm(v)">
                <div class="first_tooltip">
                  <i nz-icon nzType="edit" nzTheme="outline" class="icnHvr"></i>
                  <span class="first_tooltip_text">Edit Vehicle</span>
                </div>
              </div>
              <div class="col-lg-1 col-md-1" (click)="drvrasgndtls(v)">
                <div class="second_tooltip">
                  <i nz-icon nzType="user-add" nzTheme="outline" class="icnHvr"></i>
                  <span class="second_tooltip_text">Assign driver</span>
                </div>
              </div>
              <div *ngIf="wrdlst && wrdlst.length>0" class="col-lg-1 col-md-1" (click)="openwardlstfrm(v)">
                <div class="first_tooltip">
                  <!-- <i nz-icon nzType="LeftOutlined" nzTheme="outline" class="icnHvr"></i> -->
                  <i nz-icon nzType="project" nzTheme="outline" class="icnHvr"></i>
                  <span class="first_tooltip_text">Assign Ward</span>
                </div>
              </div>
            </div>
            <div class="row crdHdr_sb_sm">
              <div class="col-lg-3 col-md-3 usr">
                <img src="../../../assets/images/markers/mapIcons/{{v.icon_pathr}}.png">
                <!-- <span [ngClass]="{'red-dot':v.igntn_in == null ,'green-dot':v.igntn_in == 1 || v.igntn_in ==  0}">Ignition</span> -->
              </div>
              <div class="col-lg-9 col-md-9 usrDtls">
                <div class="row">
                  <div class="col-lg-1 col-md-1 mg-a">
                    <i nz-icon nzType="pushpin" nzTheme="outline"></i>
                  </div>
                  <div class="col-lg-11 col-md-11 mg-a">
                    <h1 class="card_sub_title">{{(!v.area_nm || v.area_nm == "undefined" || v.area_nm == null) ? 'NA':
                      v.area_nm}}</h1>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-1 col-md-1 mg-a">
                    <i nz-icon nzType="environment" nzTheme="outline"></i>
                  </div>
                  <div class="col-lg-11 col-md-11 mg-a">
                    <h1 class="card_sub_title">{{(!v.loc_nm || v.loc_nm == "undefined" || v.loc_nm == null) ? 'NA':
                      v.loc_nm}}</h1>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-1 col-md-1 mg-a">
                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                  </div>
                  <div class="col-lg-11 col-md-11 mg-a">
                    <h1 class="card_sub_title"><span>{{v.startFence}}</span><span>&nbsp;<i nz-icon nzType="arrow-right"
                          nzTheme="outline"></i>&nbsp;</span><span>{{v.endFence}}</span></h1>
                  </div>
                </div>
                <div class="row" style="cursor: pointer;">
                  <div class="col-lg-1 col-md-1 mg-a">
                    <i nz-icon [nzType]="'control'" [nzTheme]="'outline'"></i>
                  </div>
                  <div class="col-lg-11 col-md-11 mg-a">
                    <h1 class="card_sub_title">Odometer Reading <span> | </span><span>{{v.odmtr_ct === null? 0:
                        v.odmtr_ct }}</span> <span>Kms</span></h1>
                  </div>
                </div>
                <div class="row" (click)="getVehDashSldr(v)" style="cursor: pointer;">
                  <div class="col-lg-1 col-md-1 mg-a">
                    <i nz-icon [nzType]="'dashboard'" [nzTheme]="'outline'" style="color:blue;"></i>
                  </div>
                  <div class="col-lg-11 col-md-11 mg-a">
                    <h1 class="card_sub_title" style="color:blue;">Quick view</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- driver assignment -->
      <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="380" [nzVisible]="visible"
        nzTitle="Driver Details" (nzOnClose)="closedrvrlst()" *ngIf="drvrasgn">
        <ng-container *nzDrawerContent>
          <div class="row">
            <div class="col-md-12">
              <div class="asgndrvrtitle">
                Assign Driver
              </div>
            </div>
          </div>

          <div class="vehicleno">
            <div class="row">
              <div class="col-md-6 vhclelbl">
                Vehicle Number
              </div>
              <div class="col-md-6 vhclNbrClr">
                {{vhclenumbr}}
              </div>
            </div>
          </div>
          <form nz-form class="drvrDtlsfrm">
            <div nz-row [nzGutter]="8">

              <div nz-col nzSpan="24">
                <nz-form-label>Select Date</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <nz-date-picker class="fulwdth" [(ngModel)]="dte" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onDateChng();" nzFormat="dd-MM-yyyy"></nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="24">
                <nz-form-label>Select Trip no</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select trip" [(ngModel)]="trp_nu"
                      [ngModelOptions]="{standalone: true}" nzMode="multiple">
                      <nz-option *ngFor="let t of trpslst" [nzLabel]=t.trp_sqnc_nu [nzValue]="t.trp_rn_id"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="24">
                <nz-form-label>Select Driver</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select driver" [(ngModel)]="crw_id"
                      [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let v of drvrslst" [nzLabel]=v.crw_nm [nzValue]="v.crw_id"></nz-option>

                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </form>

          <div class="btngrp">
            <button nz-button style="margin-right: 8px;" (click)="closedrvrlst()" nzType="primary"
              nzDanger>Cancel</button>
            <button nz-button (click)="saveasgnmnt()" class="sbmtbtn">Submit</button>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="asgndrvrtitle mrgntp">
                Driver Details
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table" id="customers">
                <tr>
                  <th>Driver Name</th>
                  <th>Driver Phone No</th>
                  <th>Date</th>
                </tr>
                <tr *ngFor="let a of asgndrvrs">
                  <td>{{a.crw_nm}}</td>
                  <td>{{a.crw_ph}}</td>
                  <td>{{a.dte}}</td>
                </tr>
              </div>
            </div>
          </div>
        </ng-container>
      </nz-drawer>
      <!-- driver assignment -->
      <!-- vehicle detilas -->
      <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="500" [nzVisible]="visible"
        nzTitle="Edit Vehicle Details" (nzOnClose)="closedrvrlst()" *ngIf="vhcledtls">

        <ng-container *nzDrawerContent>

          <form nz-form [formGroup]="vhcldtlsform" class="drvrDtlsfrm">
            <div nz-row [nzGutter]="8">


              <div nz-col nzSpan="24">
                <nz-form-label>Vehicle Number</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input formControlName="veh_nu" placeholder="Vehicle Number" />
                  </nz-form-control>
                </nz-form-item>
              </div>



              <div nz-col nzSpan="24">
                <nz-form-label>Vehicle Group</nz-form-label>
                <nz-form-item>
                  <nz-form-control>

                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Group" formControlName="veh_grp"
                      (ngModelChange)="onvehGrpSelect($event)">
                      <nz-option *ngFor="let v of vhcleGrp" [nzLabel]=v.asrt_grp_nm [nzValue]="v.asrt_grp_id">
                      </nz-option>

                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="24">
                <nz-form-label>Vehicle Category</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Category" formControlName="veh_ctgry">
                      <nz-option *ngFor="let v of vehctgryLst" [nzLabel]=v.asrt_ctgry_nm [nzValue]="v.asrt_ctgry_id">
                      </nz-option>

                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div nz-col nzSpan="24">
              <nz-form-label>Vehicle Department</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Department" formControlName="veh_dprtmnt">
                    <nz-option *ngFor="let v of vhcleDprtmnt" [nzLabel]=v.dprt_nm [nzValue]="v.dprt_id"></nz-option>

                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div> -->

              <div nz-col nzSpan="24">
                <nz-form-label>Status</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Status" formControlName="veh_sts"
                      (ngModelChange)="onstatusChange(event)">
                      <nz-option *ngFor="let v of vehicleSts" [nzLabel]=v.asrt_sts_nm [nzValue]="v.asrt_sts_id">
                      </nz-option>

                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div nz-col nzSpan="24">
              <nz-form-label>Regular/Spare</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Regular/Spare" formControlName="veh_spare">
                    <nz-option nzLabel=Regular nzValue=0></nz-option>
                    <nz-option nzLabel=Spare nzValue=1></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div> -->

              <div class="row fulwdth">
                <div class="col-md-12">
                  <div class="asgndrvrtitle">
                    Details
                  </div>
                </div>
              </div>

              <div nz-col nzSpan="24">
                <div>Alerts can be generated if the vehicle doesn’t operate these many trips before reaching back to the
                  Home Location/vehicle yard</div>
                <nz-form-label>Minimum Trips to operate</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input placeholder="Trips" formControlName="veh_trps" />
                  </nz-form-control>
                </nz-form-item>
              </div>




              <div nz-col nzSpan="24">
                <div>Speed Limit for Vehicle</div>
                <nz-form-label>Speed limit in Km/hr</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input placeholder="Speed" formControlName="veh_spd" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="24">
                <div>Maximum Distance to be travelled By Vehicle</div>
                <nz-form-label>Distance</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input placeholder="Distance" formControlName="veh_dstnce" />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="24">
                <nz-form-label>UnAuthorized Stoppage Time</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input placeholder="UnAuthorized Stoppage Time" formControlName="veh_stpg_tm" />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="24">
                <nz-form-label>Milage in Km/liter</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input placeholder="Milage in Km/liter" formControlName="veh_mlge" />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="24">
                <div> Home location is the location where the vehicle stays and Starts its first Trip everyday(ex:
                  Vehicle
                  yard)</div>
                <nz-form-label>Home Location</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <!-- <input nz-input placeholder="Select Home Location" formControlName="hme_fnce_id" /> -->
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Home Location"
                      formControlName="hme_fnce_id" (ngModelChange)="onstatusChange(event)">
                      <nz-option *ngFor="let f of fncsLst" [nzLabel]=f.fnce_nm [nzValue]="f.fnce_id">
                      </nz-option>

                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="24">
                <div>This it the time before which the Vehicle have to start leaving it’s Home Location/vehicle yard in
                  a
                  day for collection.</div>
                <nz-form-label>Start Time</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <!-- <input nz-input placeholder="Start Time" formControlName="strt_tm" /> -->
                    <nz-time-picker nzShowTime formControlName="strt_tm" [nzFormat]="timeFormat">
                    </nz-time-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="24">
                <div>Notify Time Interval is the maximum delay in minutes from above start time, for the Vehicle to exit
                  from Home Location/vehicle yard.</div>
                <nz-form-label>Start Notify Time Interval(Minutes)</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input placeholder="Enter Time Interval" formControlName="ntfy_intrvl_tm" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>



          </form>


          <div class="btngrp">
            <button nz-button style="margin-right: 8px;" (click)="closedrvrlst()" nzType="primary"
              nzDanger>Cancel</button>
            <button nz-button (click)="updateVehicleData()" class="sbmtbtn"
              *ngIf="vhcldtlsform.status == 'VALID'">Submit</button>
          </div>
        </ng-container>

      </nz-drawer>
      <!-- vehicle details -->
      <!--ward Assign-->
      <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="500" [nzVisible]="visible"
        nzTitle="Edit Ward Details" (nzOnClose)="closervrlst()" *ngIf="warddtls">
        <ng-container *nzDrawerContent>
          <form nz-form [formGroup]="warddtlsform" class="drvrDtlsfrm">
            <div nz-row [nzGutter]="8">
              <div nz-col nzSpan="24">
                <nz-form-label>Vehicle Number</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input formControlName="veh_nu" placeholder="Vehicle Number" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="24">
                <nz-form-label>Ward</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Category" formControlName="wrd_nm">
                      <nz-option *ngFor="let v of wrdlst" [nzLabel]=v.wrd_nm [nzValue]="v.wrd_id">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </form>
          <div class="btngrp">
            <button nz-button style="margin-right: 8px;" (click)="closervrlst()" nzType="primary"
              nzDanger>Cancel</button>
            <button nz-button (click)="updateWardData()" class="sbmtbtn"
              *ngIf="warddtlsform.status == 'VALID'">Submit</button>
          </div>
        </ng-container>
      </nz-drawer>
    </div>

    <!-- <div class="row" *ngIf="tblview">
      <div class="col-md-12">
        <nz-table #middleTable nzSize="middle" class="drvrlstTbl" [nzData]="vhcleslst" [nzPageSize]="10">
          <thead>
            <tr>
              <th>Sno</th>
              <th>Vehicle No</th>
              <th>ULB Name</th>
              <th>District</th>
              <th>Category</th>
              <th>Group</th>
              <th>Area</th>
              <th>Location</th>
              <th>Start Fence</th>
              <th>End Fence</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let v of vhcleslst | filter:searchText let i=index">
              <td>{{i + 1}}</td>
              <td>{{v.asrt_nm}}</td>
              <td>{{v.ulb_nm}}</td>
              <td>{{v.district}}</td>
              <td>{{v.asrt_ctgry_nm}}</td>
              <td>{{v.asrt_grp_nm}}</td>
              <td>{{v.area_nm}}</td>
              <td>{{v.loc_nm}}</td>
              <td>{{v.startFence}}</td>
              <td>{{v.endFence}}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div> -->


    <div class="ds-card grid tbl dwntbl vhclsCmpnt" *ngIf="tblview">
      <dx-data-grid class="p-8" [dataSource]="vhcleslst" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true"
        [showBorders]="true" style="width:100%" (onToolbarPreparing)="true">
        <dxo-paging [pageSize]="10" [enabled]="true"></dxo-paging>
        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
          [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Vehicles List Data"></dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxi-column *ngFor="let v of vhclslstcolumnDefs" [visible]="!v.hide" [dataField]="v.field"
          [caption]="v.headerName" [alignment]="v.alignment" [width]="v.width" [allowResizing]="true"></dxi-column>
        <!-- <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons"
          [width]="100" caption="Edit" cellTemplate="editTemplate">
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons"
          [width]="100" caption="Delete" cellTemplate="delTemplate">
        </dxi-column> -->
      </dx-data-grid>
    </div>
  </div>

  <!-- <nz-drawer [nzWidth]="'22%'" [nzClosable]="true"
    [nzVisible]="VhclSldr" nzPlacement="right" nzTitle="Cameras" (nzOnClose)="VhclSldr = false" class="dashbSlider">
    <div> 
      <h1>{{pbckData.asrt_nm}}</h1>
    </div>

      <div class="row">
        <div class="col-lg-3 col-md-3 mg-a">
          <a target="_blank" [href]="urlSafe">Full Screen</a>        
        </div>
        <div class="col-lg-9 col-md-9 mg-a">
          <div class="row hdrBar"><span></span>  Front View </div>
        </div>
      </div>
      <div> 
        <iframe width="100%" height="70%" frameBorder="2" [src]="urlSafe"></iframe>
      </div>

      
      <div class="row">
        <div class="col-lg-3 col-md-3 mg-a"> -->
  <!-- <button nz-button  (click)="fullscreen(2,urlSafe2)" nzType="primary">Fullscreen</button> -->
  <!-- <a [href]="urlSafe2">FullScreen</a> -->
  <!-- <a (click)="goToLink({{urlSafe2}}">FullScreen</a> -->
  <!-- <a target="_blank" [href]="urlSafe">Full Screen</a>  


        </div>
        <div class="col-lg-9 col-md-9 mg-a">
          <div class="row hdrBar"><span></span>  Rear View </div>
        </div>
      </div>
      <div> 
        <iframe width="100%" height="70%" frameBorder="2" [src]="urlSafe2"></iframe>
      </div>
  </nz-drawer> -->
</div>