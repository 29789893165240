
<pg-header title="ULBs" ticon="database" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">
  <div>
    <div class="ds-card grid">
      <ds-loading *ngIf="shwLdg"></ds-loading>
      <dx-data-grid id="gridContainer" [dataSource]="ulbLst" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true"
        style="width:100%" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
        </dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
        <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true" fileName="LMO Details"></dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [width]="f.width" [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
          [allowHeaderFiltering]="f.filter" [visible]="f.visible"></dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" type="buttons" [width]="100" caption="Edit"
          cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="cursor-pointer tblEdtBtn" (click)="onCellClick('edit',data)">
              Edit
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons" [width]="100" caption="Delete"
          cellTemplate="delTemplate">
          <div *dxTemplate="let data of 'delTemplate'">
            <div class="cursor-pointer tblDelBtn" (click)="onCellClick('delete',data)">
              Delete
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>

  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
    (nzOnClose)="closeSideBar()">
    <div class="mcrpckMrgn">
      <div class="inptLbl">ULB Name</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="text" nz-input [(ngModel)]="ulbDt.ulbNm" placeholder="please enter ulb name" />
      </nz-input-group>
    </div>
    <!-- <div class="inptLbl">ULB Api ID</div>
        <nz-input-group [nzSuffix]="inputClearTpl">
          <input type="text" nz-input [(ngModel)]="ulbDt.apiId" placeholder="please enter ulb api id" />
        </nz-input-group>
        <div class="inptLbl">ULB Code</div>
        <nz-input-group [nzSuffix]="inputClearTpl">
          <input type="text" nz-input [(ngModel)]="ulbDt.cd" placeholder="please enter ulb code" />
        </nz-input-group> -->
    <div class="mcrpckMrgn">
      <div class="inptLbl">Lat</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="text" nz-input [(ngModel)]="ulbDt.lat" placeholder="please enter ulb lattitude" />
      </nz-input-group>
    </div>

    <div class="mcrpckMrgn">
      <div class="inptLbl">Lng</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="text" nz-input [(ngModel)]="ulbDt.lng" placeholder="please enter ulb longitude" />
      </nz-input-group>
    </div>

    <div class="mcrpckMrgn">
      <div class="inptLbl">District</div>
      <input placeholder="Select District" nz-input [(ngModel)]="ulbDt.dstrct_nm" (ngModelChange)="onChange($event)"
        [nzAutocomplete]="auto" />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let d of filteredOptions" [nzValue]="d.dstrt_nm" (click)="getDstrtID(d.dstrt_id);">
          {{d.dstrt_nm}}</nz-auto-option>
      </nz-autocomplete>
    </div>
    <ng-template #inputClearTpl><i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
        *ngIf="ulbDt.ulbNm" (click)="ulbDt = {}"></i></ng-template>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveULB()"
      *ngIf="shwULBEditDiv==false && shwULBDelDiv==false">Submit</button>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEditULB()"
      *ngIf="shwULBEditDiv==true && shwULBDelDiv==false">Update</button>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="shwULBEditDiv==false && shwULBDelDiv==true" nz-popconfirm
      nzPopconfirmTitle="Are you sure delete this ULB?" nzPopconfirmPlacement="bottom" (nzOnConfirm)="saveDeleteULB()"
      (nzOnCancel)="cancel()">Delete</button>
    <button nz-button nzType="default" class="cnclBtn" (click)="oncancel()" *ngIf="shwSidebar">Cancel</button>
  </nz-drawer>
  <!-- </div>
  </div>
</div> -->
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
  </nz-result>
</div>