<div class="dashboard" #fullScreen style="min-height: 20vh;">
    <!-- <div style="text-align: center;">
        <p class="titlehDng">Grama-Ward Sachivalayam</p>
    </div> -->
    <div class="page-header">
        <div class="row align-items-md-center">
          <div class="col-md-8">
            <div class="media m-v-10">
              <!--<i nz-icon [nzType]="ticon" nzTheme="twotone" class="header-icon"></i>&nbsp;-->
              <div class="media-body m-l-15 header-border">
                <h6 class="mb-0 header-label">Grama-Ward Sachivalayam</h6>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <!-- <nz-button-group style="float: right;margin-top: -5px;">
              <button (click)="openFullscreen()" nz-button nzType="primary"><i nz-icon nzType="expand"></i></button>
            </nz-button-group> -->
          </div>
    
        </div>
      </div> <!-- Header End -->

    <div class="row" style="margin-bottom: 20px; color:white">
        <div nzTitle="Department" class="col-lg-3 col-md-4 col-sm-12 scvlmcards" >
            <div style="    background-color: #92218c; border-radius: 5px; box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);">
                <div class="cardIn"><p class="bcgrnd-color">Total Service Requests</p><p class="value bcgrnd-color">{{dptWseTbleDatatotSrvReq}}</p></div>
                
            </div>
        </div>
        <div nzTitle="Department" class="col-lg-3 col-md-4 col-sm-12 scvlmcards" >
            <div style="background-color: #337ab7; border-radius: 5px; box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);">
                
                <div class="cardIn"><p class="bcgrnd-color">Total Open With in SLA</p><p class="value bcgrnd-color">{{dptWseTbleDatatotWtSla}}</p></div>
            
            </div>
        </div>
        <div nzTitle="Department" class="col-lg-3 col-md-4 col-sm-12 scvlmcards">
            <div  style="    background-color: #5d33b7; border-radius: 5px; box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);">
                
                <div class="cardIn"><p class="bcgrnd-color">Total Open Beyond SLA</p><p class="value bcgrnd-color">{{dptWseTbleDatatotBySla}}</p></div>
                
            </div>
        </div>
        <div nzTitle="Department" class="col-lg-3 col-md-4 col-sm-12 scvlmcards">
            <div  style="    background-color: #b76133; border-radius: 5px; box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);">
                
                <div class="cardIn"><p class="bcgrnd-color">Total Closed</p><p class="value bcgrnd-color">{{dptWseTbleDatatotClsWtSla + dptWseTbleDatatotClsBySla}}</p></div>
                
            </div>
        </div>

    </div>
    

    <ng-template #dateTemplate>
        <div style="display:flex;flex-direction:row;justify-content: flex-end;align-items: center;color:black">
            <p>Select Date Range :&nbsp;</p>
            <!-- <nz-range-picker [nzFormat]="dateFormat" [(ngModel)]="dateRange" (ngModelChange)="onDtChange($event)" style="height: 30px;
            margin-bottom: 15px;"></nz-range-picker> -->
            <nz-range-picker [nzFormat]="dateFormat" [(ngModel)]="dateRange" (ngModelChange)="onDtChange($event)" style="height: 30px;
            margin-bottom: 15px;"></nz-range-picker>
            <!-- <nz-date-picker nzFormat="dd-MM-yyyy" [nzDisabledDate]="disabledDate" [(ngModel)]="datePk"
                (ngModelChange)="onDtChange($event)"></nz-date-picker>
            <br /> -->
        </div>
      </ng-template>


    
    
    
    <nz-tabset nzType="card" style="text-align: left;"  [nzTabBarExtraContent]="dateTemplate">
        <nz-tab [nzTitle]="'Department Wise'">
        <input type="text" 
         placeholder="search here" 
         (input)="getItems($event)" style="margin-bottom: 10px;
         padding: 4px 8px;
         border: 1px solid #243282;
         box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%); " class="srchinpt">
            <nz-table #dptTbl nzShowSizeChanger [nzShowPagination]="true" [nzHideOnSinglePage]="true" [nzPageSize]="10000" [nzData]="dptWseTbleData" [nzLoading]="depLoader" style="min-height: 25vh;"
                 class="gramsvm-table dptTbl">
                 <!-- <nz-table #rowSelectionTable nzShowSizeChanger [nzShowPagination]="false" [nzData]="srvcWseTbleData" [nzLoading]="servLoader"  style="min-height: 25vh;"
                 class="gramsvm-table srvcTbl "> -->
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Department Name</th>
                        <th [nzSortFn]="sortSerReqFn">No Of Service Requests</th>
                        <th [nzSortFn]="sortOpenWthSla">Open With in SLA</th>
                        <th [nzSortFn]="sortOpenByndSla">Open Beyond SLA</th>
                        <th [nzSortFn]="sortOpenByndSlaPer">Open Beyond SLA %</th>
                        <th [nzSortFn]="sortClsWthSla">Closed With in SLA</th>
                        <th [nzSortFn]="sortClsWthSlaPer">Closed With in SLA %</th>
                        <th [nzSortFn]="sortClsByndSla">Closed Beyond SLA</th>
                        <th [nzSortFn]="sortClsByndSlaPer">Closed Beyond SLA %</th>
                        <!-- <th>Lapsing within 24 hours</th> -->
                        <!-- <th>Lapsing within 48 hours</th> -->
                    </tr>
                </thead>
                <!-- <div style="width:100%;height:25vh;display:flex;align-items:center;justify-content: center;">
                    <div style="width:100%;text-align: center;">
                       
                        <i nz-icon nzType="loading-3-quarters" [nzSpin]="true" [nzTheme]="'outline'"  
                            style="color:red;font-size:4em;"></i>
                    </div>
                </div> -->
                <tbody >
                        <tr *ngFor="let data of dptTbl.data">
                            <td>{{ data.sno }}</td>
                            <td>{{ data.SD_NAME }}</td>
                            <td>{{ data.SER_REQUESTED }}</td>
                            <td>{{ data.OPEN_WITHIN_SLA }}</td>
                            <td>{{ data.OPEN_BEYOND_SLA }}</td>
                            <td>{{ data.OPEN_BEYOND_SLA_PER }}&nbsp;%</td>
                            <td>{{ data.CLOSED_WITHIN_SLA }}</td>
                            <td>{{ data.CLOSED_WITHIN_SLA_PER }}&nbsp;%</td>
                            <td>{{ data.CLOSED_BEYOND_SLA }}</td>
                            <td>{{ data.CLOSED_BEYOND_SLA_PER }}&nbsp;%</td>
                            <!-- <td>{{ data.OPEN_LAPS_WITHIN_24 }}</td> -->
                            <!-- <td>{{ data.OPEN_LAPS_WITHIN_48 }}</td> -->
                        </tr>  
                </tbody>
        
            </nz-table>
            
        </nz-tab>
        <nz-tab [nzTitle]="'Service Wise'">
            <input type="text" 
         placeholder="search here" 
         (input)="getItemsSrv($event)" style="margin-bottom: 10px;
         padding: 4px 8px;
         border: 1px solid #243282;
         box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%); " class="srchinpt">
            <nz-table #srvTbl nzShowSizeChanger [nzShowPagination]="true" [nzHideOnSinglePage]="true" [nzPageSize]="10000" [nzData]="srvcWseTbleData" [nzLoading]="servLoader"  style="min-height: 25vh;"
                 class="gramsvm-table srvcTbl ">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Service Name</th>
                        <th>Department Name</th>
                        <th [nzSortFn]="sortSerReqFn">No Of Service Requests</th>
                        <th [nzSortFn]="sortOpenWthSla">Open With in SLA</th>
                        <th [nzSortFn]="sortOpenByndSla">Open Beyond SLA</th>
                        <th [nzSortFn]="sortOpenByndSlaPer">Open Beyond SLA %</th>
                        <th [nzSortFn]="sortClsWthSla">Closed With in SLA</th>
                        <th [nzSortFn]="sortClsWthSlaPer">Closed With in SLA %</th>
                        <th [nzSortFn]="sortClsByndSla">Closed Beyond SLA</th>
                        <th [nzSortFn]="sortClsByndSlaPer">Closed Beyond SLA %</th>
                        <!-- <th>Lapsing within 24 hours</th> -->
                        <!-- <th>Lapsing within 48 hours</th> -->
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let data of srvTbl.data;">
                        <td>{{ data.sno }}</td>
                        <td>{{ data.URL_DESCRIPTION }}</td>
                        <td>{{ data.DEPT_NAME }}</td>
                        <td>{{ data.SER_REQUESTED }}</td>
                        <td>{{ data.OPEN_WITHIN_SLA }}</td>
                        <td>{{ data.OPEN_BEYOND_SLA }}</td>
                        <td>{{ data.OPEN_BEYOND_SLA_PER }}&nbsp;%</td>
                        <td>{{ data.CLOSED_WITHIN_SLA }}</td>
                        <td>{{ data.CLOSED_WITHIN_SLA_PER }}&nbsp;%</td>
                        <td>{{ data.CLOSED_BEYOND_SLA }}</td>
                        <td>{{ data.CLOSED_BEYOND_SLA_PER }}&nbsp;%</td>
                        <!-- <td>{{ data.OPEN_LAPS_WITHIN_24 }}</td> -->
                        <!-- <td>{{ data.OPEN_LAPS_WITHIN_48 }}</td> -->
                    </tr>
                </tbody>
        
            </nz-table>
           
        </nz-tab>
        
        <nz-tab [nzTitle]="'Secretariat Wise'">
            <input type="text" 
         placeholder="search here" 
         (input)="getItemsSvm($event)" style="margin-bottom: 10px;
         padding: 4px 8px;
         border: 1px solid #243282;
         box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%); " class="srchinpt">
            <nz-table #svmTbl nzShowSizeChanger [nzShowPagination]="true" [nzHideOnSinglePage]="true" [nzPageSize]="10000" [nzData]="svmWseTbleData"  [nzLoading]="svmLoader"  style="min-height: 25vh;"
                 class="gramsvm-table svmTbl">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Secretariat Name</th>
                        <!-- <th>Mandal/ULB Name</th> -->
                        <th [nzSortFn]="sortSerReqFn">No Of Service Requests</th>
                        <th [nzSortFn]="sortOpenWthSla">Open With in SLA</th>
                        <th [nzSortFn]="sortOpenByndSla">Open Beyond SLA</th>
                        <th [nzSortFn]="sortOpenByndSlaPer">Open Beyond SLA %</th>
                        <th [nzSortFn]="sortClsWthSla">Closed With in SLA</th>
                        <th [nzSortFn]="sortClsWthSlaPer">Closed With in SLA %</th>
                        <th [nzSortFn]="sortClsByndSla">Closed Beyond SLA</th>
                        <th [nzSortFn]="sortClsByndSlaPer">Closed Beyond SLA %</th>
                        <!-- <th>Lapsing within 24 hours</th> -->
                        <!-- <th>Lapsing within 48 hours</th> -->
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let data of svmTbl.data">
                        <td>{{ data.sno }}</td>
                        <td>{{ data.SECRETARIAT_NAME }}</td>
                        <!-- <td>{{ data.MANDAL_NAME }}</td> -->
                        <td>{{ data.SER_REQUESTED }}</td>
                        <td>{{ data.OPEN_WITHIN_SLA }}</td>
                        <td>{{ data.OPEN_BEYOND_SLA }}</td>
                        <td>{{ data.OPEN_BEYOND_SLA_PER }}&nbsp;%</td>
                        <td>{{ data.CLOSED_WITHIN_SLA }}</td>
                        <td>{{ data.CLOSED_WITHIN_SLA_PER }}&nbsp;%</td>
                        <td>{{ data.CLOSED_BEYOND_SLA }}</td>
                        <td>{{ data.CLOSED_BEYOND_SLA_PER }}&nbsp;%</td>
                        <!-- <td>{{ data.OPEN_LAPS_WITHIN_24 }}</td> -->
                        <!-- <td>{{ data.OPEN_LAPS_WITHIN_48 }}</td> -->
                    </tr>
                </tbody>
        
            </nz-table>
        
        </nz-tab>
      </nz-tabset>
</div>
