<pg-header title="Gates" ticon="database" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">
  <div>
    <div class="ds-card grid">
      <ds-loading *ngIf="shwLdg"></ds-loading>
      <dx-data-grid id="gridContainer" [dataSource]="gteLst" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true"
        style="width:100%" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
        <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true" fileName="LMO Details"></dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [width]="f.width" [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
          [allowHeaderFiltering]="f.filter" [visible]="f.visible"></dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" type="buttons" [width]="100" caption="Edit"
          cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="cursor-pointer tblEdtBtn" (click)="onCellClick('edit',data)">
              Edit
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons" [width]="100" caption="Delete"
          cellTemplate="delTemplate">
          <div *dxTemplate="let data of 'delTemplate'">
            <div class="cursor-pointer tblDelBtn" (click)="onCellClick('delete',data)">
              Delete
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>

  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
    (nzOnClose)="closeSideBar()">
    <div class="gteMrgn">
      <div class="inptLbl">Gate Name</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="text" nz-input [(ngModel)]="gteDt.gte_nm" placeholder="Enter gate name" />
      </nz-input-group>
    </div>
    <div class="gteMrgn">
      <div class="inptLbl">House Number</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="number" nz-input [(ngModel)]="gteDt.gte_hse_nu" placeholder="Enter house number" />
      </nz-input-group>
    </div>
    <!-- <div class="inptLbl">Door Number</div>
  <nz-input-group [nzSuffix]="inputClearTpl">
    <input type="text" nz-input [(ngModel)]="gteDt.gte_dr_nu" placeholder="Enter door number" />
  </nz-input-group> -->
    <div class="gteMrgn">
      <div class="inptLbl">Total Households</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="number" nz-input [(ngModel)]="gteDt.hse_ct" placeholder="Enter total households" />
      </nz-input-group>
    </div>
    <div class="gteMrgn">
      <div class="inptLbl">Total Population</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="number" nz-input [(ngModel)]="gteDt.pltn_ct" placeholder="Enter total population" />
      </nz-input-group>
    </div>
    <div class="gteMrgn">
      <div class="inptLbl">Latitude</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="text" nz-input [(ngModel)]="gteDt.lat" placeholder="Enter latitude" />
      </nz-input-group>
    </div>
    <div class="gteMrgn">
      <div class="inptLbl">Longitude</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="text" nz-input [(ngModel)]="gteDt.lng" placeholder="Enter longitude" />
      </nz-input-group>
    </div>
    <div class="gteMrgn">
      <div class="inptLbl">Micropockets</div>
      <input placeholder="Select micropockets" nz-input [(ngModel)]="gteDt.mcrpt_nm" [nzAutocomplete]="mpauto" />
      <nz-autocomplete #mpauto>
        <nz-auto-option *ngFor="let d of micropockets" [nzValue]="d.mcrpt_id" (ngModelChange)="onmcrptChange($event)">
          {{d.mcrpt_nm}}</nz-auto-option>
      </nz-autocomplete>
    </div>
    <div class="gteMrgn">
      <div class="inptLbl">ULB</div>
      <input placeholder="Select ulb" nz-input [(ngModel)]="gteDt.ulb_nm" (ngModelChange)="onulbChange($event)"
        [nzAutocomplete]="auto" />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let d of ulbs" [nzValue]="d.ulb_id" (click)="getULBID(d.ulb_id);">{{d.ulb_nm}}
        </nz-auto-option>
      </nz-autocomplete>
    </div>

    <ng-template #inputClearTpl><i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
        *ngIf="gteDt.gte_nm" (click)="gteDt = {}"></i></ng-template>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveGte()"
      *ngIf="shwGteEditDiv==false && shwGteDelDiv==false">Submit</button>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEditGte()"
      *ngIf="shwGteEditDiv==true && shwGteDelDiv==false">Update</button>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="shwGteEditDiv==false && shwGteDelDiv==true" nz-popconfirm
      nzPopconfirmTitle="Are you sure delete this gate?" nzPopconfirmPlacement="bottom" (nzOnConfirm)="saveDeleteGte()"
      (nzOnCancel)="cancel()">Delete</button>
    <button nz-button nzType="default" class="cnclBtn" (click)="oncancel()" *ngIf="shwSidebar">Cancel</button>
  </nz-drawer>
  <!-- </div>
    </div>
</div> -->
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
  </nz-result>
</div>