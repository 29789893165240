import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppsService } from 'src/app/shared/services/apps.service';
// import {ToastrService } from 'ngx-toastr';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-asset-vendors',
  templateUrl: './asset-vendors.component.html',
  styleUrls: ['./asset-vendors.component.scss']
})
export class AssetVendorsComponent implements OnInit {
  vndrForm: any;
  shwschvlymDelDiv
  shwschvlymEditDiv
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs
  mainMessage
  loader
  gridData
  shwSidebar
  sidebarHeading
  delete: boolean;
  delData: any;
  editdata: any;
  shwLdng
  constructor(private location:Location, private apiSrv:AppsService,private nzMessageService: NzMessageService ) { }

  ngOnInit() {
    this.vndrForm = new FormGroup({
      vndr_nm: new FormControl(''),
      vndr_ph: new FormControl(''),
      dscn_tx: new FormControl(''),
      vndr_cd:new FormControl(''),
      
    });
    this.getGridData();
  }
  getGridData(){

      // console.log('grid data called')
      this.mainMessage = null;
      this.loader = true;
      this.usrAcsErrMsg = false;
      this.apiSrv.get('asset/tracking/vendors').subscribe((res) => {
        console.log(res)
        this.loader = false;
        if (res['status'] == 404) {
          this.usrAcsErrMsg = true;
        } else if (res['status'] == 200) {
          this.USER_PERMISSIONS = res['perm'][0];
          if (this.USER_PERMISSIONS.slct_in === 1) {
            // console.log(res['data'])
            // this.user.permissions = (res['perm'] === undefined) ? this.user.permissions : res['perm'][0];
            if (res['data'].length === 0) { this.mainMessage = 'No entries found in the database.'; }
  
            this.gridData = res['data'];
            // console.log(this.gridData)
            var cont=1
            this.gridData.forEach(e => {
              e['sno']=cont++
            });
            this.columnDefs = [
              { headerName: 'S.no', field: 'sno', alignment: 'center',width:80,cellClass: 'pm-grid-number-cell' },
              { headerName: 'Vendor Name', field: 'vndr_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
              { headerName: 'Vendor Code', field: 'vndr_cd', alignment: 'center', cellClass: 'pm-grid-number-cell' },
              { headerName: 'Vendor phone', field: 'vndr_ph', alignment: 'center', cellClass: 'pm-grid-number-cell', ortable: true, filter: true },
              { headerName: 'Vendor description', field: 'dscn_tx', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
             
            ];
            // else if (res['status'] === 404) {
            //   let permTxt = 'Desginations Creation';
            //   let prmeRte = `user/permissions/${permTxt}`;
            //   this.crdsrv.get(prmeRte).subscribe((prmres) => {
            //   // console.log(prmres['data']);
  
            //   });
            //   // this.user.permissions = { 'slct_in': 0, 'insrt_in': 0, 'updt_in': 0, 'dlte_in': 0, 'exprt_in': 0 };
            // }
          } else {
            this.usrAcsErrMsg = true;
          }
        }else {
         
        //   this.toastr.error( res["message"], '', {
        //    timeOut: 10000,
        //    positionClass: 'toast-top-right',
        //  });
       }
      }, (err) => {
        this.loader = false;
      });
  }
  goBack()
  {
    this.location.back();
  }
  onToolbarPreparing(e) {
    // console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Vendor',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(opt, data)
  {
    this.shwSidebar = true;
    this.vndrForm.value.ast_type_nm='';
    this.vndrForm.value.dscn_tx='';
    if(opt == 'edit')
    {
      this.shwschvlymEditDiv = true;
      this.shwschvlymDelDiv = false;
      this.sidebarHeading = 'Edit Vendor';
      this.vndrForm.get('vndr_nm').setValue(data.vndr_nm)
      this.vndrForm.get('vndr_ph').setValue(data.vndr_ph)
      this.vndrForm.get('dscn_tx').setValue(data.dscn_tx)
    }
    else if(opt == 'delete')
    {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = true;
      this.sidebarHeading = 'Delete Type';
    }
    else
    {
      this.shwschvlymEditDiv=false
      this.shwschvlymDelDiv=false
      this.sidebarHeading = 'Add New type';
      this.delete=false
     
    }
  }
  onDelete2(event)
  {
    this.delete = true;
    this.delData=event.data
    this.openSideBar("delete", this.delData);
  }
  onEdit2(event)
  {
    this.delete=false
    this.editdata = event.data;
    this.openSideBar("edit", this.editdata);
  }
  oncancel(): void {
    this.nzMessageService.info('Type Not Deleted');
  }
  cancel()
  {
    this.vndrForm.reset()
    this.shwSidebar=false;
    this.getGridData();
  }
  saveVndr(){
    console.log(this.vndrForm.value)
    let data={
      vndr_nm:this.vndrForm.value.vndr_nm,
      vndr_ph:this.vndrForm.value.vndr_ph,
      dscn_tx:this.vndrForm.value.dscn_tx,
      vndr_cd:this.vndrForm.value.vndr_cd

    }
    console.log(data)
    if(data.vndr_nm !=""  && data.vndr_ph !="" &&  data.dscn_tx !="")
    {
      this.shwLdng=true
      this.apiSrv.post(data, `asset/tracking/register/vendors`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Type Created Successfully', '', {
          //   timeOut: 3000,
          // });
          this.cancel()
        } else {
          // this.toastr.error(res['message'], '', {
          //   timeOut: 3000
          // });
        }
      }, (err) => {
        this.shwLdng = false;
        // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
        //   timeOut: 3000
        // });
      })
    }
    else
    {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
    }
    console.log(data)

  }
  saveEditVndr(){
    let data={
      vndr_nm:this.vndrForm.value.vndr_nm,
      vndr_ph:this.vndrForm.value.vndr_ph,
      dscn_tx:this.vndrForm.value.dscn_tx,
      vndr_cd:this.vndrForm.value.vndr_cd
    }
    console.log(this.editdata)
    console.log(data)
    if(data.vndr_nm !=""  && data.vndr_ph !="" &&  data.dscn_tx !="")
    {
      this.shwLdng=true
      this.apiSrv.update(data, `asset/tracking/vendor/${this.editdata.vndr_id}`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Type Updated Successfully', '', {
          //   timeOut: 3000,
          // });
          this.cancel()
        } else {
          // this.toastr.error(res['message'], '', {
          //   timeOut: 3000
          // });
        }
      }, (err) => {
        this.shwLdng = false;
        // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
        //   timeOut: 3000
        // });
      })
    }
    else
    {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
    }

  }
  saveDeleteVndr(){
    this.shwLdng=true
    this.apiSrv.delete(`asset/tracking/vendor/${this.delData.vndr_id}`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {
        // this.toastr.success('Type Deleted Successfully', '', {
        //   timeOut: 3000,
        // });
        this.cancel()
      } else {
        // this.toastr.error(res['message'], '', {
        //   timeOut: 3000
        // });
      }
    }, (err) => {
      this.shwLdng = false;
      // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
      //   timeOut: 3000
      // });
    })

  }

}
