import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { ApiService } from 'src/app/providers/api.service';
import * as turf from "@turf/turf";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-wrok-profile',
  templateUrl: './wrok-profile.component.html',
  styleUrls: ['./wrok-profile.component.scss']
})
export class WrokProfileComponent implements OnInit {
  map: any;
  userDtls;
  data: any = [];
  wrkDtls: any = [];
  lctnDtls: any = [];
  assetDtls: any = [];
  expnsDtls: any = [];
  asgnEmpDtls: any = [];
  timeLineData: any = [];
  atchMntDtls: any = [];
  trpDtls: any = [];
  @Input() wrkData;
  trpPnts: any = [];
  startIcon = new L.Icon({
    iconUrl: '/assets/images/markers/playback/startPoint.png',
    iconSize: [33, 33],
    iconAnchor: [16, 28],
  });
  endIcon = new L.Icon({
    iconUrl: '/assets/images/markers/playback/endPoint.png',
    iconSize: [33, 33],
    iconAnchor: [16, 28],
  });
  lctnIcon = L.icon({
    iconUrl: '/assets/images/markers/mapIcons/m/Workll.png',
    iconSize: [33, 33],
    iconAnchor: [16, 28],
  });
  startMarker: any;
  endMarker: any;
  vehMarker: any;
  plybkPoints: any = [];
  fencInOutDtls: any = [];
  showLdr: boolean = false;
  RoutecollectPointMarker: any;
  RoutecollectPointMarker1: any;
  RoutecollectPointCircle: any;
  RoutecollectPointCircle1: any;
  Route_collectPointArray: any = [];
  Route_collectPointArray1: any = [];
  totalTravelTime;
  RoutePoints;
  travelDist;
  stoppageTime;
  startPBMarker;
  endPBMarker;
  marker;
  line;
  index;
  pauseindex;
  timerId;
  speedval;
  sliderValue = 100;
  trackVehicleSpeed;
  trackVehicleTimestamp;
  finalSpdMrkrs: any = [];
  animate;
  wrkMarker;
  constructor(private apiServ: ApiService, public datepipe: DatePipe, private message: NzMessageService) {
    this.userDtls = JSON.parse(localStorage.getItem('wto_clients'));
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.initialize();

    }, 100);
    this.getDetailsByWrkId();
  }
  initialize() {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.map = L.map('map', {
        center: [this.userDtls[0].lat, this.userDtls[0].lng],
        zoom: 13
      });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      tiles.addTo(this.map);
    } catch (e) {

    }

  }
  getDetailsByWrkId() {
    console.log(this.wrkData)
    if(this.wrkData.oms_wrk_id>0){
      this.apiServ.get(`apsfl/app/works/details/${this.wrkData.oms_wrk_id}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.data = res['data'];
          this.lctnDtls = this.data[0];
          this.assetDtls = this.data[1];
          this.expnsDtls = this.data[2];
          this.asgnEmpDtls = this.data[3];
          this.timeLineData = this.data[4];
          this.atchMntDtls = this.data[5];
          this.trpDtls = this.data[6];
          this.wrkDtls = this.data[7];
          console.log(this.data);
  
          if (this.trpDtls.length == 1) {
            this.trpDtls[0].slctd = true;
          } else if (this.trpDtls.length > 1) {
            this.trpDtls.forEach(t => {
              t.slctd = false;
  
            });
            this.trpDtls[0].slctd = true;
            if (this.trpDtls[0].trp_strt_in == 1 && this.trpDtls[0].trp_cls_in == 0) {
              this.plotOnMap(this.trpDtls[0], 'live');
            } else if (this.trpDtls[0].trp_strt_in == 1 && this.trpDtls[0].trp_cls_in == 1) {
              this.plotOnMap(this.trpDtls[0], 'work');
            }
          }
        }
        else {
  
        }
      });
    }

  }
  onTrpCrdSlct(sel) {
    this.trpDtls.forEach(t => {
      if (t.trp_rn_id == sel.trp_rn_id) {
        t.slctd = true;
        if (t.trp_strt_in == 1 && t.trp_cls_in == 0) {
          this.plotOnMap(t, 'live');
        } else if (t.trp_strt_in == 1 && t.trp_cls_in == 1) {
          this.plotOnMap(t, 'work');
        }
      } else {
        t.slctd = false;
      }

    });
  }
  formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  currencyINRFormater = (val: string) => {
    return this.formatter.format(parseFloat(val));
  }
  plotOnMap(data, ky) {
    if (ky == 'live') {
      this.getLiveVehicleData(data);
    } else if (ky == 'play') {
      this.getPlayBack(data);
    } else if (ky == 'time') {
      this.getTimeline(data)
    } else if (ky == 'work') {
      this.getWrkLocation(data);
    }
  }
  getTimeline(data) {
    if (this.startMarker) {
      this.map.removeLayer(this.startMarker);
    }
    if (this.endMarker) {
      this.map.removeLayer(this.endMarker);
    }
    this.clearAllMarkers();
    console.log(data)
    var postDta = {
      trp_rn_id: data.trp_rn_id
    }
    this.apiServ.post(postDta, `apsfl/Timeline/Trip`).subscribe((res) => {
      if (res['status'] == 200) {
        this.trpPnts = res['data'];
        console.log(this.trpPnts);
        let latlng = [];
        if (this.trpPnts.length > 0) {
          for (let t = 0; t < this.trpPnts.length; t++) {
            if (this.trpPnts[t].img_upld_txt == 'start') {

              let tooltipContent = 'Work Started:<br>';
              this.trpPnts[t].imgs.forEach(i => {
                tooltipContent += `<div class='col-3'><img src="${i.img_url_tx}" alt="" class="atchmnt-img" style="max-width: 50px; max-height: 50px; margin: 2px;"></div>`;
              });
              this.startMarker = L.marker([this.trpPnts[t].img_lat, this.trpPnts[t].img_lng], {
                icon: this.startIcon
              }).addTo(this.map).bindTooltip(tooltipContent, { permanent: true, opacity: 0.7 }).openTooltip();
              latlng.push([this.trpPnts[t].img_lat, this.trpPnts[t].img_lng])
            } else if (this.trpPnts[t].img_upld_txt == 'End') {
              let etooltipContent = 'Work Ended:<br>';
              this.trpPnts[t].imgs.forEach(i => {
                etooltipContent += `<div><img src="${i.img_url_tx}" alt="" class="atchmnt-img" style="max-width: 50px; max-height: 50px; margin: 2px;"></div>`;
              });
              this.endMarker = L.marker([this.trpPnts[t].img_lat, this.trpPnts[t].img_lng], {
                icon: this.endIcon
              }).addTo(this.map).bindTooltip(etooltipContent, { permanent: true, opacity: 0.7 }).openTooltip();
              latlng.push([this.trpPnts[t].img_lat, this.trpPnts[t].img_lng])
            }
          }
          console.log(latlng)
          if (latlng.length == 2) {
            var features = turf.points(latlng);

            var center = turf.center(features);
            console.log(center);
            this.map.setView(center.geometry.coordinates, 12);
          } else {
            this.map.setView(latlng[0], 12);
          }

        }
      }
      else {
      }
    })
  }

  getPlayBack(data) {
    this.clearAllMarkers();
    this.showLdr = true;

    if (data.actl_strt_ts != undefined && data.actl_strt_ts.toString() != '0000-00-00 00:00' && data.actl_strt_ts != null && data.actl_strt_ts != ''
      && data.actl_end_ts != undefined && data.actl_end_ts.toString() != '0000-00-00 00:00' && data.actl_end_ts != null && data.actl_end_ts != '') {
      const startDate = this.convertToDate(data.actl_strt_ts);
      const endDate = this.convertToDate(data.actl_end_ts);
      var postData = {
        "checkbit": "byVeh",
        "sdate": this.datepipe.transform(startDate, 'yyyy-MM-dd HH:mm'),
        "edate": this.datepipe.transform(endDate, 'yyyy-MM-dd HH:mm'),
        "asrt_id": data.asrt_id,
        "trp_rn_id": data.trp_rn_id
      };
      console.log(postData);
      this.apiServ.post(postData, 'playback/vehicle/playback').subscribe((res) => {
        this.showLdr = false;
        var playData = res['data'];
        console.log(playData)
        console.log(playData.fncDtls);
        if (playData.fncDtls) {
          for (var i = 0; i < playData.fncDtls.length; i++) {

            this.fencInOutDtls.push({
              SNo: playData.fncDtls[i].SNo,
              fnce_id: playData.fncDtls[i].fnce_id,
              fnce_nm: playData.fncDtls[i].fnce_nm,
              fnc_in: playData.fncDtls[i].actl_arvl_ts,
              fnc_ut: playData.fncDtls[i].actl_dprte_ts,
              // asrt_nm: playData.fncDtls[i].asrt_nm,
              fnce_cvr_sts: playData.fncDtls[i].fnce_cvr_sts,
              stoppage: playData.fncDtls[i].stoppage,
              lati: playData.fncDtls[i].lat,
              longi: playData.fncDtls[i].lng,
              icn: playData.fncDtls[i].fncs_icn_pth,
              cvr_sts: playData.fncDtls[i].cvr_sts,
            });


            // if (this.fencInOutDtls.length == 0) {
            //   this.showTimeline = false;
            // } else {
            //   this.showTimeline = true;
            // }

            if (playData.fncDtls[i].fnce_cvr_sts == 1) {
              var thisIcon = new L.Icon({
                iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + playData.fncDtls[i].fncs_icn_pth + '_r_m.png',
                iconSize: [32, 30],
                iconAnchor: [22, 45],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
              });

              this.RoutecollectPointMarker = L.marker([playData.fncDtls[i].lat, playData.fncDtls[i].lng], {
                draggable: false, // Make the icon dragable
                title: playData.fncDtls[i].fnce_nm, // Add a title
                opacity: 5,
                icon: thisIcon
              }).addTo(this.map)
                .bindPopup("<div style='color: #000;'><b style='line-height: 21px; color: #1a56a2;'>Fence Name:  </b>" + playData.fncDtls[i].fnce_nm + "</div>")
                .closePopup();
              this.RoutecollectPointCircle = L.circle([playData.fncDtls[i].lat, playData.fncDtls[i].lng], 50, {
                stroke: false,
                fillColor: '#ffde00',
                fillOpacity: 0.5,
              });
              this.Route_collectPointArray.push(this.RoutecollectPointMarker, this.RoutecollectPointCircle);
            }
            if (playData.fncDtls[i].fnce_cvr_sts == 0) {
              var thisIcon = new L.Icon({
                iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + playData.fncDtls[i].fncs_icn_pth + '_o_m.png',
                iconSize: [32, 30],
                iconAnchor: [22, 45],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
              });
              this.RoutecollectPointMarker1 = L.marker([playData.fncDtls[i].lat, playData.fncDtls[i].lng], {
                draggable: false, // Make the icon dragable
                title: playData.fncDtls[i].fnce_nm, // Add a title
                opacity: 5,
                icon: thisIcon
              }).addTo(this.map)
                .bindPopup("<div style='color: #000;'><b style='line-height: 21px; color: #1a56a2;'>Fence Name:  </b>" + playData.fncDtls[i].fnce_nm + "</div>")
                .closePopup();
              this.RoutecollectPointCircle1 = L.circle([playData.fncDtls[i].lat, playData.fncDtls[i].lng], 50, {

                stroke: false,
                fillColor: '#ffde00',
                fillOpacity: 0.5,
              });
              this.Route_collectPointArray1.push(this.RoutecollectPointMarker1, this.RoutecollectPointCircle1);
            }
          }
        }
        console.log(playData);
        if (playData !== '' && playData.lat.length > 0) {

          console.log("InnnnplayData");
          // To avoid duplicates for start & end points
          // this.plybkPoints.push({
          //   lat: playData.lat[0],
          //   lng: playData.lng[0],
          //   speed_ct: playData.mapPoints[0].speed_ct,
          //   Signal: playData.mapPoints[0].Signall,
          //   asrt_nm: playData.mapPoints[0].asrt_nm,
          //   drcn_ct: playData.mapPoints[0].drcn_ct,
          // });
          var strt = playData.mapPoints[0].Signall;
          var end = playData.mapPoints[playData.mapPoints.length - 1].Signall;
          this.totalTravelTime = moment.utc(moment(end, "DD-MM-YYYY HH:mm").diff(moment(strt, "DD-MM-YYYY HH:mm"))).format("HH:mm:ss");
          // console.log(this.totalTravelTime);



          for (var c = 0; c < playData.lat.length; c++) {
            // console.log(playData.lat[c],playData.lat[playData.lat.length - 1])
            if (playData.lat[c] != playData.lat[0] && playData.lng[c] != playData.lng[0]) {
              // if (playData.lat[c] != playData.lat[playData.lat.length - 1] && playData.lng[c] != playData.lng[playData.lat.length - 1]) {
              this.plybkPoints.push({
                lat: playData.lat[c],
                lng: playData.lng[c],
                speed_ct: playData.mapPoints[c].speed_ct,
                Signal: playData.mapPoints[c].Signall,
                asrt_nm: playData.mapPoints[c].asrt_nm,
                drcn_ct: playData.mapPoints[c].drcn_ct
              });
              // }
            }
          }

          this.plybkPoints.push({
            lat: playData.lat[playData.lat.length - 1],
            lng: playData.lng[playData.lat.length - 1],
            // speed_ct: playData.mapPoints[playData.lat.length - 1].speed_ct,
            Signal: playData.mapPoints[playData.lat.length - 1].Signall != undefined ? playData.mapPoints[playData.lat.length - 1].Signall : '',
            asrt_nm: playData.mapPoints[playData.lat.length - 1].asrt_nm,
            // drcn_ct: playData.mapPoints[playData.lat.length - 1].drcn_ct,
          });
          this.RoutePoints = this.plybkPoints;

          var trvlDstnce = playData.mapPoints[playData.mapPoints.length - 1].odmtr_ct - playData.mapPoints[0].odmtr_ct;
          var totDtnce = Math.ceil(trvlDstnce);
          this.travelDist = totDtnce + ' ' + 'Kms';
          var stoppageCounter = 0;
          var stoppageStartTimeIndex = 0;
          // var stoppageTime = 0;
          var diffTime;
          var pstrt;
          var pend;
          var sno = 0;
          console.log(this.plybkPoints)
          for (var m = 0; m < this.plybkPoints.length; m++) {
            var startTime;
            var endTime;

            if (this.plybkPoints[m].speed_ct == 0) {
              console.log("In speed");
              if (this.plybkPoints[m].Signal == undefined) {
                pstrt = this.plybkPoints[m + 1].Signal;

              } else {
                pstrt = this.plybkPoints[m].Signal;

              }

              pend = this.plybkPoints[m + 1].Signal;

              this.stoppageTime = moment(pend, "DD-MM-YYYY HH:mm").diff(moment(pstrt, "DD-MM-YYYY HH:mm"));

              this.plybkPoints[m]["stoppageTime"] = this.stoppageTime / 60000;
              this.plybkPoints[m]["lastTime"] = this.plybkPoints[m + 1].Signal;
              console.log(this.stoppageTime)
              if (this.stoppageTime != 0) {

                this.plybkPoints[m]["Sno"] = ++sno;
                console.log("sno", sno)
              }

              // stoppageStartTimeIndex = 0;
              // stoppageCounter = 0;
              // }
            }
          }

          for (var k in this.plybkPoints) { }
          var endIcon = new L.Icon({
            iconUrl: '/assets/images/markers/playback/endPoint.png',
            iconSize: [42, 45],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });

          this.endPBMarker = L.marker([this.plybkPoints[k].lat, this.plybkPoints[k].lng], {
            icon: endIcon
          }).addTo(this.map);
          this.endPBMarker.bindPopup("<b>Vehicle No : " + this.plybkPoints[k].asrt_nm + "<br>Ending Time : " + this.plybkPoints[k].Signal + "</b>")
            .addTo(this.map);

          var startIcon = new L.Icon({
            iconUrl: '/assets/images/markers/playback/startPoint.png',
            iconSize: [42, 45],
            iconAnchor: [22, 45],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
          console.log(this.plybkPoints[0])

          this.startPBMarker = L.marker([this.plybkPoints[0].lat, this.plybkPoints[0].lng], {
            icon: startIcon
          }).addTo(this.map);
          this.startPBMarker.bindPopup("<b>Vehicle No : " + this.plybkPoints[0].asrt_nm + "<br>Starting Time : " + this.plybkPoints[0].Signal + "</b>")
            .addTo(this.map);
          this.marker = null;
          this.line = L.polyline([],
            {
              color: 'blue',
              weight: 3,
              opacity: 0.5,
              smoothFactor: 1,
              lineJoin: 'round',

            }
          )
            .on('mouseover', function (e) {
              e.target.setStyle({
                color: 'blue'
              });
            })
            .on('mouseout', function (e) {
              e.target.setStyle({
                color: 'blue'
              });
            }).addTo(this.map);
          this.index = 0;

          this.timerId = this.update();

        } else {
          console.log("No Vehicle Operated");
        }
      }, (err) => {

      })

    }
  }
  update() {
    return setTimeout(() => {
      this.pauseindex = this.index;

      this.speedval = 350 - this.sliderValue;
      if (this.plybkPoints.length) {
        this.redraw(this.plybkPoints.shift());
        this.timerId = this.update();
      }
    }, this.speedval);
  }
  redraw(point) {
    if (point.speed_ct) {
      var vehicleSpeed = point.speed_ct;
      this.trackVehicleSpeed = vehicleSpeed + ' Kms';
    }
    if (point.Signal) {
      this.trackVehicleTimestamp = point.Signal;
    }

    if (point.stoppageTime !== undefined && point.stoppageTime !== 0) {
      var asrt_ParkIcon = new L.Icon({
        iconUrl: '/assets/images/markers/playback/parking.png',
        iconSize: [42, 45],
        iconAnchor: [12, 31],
        popupAnchor: [0, -14],
        shadowSize: [41, 41]
      });
      if (point.stoppageTime >= 0) {
        this.finalSpdMrkrs.push(L.marker([point.lat, point.lng], {
          icon: asrt_ParkIcon
        })
          .bindPopup("<b>Vehicle No : " + point.asrt_nm + "<br>Parking No : " + point.Sno + "<br>From : " + point.Signal + "<br>To :  " + point.lastTime + "<br>STOPPAGE : " + point.stoppageTime + ' Mins' + "</b>")
          .addTo(this.map));
        console.log(this.finalSpdMrkrs)

      }
    }

    var asrtIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/startPoint2.png',
      iconSize: [41, 25],
      iconAnchor: [22, 25],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });



    if (this.marker) {
      this.map.removeLayer(this.marker)
    }
    this.marker = L.marker(point, {
      icon: asrtIcon,
    }).addTo(this.map).setRotationAngle(point.drcn_ct);



    this.line.addLatLng(point);
    this.marker.setLatLng(point);

    this.index++;

    this.map.panTo(point, this.animate = false);


    try {
      if (this.plybkPoints.length == 0) {
        console.log("In completedddd");
        this.message.success('TRIP COMPLETED', {
          nzDuration: 2000
        });
      }
    } catch (e) {
      console.log(e);
    }


  }
  convertToDate(dateStr: string): Date | null {
    const parts = dateStr.split(' ');
    const datePart = parts[0].split('-'); // Split 'dd-MM-yyyy'
    const timePart = parts[1].split(':'); // Split 'HH:mm:ss'

    if (datePart.length === 3 && timePart.length >= 2) {
      // Construct date in 'yyyy-MM-ddTHH:mm:ss' format
      const formattedDate = `${datePart[2]}-${datePart[1]}-${datePart[0]}T${timePart[0]}:${timePart[1]}:${timePart[2] || '00'}`;
      return new Date(formattedDate);
    }
    return null;
  }
  getLiveVehicleData(data) {
    if (this.vehMarker) {
      this.map.removeLayer(this.vehMarker);
    }
    this.clearAllMarkers();
    var vehIcon = new L.Icon({
      iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + data.icon_path,
      iconSize: [31, 31],
      iconAnchor: [10, 25],
      popupAnchor: [5, -24],
      shaowSize: [35, 35],
    });
    console.log(data.lat, data.lng)
    if (data.lat && data.lng) {
      this.vehMarker = L.marker([data.lat, data.lng], {
        icon: vehIcon
      }).addTo(this.map);

      var bounds = [];
      bounds.push([data.lat, data.lng]);
      this.map.fitBounds(bounds);
      // var coords = L.latLng([this.data.lat, this.data.lng]);
      // L.popup().setLatLng(coords).setContent(this.VhcleDetails.asrt_nm ).openOn(this.vehicleMap);

    }
  }
  clearAllMarkers() {
    if (this.startMarker) {
      this.map.removeLayer(this.startMarker);
    }
    if (this.endMarker) {
      this.map.removeLayer(this.endMarker);
    }
    if (this.vehMarker) {
      this.map.removeLayer(this.vehMarker);
    }




    for (var k = 0; k < this.finalSpdMrkrs.length; k++) {
      this.map.removeLayer(this.finalSpdMrkrs[k]);
    }
    this.plybkPoints = [];
    for (var k = 0; k < this.Route_collectPointArray.length; k++) {
      this.map.removeLayer(this.Route_collectPointArray[k]);
    }
    for (var k = 0; k < this.Route_collectPointArray1.length; k++) {
      this.map.removeLayer(this.Route_collectPointArray1[k]);
    }
    clearTimeout(this.timerId);
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    if (this.line) {
      this.map.removeLayer(this.line);
    }
    if (this.startPBMarker) {
      this.map.removeLayer(this.startPBMarker);
    }
    if (this.endPBMarker) {
      this.map.removeLayer(this.endPBMarker);
    }


    if (this.wrkMarker) {
      this.map.removeLayer(this.wrkMarker);
    }
  }
  getWrkLocation(data) {
    this.clearAllMarkers();
    if (this.wrkMarker) {
      this.map.removeLayer(this.wrkMarker);
    }
    console.log(data.w_lat, data)
    if (data.w_lat != undefined && data.w_lng != undefined) {
      this.wrkMarker = L.marker([data.w_lat, data.w_lng], {
        icon: this.lctnIcon
      }).addTo(this.map).bindTooltip('W' + data.oms_wrk_id + '<br>' + data.wrk_lctn_nm + `<br>` + data.oms_wrm_dsc_tx, { permanent: true, opacity: 0.7 }).openTooltip();
      this.map.setView([data.w_lat, data.w_lng], 12);
    }


  }
}
