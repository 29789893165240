import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-analysis-dashboard',
  templateUrl: './analysis-dashboard.component.html',
  styleUrls: ['./analysis-dashboard.component.scss']
})
export class AnalysisDashboardComponent implements OnInit , OnChanges{
  showLdr: boolean;
  clntDtls: any;
  tntLst: any = [];
  selectedtenant;
  selectedtenantNm;
  userDtls: any;
  clap_in: any;
  ShowClap: boolean = false;
  ulb_id;
  nodata: boolean;
  nodataMsg: string;
  urban_in;
  hdrLbl: string;
  VhcleCtnts: any = [];
  ntStrtdVhcles: any = [];
  slctdDt = new Date();
  curDate;
  postslctdDt: {};
  rdCnts: any = [];
  tripsTrvldDta: any;
  tripsTrvldcolumnDefs: any = [];
  selectedVehicle: any;
  visible: boolean;
  vehTrpLst: any[];
  vehDupTrpLst: any;
  showVhcles: boolean;
  selcdAnalysis: any = [];
  selectedAnlys;
  selectedCtgry;
  vehTrpRteLst: any = [];
  tripsTrvldRoutecolumnDefs: ({ headerName: string; field: string; alignment: string; width: number; filter: boolean; search: boolean; } | { headerName: string; field: string; alignment: string; width: number; filter?: undefined; search?: undefined; } | { headerName: string; alignment: string; field: string; width?: undefined; filter?: undefined; search?: undefined; } | { headerName: string; alignment: string; field: string; width: string; filter?: undefined; search?: undefined; })[];
  selectedTrip: any;
  trpSldrvisible: boolean;
  rowselected: any;
  selectedDriver: any;
  visibleDriverSldr: boolean;
  @Input() tenant;
  tenantId: any;
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {

    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.clntDtls[0].tnts.forEach(element => {
      if (element.urban_in == 1) {
        this.tntLst.push(element);
      }

    });
   
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.selectedtenantNm = this.tntLst[0].tnt_dsply_nm;
    this.ulb_id = this.tntLst[0].cpy_ulb_id;
    this.urban_in = this.tntLst[0].urban_in;
    console.log(this.selectedtenant, this.selectedtenantNm);
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
    if (this.urban_in == 1) {
      this.hdrLbl = 'ULB';
    } else {
      this.hdrLbl = 'Mandal';
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.tenant.currentValue)
    this.tenantId=changes.tenant.currentValue;
  }

  ngOnInit(): void {
    this.getVhcleCnts();
    this.getNotStrtedVhclesCnt();
    this.get_rtDvtdTrips(this.curDate);
    // this.get_Trips_trvalledDetails(this.slctdDt, this.selectedtenant);
    this.getTripsData('', '');
    //   this.selcdAnalysis=[{
    //     anl_id:1,
    //     anl_nm:'Vehicles'
    //   },{
    //     anl_id:2,
    //     anl_nm:'Trips'
    //   },
    // ];
    // this.selectedAnlys=1
    this.showVhcles = true;
    this.selectCategory(0, this.curDate);

    // this.getAnalysis(1);
    // this.getData()
  }
  // getAnalysis(val){
  //   if(val==1){
  //     this.showVhcles=true;
  //   }else{
  //     this.showVhcles=false;
  //   }
  // }
  getVhcleCnts() {
    console.log(this.selectedtenant)
    let rte = `dashboard/enterprise/analysis/getVhcleCnts/${this.selectedtenant}`;
    this.apiServ.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.VhcleCtnts = res['data'];
          console.log(this.VhcleCtnts)
        }
        this.showLdr = false;
      }
    }, (err) => {
    });
  }
  getNotStrtedVhclesCnt() {
    let rte = `dashboard/enterprise/analysis/ntStrtDVhcles/${this.selectedtenant}`;
    this.apiServ.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.ntStrtdVhcles = res['data'];
        }
        this.showLdr = false;
      }
    }, (err) => {
    });
  }
  ontntSelect(value) {
    if (value == 0) {
      this.selectedtenant = 0;
      this.selectedtenantNm = "All ULB's Selected";
      this.ulb_id = 0;
    }
    for (let i = 0; i < this.tntLst.length; i++) {
      console.log('in');
      if (this.tntLst[i].tnt_id == value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
        this.selectedtenantNm = this.tntLst[i].tnt_dsply_nm;
        this.ulb_id = this.tntLst[i].cpy_ulb_id;
        this.urban_in = this.tntLst[i].urban_in;
        if (this.urban_in == 1) {
          this.hdrLbl = 'ULB';
        } else {
          this.hdrLbl = 'Mandal';
        }
      }
    }
    this.getVhcleCnts();
    this.getNotStrtedVhclesCnt();
    this.get_rtDvtdTrips(this.curDate);
    this.getTripsData('', '');
    this.selectCategory(0, this.curDate);


  }
  get_rtDvtdTrips(funDt?): any {
    this.showLdr = true;
    this.slctdDt = funDt;
    console.log(this.slctdDt);
    console.log(this.selectedtenant);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      rte_dev_in: 0,
      selectedtenant: this.selectedtenant,
      vndr_id:0

    };
    let rte = `dashboard/enterprise/get_rdTripsCnt`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        this.showLdr = false;
        if (res_data && res_data.length > 0) {
          this.rdCnts = res['data'];
        }
      }
    }, (err) => {
      console.log(err);
    });
  }
  // get_Trips_trvalledDetails(funDt?, slctTnt?): any {
  //   this.tripsTrvldDta = []
  //   this.showLdr = true;
  //   this.slctdDt = funDt;
  //   this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
  //   this.postslctdDt = {
  //     dt: this.curDate,
  //     slctd_tnt: slctTnt
  //   }
  //   let rte = `dashboard/enterprise/get_tdyTripDetails`;
  //   this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       var res_data = res['data'];
  //       console.log(res_data)
  //       if (res_data && res_data.length > 0) {
  //         this.tripsTrvldDta = res['data'];
  //         if (this.clap_in == 1) {
  //           this.tripsTrvldcolumnDefs = [
  //             { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, search: false },
  //             { headerName: 'ULB', field: 'tnt_nm', alignment: 'left', width: 120 },
  //             { headerName: 'Vehicle registration Number', alignment: 'left', width: 120, field: 'asrt_nm' },
  //             { headerName: 'Trip No.', field: 'trp_sqnc_nu', alignment: 'center', width: 'auto' },
  //             { headerName: 'Trip Type', field: 'trp_typ_nm', alignment: 'center',  filter: true,width: 90 },
  //             { headerName: 'From', alignment: 'center', field: 'strt_fnce_nm', width: 220},
  //             { headerName: 'To', field: 'end_fnce_nm', alignment: 'center', width: 220},
  //             { headerName: 'Actual Start', alignment: 'center', field: 'actl_strt_ts', width: 150},
  //             { headerName: 'Actual End', field: 'actl_end_ts', alignment: 'center',width: 150 },
  //             { headerName: 'Trip Status', field: 'trp_status', alignment: 'center',width: 90},
  //             { headerName: 'Travelled Distance', alignment: 'center', field: 'actl_trvl_dstne_ct', width: 'auto' },
  //             { headerName: 'Travelled Time', field: 'actl_trvl_tm', alignment: 'center', width: 'auto'},
  //             { headerName: 'Route Deviation', field: 'rt_dev', alignment: 'center', width: 'auto'},
  //             { headerName: 'Lat', field: 'lat', alignment: 'center', width: 'auto'},
  //             { headerName: 'lng', alignment: 'center', field: 'lng', width: 'auto'},
  //             // { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'center', width: 'auto' },
  //             // { headerName: 'Vehicle Group', field: 'asrt_grp_nm', alignment: 'center', width: 'auto' },
  //           ]
  //         } else {
  //           this.tripsTrvldcolumnDefs = [
  //             { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false,  search: false },
  //             { headerName: 'ULB', field: 'tnt_nm', alignment: 'left', width: 140 },
  //             { headerName: 'Vehicle registration Number', alignment: 'left', width: 130, field: 'asrt_nm', cellClass: 'pm-grid-number-cell' },
  //             { headerName: 'Vehicle Category', alignment: 'left', field: 'asrt_ctgry_nm', cellClass: 'pm-grid-number-cell' },
  //             { headerName: 'Vehicle Group', field: 'asrt_grp_nm', alignment: 'center', cellClass: 'pm-grid-number-cell' },
  //             { headerName: 'Trip No.', field: 'trp_sqnc_nu', alignment: 'center', width: 'auto' },
  //             { headerName: 'Trip Type', field: 'trp_typ_nm', alignment: 'center',  filter: true,width: 90 },
  //             { headerName: 'From', alignment: 'center', field: 'strt_fnce_nm', width: 220},
  //             { headerName: 'To', field: 'end_fnce_nm', alignment: 'center', width: 220},
  //             { headerName: 'Actual Start', alignment: 'center', field: 'actl_strt_ts', width: 150},
  //             { headerName: 'Actual End', field: 'actl_end_ts', alignment: 'center',width: 150 },
  //             { headerName: 'Trip Status', field: 'trp_status', alignment: 'center',width: 90},
  //             { headerName: 'Travelled Distance', alignment: 'center', field: 'actl_trvl_dstne_ct', width: 'auto' },
  //             { headerName: 'Travelled Time', field: 'actl_trvl_tm', alignment: 'center', width: 'auto'},
  //             { headerName: 'Route Deviation', field: 'rt_dev', alignment: 'center', width: 'auto'},
  //             { headerName: 'Lat', field: 'lat', alignment: 'center', width: 'auto'},
  //             { headerName: 'lng', alignment: 'center', field: 'lng', width: 'auto'},
  //             // { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'center', cellClass: 'pm-grid-number-cell' },
  //           ]
  //         }
  //       }
  //       this.showLdr = false;
  //     }
  //   }, (err) => {
  //     // console.log(err);
  //   });
  // }
  gotoPlaybck(event) {
    this.TransfereService.setLoclData('plybckDta', event.row.data)
    this.route.navigate([`internal/play-back/list`]);
  }
  // onCellPrepared(colDef, e) {

  //   if (e.rowType === 'data' && e.row.data && e.column.dataField == 'Vehicle No') {
  //     e.cellElement.style.color = '#ff0000';
  //     e.cellElement.style.fontWeight = 500;
  //     e.cellElement.style.borderRadius = '10px';
  //     e.cellElement.style.background = 'rgba(243, 191, 176, 0.2784313725490196)';
  //     e.cellElement.style.backgroundClip = 'content-box';
  //     e.cellElement.style.cursor = 'pointer';
  //   }

  // }
  getVehDashSldr(evnt) {
    console.log(evnt)
    this.selectedVehicle = evnt.data;
    this.visible = true;

  }
  getsort(id, type) {
    this.showVhcles = true;
    this.getTripsData(id, type)
  }
  getTripsData(val, sts) {
    this.postslctdDt = {
      trp_type_id: val,
      tnt_id: this.selectedtenant,
      sts: sts,
      id: val,
    }
    this.tripsTrvldDta = [];
    this.showLdr = true;
    let rte = `dashboard/enterprise/getTripDetails`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        this.showLdr = false;
        console.log(res_data)
        if (res_data && res_data.length > 0) {
          this.tripsTrvldDta = res['data'];
          if(this.clntDtls[0].clnt_id==7){
            this.tripsTrvldcolumnDefs = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, search: false },
              { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'left', width: 120 },
              { headerName: 'Vehicle registration Number', alignment: 'left', width: 120, field: 'asrt_nm' },
              { headerName: 'Sachivalayam', alignment: 'left', field: 'svm_nm', width: 'auto'  },
              { headerName: 'Driver', alignment: 'left', field: 'crw_nm', width: 'auto'  },
              { headerName: 'Driver Contact', alignment: 'center', field: 'crw_ph', width: 'auto'  },
              { headerName: 'Home Location', alignment: 'left', field: 'hme_fnce_nm' , width: 'auto' },
              { headerName: 'Scheduled Start Time', alignment: 'center', field: 'sflck_nxt_chk_ts', width: 'auto'  },
              { headerName: 'Actual Start Time', alignment: 'center', field: 'actl_fnce_out_ts', width: 'auto' },
              { headerName: 'Trip From Location', alignment: 'center', field: 'strt_fnce_nm',  width: 'auto' },
              { headerName: 'Trip To Location', field: 'end_fnce_nm', alignment: 'center',  width: 'auto' },
              { headerName: 'Trip Start Time', alignment: 'center', field: 'actl_strt_ts',  width: 'auto' },
              { headerName: 'Trip End Time', field: 'actl_end_ts', alignment: 'center',  width: 'auto' },
             { headerName: 'Trip Status', field: 'trp_status', alignment: 'center', width: 'auto'  },
              { headerName: 'Delay In Minutes', field: 'time_diff_min', alignment: 'center',  width: 'auto' },
  
            ]
          }else{
            this.tripsTrvldcolumnDefs = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, search: false },
              { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'left', width: 120 },
              { headerName: 'Vehicle registration Number', alignment: 'left', width: 120, field: 'asrt_nm' },
              { headerName: 'Driver', alignment: 'left', field: 'crw_nm' , width: 'auto' },
              { headerName: 'Driver Contact', alignment: 'center', field: 'crw_ph', width: 'auto'  },
              { headerName: 'Home Location', alignment: 'left', field: 'hme_fnce_nm', width: 'auto'  },
              { headerName: 'Scheduled Start Time', alignment: 'center', field: 'sflck_nxt_chk_ts', width: 'auto'  },
              { headerName: 'Actual Start Time', alignment: 'center', field: 'actl_fnce_out_ts', width: 'auto' },
              { headerName: 'Trip From Location', alignment: 'center', field: 'strt_fnce_nm',  width: 'auto' },
              { headerName: 'Trip To Location', field: 'end_fnce_nm', alignment: 'center',  width: 'auto' },
              { headerName: 'Trip Start Time', alignment: 'center', field: 'actl_strt_ts',  width: 'auto' },
              { headerName: 'Trip End Time', field: 'actl_end_ts', alignment: 'center',  width: 'auto' },
              { headerName: 'Trip Status', field: 'trp_status', alignment: 'center', width: 'auto'  },
              { headerName: 'Delay In Minutes', field: 'time_diff_min', alignment: 'center', width: 'auto'  },
  
            ]
          }

        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  selectCategory(evnt, dt?) {
    this.showLdr = true;
    this.vehTrpLst = [];
    this.vehDupTrpLst = [];
    console.log(evnt, this.curDate);
    this.selectedCtgry = 0;
    this.postslctdDt = {
      dt: this.curDate,
      id: evnt,
      rte_dev_in: 0,
      tnt_id: this.selectedtenant,
      wrd_id:0,
      strt_fnce_id:0,
      end_fnce_id:0,
      svm_id:0,
    };
    const rte = `dashboard/enterprise/get_vehTrpsLst`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.showLdr = false;
          this.vehTrpRteLst = res['data']
          this.vehDupTrpLst = this.vehTrpRteLst;
          let counter = 0;
          this.vehTrpRteLst.filter((k) => {
            k['sno'] = ++counter;
          });
          if(this.clntDtls[0].clnt_id==7){

          this.tripsTrvldRoutecolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, search: false },
            { headerName: 'ULB', field: 'tnt_nm', alignment: 'left', width: 120 },
            { headerName: 'Vehicle registration Number', alignment: 'left', width: 120, field: 'asrt_nm' },
            { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'center', width: 'auto' },
            { headerName: 'Sachivalayam', alignment: 'left', field: 'svm_nm' , width: 'auto' },
            { headerName: 'Driver', alignment: 'left', field: 'crw_nm' , width: 'auto' },
            { headerName: 'Driver Contact', alignment: 'center', field: 'crw_ph', width: 'auto'  },
            { headerName: 'Home Location', alignment: 'left', field: 'hme_fnce_nm', width: 'auto'  },
            { headerName: 'Trip No.', field: 'trp_sqnc_nu', alignment: 'center', width: 'auto' },
            { headerName: 'Trip Status', field: 'trip_sts', alignment: 'center', width: 'auto' },
            { headerName: 'Trip Start Location', alignment: 'left', field: 'strt_fnce_nm',  width: 'auto' },
            { headerName: 'Trip End Location', field: 'end_fnce_nm', alignment: 'left', width: 'auto'  },
            { headerName: 'Start Time', alignment: 'center', field: 'start_tm', width: 'auto'  },
            { headerName: 'End Time', field: 'end_tm', alignment: 'center', width: 'auto'  },
          ];
        }else{
          this.tripsTrvldRoutecolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, search: false },
            { headerName: 'ULB', field: 'tnt_nm', alignment: 'left', width: 120 },
            { headerName: 'Vehicle registration Number', alignment: 'left', width: 120, field: 'asrt_nm' },
            { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'center', width: 'auto' },
            { headerName: 'Driver', alignment: 'left', field: 'crw_nm', width: 'auto'  },
            { headerName: 'Driver Contact', alignment: 'center', field: 'crw_ph', width: 'auto'  },
            { headerName: 'Home Location', alignment: 'left', field: 'hme_fnce_nm' , width: 'auto' },
            { headerName: 'Trip No.', field: 'trp_sqnc_nu', alignment: 'center', width: 'auto' },
            { headerName: 'Trip Status', field: 'trip_sts', alignment: 'center', width: 'auto' },
            { headerName: 'Trip Start Location', alignment: 'left', field: 'strt_fnce_nm',  width: 'auto' },
            { headerName: 'Trip End Location', field: 'end_fnce_nm', alignment: 'left', width: 'auto'  },
            { headerName: 'Start Time', alignment: 'center', field: 'start_tm', width: 'auto'  },
            { headerName: 'End Time', field: 'end_tm', alignment: 'center',  width: 'auto' },
          ];
        }
        } else {
          this.showLdr = false;
        }

      }
    }, (err) => {
      console.log(err);
    });
  }
  getTripSort(id, type) {
    this.vehTrpRteLst = [];
    this.showVhcles = false;
    if (id == 1) {
      if (type == 'rd') {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000 && e.rd_in == 1) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      } else if (type == 'trvl') {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000 && e.trvl_sts == 1) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      } else {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      }

    } else if (id == 3) {
      if (type == 'rd') {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_ctgry == null && e.trp_type_id != 1000000 && e.trp_type_id != 1000001 && e.rd_in == 1) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      } else if (type == 'trvl') {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_ctgry == null && e.trp_type_id != 1000000 && e.trp_type_id != 1000001 && e.trvl_sts == 1) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      } else {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_ctgry == null && e.trp_type_id != 1000000 && e.trp_type_id != 1000001) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      }
    }else if (id == 0) {
      if (type == 'rd') {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001 && e.rd_in == 1) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      } else if (type == 'trvl') {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001 && e.trvl_sts == 1) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      } else {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      }
    }
     else {
      if (type == 'rd') {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.rd_in == 1) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      } else if (type == 'trvl') {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          if (e.trvl_sts == 1) {
            e['sno'] = ++counter;
            this.vehTrpRteLst.push(e)
          }

        });
      } else {
        let counter = 0;
        this.vehDupTrpLst.forEach(e => {
          e['sno'] = ++counter;
        });

        this.vehTrpRteLst = this.vehDupTrpLst;
      }
    }

  }
  onCellPrepared(colDef, e) {
    if (colDef.length && colDef.length > 0) {
      colDef.filter((o) => {

        if (o.field == "asrt_nm") {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }
        if (o.field == "crw_nm") {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }

      })
    }
  }
  devCellclk(devclmns, dvclkent) {
    console.log(dvclkent);

    if (dvclkent.columnIndex == 2) {
      if (dvclkent.data && dvclkent.data.asrt_nm != undefined) {
        this.gotovehcleDashboard(dvclkent)
      }
    }
    if (dvclkent.columnIndex == 4) {
      if (dvclkent.data && dvclkent.data.crw_nm != undefined) {
        this.gotoDrvrSldr(dvclkent)
      }
    }
  }
  onCellPrepared2(colDef, e) {
    if (colDef.length && colDef.length > 0) {
      colDef.filter((o) => {

        if (o.field == "asrt_nm") {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }
        if (o.field == "crw_nm") {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }
        if (o.field == "trp_sqnc_nu") {
          if (e.column.dataField == o.field) {
            e.cellElement.style.color = 'rgb(39, 153, 234)';
            e.cellElement.style.cursor = "pointer";
          }
        }
      })
    }
  }
  devCellclk2(devclmns, dvclkent) {
    console.log(dvclkent);

    if (dvclkent.columnIndex == 2) {
      if (dvclkent.data && dvclkent.data.asrt_nm != undefined) {
        this.gotovehcleDashboard(dvclkent)
      }
    }
    if (dvclkent.columnIndex == 5) {
      if (dvclkent.data && dvclkent.data.crw_nm != undefined) {
        this.gotoDrvrSldr(dvclkent)
      }
    }
    if (dvclkent.columnIndex == 7) {
      if (dvclkent.data && dvclkent.data.trp_rn_id != undefined) {
        this.gototripSldr(dvclkent)
      }
    }

  }
  gotovehcleDashboard(data) {
    this.selectedVehicle = data.data;
    this.visible = true;
  }
  gotoDrvrSldr(evnt) {
    console.log(evnt)
    this.selectedDriver = evnt.data;
    this.visibleDriverSldr = true;
  }
  gototripSldr(evnt) {
    console.log(evnt)
    this.selectedTrip = evnt.data;
    this.trpSldrvisible = true;
  }
}
