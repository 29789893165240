
        <pg-header title="Territorial Units" ticon="folder"  bckbtn=true bckurl="/setup"></pg-header>

        <div nz-row class="gridTop" *ngIf="(user.permissions.slct_in==1)?true:false">
          <ds-loading *ngIf="loader"></ds-loading>
          <div class="ds-card grid">
            <dx-data-grid id="gridContainer" [dataSource]="gridData" [remoteOperations]="false" [allowColumnReordering]="true"
              [allowColumnResizing]="true" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
              [showRowLines]="true" [showBorders]="true" [showBorders]="true" style="width:100%"
              (onToolbarPreparing)="(user.permissions.insrt_in==1)?onToolbarPreparing($event):false">
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                [showInfo]="true">
              </dxo-pager>
              <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
              <dxo-filter-panel [visible]="true"></dxo-filter-panel>
              <dxo-group-panel [visible]="true"></dxo-group-panel>
              <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
              <dxo-column-chooser [enabled]="true" mode="select">
              </dxo-column-chooser>
              <dxo-export [enabled]="(user.permissions.exprt_in==1)?true:false" [allowExportSelectedData]="true"
                            fileName="territorial units List"></dxo-export>
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field"
              [caption]="f.headerName"  [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [allowFiltering]="f.filter">
              </dxi-column>
              <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(user.permissions.dlte_in==1)?true:false"
              [width]="100" caption="Edit" cellTemplate="editTemplate" >
              <div *dxTemplate="let data of 'editTemplate'">
                <div class="tblEdtBtn" (click)="onEdit2(data)">
                  Edit
                </div>
                </div>
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="(user.permissions.dlte_in==1)?true:false"
                type="buttons" [width]="100" caption="Delete" cellTemplate="delTemplate">
                <div *dxTemplate="let data of 'delTemplate'">
                    <div class="tblDelBtn" (click)="onDelete2(data)">
                        Delete
                    </div>
                </div>
            </dxi-column>
            </dx-data-grid>
          </div>
          <div *ngIf="(user.permissions.slct_in==1)?false:true" class="permMsg">{{mainMessage}}</div>
        </div>


<nz-drawer
      [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
      [nzMaskClosable]="false"
      [nzWidth]="370"
      [nzVisible]="shwSidebar"
      [nzTitle]="sidebarHeading"
      (nzOnClose)="closeSideBar()">
      <form nz-form [formGroup]="blockForm">
        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
            
            <div class="inptLbl">District</div>
            <nz-select nzShowSearch nzAllowClear style="width: 100%;" nzPlaceHolder="Select Districts" formControlName="dstrct_id" (ngModelChange)="getulbs($event)">
              <nz-option *ngFor="let d of dstrctsLst" [nzValue]="d.dstrt_id" [nzLabel]="d.dstrt_nm">
              </nz-option>
            </nz-select>
          </div>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
            
            <div class="inptLbl">Urban/Rural</div>
            <nz-select  style="width: 100%;" nzPlaceHolder="Select" formControlName="urbn_in">
              <nz-option *ngFor="let a of area" [nzLabel]="a.area_nm" [nzValue]="a.id">
              </nz-option>
            </nz-select>
          </div>
        </div>
        
        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
            
            <div class="inptLbl">ULB</div>
            <nz-select nzShowSearch nzAllowClear style="width: 100%;" nzPlaceHolder="Select ULB" formControlName="ulb_id" (ngModelChange)="getscvlym()">
              <nz-option *ngFor="let u of ulbLst" [nzValue]="u.ulb_id" [nzLabel]="u.ulb_nm">
              </nz-option>
            </nz-select>
          </div>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
            <div class="inptLbl">Sachivalayam Name</div>
            <nz-select nzShowSearch nzAllowClear style="width: 100%;" nzPlaceHolder="Select Sachivalayam" formControlName="svm_id" (ngModelChange)="getcluster()">
              <nz-option *ngFor="let s of schvlymlst" [nzValue]="s.svm_id" [nzLabel]="s.svm_nm">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
            <div class="inptLbl">Cluster Name</div>
            <nz-select nzShowSearch nzAllowClear style="width: 100%;" nzPlaceHolder="Select Cluster" formControlName="tu_id" (ngModelChange)="getvlntr()">
              <nz-option *ngFor="let c of clusterlst" [nzValue]="c.tu_id" [nzLabel]="c.tu_nm">
              </nz-option>
            </nz-select>
           
          </div>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="blksMrgn">
            <div class="inptLbl">Volunteer Name</div>
            <nz-select nzShowSearch nzAllowClear style="width: 100%;" nzPlaceHolder="Select Volunteer Name" formControlName="vlntr_id" (ngModelChange)="getvlntr()">
              <nz-option *ngFor="let v of vlntrlst" [nzValue]="v.vlntr_id" [nzLabel]="v.vlntr_nm">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div nz-row *ngIf="shwalert == true" style="margin-top: 10px;" class="blksMrgn">
        <nz-alert [nzCloseable]="true" nzType="error" nzMessage="This Volunteer is already assigned. Please choose another Volunteer"></nz-alert>
        </div>

        <button nz-button nzType="primary" class="sbmtBtm" (click)="saveblock()" *ngIf="shwblckEditDiv==false && shwblckDelDiv==false">Submit</button>
        <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEditblock()" *ngIf="shwblckEditDiv==true && shwblckDelDiv==false">Update</button>
        <button nz-button nzType="primary" class="sbmtBtm"  *ngIf="shwblckEditDiv==false && shwblckDelDiv==true"
        nz-popconfirm
        nzPopconfirmTitle="Are you sure delete this volunteer?"
        nzPopconfirmPlacement="bottom"  [nzIcon]="iconTpl"
        nzOkText="YES" nzCancelText="NO"
        (nzOnConfirm)="saveDeleteblock()"
        (nzOnCancel)="cancel()">Delete</button>
        <button nz-button nzType="default" class="cnclBtn" (click)="cancelbtn()">Cancel</button>
        <ng-template #iconTpl>
          <i nz-icon nzType="question-circle-o" style="color: red;"></i>
     </ng-template>

      </form>
    </nz-drawer>
    