import { Component, OnChanges, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexDataLabels,
  ApexPlotOptions,
  ApexFill,
  ApexStroke,
  ApexLegend,
  ApexTitleSubtitle,
  ApexResponsive,
  ApexTheme,
  ApexGrid,
  ApexTooltip
} from 'ng-apexcharts';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ActivatedRoute, Router } from '@angular/router';
export type chartOptionstwo = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  fill: ApexFill;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  theme: ApexTheme;
  colors: any;
  grid: ApexGrid
};

export type multiChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};


am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-apsfl-oms-district-dashboard',
  templateUrl: './apsfl-oms-district-dashboard.component.html',
  styleUrls: ['./apsfl-oms-district-dashboard.component.scss']
})
export class ApsflOmsDistrictDashboardComponent implements OnInit, OnChanges {

  public chartOptionstwo: Partial<chartOptionstwo>;
  public chartOptionsThree: Partial<chartOptionstwo>;
  public multiChartOptions: Partial<multiChartOptions>;
  columnDefs: any;
  tb_id = ''
  slctd_dstrt = null
  dbrd_lvl: any;
  tnt_id: any;
  WorksCrdData: any;
  tripCrdData: any;
  expnsCrdData: any;
  emplyCrdData: any;
  chartOptionsFour: { series: { name: string; data: number[]; }[]; chart: { type: string; height: number; stacked: boolean; }; plotOptions: { bar: { horizontal: boolean; }; }; xaxis: { categories: number[]; title: { text: string; }; }; yaxis: { title: { text: string; }; }; fill: { opacity: number; }; legend: { position: string; horizontalAlign: string; offsetX: number; }; dataLabels: { enabled: boolean; }; colors: string[]; title: { text: string; }; };
  heatMapDtls: any[];
  heatData: any[];
  shrdKy = [];
  chartOptionsFive: {
    series: { name: string; data: any[]; }[]; chart: { type: string; height: number; stacked: boolean; }; plotOptions: { bar: { horizontal: boolean; columnWidth: string; }; }; grid: { borderColor: string; }; xaxis: { categories: any[]; title: { text: string; }; }; yaxis: { title: { text: string; }; }; fill: { opacity: number; }; legend: { position: string; horizontalAlign: string; offsetX: number; }; dataLabels: { enabled: boolean; };
    // colors: ['#5967af', '#fd8900', '#34c38f'],
    colors: string[]; title: { text: string; };
  };
  dstrtdata: any;
  sidenav: boolean;
  param_dstrt_id: any;
  dstrt_nm: any;
  header_label = {
    name: `OMS  District Dashboard  `,
    dtepkrlbl: false
  };
  EmployData;
  tb_nm: any;
  slctCrd_nm: any;
  slctCrd_clr: any;
  slctdCrd: any;
  AsstGrapData: any;
  expenseCtgry=[]
  selectdCtgry=0;
  isGrouped=false;
  drwerTitle: string;
  appCond: number;
  constructor(public apiServ: AppsService, private route: ActivatedRoute, private router: Router,) {
    this.route.queryParams.subscribe(params => {
      console.log(params)
      this.param_dstrt_id = params.dstrt_id
      this.slctCrd_clr = params.crd_clr
      this.slctCrd_nm = params.crd_nm
      this.slctdCrd = {
        crd_hdr: this.slctCrd_nm,
        clr_cd: this.slctCrd_clr
      }
      console.log(this.slctCrd_clr, this.slctCrd_nm)

    })

  }
  lvl_id = null
  gridData = []
  columnDefs1;
  columnDefs2;
  columnDefs3;
  tabs = [
    { tb_nm: 'Activities', tb_id: '1' },
    { tb_nm: 'Trips', tb_id: '2' },
    { tb_nm: 'Expenses', tb_id: '3' }
  ]


  levl_data = [
    { lvl_nm: 'Districts', lvl_id: 1 },
    { lvl_nm: 'By Category', lvl_id: 2 },
    { lvl_nm: 'By Time', lvl_id: 3 }
  ]



  dataSource1 = [

  ];

  dataSource2 = [

  ];
  dataSource3 = [

  ];



  formatter1 = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  formatter2 = new Intl.NumberFormat("en-IN", {
    // style: "currency",
    currency: "INR",
  });
  mapData;
  columnEmplyDefs;
  wrkData;
  ngOnInit() {
    console.log(this.param_dstrt_id)

    this.slctd_dstrt = parseInt(this.param_dstrt_id, 10);
    this.getDstrtData();
    this.lvl_id = 2
    this.getGridData()
    setTimeout(() => {

      // this.last7dayaData()
      this.dstrtdata.forEach(e => {
        if (e.tnt_id == this.slctd_dstrt) {
          this.dstrt_nm = e.tnt_nm
        }
      });
      this.header_label = {
        name: `OMS ${this.dstrt_nm} District Dashboard  `,
        dtepkrlbl: false
      };
    }, 1000);


    this.columnDefs1 = [
      //  { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
      { headerName: 'Mandal ', field: 'mndl', width: 'auto', alignment: 'left' },
      { headerName: 'Work Description ', field: 'oms_wrm_dsc_tx', width: 'auto', alignment: 'left' },
      { headerName: 'Work type', field: 'wrk_typ_nm', alignment: 'left', groupIndex: 2 },
      { headerName: 'Work Group', field: 'wrk_grp_nm', alignment: 'left' },
      { headerName: 'Issue Type', field: 'isue_typ_nm', alignment: 'left' },
      { headerName: 'Work Status', field: 'wrk_sts_nm', alignment: 'left' },
      { headerName: 'No of Customers Effected', field: 'cstmr_efctd_ct', alignment: 'center' },
      { headerName: 'No of Emplaoyees Assigned', field: 'cstmr_efctd_ct', alignment: 'center' },
      // { headerName: 'View', field: '', alignment: 'center' },
    ]
    this.columnDefs = this.columnDefs1
    this.columnDefs2 = [
      { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
      { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left', },
      { headerName: 'Month ', field: 'mnth', width: 'auto', alignment: 'left', visible: this.lvl_id == 3 ? true : false },
      { headerName: 'Total Trips', field: 'ttl', alignment: 'center' },
      { headerName: 'Inprogress', field: 'inprg', alignment: 'center' },
      { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },
      { headerName: 'Distance Travel', field: 'dtnc_trvl', alignment: 'center' },
    ]
    this.columnDefs3 = [
      { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },

      { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left', },
      { headerName: 'Month ', field: 'mnth', width: 'auto', alignment: 'left', visible: this.lvl_id == 3 ? true : false },
      { headerName: 'Work Include Expenses', field: 'wrk_incld_expns', alignment: 'center' },
      { headerName: 'Amount', field: 'amt', alignment: 'center' },

    ]

    setTimeout(() => {
      this.selctedCatrd({
        crd_hdr: this.slctCrd_nm,
        clr_cd: this.slctCrd_clr
      })
      this.ngOnChanges()
    }, 1000);

    this.getDistEmplay()
    this.chartOptionsFour = {
      series: [{
        name: 'Total',
        data: [44, 55, 41, 67, 22, 43, 21, 44, 55, 41, 67, 22, 43, 21, 22, 43, 21]
      }, {
        name: 'In progress',
        data: [13, 23, 20, 8, 13, 27, 33, 13, 23, 20, 8, 13, 27, 33, 22, 43, 21]
      }, {
        name: 'completed',
        data: [11, 17, 15, 15, 21, 14, 15, 11, 17, 15, 15, 21, 14, 15, 22, 43, 21]
      }],
      chart: {
        type: 'bar',
        height: 800,
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: false,
          // columnWidth: '50%',
        },
      },

      xaxis: {
        categories: [29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 18, 17, 16, 15],
        title: {
          text: 'Dates'
        }
      },
      yaxis: {

        title: {
          text: 'Number of Trips'
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#008FFB', '#00E396', '#FEB019'],
      title: {
        text: ''
      }
    };


  }

  ngOnChanges() {
    this.slctd_dstrt = this.slctd_dstrt
    this.slctdCrd = this.slctdCrd
    this.header_label = {
      name: `OMS ${this.dstrt_nm} District Dashboard  `,
      dtepkrlbl: false
    };
  }

  getDstrtData() {

    this.apiServ.get(`apsfl/data/districts`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.dstrtdata = res['data'];
      }

    })

  }

  getDstrwiseData(e) {
    this.dstrtdata.forEach(e => {
      if (e.tnt_id == this.slctd_dstrt) {
        this.dstrt_nm = e.tnt_nm
      }
    });

    console.log(this.dstrt_nm)
    this.ngOnChanges();
    this.getGridData();
    this.getDistEmplay();
  }


  selctedCatrd(e) {
    console.log(e)
    this.tb_nm = e.crd_hdr
    if (e.crd_hdr == 'WORKS') {
      this.tb_id = '1'
      this.ChangeLevel(this.lvl_id)
    } else if (e.crd_hdr == 'TRIPS') {
      this.tb_id = '2'
      this.ChangeLevel(this.lvl_id)
    } else if (e.crd_hdr == 'EXPENSES') {
      this.tb_id = '3'
      this.ChangeLevel(this.lvl_id)
    }
    this.colorChanges(e.clr_cd)
  }

  colorChanges = (clr_cd) => {
    console.log("test case")
    document.documentElement.style.setProperty('--colorcode', clr_cd);

  }

  currencyINRFormater = (val: any) => {
    if (typeof val == 'string') {

    } else {
      return this.formatter1.format(parseInt(val, 10))
        ? this.formatter1.format(parseInt(val, 10))
        : 0;
    }

  };
  INRFormater = (val: any) => {
    if (typeof val == 'string') {

    } else {
      return this.formatter2.format(parseInt(val, 10))
        ? this.formatter2.format(parseInt(val, 10))
        : 0;
    }

  };


  SumINRFormater = (val: any) => {
    if (val) {
      if (val == 'Total:') {
        return val
      }
      return this.formatter2.format(parseInt(val, 10))
        ? this.formatter2.format(parseInt(val, 10))
        : 0;
    }

  };


  sumcurrencyINRFormater = (val: any) => {
    if (val) {
      if (val == 'Total:') {
        return val
      }
      return this.formatter1.format(parseInt(val, 10))
        ? this.formatter1.format(parseInt(val, 10))
        : 0;
    }

  };



  onCellPrepared(e) {

    if (e.rowType === "header") {
      return
    }
   console.log(e)
  }

  onCellClick(e) {
    if (e.rowType === "header") {
      return
    }

    if ((!this.slctd_dstrt && e.columnIndex != 1)) {
      return;
    }

    this.slctd_dstrt = e.data.dstrt_id;
    var disrt_ws_ctgry = []
    // this.category_data1.forEach(e => {
    //   if(e.dstrt_id==this.slctd_dstrt){
    //     disrt_ws_ctgry.push(e)
    //   }
    // });



    this.columnDefs = this.columnDefs1


  }
  getDstrt_Wse_data() {

  }


  getDistEmplay() {
    this.apiServ.get(`apsfl/districts/employee/${this.slctd_dstrt}`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.EmployData = res['data'];
        var count = 1
        this.EmployData.forEach(e => {
          e['sno'] = count++
        });
      }

    })
  }

    // Detect grouping
    onContentReady(e) {
      console.log(e.component.getVisibleColumns())
      const groupedColumns = e.component.getVisibleColumns().filter(c => c.groupIndex !== undefined);

      console.log(groupedColumns)
      this.isGrouped = groupedColumns.length > 0;

      
    }

  async ChangeLevel(e) {
    this.lvl_id = e


    this.columnDefs1 = [];
    this.columnDefs2 = [];
    this.columnDefs3 = [];
    console.log(this.dataSource1, this.dataSource2)
    if (this.tb_id == '1') {
      console.log('categoryyyyyyyyyyyyyyyyyyyyyy', 'tab1')

      this.columnDefs1 = [
        //  { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
        { headerName: 'Mandal ', field: 'mndl_nm', width: 'auto', alignment: 'left' },
        { headerName: 'Work Description ', field: 'oms_wrm_dsc_tx', width: 'auto', alignment: 'left' },
        { headerName: 'Work type', field: 'wrk_typ_nm', alignment: 'left', groupIndex: 2 },
        { headerName: 'Work Group', field: 'wrk_grp_nm', alignment: 'left' },
        { headerName: 'Issue Type', field: 'isue_typ_nm', alignment: 'left' },
        { headerName: 'Work Status', field: 'wrk_sts_nm', alignment: 'left' },
        { headerName: 'No of Customers Effected', field: 'cstmr_efctd_ct', alignment: 'center' },
        { headerName: 'No of Emplaoyees Assigned', field: 'asgn_emps', alignment: 'center' },
      ]

      this.columnEmplyDefs = [
        { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
        { headerName: 'Employee Name', field: 'crw_nm', width: 'auto', alignment: 'left' },
        { headerName: 'Mobile Number ', field: 'crw_ph', width: 'auto', alignment: 'left' },
        { headerName: 'License', field: 'crw_lcnse_nu', alignment: 'left', },
        { headerName: 'Date Of Birth', field: 'DOB', alignment: 'left' },
        { headerName: 'No of Works Assigned', field: 'no_of_wrk_assigned', alignment: 'center' },
      ]
      this.columnDefs = this.columnDefs1
      this.gridData = this.dataSource1
      this.EmployData = this.EmployData

      console.log(this.gridData)
    }
    else if (this.tb_id == '2') {

      console.log('categoryyyyyyyyyyyyyyyyyyyyyy', 'tab1')
      this.columnDefs2 = [
        { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },

        { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left' },
        // { headerName: 'No. of Vehicles', field: 'ttl', alignment: 'center' },
        // { headerName: 'Operated', field: 'ttl', alignment: 'center' },
        // { headerName: 'Not Operated', field: 'ttl', alignment: 'center' },
        { headerName: 'Total Trips', field: 'ttl', alignment: 'center' },
        { headerName: 'In Trips', field: 'inprg', alignment: 'center' },
        { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },
        // { headerName: 'Distance Travel', field: 'dtnc_trvl', alignment: 'center' },
      ]
      this.columnDefs = this.columnDefs2
      this.gridData = this.dataSource2
      this.chartOptionsThree = this.chartOptionsThree

    }
    else if (this.tb_id == '3') {
      console.log('categoryyyyyyyyyyyyyyyyyyyyyy', 'tab1')
      this.columnDefs3 = [
        { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
        { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left' },
        { headerName: 'Work Include Expenses', field: 'wrk_incld_expns', alignment: 'center' },
        { headerName: 'Total Amount', field: 'amt', alignment: 'center', format: this.currencyINRFormater },


      ]
      this.columnDefs = this.columnDefs3
      this.gridData = this.dataSource3
      this.chartOptionsThree = this.chartOptionsThree
    }

  }


  getGridData() {
    var postdata = {
      lvl_id: this.lvl_id,
      tb_id: this.tb_id,
      dstrt_id: this.slctd_dstrt
    }
    this.dataSource1 = []
    this.dataSource2 = []
    this.dataSource3 = []
    this.gridData = []
    this.apiServ.post(postdata, `apsfl/district/data/grid`,).subscribe((res) => {
      if (res['status'] == 200) {
        var data = res['data'];
        this.dataSource1 = data[0],
          this.dataSource2 = data[1],
          this.dataSource3 = data[2],
          console.log(this.dataSource1)
        var co1 = 1
        var co2 = 1
        var co3 = 1
        this.dataSource1.forEach(e => {
          e['sno'] = co1++
        });
        this.dataSource2?.forEach(e => {
          e['sno'] = co2++
        });
        this.dataSource3?.forEach(e => {
          e['sno'] = co3++
        });
        this.ChangeLevel(this.lvl_id)
      }

    })
  }

 


  gotoProfile(data,cnd) {
    console.log(data)
    this.sidenav = true;
    if(cnd=='work'){
      this.appCond=1
    this.drwerTitle='Work Profile'
    }else if(cnd=='employ'){
      this.appCond=2
       this.drwerTitle='Employee Profile'
    }

    this.wrkData = data.data;
  }
  closeSideBar() {
    this.sidenav = false
  }

  gotoDstrctDshbrd() {
    this.router.navigate(['/internal/dashboard/apsfloms'])
  }
}
