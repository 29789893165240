import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-trips-slider',
  templateUrl: './trips-slider.component.html',
  styleUrls: ['./trips-slider.component.scss']
})
export class TripsSliderComponent implements OnChanges {
  @Input() trip;
  curYr;trpDta;
  today = new Date();
  showLdr = false;
  startPBMarker: any;
  endPBMarker: any;
  startPBMarker1: any;
  endPBMarker1: any;
  polylinePoints: any;
  actl_Polyline: any;
  route_Polyline:any=[];
  vehicleMap1:any;
  postslctdDt: {};
  rteDta: any;
  vehMarker:any;
  eachVeh: any[];
  chart2Msg: string;
  errMsg: string;
  chart1Msg: string;
  usrDtls;
  routemap: any = [];
  schdStartPBMarker: any = [];
  schdEndPBMarker: any = [];
  ipMarker: any;
  curDate;
  ipIcon = new L.Icon({
    iconUrl: '/assets/images/markers/mapIcons/m/colctn_pnt_nt_cvrd_m.png',
    iconSize: [42, 45],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  ipPts: any = [];
  mrkerPts: any;
  rtrstrng: string;
  shwAddBtn: boolean;
  rte_id: any;
  rte_nm: any;
  trp_sqnc_nu: any;
  fncDtls: any;
  cvrdIps: any;
  ipMarker1: any;
  strngarc: string;
  trp_schde_id: any;
  shwNodata: boolean;
  rte_plyln_id: any;
  clntDtls: any;
  tntLst:any=[];
  selectedtenant;
  showsetRte;
    constructor(public apiServ: AppsService,private message: NzMessageService) { 
      this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
      this.tntLst = this.clntDtls[0].tnts;
      this.selectedtenant = this.tntLst[0].tnt_id;
    }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.trip.currentValue);
    console.log('inn Trip Sliderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr');
    this.curYr = moment(this.today).format('YYYY');
    this.trpDta = changes.trip.currentValue;
    console.log(this.trpDta);
    // this.getTrpDetails(this.trpDta.trp_rn_id);
    // this.getLast7DaysData(this.trpDta);
    this.initialize();
    // this.getTripMap(this.trpDta, this.today)
    this.getTripMap(this.trpDta, this.today)  ;

  }
  initialize = function () {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.usrDtls);
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.routemap = L.map('routeDevtmap', {
        center: [this.usrDtls[0].lat, this.usrDtls[0].lng],
        zoom: 3,
        // editable: true
      });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        minZoom: 3,
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: ''      });
      tiles.addTo(this.routemap);
      this.routemap.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 13)

    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
  }
  // getTrpDetails(trpId){
  //   this.showLdr =  true;
  //   this.trpDta = []
  //   this.apiServ.get(`dashboard/enterprise/get_Trpsdata/${trpId}`).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       var res_data = res['data'];
  //       this.showLdr =  false;
  //       if (res_data.length >= 0) {
  //         this.trpDta = res['data'][0];
  //         console.log(this.trpDta);
  //       }
  //             // this.getLast7DaysData(this.trpDta);
  //     }
  //   }, (err) => {
  //     // console.log(err);
  //   });
  // }
  getTripMap(c, dt) {
    this.rte_nm = '';
    this.showLdr = true;
    console.log(c, dt);
    console.log(this.schdStartPBMarker)
    this.rte_nm = c.rte_nm;
    this.trp_sqnc_nu = c.trp_sqnc_nu;
    this.trp_schde_id=c.trp_schde_id;
    this.rte_plyln_id=c.rte_plyln_id
    // this.vehTrpLst.forEach(ele => {
    //   ele.shwAddBtn = false;
    //   this.showsetRte=false;
    //   if (ele.trp_schde_id != c.trp_schde_id) {
    //     ele.isSelected = false;
    //   } else {
    //     ele.isSelected = true;
    //   }
    // });
    if (this.route_Polyline != undefined) {
      this.routemap.removeLayer(this.route_Polyline);
    }



    // });

    if (c.rte_arc_lne_tx && c.rte_arc_lne_tx != null) {
      this.polylinePoints = JSON.parse(c.rte_arc_lne_tx);
    } else {
      this.showLdr = false;
      // this.createMessage('warning', 'No Polyline Added to the Trip.To add polyline visit wetrackon App')
    }
    this.getCvrdFncs(c);

    if (this.schdStartPBMarker) {
      this.routemap.removeLayer(this.schdStartPBMarker);
    }

    if (this.schdEndPBMarker) {
      this.routemap.removeLayer(this.schdEndPBMarker);
    }
    // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
    var startIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/startPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    if (this.polylinePoints && this.polylinePoints[0]) {
      console.log(this.polylinePoints[0])
      this.schdStartPBMarker = L.marker(this.polylinePoints[0], {
        icon: startIcon
      }).addTo(this.routemap);
    }

    // ----------Fence END Area (flag position) -- Leaflet OS ----------//
    var endIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/endPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    if (this.polylinePoints) {
      var k = this.polylinePoints.length - 1
      if (this.polylinePoints[k]) {
        this.schdEndPBMarker = L.marker(this.polylinePoints[k], {
          icon: endIcon
        }).addTo(this.routemap);
      }
    }


    if (c.rte_arc_lne_tx) {
      this.route_Polyline = L.polyline(JSON.parse(c.rte_arc_lne_tx), {
        color: '#008000',
        weight: 5,
        lineCap: 'butt',
        opacity: 0.6,
        smoothFactor: 10.0,
        lineJoin: 'round',
        // editable: true
      }).addTo(this.routemap);
      this.routemap.fitBounds(this.route_Polyline.getBounds());
      // this.route_Polyline.enableEdit();
    }
    console.log(c.ipPts)
if(c.start_tm!='0000-00-00 00:00:00' && c.end_tm!='0000-00-00 00:00:00'){
  this.curDate = moment(dt).format('YYYY-MM-DD');
  this.rte_id = c.rte_id;
  this.postslctdDt = {
    dt: this.curDate,
    data: c
  }
  // Getting travelled route line arc
  const rte = `dashboard/enterprise/get_tripTrvlRoute`;
  this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
    this.showLdr = true;

    if (res['status'] == 200) {
      var res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.showLdr = false;
        this.rteDta = res['data'];
        console.log(this.rteDta)
        var restr = '';
        this.rtrstrng = '';
        var arcrestr = '';

        var tmp;
        for (var i = 0; i < this.rteDta.length; i++) {
          restr += (restr == "") ? "[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]" : ",[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]";
          this.rtrstrng = restr;
          arcrestr += (arcrestr == "") ? this.rteDta[i].lat + " " + this.rteDta[i].lng : "," + this.rteDta[i].lat + " " + this.rteDta[i].lng;

        }

        var lstrng = arcrestr.split("[").join("");
        var lstrng2 = lstrng.split("]").join("");
        this.strngarc = lstrng2;
        // console.log("LINESTRING(" + lstrng2 + ")");

//         this.vehTrpLst.forEach(ele => {
//           if (ele.rte_id == c.rte_id && this.rtrstrng.length > 0 && ele.trp_rn_id == c.trp_rn_id) {
//             ele.shwAddBtn = true;
// // if(ele.shwAddBtn==true){
//               this.showsetRte=true;
//             // }else{
//             //   this.showsetRte=false;
//             // }           
//            } else {
//             ele.shwAddBtn = false;
//             this.showsetRte=false; 
//           }

        // });
        // if (restr) {
        //   this.shwAddBtn = true;
        // } else {
        //   this.shwAddBtn = false;
        // }
        // console.log("[" + restr + "]");
        if (this.actl_Polyline) {
          this.routemap.removeLayer(this.actl_Polyline);
        }
        if (this.startPBMarker1) {
          this.routemap.removeLayer(this.startPBMarker1);
        }
        if (this.endPBMarker1) {
          this.routemap.removeLayer(this.endPBMarker1);
        }
        // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
        var startIcon1 = new L.Icon({
          iconUrl: '/assets/images/markers/playback/startPoint.png',
          iconSize: [42, 45],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
        });
        this.startPBMarker1 = L.marker([this.rteDta[0].lat, this.rteDta[0].lng], {
          icon: startIcon1
        }).addTo(this.routemap);
        // ----------Fence END Area (flag position) -- Leaflet OS ----------//
        var endIcon1 = new L.Icon({
          iconUrl: '/assets/images/markers/playback/endPoint.png',
          iconSize: [42, 45],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
        });
        var k1 = this.rteDta.length - 1
        this.endPBMarker1 = L.marker(this.rteDta[k1], {
          icon: endIcon1
        }).addTo(this.routemap);


        this.actl_Polyline = L.polyline(JSON.parse("[" + restr + "]"), {
          color: 'red',
          weight: 4,
          lineCap: 'butt',
          opacity: 0.5,
          smoothFactor: 10.0,
          lineJoin: 'round'
        }).addTo(this.routemap);
        this.routemap.fitBounds(this.actl_Polyline.getBounds());


      } else if (res_data.length == 0) {
        this.showLdr = false;
        if (this.actl_Polyline) {
          this.routemap.removeLayer(this.actl_Polyline);
        }
        if (this.startPBMarker1) {
          this.routemap.removeLayer(this.startPBMarker1);
        }
        if (this.endPBMarker1) {
          this.routemap.removeLayer(this.endPBMarker1);
        }
        this.createMessage('warning', 'Travelled Route not found!!!!');

      }
      else {
        this.showLdr = false;
        if (this.actl_Polyline) {
          this.routemap.removeLayer(this.actl_Polyline);
        }
        if (this.startPBMarker1) {
          this.routemap.removeLayer(this.startPBMarker1);
        }
        if (this.endPBMarker1) {
          this.routemap.removeLayer(this.endPBMarker1);
        }
        // this.createMessage('warning', 'No data found!!!!');
      }
    }
  }, (err) => {
    console.log(err);
  });
}else{
  this.createMessage('warning', 'Travelled Route not found!!!!');

}

  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  getCvrdFncs(c) {
    if (this.ipPts) {
      for (var x = 0; x < this.ipPts.length; x++) {
        this.routemap.removeLayer(this.ipPts[x]);
      }
    }
    if (this.cvrdIps) {
      for (var y = 0; y < this.cvrdIps.length; y++) {
        this.routemap.removeLayer(this.cvrdIps[y]);
      }
    }

    console.log(c)
    var postData = {
      asrt_id: c.asrt_id,
      rte_id: c.rte_id,
      tnt_id: this.selectedtenant,
      strt_tm: c.start_tm,
      end_tm: c.end_tm
    }
    console.log(postData)
    const rte = `tripschedule/sachivalayam/trips/get_cvrd_fncs`;
    this.apiServ.post(postData, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.fncDtls = res['data'];
        if (this.fncDtls) {
          for (var i = 0; i < this.fncDtls.length; i++) {
            if (this.fncDtls[i].lat && this.fncDtls[i].lng) {
              if (this.fncDtls[i].fnce_cvr_sts == 1) {
                this.ipMarker = L.circleMarker([this.fncDtls[i].lat, this.fncDtls[i].lng], {
                  radius: 6,
                  fillColor: "#008000",
                  color: "#008000",
                  weight: 1,
                  opacity: 1,
                  fillOpacity: 1
                }).addTo(this.routemap).bindTooltip(this.fncDtls[i].fnce_nm, { permanent: false, opacity: 0.7 }).openTooltip();;

                this.ipPts.push(this.ipMarker);
              } else {
                this.ipMarker1 = L.circleMarker([this.fncDtls[i].lat, this.fncDtls[i].lng], {
                  radius: 6,
                  fillColor: "#e85339",
                  color: "#e85339",
                  weight: 1,
                  opacity: 1,
                  fillOpacity: 1
                }).addTo(this.routemap).bindTooltip(this.fncDtls[i].fnce_nm, { permanent: false, opacity: 0.7 }).openTooltip();;

                this.cvrdIps.push(this.ipMarker1);
              }
            }

            // this.createMessage('success', 'Schedule Route added Successfully');
            // this.selectCategory(0, this.curDate);
          }
        } else {
          if (this.ipPts) {
            for (var x = 0; x < this.ipPts.length; x++) {
              this.routemap.removeLayer(this.ipPts[x]);
            }
          }
          if (this.cvrdIps) {
            for (var y = 0; y < this.cvrdIps.length; y++) {
              this.routemap.removeLayer(this.cvrdIps[y]);
            }
          }
        }

      }
    });

  }
  // getLast7DaysData(c){
  //   console.log(c);
  //   let chart1 = am4core.create("lastSevenDaysDistanceChart", am4charts.XYChart);
  //   let chart2 = am4core.create("lastSevenDaysChart", am4charts.XYChart);
  //   chart1.data = [];
  //   chart2.data = [];

  //   this.apiServ.get(`dashboard/enterprise/vehicleTargets/${c.asrt_id}`).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       var res_data = res['data'];
  //       if (res_data) {
  //         chart1.data = res['data'];
  //         if(chart1.data.length == 0){
  //           this.chart1Msg = 'Last 7Days Distance not found to this vehicle.';
  //         }else{
  //           this.chart1Msg = '';
  //         }
  //         console.log(chart1.data);
  //             // Create axes
  //             let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
  //             categoryAxis1.dataFields.category = "dt";
  //             categoryAxis1.renderer.grid.template.location = 0;
  //             categoryAxis1.renderer.minGridDistance = 30;
  //             categoryAxis1.title.text = ` ----- Date(${this.curYr}) ----- `;
  //             // categoryAxis1.renderer.labels.template.rotation = -45;


  //             let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
  //             valueAxis1.title.text = " ----- Distance Covered -----";
  //             valueAxis1.cursorTooltipEnabled = false;
  //             // valueAxis.title.fontWeight = 'bold';

  //             // Create series
  //             let series1 = chart1.series.push(new am4charts.ColumnSeries());
  //             series1.dataFields.valueY = "mn_dst_ct";
  //             series1.dataFields.categoryX = "dt";
  //             series1.clustered = false;
  //             series1.tooltipText = "Total Distance in {categoryX}: [bold]{valueY}[/]";
  //             series1.columns.template.fill = am4core.color("#a9dcf4");
  //             series1.columns.template.stroke = am4core.color("#a9dcf4");


  //             let series2 = chart1.series.push(new am4charts.ColumnSeries());
  //             series2.dataFields.valueY = "trp_dst";
  //             series2.dataFields.categoryX = "dt";
  //             series2.clustered = false;
  //             series2.columns.template.width = am4core.percent(50);
  //             series2.tooltipText = "Covered Distance in {categoryX}: [bold]{valueY}[/]";
  //             series2.columns.template.fill = am4core.color("#92c5dd");
  //             series2.columns.template.stroke = am4core.color("#92c5dd");
  //             let valueLabel = series2.bullets.push(new am4charts.LabelBullet());
  //             valueLabel.label.text = "{trp_dst}";
  //             valueLabel.label.fontSize = 15;
  //             let columnTemplate1 = series2.columns.template;
  //             columnTemplate1.strokeWidth = 1;
  //             // columnTemplate1.width = 60;
  //             // columnTemplate1.column.cornerRadiusTopLeft = 5;
  //             // columnTemplate1.column.cornerRadiusTopRight = 5;


  //             chart1.cursor = new am4charts.XYCursor();
  //             chart1.cursor.lineX.disabled = true;
  //             chart1.cursor.lineY.disabled = true;

  //           // console.log('****************************** CrntDyDst', this.CrntDyDst);
  //       }
  //       if (res_data) {
  //         chart2.data = res['data'];
  //         this.eachVeh = chart2.data;
  //         if(this.eachVeh.length == 0){
  //           this.chart2Msg = 'Last 7Days Trips not found to this vehicle.';
  //         }else{
  //           this.chart2Msg = '';
  //         }
  //        //  console.log(this.eachVeh);
  //                   // Create axes
  //           let categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
  //           categoryAxis.dataFields.category = "dt";
  //           categoryAxis.renderer.grid.template.location = 0;
  //           categoryAxis.renderer.minGridDistance = 30;
  //           categoryAxis.title.text = ` ----- Date(${this.curYr}) ----- `;
  //           // categoryAxis.renderer.labels.template.rotation = -45;


  //           let valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
  //           valueAxis.title.text = " ----- Trip Count -----";
  //           valueAxis.cursorTooltipEnabled = false;
  //           // valueAxis.title.fontWeight = 'bold';

  //           // Create series
  //           let series3 = chart2.series.push(new am4charts.ColumnSeries());
  //           series3.dataFields.valueY = "mn_trp_ct";
  //           series3.dataFields.categoryX = "dt";
  //           series3.clustered = false;
  //           series3.tooltipText = "Total Trips in {categoryX}: [bold]{valueY}[/]";
  //           series3.columns.template.fill = am4core.color("#f4a9be");
  //           series3.columns.template.stroke = am4core.color("#f4a9be");


  //           let series4 = chart2.series.push(new am4charts.ColumnSeries());
  //           series4.dataFields.valueY = "trp_cnt";
  //           series4.dataFields.categoryX = "dt";
  //           series4.clustered = false;
  //           series4.columns.template.width = am4core.percent(50);
  //           series4.tooltipText = "Covered Trips in {categoryX}: [bold]{valueY}[/]";
  //           series4.columns.template.fill = am4core.color("#dd92a7");
  //           series4.columns.template.stroke = am4core.color("#dd92a7");

  //           let valueLabel = series4.bullets.push(new am4charts.LabelBullet());
  //           valueLabel.label.text = "{trp_cnt}";
  //           valueLabel.label.fontSize = 15;
  //           let columnTemplate1 = series4.columns.template;
  //           columnTemplate1.strokeWidth = 1;

  //           chart2.cursor = new am4charts.XYCursor();
  //           chart2.cursor.lineX.disabled = true;
  //           chart2.cursor.lineY.disabled = true;

  //       }
  //       if(res_data == []){
  //         this.errMsg = `Last 7Days Trips not found to this vehicle.`
  //       }
  //       else{
  //         this.errMsg = ``
  //       }
  //       this.showLdr = false;

  //     }
  //   }, (err) => {
  //     // console.log(err);
  //   });

  //   // this.apiServ.get(`dashboard/enterprise/echVehicleTrips/${c.asrt_id}`).subscribe((res) => {
  //   //   if (res['status'] == 200) {
  //   //     var res_data = res['data'];
  //   //     this.showLdr = false;
  //   //    //  console.log(res_data)
  //   //     if (res_data) {
  //   //       chart2.data = res['data'];
  //   //       this.eachVeh = chart2.data;
  //   //       if(this.eachVeh.length == 0){
  //   //         this.chart2Msg = 'Last 7Days Trips not found to this vehicle.';
  //   //       }else{
  //   //         this.chart2Msg = '';
  //   //       }
  //   //      //  console.log(this.eachVeh);
  //   //                 // Create axes
  //   //         let categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
  //   //         categoryAxis.dataFields.category = "dt";
  //   //         categoryAxis.renderer.grid.template.location = 0;
  //   //         categoryAxis.renderer.minGridDistance = 30;
  //   //         categoryAxis.title.text = ` ----- Date(${this.curYr}) ----- `;
  //   //         // categoryAxis.renderer.labels.template.rotation = -45;


  //   //         let valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
  //   //         valueAxis.title.text = " ----- Trip Count -----";
  //   //         valueAxis.cursorTooltipEnabled = false;
  //   //         // valueAxis.title.fontWeight = 'bold';

  //   //         // Create series
  //   //         let series3 = chart2.series.push(new am4charts.ColumnSeries());
  //   //         series3.dataFields.valueY = "mn_trp_ct";
  //   //         series3.dataFields.categoryX = "dt";
  //   //         series3.clustered = false;
  //   //         series3.tooltipText = "Total Trips in {categoryX}: [bold]{valueY}[/]";
  //   //         series3.columns.template.fill = am4core.color("#f4a9be");
  //   //         series3.columns.template.stroke = am4core.color("#f4a9be");


  //   //         let series4 = chart2.series.push(new am4charts.ColumnSeries());
  //   //         series4.dataFields.valueY = "trp_cnt";
  //   //         series4.dataFields.categoryX = "dt";
  //   //         series4.clustered = false;
  //   //         series4.columns.template.width = am4core.percent(50);
  //   //         series4.tooltipText = "Covered Trips in {categoryX}: [bold]{valueY}[/]";
  //   //         series4.columns.template.fill = am4core.color("#dd92a7");
  //   //         series4.columns.template.stroke = am4core.color("#dd92a7");

  //   //         let valueLabel = series4.bullets.push(new am4charts.LabelBullet());
  //   //         valueLabel.label.text = "{trp_cnt}";
  //   //         valueLabel.label.fontSize = 15;
  //   //         let columnTemplate1 = series4.columns.template;
  //   //         columnTemplate1.strokeWidth = 1;

  //   //         chart2.cursor = new am4charts.XYCursor();
  //   //         chart2.cursor.lineX.disabled = true;
  //   //         chart2.cursor.lineY.disabled = true;

  //   //     }
  //   //     if(res_data == []){
  //   //       this.errMsg = `Last 7Days Trips not found to this vehicle.`
  //   //     }
  //   //     else{
  //   //       this.errMsg = ``
  //   //     }
  //   //     this.showLdr = false;
  //   //   }
  //   // }, (err) => {
  //   //   // console.log(err);
  //   // });



  // }



}
