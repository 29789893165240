<!-- <div class="page-header">
    <div class="row align-items-md-center">
      <div class="col-md-6">
        <div class="media m-v-10">
          <i nz-icon [nzType]="ticon" nzTheme="twotone" class="header-icon"></i>&nbsp;
          <div class="media-body m-l-15 header-border">
            <h6 class="mb-0 header-label">{{title}}</h6>
            <span class="text-gray font-size-13">{{sbtitle}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="bckbtn">
      
      </div>

    </div>
  </div> -->

  <div class="page-header">
    <div class="row align-items-md-center">
        <div class="col-md-6" style="margin-left: 11px">
            <div class="media m-v-10">
                <!-- <i nz-icon nzType="dashboard" nzTheme="twotone" class="header-icon"></i>&nbsp; -->
                <div class="media-body m-l-15 header-borders">
                    <h6 class="mb-0 header-label" style="margin-bottom:0px">{{title}}</h6>
                    <span class="text-gray font-size-13">{{sbtitle}}</span>
                </div>
            </div>
        </div>
    </div>
</div>