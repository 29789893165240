import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FinesRepComponent } from './fines-rep/fines-rep.component';
import { ReportsComponent} from './reports.component';
import { UserfeeRepComponent } from './userfee-rep/userfee-rep.component';
import { MctcollectRepComponent } from './mctcollect-rep/mctcollect-rep.component';
import { BuildersComponent } from './builders/builders.component';
// import { ChargesDashboardComponent } from './charges-dashboard/charges-dashboard.component';
import { CustomReportComponent } from './custom-report/custom-report.component';


const routes: Routes = [
  { path: 'list', component: ReportsComponent,  pathMatch : 'full' },
  { path: 'reportbuilder', component: BuildersComponent, pathMatch : 'full'},
  { path: 'list/custom/:rpturltx', component: CustomReportComponent , pathMatch : 'full'},
  { path: '**', redirectTo : 'list' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
