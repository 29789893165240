import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { NzMessageModule , NzMessageService} from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent implements OnInit {

  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  gridData: any;
  isedit : boolean = false;
  isadd: boolean = false;
  isdel: boolean = false;
  editid:any ;
  delid: any;
  shwblckEditDiv:boolean
  shwblckDelDiv:boolean;
  editdata;
  currentUser: any = {};

  constructor(private fb: FormBuilder,private themeService: ThemeConstantService, public apiSrv: AppsService,public atmSrv: AtomServiceService,
     private message: NzMessageService,private modal: NzModalService,private notification: NzNotificationService) {
   }

   columnDefs: any;
   sidebarHeading;
   initdata: any;
   loader:boolean;
   visible = false;
   gridApi;
   shwSidebar:boolean;
   shwalert : boolean = false;
   selectdstrct
   mndlLst=[]
   clusterlst = [];
   schvlymlst = [];
   vlntrlst = [];
   Asgnvlntrlst = [];
   ulbLst=[];
   deldata
   user: any = {
     permissions: { 'slct_in': 1, 'insrt_in': 1, 'updt_in': 1, 'dlte_in': 1, 'exprt_in': 1 },
    //  'perm_url': 'user/permissions/Desginations Creation'
   }
   permissions;
   mainMessage: any = null;
   dstrctsLst=[]
   area=[];
   blockForm;
   fnlmndlsLst=[];
   dstrct_id;
   ulb_id;
   svm_id;
   tu_id;

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.blockForm = new FormGroup({
      vlntr_id: new FormControl(''),
      tu_id: new FormControl(''),
      svm_id: new FormControl(''),
      dstrct_id: new FormControl(''),
      urbn_in: new FormControl(''),
      ulb_id: new FormControl('')
    });
    this.getGridData();
  }

  getdistricts()
{
  this.apiSrv.get('admin/districts').subscribe((res) => {
    this.dstrctsLst=res['data']
  })
}
getarea()
{
  this.area=[
    {
      id:1,
      area_nm:"Urban"
    },
    {
      id:0,
      area_nm:"Rural"
    }
  ]
}

//   getmandls()
// {
//   this.dstrct_id=this.blockForm.value.dstrct_id
//   let rte =`getmandals/` +this.dstrct_id
//   // console.log(data)
//   this.apiSrv.get(rte).subscribe((res) => {
//     this.mndlLst=res['data']
//   })

// }

getulbs(e)
{
  // console.log(e)
  this.dstrct_id=this.blockForm.value.dstrct_id
  let rte =`admin/districts/${e}/ulbs`
  // console.log(rte)
  this.apiSrv.get(rte).subscribe((res) => {
    // console.log(res['data'])
    this.ulbLst=res['data']
  })
}

 
getscvlym(){
  this.ulb_id = this.blockForm.value.ulb_id;
  if(this.ulb_id  !== ''){
    console.log(`sachivalayams/ulbs/${this.ulb_id}`);
    let rte = `sachivalayams/ulbs/` + this.ulb_id;
    this.apiSrv.get(rte).subscribe((res) => {
      this.schvlymlst = res['data'];
   //   console.log(this.schvlymlst);
    });
  }
}

getcluster()
{
  this.svm_id = this.blockForm.value.svm_id;
  if (this.svm_id !== ''){
 //   console.log(this.blockForm.value.svm_id);
    let rte = `sachivalayams/block/` + this.svm_id;
    this.apiSrv.get(rte).subscribe((res) => {
      this.clusterlst = res['data'];
   //   console.log(this.clusterlst);
    });
  }
}


getvlntr(){
  this.tu_id = this.blockForm.value.tu_id;
  if (this.tu_id !== ''){
 //   console.log(this.blockForm.value.tu_id);
    let rte = `sachivalayams/volunteer/` + this.tu_id;
    this.apiSrv.get(rte).subscribe((res) => {
      this.vlntrlst = res['data'];
   //   console.log(this.vlntrlst);
    });
  }
}



// getmandlsbyarea(e)
// {
//   this.fnlmndlsLst=[];
//   if(e==1)
//   {
//     this.mndlLst.filter((m)=>{
//       if(m.urbn_in == 1)
//       {
//         this.fnlmndlsLst.push(m)
//       }
//     })
//   }
//   if(e == 0)
//   {
//     this.mndlLst.filter((m)=>{
//       if(m.urbn_in == 0)
//       {
//         this.fnlmndlsLst.push(m)
//       }
//     })
//   }
// }
  getGridData() {
    this.mainMessage = null;
    this.loader=true;
    this.apiSrv.get('sachivalayams/blocks').subscribe((res) => {
    console.log(res)
      if (res['status'] == 200) {
        this.loader=false;
        if (res['data'].length === 0) { this.mainMessage = 'No entries found in the database.'; }
        this.gridData = res['data'];
        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 60, sortable: true, filter: false },
          { headerName: 'Volunteer Name', field: 'vlntr_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'Volunteer Mobile No', field: 'mble_nu', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'Cluster Name', field: 'tu_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'Sachivalayam Name', field: 'svm_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'ULB', field: 'ulb_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'District', field: 'dstrt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'Area', field: 'area', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell',  sortable: true, filter: true },
          { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true }

        ];
      }
      // else if (res['status'] === 404) {
      //   let permTxt = 'Desginations Creation';
      //   let prmeRte = `user/permissions/${permTxt}`;
      //   this.crdsrv.get(prmeRte).subscribe((prmres) => {
      //   // console.log(prmres['data']);

      //   });
      //   // this.user.permissions = { 'slct_in': 0, 'insrt_in': 0, 'updt_in': 0, 'dlte_in': 0, 'exprt_in': 0 };
      // }
    });
  }
  
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Assign New Volunteer',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(opt,data)
  {
    this.shwSidebar=true;
 //   console.log(data);
    if (opt == 'edit') {
      this.shwalert = false;
    this.shwblckEditDiv = true;
    this.shwblckDelDiv = false;
    this.sidebarHeading = 'Edit Volunteer';
    this.blockForm.get('urbn_in').setValue(data.urbn_in)
    this.blockForm.get('ulb_id').setValue(data.ulb_id);
    this.blockForm.get('tu_id').setValue(data.tu_id);
    this.blockForm.get('svm_id').setValue(data.svm_id);
    this.blockForm.get('vlntr_id').setValue(data.vlntr_id);
    this.blockForm.get('dstrct_id').setValue(data.dstrt_id)
    this.getdistricts();
    this.getarea();
    this.getcluster();
    this.getscvlym();
  } else if (opt == 'delete') {
    this.shwblckEditDiv = false;
    this.shwblckDelDiv = true;
    this.shwalert = false;
    this.sidebarHeading = 'Delete Volunteer';
      this.blockForm.get('urbn_in').setValue(data.urbn_in)
    this.blockForm.get('vlntr_id').setValue(data.vlntr_id);
    this.blockForm.get('tu_id').setValue(data.tu_id);
    this.blockForm.get('svm_id').setValue(data.svm_id);
    this.blockForm.get('ulb_id').setValue(data.ulb_id);
    this.blockForm.get('dstrct_id').setValue(data.dstrt_id)
    this.getdistricts();
    this.getarea();
    this.getcluster();
    this.getscvlym();
    this.getvlntr();
  } else {
    this.shwblckEditDiv = false;
    this.shwblckDelDiv = false;
    this.shwalert = false;
    this.getdistricts();
    this.getarea();
    this.sidebarHeading = 'Assign New Volunteer';
  }

  }

  closeSideBar(): void {
    this.shwSidebar = false;
  }
  
  onEdit2(event)
  {
 //   console.log(event)
    this.editdata=event.data
    this.openSideBar("edit",this.editdata)
  }
  onDelete2(event)
  {
 //   console.log(event)
    this.deldata=event.data
    this.openSideBar("delete",this.deldata)
  }
  saveblock()
  {
    this.shwalert = false;
    const data = {
    vlntr_id: this.blockForm.value.vlntr_id,
    tu_id: this.blockForm.value.tu_id
   };
    let rte=`sachivalayams/blocks`;
    this.apiSrv.create(data, rte).subscribe((res) => {
//   //   console.log(res);
      if(res['status'] == 200){
      if (res['data'] == "Volunteer Already Assgined To Other Area"){
        this.shwalert = true;
      } else {
        this.shwSidebar = false;
        this.createMessage('success', 'Volunteer Successfully Added');
        this.getGridData();
      }
    }
    })
  }

  saveEditblock()
  {
  //  console.log(this.editdata)
  //  console.log(this.blockForm.value)
  const data = {
    vlntr_id: this.blockForm.value.vlntr_id
   };
  let rte=`sachivalayams/block/`+ this.editdata.vlntr_tu_rel_ky;
  this.apiSrv.put(data, rte).subscribe((res) => {
      if (res['status'] == 200){
        this.shwSidebar = false;
        this.createMessage('info', 'Volunteer Successfully Updated');
        this.getGridData();
      }
    });
  }
  saveDeleteblock()
  {
    let rte= `sachivalayams/block/` + this.deldata.vlntr_tu_rel_ky;
    this.apiSrv.delete(rte).subscribe((res) => {
      if (res['status'] == 200){
        this.shwSidebar = false;
        // this.shwDprtAlrt = true;
        this.createMessage('warning', 'Volunteer Successfully Deleted');
        this.getGridData();
      }
    })
  }

  cancelbtn()
  {
    this.shwSidebar=false;
    this.blockForm.reset()
  }
  cancel(){
    this.message.info('Volunteer Not Deleted', {
      nzDuration: 2000
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

}
