import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { AppsService } from 'src/app/shared/services/apps.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-vehicle-slider',
  templateUrl: './vehicle-slider.component.html',
  styleUrls: ['./vehicle-slider.component.scss']
})
export class VehicleSliderComponent implements OnChanges {
  @Input() vechile;
  vehDta: any;
  vehicleMap
  currentDate; 
  recievedTime;
  diffTimeInMin;
  diffTimeInHrs;
  diffTimeInDys;
  seconds;
  minutes;
  hours;
  slctdDt = new Date();
  today = new Date();
  init_lat = 17;
  init_lng = 81;
  oprtnlhrs: any;
  vstdLctns: any;
  oprtdNtoprtd: any;
  trpLst: any;
  showLdr = false; ttl_veh;
  VehLctns: any;
  ovrSpeed: any;
  fuelDta: any;
  vehRts: any = [];
  vehTrgts: any;
  vehnm: any;
  asrt_sts_nm: any;
  ctgrynm: any;
  grpnm: any;
  CrntDyDst: any;
  hrlyDta: any;
  postslctdDt: any;
  rteDta: any;
  dateFormat = 'yyyy/MM/dd';
  selectedCtgry = 0;
  vehCtgrys: any;
  currentFrmtDate: string;
  currentTimeStmp: any;
  params: any;
  curYr: any;
  selectedvndr;
  selectedtenant;
  vendorList: any; clntDtls: any;
  tntLst: any = [];
  errMsg: string;
  vehErrMsg;
  vstdLctnsMsg: string;
  ovrSpeedMsg: string;
  oprtnlhrsMsg: string;
  fuelDtaMsg: string;
  chart1Msg: string;
  chart2Msg: string;
  ttl_vehMsg: string;
  vehTrpLstMsg: string;
  previousNotificationsMsg: string;
  vehId: any;
  selectedVehicle: any;
  filterData: any;
  previousNotifications: any;
  vehOdmtr: any;
  devOdmtr: any;
  imei: any;
  chasis: any;
  eachVeh: any = []; totCvrsBns = 0; totBins; CrntDyFuel; CrntDyFlAmt; vehTrpLst;
  data_lst_rcvd: any;
  speed: any;
  vehHrlyDtls: any[];
  postData: { selectedVehicle: any; };
  vehHrlyMsg: string;
  tripsMsg: string;
  dst_tvl_tdy: any;
  VhcleDetails: any;
  // gotVehId: any;
  userDtls:any;
  constructor(public apiServ: AppsService) {
    this.slctdDt = new Date();
    console.log(this.slctdDt);
    this.currentDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.currentFrmtDate = moment(this.slctdDt).format('DD-MM-YYYY');  
    this.currentTimeStmp = moment(this.slctdDt).format('DD-MM-YYYY HH:mm:ss');
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    // this.gotVehId = [];
  }
  ngOnChanges(changes: SimpleChanges) {

    console.log(changes.vechile.currentValue);
    console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn');
    this.curYr = moment(this.today).format('YYYY');
    this.getPreviousNotifications(changes.vechile.currentValue.asrt_id);
    this.getVehiclehourlyDtls(changes.vechile.currentValue.asrt_id);
    this.vehnm = changes.vechile.currentValue.asrt_nm;
    console.log(this.vehnm)
    this.getVehicleDtls(changes.vechile.currentValue.asrt_id);
  

  }
  getVehicleDtls(asrt_id){
    this.VhcleDetails=[];
    this.apiServ.get(`drivers/vehicles/vehicleById/${asrt_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        // var res_data = res['data'];
        // if (res_data) {
          this.VhcleDetails = res['data'][0];
          console.log(this.VhcleDetails);
        // }
        this.vehnm = this.VhcleDetails.asrt_nm;
        this.asrt_sts_nm = this.VhcleDetails.asrt_sts_nm;
        this.ctgrynm = this.VhcleDetails.asrt_ctgry_nm;
        this.grpnm = this.VhcleDetails.asrt_grp_nm;
        this.vehOdmtr = this.VhcleDetails.veh_odmtr_ct;
        this.devOdmtr = this.VhcleDetails.dvce_odmtr_ct;
        this.imei = this.VhcleDetails.dev_imei_nu;
        this.chasis = this.VhcleDetails.asrt_chses_nu;
        this.data_lst_rcvd = this.VhcleDetails.data_received_ts; 
        this.currentTimeStmp = moment(this.slctdDt).format('YYYY-MM-DD HH:mm:ss');
        this.recievedTime = moment(this.data_lst_rcvd).format('YYYY-MM-DD HH:mm:ss');
        // console.log(this.recievedTime)
        // console.log( this.currentTimeStmp)
        this.diffTimeInMin = moment(this.currentTimeStmp).diff(this.recievedTime, 'minute'); 
        this.diffTimeInHrs = moment(this.currentTimeStmp).diff(this.recievedTime, 'hours');
        this.diffTimeInDys = moment(this.currentTimeStmp).diff(this.recievedTime, 'days');
        // console.log(this.diffTimeInMin)
        // console.log(this.diffTimeInHrs)
        // console.log(this.diffTimeInDys)
        
        this.speed = this.VhcleDetails.speed_ct;
        this.dst_tvl_tdy=this.VhcleDetails.tdy_dst;
        this.CrntDyDst=this.VhcleDetails.trp_dst;
    //   }
    // }
    console.log(this.vehnm, this.asrt_sts_nm, this.ctgrynm, this.grpnm)
        if (this.VhcleDetails.asrt_sts_nm) {
          this.asrt_sts_nm = this.VhcleDetails.asrt_sts_nm;
        } else if (this.VhcleDetails.trp_status) {
          this.asrt_sts_nm = this.VhcleDetails.trp_status;
        } else { this.asrt_sts_nm = '' }
        this.ctgrynm = this.VhcleDetails.asrt_ctgry_nm;
        this.grpnm = this.VhcleDetails.asrt_grp_nm;
        this.selectedVehicle = this.VhcleDetails.asrt_id;
        this.vehId = this.VhcleDetails.asrt_id;
        console.log(this.VhcleDetails);
        this.get_allCtgrysData(this.vehId);
        this.getVstdLctns(this.vehId);
        this.getVhcleTrgts(this.vehId);
        this.initialize();

      }
    });


  }
  initialize = function () {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.vehicleMap = L.map('vehicleMap', {
        center: [this.init_lat, this.init_lng],
        zoom: 3
      });

      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   maxZoom: 18,
      //   minZoom: 3,
      //   attribution: ''
      // });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        minZoom: 3,
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: ''      });

      tiles.addTo(this.vehicleMap);

    } catch (e) {
      // console.log("please check map Initialization of leaflet:" + e);
    }
    if (this.vehMarker) {
      this.vehicleMap.removeLayer(this.vehMarker);
    }
    if (this.VhcleDetails.asrtglblId == "1") {
      if (this.VhcleDetails.spd_ct <= '5') {
        var icon = "_p_m.png";
      } else {
        var icon = "_r_m.png";
      }
    } else {
      var icon = "_o_m.png";
    }
    var vehIcon = new L.Icon({
      iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.VhcleDetails.icon_path + icon,
      iconSize: [30, null],
      iconAnchor: [10, 5],
      popupAnchor: [5, -20],
      shaowSize: [35, 35]
    });
    console.log(this.VhcleDetails.lat,this.VhcleDetails.lng)
    if(this.VhcleDetails.lat && this.VhcleDetails.lng){
      this.vehMarker = L.marker([this.VhcleDetails.lat, this.VhcleDetails.lng], {
        icon: vehIcon
      }).addTo(this.vehicleMap);
  
      var bounds = [];
      bounds.push([this.VhcleDetails.lat, this.VhcleDetails.lng]);
      this.vehicleMap.fitBounds(bounds);
      var coords = L.latLng([this.VhcleDetails.lat, this.VhcleDetails.lng]);
      L.popup().setLatLng(coords).setContent(this.VhcleDetails.asrt_nm ).openOn(this.vehicleMap);
     
    }
  };
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and todayvehTrpLst
    return differenceInCalendarDays(current, this.today) > 0;
  };
  vehMarker: any;
getVstdLctns(veh_id){
  this.apiServ.get(`dashboard/enterprise/echVehicleLocations/${veh_id}`).subscribe((res) => {
    if (res['status'] == 200) {
      var res_data = res['data'];
      if (res_data) {
        this.vstdLctns = res['data'];
        console.log(this.vstdLctns.length);
        if (this.vstdLctns.length == 0) {
          this.vstdLctnsMsg = 'Visited locations not found to this vehicle.';
        } else {
          this.vstdLctnsMsg = '';
        }
      }
      this.showLdr = false;

    }
  }, (err) => {
    // console.log(err);
  });
}
getVhcleTrgts(veh_id){
  let chart1 = am4core.create("lastSevenDaysDistanceChart", am4charts.XYChart);
  let chart2 = am4core.create("lastSevenDaysChart", am4charts.XYChart);
  chart1.data = [];
  chart2.data = []; 
  this.apiServ.get(`dashboard/enterprise/vehicleTargets/${veh_id}`).subscribe((res) => {
    if (res['status'] == 200) {
      // var res_data = res['data'];
      console.log(res['data'])
      // if (res_data) {
        chart1.data = res['data']['results'];
        if (res['data'].msg == 0) {
          this.chart1Msg = 'Last 7Days Distance not found to this vehicle.';
        } else {
          this.chart1Msg = '';
        }
        console.log(chart1.data);
        // Create axes
        let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis1.dataFields.category = "dt";
        categoryAxis1.renderer.grid.template.location = 0;
        categoryAxis1.renderer.minGridDistance = 30;
        categoryAxis1.title.text = ` ----- Date(${this.curYr}) ----- `;
        // categoryAxis1.renderer.labels.template.rotation = -45;


        let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
        valueAxis1.title.text = " ----- Distance Covered -----";
        valueAxis1.cursorTooltipEnabled = false;
        // valueAxis.title.fontWeight = 'bold';

        // Create series
        let series1 = chart1.series.push(new am4charts.ColumnSeries());
        series1.dataFields.valueY = "mn_dst_ct";
        series1.dataFields.categoryX = "dt";
        series1.clustered = false;
        series1.tooltipText = "Total Distance in {categoryX}: [bold]{valueY}[/]";
        series1.columns.template.fill = am4core.color("#a9dcf4");
        series1.columns.template.stroke = am4core.color("#a9dcf4");


        let series2 = chart1.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "trp_dst";
        series2.dataFields.categoryX = "dt";
        series2.clustered = false;
        series2.columns.template.width = am4core.percent(50);
        series2.tooltipText = "Covered Distance in {categoryX}: [bold]{valueY}[/]";
        series2.columns.template.fill = am4core.color("#92c5dd");
        series2.columns.template.stroke = am4core.color("#92c5dd");
        let valueLabel = series2.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{trp_dst}";
        valueLabel.label.fontSize = 15;
        let columnTemplate1 = series2.columns.template;
        columnTemplate1.strokeWidth = 1;
        // columnTemplate1.width = 60;
        // columnTemplate1.column.cornerRadiusTopLeft = 5;
        // columnTemplate1.column.cornerRadiusTopRight = 5;


        chart1.cursor = new am4charts.XYCursor();
        chart1.cursor.lineX.disabled = true;
        chart1.cursor.lineY.disabled = true;

        for (let j = 0; j <= chart1.data.length - 1; j++) {
          if (chart1.data[j].dt == this.currentFrmtDate) {
            this.CrntDyDst = chart1.data[j].trp_dst;
          }
        }
        // console.log('****************************** CrntDyDst', this.CrntDyDst);
      // }
      this.eachVeh = [];
      if (res['data']) {
        chart2.data = res['data']['results'];
        this.eachVeh = chart2.data;
        if (res['data'].msg == 0) {
          this.tripsMsg = 'Last 7Days Trips not found to this vehicle.';
        } else {
          this.tripsMsg = '';
        }
        //  console.log(this.eachVeh);
        // Create axes
        let categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "dt";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.title.text = ` ----- Date(${this.curYr}) ----- `;
        // categoryAxis.renderer.labels.template.rotation = -45;


        let valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = " ----- Trip Count -----";
        valueAxis.cursorTooltipEnabled = false;
        // valueAxis.title.fontWeight = 'bold';

        // Create series
        let series3 = chart2.series.push(new am4charts.ColumnSeries());
        series3.dataFields.valueY = "mn_trp_ct";
        series3.dataFields.categoryX = "dt";
        series3.clustered = false;
        series3.tooltipText = "Targeted Trips in {categoryX}: [bold]{valueY}[/]";
        series3.columns.template.fill = am4core.color("#f4a9be");
        series3.columns.template.stroke = am4core.color("#f4a9be");


        let series4 = chart2.series.push(new am4charts.ColumnSeries());
        series4.dataFields.valueY = "trp_cnt";
        series4.dataFields.categoryX = "dt";
        series4.clustered = false;
        series4.columns.template.width = am4core.percent(50);
        series4.tooltipText = "Travelled Trips in {categoryX}: [bold]{valueY}[/]";
        series4.columns.template.fill = am4core.color("#dd92a7");
        series4.columns.template.stroke = am4core.color("#dd92a7");

        let valueLabel = series4.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{trp_cnt}";
        valueLabel.label.fontSize = 15;
        let columnTemplate1 = series4.columns.template;
        columnTemplate1.strokeWidth = 1;

        chart2.cursor = new am4charts.XYCursor();
        chart2.cursor.lineX.disabled = true;
        chart2.cursor.lineY.disabled = true;


        // for (let i = 0; i <= this.eachVeh.length - 1; i++) {
        //   if (this.eachVeh[i].asrt_id == veh_id) {
        //     console.log(this.eachVeh[i].asrt_id == veh_id, this.eachVeh[i].asrt_id, veh_id)
           
      }
      if (res['data'] == undefined) {
        this.errMsg = `Last 7Days Trips not found to this vehicle.`
      }
      else {
        this.errMsg = ``
      }


      this.showLdr = false;

    }
  }, (err) => {
    // console.log(err);
  });
}
  get_allCtgrysData(veh_id) {
    //  console.log(veh_id)
   this.vehTrpLst = []; this.oprtdNtoprtd = []; this.ovrSpeed = []; this.hrlyDta = []; this.vstdLctns = []; this.VehLctns = [];
    this.oprtnlhrs = []; this.fuelDta = []; this.vehRts = []; this.eachVeh = [];
    this.showLdr = true;
    this.apiServ.get(`dashboard/enterprise/vehicleOperated/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.oprtdNtoprtd = res['data'];
          // console.log(this.oprtdNtoprtd);
        }


      }
    }, (err) => {
      // console.log(err);
    });

    this.apiServ.get(`dashboard/enterprise/echVehicleOvrSpeed/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.ovrSpeed = res['data'];
          // console.log(this.oprtdNtoprtd);
          if (this.ovrSpeed.length == 0) {
            this.ovrSpeedMsg = 'Speed Violation not found to this vehicle.';
          } else {
            this.ovrSpeedMsg = '';
          }
        }
        this.showLdr = false;

      }
    }, (err) => {
      // console.log(err);
    });

    this.apiServ.get(`dashboard/enterprise/distanceCoveredByasrtId/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.hrlyDta = res['data'];
          // console.log(this.hrlyDta);

        }
        this.showLdr = false;

      }
    }, (err) => {
      // console.log(err);
    });


 
    this.apiServ.get(`dashboard/enterprise/locations/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.VehLctns = res['data'];
          // console.log(this.VehLctns);
          this.totBins = this.VehLctns.length;
          for (let i = 0; i <= this.VehLctns.length - 1; i++) {
            if (this.VehLctns[i].covered_in == 1) {
              this.totCvrsBns = this.totCvrsBns + 1;
            }
          }
          // console.log('*******************************************',this.totBins,'/',this.totCvrsBns)
        }
        this.showLdr = false;

      }
    }, (err) => {
      // console.log(err);
    });
    this.apiServ.get(`dashboard/enterprise/operational/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.oprtnlhrs = res['data'];
          // console.log(this.oprtnlhrs);
          if (this.oprtnlhrs.length == 0) {
            this.oprtnlhrsMsg = 'No Operational Details found to this vehicle.';
          } else {
            this.oprtnlhrsMsg = '';
          }
        }
        this.showLdr = false;

      }
    }, (err) => {
      // console.log(err);
    });
    // this.apiServ.get(`dashboard/enterprise/fuel/${veh_id}`).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     var res_data = res['data'];
    //     if (res_data) {
    //       this.fuelDta = res['data'];
    //       console.log(this.fuelDta);
    //       if(this.fuelDta.length == 0){
    //         this.fuelDtaMsg = 'Fuel Issued not found to this vehicle.';
    //       }else{
    //         this.fuelDtaMsg = '';
    //       }
    //       for (let j = 0; j <= this.fuelDta.length - 1; j++) {
    //         if (this.fuelDta[j].fl_dt == this.currentDate) {
    //             this.CrntDyFuel = this.fuelDta[j].fl_ltrs_ct;
    //             this.CrntDyFlAmt = this.fuelDta[j].fl_amnt_ct;
    //         }
    //     }
    //     // console.log('********************************',this.CrntDyFuel,this.CrntDyFlAmt);
    //     }
    //     this.showLdr = false;

    //   }
    // }, (err) => {
    //   // console.log(err);
    // });
    this.apiServ.get(`dashboard/enterprise/vehicleRoutes/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.vehRts = res['data'];
          // console.log(this.vehRts);
        }
        this.showLdr = false;

      }
    }, (err) => {
      // console.log(err);
    });
   

    // this.postslctdDt = {
    //   dt: this.currentDate,
    //   asrt_id: veh_id,
    //   tnt: this.selectedtenant,
    //   ctgry: this.selectedCtgry,
    //   vndr: this.selectedvndr,
    //   tnt_in: 1
    // }
    // //  console.log('posting', this.postslctdDt);
    // const rte = `dashboard/enterprise/get_SnglevehTrpsLst`;
    // this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     var res_data = res['data'];
    //     if (res_data) {
    //       this.vehTrpLst = res['data'];
    //       console.log(this.vehTrpLst)
    //       if (this.vehTrpLst.length == 0) {
    //         this.vehTrpLstMsg = 'Trips not found to this vehicle.';
    //       } else {
    //         this.vehTrpLstMsg = '';
    //       }
    //     }

    //     this.showLdr = false;
    //   }
    // }, (err) => {
    //   // console.log(err);
    // });
  }

  getPreviousNotifications(veh_id) {
    this.showLdr = true;
    this.previousNotifications = [];
    const rte = `dashboard/enterprise/last_10VehNtfcntsLst/${veh_id}`;
    this.apiServ.get(rte).subscribe((res) => {
      this.showLdr = false;
      if (res['status'] == 200) {
        let data = res['data'];
        this.previousNotifications = data;
      }
      if (this.previousNotifications == 0) {
        this.previousNotificationsMsg = 'Notifications not found to this vehicle.';
      } else {
        this.previousNotificationsMsg = '';
      }
    }, (error) => {
      this.showLdr = false;
    })
  }

  getVehiclehourlyDtls(veh_id): any {
    console.log('in getVehiclehourlyDtls');
    this.vehHrlyDtls = [];
    this.showLdr = true;
    console.log(veh_id);
    this.postData = {
      selectedVehicle: veh_id
    }
    const rte = `drivers/vehicle/hourly_distance`;
    let chart = am4core.create("hepachartdiv", am4charts.XYChart);
    chart.data = [];
    console.log(this.postData);
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.vehHrlyDtls = res['data'];
      console.log(this.vehHrlyDtls);
      this.vehHrlyDtls.forEach(obj => {
        if (obj.dst == null) {
          obj.dst = 0;
        }
        else {
          obj.dst = obj.dst
        }

      })
      chart.data = this.vehHrlyDtls;


      chart.maskBullets = true;

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      xAxis.dataFields.category = "trvl_hr";
      yAxis.dataFields.category = "trvl_dt";

      xAxis.title.text = "------Hour-------";
      yAxis.title.text = "------Date-------";

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 10;
      yAxis.renderer.fontSize = 11;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "trvl_hr";
      series.dataFields.categoryY = "trvl_dt";
      series.dataFields.value = "dst";
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      var bgColor = new am4core.InterfaceColorSet().getFor("background");

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 0.2;
      // columnTemplate.strokeWidth = 2;
      // columnTemplate.strokeOpacity = 1;
      columnTemplate.stroke = am4core.color("eff567");
      columnTemplate.tooltipText = "{dst}";
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        min: am4core.color(bgColor),
        max: chart.colors.getIndex(0)
      });
      var legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.data = [{
        "name": "Distance Covered",
        "fill": am4core.color("#bec4f8")
      }];

      // series.heatRules.push({
      // 	target:columnTemplate, 
      // 	property:"fill", 
      // 	min:am4core.color("#f5f6fe"), 
      // 	max:am4core.color("#554abd"),
      // 	minValue: 2000,
      // 	maxValue: 5000
      //   });


      // heat legend
      var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
      heatLegend.width = am4core.percent(100);
      heatLegend.series = series;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
      heatLegend.valueAxis.renderer.minGridDistance = 30;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{dst}";
      valueLabel.label.fontSize = 15;

      // columnTemplate1.width = 60;
      columnTemplate.column.cornerRadiusTopLeft = 3;
      columnTemplate.column.cornerRadiusTopRight = 3;
      columnTemplate.column.cornerRadiusBottomLeft = 3;
      columnTemplate.column.cornerRadiusBottomRight = 3;

      // heat legend behavior
      series.columns.template.events.on("over", function (event) {
        handleHover(event.target);
      })

      series.columns.template.events.on("hit", function (event) {
        handleHover(event.target);
      })

      function handleHover(column) {
        if (!isNaN(column.dataItem.value)) {
          heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
        }
        else {
          heatLegend.valueAxis.hideTooltip();
        }
      }

      series.columns.template.events.on("out", function (event) {
        heatLegend.valueAxis.hideTooltip();
      })

      if (this.vehHrlyDtls.length == 0) {
        this.vehHrlyMsg = 'Hourly Distance not found to this driver.';
      } else {
        this.vehHrlyMsg = '';
      }
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }
}
