import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mctcollect-rep',
  templateUrl: './mctcollect-rep.component.html',
  styleUrls: ['./mctcollect-rep.component.css']
})
export class MctcollectRepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {


    
  }

}
