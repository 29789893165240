<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" nzTitle="Vehicle Dashboard"
    (nzOnClose)="visible = false" class="dashbSlider">
    <ng-container *nzDrawerContent>
    <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
    </ng-container>
</nz-drawer>
<div class="dashboard">
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="livemap" class="shadowcard svmdbrdgrph"></div>

        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
            <nz-card class="shadowcard" style="width:100%; height:auto;">
                <div class="row bg-wp">
                    <h1 style="color: #9e1644;padding-left: 10px;
                    padding-top: 10px;
                    letter-spacing: 0px;">Sachivalayam Vehicles Data</h1>
                    <div class="ds-card grid tbl">
                        <dx-data-grid id="gridContainer" [dataSource]="vehcleTble" [remoteOperations]="false"
                            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                            [wordWrapEnabled]="true">
                            <dxo-paging [pageSize]="10"></dxo-paging>
                            <dxo-pager [visible]="vehcleTble && vehcleTble.length>10" [showPageSizeSelector]="true"
                                [allowedPageSizes]="[10, 20, 30, 50]" [showInfo]="true">
                            </dxo-pager>
                            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                            </dxo-search-panel>
                            <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                            <dxo-group-panel [visible]="true"></dxo-group-panel>
                            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                            <dxo-column-chooser [enabled]="true" mode="select">
                            </dxo-column-chooser>
                            <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                            <dxo-export [enabled]="true" fileName="vendor Vehicles Data"></dxo-export>
                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                            <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [caption]="d.headerName"
                                [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                            </dxi-column>
                            <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100"
                                caption="View" cellTemplate="editTemplate">
                                <div *dxTemplate="let data of 'editTemplate'">
                                    <div class="tblEdtBtn" (click)="gotovehcleDashboard(data)"><i nz-icon nzType="car"
                                            nzTheme="outline"></i></div>
                                </div>
                            </dxi-column>


                        </dx-data-grid>
                    </div>
                    <div>Click on <i nz-icon nzType="car" nzTheme="outline"></i> to View details of Vehicle</div>
                </div>
            </nz-card>
        </div>
    </div>
</div>