import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api.service';

@Component({
  selector: 'ds-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  hostname;
  lgnPgData: any;
  err_msg: string;
  clntDtls: any;
  usrDtls: any;
  constructor(public apiSrc: ApiService,) { 
    this.hostname = location.hostname;
    this.apiSrc.get('admin/web/login/page-details/' + this.hostname).subscribe((res) => {
      if (res['status'] == 200) {
        if (res['data']) {
          // console.log('kkkkkkkk',res['data'])
           this.lgnPgData = res['data'][0];
        } else {
          this.err_msg = "Something went wrong. Please contact the administrator.";
        }
      } else {
        this.err_msg = "Something went wrong. Please contact the administrator.";
      }
    }, (err) => {
      this.err_msg = "Something went wrong. Please contact the administrator.";
    })
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.usrDtls = JSON.parse(localStorage.getItem('wto_w_user'));
  }
  ngOnInit() {
  }

}
