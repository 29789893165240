import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SetupComponent } from './setup.component';
import { DesignationsComponent } from './designations/designations.component';
import { DepartmentsComponent } from './departments/departments.component';
import { UsersComponent } from './users/users.component';
// import { NewFineAmountsComponent } from './new-fine-amounts/new-fine-amounts.component';
import { OrganizationComponent } from './organization/organization.component';
import { MicropocketsComponent } from './micropockets/micropockets.component';
import { WardsComponent } from './wards/wards';
import { ULBsComponent } from './ulbs/ulbs';
import { StreetsComponent } from './streets/streets';
import { AuthGuardService } from '../auth/auth-guard.service';
// import { FineCategoriesComponent} from './fine-categories/fine-categories.component';
// import { DomesticUserChargesComponent } from './domestic-user-charges/domestic-user-charges.component';
// import { CommericalUserChargesComponent } from './commerical-user-charges/commerical-user-charges.component';
// import { FinesComponent } from './fines/fines.component';
// import { UserChargesStreetComponent } from './user-charges-street/user-charges-street.component';
import { BlocksComponent } from './blocks/blocks.component';
import {SachivalayamsComponent}  from './sachivalayams/sachivalayams.component'
import { GatesComponent } from './gates/gates';
import { GateTypesComponent } from './gateTypes/gateTypes';
import { MenuProfileComponent } from './menu-profile/menu-profile.component';
import { HouseStatusComponent } from "./house-status/house-status.component";
import { HousesComponent } from './houses/houses.component';
import { DistrictsComponent } from './districts/districts.component';
import { MandalsComponent } from './mandals/mandals.component';
import { StateComponent } from './state/state.component';

// import { RolesComponent } from './roles/roles.component';
import {SetupProfileComponent} from './setup-profile/setup-profile.component'
// import {HelpDocumentationComponent} from './help-documentation/help-documentation.component';
import {HelpDocPagesComponent} from './help-doc-pages/help-doc-pages.component';
import {HelpFaqsComponent} from './help-faqs/help-faqs.component'
import { ReportProfileComponent } from './report-profile/report-profile.component';
// import { ChangeLogComponent } from './change-log/change-log.component';
import { HouseTypesComponent } from './house-types/house-types.component';
import {HelpvideosComponent} from './help-videos/help-videos.component';
// import { CollectionTypeComponent} from './collection-type/collection-type.component';
// import { TelegramComponent } from './telegram/telegram.component';
import { RolesPremessionsComponent } from './roles-premessions/roles-premessions.component';
import {AssetCategoriesComponent} from './asset-categories/asset-categories.component';
import {AssetLocationsComponent} from './asset-locations/asset-locations.component';
import {AssetGroupsComponent} from './asset-groups/asset-groups.component';
import {AssetModelsComponent} from './asset-models/asset-models.component';
import {AssetAgreementsComponent} from './asset-agreements/asset-agreements.component';
import {AssetVendorsComponent} from './asset-vendors/asset-vendors.component';
import {AssetTypesComponent} from './asset-types/asset-types.component';
import { AssetIssueTypesComponent } from './asset-issue-types/asset-issue-types.component';
import { AssertCategoriesComponent } from './assert-categories/assert-categories.component';
import { VehicleGroupsComponent } from './vehicle-groups/vehicle-groups.component';
import { AssertStatusComponent } from './assert-status/assert-status.component';
import { LayoutAppComponent } from 'src/app/layouts/layout-app/layout-app.component';
// import { UsermanagementComponent } from '../election/usermanagement/usermanagement.component';
// import { TicketCategoryListComponent } from './ticketing/ticket-category-list/ticket-category-list.component';


const routes: Routes = [
  { path: 'list', component: SetupComponent , pathMatch: 'full' },
  { path: 'designations', component: DesignationsComponent , pathMatch: 'full' },
  { path: 'organization', component: OrganizationComponent , pathMatch: 'full' },
  { path: 'ulbs', component: ULBsComponent , pathMatch: 'full' },
  { path: 'departments', component: DepartmentsComponent , pathMatch: 'full' },
  { path: 'streets', component: StreetsComponent , pathMatch: 'full' },
  { path: 'users', component: UsersComponent , pathMatch: 'full' },
  // { path: 'fines', component: NewFineAmountsComponent , pathMatch: 'full' },
  // { path: 'fine-categories', component: FineCategoriesComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  { path: 'micropockets', component: MicropocketsComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  { path: 'wards', component: WardsComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  { path: 'gates', component: GatesComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  { path: 'gatetypes', component: GateTypesComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  // { path: 'domestic/user/charges', component: DomesticUserChargesComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  // { path: 'commerical/user/charges', component: CommericalUserChargesComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  // { path: 'fine/charges', component: FinesComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  // { path: 'ulb/usercharges/street_vendors', component: UserChargesStreetComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  {path : 'sachivalayams',component:SachivalayamsComponent,pathMatch:'full'},
  // {path : 'collection/type',component:CollectionTypeComponent,pathMatch:'full'},
  {path : 'territorial_units',component:BlocksComponent,pathMatch:'full'},
  {path: 'house_status',component:HouseStatusComponent,pathMatch:'full'},
  {path: 'house_types',component:HouseTypesComponent,pathMatch:'full'},
  {path: 'houses',component:HousesComponent,pathMatch:'full'},
  { path: 'menu-profile', component: MenuProfileComponent , pathMatch: 'full' },
  { path: 'report-profile', component: ReportProfileComponent , pathMatch: 'full' },
  { path: 'districts', component: DistrictsComponent, pathMatch: 'full' },
  { path: 'mandals', component: MandalsComponent, pathMatch: 'full' },
  { path: 'state', component: StateComponent, pathMatch: 'full' },
  // { path: 'helpdoc', component: HelpDocumentationComponent, pathMatch: 'full' },
  { path: 'helppge', component: HelpDocPagesComponent, pathMatch: 'full'},
  { path: 'helpvideos', component: HelpvideosComponent, pathMatch: 'full'},
  { path: 'FAQs', component: HelpFaqsComponent, pathMatch: 'full'},
  // { path: 'changelog', component: ChangeLogComponent , pathMatch: 'full' },
  // { path: 'roles', component: RolesComponent , pathMatch: 'full' },
  { path: 'roles', component: RolesPremessionsComponent , pathMatch: 'full' },
  { path: 'setup-profile', component: SetupProfileComponent , pathMatch: 'full' },
  // { path: 'telegram', component: TelegramComponent , pathMatch: 'full' },
  { path: 'asset/categories', component: AssetCategoriesComponent , pathMatch: 'full' },
  { path: 'asset/locations', component: AssetLocationsComponent , pathMatch: 'full' },
  { path: 'asset/groups', component: AssetGroupsComponent , pathMatch: 'full' },
  { path: 'asset/models', component: AssetModelsComponent , pathMatch: 'full' },
  { path: 'asset/agreement-types', component: AssetAgreementsComponent , pathMatch: 'full' },
  { path: 'asset/vendors', component: AssetVendorsComponent , pathMatch: 'full' },
  { path: 'asset/types', component: AssetTypesComponent , pathMatch: 'full' },
  { path: 'asset/issue/types', component: AssetIssueTypesComponent , pathMatch: 'full' },
  { path: 'asset/vehicle/categories', component: AssertCategoriesComponent , pathMatch: 'full' },
  { path: 'asset/vehicle/groups', component: VehicleGroupsComponent , pathMatch: 'full' },
  { path: 'asset/status', component: AssertStatusComponent , pathMatch: 'full' },
  // { path: 'ticket/category', component: TicketCategoryListComponent , pathMatch: 'full' },
  { path: 'ticket/category', canActivate: [AuthGuardService], loadChildren: () => import('../setup/ticketing/ticketing.module').then(m => m.TicketingModule) },
  // { path: 'usermanagement', component: UsermanagementComponent , pathMatch: 'full' },
  // { path: 'documentation-process'          , canActivate: [AuthGuardService],loadChildren: () => import('./documentation-process/documentation-process.module').then(m => m.DocumentationProcessModule)  },
  { path: '**', redirectTo : '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SetupRoutingModule { }
