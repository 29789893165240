import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
declare const tinymce: any;
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NzModalService } from 'ng-zorro-antd/modal';
// import { PipeTransform, Pipe } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-help-documentation',
  templateUrl: './help-documentation.component.html',
  styleUrls: ['./help-documentation.component.scss'],
})
// @Pipe({ name: 'safeHtml'})
export class HelpDocumentationComponent implements OnInit {

  model = ""
  maxUploadFileSize = 1000000;
  pagesLst;
  pgeid;
  sectnLst;
  selectdnav = { sctn_nm: "", dscptn_txt: "", sctn_id: "" };
  newselectdnav = { sctn_nm: "", dscptn_txt: "", sctn_id: "" };

  newCntent = false;
  files;
  value;
  contentHtml;
  init
  checkcnt;
  length;
  temcnt;
  pageId;
  pgGrpNm;
  pageName;
  routerLinkVariable;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(public apiSrv: AppsService, private http: HttpClient, private sanitized: DomSanitizer, private modal: NzModalService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.pageId = params.pgeId
    });
    this.routerLinkVariable = `/setup/helppge`
  }
  onBack() {

  }


  ngOnInit() {
    this.init = {
      height: 300,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen image textcolor',
        'insertdatetime media table paste code help wordcount', 'quickbars', 'wordcount'
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor forecolor fontselect  | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help | image',
      font_formats:
        "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
      images_upload_url: 'postAcceptor.php',
      images_upload_handler: function (blobInfo, success, failure) {
        var data = new FormData();
        data.append('imgData', "data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
        //   console.log(data)
        var Http = new XMLHttpRequest();
        Http.open('POST', 'http://localhost:4903/apiv1/kb/page/imgsave', true);
        Http.onload = function () {
          var json;
          if (Http.status != 200) {
            failure('HTTP Error: ' + Http.status);
            return;
          }
          json = JSON.parse(Http.responseText);
          //   console.log(json)
          if (!json || typeof json.data != 'string') {
            failure('Invalid JSON: ' + Http.responseText);
            return;
          }

          success(json.data);
        };
        Http.send(data);
      },
      setup: function (editor) {
        editor.on('click', function (e) {
          //   console.log('Editor was clicked');
          //   console.log(e)
          this.length = editor.contentDocument.body.innerText.length;
          //   console.log(length)
        });
        editor.on('change', function (e) {

        });

      },
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
    }
    this.getsection();
  }
  ngOnDestroy() {
    tinymce.remove()
  }
  getPagesAll() {
    let rte = `kb/page`;
    this.apiSrv.get(rte).subscribe(res => {
      //   //   console.log(res)
      this.pagesLst = res['data']
    })
  }
  Addsection() {
    //   console.log("add new section");
    this.newCntent = true;
    this.selectdnav = { sctn_nm: "", dscptn_txt: "", sctn_id: "" };
  }
  getsection() {
    let rte = `kb/sections/${this.pageId}`;
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          //   //   console.log(res)
          this.sectnLst = res['data'];
          this.pgGrpNm = this.sectnLst[0].pge_grp_nm;
          this.pageName = this.sectnLst[0].pge_nm;
          this.selectedValue(this.sectnLst[0]);
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    })
  }
  selectedValue(sec) {
    this.selectdnav = sec;
    this.newCntent = false;
    if (this.selectdnav) {
      this.value = this.selectdnav.sctn_nm;
      this.contentHtml = this.sanitized.bypassSecurityTrustHtml(this.selectdnav.dscptn_txt);
    }
  }
  deleteSection() {
    this.modal.confirm({
      nzTitle: 'Are you sure delete this section?',
      nzContent: `<b style="color: red;">${this.selectdnav.sctn_nm}</b>`,
      nzOkText: 'Yes',
      // nzOkType: 'danger',
      nzOkDanger:true,
      nzOnOk: () => this.sectiondelete(),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }
  sectiondelete() {
    let rte = `kb/pages/sections/:sctn_id`;
    this.apiSrv.delete( rte).subscribe(res => {
      //   //   console.log(res)
      this.getsection();
    })
  }
  editSection() {
    //   console.log(this.selectdnav)
    this.newCntent = true;
    this.checkcnt = this.selectdnav.dscptn_txt.length;
    //   console.log(this.checkcnt)
  }
  cancelcontent() {
    this.newCntent = false;
  }
  savecontent() {
    let data = this.selectdnav
    //   console.log(data)

    if (data.sctn_id) {
      let rte = `kb/page/sections/`+data.sctn_id;
      this.apiSrv.update(data, rte).subscribe(res => {
        //   //   console.log(res)
        this.getsection()
      })
    }
    else {
      data['pge_id'] = this.pageId;
      data['sqnce_id'] = this.sectnLst.length + 1;
      let rte = `kb/pages/`+this.pageId+`/sections`;
      this.apiSrv.create(data, rte).subscribe(res => {
        //   //   console.log(res)
        this.getsection()
      })
    }

  }
}
