import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-timeline-data',
  templateUrl: './timeline-data.component.html',
  styleUrls: ['./timeline-data.component.scss']
})
export class TimelineDataComponent implements OnChanges {

  @Input() tcktId;
  ticket_id: any;
  timelineData: any;
  loader = true;
  fle_nm;
  attachmentsData: any[];
  doc_origin = '';
  assgn_ts: any;
  crnt_stg: any;
  timelinedata: any;
  timel: any;
  stge_nm: any;
  tckt_ctgry_nm: any;
  ise_type_nm: any;
  asstCnt: any;

  constructor(public apiSrv: AppsService
    // ,public toastr:ToastrService
    ) {
    // this.doc_origin = environment.doc_origin;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ticket_id = changes.tcktId.currentValue;
    this.getBnfcryTimeline();
  }
  getBnfcryTimeline() {
    this.timelineData = [];
    this.loader = true;
    this.apiSrv.get(`asset/tracking/time-line/${this.ticket_id}`).subscribe(res => {
      this.loader = false;
      if (res['status'] == 200) {
        this.timelineData = res['data'];
        console.log(this.timelineData)
        this.assgn_ts=this.timelineData && this.timelineData.length?this.timelineData[0].assgn_ts:''
        this.stge_nm=this.timelineData && this.timelineData.length? this.timelineData[0].stge_nm:''
        this.tckt_ctgry_nm=this.timelineData && this.timelineData.length?this.timelineData[0].tckt_ctgry_nm:''
        this.ise_type_nm=this.timelineData && this.timelineData.length?this.timelineData[0].ise_type_nm:''
        this.asstCnt=this.timelineData && this.timelineData.length?this.timelineData[0].asstCnt:''
        
      } else {
        // this.toastr.error(res['message']);
      }
    }, (err) => {
      this.loader = false;
      // this.toastr.error('Something went wrong.');
    });
  }

}
