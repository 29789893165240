import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppsService } from 'src/app/shared/services/apps.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-distnce-aprvl',
  templateUrl: './distnce-aprvl.component.html',
  styleUrls: ['./distnce-aprvl.component.scss']
})
export class DistnceAprvlComponent implements OnInit {
  tntLst: any = [];
  clntDtls: any;
  selectedtenant = 0;
  userDtls: any;
  clap_in: number;
  ShowClap: boolean;
  unaprvdData: any;
  columnDefs: any;
  selectedVehicle;
  visibleVhclSldr: boolean;
  VhclSldr: boolean;
  pbckData: any;
  showLdr;
  aprvdData: any;
  dateFormat = 'dd/MM/yyyy';
  today = new Date();
  slctdDt = new Date();
  curDate;
  columnDefs1:any=[];
  tab_id=0;
  rjctrsn;
  vsbleRjct: boolean;
  asrt_id: any;
  rjctData: any;
  columnDefs2:any=[];
  asrt_nm: any;
  dst_updtn_dt: any;
  updt_dst: any;
 
  constructor(public apiServ: AppsService, private message: NzMessageService,) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
  }

  ngOnInit(): void {
    this.getunaprvdVehicles(this.tab_id);
  }
  ontntSelect(value) {
    this.selectedtenant = value;
    this.getunaprvdVehicles(this.tab_id);
  }
  onChange(e): void {
    this.slctdDt = e;
    this.getunaprvdVehicles(this.tab_id);
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  getunaprvdVehicles(tab) {
    this.showLdr=true;
    var dte=moment(this.slctdDt).format('YYYY-MM-DD');
    const rte = `drivers/vehicle/aprv_dstnc/${this.selectedtenant}/${tab}/${dte}`;
    this.apiServ.get(rte).subscribe((res) => {
      if (res['data'] && res['data'].length >= 0) {
        this.showLdr=false;
        this.unaprvdData = res['data'];
        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB Name', field: 'tnt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Vehicle Registration Number', field: 'asrt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Reason for Distance Updation', field: 'dvce_sts_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Odometer Distance', field: 'odmtr_dst_ct', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Updated Distance', field: 'dst_ct', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Distance Updated By', field: 'updtd_usr_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Distance Updated on', field: 'updtn_dt', alignment: 'left', width: 160, filter: true, sortable: true, },

        ]
        this.aprvdData = res['data'];
        this.columnDefs1 = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB Name', field: 'tnt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Vehicle Registration Number', field: 'asrt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Reason for Distance Updation', field: 'dvce_sts_nm', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'Odometer Distance', field: 'odmtr_dst_ct', alignment: 'left',  filter: true, sortable: true, },
          { headerName: 'Updated Distance', field: 'dst_ct', alignment: 'left',  filter: true, sortable: true, },
          { headerName: 'Distance Updated By', field: 'updtd_usr_nm', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'Approved By', field: 'aprvd_usr_nm', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'Distance Updated on', field: 'updtn_dt', alignment: 'left',  filter: true, sortable: true, },
          { headerName: 'Approved On', field: 'aprve_ts', alignment: 'left',  filter: true, sortable: true, },

        ]
        this.rjctData=res['data'];
        this.columnDefs2 = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB Name', field: 'tnt_nm', alignment: 'left',  filter: true, sortable: true, },
          { headerName: 'Vehicle Registration Number', field: 'asrt_nm', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'Reason for Distance Updation', field: 'dvce_sts_nm', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'Odometer Distance', field: 'odmtr_dst_ct', alignment: 'left',  filter: true, sortable: true, },
          { headerName: 'Updated Distance', field: 'dst_ct', alignment: 'left',filter: true, sortable: true, },
          { headerName: 'Distance Updated By', field: 'updtd_usr_nm', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'Rejected By', field: 'rjct_usr_nm', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'Distance Updated on', field: 'updtn_dt', alignment: 'left',  filter: true, sortable: true, },
          { headerName: 'Rejected On', field: 'rjct_ts', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'Reason for Distance Updation', field: 'rjct_rsn_tx', alignment: 'left',  filter: true, sortable: true, },

        ]
      }
    });
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  approve(data) {
    var postData = {
      asrt_id: data.data.asrt_id,
      tnt_id: this.selectedtenant
    }
    this.apiServ.post(postData, `drivers/vehicles/Master/distance/aprve`).subscribe((res) => {
      console.log(res);
      if (res['status'] == 200) {
        this.createMessage("Success", "Approved Sucessfully");
        this.getunaprvdVehicles(1);
      }
    }, (err) => {
    });
  }
  rejectslider(data){
    this.vsbleRjct=true;
    this.asrt_id=data.data.asrt_id;
    this.asrt_nm=data.data.asrt_nm;
    this.dst_updtn_dt=data.data.updtn_dt;
    this.updt_dst=data.data.dst_ct
  }
  reject(){
    var postData = {
      asrt_id: this.asrt_id,
      tnt_id: this.selectedtenant,
      rjct_resn:this.rjctrsn
    }
    this.apiServ.post(postData, `drivers/vehicles/Master/distance/rjct`).subscribe((res) => {
      console.log(res);
      if (res['status'] == 200) {
        this.createMessage("Success", "Rejected Sucessfully");
        this.rjctrsn='';
        this.vsbleRjct=false;
        this.getunaprvdVehicles(1);
      }
    }, (err) => {
    });
  }
  getVehDashSldr(data) {
    // console.log(evnt)
    var evnt=data.data;
    this.selectedVehicle = evnt;
    this.visibleVhclSldr = true;
  }
  openslider(evt) {
    this.VhclSldr = true;
    this.pbckData = evt;
    // console.log(this.pbckData)

  }
  tabchange(e) {
    this.tab_id=e.index;
      this.getunaprvdVehicles(this.tab_id);
  }
  closeSideBar(){
    this.vsbleRjct=false;
    this.rjctrsn='';
  }
}
