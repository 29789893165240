<ds-loading *ngIf="showLdr"></ds-loading>

<div class="pg">
    <div class="row" style="width: 100%;">
        <div class="col-lg-12 col-md-12">
            <div class="row wrapper">
                <div id="slide">
                    <span>
                        <img src="../../../../../assets/images/trip.png">
                    </span>
                    <span>
                        <h1>{{trpDta.asrt_nm}}, Trip: {{trpDta.trp_sqnc_nu}}</h1>
                        <!-- <p>Adding Inteligence to your Freight</p> -->
                        <!-- <p>Trip Date: {{trpDta.dt}}</p> -->
                    </span>
                </div>
            </div>
            <!-- <div class="row mtn_35">
                <div class="col-lg-1 col-md-1"></div>
                <div class="col-lg-3 col-md-3 cl_card">
                    <i nz-icon nzType="car" nzTheme="outline"></i>
                    <p>Vehicle Details</p>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>ULB Name</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{trpDta.tnt_dsply_nm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Vehicle Number</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{trpDta.asrt_nm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Vehicle Status</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2 [ngStyle]="trpDta.asr_sts_clr_cd ? {'color': trpDta.asr_sts_clr_cd} : {'color': '#454545'}">{{(!trpDta.asrt_sts_nm || trpDta.asrt_sts_nm == undefined || trpDta.asrt_sts_nm == null) ? 'NA' : trpDta.asrt_sts_nm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Vehicle Category</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.asrt_ctgry_nm || trpDta.asrt_ctgry_nm == undefined || trpDta.asrt_ctgry_nm == null) ? 'NA' : trpDta.asrt_ctgry_nm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Vehicle Group</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.asrt_grp_nm || trpDta.asrt_grp_nm == undefined || trpDta.asrt_grp_nm == null) ? 'NA' : trpDta.asrt_grp_nm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Distance Travelled</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.tot_dst || trpDta.tot_dst == undefined || trpDta.tot_dst == null) ? 'NA' : trpDta.tot_dst}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 cl_card">
                    <i nz-icon nzType="user" nzTheme="outline"></i>
                    <p>Driver Details</p>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Driver Name</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.crw_nm || trpDta.crw_nm == undefined || trpDta.crw_nm == null) ? 'NA' : trpDta.crw_nm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Licence No.</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.crw_lcnse_nu || trpDta.crw_lcnse_nu == "undefined" || trpDta.crw_lcnse_nu == null) ? 'NA':trpDta.crw_lcnse_nu}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Joining Date</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.crw_doj || trpDta.crw_doj =="00-00-0000" || trpDta.crw_doj == null) ? 'NA' : trpDta.crw_doj}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Phone No.</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.crw_ph|| trpDta.crw_ph == 0 || trpDta.crw_ph == null) ? 'NA':trpDta.crw_ph}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Route</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.rte_nm|| trpDta.rte_nm == undefined || trpDta.rte_nm == null) ? 'NA':trpDta.rte_nm}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 cl_card">
                    <i nz-icon nzType="warning" nzTheme="outline"></i>
                    <p>Trip Events</p>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Issued Date</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.issued_dt_tm || trpDta.issued_dt_tm == undefined || trpDta.issued_dt_tm == null) ? 'NA' : trpDta.issued_dt_tm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Status</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.ntfcn_sts_nm || trpDta.ntfcn_sts_nm == undefined || trpDta.ntfcn_sts_nm == null) ? 'NA' : trpDta.ntfcn_sts_nm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Category</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2 [ngStyle]="trpDta.ntfcn_ctgry_clr_cd_tx ? {'color': trpDta.ntfcn_ctgry_clr_cd_tx} : {'color': '#454545'}">{{(!trpDta.ntfcn_ctgry_nm || trpDta.ntfcn_ctgry_nm == undefined || trpDta.ntfcn_ctgry_nm == null) ? 'NA' : trpDta.ntfcn_ctgry_nm}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <h3>Description</h3>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <h2>:</h2>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2>{{(!trpDta.ntfcn_dscrptn_txt || trpDta.ntfcn_dscrptn_txt == undefined || trpDta.ntfcn_dscrptn_txt == null) ? 'NA' : trpDta.ntfcn_dscrptn_txt}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1 col-md-1"></div>
            </div> -->
        </div>
    </div>
    <div class="row bg-w">
        <div class="col-lg-12 col-md-12 htscrl">
            <div class="inptCls">
                <span>Scheduled Route<input class="prmry" disabled="true"></span>
                <span>Travelled Route<input class="scndry" disabled="true"></span>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 htscrl">
            <div id="routeDevtmap"></div>
        </div>
    </div>
    <!-- <div class="row bg-w">
        <div class="col-lg-6 col-md-6 w-50">
            <h1>Last 7Days Distance Covered</h1>
            <div id="lastSevenDaysDistanceChart"></div>
        </div>
        <div class="col-lg-6 col-md-6 w-50">
            <h1>Last 7Days Trips Covered</h1>
            <div id="lastSevenDaysChart"></div>
        </div>
    </div> -->


</div>