import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as _moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
const moment = _moment;
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
@Component({
  selector: 'app-ams-vehicles',
  templateUrl: './ams-vehicles.component.html',
  styleUrls: ['./ams-vehicles.component.scss']
})
export class AmsVehiclesComponent implements OnInit {
  header_label ={
    name:"Vehicles"
  }
  searchText;
  crdsview: boolean;
  tblview: boolean;
  shwttlvhcle = false;
  switchValue = false;
  vhcleslst: any = [];
  visible = false;
  driverform: FormGroup;
  selectedValue;
  drvrslst: any;
  vhcledtls = false;
  warddtls = false;
  drvrasgn = false;
  vhclenumbr: any;
  crw_id
  trpslst: any;
  vhcldtlsform: FormGroup;
  warddtlsform: FormGroup;
  dte: any;
  trp_nu: any;
  asert_id: any;
  asgndrvrs: any;
  tntLst: any = [];
  clntDtls: any;
  selectedtenant;
  vendorList: any = [];
  selectedvndr;
  selectedctgry;
  vehctgryLst: any = [];
  wrdlst: any = [];
  cardcntSummary: any = [];
  totveh: number;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  vhcleGrp: any;
  vehicleSts: any;
  wardSts: any;
  vhcleDprtmnt: any;
  asrt_sts_data: any = [];
  selectedSts: any;
  asrt_id: any;
  params: any;
  navData: any = [];
  vhclslstcolumnDefs: any = [];
  showLdr = false;
  visibleVhclSldr = false;
  VhclSldr: boolean;
  url: string;
  url2: string;
  urlSafe: SafeResourceUrl;
  urlSafe2: SafeResourceUrl;
  pbckData: any = {};
  userDtls: any;
  showVndr: boolean = false;
  clap_in: any;
  ShowClap: boolean = false;
  errmsg: any;
  selectedGrp: any;
  fncsLst: any[];
  slctdDt = new Date();
  curDate: string;
  timeFormat = 'HH:mm';
  strt_tm = new Date();
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  urban_in: any;
  hdrLbl: string;
  crdChnge: boolean;
  asrt_sts_id: any;
  ctgryCardcntSummary: any;
  allCategories: any;
  prkd_veh_ct = 0;
  rn_veh_ct = 0;
  vhclLctnCnts: any;
  currentTimeStmp: string;
  recievedTime: string;
  diffTimeInMin: number;
  slctdctgry: any;
  slctdLctn: any;
  sts_data: any = [];
  vehDupLst: any;
  rn_data: any[];
  prkd_data: any[];
  sts_cd: any;
  slctctgryNm: any;
  ctgryData: any;

  constructor(private apiSrvc: AppsService, private modal: NzModalService, private router: Router, private message: NzMessageService,
    private route: ActivatedRoute, public sanitizer: DomSanitizer, public datepipe: DatePipe) {

    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    // console.log(this.clntDtls.clnt_id)
    this.tntLst = this.clntDtls[0].tnts;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    // console.log(this.userDtls);
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
      this.selectedvndr = 0;
    }
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
    if (this.params.data) {
      this.navData = JSON.parse(this.params.data);
      this.selectedtenant = this.navData.tnt_id;
      this.selectedvndr = this.navData.vndr_id;
      this.selectedctgry = this.navData.asrt_ctgry_id;
      this.urban_in = this.navData.urban_in;

    } else {
      this.navData = {};
      this.selectedtenant = this.tntLst[0].tnt_id;
      this.urban_in = this.tntLst[0].urban_in;
      this.selectedvndr = '';
      this.selectedctgry = '';
    }
    if (this.urban_in == 1) {
      this.hdrLbl = 'ULB';
    } else {
      this.hdrLbl = 'Mandal';
    }
  }

  ngOnInit() {
    this.selectedvndr = 0;
    this.selectedctgry = 0;
    this.gridview();
    this.getDrvrsLst();
    this.getVhcleCtgry();
    this.getWardLst();
    this.getVendors();
    this.getCardsData();
    this.getvhcleGrp();
    this.getLocations();
    // this.selectedvndr = 0;
    // this.getTrpsLst(null); 

    this.warddtlsform = new FormGroup({
      veh_nu: new FormControl(''),
      wrd_nm: new FormControl(''),
      wrd_sts: new FormControl(''),

    });

    this.vhcldtlsform = new FormGroup({

      veh_nu: new FormControl(''),
      veh_grp: new FormControl(''),
      veh_ctgry: new FormControl(''),
      //veh_dprtmnt: new FormControl(''),
      veh_sts: new FormControl(''),
      veh_trps: new FormControl(''),
      veh_spd: new FormControl(''),
      veh_dstnce: new FormControl(''),
      veh_stpg_tm: new FormControl(''),
      veh_mlge: new FormControl(''),
      veh_spare: new FormControl(''),
      hme_fnce_id: new FormControl(''),
      strt_tm: new FormControl(''),
      ntfy_intrvl_tm: new FormControl(''),
    });
  }

  getVhclsLst = () => {
    // console.log('getVhclsLst');
    this.showLdr = true;
    const fltrData = {};
    // console.log(this.navData);
    this.vhcleslst = [];
    if (this.navData.asrt_id) {
      if (this.navData.tnt_id && this.navData.tnt_id != 0) {
        // tslint:disable-next-line:no-string-literal
        fltrData['selectedtenant'] = this.navData.tnt_id;
      }
      if (this.navData.vndr_id) {
        // tslint:disable-next-line:no-string-literal
        fltrData['selectedvndr'] = this.navData.vndr_id;
      }
      if (this.navData.asrt_ctgry_id) {
        // tslint:disable-next-line:no-string-literal
        fltrData['selectedctgry'] = this.navData.asrt_ctgry_id;
      }
      if (this.navData.asrt_id) {
        fltrData['selectedAsrt'] = this.navData.asrt_id;

      }

    } else {
      if (this.selectedtenant && this.selectedtenant != 0) {
        // tslint:disable-next-line:no-string-literal
        fltrData['selectedtenant'] = this.selectedtenant;
      } else {
        fltrData['sts_cd'] = this.sts_cd;
      }
      if (this.clap_in == 1 && this.selectedvndr) {
        fltrData['selectedvndr'] = this.selectedvndr;
      } else {
        // tslint:disable-next-line:no-string-literal
        fltrData['selectedvndr'] = 0;
      }
      if (this.selectedctgry && this.selectedtenant != 0) {
        // tslint:disable-next-line:no-string-literal
        fltrData['selectedctgry'] = this.selectedctgry;
      }
      else if (this.selectedctgry == 0) {
        fltrData['selectedctgry'] = this.selectedctgry;
      }
      else if (this.selectedctgry != 0 && this.selectedtenant == 0 && this.slctctgryNm == null) {
        fltrData['selectedctgry'] = this.selectedctgry;
      }
      else {
        fltrData['selectedctgrynm'] = this.slctctgryNm;
      }
      if (this.selectedtenant != 0 && this.asrt_sts_id) {
        // tslint:disable-next-line:no-string-literal
        fltrData['asrt_sts_id'] = this.asrt_sts_id;
      } else if (this.selectedtenant == 0 && this.asrt_sts_id) {
        fltrData['sts_cd'] = this.sts_cd;
      }

    }
    console.log(fltrData);
    this.apiSrvc.post(fltrData, 'drivers/vehicles/vehicles').subscribe((res) => {
      if (res['status'] == 200) {
        this.vhcleslst = res['data'];
        this.showLdr = false;
        console.log(this.vhcleslst)
        // console.log(this.vhcleslst);
        var cnt = 0;
        var cnt2 = 0;
        this.rn_veh_ct = 0;
        this.prkd_veh_ct = 0;
        for (let v = 0; v < this.vhcleslst.length; v++) {
          if (this.vhcleslst[v].asrtglblId === 1) {
            this.vhcleslst[v].icon_pathr = this.vhcleslst[v].icon_path + '_r';
          } else if (this.vhcleslst[v].asrtglblId === 2) {
            this.vhcleslst[v].icon_pathr = this.vhcleslst[v].icon_path + '_o';
          } else {
            this.vhcleslst[v].icon_pathr = this.vhcleslst[v].icon_path + '_p';
          }
          this.url = "http://vms.dreamstep.info/RlKd9ipWTb41Md6O4eZVNUxCZZDjgf/embed/l4aGwhaoRk/111/jquery|fullscreen";
          this.url2 = "http://vms.dreamstep.info/RlKd9ipWTb41Md6O4eZVNUxCZZDjgf/embed/l4aGwhaoRk/111/jquery|fullscreen";

          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
          this.urlSafe2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.url2);
          //  console.log(this.vhcleslst.length)
          this.currentTimeStmp = moment(this.slctdDt).format('YYYY-MM-DD HH:mm:ss');
          this.recievedTime = moment(this.vhcleslst[v].data_received_ts).format('YYYY-MM-DD HH:mm:ss');
          // console.log(this.recievedTime)
          // console.log( this.currentTimeStmp)
          this.diffTimeInMin = moment(this.currentTimeStmp).diff(this.recievedTime, 'minute');
          // console.log(this.diffTimeInMin);
          if (this.vhcleslst[v].spd_ct != 0 && this.vhcleslst[v].spd_ct != undefined) {
            this.rn_veh_ct = ++cnt2;
            // console.log(this.rn_veh_ct)
          } else {
            this.prkd_veh_ct = ++cnt;

          }
        }
        this.vehDupLst = this.vhcleslst;
        if (this.urban_in == 1) {
          this.vhclslstcolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
            { headerName: 'District', alignment: 'center', field: 'district' },
            { headerName: 'ULB Name', alignment: 'center', field: 'tnt_nm' },
            { headerName: 'Vehicle No', alignment: 'left', field: 'asrt_nm' },
            { headerName: 'Category', field: 'asrt_ctgry_nm', alignment: 'left' },
            { headerName: 'Group', alignment: 'left', field: 'asrt_grp_nm' },
            { headerName: 'IMEI', alignment: 'center', field: 'dev_imei_nu' },
            // { headerName: 'Start Fence', alignment: 'center', field: 'startFence' },
            // { headerName: 'End Fence', field: 'endFence', alignment: 'center' }
          ]
        } else {
          this.vhclslstcolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
            { headerName: 'Vehicle No', alignment: 'left', field: 'asrt_nm' },
            { headerName: 'District', alignment: 'center', field: 'district' },
            { headerName: 'Mandal', alignment: 'center', field: 'tnt_nm' },
            { headerName: 'Village', field: 'asrt_grp_nm', alignment: 'left' },
            { headerName: 'Location', alignment: 'center', field: 'loc_nm' },
            { headerName: 'IMEI', alignment: 'center', field: 'dev_imei_nu' },

          ]
        }
      }
    }, (err) => {
      // console.log(err);
    });
  }

  getDrvrsLst = () => {
    const data = {};
    this.apiSrvc.post(data, 'drivers/drivers').subscribe((res) => {
      this.drvrslst = res['data'];
    }, (err) => {
    });
  }

  getVhcleCtgry = () => {
    this.apiSrvc.get(`drivers/vehicles/category/${this.selectedtenant}`).subscribe((res) => {
      this.vehctgryLst = res['data'];
      console.log(this.vehctgryLst)
    }, (err) => {
    });
  }
  getVhcleCtgrybygrp() {
    var asrt_data = {
      "asrt_grp": this.selectedGrp
    };
    this.apiSrvc.post(asrt_data, 'playback/categories/assets')
      .subscribe((res) => {
        this.vehctgryLst = res['data'];
        // console.log(this.vehctgryLst);
      }, (err) => {
      });
  }

  getWardLst = () => {
    this.apiSrvc.get(`drivers/vehicles/wards/${this.selectedtenant}`).subscribe((res) => {
      this.wrdlst = res['data'];
    }, (err) => {
    });
  }

  gridview() {
    if (this.switchValue == false) {
      this.crdsview = true;
      this.tblview = false;
    } else if (this.switchValue == true) {
      this.crdsview = false;
      this.tblview = true;
    }
  }
  onDateChng() {
    this.dte = moment(this.dte).format('YYYY-MM-DD');
    let postdata = {
      asrt_id: this.asert_id,
      date: this.dte
    };
    this.apiSrvc.put(postdata, `drivers/vehicle/trips`).subscribe((res) => {
      this.trpslst = res['data'];
    }, (err) => {
      // // console.log(err);
    });
  }


  openasigndrvrfrm(data) {
    this.crw_id = data.crw_id;
    this.drvrasgn = true;
    this.vhcledtls = false;
    this.visible = false;
    this.visible = true;
    this.asert_id = data.asrt_id;
    this.vhclenumbr = data.asrt_nm;
    this.asgnervrs();

  }
  closedrvrlst() {
    this.visible = false;
    this.vhcldtlsform.reset();
  }


  closervrlst() {
    this.visible = false;
    this.warddtlsform.reset();
  }


  openvhcldtlsfrm(data) {
    this.drvrasgn = false;
    this.vhcledtls = true;
    this.visible = true;
    console.log(data);
    this.asrt_id = data.asrt_id;
    let sflck_ts = moment(data.sflck_nxt_chk_ts, "HH:mm");
    console.log(sflck_ts);
    this.selectedtenant = data.tntId;
    this.vhcldtlsform.get('veh_nu').setValue(data.asrt_nm);
    this.vhcldtlsform.get('veh_grp').setValue(data.asrt_grp_id);
    this.vhcldtlsform.get('veh_ctgry').setValue(data.asrt_ctgry_id);
    // this.vhcldtlsform.get('veh_dprtmnt').setValue(data.dprt_id);
    this.vhcldtlsform.get('veh_sts').setValue(data.asrt_sts_id);
    this.vhcldtlsform.get('veh_trps').setValue(data.trgt_trps);
    this.vhcldtlsform.get('veh_spd').setValue(data.spd_ct);
    this.vhcldtlsform.get('veh_dstnce').setValue(data.max_dst);
    this.vhcldtlsform.get('veh_stpg_tm').setValue(data.unauth_range);
    this.vhcldtlsform.get('veh_mlge').setValue(data.mileage_ct);
    this.vhcldtlsform.get('veh_spare').setValue(data.asrt_spre_in);
    this.vhcldtlsform.get('hme_fnce_id').setValue(data.hme_fnce_id);
    this.vhcldtlsform.get('strt_tm').setValue(sflck_ts);
    this.vhcldtlsform.get('ntfy_intrvl_tm').setValue(data.ntfy_intrvl_tm);
    this.getvhcleGrp();
  }
  drvrasgndtls(data) {
    this.drvrasgn = true;
    this.vhcledtls = false;
    this.visible = true;
    this.warddtls = false;

  }
  openwardlstfrm(data) {
    this.drvrasgn = false;
    this.vhcledtls = false;
    this.warddtls = true;
    this.visible = true;
    this.asrt_id = data.asrt_id;
    this.warddtlsform.get('veh_nu').setValue(data.asrt_nm);
    this.warddtlsform.get('wrd_nm').setValue(data.wrd_id);
    this.warddtlsform.get('wrd_sts').setValue(data.asrt_sts_id);
  }


  saveasgnmnt() {
    if (this.vhclenumbr !== undefined && this.dte !== undefined && this.trp_nu !== undefined && this.crw_id !== undefined) {
      let postdata = {
        vhclnm: this.vhclenumbr,
        dte: this.dte,
        trp_num: this.trp_nu,
        crw_id: this.crw_id
      };
      console.log(postdata)
      this.apiSrvc.put(postdata, 'drivers/drivers/wbtripassign').subscribe((res) => {
        if (res['status'] === 200) {
          this.visible = false;
          this.modal.success({
            nzTitle: 'Driver Details',
            nzContent: 'Sucessfully Updated'
          });
        }
      });
    } else {
    }
  }

  asgnervrs() {
    this.apiSrvc.get('drivers/drivers/asgnedrvrs/' + this.asert_id).subscribe((res) => {
      this.asgndrvrs = res['data'];
    }, (err) => {
    });
  }
  updateVehicleData() {
    // console.log(this.vhcldtlsform);
    var sts = this.vhcldtlsform.value.veh_sts;
    this.vehicleSts.filter((k) => {
      if (k.asrt_sts_id == sts) {
        this.asrt_sts_data.push(k);
      }
    })
    console.log(this.vhcldtlsform.value.strt_tm)
    var postData = {
      "asrt_id": this.asrt_id,
      "asrt_nm": this.vhcldtlsform.value.veh_nu,
      "asrt_ctgry_id": this.vhcldtlsform.value.veh_ctgry,
      "asrt_grp_id": this.vhcldtlsform.value.veh_grp,
      "dprt_id": 0,
      "bnch_dst": this.vhcldtlsform.value.veh_dstnce,
      "bnch_trps": this.vhcldtlsform.value.veh_trps,
      "bnch_spd": this.vhcldtlsform.value.veh_spd,
      "unauth_tm": this.vhcldtlsform.value.veh_stpg_tm,
      "milage": this.vhcldtlsform.value.veh_mlge,
      "asrt_ownr_id": 1,
      "asrt_sts_id": this.vhcldtlsform.value.veh_sts,
      //"asrt_glbl_sts_id":this.asrt_sts_data[0].asrt_glbl_sts_id,
      "tnt_id": this.selectedtenant,
      "hme_fnce_id": this.vhcldtlsform.value.hme_fnce_id,
      "strt_tm": this.vhcldtlsform.value.strt_tm,
      "ntfy_intrvl_tm": this.vhcldtlsform.value.ntfy_intrvl_tm,
    }
    console.log(postData)
    this.apiSrvc.post(postData, 'drivers/vehicles/update').subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Vehicle successfully Updated');
        this.getVhclsLst();

      }

    });
  }

  updateWardData() {
    // console.log( this.warddtlsform.value.wrd_nm)
    // console.log(this.asrt_id)
    let data = {
      "wrd_id": this.warddtlsform.value.wrd_nm,
      "asrt_id": this.asrt_id
    }
    this.apiSrvc.post(data, `drivers/vehicles/updateWard`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Ward details Updated');
      }
    });
    this.visible = false;
    this.warddtlsform.reset();

  }
  ontntSelect(value) {
    // if (value == 0) {
    //   this.selectedctgry = 0
    // }
    // else {
    //   this.selectedctgry = null
    // }
    this.selectedctgry = 0
    console.log(this.selectedctgry)
    this.selectedtenant = value;
    this.tntLst.forEach(element => {
      if (element.tnt_id == this.selectedtenant) {
        this.urban_in = element.urban_in
      }
    });
    if (this.urban_in == 1) {
      this.hdrLbl = 'ULB';
    } else {
      this.hdrLbl = 'Mandal';
    }
    this.navData = {};
    this.getVendors();
    this.getVhclsLst();
    this.getvhcleGrp();
    this.getVhcleCtgry();
    this.getCardsData();
    this.getLocations();
  }
  getVendors() {
    console.log(this.userDtls.dsgns_id)
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      if (this.selectedvndr && this.showVndr) {
        this.apiSrvc.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
          // console.log(res)
          if (res['status'] == 200) {
            this.vendorList = res['data'];
          }
        }, (err) => {
          // console.log("In errorr");
        })
      }
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
      this.selectedvndr = 0;
      this.getVhclsLst();

    } else {
      this.apiSrvc.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          // this.USER_PERMISSIONS = res['perm'][0];
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
          }
          this.getVhclsLst();
          this.getCardsData();
        }
      }, (err) => {
        // // console.log('In errorr');
      });
    }
  }
  getvhcleGrp() {
    this.apiSrvc.get(`drivers/vehicles/group/${this.selectedtenant}`).subscribe((res) => {
      this.vhcleGrp = res['data'];
      // console.log(this.vhcleGrp)
    }, (err) => {
    });
    this.apiSrvc.get(`drivers/vehicles/departments/${this.selectedtenant}`).subscribe((res) => {
      this.vhcleDprtmnt = res['data'];
      // console.log(this.vhcleDprtmnt)
    }, (err) => {
    });

    this.apiSrvc.get(`drivers/vehicles/status/${this.selectedtenant}`).subscribe((res) => {
      this.vehicleSts = res['data'];
      // console.log(this.vehicleSts)
    }, (err) => {
    });
  }
  onvndrSelect(value) {
    this.selectedvndr = value;
    this.navData = {};
    this.getVhclsLst();
    this.getCardsData();
    this.shwttlvhcle = true;

  }


  onctgrySelect(value) {
    // console.log(value)
    // if(typeof value === 'string'){
    //   this.ctgryData = value.split('/');
    //   this.selectedctgry =Number(this.ctgryData[0]);
    //   this.slctctgryNm =this.ctgryData[1]
    // }
    // else{
    //   this.selectedctgry=value.asrt_ctgry_id
    //   this.slctctgryNm=value.asrt_ctgry_nm
    // }
    if (value.asrt_ctgry_id) {
      this.selectedctgry = value.asrt_ctgry_id;
      this.slctctgryNm = value.asrt_ctgry_nm
    }
    else {
      this.selectedctgry = value
      this.slctctgryNm = null
    }


    console.log(this.selectedctgry, this.slctctgryNm);
    console.log(this.ctgryCardcntSummary);
    this.navData = {};
    this.ctgryCardcntSummary.forEach(e => {
      if (e.asrt_ctgry_id == value) {
        e.crdChnge = true
      } else {
        e.crdChnge = false
      }

    });

    this.getVhclsLst();
    this.getCardsData();

  }

  onCardSelect() {
    this.selectedctgry = 0;
    this.getCardsData();
    this.getVhclsLst();
  }

  onvehGrpSelect(value) {
    this.selectedGrp = value;
    this.getVhcleCtgrybygrp();
  }



  getCardsData() {
    // console.log(this.selectedctgry);
    // if(this.selectedvndr){
    this.apiSrvc.get(`drivers/vehicle-wise/cards/summary/${this.selectedvndr}/${this.selectedtenant}/${this.selectedctgry}/${this.slctctgryNm}`).subscribe((res) => {
      // console.log(res)
      if (res['status'] == 200) {
        this.cardcntSummary = res['data'];
        console.log(this.cardcntSummary)
        this.asrt_sts_id = this.cardcntSummary[0].asrt_sts_id;
        this.cardcntSummary[0].crdChnge = true;
        this.getVhclsLst();
        // this.shwttlvhcle = true;
        // this.totveh = this.cardcntSummary[0].tot_veh;
        // console.log(this.totveh)
      }
    });
    this.apiSrvc.get(`drivers/vehicle-wise/ctgry/summary/${this.selectedvndr}/${this.selectedtenant}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.ctgryCardcntSummary = res['data'];
        console.log(this.ctgryCardcntSummary)
        // this.selectedctgry=this.ctgryCardcntSummary[0].asrt_ctgry_id;
        if (this.ctgryCardcntSummary[0]) {
          this.ctgryCardcntSummary[0].crdChnge = true;
        }
        this.getVhclsLst();
      }
    });
    console.log(this.slctctgryNm)
    this.apiSrvc.get(`drivers/vehicle-wise/lctns/summary/${this.selectedvndr}/${this.selectedtenant}/${this.selectedctgry} /${this.slctctgryNm}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.vhclLctnCnts = res['data'];
        console.log(this.vhclLctnCnts)
        this.vhclLctnCnts[0].crdChnge = true;
        this.getVhclsLst();
      }
    });
    // }else{
    //   this.errmsg="No Vehicles found !!!";
    // } 

    this.apiSrvc.get(`drivers/vehicle-wise/all-ctgry/summary/${this.selectedtenant}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.allCategories = res['data']
        console.log(res['data'])
        this.allCategories[0].crdChnge = true;
        console.log(this.allCategories);

      }
    })

  }
  onLctnSlct(v) { }
  gotovehcleDashboard(evnt) {
    this.router.navigate(['/internal/dashboard/vehicle-dashboard'], {
      queryParams: {
        asrt_id: JSON.stringify(evnt.asrt_id),
      }
    });
  }


  rnSlct() {
    this.rn_data = [];
    this.vhcleslst = [];
    console.log(this.vehDupLst);
    this.vehDupLst.filter(e => {
      if (e.spd_ct > 0) {
        this.rn_data.push(e);
        this.vhcleslst = this.rn_data;
      }
    })
    console.log(this.rn_data);
    console.log(this.vhcleslst);

  }

  prkdSlct() {
    this.prkd_data = [];
    this.vhcleslst = [];
    this.vehDupLst.filter(e => {
      if (e.spd_ct == 0) {
        this.prkd_data.push(e);
        this.vhcleslst = this.prkd_data;
      }
    })
    console.log(this.prkd_data);
    console.log(this.vhcleslst);

  }

  onstatusChange(value) {
    this.selectedSts = value;
    this.vehicleSts.filter((k) => {
      if (k.asrt_sts_id == this.selectedSts) {
        this.asrt_sts_data.push(k);
      }
    })
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  selectedVehicle;
  getVehDashSldr(evnt) {
    // console.log(evnt)
    this.selectedVehicle = evnt;
    this.visibleVhclSldr = true;
  }
  openslider(evt) {
    this.VhclSldr = true;
    this.pbckData = evt;
    // console.log(this.pbckData)

  }
  // fullscreen(id, url) {
  //   if (id == 1) {
  //     window.open(url);
  //   } else {
  //     window.open(url);

  //   }
  // }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
  getLocations() {
    this.fncsLst = [];
    this.apiSrvc.get(`geo/locations/list/${this.selectedtenant}`).subscribe((res) => {
      // console.log(res)
      if (res['status'] == 200) {
        this.fncsLst = res['data'];
      }
    });
  }
  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
  }
  exlArry = [];
  export() {
    this.exlArry = [];
    var count = 0;
    this.vhcleslst.forEach(obj => {
      obj.sno = ++count;
      this.exlArry.push(
        { "Sno": obj.sno, "ULB": obj.tnt_nm, "Vehicle Registration Number": obj.asrt_nm, "Vehicle Category": obj.asrt_ctgry_nm, "IMEI Number": obj.dev_imei_nu, "Driver Name": obj.crw_nm, "Driver Phone No": obj.crw_ph, "Vehicle Status": obj.asrt_sts_nm, "Data Last Received On": obj.data_received_ts }
      )
    })
    console.log(this.exlArry);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exlArry);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    let fileName = 'Vehicles-List';
    this.saveExcelFile(excelBuffer, fileName);
  }
  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
  createPdf() {
    let head = [];
    let data = [];
    head.push({ title: "Sno", dataKey: "sno" }, { title: "ULB", dataKey: "ulb_nm" }, { title: "Vehicle Registration Number", dataKey: "asrt_nm" }, { title: "Vehicle Category", dataKey: "asrt_ctgry_nm" }, { title: "IMEI Number", dataKey: "dev_imei_nu" },
      { title: "Driver Name", dataKey: "crw_nm" }, { title: "Driver Phone No", dataKey: "crw_ph" }, { title: "Vehicle Status", dataKey: "asrt_sts_nm" }, { title: "Data Last Received On", dataKey: "data_received_ts" });
    let keys = ['sno', 'ulb_nm', 'asrt_nm', 'asrt_ctgry_nm', 'dev_imei_nu', 'crw_nm', 'crw_ph', 'asrt_sts_nm', 'data_received_ts'];
    for (var i = 0; i < this.vhcleslst.length; i++) {
      let row = [];
      for (var j = 0; j < keys.length; ++j) {
        let key = keys[j];
        row.push(this.vhcleslst[i][key]);
      }
      data.push(row);
    } console.log(data)
    var pdfsize = 'a4';
    var doc = new jsPDF("l", "mm", pdfsize);
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    var logotx = "assets/images/logo/swatchAndraLgo.png";
    var rpt_hdr_tx = 'SWACHHA ANDHRA CORPORATION';
    var ovrall_wdth = 30;
    var ovrall_hgt = 25;

    doc.addImage(logotx, 'PNG', 15, 5, ovrall_wdth, ovrall_hgt);
    doc.setTextColor("brown");
    doc.setFontSize(16);
    var fontSize = doc.getFontSize();
    var pageWidth = doc.internal.pageSize.width;
    // Calculate text's x coordinate
    var x = (pageWidth) / 2;
    doc.text(rpt_hdr_tx, x, 14, { align: "center" });
    doc.setTextColor("black");
    doc.setFontSize(12);
    var rpt_nm = 'VehiclesList';
    doc.text(rpt_nm, x, 20, { align: "center" });
    doc.setTextColor("blue");
    // doc.setFontSize(11);
    doc.setTextColor("blue");
    (doc as any).autoTable({
      head: [head],
      body: data,
      theme: 'grid',
      startY: 31,
      headStyles: { fillColor: [57, 50, 126], fontSize: 8 },
      bodyStyles: { fontSize: 8 },
      didDrawCell: data => {

      }
    })
    // Open PDF document in new tab
    doc.output('dataurlnewwindow')
    // Download PDF document  
    doc.save("VehiclesList.pdf");
  }
  cardchnge(sts) {
    console.log(sts);
    this.asrt_sts_id = sts;
    this.cardcntSummary.forEach(e => {
      if (e.asrt_sts_id == sts) {
        this.sts_cd = e.asrt_sts_nm
        console.log(this.sts_cd)
        e.crdChnge = true
      } else {
        e.crdChnge = false
      }

    });
    this.getVhclsLst();
  }
}
