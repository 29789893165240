<ds-loading *ngIf="showLdr"></ds-loading>
<div class="row hdrBar">
    <div class="col-lg-4 col-md-4 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0 && clntDtls[0].clnt_id==7">Trip Wise Coverage Status
            <span class="tntTxt">-{{rdrctSvmDtls.TOT_VEHICLES}}</span>
        </h1>
    </div>
    <div class="col-lg-3 col-md-3 mg-a">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
    <div class="col-lg-2 col-md-2 mg-a">
        <button nz-button nzType="default" (click)="goBack()" style="    box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
        color: #ffffff;
        font-weight: 500;
        background: #ff7272;
        float: right;
        border-radius: 4px;"><span style="color:#fff"><i nz-icon nzType="left"></i></span> <span style="color:#fff">Go
                back</span></button>
    </div>
</div>

<div class="main">
    <div class="row">
        <ul class="cards">
                <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="    background: linear-gradient(135deg, #6b3a0b 0%,#ab5d4a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/routes.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #8e4d2d;"></div>
                                <div class="cvrg_crd_cnt">{{d.OPERATED === null ? "0" : d.OPERATED}} / {{d.TOTAL_TRIPS === null ? "0" : d.TOTAL_TRIPS}}<span style="font-size: 12px;"> ({{d.op_per}} %)</span></div>
                                <div class="cvrg_crd_lbl">Operated Trips/Total Trips</div>
                            </div>
                        </div>


                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
            
                <div class="card_g" style="   background: linear-gradient(135deg, #665f03 0%,#817e26 100%);">
                    <div class="card_content">


                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/rout.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #6c660b;"></div>
                                <div class="cvrg_crd_cnt">{{d.ROUTE_DEVIATED === null ? "0" : d.ROUTE_DEVIATED}}</div>
                                <div class="cvrg_crd_lbl">Route Deviated</div>
                            </div>

                        </div>


                    </div>
                </div>
              
            </li>
          
                <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/discount.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #28816b;"></div>
                               
                                <div class="cvrg_crd_cnt">{{d.coverage_per === null ? 0 : d.coverage_per}}</div>
                                <div class="cvrg_crd_lbl">Coverage %</div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </li>
            
                <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #477085 0%,#34587a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/bus.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                              
                                <div class="cvrg_crd_cnt">{{d.trvl_dist === null ? "0" : d.trvl_dist}} Km</div>
                                <div class="cvrg_crd_lbl">Total Distance</div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </li>
        </ul>
    </div>
</div>

<div class="dxgridpdng svmcvrgedshbrd" style="padding-top:0px">
    <h1 class="bdycntnthdr ">Vechile wise Trip Coverage Status</h1>
   

    <dx-data-grid class="p-8" id="gridContainer" [dataSource]="tripsData" [remoteOperations]="false"
        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%" [wordWrapEnabled]="false"
        (onToolbarPreparing)="true" [columnAutoWidth]="true">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [visible]="tripsData.length>10" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
            [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
        </dxo-search-panel>
        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="true" fileName="ulb wise operated Trips">
        </dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
            [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
        </dxi-column>
    </dx-data-grid>
</div>
<div #pdfContent>
    <div class="row bg-wp maphgt">
        <div class="col-lg-12 col-md-12">
            <!-- <div class=" row rowr">
                <div class="col-lg-3 col-md-3">
                    <label nz-checkbox [(ngModel)]="shwCvrge" (ngModelChange)="onChkCoverage($event)">Coverage</label>
                    <label nz-checkbox [(ngModel)]="shwplyback" (ngModelChange)="onChkPlyback($event)">Playback</label>
                </div>



                <h1 class="bdycntnthdr " *ngIf="shwCvrge">Coverage of {{rdrctSvmDtls.TOT_VEHICLES}}</h1>
                <h1 class="bdycntnthdr " *ngIf="!shwCvrge">Playback of {{rdrctSvmDtls.TOT_VEHICLES}}</h1>
                <div class="mapdslctnDtls" *ngIf="shwCvrge">
                    <div class="travelruts">
                        <div class="grenbx"></div>
                        <div class="redbxlbl">Covered Area</div>
                    </div>
                    <div class="scheduleruts">
                        <div class="redbx"></div>
                        <div class="redbxlbl"> Not Covered Area</div>
                    </div>
                </div>
            </div> -->




            <div class="row">
                <div class="col-lg-4">
                    <label nz-checkbox [(ngModel)]="shwCvrge" (ngModelChange)="onChkCoverage($event)">Coverage</label>
                    <label nz-checkbox [(ngModel)]="shwplyback" (ngModelChange)="onChkPlyback($event)">Playback</label>
                </div>
                <div class="col-lg-4">
                    <h1 class="bdycntnthdr " *ngIf="shwCvrge">Coverage of {{rdrctSvmDtls.TOT_VEHICLES}}</h1>
                    <h1 class="bdycntnthdr " *ngIf="!shwCvrge">Playback of {{rdrctSvmDtls.TOT_VEHICLES}}</h1>
                </div>
                <div class="col-lg-4">
                    <div class="mapdslctnDtls" *ngIf="shwCvrge">
                        <div class="travelruts">
                            <div class="grenbx"></div>
                            <div class="redbxlbl">Covered Area</div>
                        </div>
                        <div class="scheduleruts">
                            <div class="redbx"></div>
                            <div class="redbxlbl"> Not Covered Area</div>
                        </div>
                    </div>
                    <div *ngIf="!shwCvrge">

                        <div class="mapdslctnDtls">
                            <div class="travelruts">
                                <div class="grenbx"></div>
                                <div class="redbxlbl">Schedule Route</div>
                            </div>
                            <div class="scheduleruts">
                                <div class="redbx"></div>
                                <div class="redbxlbl"> Travelled Route</div>
                            </div>
        
                            <div class="trvlruts">
                            </div>
                        </div>
        
                    </div>



                </div>
            </div>

            
            <!-- <div *ngIf="!shwCvrge">

                <div class="mapdslctnDtls">
                    <div class="travelruts">
                        <div class="grenbx"></div>
                        <div class="redbxlbl">Schedule Route</div>
                    </div>
                    <div class="scheduleruts">
                        <div class="redbx"></div>
                        <div class="redbxlbl"> Travelled Route</div>
                    </div>

                    <div class="trvlruts">
                    </div>
                </div>

            </div> -->
            <div id="map"></div>
        </div>
    </div>
</div>