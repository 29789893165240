import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleTripsComponent } from './schedule-trips.component';
import { SvmTripScheduleComponent } from './svm-trip-schedule/svm-trip-schedule.component';
import { TripOperationsComponent } from './trip-operations/trip-operations.component';


const routes: Routes = [
  { path: 'addtrip', component: ScheduleTripsComponent,  pathMatch : 'full' },
  { path: 'svm-trip', component: SvmTripScheduleComponent,  pathMatch : 'full' },
  { path: 'trip-operations', component: TripOperationsComponent,  pathMatch : 'full' },
  { path: '**', redirectTo : 'addtrip' }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScheduleTripsRoutingModule { }
