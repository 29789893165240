<!-- <div>
  <i nz-icon nzType="folder" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">Create Page</b>
</div> -->
<pg-header title="Create Page" ticon="folder" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">
  <div style="text-align: right;">
    <!-- <span nz-tooltip nzTooltipTitle="Create new page group" nzTooltipPlacement="bottom"> -->
    <button nz-button nzType="primary" (click)="createNwgrp();"><i nz-icon nzType="plus"></i>Create New Group</button>
    <!-- </span> -->
  </div>

  <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="true" [nzWidth]="370" [nzVisible]="visible"
    nzTitle="Create new page" (nzOnClose)="close()">
    <form nz-form [formGroup]="validateForm">
      <!-- <nz-form-item>
          <nz-form-label [nzSpan]="8">Page Group Name</nz-form-label>
          <nz-form-control [nzSpan]="16">
            <input formControlName="pge_grp_nm" nz-input placeholder="please enter user name" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8">Description</nz-form-label>
          <nz-form-control [nzSpan]="16">
            <textarea formControlName="pge_grp_dscn_tx" nz-input placeholder="please enter page description"></textarea>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8">Select Profile</nz-form-label>
          <nz-form-control [nzSpan]="16">
        <nz-select
      [nzMaxTagCount]="3"
      [nzMaxTagPlaceholder]="tagPlaceHolder"
      nzMode="multiple"
      nzPlaceHolder="Please select" formControlName="prfle_id"
    >
      <nz-option *ngFor="let a of appPrfls" [nzLabel]="a.prfle_nm" [nzValue]="a.prfle_id"></nz-option>
    </nz-select>
    </nz-form-control>
    </nz-form-item> -->

      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
          <nz-form-item>
            <nz-form-control>
              <div class="inptLbl">Page Group Name</div>
              <input formControlName="pge_grp_nm" nz-input placeholder="please enter user name" />
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
          <nz-form-item>
            <nz-form-control>
              <div class="inptLbl">Description</div>
              <textarea formControlName="pge_grp_dscn_tx" nz-input
                placeholder="please enter page description"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
          <div class="inptLbl">Select Profile</div>
          <nz-form-item>
            <nz-form-control>
              <nz-select [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                nzPlaceHolder="Please select" formControlName="prfle_id">
                <nz-option *ngFor="let a of appPrfls" [nzLabel]="a.prfle_nm" [nzValue]="a.prfle_id"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <ng-template #tagPlaceHolder let-selectedList> and {{ selectedList.length }} more selected </ng-template>

      <div style="float: right">
        <button nz-button style="margin-right: 8px;" (click)="close()">Cancel</button>
        <button nz-button nzType="primary" (click)="submit()" [disabled]="!validateForm.valid">Submit</button>
      </div>
    </form>
  </nz-drawer>
  <div class="m-24">

    <div>
      <div>
        <div class="input-row" nz-row>
          <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="11">
            <div class="inptLbl">Page Groups</div>
            <nz-select required [(ngModel)]="selectedGroupId" (ngModelChange)="onPgeGrpSelection()">
              <nz-option *ngFor="let pg of pagesLst" [nzValue]="pg.pge_grp_id" [nzLabel]="pg.pge_grp_nm">
              </nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>

    <div nz-row class="gridTop" *ngIf="(permissions.slct_in==1)?true:false">
      <ds-loading *ngIf="loader"></ds-loading>
      <div class="ds-card grid">
        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="rowData" [remoteOperations]="false"
          [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
          [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
          (onToolbarPreparing)="(USER_PERMISSIONS.insrt_in==1)?onToolbarPreparing($event):false"
          (onCellClick)="onCellClick($event)" (onCellPrepared)="onCellPrepared(null,$event)">
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
            [showInfo]="true"></dxo-pager>
          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
          <dxo-filter-panel [visible]="true"></dxo-filter-panel>
          <dxo-group-panel [visible]="true"></dxo-group-panel>
          <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
          <dxo-column-chooser [enabled]="true" mode="select">
          </dxo-column-chooser>
          <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
            fileName="Change Log"></dxo-export>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
            [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [allowFiltering]="f.filter"></dxi-column>
          <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" type="buttons"
            [width]="100" caption="Edit" cellTemplate="editTemplate">
            <div *dxTemplate="let data of 'editTemplate'">
              <div class="tblEdtBtn" (click)="onEdit2(data)">
                Edit
              </div>
            </div>
          </dxi-column>
          <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons"
            [width]="100" caption="Delete" cellTemplate="delTemplate">
            <div *dxTemplate="let data of 'delTemplate'">
              <div class="tblDelBtn" (click)="onDelete2(data)">
                Delete
              </div>
            </div>
          </dxi-column>
        </dx-data-grid>
      </div>
    </div>

    <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
      [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="heading"
      (nzOnClose)="closeSideBar()">
      <form nz-form [formGroup]="validatepgaeForm">
        <!-- <nz-form-item>
            <nz-form-label [nzSpan]="8">Page Name</nz-form-label>
            <nz-form-control [nzSpan]="16">
              <input formControlName="pge_nm" nz-input placeholder="please enter page name" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8">Page Url</nz-form-label>
            <nz-form-control [nzSpan]="16">
              <input formControlName="pge_url_tx" nz-input placeholder="please enter page url" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8">Page Description</nz-form-label>
            <nz-form-control [nzSpan]="16">
              <textarea formControlName="pge_dscn_tx" nz-input placeholder="please enter page description"></textarea>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8">Select Page Group</nz-form-label>
            <nz-form-control [nzSpan]="16">
              <nz-select required nzPlaceHolder="Select Type" formControlName="pge_grp_id">
                <nz-option *ngFor="let pg of pagesLst" [nzValue]="pg.pge_grp_id" [nzLabel]="pg.pge_grp_nm">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item> -->

        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
            <nz-form-item>
              <nz-form-control>
                <div class="inptLbl">Page Name</div>
                <input formControlName="pge_nm" nz-input placeholder="please enter page name" />
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>
        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
            <nz-form-item>
              <nz-form-control>
                <div class="inptLbl">Page Url</div>
                <input formControlName="pge_url_tx" nz-input placeholder="please enter page url" />
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>


        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
            <nz-form-item>
              <nz-form-control>
                <div class="inptLbl">Page Description</div>
                <textarea formControlName="pge_dscn_tx" nz-input placeholder="please enter page description"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>


        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn">
            <div class="inptLbl">Select Page Group</div>
            <nz-form-item>
              <nz-form-control>
                <nz-select required nzPlaceHolder="Select Type" formControlName="pge_grp_id">
                  <nz-option *ngFor="let pg of pagesLst" [nzValue]="pg.pge_grp_id" [nzLabel]="pg.pge_grp_nm">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>



        <div style="float: right">
          <button nz-button style="margin-right: 8px;" (click)="closeSideBar()">Cancel</button>
          <button nz-button nzType="primary" (click)="submitpge()" *ngIf="!editClicked"
            [disabled]="!validatepgaeForm.valid">Submit</button>
          <button nz-button nzType="primary" (click)="updatepge()" *ngIf="editClicked"
            [disabled]="!validatepgaeForm.valid">Save</button>
        </div>
      </form>
    </nz-drawer>
  </div>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
  </nz-result>
</div>