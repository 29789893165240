<div class="dashboard">
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <nz-card class="shadowcard svmdbrdgrph"> -->
            <div id="map1" class="shadowcard svmdbrdgrph"> </div>
            <!-- </nz-card> -->
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12" style="padding:15px;">
            <nz-card class="shadowcard" style="width:100%; height:auto;">
                <!-- <div nz-row [nzGutter]="8" class="shadowcard">
                    <div nz-col [nzSpan]="12" class="attnBdrRght">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Residential</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{rScCount}} /{{rCount}}</p>

                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">
                            Residential&Commercial</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{rcScCount}} /{{rcCount}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12" class="gtsBdrRght brdertop">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Apartments</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{aScCount}} /{{aCount}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12" class="brdertop">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Bulk Commercial
                        </p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{bcScCount}} /{{bcCount}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                </div> -->

                <ul class="cards">
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg,  #63badb 0%,#35a1af 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Residential</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">{{rScCount === null ? "0" : rScCount}}
                                        </h1>
                                        <h1 class="card_sub_title">Scanned Gates</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{rCount=== null ? "0" : rCount}}
                                        </h1>
                                        <h1 class="card_sub_title">Total Gates</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg, #7f75e5 0%,#4e49b9 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Residential&Commercial</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">{{rcScCount === null ? "0" : rcScCount}}
                                        </h1>
                                        <h1 class="card_sub_title">Scanned</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{rcCount === null ? "0" : rcCount}}
                                        </h1>
                                        <h1 class="card_sub_title">Total</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg, #9267d9 0%,#683eb5 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Apartments</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">{{aScCount === null ? "0" : aScCount}}
                                        </h1>
                                        <h1 class="card_sub_title">
                                           Scanned</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{aCount === null ? "0" : aCount}}
                                        </h1>
                                        <h1 class="card_sub_title">
                                           Total</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg, #63b5f5 0%,#449ee5 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Bulk Commercial</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{bcScCount === null ? 0 : bcScCount}}
                                        </h1>
                                        <h1 class="card_sub_title">Scanned</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{bcCount === null ? 0 : bcCount}}
                                        </h1>
                                        <h1 class="card_sub_title">Total</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </nz-card>
        </div>
    </div>
    <div class="dashboard-card">
        <nz-card>
            <div nz-row [nzGutter]="8" style="margin-bottom: 10px;">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="shadowcard gtsgrph verScroll">
                        <p class="crd-gp-hrd" style="color: #9e1644;
            padding-left: 10px;
            padding-top: 10px;
            letter-spacing: 0px;">Not Scanned Gates</p>
                        <div class="row gridTop" id="target">
                            <div class="col-lg-12 col-md-12 mgnb-2 p-8">
                                <dx-data-grid class="p-8" id="gridContainer" [dataSource]="notScndGts"
                                    [remoteOperations]="false" [allowColumnReordering]="true"
                                    [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true"
                                    [showRowLines]="true" [showBorders]="true" style="width:100%" [wordWrapEnabled]="true">
                                    <dxo-paging [pageSize]="5"></dxo-paging>
                                    <dxo-pager [visible]="notScndGts.length>5" [showPageSizeSelector]="true"
                                        [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                                    </dxo-pager>
                                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                                    </dxo-search-panel>
                                    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                                    <dxo-group-panel [visible]="true"></dxo-group-panel>
                                    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                                    <dxo-column-chooser [enabled]="true" mode="select">
                                    </dxo-column-chooser>
                                    <!-- <dxo-scrolling mode="virtual"></dxo-scrolling> -->
                                    <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                                    <dxo-export [enabled]="true" fileName="Sachivalayam Data"></dxo-export>
                                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                                    <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field"
                                        [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width"
                                        allowResizing="true">
                                    </dxi-column>
                                </dx-data-grid>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="shadowcard gtsgrph">
                        <p class="crd-gp-hrd" style="color: #9e1644;
            padding-left: 10px;
            padding-top: 10px;
            letter-spacing: 0px;">Gates Scanned Last 7 Days </p>
                        <div style="display: block;" style="margin-top: 1.5%;">
                            <!-- <canvas baseChart class="hmechrt" [datasets]="barChartData" [labels]="barChartLabels"
                    [options]="barChartOptions" [colors]="barChartColors" [plugins]="barChartPlugins"
                    [legend]="barChartLegend" [chartType]="barChartType">
                </canvas> -->
                <div id="gatesChart"></div>

                        </div>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>

    <div>
        <div class="dashboard-card">
            <div class="row border-bottom">
                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="card-heading p-8">Street Wise Houses Coverage on <span
                            style="color: #321da4;">{{today.split("-").reverse().join("-")}}</span></div>
                            <div class="row">Click on the &nbsp;<i nz-icon nzType="home" class="bg-residential" nzTheme="outline"></i> &nbsp;to get the collection details </div>

                </div>
                <!-- <div class="col-lg-2 col-md-2">
                  <div id="monthbox"></div>
                </div> -->
                <div class="col-xl-8 col-lg-12 col-md-12">
                    <div class="row  p-8">
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <div style="padding: 6px;margin: 2px;" class="house-paid block mt-08prcnt"></div>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Gates Scanned</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <div style="padding: 6px;margin: 2px;" class="house-not-paid block mt-08prcnt"></div>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Gates Not Scanned</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <!-- <div class="bg-bulk-comrl block mt-08prcnt"></div> -->
                                <i nz-icon nzType="shop" nzTheme="outline" class="bg-bulk-comrl"
                                    style="padding: 6px;margin: 2px;height: 27px;"></i>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Bulk Commerical</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <!-- <div class="bg-apartment block mt-08prcnt"></div> -->
                                <i nz-icon nzType="database" nzTheme="outline" class="bg-apartment"
                                    style="padding: 6px;margin: 2px;height: 27px;"></i>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Apartments</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <!-- <div class="bg-residential block mt-08prcnt"></div> -->
                                <i nz-icon nzType="home" nzTheme="outline" class="bg-residential"
                                    style="padding: 6px;margin: 2px;height: 27px;"></i>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Residential</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <!-- <div class="bg-cmrl-res block mt-08prcnt"></div> -->
                                <i nz-icon nzType="insert-row-left" nzTheme="outline" class="bg-cmrl-res"
                                    style="padding: 6px;margin: 2px;height: 27px;"></i>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Commercial & Residential</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div id="monthbox"></div> -->
            </div>
            <div>
                <div class="row border-bottom" *ngFor="let s of strtWsegtsScndLst">
                    <div class="col-lg-3 col-md-3 col-12 street-name">
                        {{s.strt_nm}}
                    </div>
                    <div class="col-lg-9 col-md-9 col-12 street-house-padding">
                        <div class="row">
                            <div *ngFor="let h of s.gates">
                                <i nz-icon nzType="home" nzTheme="outline" *ngIf="h.gte_ctgry_nm === 'Residential'"
                                    [ngClass]="{'color-green':h.isScanned === 1, 'color-red':h.isScanned === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential',
                                                   'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Residential Commercial','bg-bulk-comrl': h.gte_ctgry_nm ===  'Bulk Commercial'}"
                                    style="padding: 6px;margin: 2px;" (click)="open(h)"></i>
                                <i nz-icon nzType="database" nzTheme="outline" *ngIf="h.gte_ctgry_nm === 'Apartment'"
                                    [ngClass]="{'color-green':h.isScanned === 1, 'color-red':h.isScanned === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential',
                                                   'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Residential Commercial','bg-bulk-comrl': h.gte_ctgry_nm ===  'Bulk Commercial'}"
                                    style="padding: 6px;margin: 2px;" (click)="open(h)"></i>
                                <i nz-icon nzType="insert-row-left" nzTheme="outline"
                                    *ngIf="h.gte_ctgry_nm === 'Residential Commercial'"
                                    [ngClass]="{'color-green':h.isScanned === 1, 'color-red':h.isScanned === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential',
                                                   'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Residential Commercial','bg-bulk-comrl': h.gte_ctgry_nm ===  'Bulk Commercial'}"
                                    style="padding: 6px;margin: 2px;" (click)="open(h)"></i>
                                <i nz-icon nzType="shop" nzTheme="outline" *ngIf="h.gte_ctgry_nm ===  'Bulk Commercial'"
                                    [ngClass]="{'color-green':h.isScanned === 1, 'color-red':h.isScanned === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential',
                                                   'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Residential Commercial','bg-bulk-comrl': h.gte_ctgry_nm ===  'Bulk Commercial'}"
                                    style="padding: 6px;margin: 2px;" (click)="open(h)"></i>

                            </div>

                            <!-- <i nz-icon nzType="home" nzTheme="fill" style="background-color: #F9EAEA;color: red;padding: 6px;
                      margin: 2px;"></i>
                      <i nz-icon nzType="home" nzTheme="fill" style="background-color: #FBEDBC;color: green;padding: 6px;
                      margin: 2px;"></i> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<nz-drawer
      [nzClosable]="true"
      [nzWidth]="500"
      [nzVisible]="visible"
      nzTitle="House Info"
      (nzOnClose)="close()"
    >
    <ng-container *nzDrawerContent>
      <nz-list>
        <nz-list-item nz-row style="background-color: #f6f1ff;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Gate Number</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{gateNu}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Gate Name</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{gateNm}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: #f6f1ff;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Street Name</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12"></div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">No.of Residentials</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{numOfRs}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: #f6f1ff;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">No.of People</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{numOfPl}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">scanned on {{today.split("-").reverse().join("-")}}</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{scanned}}</div>
        </nz-list-item>
        <nz-list-item nz-row>
            <div id="map"> </div>
        </nz-list-item>
        <nz-list-item nz-row>
            <div>Day wise gate Scanned for month {{mnthName}}</div>
            <div nz-row style="float:right;margin-top:15px;"><div nz-col style="height:20px;width:20px;background-color: #00ce00ad;"></div><span nz-col style="padding-top: 5px;">&nbsp;&nbsp;Gates Scanned</span></div>
            <div style="    display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 15px;">
                <div *ngFor="let obj of houseInfo">
                    <!-- <div style="background-color: silver;padding:1%"> -->
                    <div>
                    <div style="height: 21px;
                    width: 25px;
                    margin: 2.5px;
                    text-align: center;
                    padding-top: 6px;
                    color: black;
                    font-weight: 600;
                    font-size: 14px;
                    background-color: rgba(0, 206, 0, 0.68);
                    border: 1px solid #c8a9fd;" [ngStyle]="{'background-color': (obj.cvrd_sts=='Covered') ? '#00ce00ad' : '#f6f1ff'}">{{obj.day_dt}}</div>
                    </div>
                </div>
            </div>
        </nz-list-item>
      </nz-list>
      </ng-container>
    </nz-drawer>