import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { FormBuilder, FormGroup, FormControl, FormArray} from '@angular/forms';
import * as moment from 'moment'; 
import { differenceInCalendarDays } from 'date-fns';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';


@Component({
  selector: 'app-trips-dashboard',
  templateUrl: './trips-dashboard.component.html',
  styleUrls: ['./trips-dashboard.component.scss']
})
export class TripsDashboardComponent implements OnInit {

  header_label = {
    name:"Daily Trips"
  }
  trnsfrPntsCvrdDta: any;
  trnsfrPntsCvrdcolumnDefs;
  trnsfrPntsNtCvrdDta: any;
  trnsfrPntsNtCvrdcolumnDefs;
  dateFormat = 'yyyy/MM/dd';
  monthFormat = 'yyyy/MM';
  selectedValue = null;
  showLdr = true; vehCtgrys; postslctdDt;
  wghmnts = false;
  selectedCtgry = 0; slctdDt = new Date(); curDate; curTblDate; rteDta; rdCnts;
  vehTrpLst: any;
  clntDtls: any;
  today = new Date();
  routemap: any = [];
  route_Polyline: any = [];
  tntLst: any = [];
  selectedtenant;
  currentDate;
  selectedVehicle = 0;
  selectedTrip;
  vehTrpLstMsg;
  selectedvndr;vendorList;
  ttl_veh;visible = false;
  trpSldrvisible = false;
  vhclSldrvisible = false;
  trpsdata;tripsDatacolumnDefs;
  clap_in: any;
  userDtls: any;
  ShowClap: boolean;
  wghmntForm: FormGroup;
  constructor(public apiServ: AppsService, public TransfereService: TransfereService,public route: Router, private message: NzMessageService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = 0;
    this.currentDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
      this.selectedvndr = 0;
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  
  ngOnInit() {
    this.wghmntForm = new FormGroup({
      veh_nu: new FormControl(''),
      grs_wght: new FormControl(''),
      tr_wght: new FormControl(''),
      nt_wght: new FormControl(''),
    });
    this.slctdDt = new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.get_vehCategories();
    this.get_vehCategories();
    this.getVendors(this.selectedtenant);
    this.get_totalVehiclesData()
  
  }



  getSldr(evnt){
    console.log(evnt);
    if (evnt.rowType == 'data' && evnt.column.dataField == "trp_sqnc_nu") {  
      console.log('Trippp');
      this.selectedTrip = evnt.data;
      this.trpSldrvisible = true;
    } else if (evnt.rowType == 'data' && evnt.column.dataField == "asrt_nm"){
      console.log('Vehice');
      this.selectedVehicle = evnt.data;
      this.vhclSldrvisible = true;
    }else if(evnt.rowType == 'data' && evnt.column.dataField == "ply_ln_oprtn"){
      if(evnt.data.ply_ln_oprtn =="Edit"){
      this.editPolyline(evnt.data);
      }else{
        this.addPolyline(evnt.data);
      }

    }
  }
  editPolyline(data){
console.log('editable polyline')
  }
  addPolyline(data){
    console.log('add new  polyline')

  }
  ontntSelect(value) {
    // console.log(value);
    if (value == 0) {
      this.selectedtenant = 0;
    }
      for (let i=0; i<this.tntLst.length; i++) {
        // console.log('in');
        if (this.tntLst[i].tnt_id == value) {
          this.selectedtenant = this.tntLst[i].tnt_id;
        }
      
    }
    this.getVendors(this.selectedtenant);
  }
  getVendors(slctTnt?) {
    this.showLdr = true;
    this.postslctdDt = {
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
   //  console.log('vendorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',this.postslctdDt);
      let rte = `dashboard/enterprise/getVhclewise_vendorlst`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      // console.log(res)
      if (res['status'] == 200) {
        this.showLdr = false;
        this.vendorList = res['data'];
       //  console.log(this.selectedvndr);
       if(this.vendorList.length>1){
        this.selectedvndr = 0;
      }else{
        this.selectedvndr = this.vendorList[0].vndr_id;
      }       //  console.log(this.selectedvndr);
        this.selectCategory(0,this.currentDate);
        this.get_totalVehiclesData()
        this.get_TripsData(this.currentDate,this.selectedtenant,this.selectedvndr,this.selectedCtgry,this.selectedVehicle);
      }
    }, (err) => {
      // console.log("In errorr");
    })
  }
  onvndrSelect(value) {
   //  console.log(this.selectedvndr);
    this.selectedvndr = value;
    // this.selectCategory(0,this.currentDate);
    this.get_totalVehiclesData()
    // this.get_TripsData(this.currentDate,this.selectedtenant,this.selectedvndr,this.selectedCtgry,this.selectedVehicle);
  }

  onChange(e): void {
    // console.log('onChange: ',e);
    this.selectedCtgry = 0;
    this.slctdDt = e;
    // console.log('slctdDt: ', this.slctdDt);
    this.currentDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');

    // console.log(this.currentDate);
    this.selectCategory(0,this.currentDate);
    this.get_vehCategories();
    this.get_TripsData(this.currentDate,this.selectedtenant,this.selectedvndr,this.selectedCtgry,this.selectedVehicle);
  }


  get_vehCategories(): any {
    this.showLdr = true;
    this.apiServ.get(`dashboard/enterprise/get_vehCtgrys`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.vehCtgrys = res['data'];
          // console.log(this.vehCtgrys);
          this.selectedCtgry = 0;
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  selectCategory(evnt,dt?,vehID?){
    console.log('selectCategory', evnt, this.selectedVehicle);
   //  console.log(this.currentDate);
    this.selectedCtgry = evnt;
    this.get_totalVehiclesData()
    // this.get_TripsData(this.currentDate,this.selectedtenant,this.selectedvndr,this.selectedCtgry,this.selectedVehicle);
  }
  selectVehicle(evnt){
    console.log(evnt);
    this.selectedVehicle = evnt;
    // this.get_allCtgrysData(evnt.itemData.asrt_id);
    this.get_TripsData(this.currentDate,this.selectedtenant,this.selectedvndr,this.selectedCtgry,this.selectedVehicle);
  }
  get_totalVehiclesData(){
    this.showLdr = true;
    this.ttl_veh = []
    this.postslctdDt = {
      dt: this.currentDate,
      selectedtenant: this.selectedtenant,
      selectedctgry: this.selectedCtgry,
      selectedvndr: this.selectedvndr,
      tnt_in: 1
    }
    console.log('posting Total Vehicles', this.postslctdDt);
    const rte = `dashboard/enterprise/total_vehicles`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.ttl_veh = res['data'];
          console.log(this.ttl_veh);
          this.showLdr = false;
          // console.log(this.params.length)
            // this.selectedVehicle = this.ttl_veh[0].asrt_id;
            console.log(this.selectedVehicle);
          
          this.get_TripsData(this.currentDate,this.selectedtenant,this.selectedvndr,this.selectedCtgry,this.selectedVehicle);

          // this.selectCategory(0,this.currentDate,this.selectedVehicle);
        }
      }
    }, (err) => {
      // console.log(err);
    });

  }
  get_TripsData(dt?,ulb?,vndr?,ctgry?,vhcl?){

    console.log(dt,ulb,vndr,ctgry,vhcl);

    this.showLdr = true;
    this.postslctdDt = {
      dt: this.currentDate,
      selectedtenant: ulb,
      selectedctgry: ctgry,
      selectedvndr: vndr,
      asrtId: vhcl
    }
    // console.log('posting Filterssssssssssssssss', this.postslctdDt);
    const rte = `dashboard/enterprise/get_tdyFltrTripDetails`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.trpsdata = res['data'];
          this.trpsdata.filter((k) => {
            if(k['ply_sts']==1){
              k['ply_ln_oprtn']='Edit'
            }else{
              k['ply_ln_oprtn']='Add'
            }
            // k['sno'] = ++counter;
            // k['amnt_pyble_ct'] = formatter.format(k.amnt_pyble_ct);
          });
          // console.log(this.trpsdata);
          this.showLdr = false;
          this.tripsDatacolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, cellClass: 'pm-grid-number-cell', search: false },
            { headerName: 'Vehicle Registration Number', alignment: 'center', width: 130, field: 'asrt_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'center', cellClass: 'pm-grid-number-cell',filter: true, },
            { headerName: 'Sachivalayam', alignment: 'center', width: 130, field: 'svm_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Driver', alignment: 'center',  field: 'crw_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Trip No.', field: 'trp_sqnc_nu', width: 130, alignment: 'center', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Trip Type', field: 'trp_typ_nm', alignment: 'center', cellClass: 'pm-grid-number-cell',filter: true, groupIndex: '0', },
            { headerName: 'Trip Type', field: 'trp_typ_nm', alignment: 'center', cellClass: 'pm-grid-number-cell',filter: true},            
            { headerName: 'From', alignment: 'center',  field: 'strt_fnce_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'To', field: 'end_fnce_nm',  alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Actual Start', alignment: 'center', field: 'actl_strt_ts', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Actual End', field: 'actl_end_ts',  alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Trip Status', field: 'trp_status',  alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Travelled Distance', alignment: 'center', field: 'actl_trvl_dstne_ct', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Travelled Time', field: 'actl_trvl_tm',  alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Route Deviation', field: 'rt_dev', alignment: 'center', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Polyline', field: 'ply_ln_oprtn', alignment: 'center', cellClass: 'pm-grid-number-cell' },
            

          ]
          }
          // console.log(this.selectedVehicle);
        }
    }, (err) => {
      // console.log(err);
    });




  }

  gotoPlaybck(event) {
    this.TransfereService.setLoclData('plybckDta', event.row.data)
    // if(event.row.data.actl_strt!=undefined && event.row.data.actl_end !=undefined){
      this.route.navigate([`internal/play-back/list`]);
    // }
  }

  onCellPrepared(colDef, e) {

    // if (e.rowType === 'data' && e.row.data && e.column.dataField == 'Vehicle No') {
    //   e.cellElement.style.color = '#ff0000';
    //   e.cellElement.style.fontWeight = 500;
    //   e.cellElement.style.borderRadius = '10px';
    //   e.cellElement.style.background = 'rgba(243, 191, 176, 0.2784313725490196)';
    //   e.cellElement.style.backgroundClip = 'content-box';
    //   e.cellElement.style.cursor = 'pointer';
    // }
    // if (e.column.dataField == o.field) {
    if (colDef.length && colDef.length > 0) {
      colDef.filter((o) => {
        if (o.field == 'ply_ln_oprtn') {
          if (e.data && (e.displayValue == "Edit" ||e.displayValue == "Add") ) {
          e.cellElement.style.color = 'rgb(21 101 192);';
          e.cellElement.style.fontWeight = 500;
          e.cellElement.style.borderRadius = '10px';
          e.cellElement.style.background = 'rgb(222 234 247)';
          e.cellElement.style.backgroundClip = 'content-box';
          e.cellElement.style.cursor = 'pointer';
        }
        
      }
      })
    }

  }
  goToSchduleIntrfc(){
    this.route.navigate([`internal/schedule-trips/addtrip`]);
  }
  goToprimaryTrps(){
    this.route.navigate([`internal/schedule-trips/svm-trip`]);
  }
  eachVehData;
  addWghmnt(data) {
    this.showLdr = false;
    console.log(data);
    this.eachVehData=data.data;
    this.wghmnts = true;
    this.wghmntForm.get('veh_nu').setValue(data.data.asrt_nm); 
    this.get_TripsData();
  }
  closervrlst() {
    this.wghmnts = false;
    this.wghmntForm.reset();
    this.showLdr = false;
  } 
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  calculate() {
    var veh_dtls = this.wghmntForm.value;
    var result=veh_dtls.grs_wght-veh_dtls.tr_wght;
    if (result > 0) {
      this.wghmntForm.get('nt_wght').setValue(result);
    }else {
      this.createMessage('warning', 'Gross Weight should be greater than Tare Weight');
    } 
  }

  updateWeighmnt() {
    var veh_dtls = this.wghmntForm.value;
    var grss_wt = veh_dtls.grs_wght
    var tar_wt = veh_dtls.tr_wght
    var net_wt = grss_wt - tar_wt;
    var postData = {
      asrt_id: this.eachVehData.asrt_id,
      trp_rn_id: this.eachVehData.trp_rn_id,
      grs_wt: grss_wt,
      tr_wt: tar_wt,
      nt_wt: net_wt
    }
    console.log(postData)
    if (net_wt > 0 && grss_wt > tar_wt) {
      this.apiServ.post(postData, `dashboard/enterprise/wghmnt_dtls`).subscribe((res) => {
        if (res['status'] == 200) {
          this.createMessage('Success', 'Weighments added successfully');
        }
      })
    }else {
      // console.log(net_wt,grss_wt,tar_wt)
      this.createMessage('warning', 'Gross Weight should be greater than Tare Weight');
    }
    
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Trips TravelledData.xlsx');
      });
    });
  }

}
