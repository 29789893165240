<div class="row">
    <div class="col-lg-8 col-md-8 mg-a">
        <div class="row hdrBar">
            <div class="col-lg-12 col-md-12 mg-a">
                <h1>Vehicle Device Assignment</h1>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 mg-a">
        <button nz-button nzType="primary" class="adddrvrBtn" (click)="openSlider('add','');">Add Device</button>
    </div>
    <!-- <div class="col-lg-2 col-md-2 mg-a">
        <button nz-button nzType="primary" class="adddrvrBtn" >Assign Device</button>
    </div> -->
</div>
<ds-loading *ngIf="showLdr"></ds-loading>
<div class="fltrbg">
    <div class="row">
        <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst.length>1">
            <p class="slctlbl">ULB</p>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
                nzPlaceHolder="Select ULB" [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
            </nz-select>
        </div>
        <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst && tntLst.length==1">
            <p class="slctlbl">ULB</p>
            <p class="slctlbl dsblTxt">{{tntLst[0].tnt_nm}}</p>
        </div>
        <div class="col-lg-2 col-md-2 crdCl" *ngIf="ShowClap">
            <p class="slctlbl">Vehicle Vendor</p>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
                [ngModelOptions]="{standalone: true}">
                <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
                <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
            </nz-select>
        </div>
        <!-- <div class="col-lg-2 col-md-2 crdCl">
            <p class="slctlbl">Vehicle Category</p>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedctgry"
                (ngModelChange)="onctgrySelect($event)" [ngModelOptions]="{standalone: true}">
                <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                <nz-option *ngFor="let v of vehctgryLst" [nzValue]="v.asrt_ctgry_id" [nzLabel]="v.asrt_ctgry_nm">
                </nz-option>
            </nz-select>
        </div>
        <div class="col-lg-4 col-md-4 crdCl">
            <p class="slctlbl">Date Range</p>
            <nz-range-picker class="slctBx" [(ngModel)]="range" [nzFormat]="dateFormat"
                (ngModelChange)="ondateChange($event)"></nz-range-picker>
        </div> -->
    </div>
</div>
<div class="main">
    <div class="row bg-wp">
        <h1 style="font-size: 20px;">Vehicle Devices Assignment Data</h1>
        <div class="ds-card grid tbl">
            <dx-data-grid id="gridContainer" [dataSource]="vehcleTble" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                (onToolbarPreparing)="true" [wordWrapEnabled]="true">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="vehcleTble.length>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 20, 30, 50]" [showInfo]="true">
                </dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                <dxo-export [enabled]="true" fileName="vehicle device Data"></dxo-export>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [caption]="d.headerName"
                    [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="Edit"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="openSlider('assign',data);"><i nz-icon nzType="edit"
                                nzTheme="outline"></i></div>
                    </div>
                </dxi-column>
            </dx-data-grid>

        </div>
    </div>

</div>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="400" [nzVisible]="opensldr"
    nzTitle="{{sidebartitle}}" (nzOnClose)="closeSideBar()" >
    <ng-container *nzDrawerContent>
    <div class="row drvrDtlsfrm" *ngIf="showAdd">
        <div nz-row [nzGutter]="4">
            <div nz-col nzSpan="24">
                <div *ngIf="slctTntDv">
                    <nz-form-label>Select ULB</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant"
                                (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}">
                                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm">
                                </nz-option>
                            </nz-select>

                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- <div nz-col nzSpan="24">
                <nz-form-label>Vehicle Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehicle Number"
                            [(ngModel)]="asrt_id" (ngModelChange)="onvehicleselect($event)">
                            <nz-option *ngFor="let v of vehicleDta" [nzLabel]=v.asrt_nm [nzValue]="v.asrt_id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div> -->

            <div nz-col nzSpan="24">
                <nz-form-label>Enter Device Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input [(ngModel)]="devicedata.dvce_nm" placeholder="Device Number"
                            [ngModelOptions]="{standalone: true}" />

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Device Type</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Device Type"
                            [(ngModel)]="devicedata.dvce_type_id">
                            <nz-option *ngFor="let v of dvceTypeLst" [nzLabel]=v.dvce_type_nm
                                [nzValue]="v.dvce_type_id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Device Category</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Device Category"
                            [(ngModel)]="devicedata.dvce_ctgry_id">
                            <nz-option *ngFor="let v of dvceCtgryLst" [nzLabel]=v.dvce_ctgry_nm
                                [nzValue]="v.dvce_ctgry_id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>IMEI Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input type="number" [(ngModel)]="devicedata.imei_nu" placeholder="Device Number"
                            [ngModelOptions]="{standalone: true}" />

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Mobile Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input type="number" [(ngModel)]="devicedata.mble_nu" placeholder="Device Number"
                            [ngModelOptions]="{standalone: true}" />

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="btngrp">
                <button nz-button style="margin-right: 8px;" (click)="closeSideBar()" nzType="primary"
                    nzDanger>Cancel</button>
                <button nz-button (click)="SubmitDetails()" nzType="primary" class="sbmtbtn">Submit</button>
            </div>
        </div>

    </div>

    <div class="row drvrDtlsfrm" *ngIf="!showAdd">
        <div nz-row [nzGutter]="4">
            <div nz-col nzSpan="24">
                <div *ngIf="slctTntDv">
                    <nz-form-label>Select ULB</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant"
                                (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}">
                                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm">
                                </nz-option>
                            </nz-select>

                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Vehicle Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehicle Number" [(ngModel)]="asrt_id"
                            (ngModelChange)="onvehicleselect($event)">
                            <nz-option *ngFor="let v of vehicleDta" [nzLabel]=v.asrt_nm [nzValue]="v.asrt_id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Device IMEI Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Device IMEI Number"
                            [(ngModel)]="dvce_id" (ngModelChange)="ondvceselect($event)">
                            <nz-option *ngFor="let d of dvceData" [nzLabel]=d.imei_nu [nzValue]="d.dvce_id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="btngrp">
                <button nz-button style="margin-right: 8px;" (click)="closeSideBar()" nzType="primary"
                    nzDanger>Cancel</button>
                <button nz-button (click)="assignDevice()" nzType="primary" class="sbmtbtn">Assign</button>
            </div>

        </div>

    </div>
    </ng-container>
</nz-drawer>