import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-districts',
  templateUrl: './districts.component.html',
  styleUrls: ['./districts.component.scss']
})
export class DistrictsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  columnDefs;
  shwSidebar = false;
  shwdstrctAlrt = false;
  shwdstrctEditDiv = false;
  sidebarHeading: string;
  shwdstrctDelDiv: boolean;
  shwLdg: boolean;
  dstrctLst: any;
  dstrctId: any;
  dstrctNm: string | null = null;
  ulbs: any = [];
  getuid;
  statesLst: any = [];
  state;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    // this.getDstrctLst();

    this.getUlbs();
    this.getstates();
  }
  getUlbs() {
    const rte = 'admin/ulbs';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.ulbs = res['data'];
          this.getuid = this.ulbs[0].ulb_id;
          this.getDstrctLst();

        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    });
  }

  getstates(){
  let rte =`admin/states`
  // console.log(rte)
  this.apiSrv.get(rte).subscribe((res) => {
    // console.log(res['data'])
    this.statesLst =res['data']
  })

  }

  getDstrctLst() {
    this.shwLdg = true;
    var ulb_id = this.getuid
    const rte = `admin/districts`;
    this.apiSrv.get(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      this.dstrctLst = res['data'];
      this.shwLdg = false;
      this.columnDefs = [
        { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
        { headerName: 'District Name', field: 'dstrt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 265 },
        { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
        { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
        { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
        { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
      ];
    });
  }

  onCellClick(opt, edtData) {
    if (opt === 'edit') {
      this.shwdstrctEditDiv = true;
      this.dstrctNm = edtData.data.dstrt_nm;
      this.dstrctId = edtData.data.dstrt_id;
      this.state = edtData.data.ste_id
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwdstrctEditDiv = false;
      this.shwdstrctDelDiv = true;
      this.dstrctId = edtData.data.dstrt_id;
      this.dstrctNm = edtData.data.dstrt_nm;
      this.state = edtData.data.ste_id
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New District',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwdstrctEditDiv = true;
      this.shwdstrctDelDiv = false;
      this.sidebarHeading = 'Edit District';
    } else if (opt === 'delete') {
      this.shwdstrctEditDiv = false;
      this.shwdstrctDelDiv = true;
      this.sidebarHeading = 'Delete District';
    } else {
      this.shwdstrctEditDiv = false;
      this.shwdstrctDelDiv = false;
      this.dstrctNm = '';
      this.sidebarHeading = 'Add New District';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  savedstrct() {
    const dstrctData = {
      name: this.dstrctNm,
      state: this.state
    };
    const rte = 'admin/districts';
    this.apiSrv.create(dstrctData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwdstrctAlrt = true;
        this.createMessage('success', 'District Successfully Added');
        this.getDstrctLst();
      }
    });
  }

  saveEditdstrct() {
    const dstrctEdtData = {
      id: this.dstrctId,
      name: this.dstrctNm,
      state : this.state
    };

    const rte = 'admin/districts/'+this.dstrctId;
    this.apiSrv.update(dstrctEdtData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwdstrctAlrt = true;
        this.createMessage('info', 'District Successfully Updated');
        this.getDstrctLst();
      }
    });
  }

  saveDeletedstrct() {
    const rte = 'admin/districts/'+this.dstrctId;
    this.apiSrv.delete(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwdstrctAlrt = true;
        this.createMessage('warning', 'District Successfully Deleted');
        this.getDstrctLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('District Not Deleted');
  }

}
