import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { MessageDialogComponent } from '../../../shared/components/message-dialog/message-dialog.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-sachivalayams',
  templateUrl: './sachivalayams.component.html',
  styleUrls: ['./sachivalayams.component.scss']
})
export class SachivalayamsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  gridData: any;
  isedit: boolean = false;
  isadd: boolean = false;
  isdel: boolean = false;
  editid: any;
  delid: any;
  myform: FormGroup;
  shwschvlymEditDiv: boolean
  shwschvlymDelDiv: boolean;
  editdata;
  constructor(private fb: FormBuilder, private themeService: ThemeConstantService, public apiSrv: AppsService, public atmSrv: AtomServiceService, private message: NzMessageService, private modal: NzModalService, private notification: NzNotificationService) { }
  columnDefs: any;
  sidebarHeading;
  initdata: any;
  loader: boolean;
  visible = false;
  gridApi;
  shwSidebar: boolean;
  selectdstrct
  ulbLst = []
  deldata
  dstrct_id;
  fnlmndlsLst = [];
  user: any = {
    permissions: { 'slct_in': 1, 'insrt_in': 1, 'updt_in': 1, 'dlte_in': 1, 'exprt_in': 1 },
    'perm_url': 'user/permissions/Desginations Creation'
  }
  permissions;
  mainMessage: any = null;
  dstrctsLst = []
  area = [];
  sachivalayamForm;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.sachivalayamForm = new FormGroup({
      svm_nm: new FormControl(''),
      svm_cd: new FormControl(''),
      svm_shrt_nm: new FormControl(''),
      dstrct_id: new FormControl(''),
      urbn_in: new FormControl(''),
      ulb_id: new FormControl('')
    });
    this.getGridData();
    // this.getdistricts();
    // this.getarea();
  }

  // getUsrPermissions(): any {
  //   this.mainMessage = null;
  //   this.apiSrv.get(this.user.perm_url).subscribe((res) => {
  //     // console.log(res['data']);
  //     this.user.permissions = res['data'][0];
  //     if (this.user.permissions.slct_in === 0) {
  //       this.mainMessage = 'You do not have permissions to do this operation. Please contact Administrator to get permissions.';
  //     } else
  //       this.getGridData();
  //   });
  // }
  getdistricts() {
    this.apiSrv.get('admin/districts').subscribe((res) => {
      // console.log(res['data'])
      this.dstrctsLst = res['data']
    })

  }
  getarea() {
    this.area = [
      {
        id: 1,
        area_nm: "Urban"
      },
      {
        id: 0,
        area_nm: "Rural"
      }
    ]
  }
  // getmndls(data)
  // {
  //   // console.log(data)
  //   // console.log(this.sachivalayamForm.value.dstrct_id);
  //   // console.log(this.sachivalayamForm.value.urbn_in)
  //   // let data={}
  //   if(this.shwschvlymEditDiv==true)
  //   {

  //   }
  //   else if(this.shwschvlymDelDiv==true)
  //   {
  //     data={
  //       dstrctid:this.deldata.dstrt_id,
  //     urb_in:this.deldata.urbn_in
  //     }
  //   }
  //   else
  //   {
  //     data ={
  //       dstrctid:this.sachivalayamForm.value.dstrct_id,
  //       urb_in:data
  //     }
  //   }


  //   let rte =`admin/mandals/`
  //   // console.log(data)
  //   this.apiSrv.create(data,rte).subscribe((res) => {
  //     // console.log(res['data'])
  //     this.mndlLst=res['data']
  //   })
  // }

  getmandls() {


  }
  getulbs(e) {
    this.dstrct_id = this.sachivalayamForm.value.dstrct_id
    if(e  !== ''){
      let rte = `admin/districts/${e}/ulbs`
      // console.log(rte)
      this.apiSrv.get(rte).subscribe((res) => {
        // console.log(res['data'])
        this.ulbLst = res['data']
      })
    }
   
  }

  // getmandlsbyarea(e)
  // {
  //   this.fnlmndlsLst=[]
  //   if(e==1)
  //   {
  //     this.mndlLst.filter((m)=>{
  //       if(m.urbn_in == 1)
  //       {
  //         this.fnlmndlsLst.push(m)
  //       }
  //     })
  //   }
  //   if(e == 0)
  //   {
  //     this.mndlLst.filter((m)=>{
  //       if(m.urbn_in == 0)
  //       {
  //         this.fnlmndlsLst.push(m)
  //       }
  //     })
  //   }
  // }

  getGridData() {
    // console.log('grid data called')
    this.mainMessage = null;
    this.loader = true;
    this.usrAcsErrMsg = false;
    this.apiSrv.get('sachivalayams/').subscribe((res) => {
      console.log(res)
      this.loader = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          // console.log(res['data'])
          // this.user.permissions = (res['perm'] === undefined) ? this.user.permissions : res['perm'][0];
          if (res['data'].length === 0) { this.mainMessage = 'No entries found in the database.'; }

          this.gridData = res['data'];
          // console.log(this.gridData)

          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 60, sortable: true, filter: false },
            { headerName: 'Sachivalayam Name', field: 'svm_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Sachivalayam Code', field: 'svm_cd', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Sachivalayam Short Name', field: 'svm_shrt_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', ortable: true, filter: true },
            { headerName: 'ULB', field: 'ulb_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'District', field: 'dstrt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'area', field: 'area', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true }
          ];
          // else if (res['status'] === 404) {
          //   let permTxt = 'Desginations Creation';
          //   let prmeRte = `user/permissions/${permTxt}`;
          //   this.crdsrv.get(prmeRte).subscribe((prmres) => {
          //   // console.log(prmres['data']);

          //   });
          //   // this.user.permissions = { 'slct_in': 0, 'insrt_in': 0, 'updt_in': 0, 'dlte_in': 0, 'exprt_in': 0 };
          // }
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    }, (err) => {
      this.loader = false;
    });
  }
  onToolbarPreparing(e) {
    // console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Sachivalayam',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(opt, data) {
    this.shwSidebar = true;
    // console.log(opt);
    // console.log(data);
    if (opt == 'edit') {
      this.shwschvlymEditDiv = true;
      this.shwschvlymDelDiv = false;
      this.sidebarHeading = 'Edit Sachivalayam';
      let data1 = {
        dstrctid: data.dstrt_id,
        urb_in: data.urbn_in
      }
      // this.getmndls(data1);
      this.getdistricts();
      this.getarea();
      this.sachivalayamForm.get('svm_nm').setValue(data.svm_nm)
      this.sachivalayamForm.get('svm_cd').setValue(data.svm_cd)
      this.sachivalayamForm.get('svm_shrt_nm').setValue(data.svm_shrt_nm)
      this.sachivalayamForm.get('dstrct_id').setValue(data.dstrt_id)
      this.sachivalayamForm.get('urbn_in').setValue(data.urbn_in)
      this.sachivalayamForm.get('ulb_id').setValue(data.ulb_id)
    } else if (opt == 'delete') {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = true;
      this.sidebarHeading = 'Delete Sachivalayam';
      // this.getmndls(data);
      this.getdistricts();
      this.getarea();
      this.sachivalayamForm.get('svm_nm').setValue(data.svm_nm)
      this.sachivalayamForm.get('svm_cd').setValue(data.svm_cd)
      this.sachivalayamForm.get('svm_shrt_nm').setValue(data.svm_shrt_nm)
      this.sachivalayamForm.get('dstrct_id').setValue(data.dstrt_id)
      this.sachivalayamForm.get('urbn_in').setValue(data.urbn_in)
      this.sachivalayamForm.get('ulb_id').setValue(data.ulb_id)
    } else {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = false;
      this.dstrctsLst = []
      this.area = []
      this.ulbLst = []
      this.getdistricts();
      this.getarea();
      this.sachivalayamForm.get('svm_nm').setValue(''),
        this.sachivalayamForm.get('svm_cd').setValue('')
      this.sachivalayamForm.get('svm_shrt_nm').setValue('')
      this.sachivalayamForm.get('dstrct_id').setValue('')
      this.sachivalayamForm.get('urbn_in').setValue('')
      this.sachivalayamForm.get('ulb_id').setValue('')
      this.sidebarHeading = 'Add New Sachivalayam';
    }

  }

  closeSideBar(): void {
    this.shwSidebar = false;
    this.sachivalayamForm.reset()
  }

  onEdit2(event) {
    // console.log(event)
    this.editdata = event.data
    this.openSideBar("edit", this.editdata)
  }
  onDelete2(event) {
    // console.log(event)
    this.deldata = event.data
    this.openSideBar("delete", this.deldata)
  }
  savesachivalayam() {
    // console.log(this.sachivalayamForm.value)
    let data = this.sachivalayamForm.value;
    let rte = `sachivalayams/`;
    this.apiSrv.create(data, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.shwSidebar = false;
        // this.shwDprtAlrt = true;
        this.createNotification('success', 'Sachivalayam Successfully Added');
        this.getGridData();
      }
    })
  }
  saveEditsachivalayam() {
    console.log(this.editdata);
    console.log(this.sachivalayamForm.value);
    let data = this.sachivalayamForm.value;
    let rte = `sachivalayams/` + this.editdata.svm_id;
    this.apiSrv.put(data, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.shwSidebar = false;
        this.createNotification('info', 'Sachivalayam Successfully Updated');
        this.getGridData();
      }
    })
  }
  saveDeletesachivalayam() {
    // console.log(this.deldata)
    let rte = `sachivalayams/` + this.deldata.svm_id;
    // console.log(rte)
    // this.apiSrv.delete

    this.apiSrv.delete(rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.shwSidebar = false;
        // this.shwDprtAlrt = true;
        this.createNotification('warning', 'Sachivalayam Successfully Deleted');
        this.getGridData();
      }
    })
  }

  createNotification(type: string, msg: string): void {
    this.notification.create(
      type,
      msg,
      ''
    );
  }
  cancel() {
    this.shwSidebar = false;
    this.sachivalayamForm.reset()
  }

}
