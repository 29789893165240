<div class="maindv">
    <div class="row hdrBar">
        <div class="col-lg-12 col-md-12 mg-a">
            <h1>Vehicle Category</h1>
        </div>
    </div>
    <br>
    <div style="padding-left :14px">
        <div class="bgwhite" [style.background]="'#fff'">
            <dx-data-grid id="grid" class="gridHeight" [dataSource]="ctgry_lst" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="false" [showColumnLines]="true"
                [showRowLines]="true" [showBorders]="true" style="width:100%;    max-width: 100%!important;"
                [allowColumnResizing]="true" [wordWrapEnabled]="true" (onToolbarPreparing)=onToolbarPreparing($event)>
                <dxo-paging [pageSize]="30"></dxo-paging>

                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 30, 50, 100]" [showInfo]="true">
                </dxo-pager>
                <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Vechile_lst">
                </dxo-export>
                <dxo-sorting mode="single">
                </dxo-sorting>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                <dxi-column *ngFor="let v of columnDefs" [dataField]="v.field" [caption]="v.headerName"
                    [alignment]="v.alignment" [width]="v.width" [allowResizing]="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" [width]="100" caption="Edit"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="openSideBar('Edit',data.data)">
                            &nbsp;&nbsp;<span
                                style="font-size: 11px;text-transform:capitalize; font-weight: 500;color: green;">Edit</span>
                        </div>
                    </div>
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" [width]="100" caption="Delete"
                    cellTemplate="delTemplate">
                    <div *dxTemplate="let data of 'delTemplate'">
                        <div class="tblEdtBtn" (click)="showDeleteConfirm(data.data)">
                            &nbsp;&nbsp;<span
                                style="font-size: 11px;text-transform:capitalize; font-weight: 500;color: red;">Delete</span>
                        </div>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>
</div>
<nz-drawer [nzVisible]="ctgryForm" nzPlacement="right" [nzTitle]="sdBrHdr" (nzOnClose)="close()" [nzWidth]="500">
    <ng-container *nzDrawerContent>
        <div nz-row [nzGutter]="8">

            <div nz-col nzSpan="12">
                <nz-form-label nzRequired>Vehicle Category Name</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input placeholder="Vehicle Category"  [(ngModel)]="ctgry_nm" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-label>Vehicle Category code</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input placeholder="Vehicle Category Code" [(ngModel)]="ctgry_cd"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="row" style="padding-left: 37%;padding-top: 11%;">
                <div style=" padding-right: 18px;">
                    <button nz-button nzType="primary" nzDanger>Cancel</button>
                </div>
                <div>
                    <button nz-button nzType="primary" style="padding-left: 24px;padding-right: 20px;"
                        (click)="addCategory()">Save</button>
                </div>
            </div>
        </div>
    </ng-container>
</nz-drawer>
