<div class="maindv">
    <div class="row hdrBar">
        <div class="col-lg-12 col-md-12 mg-a">
            <h1>Add Vehicle</h1>
        </div>
    </div>
    <br>
    <div class="row" style="padding-left :14px">
        <div class="col-md-4">
            <div style="font-size: 13px;font-weight: 600;">Select Client</div>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Client" [(ngModel)]="selectedclnt"
                (ngModelChange)="slct_clnt($event)" style="width: 80%;">
                <nz-option *ngFor="let c of clnts" [nzLabel]=c.clnt_nm [nzValue]="c.clnt_id"></nz-option>
            </nz-select>
        </div>
        <div class="col-md-4">
            <div style="font-size: 13px; font-weight: 600;">Select Ulb</div>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Client" [(ngModel)]="selectedTnt"
                style="width: 80%;" (ngModelChange)="slct_tnt($event)">
                <nz-option *ngFor="let t of tnts" [nzLabel]=t.tnt_nm [nzValue]="t.tnt_id"></nz-option>
            </nz-select>
        </div>

        <div class="col-md-4">
            <div style="padding-top: 18px;">
                <button nz-button nzType="primary" (click)="getAsrtDtls()" *ngIf="vchlDtaBtn">Get Details</button>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div style="padding-left :14px">
        <div class="bgwhite" [style.background]="'#fff'">
            <dx-data-grid id="grid" class="gridHeight" [dataSource]="vechile_lst" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="false" [showColumnLines]="true"
                [showRowLines]="true" [showBorders]="true" style="width:100%;    max-width: 100%!important;"
                [allowColumnResizing]="true" [columnWidth]="auto" [wordWrapEnabled]="true"
                (onToolbarPreparing)=onToolbarPreparing($event)>
                <dxo-paging [pageSize]="30"></dxo-paging>

                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 30, 50, 100]" [showInfo]="true">
                </dxo-pager>
                <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Vechile_lst">
                </dxo-export>
                <dxo-sorting mode="single">
                </dxo-sorting>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                <dxi-column *ngFor="let v of columnDefs" [dataField]="v.field" [caption]="v.headerName"
                    [alignment]="v.alignment" [width]="v.width" [allowResizing]="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" [width]="100" caption="Edit"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="openSideBar('Edit',data.data)">
                            &nbsp;&nbsp;<span
                                style="font-size: 11px;text-transform:capitalize; font-weight: 500;color: green;">Edit</span>
                        </div>
                    </div>
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" [width]="100" caption="Delete"
                    cellTemplate="delTemplate">
                    <div *dxTemplate="let data of 'delTemplate'">
                        <div class="tblEdtBtn" (click)="showDeleteConfirm(data.data)">
                            &nbsp;&nbsp;<span
                                style="font-size: 11px;text-transform:capitalize; font-weight: 500;color: red;">Delete</span>
                        </div>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>
</div>
<nz-drawer [nzVisible]="addVchleFrm" nzPlacement="right" [nzTitle]="sdbarHdr" (nzOnClose)="close()" [nzWidth]="500" >
    <ng-container *nzDrawerContent>
        <form nz-form [formGroup]="addVhcldtlsform">
            <div nz-row [nzGutter]="8">
                <div nz-col nzSpan="12">
                    <nz-form-label nzRequired>Client</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Client" formControlName="clnt"
                                [(ngModel)]="selectedclnt">
                                <nz-option *ngFor="let c of clnts" [nzLabel]=c.clnt_nm
                                    [nzValue]="c.clnt_id"></nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>



                <div nz-col nzSpan="12">
                    <nz-form-label nzRequired>Tenant</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Tenant" formControlName="tnt"
                                [(ngModel)]="selectedTnt" >
                                <nz-option *ngFor="let t of tnts" [nzLabel]=t.tnt_nm [nzValue]="t.tnt_id"></nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-label nzRequired>Vehicle Number</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>
                            <input nz-input formControlName="veh_nu" placeholder="Vehicle Number" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-label nzRequired>Vechile Type</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzAllowClear nzPlaceHolder="Select Vechile Type " formControlName="veh_typ">
                                <nz-option nzValue='1' nzLabel='Clap'></nz-option>
                                <nz-option nzValue='0' nzLabel='Non-Clap'></nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12" *ngIf="mstrDta">
                    <nz-form-label nzRequired>IMEI Number</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Device"
                                formControlName="imei_nu" (ngModelChange)="ImeiSlct($event)">
                                <nz-option *ngFor="let c of mstrDta.Imei" [nzLabel]=c.imei_nu
                                    [nzValue]="c.dvce_id">
                                </nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12" *ngIf="mstrDta">
                    <nz-form-label>Home Fence</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Home Fence"
                                formControlName="hme_fnce">
                                <nz-option *ngFor="let c of mstrDta.Fence" [nzLabel]=c.fnce_nm
                                    [nzValue]="c.fnce_id">
                                </nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="24" *ngIf="editform">
                    <nz-form-label>Select ULB</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>
                            <nz-select nzAllowClear nzPlaceHolder="Select ULB " formControlName="ULB" (ngModelChange)="editUlb($event)">
                                <nz-option *ngFor="let c of tnts" [nzLabel]=c.tnt_nm
                                [nzValue]="c.tnt_id">
                            </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12" *ngIf="mstrDta">
                    <nz-form-label nzRequired>Vehcile Category</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehcile Category"
                                formControlName="veh_ctgry" (ngModelChange)="ctgrtSlct($event)">
                                <nz-option *ngFor="let c of mstrDta.ctgry" [nzLabel]=c.asrt_ctgry_nm
                                    [nzValue]="c.asrt_ctgry_id">
                                </nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12" *ngIf="mstrDta">
                    <nz-form-label nzRequired>Vehcile Group</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehcile Group"
                                formControlName="veh_grp" (ngModelChange)="grpSlct($event)">
                                <nz-option *ngFor="let c of mstrDta.Groups" [nzLabel]=c.asrt_grp_nm
                                    [nzValue]="c.asrt_grp_id">
                                </nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12" *ngIf="mstrDta">
                    <nz-form-label nzRequired>Vehcile Status</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehcile Staus"
                                formControlName="veh_sts">
                                <nz-option *ngFor="let c of mstrDta.status" [nzLabel]=c.asrt_sts_nm
                                    [nzValue]="c.asrt_sts_id">
                                </nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12" *ngIf="mstrDta">
                    <nz-form-label nzRequired>Icon</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>

                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Icon" formControlName="icn_pth">
                                <nz-option *ngFor="let c of mstrDta.imgs" [nzLabel]=c.icon_path [nzValue]="c.icon_path">
                                </nz-option>

                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="15">
                    <label nz-checkbox [(ngModel)]="checked"   (ngModelChange)="ign_ckd($event)"  [ngModelOptions]="{standalone: true}">Select for Ignition Connection</label>
                </div>

                <div nz-col nzSpan="24" style="margin-top: 32px;background-color: #f9f0e4;" *ngIf="usdImei">
                    <span nz-icon nzType="info-circle" nzTheme="outline"></span> &nbsp;
                    <span style="font-weight: 500;">The Selected IMEI Number Was Already Assigned To {{asrt_nm}} Of {{tnt_nm}}
                        <!-- <span *ngIf="cndtn">  -->
                            <span class="unAsgnDvc" (click)="cnfrmDvcUnAssgn()">.Click Here</span> To Unassign Device & Continue</span>
                        <!-- </span> -->
                        <!-- <span *ngIf="!cndtn"> 
                            <span  style="font-weight: 500;">.You don't have access to this device.Please select another device.</span>
                        </span> -->
                </div>
                <div class="row" style="padding-left: 37%;padding-top: 11%;">
                    <div style=" padding-right: 18px;">
                        <button nz-button nzType="primary" nzDanger>Cancel</button>
                    </div>
                    <div>
                        <button nz-button nzType="primary" style="padding-left: 24px;padding-right: 20px;"
                            (click)="addVechile()">Save</button>
                    </div>

                </div>
            </div>


        </form>
    </ng-container>
</nz-drawer>
<nz-drawer  [nzVisible]="addDvceFrm" nzPlacement="right" nzTitle="Add New Device" (nzOnClose)="closedvcFrm()" [nzWidth]="500">
    <ng-container *nzDrawerContent>
        <div nz-row [nzGutter]="4">
            <div nz-col nzSpan="12">
                <nz-form-label>Client</nz-form-label>
                <nz-form-item>
                    <nz-form-control>

                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Client"
                            [(ngModel)]="selectedclnt" (ngModelChange)="slct_clnt($event)">
                            <nz-option *ngFor="let c of clnts" [nzLabel]=c.clnt_nm
                                [nzValue]="c.clnt_id"></nz-option>

                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>



            <div nz-col nzSpan="12">
                <nz-form-label>Tenant</nz-form-label>
                <nz-form-item>
                    <nz-form-control>

                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Tenant" 
                            [(ngModel)]="selectedTnt" (ngModelChange)="slct_tnt($event)">
                            <nz-option *ngFor="let t of tnts" [nzLabel]=t.tnt_nm [nzValue]="t.tnt_id"></nz-option>

                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Enter Device Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input [(ngModel)]="dvce_nm" placeholder="Device Number"
                            [ngModelOptions]="{standalone: true}" />

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Device Type</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Device Type"
                            [(ngModel)]="dvce_type_id">
                            <nz-option *ngFor="let v of dvceTypeLst" [nzLabel]=v.dvce_type_nm
                                [nzValue]="v.dvce_type_id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Device Group</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Device Category"
                            [(ngModel)]="dvce_ctgry_id">
                            <nz-option *ngFor="let v of dvceCtgryLst" [nzLabel]=v.dvce_ctgry_nm
                                [nzValue]="v.dvce_ctgry_id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>IMEI Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input type="number" [(ngModel)]="dvcimei_nu" placeholder="Device Number"
                            [ngModelOptions]="{standalone: true}" />

                    </nz-form-control>
                </nz-form-item>
            </div>
  
            <div nz-col nzSpan="24">
                <nz-form-label>Mobile Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input type="number" [(ngModel)]="mble_nu" placeholder="Device Number"
                            [ngModelOptions]="{standalone: true}" />

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Sim Number</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input type="number" [(ngModel)]="sim_nu" placeholder="Sim Number"
                            [ngModelOptions]="{standalone: true}" />

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div style="margin-left: 34%;">
                <button nz-button style="margin-right: 8px;" (click)="closedvcFrm()" nzType="primary"
                    nzDanger>Cancel</button>
                <button nz-button (click)="addDevice()" nzType="primary" class="sbmtbtn">Add</button>
            </div>

        </div>
    </ng-container>
</nz-drawer>