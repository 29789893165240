import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as L from 'leaflet';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { AppsService } from 'src/app/shared/services/apps.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';
import { ActivatedRoute } from '@angular/router'; 
import { Pipe, PipeTransform } from '@angular/core';


@Component({
  selector: 'app-vehicle-dashboard',
  templateUrl: './vehicle-dashboard.component.html',
  styleUrls: ['./vehicle-dashboard.component.scss']
})
export class VehicleDashboardComponent implements OnInit {
  header_label ={
    name : "Vehicle Dashboard"
  }
  vehDta: any;
  selectedVehicle;
  vehicleMap;
  vehicleMap1;
  currentDate;
  slctdDt = new Date();
  today = new Date();
  init_lat = 17;
  init_lng = 81;
  oprtnlhrs: any;
  vstdLctns: any;
  oprtdNtoprtd: any;
  trpLst: any;
  showLdr = false; ttl_veh;
  VehLctns: any;
  ovrSpeed: any;
  eachVeh: any;
  fuelDta: any;
  vehRts: any = [];
  vehTrgts: any;
  vehnm: any;
  asrt_sts_nm: any;
  ctgrynm: any;
  grpnm: any;
  CrntDyDst: any;
  hrlyDta: any;
  postslctdDt: any;
  rteDta: any;
  dateFormat = 'dd/MM/yyyy';
  selectedCtgry = 0;
  vehCtgrys: any;
  currentFrmtDate: string;
  params: any;
  curYr: any;
  selectedvndr;
  selectedtenant = 0;
  vendorList: any; clntDtls: any;
  tntLst: any = [];
  errMsg: string;
  vehErrMsg;
  vstdLctnsMsg: string;
  ovrSpeedMsg: string;
  oprtnlhrsMsg: string;
  fuelDtaMsg: string;
  chart1Msg: string;
  chart2Msg: string;
  ttl_vehMsg: string;
  vehTrpLstMsg: string; searchText;
  previousNotifications: any;
  previousNotificationsMsg: string;
  matchVehData: any;
  apply_actv = 0;
  vehOdmtr: any;
  devOdmtr: any;
  userDtls: any;
  clap_in: number;
  showVndr: boolean = false;
  ShowClap: boolean = false;
  loading;
  data_lst_rcvd: any;
  speed: any;
  vehHrlyMsg: string;
  vehHrlyDtls: any;
  postData: any;
  dst_tvl_tdy: any;
  VhcleDetails: any;
  chart1: any;
  title;
  constructor(public apiServ: AppsService, private route: ActivatedRoute,) {
    this.slctdDt = new Date();
    this.currentDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.currentFrmtDate = moment(this.slctdDt).format('DD-MM-YYYY');
    // console.log(this.currentDate,this.currentFrmtDate);
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    // this.selectedtenant = this.tntLst[0].tnt_id;
    if( this.tntLst.length>1 && this.tntLst.length<=8 ){
      this.title="Zone"
    }else{
      this.title="ULB"
    }
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
    this.route.queryParams.subscribe(params => {
      this.params = params;
      // console.log(params.asrt_id)
      if (params) {
        this.selectedVehicle = this.params.asrt_id;
      }
    });
  }

  initialize = function () {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.vehicleMap = L.map('vehicleMap', {
        center: [this.init_lat, this.init_lng],
        zoom: 3
      });

      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   maxZoom: 18,
      //   minZoom: 3,
      //   attribution: ''
      // });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: ''
      });

      tiles.addTo(this.vehicleMap);
      this.vehicleMap.setView([this.clntDtls[0].lat, this.clntDtls[0].lng], 13)


    } catch (e) {
      // console.log("please check map Initialization of leaflet:" + e);
    }

  };
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };

  ngOnInit() {

    this.curYr = moment(this.today).format('YYYY');
    this.get_totalVehiclesData()
    this.get_vehCategories();
    this.getVendors(this.selectedtenant);
    this.initialize();
    this.getPreviousNotifications(this.selectedVehicle);
    // console.log(this.selectedVehicle);
    this.getVehiclehourlyDtls(this.selectedVehicle);
    this.getVehInfo(this.selectedVehicle);
    this.get_distGraphsData(this.selectedVehicle);
    this.get_tripsGraphsData(this.selectedVehicle);

  }
  ontntSelect(value) {
    console.log(value);
    if (value == 0) {
      this.selectedtenant = 0;
    }
    for (let i = 0; i < this.tntLst.length; i++) {
      // console.log('in');
      if (this.tntLst[i].tnt_id == value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
      }

    }
    this.getVendors(this.selectedtenant);
  }
  getVendors(slctTnt?) {
   console.log('ddddd')
    if (this.userDtls.dsgns_id == 1000032) {
      console.log('ffffffffffffffffffff')
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      this.apiServ.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vendorList = res['data'];
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
            console.log(this.selectedvndr,'this.selectedvndr')
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
            console.log(this.selectedvndr ,'this.selectedvndr')
          }
          // this.selectCategory(0,this.currentDate);
          this.get_totalVehiclesData()
        }
      }, (err) => {
        // console.log("In errorr");
      })
    } else if (this.clap_in == 0) {
      this.vendorList = [];
      console.log('fffffffffffffff')
      this.get_totalVehiclesData(); //#change
    } else {
      console.log('gggggggggg')
      this.showLdr = true;
      this.postslctdDt = {
        tnt_in: 1,
        slctd_tnt: slctTnt
      }
       console.log('vendorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',this.postslctdDt);
      let rte = `dashboard/enterprise/getVhclewise_vendorlst`;
      this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.showLdr = false;
          this.vendorList = res['data'];
           console.log(this.selectedvndr);
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
          }    
                console.log(this.selectedvndr);
          // this.selectCategory(0,this.currentDate);
          this.get_totalVehiclesData()
        }
      }, (err) => {
        console.log("In errorr");
      })
    }
  }
  onvndrSelect(value) {
    //  console.log(this.selectedvndr);
    this.selectedvndr = value;
    // this.selectCategory(0,this.currentDate);
    this.get_totalVehiclesData()
  }
  vehMarker: any;
  selectVehicle(evnt) {
    if (this.vehMarker) {
      this.vehicleMap.removeLayer(this.vehMarker);
    }
    // console.log(evnt);
    for (let i = 0; i < this.ttl_veh.length; i++) {
      if (this.ttl_veh[i].asrt_id == evnt.asrt_id) {
        // console.log(this.ttl_veh[i].asrt_id == evnt.asrt_id, this.ttl_veh[i].asrt_id, evnt.asrt_id);
        this.ttl_veh[i].apply_actv = 1;
      } else { this.ttl_veh[i].apply_actv = 0; }
    }
    this.vehnm = evnt.asrt_nm;
    this.asrt_sts_nm = evnt.asrt_sts_nm;
    this.ctgrynm = evnt.asrt_ctgry_nm;
    this.grpnm = evnt.asrt_grp_nm;
    this.selectedVehicle = evnt.asrt_id;
    this.getPreviousNotifications(this.selectedVehicle);
    this.getVehiclehourlyDtls(this.selectedVehicle);
    this.get_distGraphsData(this.selectedVehicle);
    this.get_tripsGraphsData(this.selectedVehicle);
    this.getVehInfo(this.selectedVehicle);

    if (evnt.asrtglblId == "1") {
      if (evnt.spd_ct <= '5') {
        var icon = "_p_m.png";
      } else {
        var icon = "_r_m.png";
      }
    } else {
      var icon = "_o_m.png";
    }
    var vehIcon = new L.Icon({
      iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + evnt.icon_path + icon,
      iconSize: [30, null],
      iconAnchor: [10, 5],
      popupAnchor: [5, -20],
      shaowSize: [35, 35]
    });
    this.vehMarker = L.marker([evnt.lat, evnt.lng], {
      icon: vehIcon
    }).addTo(this.vehicleMap);

    var bounds = [];
    bounds.push([evnt.lat, evnt.lng]);
    this.vehicleMap.fitBounds(bounds);
    var coords = L.latLng([evnt.lat, evnt.lng]);
    L.popup().setLatLng(coords).setContent(evnt.asrt_nm + "-" + evnt.asrt_ctgry_nm + " </br> " + evnt.asrt_grp_nm).openOn(this.vehicleMap);

    this.get_allCtgrysData(evnt.asrt_id);
    // this.getVehiclehourlyDtls(evnt.asrt_id);
    // this.selectCategory(0,this.currentDate,evnt.asrt_id);
    // this.defaultSelectedVehicle(evnt);
    // this.get_distGraphsData(evnt.asrt_id);
    // this.get_tripsGraphsData(evnt.asrt_id);
    // this.getVehInfo(evnt.asrt_id);


  }
  defaultSelectedVehicle(evnt) {

    // console.log(evnt);

    if (this.vehMarker) {
      this.vehicleMap.removeLayer(this.vehMarker);
    }
    this.vehnm = evnt.asrt_nm;
    this.asrt_sts_nm = evnt.asrt_sts_nm;
    this.ctgrynm = evnt.asrt_ctgry_nm;
    this.grpnm = evnt.asrt_grp_nm;
    this.selectedVehicle = evnt.asrt_id;
    this.getPreviousNotifications(this.selectedVehicle);
    this.getVehiclehourlyDtls(this.selectedVehicle);
    this.get_distGraphsData(this.selectedVehicle);
    this.get_tripsGraphsData(this.selectedVehicle);
    this.getVehInfo(this.selectedVehicle);

    if (evnt.asrtglblId == "1") {
      if (evnt.spd_ct <= '5') {
        var icon = "_p_m.png";
      } else {
        var icon = "_r_m.png";
      }
    } else {
      var icon = "_o_m.png";
    }
    var vehIcon = new L.Icon({
      iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + evnt.icon_path + icon,
      iconSize: [30, null],
      iconAnchor: [10, 5],
      popupAnchor: [5, -20],
      shaowSize: [35, 35]
    });
    this.vehMarker = L.marker([evnt.lat, evnt.lng], {
      icon: vehIcon
    }).addTo(this.vehicleMap);

    var bounds = [];
    bounds.push([evnt.lat, evnt.lng]);
    this.vehicleMap.fitBounds(bounds);
    var coords = L.latLng([evnt.lat, evnt.lng]);
    L.popup().setLatLng(coords).setContent(evnt.asrt_nm + "-" + evnt.asrt_ctgry_nm + " </br> " + evnt.asrt_grp_nm).openOn(this.vehicleMap);
    // L.popup().setLatLng(coords).setContent(evnt.asrt_nm).openOn(this.vehicleMap);

    // this.selectCategory(0,this.currentDate,evnt.asrt_id);

    for (let i = 0; i < this.ttl_veh.length; i++) {
      if (this.ttl_veh[i].asrt_id == evnt.asrt_id) {
        // console.log(this.ttl_veh[i].asrt_id == evnt.asrt_id, this.ttl_veh[i].asrt_id, evnt.asrt_id);
        this.ttl_veh[i].apply_actv = 1;
      } else { this.ttl_veh[i].apply_actv = 0; }
    }

  }
  totCvrsBns = 0; totBins; CrntDyFuel; CrntDyFlAmt; vehTrpLst;
  get_allCtgrysData(veh_id) {
    //  console.log(veh_id)
    this.vehTrpLst = []; this.oprtdNtoprtd = []; this.ovrSpeed = []; this.hrlyDta = []; this.vstdLctns = []; this.VehLctns = [];
    this.oprtnlhrs = []; this.fuelDta = []; this.vehRts = []; this.eachVeh = [];
    this.showLdr = true;
    // this.apiServ.get(`dashboard/enterprise/vehicleOperated/${veh_id}`).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     var res_data = res['data'];
    //     if (res_data) {
    //       this.oprtdNtoprtd = res['data'];
    //     }
    //   }
    // }, (err) => {
    //   // console.log(err);
    // });
    this.apiServ.get(`dashboard/enterprise/echVehicleOvrSpeed/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.ovrSpeed = res['data'];
          if (this.ovrSpeed.length == 0) {
            this.ovrSpeedMsg = 'Speed Violation not found to this vehicle.';
          } else {
            this.ovrSpeedMsg = '';
          }
        }
        this.showLdr = false;

      }
    }, (err) => {
      // console.log(err);
    });

    // this.apiServ.get(`dashboard/enterprise/distanceCoveredByasrtId/${veh_id}`).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     var res_data = res['data'];
    //     if (res_data) {
    //       this.hrlyDta = res['data'];
    //     }
    //     this.showLdr = false;
    //   }
    // }, (err) => {
    //   // console.log(err);
    // });

    this.apiServ.get(`dashboard/enterprise/echVehicleLocations/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.vstdLctns = res['data'];
          if (this.vstdLctns.length == 0) {
            this.vstdLctnsMsg = 'Visited locations not found to this vehicle.';
          } else {
            this.vstdLctnsMsg = '';
          }
        }
        this.showLdr = false;

      }
    }, (err) => {
      // console.log(err);
    });

    // this.apiServ.get(`dashboard/enterprise/locations/${veh_id}`).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     var res_data = res['data'];
    //     if (res_data) {
    //       this.VehLctns = res['data'];
    //       // console.log(this.VehLctns);
    //       this.totBins = this.VehLctns.length;
    //       for (let i = 0; i < this.VehLctns.length - 1; i++) {
    //           if (this.VehLctns[i].covered_in == 1) {
    //               this.totCvrsBns = this.totCvrsBns + 1;
    //           }
    //       }
    //     }
    //     this.showLdr = false;

    //   }
    // }, (err) => {
    //   // console.log(err);
    // });

    this.apiServ.get(`dashboard/enterprise/operational/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        // if (res_data) {
        this.oprtnlhrs = res['data'];
        // console.log(this.oprtnlhrs);
        if (this.oprtnlhrs.length == 0) {
          this.oprtnlhrsMsg = 'No Operational Details found to this vehicle.';
        } else {
          this.oprtnlhrsMsg = '';
        }
        // }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });

    this.apiServ.get(`dashboard/enterprise/vehicleRoutes/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.vehRts = res['data'];
          console.log(this.vehRts)
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });


    // this.postslctdDt = {
    //   dt: this.currentDate,
    //   asrt_id: veh_id,
    //   tnt: this.selectedtenant,
    //   ctgry: this.selectedCtgry,
    //   vndr: this.selectedvndr,
    //   tnt_in: 1
    // }
    //  console.log('posting', this.postslctdDt);
    // const rte = `dashboard/enterprise/get_SnglevehTrpsLst`;
    // this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     var res_data = res['data'];
    //     if (res_data) {
    //       this.vehTrpLst = res['data'];
    //       console.log(this.vehTrpLst)
    //     //   if(!this.vehicleMap1){
    //     //   this.vehicleMap1 = L.map('vehicleMap', {
    //     //     center: [this.init_lat, this.init_lng],
    //     //     zoom: 3
    //     //   });

    //     //   // const tiles1 = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     //   //   maxZoom: 18,
    //     //   //   minZoom: 3,
    //     //   //   attribution: ''
    //     //   // });
    //     //   const tiles1 = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
    //     //     maxZoom: 18,
    //     //     minZoom: 3,
    //     //     subdomains:['mt0','mt1','mt2','mt3'],
    //     //     attribution: ''            });
    //     //   tiles1.addTo(this.vehicleMap1);
    //     //   this.vehicleMap1.setView([this.clntDtls[0].lat, this.clntDtls[0].lng], 13)
    //     // }
    //       if(this.vehTrpLst.length == 0){
    //         this.vehTrpLstMsg = 'Trips not found to this vehicle.';
    //       }else{
    //         this.vehTrpLstMsg = '';
    //       }
    //     }

    //     this.showLdr = false;
    //   }
    // }, (err) => {
    //   // console.log(err);
    // });

    this.getPreviousNotifications(veh_id);
    this.getVehiclehourlyDtls(veh_id);
    this.get_distGraphsData(veh_id);
    this.get_tripsGraphsData(veh_id);
    this.getVehInfo(veh_id);

    // // get each vehicle details by asrt_id
    // this.apiServ.get(`dashboard/enterprise/getVehicleDetailsById/${veh_id}`).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     var res_data = res['data'];
    //     if (res_data) {
    //       this.vehCtgrys = res['data'];
    //       // console.log(this.vehCtgrys);
    //       this.selectedCtgry = 0;
    //     }
    //     this.showLdr = false;
    //   }
    // }, (err) => {
    //   // console.log(err);
    // });
  }

  onChange(e): void {
    this.selectedCtgry = 0;
    this.slctdDt = e;
    this.currentDate = moment(this.slctdDt).format('YYYY-MM-DD');
    // this.selectCategory(0,this.currentDate);
    this.get_vehCategories();
  }
  get_vehCategories(): any {
    this.showLdr = true;
    this.apiServ.get(`dashboard/enterprise/get_vehCtgrys`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.vehCtgrys = res['data'];
          this.selectedCtgry = 0;
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  selectCategory(evnt,dt?,vehID?){
    console.log('selectCategory', evnt, this.selectedVehicle)
    this.showLdr = true;
    this.vehTrpLst = []
    this.currentDate = moment(dt).format('YYYY-MM-DD');
   //  console.log(this.currentDate);
    this.selectedCtgry = evnt;
    this.get_totalVehiclesData()
    // this.selectedVehicle = vehID;
    // this.postslctdDt = {
    //   dt: this.currentDate,
    //   asrt_id: this.selectedVehicle,
    //   tnt: this.selectedtenant,
    //   ctgry: this.selectedCtgry,
    //   vndr: this.selectedvndr,
    //   tnt_in: 1
    // }
    // console.log('posting Dataaaaaaaaaaaaaaaaaaaaaaaaaaa', this.postslctdDt);

    // const rte = `dashboard/enterprise/get_SnglevehTrpsLst`;
    // this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     var res_data = res['data'];
    //     if (res_data) {
    //       this.vehTrpLst = res['data'];
    //         console.log(this.vehTrpLst)
    //         if(this.vehTrpLst.length == 0){
    //           this.vehTrpLstMsg = 'Trips not found to this vehicle.';
    //         }else{
    //           this.vehTrpLstMsg = '';
    //         }
    //     }
    //     this.showLdr = false;
    //   }
    // }, (err) => {
    //   // console.log(err);
    // });
  }

  startPBMarker: any;
  endPBMarker: any;
  startPBMarker1: any;
  endPBMarker1: any;
  polylinePoints: any;
  actl_Polyline: any;
  route_Polyline: any = [];
  getTripMap(c, dt) {
    this.showLdr = true;
    this.polylinePoints = JSON.parse(c.rte_arc_lne_tx);

    if (this.route_Polyline) {
      this.vehicleMap1.removeLayer(this.route_Polyline);
    }
    if (this.startPBMarker) {
      this.vehicleMap1.removeLayer(this.startPBMarker);
    }

    if (this.endPBMarker) {
      this.vehicleMap1.removeLayer(this.endPBMarker);
    }

    if (this.actl_Polyline) {
      this.vehicleMap1.removeLayer(this.actl_Polyline);

    }
    if (this.startPBMarker1) {
      this.vehicleMap1.removeLayer(this.startPBMarker1);
    }

    if (this.endPBMarker1) {
      this.vehicleMap1.removeLayer(this.endPBMarker1);
    }
    // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
    var startIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/startPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    this.startPBMarker = L.marker(this.polylinePoints[0], {
      icon: startIcon
    }).addTo(this.vehicleMap1);
    // ----------Fence END Area (flag position) -- Leaflet OS ----------//
    var endIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/endPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    var k = this.polylinePoints.length - 1
    this.endPBMarker = L.marker(this.polylinePoints[k], {
      icon: endIcon
    }).addTo(this.vehicleMap1);
    if (c) {
      this.route_Polyline = L.polyline(JSON.parse(c.rte_arc_lne_tx), {
        color: '#008000',
        weight: 5,
        lineCap: 'butt',
        opacity: 0.6,
        smoothFactor: 10.0,
        lineJoin: 'round'
      }).addTo(this.vehicleMap1);
    }
    this.vehicleMap1.fitBounds(this.route_Polyline.getBounds());
    // console.log(c,dt);
    this.currentDate = moment(dt).format('YYYY-MM-DD');
    this.postslctdDt = {
      dt: this.currentDate,
      data: c
    }
    const rte = `dashboard/enterprise/get_tripTrvlRoute`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {

      if (res['status'] == 200) {
        this.showLdr = false;
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {

          this.rteDta = res['data'];
          console.log(this.rteDta)
          var restr = '';
          var tmp;
          for (var i = 0; i < this.rteDta.length; i++) {
            restr += (restr == "") ? "[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]" : ",[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]";
          }
          // console.log("[" + restr + "]");

          // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
          var startIcon1 = new L.Icon({
            iconUrl: '/assets/images/markers/playback/startPoint.png',
            iconSize: [42, 45],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
          this.startPBMarker1 = L.marker([this.rteDta[0].lat, this.rteDta[0].lng], {
            icon: startIcon1
          }).addTo(this.vehicleMap1);
          // ----------Fence END Area (flag position) -- Leaflet OS ----------//
          var endIcon1 = new L.Icon({
            iconUrl: '/assets/images/markers/playback/endPoint.png',
            iconSize: [42, 45],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
          var k1 = this.rteDta.length - 1
          this.endPBMarker1 = L.marker(this.rteDta[k], {
            icon: endIcon1
          }).addTo(this.vehicleMap1);


          this.actl_Polyline = L.polyline(JSON.parse("[" + restr + "]"), {
            color: 'red',
            weight: 4,
            lineCap: 'butt',
            opacity: 0.5,
            smoothFactor: 10.0,
            lineJoin: 'round'
          }).addTo(this.vehicleMap1);
        }
      }
    }, (err) => {
      // console.log(err);
    });

  }




  get_totalVehiclesData() {
    this.showLdr = true;
    this.ttl_veh = []
    this.postslctdDt = {
      dt: this.currentDate,
      selectedtenant: this.selectedtenant,
      selectedctgry: this.selectedCtgry,
      selectedvndr: this.selectedvndr,
      tnt_in: 1
    }
    console.log('posting Total Vehicles', this.postslctdDt);
    const rte = `dashboard/enterprise/total_vehicles`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data) {
          this.ttl_veh = res['data'];
          console.log(this.ttl_veh);
          for (let i = 0; i < this.ttl_veh.length; i++) {
            this.ttl_veh[i]['apply_actv'] = 0;
            // if(this.ttl_veh[i].asrt_id == evnt.asrt_id){
            //   console.log(this.ttl_veh[i].asrt_id == evnt.asrt_id,this.ttl_veh[i].asrt_id,evnt.asrt_id);
            //   this.apply_actv = 1;
            // } else {this.apply_actv = 0;}
          }
          console.log(this.ttl_veh);
          this.showLdr = false;
          // console.log(this.params.length)
          if (this.params.asrt_id != undefined) {
            this.selectedVehicle = this.params.asrt_id;
          } else {
            if (this.ttl_veh.length == 0) {
              this.ttl_vehMsg = 'No vehicles found in the selected filters.';
              this.selectedVehicle =0
            } else {
              this.selectedVehicle = this.ttl_veh[0].asrt_id;
              this.ttl_vehMsg = '';
            }
          }
          console.log(this.selectedVehicle);
          
          this.get_allCtgrysData(this.selectedVehicle);
          this.get_distGraphsData(this.selectedVehicle);
          this.get_tripsGraphsData(this.selectedVehicle);
          
          // this.selectCategory(0,this.currentDate,this.selectedVehicle);
        }
      }
    }, (err) => {
      console.log(err);
    });

  }

  getPreviousNotifications(veh_id) {
    this.showLdr = true;
    this.previousNotifications = [];
    const rte = `dashboard/enterprise/last_10VehNtfcntsLst/${veh_id}`;
    this.apiServ.get(rte).subscribe((res) => {
      this.showLdr = false;
      if (res['status'] == 200) {
        let data = res['data'];
        this.previousNotifications = data;
        console.log(this.previousNotifications);
      }
      if (this.previousNotifications == 0) {
        this.previousNotificationsMsg = 'Notifications not found to this vehicle.';
      } else {
        this.previousNotificationsMsg = '';
      }
    }, (error) => {
      this.showLdr = false;
    })
  }

  getVehiclehourlyDtls(veh_id): any {
    console.log('in getVehiclehourlyDtls');
    this.vehHrlyDtls = [];
    this.showLdr = true;
    console.log(veh_id);
    this.postData = {
      selectedVehicle: veh_id
    }
    const rte = `drivers/vehicle/hourly_distance`;
    let chart = am4core.create("hepachartdiv", am4charts.XYChart);
    chart.data = [];
    console.log(this.postData);
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.vehHrlyDtls = res['data'];
      console.log(this.vehHrlyDtls);
      this.vehHrlyDtls.forEach(obj => {
        if (obj.dst == null) {
          obj.dst = 0;
        }
        else {
          obj.dst = obj.dst
        }

      })
      chart.data = this.vehHrlyDtls;


      chart.maskBullets = true;

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      xAxis.dataFields.category = "trvl_hr";
      yAxis.dataFields.category = "trvl_dt";

      xAxis.title.text = "------Hour-------";
      yAxis.title.text = "------Date-------";

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 10;
      yAxis.renderer.fontSize = 11;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "trvl_hr";
      series.dataFields.categoryY = "trvl_dt";
      series.dataFields.value = "dst";
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      var bgColor = new am4core.InterfaceColorSet().getFor("background");

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 0.2;
      // columnTemplate.strokeWidth = 2;
      // columnTemplate.strokeOpacity = 1;
      columnTemplate.stroke = am4core.color("eff567");
      columnTemplate.tooltipText = "{dst}";
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        min: am4core.color(bgColor),
        max: chart.colors.getIndex(0)
      });
      var legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.data = [{
        "name": "Distance Covered",
        "fill": am4core.color("#bec4f8")
      }];

      // series.heatRules.push({
      // 	target:columnTemplate, 
      // 	property:"fill", 
      // 	min:am4core.color("#f5f6fe"), 
      // 	max:am4core.color("#554abd"),
      // 	minValue: 2000,
      // 	maxValue: 5000
      //   });


      // heat legend
      var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
      heatLegend.width = am4core.percent(100);
      heatLegend.series = series;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
      heatLegend.valueAxis.renderer.minGridDistance = 30;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{dst}";
      valueLabel.label.fontSize = 15;

      // columnTemplate1.width = 60;
      columnTemplate.column.cornerRadiusTopLeft = 3;
      columnTemplate.column.cornerRadiusTopRight = 3;
      columnTemplate.column.cornerRadiusBottomLeft = 3;
      columnTemplate.column.cornerRadiusBottomRight = 3;

      // heat legend behavior
      series.columns.template.events.on("over", function (event) {
        handleHover(event.target);
      })

      series.columns.template.events.on("hit", function (event) {
        handleHover(event.target);
      })

      function handleHover(column) {
        if (!isNaN(column.dataItem.value)) {
          heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
        }
        else {
          heatLegend.valueAxis.hideTooltip();
        }
      }

      series.columns.template.events.on("out", function (event) {
        heatLegend.valueAxis.hideTooltip();
      })

      if (this.vehHrlyDtls.length == 0) {
        this.vehHrlyMsg = 'Hourly Distance not found to this driver.';
      } else {
        this.vehHrlyMsg = '';
      }
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }
  get_distGraphsData(veh_id) {
    this.showLdr = true;


    // this.eachVeh = [];
    this.apiServ.get(`dashboard/enterprise/vehicleTargets/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        // var res_data = res['data'].results;
        if (res['data'].results) {
          let chart1 = am4core.create("lastSevenDaysDistanceChart", am4charts.XYChart);
          chart1.data = [];
          chart1.data = res['data'].results;
          this.showLdr = false;
          // Create axes
          let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis1.dataFields.category = "dt";
          categoryAxis1.renderer.grid.template.location = 0;
          categoryAxis1.renderer.minGridDistance = 30;
          categoryAxis1.title.text = ` ----- Date(${this.curYr}) ----- `;
          let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
          valueAxis1.title.text = " ----- Distance Covered -----";
          valueAxis1.cursorTooltipEnabled = false;
          // let series1 = chart1.series.push(new am4charts.ColumnSeries());
          // series1.dataFields.valueY = "mn_dst_ct";
          // series1.dataFields.categoryX = "dt";
          // series1.clustered = false;
          // series1.tooltipText = "Total Distance in {categoryX}: [bold]{valueY}[/]";
          // series1.columns.template.fill = am4core.color("#a9dcf4");
          // series1.columns.template.stroke = am4core.color("#a9dcf4");

          let series2 = chart1.series.push(new am4charts.ColumnSeries());
          series2.dataFields.valueY = "trp_dst";
          series2.dataFields.categoryX = "dt";
          series2.clustered = false;
          series2.columns.template.width = am4core.percent(50);
          series2.tooltipText = "Covered Distance in {categoryX}: [bold]{valueY}[/]";
          series2.columns.template.fill = am4core.color("#ffc107");
          series2.columns.template.stroke = am4core.color("#ffc107");
          let valueLabel = series2.bullets.push(new am4charts.LabelBullet());
          valueLabel.label.text = "{trp_dst}";
          valueLabel.label.fontSize = 15;
          let columnTemplate1 = series2.columns.template;
          columnTemplate1.strokeWidth = 1;
          chart1.cursor = new am4charts.XYCursor();
          chart1.cursor.lineX.disabled = true;
          chart1.cursor.lineY.disabled = true;

          //   for (let j = 0; j <= chart1.data.length - 1; j++) {
          //     if (chart1.data[j].dt == this.currentFrmtDate) {
          //         this.CrntDyDst = chart1.data[chart1.data.length - 1].trp_dst;
          //     }
          // }
          this.showLdr = false;
          if (chart1.data.length == 0) {
            this.chart1Msg = 'Last 7Days Distance not found to this vehicle.';
          } else {
            this.chart1Msg = '';
          }
        }


      }
    }, (err) => {
      // console.log(err);
    });

  }
  get_tripsGraphsData(veh_id) {
    this.showLdr = true;
    this.eachVeh = [];
    this.apiServ.get(`dashboard/enterprise/vehicleTargets/${veh_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        // var res_data = res['data'].results;

        if (res['data'].results) {
          let chart2 = am4core.create("lastSevenDaysChart", am4charts.XYChart);
          chart2.data = [];
          chart2.data = res['data'].results;
          this.showLdr = false;
          this.eachVeh = chart2.data;
          if (chart2.data.length == 0) {
            this.chart2Msg = 'Last 7Days Trips not found to this vehicle.';
          } else {
            this.chart2Msg = '';
          }
          let categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = "dt";
          categoryAxis.renderer.grid.template.location = 0;
          categoryAxis.renderer.minGridDistance = 30;
          categoryAxis.title.text = ` ----- Date(${this.curYr}) ----- `;
          let valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
          valueAxis.title.text = " ----- Trip Count -----";
          valueAxis.cursorTooltipEnabled = false;
          let series3 = chart2.series.push(new am4charts.ColumnSeries());
          series3.dataFields.valueY = "mn_trp_ct";
          series3.dataFields.categoryX = "dt";
          series3.clustered = false;
          series3.tooltipText = "Targeted Trips in {categoryX}: [bold]{valueY}[/]";
          series3.columns.template.fill = am4core.color("#55ac73");
          series3.columns.template.stroke = am4core.color("#55ac73");


          let series4 = chart2.series.push(new am4charts.ColumnSeries());
          series4.dataFields.valueY = "trp_cnt";
          series4.dataFields.categoryX = "dt";
          series4.clustered = false;
          series4.columns.template.width = am4core.percent(50);
          series4.tooltipText = "Covered Trips in {categoryX}: [bold]{valueY}[/]";
          series4.columns.template.fill = am4core.color("#80cf9b");
          series4.columns.template.stroke = am4core.color("#80cf9b");

          let valueLabel = series4.bullets.push(new am4charts.LabelBullet());
          valueLabel.label.text = "{trp_cnt}";
          valueLabel.label.fontSize = 15;
          let columnTemplate1 = series4.columns.template;
          columnTemplate1.strokeWidth = 1;

          chart2.cursor = new am4charts.XYCursor();
          chart2.cursor.lineX.disabled = true;
          chart2.cursor.lineY.disabled = true;
          this.showLdr = false;
          // for (let i = 0; i < this.eachVeh.length - 1; i++) {
          //   if (this.eachVeh[this.eachVeh.length - 1].asrt_id == veh_id) {
          // this.vehnm = this.eachVeh[this.eachVeh.length - 1].asrt_nm;
          // this.asrt_sts_nm = this.eachVeh[this.eachVeh.length - 1].asrt_sts_nm;
          // this.ctgrynm = this.eachVeh[this.eachVeh.length - 1].asrt_ctgry_nm;
          // this.grpnm = this.eachVeh[this.eachVeh.length - 1].asrt_grp_nm;
          // this.matchVehData = this.eachVeh[this.eachVeh.length - 1];
          // this.vehOdmtr = this.eachVeh[this.eachVeh.length - 1].veh_odmtr_ct;
          // this.devOdmtr = this.eachVeh[this.eachVeh.length - 1].dvce_odmtr_ct;
          // this.data_lst_rcvd = this.eachVeh[this.eachVeh.length - 1].data_received_ts;
          // this.speed = this.eachVeh[this.eachVeh.length - 1].speed_ct;
          // this.CrntDyDst = this.eachVeh[this.eachVeh.length - 1].trp_dst;
          // this.dst_tvl_tdy = this.eachVeh[this.eachVeh.length - 1].tdy_dst
          // }
          // }
          // console.log(this.asrt_sts_nm)
          // console.log('this.matchVehData', this.matchVehData);
          // this.defaultSelectedVehicle(this.matchVehData);
        }
        // if (res_data. == undefined) {
        //   this.errMsg = `Last 7Days Trips not found to this vehicle.`
        // }
        // else {
        //   this.errMsg = ``
        // }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  getVehInfo(asrt_id) {
    this.VhcleDetails = [];
    this.apiServ.get(`drivers/vehicles/vehicleById/${asrt_id}`).subscribe((res) => {
      if (res['status'] == 200) {
        // var res_data = res['data'];
        // if (res_data) {
        this.VhcleDetails = res['data'][0];
        this.vehnm = this.VhcleDetails.asrt_nm;
        this.asrt_sts_nm = this.VhcleDetails.asrt_sts_nm;
        this.ctgrynm = this.VhcleDetails.asrt_ctgry_nm;
        this.grpnm = this.VhcleDetails.asrt_grp_nm;
        this.matchVehData = this.VhcleDetails;
        this.vehOdmtr = this.VhcleDetails.veh_odmtr_ct;
        this.devOdmtr = this.VhcleDetails.dvce_odmtr_ct;
        this.data_lst_rcvd = this.VhcleDetails.data_received_ts;
        this.speed = this.VhcleDetails.speed_ct;
        this.CrntDyDst = this.VhcleDetails.trp_dst;
        this.dst_tvl_tdy = this.VhcleDetails.tdy_dst

      }
    });
  }
}
