import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-gates',
  templateUrl: './gates.html',
  styleUrls: ['./gates.scss']
})
export class GatesComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  gteLst: any;
  columnDefs;
  shwSidebar = false;
  gteDt: any = {};
  shwGteAlrt = false;
  shwGteEditDiv = false;
  gteId: any;
  sidebarHeading: string;
  shwGteDelDiv: boolean;
  shwLdg: boolean;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.getGtesLst();
  }

  getGtesLst() {
    this.shwLdg = true;
    const rte = 'admin/gates/list';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      this.shwLdg = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.gteLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 80, filter: false },
            { headerName: 'Gate Name', field: 'gte_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Gate category', field: 'gte_ctgry_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'House Number', field: 'gte_hse_nu', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            // { headerName: 'Door Number', field: 'gte_dr_nu', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Total Households', field: 'hse_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'Total Population', field: 'pltn_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150 },
            { headerName: 'ULB', field: 'ulb_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Micropocket', field: 'mcrpt_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Street', field: 'strt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 250 },
            { headerName: 'Lattitude', field: 'lat', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Longitude', field: 'lng', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
          ];
          this.getMicropockets();
          this.getUlbs();
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    }, (err) => {
      this.shwLdg = false;
    });
  }

  micropockets: any = [];
  getMicropockets() {
    this.apiSrv.get('admin/micropockets/list').subscribe((res) => {
      this.micropockets = res['data'];
    })
  }
  ulbs: any = [];
  getUlbs() {
    const rte = 'admin/ulbs/list';
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] === 200) {
        this.ulbs = res['data'];
      }
    });
  }
  getULBID(id) {
    this.gteDt.ulb_id = id;
    // this.filteredMcrptOptions = this.micropockets.filter(option => option.ulb_id == id);

  }
  filteredOptions: any = [];
  onulbChange(value): void {
    this.filteredOptions = this.ulbs.filter(option => option.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getMcrptID(id) {
    this.gteDt.mcrpt_id = id;
  }

  filteredMcrptOptions: any = [];
  onmcrptChange(value): void {
    this.filteredMcrptOptions = this.micropockets.filter(option => option.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  onCellClick(opt, edtData) {
    this.gteDt = edtData.data;
    if (opt === 'edit') {
      this.shwGteEditDiv = true;
    }
    if (opt === 'delete') {
      this.shwGteEditDiv = false;
      this.shwGteDelDiv = true;
    }
    this.openSideBar(opt);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Gate',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwGteEditDiv = true;
      this.shwGteDelDiv = false;
      this.sidebarHeading = 'Edit Gate';
    } else if (opt === 'delete') {
      this.shwGteEditDiv = false;
      this.shwGteDelDiv = true;
      this.sidebarHeading = 'Delete Gate';
    } else {
      this.shwGteEditDiv = false;
      this.shwGteDelDiv = false;
      this.gteDt.gte_nm = '';
      this.sidebarHeading = 'Add New Gate';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
    this.gteDt = {};
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  saveGte() {
    const gteData = {
      name: this.gteDt.gte_nm,
      ulb_id: this.gteDt.ulb_id
    };

    const rte = 'gates';
    this.apiSrv.create(gteData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwGteAlrt = true;
        this.createMessage('success', 'Gate Successfully Added');
        this.getGtesLst();
      }
    });
  }

  saveEditGte() {
    const gteEdtData = this.gteDt;
    //   console.log(this.gteDt);
    const rte = 'admin/gates';
    this.apiSrv.update(gteEdtData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwGteAlrt = true;
        this.createMessage('info', 'Gate Successfully Updated');
        this.getGtesLst();
      }
    });
  }

  saveDeleteGte() {
    // console.log('in del');
    const gteDelData = {
      id: this.gteId,
      ulb_id: this.gteDt.ulb_id
    };

    const rte = 'admin/gates/'+this.gteId;
    this.apiSrv.delete(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwGteAlrt = true;
        this.createMessage('warning', 'Gate Successfully Deleted');
        this.getGtesLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('Gate Not Deleted');
  }
}
