import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-gateTypes',
  templateUrl: './gateTypes.html',
  styleUrls: ['./gateTypes.scss']
})
export class GateTypesComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  gteTypeLst: any;
  columnDefs;
  shwSidebar = false;
  gteTypeDt: any = {};
  gteTypeNm: string | null = null;
  shwGteTypAlrt = false;
  shwGteTypEditDiv = false;
  gteTypeId: any;
  sidebarHeading: string;
  shwGteTypDelDiv: boolean;
  shwLdg: boolean;
  shwEditDiv: boolean;
  shwDelDiv: boolean;
  shwstsAlrt: boolean;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getGteTypsLst();
  }

  getGteTypsLst() {
    this.shwLdg = true;
    const rte = 'admin/gate/types';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      this.shwLdg = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.gteTypeLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'Gate Type Name', field: 'gte_ctgry_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 200 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
          ];
          this.getDstrcts();
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    }, (err) => {
      this.shwLdg = false;
    });
  }
  districts: any = [];
  getDstrcts() {
    this.apiSrv.get('/admin/districts').subscribe((res) => {
      this.districts = res['data'];
    })
  }
  filteredOptions: any = [];
  onChange(value): void {
    this.filteredOptions = this.districts.filter(option => option.dstrt_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getDstrtID(id) {
    this.gteTypeDt.dstrt_id = id;
  }

  onCellClick(opt, edtData) {
    if (opt === 'edit') {
      this.shwEditDiv = true;
      this.gteTypeNm = edtData.data.gte_ctgry_nm;
      this.gteTypeId = edtData.data.gte_ctgry_id;
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwEditDiv = false;
      this.shwDelDiv = true;
      this.gteTypeNm = edtData.data.gte_ctgry_nm;
      this.gteTypeId = edtData.data.gte_ctgry_id;
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New  Gate Type',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwEditDiv = true;
      this.shwDelDiv = false;
      this.sidebarHeading = 'Edit Gate Type';
    } else if (opt === 'delete') {
      this.shwEditDiv = false;
      this.shwDelDiv = true;
      this.sidebarHeading = 'Delete Gate Type';
    } else {
      this.shwEditDiv = false;
      this.shwDelDiv = false;
      this.gteTypeNm = '';
      this.sidebarHeading = 'Add New Gate Type';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  saveNew() {
    const hsetypData = {
      name: this.gteTypeNm
    };

    const rte = 'admin/gate/types';
    this.apiSrv.create(hsetypData, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('success', ' Gate Type Successfully Added');
        this.getGteTypsLst();
      }
    });
  }

  saveEdit() {
    const hseEdtData = {
      id: this.gteTypeId,
      name: this.gteTypeNm
    };

    const rte = 'admin/gate/types/'+this.gteTypeId;
    this.apiSrv.update(hseEdtData, rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('info', ' Gate Type Successfully Updated');
        this.getGteTypsLst();
      }
    });
  }

  saveDelete() {
    const hseDelData = {
      id: this.gteTypeId
    };

    const rte = 'admin/gate/types/'+this.gteTypeId;
    this.apiSrv.delete( rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('warning', ' Gate Type Successfully Deleted');
        this.getGteTypsLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info(' Gate Type Not Deleted');
  }
}
