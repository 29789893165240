<ds-loading *ngIf="showLdr"></ds-loading>
<div class="row bg-strip">
    <div class="col-lg-4 col-md-4 mg-a" *ngIf="dbrd_lvl==0">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">State Dashboard (CLAP)</h1>
    </div>
    <div class="col-lg-6 col-md-6 mg-a" *ngIf="dbrd_lvl==1">
        <h1 *ngIf="rowselected && rowselected.tnt_id && rowselected.urban_in==1"> ULB Dashboard<span
                style="font-size:16px;color:teal">( {{rowselected.tnt_dsply_nm}} )</span></h1>
        <h1 *ngIf="rowselected && rowselected.tnt_id && rowselected.rural_in==1"> Mandal Dashboard<span
                style="font-size:16px;color:teal">( {{rowselected.tnt_dsply_nm}} )</span></h1>
    </div>
    <div class="col-lg-6 col-md-6 mg-a" *ngIf="dbrd_lvl==2">
        <h1> Sachivalayam Dashboard<span style="font-size:16px;color:teal">( {{rowselected2.svm_nm}} )</span></h1>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;" *ngIf="dbrd_lvl==0">
        <div class="Lst_rfrshd">Last Refreshed on: <span style="color: #3c54be;">{{refresh_tm}} </span>
            <button (click)="refresh()" nz-button nzType="default" style="    box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
            color: #ffffff;
            font-weight: 500;
            background: #03A9F4;
            float: right;
            border-radius: 4px;margin-top: -4px;"><span nz-icon nzType="reload" nzTheme="outline" style="color:#fff"></span><span
                    style="color:#fff"> Refresh</span></button>
        </div>
    </div>
    <div class="col-lg-2 col-md-2 mg-a" style="text-align: right;" *ngIf="dbrd_lvl==1 ||dbrd_lvl==2 ">
        <button nz-button nzType="default" (click)="goBack()" style="    box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
        color: #ffffff;
        font-weight: 500;
        background: #727bff;
        float: right;
        border-radius: 4px;"><span style="color:#fff"><i nz-icon nzType="left"></i></span> <span style="color:#fff">Go
                back</span></button>
    </div>
</div>
<div class="main" *ngIf="dbrd_lvl<2">
    <div class="row" *ngIf="dbrd_lvl==0 || (rowselected && rowselected.urban_in==1) || dbrd_lvl==2">
        <h1 class="lblHdr">Urban</h1>
        <ul class="cards">
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);"
                    (click)="cardOne()" [ngClass]="crdOne == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/pick-up-truck.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                                <div class="cvrg_crd_cnt">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">{{d.urban_ttl === null ? "0" : d.urban_ttl}}
                                        </div>
                                        <div class="col-lg-6 col-md-6">{{d.u_ttl_onl === null ? "0" : d.u_ttl_onl}} /
                                            {{d.u_ttl_ofl === null ? "0" : d.u_ttl_ofl}}</div>

                                    </div>
                                </div>
                                <div class="cvrg_crd_lbl">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">Vehicles</div>
                                        <div class="col-lg-6 col-md-6">Online/Offline</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="    background: linear-gradient(135deg, #6b3a0b 0%,#ab5d4a 100%);"
                    (click)="cardTwo()" [ngClass]="crdTwo == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/operated.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #8e4d2d;"></div>
                                <div class="cvrg_crd_cnt">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">{{d.u_operated === null ? "0" : d.u_operated}}
                                        </div>
                                        <div class="col-lg-6 col-md-6">{{d.u_notoperated === null ? "0" :
                                            d.u_notoperated}} <span style="font-size: 12px;"> ({{d.u_opper === null ?
                                                "0" : d.u_opper}}%)</span></div>
                                    </div>
                                </div>
                                <div class="cvrg_crd_lbl">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">Operated</div>
                                        <div class="col-lg-6 col-md-6">Not Operated</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </li>

            <li class="cards_item" *ngFor="let d of ttlDstncCnt">
                <div class="card_g" style="background: linear-gradient(135deg, #665f03 0%,#817e26 100%);"
                    (click)="cardThree()" [ngClass]="crdThree == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">


                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/routes.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #6c660b;"></div>
                                <div class="cvrg_crd_cnt">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">{{d.urbn_tdydst_abv30 === null ? "0" :
                                            d.urbn_tdydst_abv30}}</div>
                                        <div class="col-lg-6 col-md-6">{{d.urbn_tdydst_blw30 === null ? "0" :
                                            d.urbn_tdydst_blw30}}</div>
                                    </div>
                                </div>
                                <div class="cvrg_crd_lbl">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">Travelled > 30 Km</div>
                                        <div class="col-lg-6 col-md-6">Travelled < 30 Km</div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
            </li>


            <li class="cards_item" *ngFor="let d of ttlTrpsCnt">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);"
                    (click)="cardFour()" [ngClass]="crdFour == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/rout.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #28816b;"></div>
                                <div class="cvrg_crd_cnt">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">{{d.tdy_trps === null ? "0" : d.tdy_trps}}</div>
                                        <div class="col-lg-6 col-md-6">{{d.tdy_trvl_trps === null ? "0" :
                                            d.tdy_trvl_trps}}
                                            <span style="font-size: 12px;"> ({{d.tdy_per === null ? 0 : d.tdy_per}} %)
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="cvrg_crd_lbl">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">Total Trips</div>
                                        <div class="col-lg-6 col-md-6">Travelled Trips</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
    <div class="row" *ngIf="dbrd_lvl==0 || rowselected.rural_in==1">
        <h1 class="lblHdr">Grameen</h1>

        <ul class="cards">
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);"
                    (click)="cardFive()" [ngClass]="crdFive == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/pick-up-truck.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                                <div class="cvrg_crd_cnt">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">{{d.rural_ttl === null ? "0" : d.rural_ttl}}
                                        </div>
                                        <div class="col-lg-6 col-md-6">{{d.r_ttl_onl === null ? "0" : d.r_ttl_onl}} /
                                            {{d.r_ttl_ofl === null ? "0" : d.r_ttl_ofl}}</div>
                                    </div>
                                </div>
                                <div class="cvrg_crd_lbl">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">Vehicles</div>
                                        <div class="col-lg-6 col-md-6">Online/Offline</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="    background: linear-gradient(135deg, #6b3a0b 0%,#ab5d4a 100%);"
                    (click)="cardSix()" [ngClass]="crdSix == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/operated.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #8e4d2d;"></div>
                                <div class="cvrg_crd_cnt">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">{{d.r_operated === null ? "0" : d.r_operated}}
                                        </div>
                                        <div class="col-lg-6 col-md-6">{{d.r_notoperated === null ? "0" :
                                            d.r_notoperated}}<span style="font-size: 12px;"> ({{d.r_opper ===
                                                null ? "0" : d.r_opper}} %)</span></div>
                                    </div>
                                </div>
                                <div class="cvrg_crd_lbl">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">Operated</div>
                                        <div class="col-lg-6 col-md-6">Not Operated</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </li>

            <li class="cards_item" *ngFor="let d of ttlDstncCnt">
                <div class="card_g" style="   background: linear-gradient(135deg, #665f03 0%,#817e26 100%);"
                    (click)="cardSeven()" [ngClass]="crdSeven == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">


                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/routes.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #6c660b;"></div>
                                <div class="cvrg_crd_cnt">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">{{d.rrl_tdydst_abv30 === null ? "0" :
                                            d.rrl_tdydst_abv30}}</div>
                                        <div class="col-lg-6 col-md-6">{{d.rrl_tdydst_blw30 === null ? "0" :
                                            d.rrl_tdydst_blw30}}</div>
                                    </div>
                                </div>
                                <div class="cvrg_crd_lbl">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">Travelled > 30 Km</div>
                                        <div class="col-lg-6 col-md-6">Travelled < 30 Km</div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
            </li>


            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);"
                    (click)="cardEight()" [ngClass]="crdEight == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/rout.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #28816b;"></div>
                                <div class="cvrg_crd_cnt"> {{d.r_dst === null ? 0 : d.r_dst}}</div>
                                <div class="cvrg_crd_lbl">Distance covered (Km)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>
<div class="main" *ngIf="dbrd_lvl==2">
    <div class="row">
        <ul class="cards">
            <li class="cards_item" *ngFor="let d of svmtdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/pick-up-truck.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                                <div class="cvrg_crd_cnt">{{d.TOT_VEHICLES === null ? "0" : d.TOT_VEHICLES}}</div>
                                <div class="cvrg_crd_lbl">Total Vehicles</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of svmtdyCoveredTrips">
                <div class="card_g" style="    background: linear-gradient(135deg, #6b3a0b 0%,#ab5d4a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/routes.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #8e4d2d;"></div>
                                <!-- <div class="cvrg_crd_cnt">{{d.OPERATED === null ? "0" : d.OPERATED}} / {{d.TOTAL_TRIPS
                                    === null ? "0" : d.TOTAL_TRIPS}}<span style="font-size: 12px;"> ({{d.op_per}}
                                        %)</span></div>
                                <div class="cvrg_crd_lbl">Operated Trips/Total Trips</div> -->
                                <div class="cvrg_crd_cnt">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">{{d.OPERATED === null ? "0" : d.OPERATED}}
                                        </div>
                                        <div class="col-lg-6 col-md-6">{{d.TOTAL_TRIPS === null ? "0" :
                                            d.TOTAL_TRIPS}}<span style="font-size: 12px;"> ({{d.op_per ===
                                                null ? "0" : d.op_per}} %)</span></div>
                                    </div>
                                </div>
                                <div class="cvrg_crd_lbl">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">Operated Trips</div>
                                        <div class="col-lg-6 col-md-6">Total Trips</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </li>

            <li class="cards_item" *ngFor="let d of svmtdyCoveredTrips">
                <div class="card_g" style="   background: linear-gradient(135deg, #665f03 0%,#817e26 100%);">
                    <div class="card_content">


                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/rout.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #6c660b;"></div>
                                <div class="cvrg_crd_cnt">{{d.ROUTE_DEVIATED === null ? "0" : d.ROUTE_DEVIATED}} </div>
                                <div class="cvrg_crd_lbl">Route Deviated</div>
                            </div>

                        </div>


                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of svmtdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #477085 0%,#34587a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/bus.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                                <div class="cvrg_crd_cnt">{{d.dist === null ? "0" : d.dist}} Km</div>
                                <div class="cvrg_crd_lbl">Total Distance</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>
<div class="dxgridpdng cvrgrdsbrd" style="padding-top:0px" *ngIf="dbrd_lvl==0">
    <div class="row">
        <div class="col-lg-1 col-md-1">
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="fltrMd" (ngModelChange)="onfltrSelect($event)"
                style="width:100%;" [ngModelOptions]="{standalone: true}">
                <!-- <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option> -->
                <nz-option [nzLabel]="'Urban'" [nzValue]="1"></nz-option>
                <nz-option [nzLabel]="'Rural'" [nzValue]="2"></nz-option>
            </nz-select>
        </div>
        <div class="col-lg-11 col-md-11">
            <h1 class="bdycntnthdr">{{tblHdr}}</h1>
        </div>
    </div>

    <dx-data-grid id="gridContainer" class="p-8" [dataSource]="ulbTripsDta" [remoteOperations]="false"
        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
        (onCellClick)="devCellclk(columnDefs,$event)" (onToolbarPreparing)="true"
        (onCellPrepared)="onCellPrepared(columnDefs,$event)" [columnAutoWidth]="true" [wordWrapEnabled]="true"  (onRowPrepared)="onRowPrepared($event)" (onExporting)="onExporting($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
        <dxo-pager [visible]="ulbTripsDta.length>10" [showPageSizeSelector]="true"
            [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
        </dxo-search-panel>
        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ULB Wise Vehicles Summary">
        </dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [fixed]="f.fixed"
            [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true"
            [calculateCellValue]="calculateCellValue">
        </dxi-column>
        <dxo-summary>
            <dxi-total-item *ngFor="let g of ttlColumnDefs " [column]="g.field"
                [summaryType]="g.summaryType ? g.summaryType: ''"
                [displayFormat]="g.crncy_in == 1 ? currencyINRFormater:roundingFormater" [alignment]="g.algnmnt"
                [valueFormat]="g.format ? g.format : '' ">
            </dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
</div>
<div class="dxgridpdng cvrgrdsbrd " style="padding-top:0px" *ngIf="dbrd_lvl==1 || dbrd_lvl==2">
    <h1 class="bdycntnthdr">Vehicles Summary</h1>
    <dx-data-grid id="gridContainer" class="p-8" [dataSource]="vehclesTble" [remoteOperations]="false"
        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
        (onCellClick)="devCellclkL1(vehclesTblecolumnDefs,$event)" (onToolbarPreparing)="true"
        (onCellPrepared)="onCellPreparedL1(vehclesTblecolumnDefs,$event)" [columnAutoWidth]="true"
        [wordWrapEnabled]="true" (onExporting)="onExporting($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
        <dxo-pager [visible]="vehclesTble.length>10" [showPageSizeSelector]="true"
            [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
        </dxo-search-panel>
        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="Vehicles Summary">
        </dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxi-column *ngFor="let f of vehclesTblecolumnDefs" [visible]="!f.hide" [dataField]="f.field" [fixed]="f.fixed"
            [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true"
            [calculateCellValue]="calculateCellValue">
        </dxi-column>
        <dxo-summary>
            <dxi-total-item *ngFor="let g of vehttlColumnDefs " [column]="g.field"
                [summaryType]="g.summaryType ? g.summaryType: ''"
                [displayFormat]="g.crncy_in == 1 ? currencyINRFormater:roundingFormater" [alignment]="g.algnmnt"
                [valueFormat]="g.format ? g.format : '' ">
            </dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
</div>
<div class="row" [hidden]="dbrd_lvl==2">
    <div class="col-lg-8 col-md-8" *ngIf="ShowClap && dbrd_lvl==0">
        <div class="dxgridpdng cvrgrdsbrd " style="padding-top:0px">
            <h1 class="bdycntnthdr">Vendor wise operated vehicles and operated %</h1>
            <dx-data-grid id="gridContainer" class="p-8" [dataSource]="ulbVndrwseopratedTripsTble"
                [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true"
                style="width:100%" [columnAutoWidth]="true" [wordWrapEnabled]="true" (onExporting)="onExporting($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                <dxo-pager [visible]="ulbVndrwseopratedTripsTble.length>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"> </dxo-filter-panel>
                <dxo-group-panel [visible]="false"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false"
                    fileName="Zone wise Trip Coverage Status">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of ulbVndrwseopratedcolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [fixed]="f.fixed" [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width"
                    [allowResizing]="true" [calculateCellValue]="calculateCellValue">
                </dxi-column>
                <dxo-summary>
                    <dxi-total-item *ngFor="let g of vndrttlColumnDefs" [column]="g.field"
                        [summaryType]="g.summaryType ? g.summaryType: ''"
                        [displayFormat]="g.crncy_in == 1 ? currencyINRFormater:roundingFormater" [alignment]="g.algnmnt"
                        [valueFormat]="g.format ? g.format : '' ">
                    </dxi-total-item>
                </dxo-summary>
            </dx-data-grid>
        </div>
    </div>
    <div [ngClass]="dbrd_lvl==1 ? 'col-lg-6 col-md-6' : 'col-lg-4 col-md-4'">
        <div class="dxgridpdng cvrgrdsbrd" style="padding-top:0px">
            <h1 class="bdycntnthdr">
                <div class="row">
                    <div class="col-lg-8 col-md-8">
                        Vehicles Operated in Last 7 Days
                    </div>
                    <div class="col-lg-4 col-md-4" style="font-size:14px;">
                        Total Vehicles:{{ttl_vhcls}}
                    </div>
                </div>
            </h1>
            <div id="vehOperationalChart"></div>
        </div>

    </div>
</div>