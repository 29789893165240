import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';



@Component({
  selector: 'app-help-videos',
  templateUrl: './help-videos.component.html',
  styleUrls: ['./help-videos.component.scss']
})
export class HelpvideosComponent implements OnInit {
  videogrps;
  selectedGroupId;
  videosLst;
  columnDefs;
  rowData;
  loader: boolean;
  shwSidebar = false;
  heading;
  editClicked;
  validateForm!: FormGroup;
  editdata;
  init;
  permissions = { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 };
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(public apiSrv: AppsService, private fb: FormBuilder, private modal: NzModalService) { }

  ngOnInit() {
    this.validateForm = this.fb.group({
      vdo_nm: [null, [Validators.required]],
      vdo_embd_tx: [null, [Validators.required]],
      vdo_dscn_tx: [null],
      thmbl_imge_url_tx: [null],
      vdo_grp_id: [null, [Validators.required]]
    });
    this.getvideogroups();
  }

  getvideogroups() {
    let rte = `kb/video/groups`;
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.videogrps = res['data'];
          this.selectedGroupId = 1;
          this.onvideoGrpSelection();
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    })
  }
  onvideoGrpSelection() {
    let rte = `kb/video/groups/${this.selectedGroupId}`;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['data'].length > 0) {
        this.videosLst = res['data'];
        this.rowData = this.videosLst;
      }
      else {
        this.videosLst = [];
        this.rowData = this.videosLst;
      }
    })
    this.columnDefs = [
      { headerName: 'Sno', field: 'sno', alignment: 'center', width: 100, filter: false },
      { headerName: 'video Name', alignment: 'left', width: 200, field: 'vdo_nm' },
      { headerName: 'video Url', alignment: 'left', width: 250, field: 'vdo_embd_tx' },
      { headerName: 'video description', alignment: 'left', width: 200, field: 'vdo_dscn_tx' },
      { headerName: 'video Group', field: 'vdo_grp_nm', width: 200, alignment: 'left' },
    ]

  }
  onDelete2(event) {
    let data = event.data
    this.modal.confirm({
      nzTitle: 'Are you sure delete this section?',
      nzContent: `<b style="color: red;">${data.vdo_nm}</b>`,
      nzOkText: 'Yes',
      // nzOkType: 'danger',
      nzOkDanger:true,
      nzOnOk: () => this.deletevideo(data),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });

  }
  deletevideo(data) {
    let rte = `kb/delete/videos`;
    this.apiSrv.create(data, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.closeSideBar();
        this.onvideoGrpSelection();
        this.modal.success({
          nzTitle: 'delete question',
          nzContent: 'Sucessfully deleted'
        });
      }
    })
  }
  onEdit2(event) {
    this.editdata = event.data
    this.openSideBar("edit", this.editdata)

  }
  onToolbarPreparing(e) {
    //   console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  closeSideBar() {
    this.shwSidebar = false
  }
  submit() {
    let rte = `kb/videos`;
    this.validateForm.value['sqnce_id'] = this.videosLst.length + 1
    //   console.log(this.validateForm)
    this.apiSrv.create(this.validateForm.value, rte).subscribe(res => {
      if (res['status'] == 200) {
        this.modal.success({
          nzTitle: 'New video Creation',
          nzContent: 'Sucessfully created'
        });
        this.closeSideBar();
        this.onvideoGrpSelection();
      }
    })

  }
  update() {
    let rte = `kb/videos/`+this.editdata.vdo_id;
    //   console.log(this.validateForm);
    this.validateForm.value['vdo_id'] = this.editdata.vdo_id
    this.apiSrv.update(this.validateForm.value, rte).subscribe(res => {
      if (res['status'] == 200) {
        this.modal.success({
          nzTitle: 'video Updated',
          nzContent: 'Sucessfully Updated'
        });
        this.closeSideBar();
        this.onvideoGrpSelection();
      }
    })

  }
  openSideBar(key, data) {
    this.shwSidebar = true;
    //   console.log(key)
    //   console.log(data)
    if (key == 'new') {
      this.heading = "Create New video";
      this.editClicked = false;
      this.validateForm.get('vdo_nm').setValue('');
      this.validateForm.get('vdo_embd_tx').setValue('');
      this.validateForm.get('vdo_dscn_tx').setValue('');
      this.validateForm.get('thmbl_imge_url_tx').setValue('');
      this.validateForm.get('vdo_grp_id').setValue('');

    }
    else if (key == 'edit') {
      this.heading = "Edit page Details";
      this.editClicked = true;
      this.editdata = data
      this.validateForm.get('vdo_nm').setValue(data.vdo_nm);
      this.validateForm.get('vdo_embd_tx').setValue(data.vdo_embd_tx);
      this.validateForm.get('vdo_dscn_tx').setValue(data.vdo_dscn_tx);
      this.validateForm.get('thmbl_imge_url_tx').setValue(data.thmbl_imge_url_tx);
      this.validateForm.get('vdo_grp_id').setValue(data.vdo_grp_id);
    }
  }
}
