import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-emplye-prfle',
  templateUrl: './emplye-prfle.component.html',
  styleUrls: ['./emplye-prfle.component.scss']
})
export class EmplyePrfleComponent {
@Input() wrkData
slctdActdt =3
slctdAtnd =1 
  empdtls: any;
ngOnInit() {
  console.log(this.wrkData.crw_id)  
  console.log(this.wrkData)  

  this.getEmplyDtls()


}


  constructor(public apiServ: AppsService, private rout: ActivatedRoute) {
 

  }
getEmplyDtls(){
  console.log('emppppppppppp')
  
 const rte = `/apsfl/employee/profile/${this.wrkData.crw_id}`;

    this.apiServ.get( rte).subscribe((res) => {
      if (res['status'] == 200) {
        console.log(res['data'])


        this.empdtls= res['data'][0]


      }
    })
  
  
}
ngOnChanges(){

}
tabsActdata = [
  {
    'tab_id': 1,
    'tab_nm': 'Overall',
    'tab_hndlr': 'overall'
  },
  {
    'tab_id': 2,
    'tab_nm': 'This Month',
    'tab_hndlr': 'crntmnt'

  },
  {
    'tab_id': 3,
    'tab_nm': 'Today',
    'tab_hndlr': 'crntdt'

  },

]

tabsAtnddata = [
 
  {
    'tab_id': 1,
    'tab_nm': 'This Month',
    'tab_hndlr': 'crntmnt'

  }

]
slectdActTab(t){
  console.group(t,'ttttttttttttttttttt')
}
}
