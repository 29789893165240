import { Component, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import * as moment from 'moment';
import { AppsService } from 'src/app/shared/services/apps.service';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
/* Chart code */
// Themes begin
am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);
// Themes end
@Component({
  selector: 'app-rural-dashboard',
  templateUrl: './rural-dashboard.component.html',
  styleUrls: ['./rural-dashboard.component.scss']
})
export class RuralDashboardComponent implements OnInit {
  chart: am4charts.XYChart;
  chart2: am4charts.XYChart;
  chart3: am4charts.XYChart;
  dstrctTripsDta: any;
  tpPrfmnceUlbDta: any;
  lstPrfmnceUlbDta: any;
  dateFormat = 'yyyy/MM/dd';
  ulbTripscolumnDefs;
  tpPrfmnceUlbcolumnDefs;
  lstPrfmnceUlbcolumnDefs;
  slctdDt = new Date();
  today = new Date();
  curDate;
  curTblDate;
  vhclsTrvld: any;
  ttlTrpsCnt: any; prmryTrpsCnt: any; scndryTrpsCnt: any;
  showLdr = false;
  postslctdDt;
  ctgryWseCnts: any;
  // legend: am4charts.Legend;
  curTmspDate: string;
  clntDtls: any;
  chartHeatData = [];
  heatChrt: any;
  curYr: any;
  shwzrwErr: boolean = false;
  shwScndryzrwErr: boolean = false;
  tdyCoveredDstnce: any = [];
  ThrtyKmbelowVehcles: any = [];
  ThrtyKmbelowVehclescolumnDefs: any = [];
  ulbwseopratedTripsTble: any;
  ulbwseopratedTripsTblecolumnDefs: any = [];
  ulbVndrwseopratedTripsTble: any;
  ulbVndrwseopratedTripsTblecolumnDefs: any = [];
  crdFive: boolean = false; crdSix: boolean = false; crdSeven: boolean = false; crdEight: boolean = false;
  urbn_in: number;
  chartsData: any;
  userDtls: any;
  clap_in: any;
  ShowClap: boolean = false;
  // dbrd_ky = 'rural';
  ispagination;
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };


  onChange(e): void {
    // console.log('onChange: ',e);
    this.slctdDt = e;
    // console.log('slctdDt: ', this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    // console.log(this.curDate);
    this.get_totalVehiclesTravelled(this.slctdDt);
    // this.get_totalTripsCount(this.slctdDt);
    // this.get_prmryTripsCount(this.slctdDt);
    // this.get_scndryTripsCount(this.slctdDt);
    // this.get_lst7DaysTrpsDta(this.slctdDt);
    // this.get_lst7DaysDistanceDta(this.slctdDt);
    // this.get_ctgrywisecounts(this.slctdDt);
    this.get_ulbWsedta(this.slctdDt);
    this.get_today_Ulb_CoveredDistance(this.slctdDt, 0);
    // this.getbelowThirtyKmVehicles(this.slctdDt);
    // this.getVehiclesOperatedcntsULBwise(this.slctdDt)
    this.getVehiclesOperatedcntsVndrULBwise(this.slctdDt);

  }


  ngOnInit() {
    this.curYr = moment(this.today).format('YYYY');
    // console.log('Current Year ******************************************* ',this.curYr);
    this.slctdDt = new Date();
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.get_totalVehiclesTravelled(this.slctdDt);
    // this.get_totalTripsCount(this.slctdDt);
    // this.get_prmryTripsCount(this.slctdDt);
    // this.get_scndryTripsCount(this.slctdDt);
    // this.get_ctgrywisecounts(this.slctdDt);
    this.get_ulbWsedta(this.slctdDt);
    this.get_today_Ulb_CoveredDistance(this.slctdDt, 0);
    // this.getbelowThirtyKmVehicles(this.slctdDt);
    // this.getVehiclesOperatedcntsULBwise(this.slctdDt)
    this.getVehiclesOperatedcntsVndrULBwise(this.slctdDt);
    this.getChrtsData(0);
    this.cardFive();
  }

  get_totalVehiclesTravelled(funDt?): any {
    this.vhclsTrvld = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 0
    }
    const rte = `dashboard/enterprise/rural/get_vhclesTrvld`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        let res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.vhclsTrvld = res['data'];
          console.log(this.vhclsTrvld)
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  get_today_Ulb_CoveredDistance(funDt?, slctTnt?): any {
    this.tdyCoveredDstnce = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/rural/get_today_CoveredDistance`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.tdyCoveredDstnce = res['data'];
          console.log(this.tdyCoveredDstnce)
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  get_ulbWsedta(funDt?): any {
    this.dstrctTripsDta = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 0
    }
    const rte = `dashboard/enterprise/rural/get_distrctwiseDta`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        let res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.dstrctTripsDta = res['data'];
          // console.log(this.dstrctTripsDta);
          this.ulbTripscolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
            { headerName: 'District Name', alignment: 'left', width: 100, field: 'dstrct_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Total Vehicles', alignment: 'center', width: 80, field: 'tot_veh', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
            { headerName: 'Non Operated Vehicles', field: 'notoperated',  alignment: 'center' },
            { headerName: 'Operated %', field: 'opper',  alignment: 'center' },
            // { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Travelled Trips', alignment: 'center',  field: 'trvld_trps', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Trips %', alignment: 'center',  field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Running', field: 'running',  alignment: 'center', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Route Deviated', field: 'rd_cnt',  alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }

          ]
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }

  gotoUlb(event) {
    console.log(event.row.data);
    this.TransfereService.setLoclData('ulbDta', event.row.data)
    this.route.navigate([`internal/dashboard/district-dashboard`]);
  }
  onCellPrepared(colDef, e) {

    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'ULB Name') {
      e.cellElement.style.color = '#ff0000';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
      e.cellElement.style.background = 'rgba(243, 191, 176, 0.2784313725490196)';
      e.cellElement.style.backgroundClip = 'content-box';
      e.cellElement.style.cursor = 'pointer';
    }

  }
  getVehiclesOperatedcntsVndrULBwise(funDt?) {
    this.slctdDt = funDt;
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    let rte = `dashboard/enterprise/rural/get_vndrwise_operated_Vehicles/${this.curDate}`;
    this.apiServ.get(rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.ulbVndrwseopratedTripsTble = res['data'];
        // let counter = 0;
        //   this.ulbVndrwseopratedTripsTble.filter((k) => {
        //     k['sno'] = ++counter;
        //   });
        this.ulbVndrwseopratedTripsTblecolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
          { headerName: 'Zone', alignment: 'left', width: 90, field: 'zone_nm' },
          { headerName: 'Vendor Name', alignment: 'left', width: 140, field: 'vndr_nm' },
          // { headerName: 'ULB Name', alignment: 'left', width: 200, field: 'tnt_nm' },
          { headerName: 'No of Locations', alignment: 'center', field: 'ulb_ct' },
          { headerName: 'Total Vehicles', field: 'ttl', alignment: 'center' },
          { headerName: 'Online Vehicles', field: 'online_ct', alignment: 'center' },
          { headerName: 'Offline Vehicles', field: 'offline_ct', alignment: 'center' },
          { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
          { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
          { headerName: 'Operated %', field: 'op_prct', alignment: 'center' },
        ]
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }

  cardFive() {
    this.crdFive = true; this.crdSix = false; this.crdSeven = false; this.crdEight = false;
    this.urbn_in = 0;
    this.getChrtsData(0);

  }
  cardSix() {
    this.crdFive = false; this.crdSix = true; this.crdSeven = false; this.crdEight = false;
    this.urbn_in = 0;
    this.getChrtsData(0);

  }
  cardSeven() {
    this.crdFive = false; this.crdSix = false; this.crdSeven = true; this.crdEight = false;
    this.urbn_in = 0;
    this.getChrtsData(0);

  }
  cardEight() {
    this.crdFive = false; this.crdSix = false; this.crdSeven = false; this.crdEight = true;
    this.urbn_in = 0;
  }
  getChrtsData(u_in) {
    this.showLdr = true;
    let rte = `dashboard/enterprise/state/rural/dstrt_charts_data/${u_in}`;
    this.apiServ.get(rte).subscribe((res) => {
      let res_data = res['data'];
      this.chartsData = res['data'];

      console.log(res_data)
      this.showLdr = false;
      // rural graphs
      if (this.crdFive == true) {
        let chart5 = am4core.create('ruralVehiclesChart', am4charts.XYChart);
        chart5.data = this.chartsData;

        let categoryAxis5 = chart5.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis5.dataFields.category = 'dstrct_nm';
        categoryAxis5.renderer.grid.template.location = 0;
        categoryAxis5.renderer.minGridDistance = 10;
        categoryAxis5.renderer.labels.template.rotation = -90;
        categoryAxis5.renderer.labels.template.fontSize = 10;

        categoryAxis5.title.text = ` ----- Districts  ----- `;

        let valueAxis5 = chart5.yAxes.push(new am4charts.ValueAxis());

        valueAxis5.title.text = '-- Vehicles -- ';

        // Create series
        function createSeries(field, name, clr) {

          // Set up series
          let series5 = chart5.series.push(new am4charts.ColumnSeries());
          series5.name = name;
          series5.dataFields.valueY = field;
          series5.dataFields.categoryX = 'dstrct_nm';
          series5.sequencedInterpolation = true;
          series5.fill = am4core.color(clr);

          // Make it stacked
          series5.stacked = true;

          // Configure columns
          series5.columns.template.width = am4core.percent(60);
          series5.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series5.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;

          return series5;

        }
        chart5.legend = new am4charts.Legend();


        createSeries('actve_rural_ttl', 'Active Vehicles', '#185aad');
        createSeries('inact_rural_ttl', 'Spare Vehicles', '#ed844e');
      }
      if (this.crdSix == true) {
        let chart6 = am4core.create('ruraloperStatusChart', am4charts.XYChart);
        chart6.data = this.chartsData;

        let categoryAxis6 = chart6.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis6.dataFields.category = 'dstrct_nm';
        categoryAxis6.renderer.grid.template.location = 0;
        categoryAxis6.renderer.minGridDistance = 10;
        categoryAxis6.renderer.labels.template.rotation = -90;
        categoryAxis6.renderer.labels.template.fontSize = 10;

        categoryAxis6.title.text = ` ----- Districts  ----- `;

        let valueAxis6 = chart6.yAxes.push(new am4charts.ValueAxis());
        valueAxis6.title.text = '-- Vehicles -- ';

        // Create series
        function createSeries(field, name, clr) {

          // Set up series
          let series6 = chart6.series.push(new am4charts.ColumnSeries());
          series6.name = name;
          series6.dataFields.valueY = field;
          series6.dataFields.categoryX = 'dstrct_nm';
          series6.sequencedInterpolation = true;
          series6.fill = am4core.color(clr);

          // Make it stacked
          series6.stacked = true;

          // Configure columns
          series6.columns.template.width = am4core.percent(60);
          series6.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series6.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;

          return series6;

        }
        chart6.legend = new am4charts.Legend();
        createSeries('r_operated', 'Operated Vehicles', '#fddd33');
        createSeries('r_notoperated', 'Not Operated Vehicles', '#2751bf');
      }
      if (this.crdSeven == true) {
        let chart7 = am4core.create('ruraltrvlVhcleChart', am4charts.XYChart);
        chart7.data = this.chartsData;

        let categoryAxis7 = chart7.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis7.dataFields.category = 'dstrct_nm';
        categoryAxis7.renderer.grid.template.location = 0;
        categoryAxis7.renderer.minGridDistance = 10;
        categoryAxis7.renderer.labels.template.rotation = -90;
        categoryAxis7.renderer.labels.template.fontSize = 10;

        categoryAxis7.title.text = ` ----- Districts  ----- `;

        let valueAxis7 = chart7.yAxes.push(new am4charts.ValueAxis());
        valueAxis7.title.text = '-- Vehicles -- ';

        // Create series
        function createSeries(field, name, clr) {

          // Set up series
          let series7 = chart7.series.push(new am4charts.ColumnSeries());
          series7.name = name;
          series7.dataFields.valueY = field;
          series7.dataFields.categoryX = 'dstrct_nm';
          series7.sequencedInterpolation = true;
          series7.fill = am4core.color(clr);

          // Make it stacked
          series7.stacked = true;

          // Configure columns
          series7.columns.template.width = am4core.percent(60);
          series7.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series7.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;

          return series7;

        }
        chart7.legend = new am4charts.Legend();
        createSeries('rrl_tdydst_abv30', '>= 30 kms', '#29989d');
        createSeries('rrl_tdydst_blw30', '< 30 Kms', '#d9809c');

      }
    }, (err) => {
      // console.log(err);
    });
  }

}
