<div class="wrk-container">
    <div>
        <div class="bg-img_top">
            <!-- <div class="row" style="display: flex; margin-bottom:10px">
                <div class="row m-0 gap w-15">
                    <div class="col-5 label">District</div>
                    <span>:</span>
                    <div class="col-auto">{{wrkDtls[0].tnt_nm}}</div>
                </div>
                <div class="row m-0 gap w-15">
                    <div class="col-5 label">Mandal</div>
                    <span>:</span>
                    <div class="col-auto">{{wrkDtls[0].mndl_nm}}</div>
                </div>
                <div class="row m-0 gap w-15">
                    <div class="col-5 label">Issue Type</div>
                    <span>:</span>
                    <div class="col-auto">{{wrkDtls[0].isue_typ_nm}}</div>
                </div>
                <div class="row m-0 gap w-15">
                    <div class="col-5 label">Work Group</div>
                    <span>:</span>
                    <div class="col-auto">{{wrkDtls[0].wrk_grp_nm}}</div>
                </div>

                <div class="row m-0 gap w-15">
                    <div class="col-5 label">Work Type</div>
                    <span>:</span>
                    <div class="col-auto">{{wrkDtls[0].wrk_typ_nm}}</div>
                </div>

                <div class="row m-0 gap w-15">
                    <div class="col-5 label">Description</div>
                    <span>:</span>
                    <div class="col-auto">{{wrkDtls[0].oms_wrm_dsc_tx}}</div>
                </div>
                <div class="row m-0 gap w-15">
                    <div class="col-5 label">Work Status</div>
                    <span>:</span>
                    <div class="col-auto">{{wrkDtls[0].wrk_sts_nm}}</div>
                </div>
            </div> -->
        <div class = "row" *ngIf="wrkDtls && wrkDtls.length>0">
            <div class="col-md-4">
                <div class="cntnt">
                    <div class="lft_labels">
                        District <span style="float:right">-</span>
                    </div>
                    <div class="rgt_labels">
                        {{wrkDtls[0].tnt_nm}}
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="cntnt">
                    <div class="lft_labels">
                        Mandal <span style="float:right">-</span>
                    </div>
                    <div class="rgt_labels">
                        {{wrkDtls[0].mndl_nm}}
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="cntnt">
                    <div class="lft_labels">
                        Issue Type <span style="float:right">-</span>
                    </div>
                    <div class="rgt_labels">
                        {{wrkDtls[0].isue_typ_nm}}
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="cntnt">
                    <div class="lft_labels">
                        Work Group<span style="float:right">-</span>
                    </div>
                    <div class="rgt_labels">
                        {{wrkDtls[0].wrk_grp_nm}}
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="cntnt">
                    <div class="lft_labels">
                        Work Type<span style="float:right">-</span>
                    </div>
                    <div class="rgt_labels">
                        {{wrkDtls[0].wrk_typ_nm}}
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="cntnt">
                    <div class="lft_labels">
                        Work Status<span style="float:right">-</span>
                    </div>
                    <div class="rgt_labels">
                        {{wrkDtls[0].wrk_sts_nm}}
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="cntnt">
                    <div class="lft_labels" style="    width: 9.8%;margin-right: 1.5%;">
                         Description<span style="float:right">-</span>
                    </div>
                    <div class="rgt_labels" style="width:87%">
                        {{wrkDtls[0].oms_wrm_dsc_tx}}
                    </div>
                </div>
            </div>


   
        </div>
        
        
        
        </div>
        <div class="row m-0">
            <div class="w-65">
                <div id="map"></div>
            </div>
            <div class="w-35">
                <div class="sctn-container">
                    <div class="sctn-header">Trips</div>
                    <div class="trips-container">
                        <div class="trip bottom-border" *ngFor="let t of trpDtls"  (click)="onTrpCrdSlct(t)" [ngClass]="{'ActvTrp':t.slctd==true}">
                            <div class="trip-data">
                                <div *ngIf="t.actl_strt_ts" class="row m-0 gap">
                                    <span class="label p-0 col-5">Registration No </span> <span>:</span>
                                    <span class=" p-0 col-auto">{{t.asrt_nm}}
                                        <span class="trip-status" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==1"> Trip
                                            Completed</span>
                                        <span class="trip-status" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==0"> In
                                            Trip</span>
                                        <span class="trip-status" *ngIf="t.trp_strt_in==0 && t.trp_cls_in==0"> Trip
                                            Not
                                            Started</span>
                                    </span>
                                </div>
                                <div *ngIf="t.actl_strt_ts" class="row m-0 gap">
                                    <span class="label p-0 col-5">Trip Start time </span> <span>:</span>
                                    <span class=" p-0 col-auto">{{t.actl_strt_ts}}</span>
                                </div>
                                <div *ngIf="t.actl_end_ts" class="row m-0 gap">
                                    <span class="label p-0 col-5">Trip End time </span> <span>:</span>
                                    <span class=" p-0 col-auto">{{t.actl_end_ts }}</span>
                                </div>
                                <!-- <div class="row m-0 gap">
                                    <span class="label p-0 col-5">Trip Status</span>
                                    <span>:</span>
                                    <span class=" p-0 col-auto" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==1"> Trip
                                        Completed</span>
                                    <span class=" p-0 col-auto" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==0"> In
                                        Trip</span>
                                    <span class=" p-0 col-auto" *ngIf="t.trp_strt_in==0 && t.trp_cls_in==0"> Trip Not
                                        Started</span>
                                </div> -->
                            </div>
                            <div class="btns-container">
                                <button class="btn live-btn" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==0"
                                    (click)="plotOnMap(t,'live')">
                                    <span nz-icon nzType="compass" nzTheme="outline"></span>
                                    Live track
                                </button>
                                <button class="btn live-btn" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==1"
                                    (click)="plotOnMap(t,'play')">
                                    <span nz-icon nzType="left-circle" nzTheme="outline"></span>
                                    Playback
                                </button>
                                <button class="btn timeline-btn" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==1"
                                    (click)="plotOnMap(t,'time')">
                                    <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
                                    Timeline
                                </button>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div style="padding:10px; display: flex; gap:5px" *ngIf="wrkDtls && wrkDtls.length>0">
            <div class="details-container bg-img w-30">
                <div class="row m-0">
                    <div class="col-4 label">District</div>
                    <span class="col-1">:</span>
                    <div class="col-7">{{wrkDtls[0].tnt_nm}}</div>
                </div>
                <div class="row m-0">
                    <div class="col-4 label">Mandal</div>
                    <span class="col-1">:</span>
                    <div class="col-7">{{wrkDtls[0].mndl_nm}}</div>
                </div>
                <div class="row m-0">
                    <div class="col-4 label">Issue Type</div>
                    <span class="col-1">:</span>
                    <div class="col-7">{{wrkDtls[0].isue_typ_nm}}</div>
                </div>
                <div class="row m-0">
                    <div class="col-4 label">Work Group</div>
                    <span class="col-1">:</span>
                    <div class="col-7">{{wrkDtls[0].wrk_grp_nm}}</div>
                </div>

                <div class="row m-0">
                    <div class="col-4 label">Work Type</div>
                    <span class="col-1">:</span>
                    <div class="col-7">{{wrkDtls[0].wrk_typ_nm}}</div>
                </div>

                <div class="row m-0">
                    <div class="col-4 label">Description</div>
                    <span class="col-1">:</span>
                    <div class="col-7">{{wrkDtls[0].oms_wrm_dsc_tx}}</div>
                </div>
                <div class="row m-0">
                    <div class="col-4 label">Work Status</div>
                    <span class="col-1">:</span>
                    <div class="col-7">{{wrkDtls[0].wrk_sts_nm}}</div>
                </div>
            </div>
            <div style="flex-grow:1;">
                <div id="map"></div>
            </div>
            <div class="w-30">
                <div class="sctn-container">
                    <div class="sctn-header">Trips</div>
                    <div class="trips-container">
                        <div class="trip item-card bg-img bottom-border" *ngFor="let t of trpDtls">
                            <div class="trip-data">
                                <div *ngIf="t.actl_strt_ts" class="row m-0 gap">
                                    <span class="label p-0 col-5">Registration No </span> <span>:</span>
                                    <span class=" p-0 col-auto">{{t.asrt_nm}}</span>
                                </div>
                                <div *ngIf="t.actl_strt_ts" class="row m-0 gap">
                                    <span class="label p-0 col-5">Trip Start time </span> <span>:</span>
                                    <span class=" p-0 col-auto">{{t.actl_strt_ts}}</span>
                                </div>
                                <div *ngIf="t.actl_end_ts" class="row m-0 gap">
                                    <span class="label p-0 col-5">Trip End time </span> <span>:</span>
                                    <span class=" p-0 col-auto">{{t.actl_end_ts }}</span>
                                </div>
                                <div class="row m-0 gap">
                                    <span class="label p-0 col-5">Trip Status</span>
                                    <span>:</span>
                                    <span class=" p-0 col-auto" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==1"> Trip
                                        Completed</span>
                                    <span class=" p-0 col-auto" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==0"> In
                                        Trip</span>
                                    <span class=" p-0 col-auto" *ngIf="t.trp_strt_in==0 && t.trp_cls_in==0"> Trip Not
                                        Started</span>
                                </div>
                            </div>
                            <div class="btns-container">
                                <button class="btn live-btn" *ngIf="t.trp_strt_in==1 && t.trp_cls_in==0">Live
                                    track</button>
                                <button class="btn live-btn"
                                    *ngIf="t.trp_strt_in==1 && t.trp_cls_in==1">Playback</button>
                                <button class="btn timeline-btn"
                                    *ngIf="t.trp_strt_in==1 && t.trp_cls_in==1">Timeline</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="work-details-container">
            <div style="flex-grow: 1;">
                <div class="sctn-container">
                    <div class="sctn-header">Assets</div>
                    <div class="grid-container">
                        <div class="asset item-card bg-img" *ngFor="let a of assetDtls">
                            <div class="asset-date">{{a.asrt_i_dt}} </div>
                            <div class="row gap m-0">
                                <div class="p-0 col-5 label">Asset Type</div>
                                <span>:</span>
                                <div class="p-0 col-auto">{{a.wrk_ast_ctgry_nm}}</div>
                            </div>
                            <div class="row gap m-0">
                                <div class="p-0 col-5 label">Category</div>
                                <span>:</span>
                                <div class="p-0 col-auto">{{a.wrk_ast_nm}}</div>
                            </div>

                            <div class="row w-100 m-0 p-0">
                                <div class="p-0 col-6 row m-0 p-0 gap" *ngIf="a.fld_in==1">
                                    <div class="p-0 col-7 label">No of Meters</div>
                                    <span>:</span>
                                    <div class="p-0 col-auto">{{a.wire_mtr}}</div>
                                </div>
                                <div class="p-0 col-6 row m-0 p-0 gap" *ngIf="a.fld_in==1">
                                    <div class="p-0 col-7 label">Drum Number</div>
                                    <span>:</span>
                                    <div class="p-0 col-auto">{{a.drm_nu}}</div>
                                </div>
                                <div class="p-0 col-6 row m-0 p-0 gap" *ngIf="a.fld_in==1">
                                    <div class="p-0 col-7 label">Start Meter</div>
                                    <span>:</span>
                                    <div class="p-0 col-auto">{{a.strt_drm_mtr}}</div>
                                </div>
                                <div class="p-0 col-6 row m-0 p-0 gap" *ngIf="a.fld_in==1">
                                    <div class="p-0 col-7 label">End Meter</div>
                                    <span>:</span>
                                    <div class="p-0 col-auto">{{a.end_drm_mtr}}</div>
                                </div>
                                <div class="p-0 col-6 row m-0 p-0 gap" *ngIf="a.fld_in==2">
                                    <div class="p-0 col-7 label">No of Items</div>
                                    <span>:</span>
                                    <div class="p-0 col-auto">{{a.no_of_itms}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sctn-container" style="display:flex; gap:10px">
                    <div class="sctn-container w-50" style="box-shadow: 0px 0px 16px 0px #e9edffd6;">
                        <div class="sctn-header"> Employees </div>
                        <div class="item-container" *ngFor="let e of asgnEmpDtls">
                            <!-- For loop div-->
                            <div class="profile label">
                                <span nz-icon nzType="user" nzTheme="outline"></span>
                                {{e.crw_nm}}<span class="mobile-no">({{e.crw_ph}})</span>
                            </div>
                            <div style="font-size:10px"><span style="color: #e75454;">Designation :
                                </span>{{e.dsgns_nm}}
                            </div>
                        </div>
                    </div>
                    <div class="sctn-container w-50" style="box-shadow: 0px 0px 16px 0px #e9edffd6;">
                        <div class="sctn-header">Locations</div>
                        <div class="location" *ngFor="let l of lctnDtls">
                            <!-- For loop div-->
                            <div class="attachment-container">
                                <div class="attachment" *ngFor="let i of l.imgs">
                                    <img *ngIf=i.img_url_tx src="{{i.img_url_tx}}" alt="" class="atchmnt-img">
                                </div>
                            </div>
                            <div>
                                <div class="label">{{l.wrk_lctn_nm}}</div>
                                <div class="location-description">{{l.oms_wrm_dsc_tx}}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="sctn-container">
                    <div class="sctn-header">Expenses</div>
                    <div style="display: grid; gap: 10px;">
                        <div class="grid-container">
                            <div class="item-card bg-img" *ngFor="let e of expnsDtls">
                                <div class="expense-category row m-0 gap-1">
                                    <span class="p-0 col-3 label font-weight-semibold">Expense</span>
                                    <span>:</span>
                                    <span class="p-0 col-auto">{{e.expne_tx}}-{{e.expne_ctgry_nm}}</span>
                                </div>
                                <!-- <div class="expense-category row m-0 gap-1">
                                                    <span class="p-0 col-3 label font-weight-semibold">Category</span>
                                                    <span>:</span>
                                                    <span class="p-0 col-auto">{{e.expne_ctgry_nm}}</span>
                                                </div> -->
                                <div class="row m-0 ">
                                    <div class="expnse-date row m-0 gap p-0 col-6">
                                        <span class="p-0 col-6 label font-weight-semibold">Invoice
                                            Date</span>
                                        <span>:</span>
                                        <span class="p-0 col-auto">{{e.invce_dt}}</span>
                                    </div>
                                    <div class="expnse-amount row m-0 gap p-0 w-auto col-6 flex-fill">
                                        <span class="p-0 col-6 label font-weight-semibold">Amount</span>
                                        <span>:</span>
                                        <span class="p-0 col-auto">{{currencyINRFormater(e.amnt)}}</span>
                                    </div>
                                </div>
                                <div class="row m-0  w-100">
                                    <div class="payment-status row m-0 gap p-0 col-6">
                                        <span class="p-0 col-6 label font-weight-semibold">Payment
                                            Status</span>
                                        <span>:</span>
                                        <span class="p-0 col-auto">{{e.pymnt_sts}}</span>
                                    </div>
                                    <div class="payment-status row m-0 gap w-auto p-0 col-6 flex-fill">
                                        <span class="p-0 col-6 label font-weight-semibold">Payment
                                            Date</span>
                                        <span>:</span>
                                        <span class="p-0 col-auto">{{e.expn_dt}}</span>
                                    </div>
                                </div>

                                <div class="row m-0  w-100">
                                    <div class="col-6 p-0">
                                        <div style="max-width: 50px; margin:10px auto 0px auto;">
                                            <div class="attachment" *ngIf=e.atchmt_url_tx>
                                                <img src="{{e.atchmt_url_tx}}" alt="" class="atchmnt-img">
                                            </div>
                                        </div>
                                        <div class="label font-size-11" style="text-align: center;">
                                            Invoice Image</div>
                                    </div>
                                    <div class="col-6 p-0">
                                        <div style="max-width: 50px; margin:10px auto 0px auto;">
                                            <div class="attachment" *ngIf=e.img_url_tx>
                                                <img src="{{e.img_url_tx}}" alt="" class="atchmnt-img">
                                            </div>
                                        </div>
                                        <div class=" label font-size-11" style="text-align: center;">
                                            Billing Image</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sctn-container">
                    <div class="sctn-header">Attachments</div>
                    <div class="attachment-container">
                        <div *ngFor="let a of atchMntDtls">
                            <!-- For loop div-->
                            <div class="attachment-category">{{a.img_typ_nm?a.img_typ_nm:'Image' }}</div>
                            <div class="attachment" *ngIf="a.img_url_tx">
                                <img src="{{a.img_url_tx}}" alt="" class="atchmnt-img">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="timeline w-30">
                <div class="sctn-header">Timeline</div>
                <div class="timeline-item" *ngFor="let t of timeLineData">
                    <div class="timeline-icon"></div>
                    <div class="timeline-content bg-img">
                        <p class="status-title">{{t.wrk_sts_nm}}</p>
                        <div>
                            <span class="label">Description : </span> <span>{{t.oms_wrm_dsc_tx}}</span>
                        </div>
                        <div><span style="margin-right:10px" nz-icon nzType="environment" nzTheme="fill"></span>
                            {{t.wrk_lctn_nm}}</div>
                        <div class="date"><span style="margin-right:10px" nz-icon nzType="calendar"
                                nzTheme="outline"></span>
                            {{t.asgnd_dt}}</div>
                    </div>
                </div>
                <!-- <div class="timeline-item">
                        <div class="timeline-icon"></div>
                        <div class="timeline-content bg-img">
                            <p class="status-title">Employee Assigned</p>
                            <div>
                                <span class="label">Description : </span> <span>test</span>
                            </div>
                            <div><span style="margin-right:10px" nz-icon nzType="environment"
                                    nzTheme="fill"></span> syamala
                                centre</div>
                            <div class="date"><span style="margin-right:10px" nz-icon nzType="calendar"
                                    nzTheme="outline"></span>
                                30-09-2024 10:04:43</div>
                        </div>
                    </div>
    
                    <div class="timeline-item">
                        <div class="timeline-icon"></div>
                        <div class="timeline-content bg-img">
                            <p class="status-title">Trip Started</p>
                            <div>
                                <span class="label">Description : </span> <span>test</span>
                            </div>
                            <div><span style="margin-right:10px" nz-icon nzType="environment"
                                    nzTheme="fill"></span> syamala
                                centre</div>
                            <div class="date"><span style="margin-right:10px" nz-icon nzType="calendar"
                                    nzTheme="outline"></span>
                                30-09-2024 10:07:57</div>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
    <ds-loading *ngIf="showLdr"></ds-loading>
</div>