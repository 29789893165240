import { Component, Input,SimpleChanges } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { AppsService } from '../../services/apps.service';
import * as _ from 'lodash';
import { id } from 'date-fns/locale';
import { NavigationStart, Router } from '@angular/router';
import { UserService } from 'src/app/providers/user/user.serivce';

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})

export class SideNavComponent {
  @Input() chkSde;
  public menuItems: any[]
  isFolded: boolean = false;
  isExpand: boolean;
  isSideNavDark: boolean;
  totMnuItmLst: any;
  fnlMenuItemsLst: any[];
  loader: boolean = true;
  clntDtls: any;
  tmpMenuItems: any[];
  shwBlr = 'true';
  hasChild: boolean = false;
  currentUrl: any;
  shwMblSdeVW : boolean = false;
  usrDtls: any;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService,
    public userService: UserService, private route: Router) {
    this.currentUrl = localStorage.getItem('homeUrl');
    this.usrDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.route.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;
      }
    });
    this.gstMnuItmsLst();
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('innnnnnnnnnnnnn SIDEnav', this.chkSde);
    if(this.chkSde == true){
      this.shwMblSdeVW = true;
    } else if(this.chkSde == false){
      this.shwMblSdeVW = false;
    }
    console.log('out SIDEnav', this.shwMblSdeVW);
    
  }
  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
  }

  toggleFold() {
    this.themeService.toggleFold(true);
  }

  toggleExpand() {
    // this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(false);
  }

  gstMnuItmsLst() {
    this.loader = true;
    const rte = `auth2/admin/menu`;
    this.apiSrv.get(rte).subscribe((res) => {
      this.loader = false;
      if (res['status'] == 200) {
        this.menuItems = res['data'];
        // console.log(this.menuItems);
        for (let i = 0; i < this.menuItems.length; i++) {
          this.menuItems[i]['actv_in'] = 0;
          if (this.menuItems[i].mnu_itm_url_tx == this.currentUrl) {
            this.menuItems[i].actv_in = 1;
            if (!this.menuItems[i].children) {
              this.hasChild = false;
              this.toggleFold();
            } else {
              this.hasChild = true;
              this.toggleExpand();
            }
          } else {
            this.menuItems[i].actv_in = 0;
          }

          if (!this.menuItems[i].children) {
            this.menuItems[i].children = [];
          } else {
            for (let j = 0; j < this.menuItems[i].children.length; j++) {
              this.menuItems[i].children[j]['actv_sb_in'] = 0;
              this.menuItems[i].children[j]['submenu'] = [];
              if (this.menuItems[i].children[j].mnu_itm_url_tx == this.currentUrl) {
                this.hasChild = true;
                this.toggleExpand();
                this.menuItems[i].actv_in = 1;
                this.menuItems[i].children[j].actv_sb_in = 1;
              } else {
                this.menuItems[i].children[j].actv_sb_in = 0;
              }
            }
          }
        }
      }
    }, err => {
      this.loader = false;
    });
  }

  setMnuActive(m) {
    if (m.prnt_mnu_itm_id && m.prnt_mnu_itm_id !== 0) {
      this.hasChild = true;
      this.toggleExpand();
      for (let i = 0; i < this.menuItems.length; i++) {
        if (this.menuItems[i].prnt_mnu_itm_id == m.prnt_mnu_itm_id) {
          this.menuItems[i].actv_in = 1;
          for (let j = 0; j < this.menuItems[i].children.length; j++) {
            if (this.menuItems[i].children[j].mnu_itm_url_tx == this.currentUrl) {
              this.menuItems[i].children[j].actv_sb_in = 1;
            }
            else {
              this.menuItems[i].children[j].actv_sb_in = 0;
            }
          }
        } else {
          this.menuItems[i].actv_in = 0;
        }
      }
    } else {
      this.hasChild = false;
      this.toggleFold();
      this.menuItems.forEach(obj => {
        if (obj.mnu_itm_id == m.mnu_itm_id) {
          obj['actv_in'] = 1;
        } else {
          obj['actv_in'] = 0;
        }
      });
    }
  }

  setSbMnuActive(m, sm) {
    this.menuItems.forEach(obj => {
      obj['actv_in'] = 0;
      if (obj.children.length > 0) {
        obj.children.forEach(element => {
          element['actv_sb_in'] = 0;
        });

      }
    })
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i].prnt_mnu_itm_id == m.prnt_mnu_itm_id && m.prnt_mnu_itm_id !== 0) {
        this.menuItems[i].actv_in = 1;

      } else {
        this.menuItems[i].actv_in = 0;

      }
      for (let j = 0; j < this.menuItems[i].children.length; j++) {
        if (this.menuItems[i].children[j].mnu_itm_id == sm.mnu_itm_id) {
          this.menuItems[i].children[j].actv_sb_in = 1;

        } else {
          this.menuItems[i].children[j].actv_sb_in = 0;

        }
      }
    }
  }

  blr(evnt) {
    this.shwBlr = evnt;
  }
  logout() {
    this.apiSrv.get("auth/logout")
        .subscribe(res => {
            if (res['status'] == 200) {
                this.userService.loggedIn(false);
                localStorage.clear();
                this.route.navigate(['/internal'], { replaceUrl: true });
            } else {
            }
        }, (err) => {
        })

}

editprfl() {
    this.route.navigateByUrl('internal/pages/profile')
}
}
