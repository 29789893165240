import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  usrRlesLst: any;
  columnDefs;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getUsrRoles();
  }
  getUsrRoles() {
    const rte = 'auth2/admin/roles';
    let ct = 0;
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.usrRlesLst = res['data'];
          this.usrRlesLst.filter(k => {
            k['sno'] = ++ct;
          });
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'Role Name', field: 'rle_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 200 },
            { headerName: 'Role Description', field: 'rle_dscrn_tx', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 200 },
            { headerName: 'Creation Time', field: 'crtd_tmstmp', alignment: 'center', width: 200 },
            { headerName: 'Updated Time', field: 'upd_tmstmp', alignment: 'center', width: 200 },
            { headerName: 'Created By', field: 'crtd_usr_nm', alignment: 'center', width: 200 },
            { headerName: 'Updated By', field: 'upd_usr_nm', alignment: 'center', width: 200 }
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      }
    });
  }

}
