<!-- <div class="row hdrBar">
    <div class="col-lg-12 col-md-12 mg-a">
        <h1>Assign Vehicles For Sanitory Secratory</h1>
    </div>
  
</div> -->
<div class="row">
  <div class="col-lg-8 col-md-8 mg-a">
    <div class="row hdrBar">
      <div class="col-lg-12 col-md-12 mg-a">
        <h1>Assign Vehicles For Sanitory Secratory</h1>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 mg-a">
    <button nz-button nzType="primary" class="adddrvrBtn" (click) = "openSlider();">Assign Vehicle</button>
  </div>
</div>
<div class="fltrbg">
    <div class="row">
        <div class="col-lg-2 col-md-2 crdCl">
            <p class="slctlbl">ULB</p>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
                [ngModelOptions]="{standalone: true}">
                <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
            </nz-select>
        </div>
        <div class="col-lg-2 col-md-2 crdCl" *ngIf="ShowClap">
          <p class="slctlbl">Vehicle Vendor</p>
          <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
          [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0"  *ngIf="!showVndr"></nz-option>
          <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
        </nz-select>
        </div>
    </div>
</div>
<div style="padding: 0px 10px;">

<div class="ds-card grid tbl dwntbl vhclsCmpnt" >
    <dx-data-grid class="p-8" [dataSource]="vhcleslst" [remoteOperations]="false"  
      [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
      [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
      (onToolbarPreparing)="true">
      <dxo-paging [pageSize]="10" [enabled]="true"></dxo-paging>
      <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
      [showInfo]="true"></dxo-pager>
      
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-filter-panel [visible]="false"></dxo-filter-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxo-column-chooser [enabled]="true" mode="select">
      </dxo-column-chooser>
      <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="SS assigned Vehicles Data"></dxo-export>
      <dxo-filter-row [visible]="false"></dxo-filter-row>
      <dxi-column *ngFor="let v of vhclslstcolumnDefs" [visible]="!v.hide" [dataField]="v.field" [caption]="v.headerName"
        [alignment]="v.alignment" [width]="v.width" [allowResizing]="true"></dxi-column>
    </dx-data-grid>
  </div>
</div>

    <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="500" [nzVisible]="opensldr"
    nzTitle="Assign vehicle for Sanitory Secratory" (nzOnClose)="closeSideBar()">
    <!-- <div class="row">
        <div class="col-md-12">
          <div class="sdHdr">
            Assign vehicle for Sanitory Secratory
          </div>
        </div>
      </div> -->
    <form nz-form class="drvrDtlsfrm">
        <div nz-row [nzGutter]="8">
          <div nz-col nzSpan="24">
            <nz-form-label>Select Sanitory Secratory</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Sanitory Secratory" [(ngModel)]="ss_id"
                  [ngModelOptions]="{standalone: true}" >
                  <nz-option *ngFor="let s of ssList" [nzLabel]=s.fst_nm [nzValue]="s.usr_id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="24">
            <nz-form-label>Select Vehicle</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehicle" [(ngModel)]="veh_id"
                  [ngModelOptions]="{standalone: true}" nzMode="multiple" (ngModelChange)="onvehiclesselect($event)">
                  <nz-option *ngFor="let v of asrtList" [nzLabel]=v.asrt_nm [nzValue]="v.asrt_id"></nz-option>

                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngIf='disabled' class="clrred">* {{errMsg}}</div>
        </div>
      </form>

      <div class="btngrp">
        <button nz-button style="margin-right: 8px;" (click)="closeSideBar()" nzType="primary"
          nzDanger>Cancel</button>
        <button nz-button (click)="saveasgnmnt()" class="sbmtbtn">Assign</button>
      </div>
</nz-drawer>