<ds-loading *ngIf="showLdr"></ds-loading>




<div class="page-header">
    <div class=" align-items-md-center">
          
                 
                    <div class="row hdrBar">
                            <div class="col-lg-6 col-md-6 mg-a">
                                <h1 *ngIf="clntDtls && clntDtls.length > 0" >
                                    <app-common-header [header_name] ="header_label" ></app-common-header>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;" >
                                <span class="slctDteLbl">Select Date:</span>
                                <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                                    (ngModelChange)="onChange($event)" class="dte_picker"></nz-date-picker>
                
                
                            </div>
                        </div>
            
          


            <!-- <div class>
    </div> -->
</div>
</div>


<div class="main">
    <div class="row">
        <div class="col-lg-5 " style=" width: 100%;">
            <div class="bg-wp" style="    border-top: 2px solid #8f98a3;">
            <h1 class=" hdrSdLblf" style="    font-weight: 700;
            color: #d94f24;
            text-transform: capitalize;text-align: center;">URBAN</h1>
            <!-- hdrTxt_sm -->
            <ul class="cards">
                <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                    <div class="card_g"  style="background:rgba(68, 168, 215, 0.1)"
                        (click)="cardOne()" [ngClass]="crdOne == true ? 'active_class' : 'normalclass'">
                        <div class="card_content">
                            <div class="">
                                <div class="icnsimg" style="  background: #488eb1;" >
                                    <img src="/assets/images/dshbrdicns/truck.png">
                                </div>
                                <div>
                                <h1 class="crdHdr">Total Vehicles</h1>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">{{d.actve_urbn_ttl === null ? "0" : d.actve_urbn_ttl}}
                                    </h1>
                                    <h1 class="card_sub_title">Active</h1>
                                </div>
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.inact_urbn_ttl === null ? "0" : d.inact_urbn_ttl}}
                                    </h1>
                                    <h1 class="card_sub_title_two">Spare</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                    <div class="card_g" style="background-color: rgba(255, 151, 102, 0.1)"
                        (click)="cardTwo()" [ngClass]="crdTwo == true ? 'active_class' : 'normalclass'">
                        <div class="card_content">
                            <!-- <div class="row">
                                <h1 class="crdHdr">Operational Status</h1>
                            </div> -->
                            <div class="">
                                <div class="icnsimg" style="     background: #bd846a;">
                                    <img src="/assets/images/dshbrdicns/system-update.png">
                                </div>
                                <div>
                                <h1 class="crdHdr">Operational Status</h1>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm crdHdr_sb_smv">{{d.u_operated === null ? "0" : d.u_operated}}<h6 class="cndHdr_sd_smvh">({{d.opper===null ? "0" :d.opper}}%)</h6>
                                    </h1>
                                    <h1 class="card_sub_title">Operated & %</h1>
                                </div>
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.u_opper === null ? "0" : d.notoperated}}
                                    </h1>
                                    <h1 class="card_sub_title_two">Not operated</h1>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <p class="crdFtr">VEHICLES TRAVELLED > 500 Meters IS CONSIDERED AS OPERATED</p>
                            </div> -->
                        </div>
                    </div>
                </li>
                <li class="cards_item" *ngFor="let d of ttlDstncCnt">
                    <div class="card_g" style="background-color: rgb(248 31 88 / 8%);"
                        (click)="cardThree()" [ngClass]="crdThree == true ? 'active_class' : 'normalclass'">
                        <div class="card_content">
                            <!-- <div class="row">
                                <h1 class="crdHdr">Vehicles Travelled</h1>
                            </div> -->
                            <div class="">
                                <div class="icnsimg" style="     background: #d17691;">
                                    <img src="/assets/images/dshbrdicns/distance.png">
                                </div>
                                <div>
                                <h1 class="crdHdr">Vehicles Travelled</h1>
                                </div>
                            </div>



                            <div class="row">
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.urbn_tdydst_abv30 === null ? "0" : d.urbn_tdydst_abv30}}
                                    </h1>
                                    <h1 class="card_sub_title">
                                        >= 30km</h1>
                                </div>
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.urbn_tdydst_blw30 === null ? "0" : d.urbn_tdydst_blw30}}
                                    </h1>
                                    <h1 class="card_sub_title_two">
                                        < 30km</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="cards_item" *ngFor="let d of ttlTrpsCnt">
                    <div class="card_g"  style="       background: rgba(97, 174, 65, 0.1);"
                        (click)="cardFour()" [ngClass]="crdFour == true ? 'active_class' : 'normalclass'">
                        <div class="card_content">
                            <!-- <div class="row">
                                <h1 class="crdHdr">Trips covered</h1>
                            </div> -->
                            <div class="">
                                <div class="icnsimg" style=" background: #26bf94;"    >
                                    <img src="/assets/images/dshbrdicns/percent.png">
                                </div>
                                <div>
                                <h1 class="crdHdr">Trips covered</h1>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.tdy_trps === null ? 0 : d.tdy_trps}}
                                    </h1>
                                    <h1 class="card_sub_title">Trips</h1>
                                </div>
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.tdy_per === null ? 0 : d.tdy_per}} %
                                    </h1>
                                    <h1 class="card_sub_title_two">Trips covered%</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="row">
                <div class="col-md-12">
                <p class="crdFtr" style="    margin-top: 15px;
    text-align: center;"><span style="color: #000;font-weight: 700;">Note</span> : Vehicles Travelled > 500 Meters is Consideres as Operated</p>
                </div>
            </div>

        </div>

        </div>



        <div class="col-lg-7 " *ngIf="crdOne==true">
            <div class="bg-wp" style="    border-top: 2px solid #8f98a3;">
            <h1 style="    font-weight: 700;
            color: #d94f24;
            text-transform: capitalize;">ULB Wise vehicles Status (URBAN)</h1>
            <div id="urbanVehiclesChart"></div>
            </div>
        </div>
        <div class="col-lg-7" *ngIf="crdTwo==true">
            <div class="bg-wp">
            <h1 style="    font-weight: 700;
            color: #d94f24;
            text-transform: capitalize;">ULB Wise vehicles Operational Status (URBAN)</h1>
            <div id="urbanoperStatusChart"></div>
        </div>
        </div>
        <div class="col-lg-7" *ngIf="crdThree==true">
            <div class="bg-wp">
            <h1 style="    font-weight: 700;
            color: #d94f24;
            text-transform: capitalize;">ULB Wise Travelled Vehicles (URBAN)</h1>
            <div id="urbantrvlVhcleChart"></div>
        </div>
        </div>
        <div class="col-lg-7" *ngIf="crdFour==true">
            <div class="bg-wp">
            <h1 style="    font-weight: 700;
            color: #d94f24;
            text-transform: capitalize;">ULB Wise Trips Covered (URBAN)</h1>
            <div id="urbantripCvrdChart"></div>
            </div>
        </div>








    </div>
    <!-- <div class="row bg-wp" *ngIf="crdOne==true">
        <h1>ULB Wise vehicles Status (URBAN)</h1>
        <div id="urbanVehiclesChart"></div>
    </div>
    <div class="row bg-wp" *ngIf="crdTwo==true">
        <h1>ULB Wise vehicles Operational Status (URBAN)</h1>
        <div id="urbanoperStatusChart"></div>
    </div>
    <div class="row bg-wp" *ngIf="crdThree==true">
        <h1>ULB Wise Travelled Vehicles (URBAN)</h1>
        <div id="urbantrvlVhcleChart"></div>
    </div>
    <div class="row bg-wp" *ngIf="crdFour==true">
        <h1>ULB Wise Trips Covered (URBAN)</h1>
        <div id="urbantripCvrdChart"></div>
    </div> -->
    <div class="row ">
        <div class="col-lg-12">
            <div class="bg-wp">
        <h1>ULB Wise Trips</h1>
        <div class=" grid tbl">
            <!-- ds-card -->
            <dx-data-grid class="p-8" [dataSource]="ulbTripsDta" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                (onToolbarPreparing)="true" (onCellPrepared)="onCellPrepared(ulbTripscolumnDefs,$event)"
                [wordWrapEnabled]="true" (onExporting)="onExporting1($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="ispagination" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ULB Trips Data"></dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <!-- <dxo-summary>
                    <dxi-total-item column="tot_veh" summaryType="sum" name="Total Vehicles">
                    </dxi-total-item>
                    <dxi-total-item column="operated" summaryType="sum" name="Online">
                    </dxi-total-item>
                    <dxi-total-item column="notoperated" summaryType="sum" name="Offline">
                    </dxi-total-item>
                    <dxi-group-item column="tnt_dsply_nm" name="Total Tenants" summaryType="count"
                        [alignByColumn]="true" [showInGroupFooter]="true">
                    </dxi-group-item>
                    <dxi-group-item column="totl_trps" name="Total Trips" summaryType="count" [alignByColumn]="true"
                        [showInGroupFooter]="true">
                    </dxi-group-item>
                    <dxi-total-item column="yet_to_strt" summaryType="sum" name="Total">
                    </dxi-total-item>
                    <dxi-total-item column="running" summaryType="sum" name="Running">
                    </dxi-total-item>
                    <dxi-total-item column="closed" summaryType="sum" name="Closed">
                    </dxi-total-item>
                    <dxi-total-item column="rd_cnt" summaryType="sum" name="Closed">
                    </dxi-total-item>
                    <dxi-total-item column="opper" summaryType="avg" name="Closed"  >
                       
                    </dxi-total-item>
                </dxo-summary> -->
                <dxo-summary>
                    <dxi-total-item *ngFor="let g of smryClmns " [column]="g.field"
                            [summaryType]="g.summaryType ? g.summaryType: ''"
                             [alignment]="g.algnmnt"  [valueFormat]="g.format ? g.format : '' ">
                          </dxi-total-item>
                </dxo-summary>
                <dxi-column *ngFor="let f of ulbTripscolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoUlb(data)">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>Quick View</p>
                        </div>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
        </div>
        </div>
    </div>
    <div class="row" *ngIf="ShowClap">

        <div class="col-lg-12">
            <div class="bg-wp">
        <h1>Vendor wise operated vehicles and operated %</h1>
        <div class=" tbl grid">
            <!-- ds-card -->
            <dx-data-grid class="p-8" [dataSource]="ulbVndrwseopratedTripsTble" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="true" (onExporting)="onExporting2($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="ulbVndrwseopratedTripsTble>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ulb wise operated Trips">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of ulbVndrwseopratedTripsTblecolumnDefs" [visible]="!f.hide"
                    [dataField]="f.field" [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width"
                    [allowResizing]="true">
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>
    </div>
    </div>

</div>