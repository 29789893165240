import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScheduleTripsRoutingModule } from './schedule-trips-routing.module';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { DxDataGridModule,DxBulletModule,DxTemplateModule, DxButtonModule, DxNumberBoxModule,
  DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule, DxChartModule, DxPieChartModule,DxTabsModule,DxLoadPanelModule, DxListModule, DxDiagramModule} from 'devextreme-angular';
// import { DxDataGridModule, DxBulletModule, DxTemplateModule } from 'devextreme-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NgChartsModule } from 'ng2-charts';
import { TemplateModule } from '../../shared/template/template.module';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ScheduleTripsComponent } from './schedule-trips.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationAddModule } from '../location/location-add/location-add/location-add.module';
import { SvmTripScheduleComponent } from './svm-trip-schedule/svm-trip-schedule.component';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { TripOperationsComponent } from './trip-operations/trip-operations.component';

@NgModule({
  declarations: [ScheduleTripsComponent, SvmTripScheduleComponent, TripOperationsComponent],
  imports: [
    ScheduleTripsRoutingModule,    CommonModule,
    
    // --  DXmodules -- //
    DxDataGridModule,DxBulletModule,DxTemplateModule,DxButtonModule,
    DxNumberBoxModule,DxChartModule,DxSelectBoxModule,DxCheckBoxModule,
    DxTabPanelModule,DxPieChartModule,DxTabsModule,DxLoadPanelModule,
   
    SharedModule,
     // -- NZModules --//
     NzIconModule,
     NzButtonModule,NzCardModule,NzAvatarModule,NzRateModule,NzBadgeModule,NzSkeletonModule,
     NzProgressModule,NzRadioModule,NzDropDownModule,NzTimelineModule,NzTabsModule,
     NzTagModule,NzListModule,NzCalendarModule,NzToolTipModule,NzFormModule,NzModalModule,
     NzSelectModule,NzUploadModule,NzInputModule,NzPaginationModule,NzDatePickerModule,NzResultModule,NzAutocompleteModule,
     NzCheckboxModule,NzMessageModule,NzLayoutModule, NzGridModule, NzDividerModule, NgChartsModule, TemplateModule,NzTimePickerModule ,

     FormsModule,ReactiveFormsModule,LocationAddModule,NzStepsModule 
  ],
  exports: [ CommonModule ], //add common module here
  providers: [ NzModalService,],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class ScheduleTripsModule { }
