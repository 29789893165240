import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { AppsService } from 'src/app/shared/services/apps.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-weighment-dashboard',
  templateUrl: './weighment-dashboard.component.html',
  styleUrls: ['./weighment-dashboard.component.scss']
})
export class WeighmentDashboardComponent implements OnInit,OnChanges {
  @Input() tenant;
  selectedtenant: any;
  slctdDt = new Date();
  curDate;
  showLdr: boolean;
  wghmntSmry: any=[];
  today = new Date();
  dateFormat = 'dd/MM/yyyy';
  wghmntDtls: any[];
  columnDefs:any=[];
  constructor(public apiServ: AppsService, ) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedtenant=changes.tenant.currentValue;
  }

  ngOnInit(): void {
    console.log(this.selectedtenant)
    this.getWghmntCrdSummary(this.slctdDt, this.selectedtenant);
    this.getWghmntDetails(this.slctdDt, this.selectedtenant);

  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  onChange(e): void {
    this.slctdDt = e;
    // this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.getWghmntCrdSummary(this.slctdDt, this.selectedtenant);
    this.getWghmntDetails(this.slctdDt, this.selectedtenant);

  }
  getWghmntCrdSummary(funDt?, slctTnt?){
    this.wghmntSmry = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/getWghmntSmry`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
          this.wghmntSmry = res['data'];
          console.log(this.wghmntSmry)
          this.wghmntSmry.forEach(element => {
            element.lnrBgcd="linear-gradient(135deg, "+element.bgclr1+" 0%, "+element.bgclr2+" 100%)";
          });
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  getWghmntDetails(funDt?, slctTnt?){
    this.wghmntDtls = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/getWghmntDtls`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
          this.wghmntDtls = res['data'];
          console.log(this.wghmntDtls)
        this.showLdr = false;
        this.columnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'Vehicle Registration Number', field: 'asrt_nm', alignment: 'left', width: 'auto' },
          { headerName: 'RFID Tag', field: 'rfid_tge_id', alignment: 'center', width: 200},
          { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'left', width: 'auto' },
          { headerName: 'Vehicle Type', field: 'type', alignment: 'left', width: 'auto' },
          { headerName: 'Weighment Date', field: 'wghmnt_dt', alignment: 'left', width: 'auto' },
          { headerName: 'Weighment Location', field: 'fnce_nm', alignment: 'left', width: 'auto' },
          { headerName: 'Weighment Scanner', field: 'scnr_cd', alignment: 'left', width: 'auto' },
          { headerName: 'Gross Weight(Kg)', field: 'grs_wt', alignment: 'right', width: 'auto' },
          { headerName: 'Tare Weight(Kg)', field: 'tr_wt', alignment: 'right', width: 'auto' },
          { headerName: 'Net Weight(Kg)', field: 'nt_wt', alignment: 'right', width: 'auto' },
          { headerName: 'Target Weight(Kg)', field: 'trgt_wt', alignment: 'right', width: 'auto' },
          { headerName: 'Weighment %', field: 'wghmnt_per', alignment: 'right', width: 'auto' },

        ]
      }
    }, (err) => {
      // console.log(err);
    });
  }
}
