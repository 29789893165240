<div class="row hdrBar">
    <div class="col-lg-9 col-md-9 mg-a">
     
    </div>
    <div class="col-lg-3 col-md-3 mg-a">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
</div>
<div class="main">
    <div class="row" *ngIf="wghmntSmry&&wghmntSmry.length>0">
        <ul class="cards" >
            <li class="cards_item" *ngFor="let w of wghmntSmry">
                <div class="card_g" 
                [ngStyle]="{'background': w.lnrBgcd}">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">{{w.type==null?'':w.type}}</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{w.wghmnt==null?0:w.wghmnt}}
                                </h1>
                                <h1 class="card_sub_title">weighment in Kgs</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{w.trgt_wt==null?0:w.trgt_wt}}
                                </h1>
                                <h1 class="card_sub_title">Target Weighment</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{w.wghmnt_cvrg==null?0:w.wghmnt_cvrg}} %
                                </h1>
                                <h1 class="card_sub_title">Weighment %</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
          
        </ul>
        <div class="row bg-wp">
            <h1 style="color:#2656ff;font-size:16px;">Weighment</h1>
            <div class="ds-card tbl grid">
    
                <dx-data-grid class="p-8" [dataSource]="wghmntDtls" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                    [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                    [wordWrapEnabled]="true">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [visible]="wghmntDtls.length>10" [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                    </dxo-search-panel>
                    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                    <dxo-group-panel [visible]="true"></dxo-group-panel>
                    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                    <dxo-column-chooser [enabled]="true" mode="select">
                    </dxo-column-chooser>
                    <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ulb wise operated Trips">
                    </dxo-export>
                    <dxo-filter-row [visible]="false"></dxo-filter-row>
                    <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field"
                        [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                    </dxi-column>
    
                </dx-data-grid>
            </div>
        </div>
       
    </div>
    <div *ngIf="wghmntSmry&&wghmntSmry.length==0" class="text-center">
        No Data found for Selected Date
    </div>
</div>

