import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-help-faqs',
  templateUrl: './help-faqs.component.html',
  styleUrls: ['./help-faqs.component.scss']
})
export class HelpFaqsComponent implements OnInit {
  faqsgroup;
  selectedGroupId;
  faqsqstns;
  columnDefs;
  rowData;
  shwSidebar;
  permissions = { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 }
  heading;
  editClicked;
  editdata;
  validateForm!: FormGroup;
  loader: boolean;

  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(public apiSrv: AppsService, private fb: FormBuilder, private modal: NzModalService) { }

  ngOnInit() {
    this.validateForm = this.fb.group({
      fq_tx: [null, [Validators.required]],
      fq_answr_tx: [null],
      fq_grp_id: [null, [Validators.required]]
    });
    this.getfaqsgrps();
  }
  getfaqsgrps() {
    let rte = `kb/faq/groups`;
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          //   //   console.log(res)
          this.faqsgroup = res['data'];
          this.selectedGroupId = 1;
          this.onFaqGrpSelection()
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    })
  }
  onFaqGrpSelection() {
    let rte = `kb/faq/groups/${this.selectedGroupId}`;
    this.apiSrv.get(rte).subscribe(res => {
      //   //   console.log(res)
      if (res['data'].length > 0) {
        this.faqsqstns = res['data'];
        this.rowData = this.faqsqstns;
      }
      else {
        this.faqsqstns = [];
        this.rowData = this.faqsqstns;
      }
    })
    this.columnDefs = [
      { headerName: 'Sno', field: 'sno', alignment: 'center', width: 100, filter: false },
      { headerName: 'FAQ Question', alignment: 'center', width: 200, field: 'fq_tx' },
      { headerName: 'FAQ Answer', alignment: 'center', width: 200, field: 'fq_answr_tx' },
      { headerName: 'FAQ Group', field: 'fq_grp_nm', width: 200, alignment: 'left' },
    ]
  }
  onToolbarPreparing(e) {
    //   console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(key, data) {
    this.shwSidebar = true;
    //   console.log(key)
    //   console.log(data)
    if (key == 'new') {
      this.heading = "Create New page";
      this.editClicked = false;
      this.validateForm.get('fq_tx').setValue('');
      this.validateForm.get('fq_answr_tx').setValue('');
      this.validateForm.get('fq_grp_id').setValue('');
    }
    else if (key == 'edit') {
      this.heading = "Edit page Details";
      this.editClicked = true;
      this.editdata = data
      this.validateForm.get('fq_tx').setValue(data.fq_tx);
      this.validateForm.get('fq_answr_tx').setValue(data.fq_answr_tx);
      this.validateForm.get('fq_grp_id').setValue(data.fq_grp_id);
    }
  }
  closeSideBar() {
    this.shwSidebar = false
  }
  onDelete2(event) {
    let data = event.data
    this.modal.confirm({
      nzTitle: 'Are you sure delete this section?',
      nzContent: `<b style="color: red;">${data.fq_tx}</b>`,
      nzOkText: 'Yes',
      // nzOkType: 'danger',
      nzOkDanger:true,
      nzOnOk: () => this.questiondelete(data),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }
  questiondelete(data) {
    let rte = `kb/delete/deletequestion`;
    this.apiSrv.create(data, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.closeSideBar();
        this.modal.success({
          nzTitle: 'delete question',
          nzContent: 'Sucessfully deleted'
        });
      }
    })
  }
  onEdit2(event) {
    this.editdata = event.data
    this.openSideBar("edit", this.editdata)
  }
  submit() {
    let rte = `kb/faqs`;
    //   console.log(this.validateForm.value)
    this.apiSrv.create(this.validateForm.value, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.modal.success({
          nzTitle: 'New Page Creation',
          nzContent: 'Sucessfully created'
        });
      }
    })
  }
  update() {
    let rte = `kb/faqs/`+this.editdata.fq_id;
    //   console.log(this.validateForm.value)
    this.validateForm.value['fq_id'] = this.editdata.fq_id;
    this.apiSrv.update(this.validateForm.value, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] == 200) {
        this.modal.success({
          nzTitle: 'New Page Creation',
          nzContent: 'Sucessfully created'
        });
      }
    })
  }
}
