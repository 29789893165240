import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as L from 'leaflet';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from 'src/app/providers/api.service';

@Component({
  selector: 'app-location-add',
  templateUrl: './location-add.component.html',
  styleUrls: ['./location-add.component.scss']
})
export class LocationAddComponent implements OnChanges {
  @Input() tntId;
  tnt_id;
  marker;
  slctdLat;
  slctdLng;
  tpGrpLst: any;
  addFncForm: FormGroup;
  shwErrMsg: boolean;
  fncGrpHndlr: any;
  aryLatlng;
  map;
  moveMarker;
  drgndLat;
  drgndLng;
  drgn: boolean;
  circle: any;
  radius: number;
  lati: any;
  long: any;
  fnce_nm: any;
  fnce_grp: any;
  rds: any;
  usrDtls: any;
  tntLst:any=[];
  constructor(private apiServ: ApiService, private message: NzMessageService,private modal: NzModalService) { }
  ngOnChanges(changes: SimpleChanges) {
    this.tnt_id = changes.tntId.currentValue;
    console.log(changes)
    setTimeout(() => {
      this.initialize();
    }, 1000);
    // this.addFncForm = new FormGroup({
    //   fnce_nm: new FormControl('', Validators.required),
    //   // lat: new FormControl('', Validators.required),
    //   // lng: new FormControl('', Validators.required),
    //   fnce_grp: new FormControl('', Validators.required),
    //   rds: new FormControl('', Validators.required),
    // });
    this.getAllMstrData();
  }
  initialize = function () {
    this.tntLst=[];
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS ----------//
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.usrDtls);
    console.log(this.usrDtls[0].lat, this.usrDtls[0].lng);
    // if(this.usrDtls[0].lat && this.usrDtls[0].lng){
    //   this.drgndLat=this.usrDtls[0].lat;
    //   this.drgndLng=this.usrDtls[0].lng
    // }else{
    //   this.drgndLat=16.000;
    //   this.drgndLng= 80.00;
    // }
    if(this.marker){
      this.map.removeLayer(this.marker)
    }
    if(this.map){
      this.map.removeLayer(this.map)
    }
    this.tntLst = this.usrDtls[0].tnts;    
    if (this.tnt_id != 0) {
      for (var i = 0; i < this.tntLst.length; i++) {
        if (this.tntLst[i].tnt_id == this.tnt_id) {
          this.drgndLat=this.tntLst[i].lat;
          this.drgndLng=this.tntLst[i].lng;
        }
      }
    } else {
      this.drgndLat=15.912327;
      this.drgndLng=79.739238;
    }
    try {

      this.map = L.map('lctnmap', {
        // center: [16.000, 80.00],
        center: [this.drgndLat, this.drgndLng],
        zoom: 11,
      //   fullscreenControl: {
      //     pseudoFullscreen: false // if true, fullscreen to page width and height
      // }      
    });

      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   // maxZoom: 18,
      //   // minZoom: 3,
      //   attribution: ''
      // });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: ''
      });

      tiles.addTo(this.map)
      // .getCenter();
    //   this.map.addControl(new L.Control.Fullscreen());
    //   this.map.addControl(new L.Control.Fullscreen({
    //     title: {
    //         'false': 'View Fullscreen',
    //         'true': 'Exit Fullscreen'
    //     }
    // }));
    } catch (e) {
      // console.log("please check map Initialization of leaflet:" + e);
    }
    this.map.setView([this.drgndLat, this.drgndLng],11);

    const myIcon = L.icon({
      iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/location.png',
      iconSize: [33, 33],
      iconAnchor: [16, 28],
    });
    this.marker = L.marker([this.drgndLat, this.drgndLng], {
      icon: myIcon,
      draggable: true,
    }).addTo(this.map);
    this.marker.on('dragend', () => {
      this.drgndLat = this.marker.getLatLng().lat;
      this.drgndLng = this.marker.getLatLng().lng;
      this.drgn = true;
      this.fence();
    });
    // this.map.addControl(new L.Control.Fullscreen());

  }
  fence() {
    if (this.drgn == true) {
      this.lati = this.drgndLat; this.long = this.drgndLng;
    }
    else {
      this.lati = this.drgndLat; this.long = this.drgndLng;
    }
  }
ondrag(){
  this.drgn = true;
  this.map.setView([this.drgndLat, this.drgndLng]).getCenter();
  this.marker.setLatLng([this.drgndLat, this.drgndLng]);

}
  getAllMstrData() {
    this.apiServ.get(`tripschedule/trips/schedule/dropdown/master/${this.tnt_id}`).subscribe((res) => {
      this.tpGrpLst = res['data'].transfrPointLst;

    });
  }
  addNewFnc() {
    // console.log(this.slctdLat, this.slctdLng);
    this.tpGrpLst.forEach(element => {
      if (element.fnce_grp_id == this.fnce_grp) {
        this.fncGrpHndlr = element.fnce_hndlr_tx;
      }
    });
    if (this.fnce_nm && this.fnce_grp && this.drgndLat && this.drgndLng && this.rds) {
      let postData = {
        fncs_nm: this.fnce_nm,
        fncsGrpId: this.fnce_grp,
        lat: this.drgndLat,
        lng: this.drgndLng,
        tnt_id: this.tnt_id,
        rds: this.rds,
        fnce_hndlr_tx: this.fncGrpHndlr
      }
      // console.log(postData)
      this.apiServ.post(postData, 'tripschedule/trips/schedule/fnce').subscribe((res) => {
        if (res['status'] == 200) {
          if(res['data']=='Location Added Successfully'){
            this.createMessage('success', res['data']);
          }else{
            this.modal.confirm({
              nzTitle: 'Are you sure Add this Location?',
              nzContent:res['data'],
              nzOkText: 'Yes',
              nzOkType: 'primary',
              nzOnOk: () => this.addAsNewLctn(),
              nzCancelText: 'No',
              nzOnCancel: () => this.cancel(),
            });
          }
        }
      })
    } else this.shwErrMsg = true;
  }
  addAsNewLctn(){
    if (this.fnce_nm && this.fnce_grp && this.drgndLat && this.drgndLng && this.rds) {
      let postData = {
        fncs_nm: this.fnce_nm,
        fncsGrpId: this.fnce_grp,
        lat: this.drgndLat,
        lng: this.drgndLng,
        tnt_id: this.tnt_id,
        rds: this.rds,
        fnce_hndlr_tx: this.fncGrpHndlr
      }
      // console.log(postData)
      this.apiServ.post(postData, 'tripschedule/trips/schedule/fnce/add').subscribe((res) => {
        if (res['status'] == 200) {
            this.createMessage('success', res['data']);
          }
      });
  }
}
  cancel(): void {
    this.message.info('Request Canceled');
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  reset() {
    this.fnce_nm='';
    this.fnce_grp='';
    this.drgndLat='';
    this.drgndLng='';
    this.rds='';
  }
}
