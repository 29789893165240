import { Component } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { Router } from '@angular/router';
import { UserService } from './providers/user/user.serivce';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    selectedHeaderColor: string;
    empdata: any;
    isFolded: boolean;
    isSideNavDark: boolean;
    isExpand: boolean;
    url: string;
    full_screen: boolean;
    clntDtls: any;
    constructor(private router: Router, public userService: UserService, public apiSrv: AppsService, private themeService: ThemeConstantService) {
        this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    }
    ngOnInit(): void {
        this.userService.userLoggedIn.subscribe((event: any) => {
            console.log(event);
            if (event == true)
                this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
            else this.clntDtls = null;
        });
        this.url = this.router.url;
        if (this.router.url == "/") this.full_screen = true;
        else this.full_screen = false;
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
        this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    }
}