import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-svm-door-to-door-clctn',
  templateUrl: './svm-door-to-door-clctn.component.html',
  styleUrls: ['./svm-door-to-door-clctn.component.scss']
})
export class SvmDoorToDoorClctnComponent implements OnChanges {
  @Input() svm;
  rCount: number;
  rScCount: number;
  aCount: number;
  aScCount: number;
  rcCount: number;
  rcScCount: number;
  bcCount: number;
  bcScCount: number;
  today: any = new Date().toISOString().slice(0, 10);
  svmId: any;
  strtWsegtsScndLst;
  getsvmSvnDaysScndGts;
  latlng: any;
  mapData: any[];
  markerIcon1: { icon: L.Icon<L.IconOptions>; };
  map1: any;
  visible: boolean;
  notScndGts: any = [];
  columnDefs: any = [];
  chart: am4charts.XYChart;
  chartsData: any;
  gateNu: any;
  gateNm: any;
  numOfRs: any;
  numOfPl: any;
  scanned: string;
  markerIcon: { icon: any; };
  map: any;
  houseInfo: any;
  mnth: number;
  owms_svm_id: any;
  mnthName;
  svm_apfs_cd: number;
  // barData1: any = [];
  // barData2: any = [];
  // barXaxis: any = [];
  // barChartData: ChartDataSets[] = [
  //   { data: [], label: 'Not Scanned Gates', stack: 'a' },
  //   { data: [], label: 'Scanned Gates', stack: 'a' }
  // ];
  constructor(public apiSrv: AppsService,) { }

  // ngOnInit(): void {
  //   // console.log(this.svm)
  //   // this.svmId=this.svm;
  //   // this.getSachivalayamFenceData();
  //   // this.getStrtWseGatesScanned();
  //   // this.getsvmSvnDysScndGts();
  //   // this.getSvmNtScndGts();

  // }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.svm.currentValue);
    this.svmId = changes.svm.currentValue.svm_id;
    this.owms_svm_id = changes.svm.currentValue.owms_svm_id;
    this.svm_apfs_cd = changes.svm.currentValue.svm_apfss_cd;
    //  this.svm_apfs_cd=1064022;
    console.log(this.svmId, this.owms_svm_id)
    this.getSachivalayamFenceData();
    this.getStrtWseGatesScanned();
    this.getsvmSvnDysScndGts();
    this.getSvmNtScndGts();
  }
  getStrtWseGatesScanned() {
    // const rte = 'support/svm/strtwise/gatesScannedData';
    const rte = 'external/owms/sachivalayam/street-wise/gates-scan';
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      svm_id: this.owms_svm_id,
      svm_apfs_cd: this.svm_apfs_cd,
      to_dt: this.today
    }
    //   console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {
      this.rCount = 0;
      this.rScCount = 0;
      this.aCount = 0;
      this.aScCount = 0;
      this.rcCount = 0;
      this.rcScCount = 0;
      this.bcCount = 0;
      this.bcScCount = 0;
      if (res['status'] == 200) {
        console.log(res['data']);
        this.strtWsegtsScndLst = res['data'];
        for (let i = 0; i < this.strtWsegtsScndLst.length; i++) {
          for (let k = 0; k < this.strtWsegtsScndLst[i]['gates'].length; k++) {
            if (this.strtWsegtsScndLst[i]['gates'][k].gte_ctgry_nm === 'Residential') {
              this.rCount++;
              if (this.strtWsegtsScndLst[i]['gates'][k].isScanned === 1) {
                this.rScCount++;
              }

            } else if (this.strtWsegtsScndLst[i]['gates'][k].gte_ctgry_nm === 'Apartment') {
              this.aCount++;
              if (this.strtWsegtsScndLst[i]['gates'][k].isScanned === 1) {
                this.aScCount++;
              }
            } else if (this.strtWsegtsScndLst[i]['gates'][k].gte_ctgry_nm === 'Residential Commercial') {
              this.rcCount++;
              if (this.strtWsegtsScndLst[i]['gates'][k].isScanned === 1) {
                this.rcScCount++;
              }
            } else if (this.strtWsegtsScndLst[i]['gates'][k].gte_ctgry_nm === 'Bulk Commercial') {
              this.bcCount++;
              if (this.strtWsegtsScndLst[i]['gates'][k].isScanned === 1) {
                this.bcScCount++;
              }
            }
          }
        }

      }
      else {

        //   this.toastr.error( res["message"], '', {
        //    timeOut: 10000,
        //    positionClass: 'toast-top-right',
        //  });
      }
    })
  }
  getsvmSvnDysScndGts() {
    // const rte = "support/svm/svmSvnDysScndGts";
    const rte = "external/owms/sachivalayam/seven-days/gates-scan"
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      svm_id: this.owms_svm_id,
      svm_apfs_cd: this.svm_apfs_cd,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.getsvmSvnDaysScndGts = res['data'];
        console.log(this.getsvmSvnDaysScndGts);
        //   for (let i = this.getsvmSvnDysScndGts.length - 1; i >= 0; i--) {
        //     this.barData1.push(this.getsvmSvnDysScndGts[i].tot_gts - this.getsvmSvnDysScndGts[i].scnr_gts);
        //     this.barData2.push(this.getsvmSvnDysScndGts[i].scnr_gts);
        //     this.barXaxis.push(this.getsvmSvnDysScndGts[i].dt_nm);
        //   }
        //   // this.barData1=this.barData1.reverse();
        //   // this.barData2=this.barData2.reverse();
        //   // this.barXaxis=this.barXaxis.reverse();
        //   this.barChartData = [
        //     { data: [...this.barData2], label: 'Scanned Gates', stack: 'a' },
        //     { data: [...this.barData1], label: 'Not Scanned Gates', stack: 'a' }

        //   ];
        //   this.barChartLabels = [...this.barXaxis];
        //   this.barChartLabelsBig = [this.barXaxis[this.barXaxis.length - 1]];
        //   //   console.log(this.barChartData);
        // }
        // else {

        //   this.toastr.error( res["message"], '', {
        //    timeOut: 10000,
        //    positionClass: 'toast-top-right',
        //  });
        this.chartsData = this.getsvmSvnDaysScndGts;
        let chart1 = am4core.create('gatesChart', am4charts.XYChart);
        chart1.data = this.chartsData;

        let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis1.dataFields.category = 'dt_nm';
        categoryAxis1.renderer.grid.template.location = 0;
        categoryAxis1.renderer.minGridDistance = 10;
        categoryAxis1.renderer.labels.template.rotation = 0;
        categoryAxis1.renderer.labels.template.fontSize = 10;
        categoryAxis1.startLocation = 0;
        categoryAxis1.endLocation = 0;
        categoryAxis1.title.text = ` ----- Date  ----- `;

        let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
        // valueAxis1.renderer.inside = true;
        // valueAxis1.renderer.labels.template.disabled = true;
        // valueAxis1.min = 0;
        valueAxis1.title.text = '-- Gates -- ';

        // Create series
        function createSeries(field, name, clr) {

          // Set up series
          let series1 = chart1.series.push(new am4charts.ColumnSeries());
          series1.name = name;
          series1.dataFields.valueY = field;
          series1.dataFields.categoryX = 'dt_nm';
          series1.sequencedInterpolation = true;
          series1.fill = am4core.color(clr);

          // Make it stacked
          series1.stacked = true;

          // Configure columns
          series1.columns.template.width = am4core.percent(60);
          series1.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series1.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;

          return series1;

        }
        chart1.legend = new am4charts.Legend();


        createSeries('scnr_gts', 'Scanned Gates', '#185aad');
        createSeries('tot_gts', 'Not Scanned Gates', '#e0cd28');

      }
    })
  }
  initializeMapOptions1() {
    //   console.log(house);
    //   console.log("In Mapppppp");

    var map1 = L.map('map1', {
    })
    map1.remove();


    this.map1 = L.map('map1').setView([16.708785, 81.108350], 14);
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    //   maxZoom: 19,

    // }).addTo(this.map1);
    L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 19,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: ''
    }).addTo(this.map1);

    this.markerIcon1 = {
      icon: L.icon({
        iconSize: [30, 30],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: `../../../../assets/images/app-store/map`
      })
    };
    var latlngs = [[17.000045, 81.798557], [17.001451, 81.802967], [17.001985, 81.807849],
    [16.999133, 81.801809], [17.000045, 81.798557]];

    var polygon = L.polygon(this.mapData as [], { color: '#f17c7c' }).addTo(this.map1);
    // zoom the map to the polygon
    this.map1.fitBounds(polygon.getBounds());



  }
  getSachivalayamFenceData() {
    console.log(this.svmId)
    const rte = `external/owms/sachivalayams/fence/${this.svm_apfs_cd}`;

    this.apiSrv.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        console.log(res['data'][0])
        this.latlng = res['data'][0]['fence'].substring(res['data'][0]['fence'].indexOf("((") + 2, res['data'][0]['fence'].indexOf("))")).split(",");
        this.mapData = [];
        for (let i = 0; i < this.latlng.length; i++) {
          let data = this.latlng[i].split(" ").reverse();
          data = data.filter(e => {
            return e != "";
          })
          this.mapData.push(data);
        }

        this.initializeMapOptions1();

      }

    });
  }
  close1(): void {
    this.visible = false;
    this.map1.off();
    this.map1.remove();


  }
  getSvmNtScndGts() {
    // const rte = "support/svm/svmNtScndGts"
    const rte = 'external/owms/sachivalayam/gates-not-scan'
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      svm_id: this.owms_svm_id,
      svm_apfs_cd: this.svm_apfs_cd,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    // this.apiSrv.get(rte).subscribe((res) => {
    this.apiSrv.post(postdata, rte).subscribe((res) => {
      //console.log(res);

      if (res['status'] == 200) {

        this.notScndGts = res['data'];
        //   console.log(this.notScndGts);

        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'Street Name', field: 'strt_nm', alignment: 'left', width: 160, filter: false },
          { headerName: 'RFID Tag', alignment: 'left', width: 70, field: 'tge_nm' },
          { headerName: 'Gate No', alignment: 'center', width: 80, field: 'gte_hse_nu' },
          { headerName: 'Gate Category', field: 'gte_ctgry_nm', width: 100, alignment: 'left', hide: true }
        ]
      }
      else {

      }
    })

  }
  getHouseInfo(gate_id) {
    // const rte = "support/micropocket/getHouseInfo";
    const rte = 'external/owms/sachivalayam/house-info';
    this.mnth = new Date().getMonth() + 1;
    let postdata = {
      dt: this.mnth,
      gte_id: gate_id
    }
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.houseInfo = res['data'];
        console.log(this.houseInfo);

      }
      else {


      }
    })

  }

  open(house): void {
    //   console.log(house)
    this.visible = true;
    this.gateNu = house.gte_hse_nu;
    this.gateNm = house.gte_nm;
    this.numOfRs = house.hse_hlds_ct;
    this.numOfPl = house.ppltn_ct;
    this.scanned = house.isScanned == 1 ? "YES" : (house.isScanned == 0 ? "NO" : "");
    this.getHouseInfo(house.gte_id);
    // if(this.map){
    //   this.map.removeLayer();
    // }
    setTimeout(() => {
      this.initializeMapOptions(house);

    }, 1000);

  }
  initializeMapOptions(house) {
    // console.log(house);
    //   console.log("In Mapppppp");
    try {
      this.map = L.map('map')
      // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      //   maxZoom: 19,

      // })
      L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 19,
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: ''
      }).addTo(this.map);
      this.map.setView([house.lat, house.lng], 14);
      this.markerIcon = {
        icon: L.icon({
          iconSize: [35, 38],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40],
          iconUrl: `../../../../assets/images/app-store/home.png`
        })
      };

      var marker = L.marker([house.lat, house.lng], this.markerIcon).addTo(this.map);
    } catch (error) {

    }



  }

  close(): void {
    this.visible = false;
    this.map.off();
    this.map.remove();


  }

}
