import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';

import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule, DxNumberBoxModule,
         DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule, DxChartModule, DxPieChartModule, 
         DxTabsModule, DxLoadPanelModule , DxDiagramModule} from 'devextreme-angular';
import { NgChartsModule } from 'ng2-charts';
import { TemplateModule } from '../../shared/template/template.module';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { ExportService } from 'src/app/services/export.service';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { AmsVehiclesRoutingModule } from './ams-vehicles-routing.module';
import { AmsVehiclesComponent } from './ams-vehicles.component';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleSliderModule } from '../dashboards/enterprise/vehicle-slider/vehicle-slider.modules';
import { VehicleAsgnmentComponent } from './vehicle-asgnment/vehicle-asgnment.component';
import { AssignDeviceComponent } from './assign-device/assign-device.component';
import { DistnceAprvlComponent } from './distnce-aprvl/distnce-aprvl.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { VhcleUlbAsgnmntComponent } from './vhcle-ulb-asgnmnt/vhcle-ulb-asgnmnt.component';
import { VhcleSachivalayamAsgnmntComponent } from './vhcle-sachivalayam-asgnmnt/vhcle-sachivalayam-asgnmnt.component';
import { AddVechileComponent } from './vehicle-asgnment/add-vechile/add-vechile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ AmsVehiclesComponent, VehicleAsgnmentComponent, AssignDeviceComponent, DistnceAprvlComponent, VhcleUlbAsgnmntComponent, VhcleSachivalayamAsgnmntComponent, AddVechileComponent ],
  exports: [ AmsVehiclesComponent ],
  imports: [
    CommonModule,
    // --  DXmodules -- //
    DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule,
    DxNumberBoxModule, DxChartModule, DxSelectBoxModule, DxCheckBoxModule,
    DxTabPanelModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule,
    SharedModule,
     // -- NZModules --//
     NzIconModule, NzDrawerModule,
     NzButtonModule, NzCardModule, NzAvatarModule, NzRateModule, NzBadgeModule,
     NzProgressModule, NzRadioModule,  NzDropDownModule, NzTimelineModule, NzTabsModule,
     NzTagModule, NzListModule, NzCalendarModule, NzToolTipModule, NzFormModule, NzModalModule,
     NzSelectModule, NzUploadModule, NzInputModule, NzPaginationModule, NzDatePickerModule, NzSwitchModule,
     NzCheckboxModule, NzMessageModule, NzLayoutModule, NzGridModule, NzDividerModule,
     NgChartsModule, TemplateModule, AmsVehiclesRoutingModule, FormsModule,ReactiveFormsModule,VehicleSliderModule,NzTimePickerModule
  ],
  providers: [ExportService,TransfereService,
  ]
})
export class AmsVehiclesModule { }
