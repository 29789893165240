<ds-loading *ngIf="showLdr"></ds-loading>
<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visibleVhclSldr" nzPlacement="right"
    nzTitle="Vehicle Dashboard" (nzOnClose)="visibleVhclSldr = false" class="dashbSlider">
    <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
</nz-drawer>
<div class="row">
    <div class="col-lg-12 col-md-12 mg-a">
        <div class="row hdrBar">
            <div class="col-lg-12 col-md-12 mg-a">
                <h1>Distance Approval</h1>
            </div>
        </div>
    </div>
</div>
<div class="fltrbg">
    <div class="row">
        <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst.length>1">
            <p class="slctlbl">Select ULB</p>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" nzPlaceHolder="Select ULB"
                (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}">
                <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
            </nz-select>
        </div>
        <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst.length==1">
            <p class="slctlbl">ULB</p>
            <p class="slctlbl">{{tntLst[0].tnt_nm}}</p>
        </div>
        <div class="col-lg-2 col-md-2 crdCl">
            <span>Select Date:</span>
            <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                (ngModelChange)="onChange($event)"></nz-date-picker>
        </div>
    </div>
</div>
<div class="main">
    <div class="row bg-wp">
        <h1 style="font-size: 20px;">Distance Updated Vehicles</h1>
        <nz-tabset nzType="card" (nzSelectChange)="tabchange($event)">
            <nz-tab nzTitle="Pending">
                <div class="ds-card grid tbl">
                    <dx-data-grid id="gridContainer" [dataSource]="unaprvdData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                        (onToolbarPreparing)="true" [wordWrapEnabled]="true">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager [visible]="unaprvdData.length>10" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 20, 30, 50]" [showInfo]="true">
                        </dxo-pager>
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                        </dxo-search-panel>
                        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                        <dxo-column-chooser [enabled]="true" mode="select">
                        </dxo-column-chooser>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-export [enabled]="true" fileName="vehicle distance approved Data"></dxo-export>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [caption]="d.headerName"
                            [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                        </dxi-column>
                        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100"
                            caption="Approve" cellTemplate="editTemplate">
                            <div *dxTemplate="let data of 'editTemplate'">
                                <div class="tblEdtBtn" (click)="approve(data);">Approve</div>
                            </div>
                        </dxi-column>
                        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100"
                            caption="Reject" cellTemplate="editTemplate2">
                            <div *dxTemplate="let data of 'editTemplate2'">
                                <div class="tblEdtBtn" (click)="rejectslider(data);">Reject</div>
                            </div>
                        </dxi-column>
                        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100"
                            caption="View" cellTemplate="editTemplate1">
                            <div *dxTemplate="let data of 'editTemplate1'">
                                <div class="tblEdtBtn" (click)="getVehDashSldr(data);"><i nz-icon nzType="car"
                                        nzTheme="outline"></i></div>
                            </div>
                        </dxi-column>
                    </dx-data-grid>

                </div>
            </nz-tab>
            <nz-tab nzTitle="Approved">
                <div class="ds-card grid tbl">
                    <dx-data-grid id="gridContainer" [dataSource]="aprvdData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                        (onToolbarPreparing)="true" [wordWrapEnabled]="true">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager [visible]="aprvdData.length>10" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 20, 30, 50]" [showInfo]="true">
                        </dxo-pager>
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                        </dxo-search-panel>
                        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                        <dxo-column-chooser [enabled]="true" mode="select">
                        </dxo-column-chooser>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-export [enabled]="true" fileName="vehicle distance approved Data"></dxo-export>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxi-column *ngFor="let d of columnDefs1" [dataField]="d.field" [caption]="d.headerName"
                            [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                        </dxi-column>
                        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100"
                            caption="View" cellTemplate="editTemplate1">
                            <div *dxTemplate="let data of 'editTemplate1'">
                                <div class="tblEdtBtn" (click)="getVehDashSldr(data);"><i nz-icon nzType="car"
                                        nzTheme="outline"></i></div>
                            </div>
                        </dxi-column>
                    </dx-data-grid>

                </div>
            </nz-tab>
            <nz-tab nzTitle="Rejected">
                <div class="ds-card grid tbl">
                    <dx-data-grid id="gridContainer" [dataSource]="rjctData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                        (onToolbarPreparing)="true" [wordWrapEnabled]="true">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager [visible]="rjctData.length>10" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 20, 30, 50]" [showInfo]="true">
                        </dxo-pager>
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                        </dxo-search-panel>
                        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                        <dxo-column-chooser [enabled]="true" mode="select">
                        </dxo-column-chooser>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-export [enabled]="true" fileName="vehicle distance approved Data"></dxo-export>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxi-column *ngFor="let d of columnDefs2" [dataField]="d.field" [caption]="d.headerName"
                            [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                        </dxi-column>
                        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100"
                            caption="View" cellTemplate="editTemplate1">
                            <div *dxTemplate="let data of 'editTemplate1'">
                                <div class="tblEdtBtn" (click)="getVehDashSldr(data);"><i nz-icon nzType="car"
                                        nzTheme="outline"></i></div>
                            </div>
                        </dxi-column>
                    </dx-data-grid>

                </div>
            </nz-tab>
        </nz-tabset>
    </div>

</div>
<nz-drawer [nzWidth]="'20%'" [nzClosable]="true" [nzVisible]="vsbleRjct" nzPlacement="right"
    nzTitle="Reject Distance Approval" (nzOnClose)="vsbleRjct = false" class="dashbSlider">
    <div class="row drvrDtlsfrm">
        <div class="vehicleno">
            <div class="row vhcl">
                <div class="col-md-8 vhclelbl">
                    Vehicle Registration Number
                </div>
                <div class="col-md-4 vhclNbrClr">
                    {{asrt_nm}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 vhclelbl">
                    Distance Updated On
                </div>
                <div class="col-md-4 vhclNbrClr">
                    {{dst_updtn_dt}}
                </div>
            </div>
            <div class="row vhcl">
                <div class="col-md-8 vhclelbl">
                    Updated Distance </div>
                <div class="col-md-4 vhclNbrClr">
                    {{updt_dst}}
                </div>
            </div>
        </div>
        <div nz-row [nzGutter]="4">
            <div nz-col nzSpan="24">
                <nz-form-label>Reason for Rejection</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input [(ngModel)]="rjctrsn" placeholder="Reason for Rejection"
                            [ngModelOptions]="{standalone: true}" />

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="btngrp">
                <button nz-button style="margin-right: 8px;" (click)="closeSideBar()" nzType="primary"
                    nzDanger>Cancel</button>
                <button nz-button (click)="reject()" nzType="primary" class="sbmtbtn">Submit</button>
            </div>
        </div>
    </div>
</nz-drawer>