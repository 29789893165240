<div class="page-header">
    <div class=" align-items-md-center">


        <div class="row hdrBar">
            <div class="col-lg-6 col-md-6">
                <h1>
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
            <!-- <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;" >
                                <span class="slctDteLbl">Select Date:</span>
                                <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                                    (ngModelChange)="onChange($event)" class="dte_picker"></nz-date-picker>
                
                
                            </div> -->
        </div>
    </div>
</div>

<div class="apsfl_dshbrd">

    <!-- <button (click)="open()"></button> -->

    <app-oms-dashbourd-cards [slctd_dstrt]="slctd_dstrt" (slctdata)="selctedCatrd($event)"></app-oms-dashbourd-cards>


    <div class="row">
        <div class="col-lg-7 col-md-7">


            <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;">

                <div class="card_ttl">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="row pstnCls">
                                <div class="col-md-4">
                                    <div class="card_hdr_ttl" *ngIf="selctedcrd?.crd_hdr == 'WORKS'">Activities</div>
                                    <div class="card_hdr_ttl" *ngIf="selctedcrd?.crd_hdr == 'TRIPS'">Travel Registry
                                    </div>
                                    <div class="card_hdr_ttl" *ngIf="selctedcrd?.crd_hdr == 'EXPENSES'">Expenses</div>
                                </div>

                                <div class="col-md-6" style="text-align: center;">
                                    <div class="tabs_data" *ngFor="let t of tabsdata" (click)="ChangeLevel(t.tab_id)"
                                        [ngClass]="t.tab_id == lvl_id ? 'slct_tab' : 'dflt_tab' ">
                                        {{t.tab_nm}}
                                    </div>
                                </div>
                                <div class="col-md-2" style="text-align: right;">
                                    <div class="vws_data" *ngFor="let v of viewsdata" (click)="slectdvw(v.vw_id)"
                                        [ngClass]="v.vw_id == slctdvw ? 'slct_vw' : 'dflt_vw' ">
                                        <span nz-icon [nzType]="v.vw_icn" nzTheme="outline"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mgnb-2 p-8 apsflgrid_sumry" *ngIf="slctdvw==1">
                    <dx-data-grid class="p-8" id="gridContainer" [dataSource]="gridData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                        (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)">
                        <dxo-paging [pageSize]="26"></dxo-paging>
                        <!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                        </dxo-pager> -->
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                        </dxo-search-panel>
                        <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
                        <!-- <dxo-group-panel [visible]="true"></dxo-group-panel> -->
                        <!-- <dxo-grouping [autoExpandAll]="false"></dxo-grouping> -->
                        <dxo-column-chooser [enabled]="false" mode="select">
                        </dxo-column-chooser>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-export [enabled]="true" fileName=""></dxo-export>
                        <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
                        <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [visible]="d.visible"
                            [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                            [format]="d.format">
                        </dxi-column>
                        <dxo-summary>
                            <!-- [customizeText]="addSumLabel('Total Activities')" -->
                            <dxi-total-item column="ttl" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="no_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="inprg" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="cmpltd" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="dtnc_trvl" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="wrk_incld_expns" summaryType="sum"
                                [displayFormat]="sumcurrencyINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="trvld_vehs" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="oprd_veh" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="not_op_veh" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="amt" summaryType="sum" [displayFormat]="sumcurrencyINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="plnd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="un_plnd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="cstmr_efctd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="in_prgrs" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="ttl_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="cmpltd_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="actl_trvl_dstne_ct" summaryType="sum"
                                [displayFormat]="SumINRFormater">
                            </dxi-total-item>


                        </dxo-summary>
                    </dx-data-grid>
                </div>
                <div class="col-md-12 p-8 " *ngIf="slctdvw==2">
                    <div class="crd_style">
                        <div class="apxgrphone">
                            <!-- <nz-divider nzDashed [nzText]="text" nzOrientation="left">
                        <ng-template #text>
                            <span class="dividertxt">
                                <img src="/assets/images/calendar.png" class="right_mrgin"> By District
                            </span>
                        </ng-template>
                    </nz-divider> -->
                            <div id="chart1">
                                <apx-chart [series]="chartOptionsFour.series" [chart]="chartOptionsFour.chart"
                                    [dataLabels]="chartOptionsFour.dataLabels"
                                    [plotOptions]="chartOptionsFour.plotOptions" [xaxis]="chartOptionsFour.xaxis"
                                    [yaxis]="chartOptionsFour.yaxis" [fill]="chartOptionsFour.fill"
                                    [responsive]="chartOptionsFour.responsive" [legend]="chartOptionsFour.legend"
                                    [colors]="chartOptionsFour.colors" [states]="chartOptionsFour.states"
                                    [stroke]="chartOptionsFour.stroke" [labels]="chartOptionsFour.labels"
                                    [title]="chartOptionsFour.title" [grid]="chartOptionsFour.grid"
                                    [theme]="chartOptionsFour.theme">
                                </apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-5 col-md-5">
            <div class="crd_style">
                <div class="card_ttl">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="row pstnCls">
                                <div class="col-md-12">
                                    <div class="card_hdr_ttl">Activities In Progress</div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12" style="margin-top: 34px;">

                            <div id="dbrdMap"></div>


                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">

            <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 30px;" *ngIf="tb_id=='3'">
                <div class="row">
                    <div class="col-lg-9 col-md-9"></div>
                    <div class="col-lg-2 col-md-2">
                        <span>Expense Category</span>
                        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectdCtgry"
                            (ngModelChange)="onCtgrySlct($event)" nzPlaceHolder="Expense Category"
                            [ngModelOptions]="{standalone: true}">
                            <nz-option *ngFor="let t of expenseCtgry" [nzValue]="t.expne_ctgry_id"
                                [nzLabel]="t.expne_ctgry_nm"></nz-option>
                        </nz-select>
                    </div>
                    <div class="col-lg-1 col-md-1"></div>
                </div>

                <div class="col-lg-12 col-md-12 mgnb-2 p-8 " *ngIf="expnsGrapData && expnsGrapData.length>0">
                    <div id="multichart">
                        <apx-chart [series]="multiChartOptions?.series" [chart]="multiChartOptions?.chart"
                            [xaxis]="multiChartOptions?.xaxis" [markers]="multiChartOptions?.markers"
                            [stroke]="multiChartOptions?.stroke" [yaxis]="multiChartOptions?.yaxis"
                            [dataLabels]="multiChartOptions?.dataLabels" [title]="multiChartOptions?.title"
                            [fill]="multiChartOptions?.fill" [tooltip]="multiChartOptions?.tooltip"
                            [legend]="multiChartOptions?.legend"></apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 30px;" *ngIf="tb_id=='1'">
                <div class="row">
                    <div class="col-lg-9 col-md-9"></div>
                    <div class="col-lg-2 col-md-2">
                        <span>Consumable Fiber Categories</span>
                        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectdAsstCtgry"
                            (ngModelChange)="onAssetCtgrySlct($event)" nzPlaceHolder="Expense Category"
                            [ngModelOptions]="{standalone: true}">
                            <nz-option *ngFor="let t of AsstCtgry" [nzValue]="t.wrk_ast_id"
                                [nzLabel]="t.wrk_ast_nm"></nz-option>
                        </nz-select>
                    </div>
                    <div class="col-lg-1 col-md-1"></div>
                </div>

                <div class="col-lg-12 col-md-12 mgnb-2 p-8 " *ngIf="expnsGrapData && expnsGrapData.length>0">
                    <div id="multichart">
                        <apx-chart [series]="AsstMultiChartOptions?.series" [chart]="AsstMultiChartOptions?.chart"
                            [xaxis]="AsstMultiChartOptions?.xaxis" [markers]="AsstMultiChartOptions?.markers"
                            [stroke]="AsstMultiChartOptions?.stroke" [yaxis]="AsstMultiChartOptions?.yaxis"
                            [dataLabels]="AsstMultiChartOptions?.dataLabels" [title]="AsstMultiChartOptions?.title"
                            [fill]="AsstMultiChartOptions?.fill" [tooltip]="AsstMultiChartOptions?.tooltip"
                            [legend]="AsstMultiChartOptions?.legend"></apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>






</div>




<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="1500" [nzVisible]="visible"
    nzPlacement="right" nzTitle="Work View" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <app-wrok-profile [wrkData]="wrkData"></app-wrok-profile>
    </ng-container>
</nz-drawer>