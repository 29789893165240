


<div class="row">
    <div class="col-md-4">

      <div class="crd_style">
            <div class="row">
                <div class="col-md-5 cstmcol">
                    <div class="actvts" style="    background: #544795;">Activities <span
                            style="color: #ffdaa1;">(55)</span></div>
                </div>
                <div class="col-md-7 lftpdngnone" style="    text-align: right;">

                    <div class="tabs_data" *ngFor="let t of tabsActdata" 
                        [ngClass]="t.tab_id == slctdActdt ? 'slct_dt' : 'dflt_dt' ">
                        {{t.tab_nm}}
                    </div>

                </div>
            </div>
            <div class="row rwpdng">


                <div class="col-md-4">
                    <div class="cnt">10
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
In Progress                </div>


                <div class="col-md-4">
                    <div class="cnt">47
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #3F51B5;"> Completed</span>
                    </div>
                </div>


            </div>
        </div>
    </div>
        <div class="crd_style">
            <div class="row">
                <div class="col-md-5 cstmcol">
                    <div class="actvts" style="    background: #544795;">Attendance <span
                            style="color: #ffdaa1;">(55)</span></div>
                </div>
                <div class="col-md-7 lftpdngnone" style="    text-align: right;">

                    <div class="tabs_data" *ngFor="let t of tabsAtnddata" 
                        [ngClass]="t.tab_id == slctdAtnd ? 'slct_dt' : 'dflt_dt' ">
                        {{t.tab_nm}}
                    </div>

                </div>
            </div>
            <div class="row rwpdng">

                <div class="col-md-4">
                    <div class="cnt">{{empdtls  && empdtls.total_days  ? empdtls.total_days  : ''}}
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="color: #f19000;"> Total Days</span></div>
                </div>


                <div class="col-md-4">
                    <div class="cnt">10
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #4CAF50;"> Present</span></div>
                </div>


                <div class="col-md-4">
                    <div class="cnt">47
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #3F51B5;"> Absent</span>
                    </div>
                </div>


            </div>
        </div>
</div>
