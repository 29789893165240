import { Component, OnInit, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { DatePipe } from '@angular/common';

import { NzModalService } from 'ng-zorro-antd/modal';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {

  header_label = {
    name :'Drivers'
  }


  drvrnm;
  phnmb;
  lcncnmb;
  drvrid;
  doj;
  newdrvr;
  drvrslst: any;
  searchText;
  visible = false;
  visibleDriverSldr = false;
  date = null;
  crdsview: boolean;
  tblview: boolean;
  switchValue = false;
  driverform: FormGroup;
  addrvrfrm: FormGroup;
  crw_id: any;
  tntLst: any = [];
  clntDtls: any;
  selectedtenant;
  vendorList: any = [];
  showLdr = false;
  shwdrvrfrm = false
  selectedvndr; colors; selectedDriver;
  drvrslstcolumnDefs;
  userDtls: any;
  clap_in: any;
  ShowClap: boolean = false;
  showVndr: boolean = false;
  exportActive: boolean = false;
  constructor(private apiSrvc: AppsService, private router: Router, private themeService: ThemeConstantService, private fb: FormBuilder,
    public datepipe: DatePipe, private modal: NzModalService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.colors = [{ id: 1, clr: '#00bcd4', bgClr: '#d5ffff' }, { id: 2, clr: '#424242', bgClr: '#e0e0e0' }, { id: 3, clr: 'blue', bgClr: '#e8e8ff' }, { id: 4, clr: '#0dd015', bgClr: '#deffde' }, { id: 5, clr: 'gray', bgClr: '#f5f5f5' }, { id: 6, clr: 'green', bgClr: '#e0fbe0' }, { id: 7, clr: 'fuchsia', bgClr: '#ffe9ff' }, { id: 8, clr: 'maroon', bgClr: '#ffe1e1' }, { id: 9, clr: 'navy', bgClr: '#e5e5ff' }, { id: 10, clr: 'olive', bgClr: '#ececc9' }, { id: 11, clr: 'orange', bgClr: '#ffe9bf' }, { id: 12, clr: 'purple', bgClr: '#ffe8ff' }, { id: 13, clr: 'red', bgClr: '#ffdfdf' }, { id: 14, clr: 'silver', bgClr: '#efefef' }, { id: 15, clr: 'teal', bgClr: '#ddffff' }, { id: 16, clr: '#303f9f', bgClr: '#e8eaf6' }, { id: 17, clr: 'rgb(230 161 5)', bgClr: '#fff5ca' }];
  }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  ngOnInit() {
    this.getDriversLst();
    this.gridview();
    this.getVendors();
    this.driverform = new FormGroup({
      drvr_stf_id: new FormControl(''),
      drvr_nm: new FormControl(''),
      drvr_phn_no: new FormControl(''),
      drvr_lcnce_nm: new FormControl(''),
      dt_of_jn: new FormControl(''),
    });

    this.addrvrfrm = new FormGroup({
      drvrnm: new FormControl(''),
      phnmb: new FormControl(''),
      drvrid: new FormControl(''),
      lcncnmb: new FormControl(''),
      doj: new FormControl(''),
    });


  }
  gotoDrvrSldr(evnt) {
    console.log(evnt)
    this.selectedDriver = evnt;
    this.visibleDriverSldr = true;
  }
  gridview() {
    if (this.switchValue == false) {
      this.crdsview = true;
      this.tblview = false;
    } else if (this.switchValue == true) {
      this.crdsview = false;
      this.tblview = true;
    }
  }
  getDriversLst = () => {
    const fltrData = {
      selectedtenant: this.selectedtenant,
    };
    console.log(this.selectedvndr)
    if (this.selectedvndr) {
      // tslint:disable-next-line:no-string-literal
      fltrData['selectedvndr'] = this.selectedvndr;
    } else {
      fltrData['selectedvndr'] = 0;
    }
    this.showLdr = true;
    this.apiSrvc.post(fltrData, 'drivers/drivers').subscribe((res) => {
      this.drvrslst = res['data'];
      this.showLdr = false;
      this.drvrslst.forEach((element, index) => {
        element['clr_obj'] = this.colors[(index % this.colors.length)];
      });
      let counter = 0;

      this.drvrslst.filter((k) => {
        // k.chk_ind = true;
        k['sno'] = ++counter;
      })

      this.drvrslstcolumnDefs = [
        { headerName: 'Sno', field: 'sno', alignment: 'center', },
        { headerName: 'Driver No', field: 'crw_id', alignment: 'center', hide: true },
        { headerName: 'Driver Name', alignment: 'left', field: 'crw_nm' },
        { headerName: 'Licence No.', alignment: 'center', field: 'crw_lcnse_nu' },
        { headerName: 'DOJ', alignment: 'center', field: 'doj' },
        { headerName: 'Mobile No', field: 'crw_ph', alignment: 'center' },
        { headerName: 'Vehicle No.', field: 'asrt_nm', alignment: 'center' },
        // { headerName: 'Mobile No', field: 'crw_ph', alignment: 'center' }
      ]

    }, (err) => {
      // console.log(err);
    });
  }

  opendrvrlst(data) {
    this.visible = true;
    this.crw_id = data.crw_id;

    var join_dt = new Date();
    if (data.doj && data.doj !== '00-00-0000') {
      let dt_apt = data.doj.split('-');
      join_dt.setFullYear(dt_apt[2], dt_apt[1] - 1, dt_apt[0]);
    }
    else {
      join_dt = null;
    }

    // data.doj = this.datepipe.transform(data.doj, 'yyyy-mm-dd'),
    this.driverform.get('drvr_stf_id').setValue(data.crw_stf_id);
    this.driverform.get('drvr_nm').setValue(data.crw_nm);
    this.driverform.get('drvr_phn_no').setValue(data.crw_ph);
    this.driverform.get('drvr_lcnce_nm').setValue(data.crw_lcnse_nu);
    this.driverform.get('dt_of_jn').setValue(join_dt);
  }
  closedrvrlst() {
    this.visible = false;
    this.getDriversLst();
  }

  // onChange(result: Date): void {
  //   console.log('onChange: ', result);
  // }
  subDrvrlst() {
    let postdata = this.driverform.value;
    let crw_id = this.crw_id;
    const rte = `drivers/updatedrvr/${crw_id}`;
    this.apiSrvc.put(postdata, rte).subscribe((res) => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.visible = false;
        this.modal.success({
          nzTitle: 'Driver Details',
          nzContent: 'Sucessfully Updated'
        });
        this.getDriversLst();
      }
    })
  }


  ontntSelect(value) {
    this.selectedtenant = value;
    this.selectedvndr = '';
    this.getVendors();
    this.getDriversLst();
  }

  getVendors() {
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      this.apiSrvc.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vendorList = res['data'];
          this.selectedvndr = this.vendorList[0].vndr_id;
          this.getDriversLst();
        }
      }, (err) => {
        console.log("In errorr");
      })
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
      this.selectedvndr = 0;
      this.getDriversLst();

    } else {
      this.apiSrvc.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          // this.USER_PERMISSIONS = res['perm'][0];
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
          }
          this.getDriversLst();
        }
      }, (err) => {
        console.log('In errorr');
      });
    }
  }

  onvndrSelect(value) {
    this.selectedvndr = value;
    this.getDriversLst();
  }



  opendrvrfrm() {
    this.shwdrvrfrm = true;
    this.visible = true;
    console.log("dddddddddddddd")
  }
  bckbtn() {
    this.visible = false;
    this.addrvrfrm.reset();
    this.shwdrvrfrm = false;
  }



  addDrvr() {
    console.log("sssssssss")
    console.log(this.addrvrfrm.value)
    if (this.addrvrfrm.value.drvrnm == '' || this.addrvrfrm.value.drvrnm == undefined || this.addrvrfrm.value.phnmb == '' || this.addrvrfrm.value.phnmb == undefined || this.addrvrfrm.value.doj == '' || this.addrvrfrm.value.doj == undefined) {
      // (await this.toastCtrl.create({
      //   message: 'Please Check the Fields are empty',
      //   duration: 2000,
      //   position: 'top'
      // })).present();
      this.modal.error({
        nzTitle: 'Driver Details',
        nzContent: 'Please Check the Fields are empty'
      });
    }
    // else if (this.phnmb.length != 10) {
    else if (this.addrvrfrm.value.phnmb.toString().length != 10) {

      // (await this.alertCtrl.create({
      //   header: 'Invalid Phone Number!',
      //   subHeader: 'Please Enter 10 Digited Phone Number',
      //   buttons: ['OK']
      // })).present();
      this.modal.error({
        nzTitle: 'Invalid Phone Number!',
        nzContent: 'Please Enter 10 Digited Phone Number'
      });
    }
    else {
      console.log("aaaaaaaaaaaaaaaaaa")
      this.newdrvr = {
        "crw_nm": this.addrvrfrm.value.drvrnm,
        "crw_ph": this.addrvrfrm.value.phnmb,
        "crw_stf_id": this.addrvrfrm.value.drvrid,
        "crw_lcnse_nu": this.addrvrfrm.value.lcncnmb,
        "crw_doj": this.addrvrfrm.value.doj,
        "selectedtenant": this.selectedtenant,
        "clap_in": this.clap_in
      }
      // console.log(this.newdrvr);
      this.apiSrvc.post(this.newdrvr, `drivers/register/driver`).subscribe((res) => {
        if (res['status'] == 200) {
          this.modal.success({
            nzTitle: 'Driver',
            nzContent: 'Sucessfully Added'
          });
          this.visible = false;
          this.shwdrvrfrm = false;
          this.addrvrfrm.reset();
        }
      }, (err) => {
        console.log('In errorr');
      });
    }
  }

  gotoSldr(dta) {
    console.log(dta.data)
    this.gotoDrvrSldr(dta.data);
  }
  exlArry = [];
  export() {
    this.exlArry = [];
    // this.exportActive = true;
    // console.log(this.drvrslst);
    this.drvrslst.forEach(obj => {
      this.exlArry.push(
        { "Driver Name": obj.crw_nm, "Driver Phone No": obj.crw_ph, "Driver License": obj.crw_lcnse_nu, "Date of Joining": obj.doj, "Sachivalayam": obj.svm_nm, "Assigned Vehicle": obj.asrt_nm, "Vehicle Status": obj.asrt_sts_nm, "Vehicle Category": obj.asrt_ctgry_nm }
      )
    })
    console.log(this.exlArry);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exlArry);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    let fileName = 'Drivers-List';
    this.saveExcelFile(excelBuffer, fileName);
  }
  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
  visibleDriverSldrclse(){
    this.visibleDriverSldr = false
  }
}
