import { Component, OnInit } from '@angular/core';

import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
// import { Color, Label } from 'ng2-charts';
import { AppsService } from 'src/app/shared/services/apps.service';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { Router } from '@angular/router';
import { Color, Labels } from 'ng-chartjs';

@Component({
  selector: 'app-owms-dashboard',
  templateUrl: './owms-dashboard.component.html',
  styleUrls: ['./owms-dashboard.component.scss']
})
export class OwmsDashboardComponent implements OnInit {

  plainFooter = 'plain extra footer';


  public lineChartData: ChartDataset[] = [
    { data: [], label: 'PH Workers' },
    { data: [], label: 'Loaders' },
    { data: [], label: 'Drivers' },
    { data: [], label: 'Supervisors' },
  ];
  public lineChartLabels: Labels[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'bottom' }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Dates'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'No.of members Attended'
        }
      }
    }
  }
  public barChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'bottom' }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Dates'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'No.of Gates Scanned'
        }
      }
    }
  }


  public lineChartColors: Color[] = [
    {
      borderColor: '#16a086',
      backgroundColor: 'transparent',
    },
    {
      borderColor: '#e77e23',
      backgroundColor: 'transparent',
    },
    {
      borderColor: '#2dcc70',
      backgroundColor: 'transparent',
    },
    {
      borderColor: '#e84c3d',
      backgroundColor: 'transparent',
    },
  ];
  public lineChartLegend = true;
  public lineChartType : ChartType = 'line';
  public lineChartPlugins = [];
 

  // public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  // public barChartColors: Color[] = [
  //   { backgroundColor: '#16a086' },
  //   { backgroundColor: '#e77e23' }

  // ]


  barChartData: ChartDataset[] = [
    { data: [], label: 'Not Scanned Gates', stack: 'a' },
    { data: [], label: 'Scanned Gates', stack: 'a' }
  ];

  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    let today = new Date();
    return differenceInCalendarDays(current, today) > 0;
  };

  showLdr: boolean;
  todayDate = new Date().toISOString().slice(0, 10);
  batChrtData: any;
  lineChrtData: any;
  barData1: any = [];
  barData2: any = [];
  barXaxis: any = [];
  act_PHWorker: any = [];
  act_Loader: any = [];
  act_Supervisor: any = [];
  act_Driver: any = [];
  lineXaxis: any = [];
  cvrdCrdData: any = {};
  attndncCrdData: any = {};
  attdPer: any;
  columnDefs: any = [];
  svmTbleData: any = [];
  mcrpctTbleData: any = [];
  columnDefs1: any = [];
  datePk: any = new Date();
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;

  constructor(private apiSrv: AppsService, private router: Router) {
    this.getLstSvnDysCvrdGts();
    this.getLstSvnDysAtndc();
    this.getCvrdCrdData();
    this.getAttndnceCrdData();
    this.getSvmTableData();
    this.getMcrpctTableData();
  }

  ngOnInit() {

  }
  footerRender = () => {

  };
  getLstSvnDysCvrdGts() {
    this.showLdr = true;
    let rte = 'external/owms/gates-scan';
    this.apiSrv.get(rte).subscribe(res => {
      this.showLdr = false;
      this.batChrtData = res['data'];
      for (let i = this.batChrtData.length - 1; i >= 0; i--) {
        this.barData1.push(this.batChrtData[i].tot_gts - this.batChrtData[i].scnd_gts);
        this.barData2.push(this.batChrtData[i].scnd_gts);
        this.barXaxis.push(this.batChrtData[i].dt);
      }
      this.barChartData = [
        { data: [...this.barData2], label: 'Scanned Gates', stack: 'a' },
        { data: [...this.barData1], label: 'Not Scanned Gates', stack: 'a' }

      ];
      // this.barChartLabels = [...this.barXaxis]
    })
  }
  getLstSvnDysAtndc() {
    let rte = 'external/owms/attendance';
    this.apiSrv.get(rte).subscribe(res => {
      this.lineChrtData = res['data'];

      for (let j = this.lineChrtData.length - 1; j >= 0; j--) {
        this.act_PHWorker.push(this.lineChrtData[j].act_PHWorker);
        this.act_Loader.push(this.lineChrtData[j].act_Loader);
        this.act_Supervisor.push(this.lineChrtData[j].act_Supervisor);
        this.act_Driver.push(this.lineChrtData[j].act_Driver);
        this.lineXaxis.push(this.lineChrtData[j].dt);
      }
      this.lineChartData = [
        { data: [...this.act_PHWorker], label: 'PH Workers' },
        { data: [...this.act_Loader], label: 'Loaders' },
        { data: [...this.act_Driver], label: 'Drivers' },
        { data: [...this.act_Supervisor], label: 'Supervisors' },
      ];
      // this.lineChartLabels = [...this.lineXaxis];

    })
  }
  getCvrdCrdData() {
    let rte = `external/owms/coverage/counts`;
    let postData = {
      "ulb_id": 74,
      "from_dt": this.todayDate,
      "to_dt": this.todayDate
    }
    this.apiSrv.post(postData, rte).subscribe(res => {
      this.cvrdCrdData = res['data'][0];
    })

  }
  getAttndnceCrdData() {
    let rte = 'external/owms/attendance/counts';
    let postData = {
      "ulb_id": 74,
      "from_dt": this.todayDate,
      "to_dt": this.todayDate
    }
    this.apiSrv.post(postData, rte).subscribe(res => {
      this.attndncCrdData = res['data'][0];
      this.attdPer = this.attndncCrdData.tot_attnd_prcnt.toFixed(2);
    })

  }
  getSvmTableData() {
    let rte = `external/owms/sachivalayam/gates-scan/74/${this.todayDate}`;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.svmTbleData = res['data']
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
            { headerName: 'Date', field: 'Date', alignment: 'center', width: 100, filter: false },
            { headerName: 'Sachivalayam Name', alignment: 'left', width: 100, field: 'svm_nm' },
            { headerName: 'Micropockets', alignment: 'left', width: 100, field: 'mp' },
            { headerName: 'Total Gates', field: 'Total_Gates', width: 100, alignment: 'center', hide: true },
            { headerName: 'Total Scanned', field: 'Scanned_Gates', width: 100, alignment: 'center' },
            { headerName: 'Scanned %', field: 'Scanned_Percentage', width: 100, alignment: 'center' },
            { headerName: 'Residential Total', field: 'hh_tot_gtes', width: 100, alignment: 'center', hide: true },
            { headerName: 'Residential Scanned', field: 'hh_scnd_gts', width: 100, alignment: 'center' },
            { headerName: 'Residential Scanned %', field: 'hh_scnd_prcnt', width: 100, alignment: 'center' },
            { headerName: 'R & C Total', field: 'rc_tot_gtes', width: 100, alignment: 'center', hide: true },
            { headerName: 'R & C Scanned', field: 'rc_scnd_gts', width: 100, alignment: 'center' },
            { headerName: 'R & C Scanned %', field: 'rc_scnd_prcnt', width: 100, alignment: 'center' },
            { headerName: 'Apartment Total', field: 'a_tot_gtes', width: 100, alignment: 'center', hide: true },
            { headerName: 'Apartment Scanned Gates', field: 'a_scnd_gts', width: 100, alignment: 'center' },
            { headerName: 'Apartment Scanned %', field: 'a_scnd_prcnt', width: 100, alignment: 'center' },
            { headerName: 'Bulk commercial Total', field: 'tot_gtes', width: 100, alignment: 'center', hide: true },
            { headerName: 'Bulk commercial Scanned', field: 'scnd_gts', width: 100, alignment: 'center' },
            { headerName: 'Bulk commercial Scanned %', field: 'tot_scnd_prcnt', width: 100, alignment: 'center' },
          ]
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }

    })

  }
  getMcrpctTableData() {
    let rte = `external/owms/micropocket/gates-scan/74/${this.todayDate}`

    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.mcrpctTbleData = res['data'];
          for (let i = 0; i < this.mcrpctTbleData.length; i++) {
            this.mcrpctTbleData[i]['sno'] = i + 1;
            this.mcrpctTbleData[i]['date'] = this.todayDate.split("-").reverse().join("-");
          }
          this.columnDefs1 = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
            { headerName: 'Date', field: 'Date', alignment: 'center', width: 100, filter: false },
            { headerName: 'Sachivalayam Name', alignment: 'left', width: 100, field: 'svm_nm' },
            { headerName: 'Micropockets', alignment: 'left', width: 100, field: 'mp' },
            { headerName: 'Total Gates', field: 'Total_Gates', width: 100, alignment: 'center', hide: true },
            { headerName: 'Total Scanned', field: 'Scanned_Gates', width: 100, alignment: 'center' },
            { headerName: 'Scanned %', field: 'Scanned_Percentage', width: 100, alignment: 'center' },
            { headerName: 'Residential Total', field: 'hh_tot_gtes', width: 100, alignment: 'center', hide: true },
            { headerName: 'Residential Scanned', field: 'hh_scnd_gts', width: 100, alignment: 'center' },
            { headerName: 'Residential Scanned %', field: 'hh_scnd_prcnt', width: 100, alignment: 'center' },
            { headerName: 'R & C Total', field: 'rc_tot_gtes', width: 100, alignment: 'center', hide: true },
            { headerName: 'R & C Scanned', field: 'rc_scnd_gts', width: 100, alignment: 'center' },
            { headerName: 'R & C Scanned %', field: 'rc_scnd_prcnt', width: 100, alignment: 'center' },
            { headerName: 'Apartment Total', field: 'a_tot_gtes', width: 100, alignment: 'center', hide: true },
            { headerName: 'Apartment Scanned Gates', field: 'a_scnd_gts', width: 100, alignment: 'center' },
            { headerName: 'Apartment Scanned %', field: 'a_scnd_prcnt', width: 100, alignment: 'center' },
            { headerName: 'Bulk commercial Total', field: 'tot_gtes', width: 100, alignment: 'center', hide: true },
            { headerName: 'Bulk commercial Scanned', field: 'scnd_gts', width: 100, alignment: 'center' },
            { headerName: 'Bulk commercial Scanned %', field: 'tot_scnd_prcnt', width: 100, alignment: 'center' },
          ]
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }


    })

  }
  createPdf1() {
    let head = [];
    let data = [];
    this.columnDefs1.filter((c) => {
      head.push(c.headerName)
    })
    this.mcrpctTbleData.filter((k) => {
      let row = [];
      this.columnDefs1.filter((c) => {
        row.push(k[`${c.field}`]);
      })
      data.push(row)
    });
    var doc = new jsPDF("l", "mm", "a4");

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();

    doc.addImage("assets/images/others/rjy.png", 'PNG', 15, 5, width - 500, height - 500);
    doc.setFontSize(13);
    doc.setFontSize(8);
    doc.text('RAJAHMUNDRY MUNICIPAL CORPORATION', 220, 23);
    doc.setTextColor("green");
    doc.setFontSize(11);
    doc.setTextColor(100);
    (doc as any).autoTable({
      head: [head],
      body: data,
      theme: 'grid',
      startY: 31,
      didDrawCell: data => {

      }
    })
    doc.output('dataurlnewwindow')
    doc.save('table1.pdf');
  }
  createPdf() {
    let head = [];
    let data = [];
    this.columnDefs.filter((c) => {
      head.push(c.headerName)
    })
    this.svmTbleData.filter((k) => {
      let row = [];
      this.columnDefs.filter((c) => {
        row.push(k[`${c.field}`]);
      })
      data.push(row)
    });
    var doc = new jsPDF("l", "mm", "a4");

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();

    doc.addImage("assets/images/others/rjy.png", 'PNG', 15, 5, width - 500, height - 500);
    doc.setFontSize(13);
    doc.setFontSize(8);
    doc.text('RAJAHMUNDRY MUNICIPAL CORPORATION', 220, 23);
    doc.setTextColor("green");
    doc.setFontSize(11);
    doc.setTextColor(100);
    (doc as any).autoTable({
      head: [head],
      body: data,
      theme: 'grid',
      startY: 31,
      didDrawCell: data => {

      }
    })
    doc.output('dataurlnewwindow')
    doc.save('table.pdf');
  }
  onDtChange(result: Date) {
    this.todayDate = result.toISOString().slice(0, 10);
    this.getCvrdCrdData();
    this.getAttndnceCrdData();
    this.getSvmTableData();
    this.getMcrpctTableData();
  }
  getAWdth(awdth) {
    return { 'width': awdth + '%' };
  }
  getWdth(wdth) {
    return { 'width': wdth + '%' };
  }

  getTWdth(twdth) {
    return { 'width': twdth + '%' };
  }
  onCellPrepared(colDef, e: any) {



    if (e.rowType === "data" && e.row.data && e.column.dataField == 'mcrpt_nm') {
      e.cellElement.style.color = '#3f87f5';
      e.cellElement.style.cursor = "pointer";
    }

  }
  onCellClick(e) {
    if (e.column.dataField === 'mcrpt_nm') {
      this.router.navigate(['internal/dashboard/micropocket'], { queryParams: { "paramsdata": JSON.stringify(e.data) }, skipLocationChange: true })
    }
  }
  onCellPrepared1(colDef, e: any) {



    if (e.rowType === "data" && e.row.data && e.column.dataField == 'svm_nm') {
      e.cellElement.style.color = '#3f87f5';
      e.cellElement.style.cursor = "pointer";
    }

  }
  onCellClick1(e) {
    if (e.column.dataField === 'svm_nm') {
      this.router.navigate(['internal/dashboard/sachivalayam'], { queryParams: { "paramsdata": JSON.stringify(e.data) }, skipLocationChange: true })
    }
  }
}
