import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppsService } from 'src/app/shared/services/apps.service';


import * as L from 'leaflet';


import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Color } from 'ng-chartjs';
// import { Color, Label, ThemeService } from 'ng2-charts';

@Component({
  selector: 'app-micropocket-dashboard',
  templateUrl: './micropocket-dashboard.component.html',
  styleUrls: ['./micropocket-dashboard.component.scss']
})
export class MicropocketDashboardComponent implements OnInit {
  mcrpktParamDta: any;
  prvMnthName: any;
  mcrpctSvnDysScndGts: any = [];
  months: { "id": number; "name": string; }[];
  getStrtWseClctnList: any = [];
  map: any;
  markerIcon: any;
  markerIconTwo: any;
  markerIconThree: any;
  markerIconFour: any;
  markerIconFive: any;
  markerIconSix: any;
  markerIconSeven: any;
  mcrpctId: any;
  mcrpctNm: any;
  today: any = new Date().toISOString().slice(0, 10);
  mcrpctEmpCrdCont: any = [];
  transferPtCrdCnt: any = [];
  strtWsegtsScndLst: any = [];
  mcrpctSvnDysAttnEmp: any = [];
  mcrpctSvnDysWmnt: any = [];
  mcrpctEmpNtPrsnt: any = [];
  notScndGts: any = [];
  dayWmntInTons: any = [];
  columnDefs: any = [];
  // public barChartLabels: Label[] = [];
  // public barChartLabelsBig: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartLegendBig = false;
  public barChartPlugins = [];
  // public barChartColors: Color[] = [
  //   { backgroundColor: '#16a086' },
  //   { backgroundColor: '#e77e23' },
  //   { backgroundColor: '#e84c3d' }

  // ]
  public barChartColorsBig: Color[] = [
    { backgroundColor: '#16a086' },
    { backgroundColor: '#d6d9dcad' },
    { backgroundColor: '#e77e23' },
    { backgroundColor: '#d6d9dcad' },
    { backgroundColor: '#e84c3d' },
    { backgroundColor: '#d6d9dcad' }
  ]


  barChartData: ChartDataset[] = [
    { data: [], label: 'Not Scanned Gates', stack: 'a' },
    { data: [], label: 'Scanned Gates', stack: 'a' }
  ];
  barChartData1: ChartDataset[] = [
    { data: [], label: 'Absent Employees', stack: 'a' },
    { data: [], label: 'Present Employees', stack: 'a' }
  ];

  barChartData2: ChartDataset[] = [
    { data: [], label: 'Dry', stack: 'a' },
    { data: [], label: 'Wet', stack: 'a' },
    { data: [], label: 'Other', stack: 'a' }

  ];
  barChartDataBig: ChartDataset[] = [
    { data: [], label: 'Gates', stack: 'a' },
    { data: [], label: 'Attendance', stack: 'b' },
    { data: [], label: 'Weighment', stack: 'c' }

  ];
  barData1: any = [];
  barData2: any = [];
  barXaxis: any = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
    //maintainAspectRatio: false,
    plugins: {
    legend: { position: 'bottom' }},
    scales: { 
      x: {
        display: true,
        title: {
          display: true,
          text: 'Dates'
      }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'No.of Gates Scanned'
        }
      }
    }
  }
  public barChartOptionsBig: ChartOptions = {
    responsive: true,
     plugins: {
    legend: { position: 'bottom' }},
    scales: { 
      x: {
        display: true,
        title: {
          display: true,
          text: 'Dates'
      }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Percentage'
        }
      }
    }
  }
  columnDefs1: any = [];
  columnDefs2: any = [];
  barData11: any = [];
  barData12: any = [];
  barXaxis1: any = [];

  barData22: any = [];
  barData21: any = [];
  barData23: any = [];
  gatesPer: any = 0;
  gatesPer1: any = 100 - this.gatesPer;
  attnPer: any = 0;
  attnPer1: any = 100 - this.attnPer;
  wemntPer: any = 0;
  wemntPer1: any = 100 - this.wemntPer;
  visible: boolean = false;
  gateNu: any;
  gateNm: any;
  numOfRs: any;
  numOfPl: any;
  scanned: string;
  houseInfo: any=[];
  mnth: number;
  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  mnthName=this.monthNames[new Date().getMonth()]


  constructor(private route: ActivatedRoute, public apiSrv: AppsService) {

    this.route.queryParams.subscribe(params => {
      this.mcrpktParamDta = JSON.parse(params.paramsdata);


      if (params && this.mcrpktParamDta) {
     //   console.log(this.mcrpktParamDta);
        this.mcrpctId = this.mcrpktParamDta.mcrpt_id;
        this.mcrpctNm = this.mcrpktParamDta.mcrpt_nm;
        this.gatesPer = this.mcrpktParamDta.tot_scnd_prcnt;
        this.gatesPer = this.gatesPer.toFixed(2);
        this.gatesPer1 = 100 - this.gatesPer;
        this.gatesPer1 = this.gatesPer1.toFixed(2);
      }
    });
    this.getStrtWseGatesScanned();
    this.getMcrpctSvnDysScndGts();
    this.getMctpctEmpCrdCount();
    this.getMctpctTPCrdCount();
    this.getMcrpctSvnDysAttnEmp();
    this.getMcrpctSvnDysWmnt();
    this.getMcrptNtScndGts();
    this.getMcrptNtPrsntEmp();
    this.getDayWmntInTons();
    //this.getMcrpctEmpNtPrsnt();
  }

  ngOnInit() {

    // this.barChartDataBig = [
    //   { data: [this.gatesPer], label: 'Gates Scanned %', stack: 'a' },
    //   { data: [this.gatesPer1], label: 'un Gates Scanned %', stack: 'a' },
    //   { data: [this.attnPer], label: 'Attendance Present %', stack: 'b' },
    //   { data: [this.attnPer1], label: 'Attendance Absent %', stack: 'b' },
    //   { data: [this.wemntPer], label: 'Weighment Collected %', stack: 'c' },
    //   { data: [this.wemntPer1], label: 'Weighment  not Collected %', stack: 'c' }
    // ]
  }
  ngOnChanges() {
    // this.barChartDataBig = [
    //   { data: [this.gatesPer], label: 'Gates Scanned %', stack: 'a' },
    //   { data: [this.gatesPer1], label: 'un Gates Scanned %', stack: 'a' },
    //   { data: [this.attnPer], label: 'Attendance Present %', stack: 'b' },
    //   { data: [this.attnPer1], label: 'Attendance Absent %', stack: 'b' },
    //   { data: [this.wemntPer], label: 'Weighment Collected %', stack: 'c' },
    //   { data: [this.wemntPer1], label: 'Weighment  not Collected %', stack: 'c' }
    // ]
  }


   initializeMapOptions(house) {
 //   console.log(house);
 //   console.log("In Mapppppp");
    this.map = L.map('map').setView([house.lat, house.lng], 14);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 19,

    }).addTo(this.map);

    this.markerIcon = {
      icon: L.icon({
        iconSize: [35, 38],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: `../../../../assets/images/app-store/home.png`
      })
    };

    var marker = L.marker([house.lat, house.lng],this.markerIcon).addTo(this.map);

    // this.markerIcon = {
    //   icon: L.icon({
    //     iconSize: [30, 30],
    //     iconAnchor: [10, 41],
    //     popupAnchor: [2, -40],
    //     iconUrl: `../../../../assets/images/app-store/map`
    //   })
    // };

    // var marker = L.marker([house.lat,house.lng]).addTo(this.map);


  }

  getStrtWseGatesScanned() {
    // const rte = 'support/micropocket/strtwise/gatesScannedData';
    const rte = 'external/owms/micropocket/street-wise/gates-scan';
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.strtWsegtsScndLst = res['data'];
     //   console.log(this.strtWsegtsScndLst);

      }
    })
  }
  getMcrpctSvnDysScndGts() {
    // const rte = "support/micropocket/mcrpctSvnDysScndGts";
    const rte = "external/owms/micropocket/seven-days/gates-scan";
    
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.getMcrpctSvnDysScndGts = res['data'];
     //   console.log(this.getMcrpctSvnDysScndGts);
        for (let i = this.getMcrpctSvnDysScndGts.length - 1; i >= 0; i--) {
          this.barData1.push(this.getMcrpctSvnDysScndGts[i].tot_gts - this.getMcrpctSvnDysScndGts[i].scnr_gts);
          this.barData2.push(this.getMcrpctSvnDysScndGts[i].scnr_gts);
          this.barXaxis.push(this.getMcrpctSvnDysScndGts[i].dt_nm);
        }
        // this.barData1=this.barData1.reverse();
        // this.barData2=this.barData2.reverse();
        // this.barXaxis=this.barXaxis.reverse();
        this.barChartData = [
          { data: [...this.barData2], label: 'Scanned Gates', stack: 'a' },
          { data: [...this.barData1], label: 'Not Scanned Gates', stack: 'a' }

        ];
        // this.barChartLabels = [...this.barXaxis];
        // this.barChartLabelsBig = [this.barXaxis[this.barXaxis.length - 1]];
     //   console.log(this.barChartData);
      }
    })
  }

  getMcrpctSvnDysAttnEmp() {
    // const rte = "support/micropocket/mcrpctSvnDysAttnEmp";
    const rte = "external/owms/micropocket/seven-days/attendance";
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.mcrpctSvnDysAttnEmp = res['data'];
     //   console.log(this.mcrpctSvnDysAttnEmp);

        for (let i = this.mcrpctSvnDysAttnEmp.length - 1; i >= 0; i--) {
          this.barData11.push(this.mcrpctSvnDysAttnEmp[i].tot_emps - this.mcrpctSvnDysAttnEmp[i].prsnt_emps);
          this.barData12.push(this.mcrpctSvnDysAttnEmp[i].prsnt_emps);
          //this.barXaxis1.push(this.mcrpctSvnDysAttnEmp[i].dt_nm);
        }

        this.barChartData1 = [

          { data: [...this.barData12], label: 'Present Employees', stack: 'a' },
          { data: [...this.barData11], label: 'Absent Employees', stack: 'a' }

        ];

      }
    })
  }

  getMcrpctSvnDysWmnt() {
    // const rte = "support/micropocket/mcrpctSvnDysWmnt";
    const rte = "external/owms/micropocket/seven-days/weighnment";
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.mcrpctSvnDysWmnt = res['data'];
     //   console.log(this.mcrpctSvnDysWmnt);

        for (let i = this.mcrpctSvnDysWmnt.length - 1; i >= 0; i--) {
          this.barData21.push(this.mcrpctSvnDysWmnt[i].dry);
          this.barData22.push(this.mcrpctSvnDysWmnt[i].wet);
          this.barData23.push(this.mcrpctSvnDysWmnt[i].othrs);
        }

        this.barChartData2 = [
          { data: [...this.barData21], label: 'Wet', stack: 'a' },
          { data: [...this.barData22], label: 'Dry', stack: 'a' },
          { data: [...this.barData23], label: 'Others', stack: 'a' }

        ];

      }
    })
  }

  getDayWmntInTons() {
    // const rte = "support/micropocket/getDayWmntInTons";
    const rte = "external/owms/micropocket/weighnment";
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.dayWmntInTons = res['data'];
     //   console.log(this.dayWmntInTons);

        for (let i = 0; i < this.dayWmntInTons.length; i++) {

        }

        //this.wemntPer=this.dayWmntInTons

        this.columnDefs2 = [
          { headerName: 'Sno', field: 'wght_type_id', alignment: 'center', width: 50, filter: false },
          { headerName: 'Weight Type', field: 'wght_type_nm', alignment: 'left', width: 160, filter: false },
          { headerName: 'Weight Cllection in Tons', alignment: 'left', width: 100, field: 'wght_ct' },

        ]

      }
    })
  }

  // getMcrpctEmpNtPrsnt(){
  //   const rte= "support/micropocket/mcrpctEmpNtPrsnt";
  //   let postdata = {
  //     from_dt: this.today,
  //     indicator: 3,
  //     mp_id: this.mcrpctId,
  //     to_dt: this.today
  //   }
  //   //console.log("post data",postdata)
  //   this.apiSrv.post(postdata,rte).subscribe((res)=>{

  //     if(res['status']==200){

  //       this.mcrpctEmpNtPrsnt=JSON.parse(res['data']).data; 
  //    //   console.log(this.mcrpctEmpNtPrsnt);

  //     }
  //   })
  // }

  getMctpctEmpCrdCount() {
    // const rte = "support/micropocket/mcrpctEmpCrdCount";
    const rte = "external/owms/micropocket/employee";
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.mcrpctEmpCrdCont = res['data'];
     //   console.log(this.mcrpctEmpCrdCont);
     //   console.log(this.mcrpctEmpCrdCont[0].prsnt_emp)
     //   console.log(this.mcrpctEmpCrdCont[0].tot_emp)
        this.attnPer = (this.mcrpctEmpCrdCont[0].prsnt_emp / this.mcrpctEmpCrdCont[0].tot_emp) * 100;
     //   console.log(this.attnPer)
        this.attnPer = this.attnPer.toFixed(2);
        this.attnPer1 = 100 - this.attnPer;
        this.attnPer1 = this.attnPer1.toFixed(2);
     //   console.log(this.attnPer, this.attnPer1);
        this.barChartDataBig = [
          { data: [this.gatesPer], label: 'Gates Scanned %', stack: 'a' },
          { data: [this.gatesPer1], label: 'un Gates Scanned %', stack: 'a' },
          { data: [this.attnPer], label: 'Attendance Present %', stack: 'b' },
          { data: [this.attnPer1], label: 'Attendance Absent %', stack: 'b' },
          { data: [this.wemntPer], label: 'Weighment Collected %', stack: 'c' },
          { data: [this.wemntPer1], label: 'Weighment  not Collected %', stack: 'c' }
        ]

      }
    })
  }

  getMctpctTPCrdCount() {
    // const rte = "support/micropocket/mcrpctTPCrdCount";
    const rte = "external/owms/micropocket/transfer-points";
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.transferPtCrdCnt = res['data'][0];
     //   console.log(this.transferPtCrdCnt);

      }
    })
  }
  getMcrptNtScndGts() {
    // const rte = "support/micropocket/mcrpctNtScndGts";
    const rte = "external/owms/micropocket/gates-not-scan";
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {
      // this.apiSrv.get(rte).subscribe((res) => {
      if (res['status'] == 200) {

        this.notScndGts = res['data'];
     //   console.log(this.notScndGts);

        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'Street Name', field: 'strt_nm', alignment: 'left', width: 160, filter: false },
          { headerName: 'RFID Tag', alignment: 'left', width: 70, field: 'tge_nm' },
          { headerName: 'Gate No', alignment: 'center', width: 60, field: 'gte_hse_nu' },
          { headerName: 'Gate Category', field: 'gte_ctgry_nm', width: 100, alignment: 'left', hide: true }
        ]
      }
    })

  }

  getHouseInfo(gate_id){
    // const rte = "support/micropocket/getHouseInfo";
    const rte = 'external/owms/sachivalayam/house-info';
    this.mnth=new Date().getMonth()+1;
    let postdata = {
      dt:this.mnth,
      gte_id:gate_id
    }
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.houseInfo = res['data'];
     //   console.log(this.houseInfo);

      }
    })
    
  }

  open(house): void {
 //   console.log(house)
    this.visible = true;
    this.gateNu=house.gte_hse_nu;
    this.gateNm=house.gte_nm;
    this.numOfRs=house.hse_hlds_ct;
    this.numOfPl=house.ppltn_ct;
    this.scanned = house.isScanned == 1 ?  "YES" :( house.isScanned == 0 ? "NO" : "");
    this.getHouseInfo(house.gte_id);
    // if(this.map){
    //   this.map.removeLayer();
    // }
    this.initializeMapOptions(house);
    
  }

  close(): void {
    this.visible = false;
    this.map.off();
    this.map.remove();
    

  }
  getMcrptNtPrsntEmp() {
    // const rte = "support/micropocket/mcrpctEmpNtPrsnt";
    const rte = "external/owms/micropocket/employee/absentees";
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      mp_id: this.mcrpctId,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.mcrpctEmpNtPrsnt = res['data'];
     //   console.log(this.mcrpctEmpNtPrsnt);
        for (let k = 0; k < this.mcrpctEmpNtPrsnt.length; k++) {
          this.mcrpctEmpNtPrsnt[k]['sno'] = k + 1;
        }

        this.columnDefs1 = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'Employee Name', field: 'emp_nm', alignment: 'left', width: 140, filter: false },
          { headerName: 'Employee ID', alignment: 'center', width: 100, field: 'emp_id' },
          { headerName: 'Mobile No', alignment: 'center', width: 100, field: 'mble_nu' },
          { headerName: 'Designation', field: 'dsgn_nm', width: 100, alignment: 'left', hide: true }
        ]

      }
    })

  }
  // getMcrpctSvnDysWmnt(){
  //   const rte= "support/micropocket/mcrpctSvnDysWmnt";
  //   let postdata = {
  //     from_dt: this.today,
  //     indicator: 3,
  //     mp_id: this.mcrpctId,
  //     to_dt: this.today
  //   }
  //   //console.log("post data",postdata)
  //   this.apiSrv.post(postdata,rte).subscribe((res)=>{

  //     if(res['status']==200){

  //       this.mcrpctEmpNtPrsnt=JSON.parse(res['data']); 
  //    //   console.log(this.mcrpctEmpNtPrsnt);

  //     }
  //   })
  // }

}
