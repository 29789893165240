<nz-list class="sidemenuOptnsWthoutScroll">
    <div *ngFor="let p of menulst">
        <nz-list-item *ngIf="p.prnt_mnu_itm_id == 0" class="menu_item">
            <h6>{{ p.mnu_itm_nm }}</h6>
        </nz-list-item>
        <div *ngIf="p.prnt_mnu_itm_id != 0" class="parent_menu_item">
            <div  *ngIf="p.prnt_mnu_itm_nm != 'MY TICKETS'">
                <h6 class="ticket-sidemenu-prnt-item"> {{p.prnt_mnu_itm_nm}}</h6>
            </div>
            <div *ngIf="p.prnt_mnu_itm_nm == 'MY TICKETS'">
                <h6  class="ticket-sidemenu-prnt-item"> {{p.prnt_mnu_itm_nm}}</h6>
            </div>
            <nz-list-item *ngFor="let a of p.sub_mnus" class="child_menu_item">
                <h6  (click)="item(a.mnu_itm_nm)"> 
                    <nz-icon class="ticket-sidemenu-icon-style"
                    [ngStyle]="{'background-color': a.mnu_icn_tx_bg, 'color': a.mnu_icn_tx_clr}">{{a.mnu_icn_tx}}</nz-icon>
              
                    {{ a.mnu_itm_nm }}</h6>
            </nz-list-item>
        </div>
    </div>
</nz-list>

