<pg-header title="Sachivalayams" ticon="folder" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">

  <div nz-row class="gridTop" *ngIf="(USER_PERMISSIONS.slct_in==1)?true:false">
    <ds-loading *ngIf="loader"></ds-loading>
    <div class="ds-card grid">
      <dx-data-grid id="gridContainer" [dataSource]="gridData" [remoteOperations]="false" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
        [showRowLines]="true" [showBorders]="true" [showBorders]="true" style="width:100%"
        (onToolbarPreparing)="(USER_PERMISSIONS.insrt_in==1)?onToolbarPreparing($event):false">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
          [showInfo]="true">
        </dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
          fileName="Designations List"></dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [allowFiltering]="f.filter">
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false"
          [width]="100" caption="Edit" cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="tblEdtBtn" (click)="onEdit2(data)">
              Edit
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons"
          [width]="100" caption="Delete" cellTemplate="delTemplate">
          <div *dxTemplate="let data of 'delTemplate'">
            <div class="tblDelBtn" (click)="onDelete2(data)">
              Delete
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
    <div *ngIf="(USER_PERMISSIONS.slct_in==1)?false:true" class="permMsg">{{mainMessage}}</div>
  </div>


  <!-- </div>
    </div>
</div> -->

  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
    (nzOnClose)="closeSideBar()">
    <form nz-form [formGroup]="sachivalayamForm">
      <div class="input-row mt-2" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="schvlmMrgn">
          <div class="inptLbl">Sachivalayam Name</div>
          <input type="text" nz-input formControlName="svm_nm" />
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="schvlmMrgn">
          <div class="inptLbl">Sachivalayam Code</div>
          <input type="number" nz-input formControlName="svm_cd" />
        </div>
      </div>
      
      <div class="input-row mt-2" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="schvlmMrgn">
          <div class="inptLbl">Sachivalayam Short Name</div>
          <input type="text" nz-input formControlName="svm_shrt_nm" />
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="schvlmMrgn">
          <div class="inptLbl">District</div>
          <nz-select class="slctwidth" style="width: 100%;" nzPlaceHolder="Select Districts" formControlName="dstrct_id"
            (ngModelChange)="getulbs($event)">
            <nz-option *ngFor="let d of dstrctsLst" [nzValue]="d.dstrt_id" [nzLabel]="d.dstrt_nm">
            </nz-option>
          </nz-select>
        </div>
      </div>
      <div class="input-row mt-2" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="schvlmMrgn">
          <div class="inptLbl">Urban/Rural</div>
          <nz-select class="slctwidth" style="width: 100%;" nzPlaceHolder="Select" formControlName="urbn_in">
            <nz-option *ngFor="let a of area" [nzLabel]="a.area_nm" [nzValue]="a.id"></nz-option>
          </nz-select>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="schvlmMrgn">
          <div class="inptLbl">ULB</div>
          <nz-select class="slctwidth" style="width: 100%;" nzPlaceHolder="Select ULB's" formControlName="ulb_id">
            <nz-option *ngFor="let u of ulbLst" [nzValue]="u.ulb_id" [nzLabel]="u.ulb_nm"></nz-option>
          </nz-select>
        </div>
      </div>
      <button nz-button nzType="primary" class="sbmtBtm" (click)="savesachivalayam()"
        *ngIf="shwschvlymEditDiv==false && shwschvlymDelDiv==false">Submit</button>
      <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEditsachivalayam()"
        *ngIf="shwschvlymEditDiv==true && shwschvlymDelDiv==false">Update</button>
      <button nz-button nzType="primary" class="sbmtBtm" (click)="saveDeletesachivalayam()"
        *ngIf="shwschvlymEditDiv==false && shwschvlymDelDiv==true">Delete</button>
      <button nz-button nzType="default" class="cnclBtn" (click)="cancel()">Cancel</button>
    </form>
  </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                  <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
              </div> -->
  </nz-result>
</div>