<ds-loading *ngIf="showLdr"></ds-loading>

<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" nzTitle="Vehicle Dashboard"
    (nzOnClose)="visible = false" class="dashbSlider">
    <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
</nz-drawer>

<div class="row hdrBar">
    <div class="col-lg-6 col-md-6 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">{{hdrLbl}} Dashboard - Clean Andhra Pradesh(CLAP) <span
                *ngIf="selectedtenantNm" class="tntTxt">({{selectedtenantNm}})</span></h1>
    </div>
    <div class="col-lg-3 col-md-3 mg-a" style="text-align: right;" *ngIf="tntLst.length>1">
        <span>Select {{hdrLbl}}:</span>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
            [ngModelOptions]="{standalone: true}">
            <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
            <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
        </nz-select>
    </div>
    <div class="col-lg-3 col-md-3 mg-a" style="text-align: right;" *ngIf="tntLst.length==1">
        <span>{{hdrLbl}}:</span>
        <span class="disbledtxt">{{tntLst[0].tnt_nm}}</span>
    </div>
    <div class="col-lg-3 col-md-3 mg-a">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
    <!-- <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <span>Select Date:</span>
        <nz-date-picker   [nzDisabledDate]="disabledDate"   [nzFormat]="dateFormat" [(ngModel)]="slctdDt" (ngModelChange)="onChange($event)"></nz-date-picker>
    </div> -->
</div>

<div class="main">
    <div class="row">
        <ul class="cards">
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Vehicles</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.actv_veh_ct === null ? "0" : d.actv_veh_ct}}
                                </h1>
                                <h1 class="card_sub_title">Active</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.inact_veh_cnt === null ? "0" : d.inact_veh_cnt}}
                                </h1>
                                <h1 class="card_sub_title">Spare</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #7f75e5 0%,#4e49b9 100%);"
                    (click)="cardTwo()" [ngClass]="crdTwo == true ? 'active_class' : 'normalclass'">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Operational Status</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.u_operated === null ? "0" : d.u_operated}}
                                </h1>
                                <h1 class="card_sub_title">Operated</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.u_opper === null ? "0" : d.u_opper}} %
                                </h1>
                                <h1 class="card_sub_title">Operated %</h1>
                            </div>
                        </div>
                        <div class="row">
                            <p class="crdFtr">VEHICLES TRAVELLED > 500 Meters IS CONSIDERED AS OPERATED</p>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of ttlTrpsCnt">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Trips Travelled</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.def_trps === null ? 0 : d.def_trps}}
                                </h1>
                                <h1 class="card_sub_title">Defined Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.tdy_trvl_trps === null ? 0 : d.tdy_trvl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Travelled</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.tdy_per === null ? 0 : d.tdy_per}}
                                </h1>
                                <h1 class="card_sub_title">Travelled %</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
    </ul>

    </div>

    <div class="row bg-w">
        <div class="col-lg-12 col-md-12">
            <h1>Operated Vehicles % in Last 7 Days </h1>
            <div id="distanceTavelledChart"></div>
        </div>
    </div>
    <div class="row bg-wp">
        <h1>Vehicles </h1>
        <div class="ds-card tbl grid">

            <dx-data-grid class="p-8" [dataSource]="vehclesTble" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="true">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <!--<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>-->
                <dxo-pager [visible]="vehclesTble.length>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ulb wise operated Trips">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of vehclesTblecolumnDefs" [visible]="!f.hide"
                    [dataField]="f.field" [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width"
                    [allowResizing]="true">
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>



   <div class="row bg-wp">
        <h1>Last 10 Days Not-operated Vehicles<span>(Vehicles Travel Less than 2KMS)</span></h1>
        <div id="lst10DysNtoprdTrpsChart"></div>
     </div>
</div>