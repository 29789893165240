<div class="row">
  <div class="row hdrBar">
    <div class="col-lg-12 col-md-12 mg-a">
      <h1>Vehicle ULB assignment</h1>
    </div>
  </div>
</div>
<div class="fltrbg">
  <div class="row">
    <div class="col-lg-2 col-md-2 crdCl" *ngIf="ShowClap">
      <p class="slctlbl">Vehicle Vendor</p>
      <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
        [ngModelOptions]="{standalone: true}">
        <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
        <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
      </nz-select>
    </div>
  </div>
</div>
<div style="padding: 0px 10px;">

  <div class="ds-card grid tbl dwntbl vhclsCmpnt">
    <dx-data-grid class="p-8" [dataSource]="vhcleslst" [remoteOperations]="false" [allowColumnReordering]="true"
      [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true"
      [showBorders]="true" style="width:100%" (onToolbarPreparing)="true">
      <dxo-paging [pageSize]="10" [enabled]="true"></dxo-paging>
      <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
        [showInfo]="true"></dxo-pager>

      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-filter-panel [visible]="false"></dxo-filter-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxo-column-chooser [enabled]="true" mode="select">
      </dxo-column-chooser>
      <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="SS assigned Vehicles Data"></dxo-export>
      <dxo-filter-row [visible]="false"></dxo-filter-row>
      <dxi-column *ngFor="let v of vhclslstcolumnDefs" [visible]="!v.hide" [dataField]="v.field"
        [caption]="v.headerName" [alignment]="v.alignment" [width]="v.width" [allowResizing]="true"></dxi-column>
      <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="change ULB"
        cellTemplate="editTemplate">
        <div *dxTemplate="let data of 'editTemplate'">
          <div class="tblEdtBtn" (click)="openSlider(data);"><i nz-icon nzType="car" nzTheme="outline"></i></div>
        </div>
      </dxi-column>
    </dx-data-grid>
  </div>
</div>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="500" [nzVisible]="opensldr"
  nzTitle="Change ULB" (nzOnClose)="closeSideBar()">
  <ng-container *nzDrawerContent>
  <form nz-form class="drvrDtlsfrm">
    <div nz-row [nzGutter]="8">
      <div nz-col nzSpan="24">
        <nz-form-label>Vehicle</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehicle" [(ngModel)]="asrt_id"
              [ngModelOptions]="{standalone: true}" nzDisabled>
              <nz-option *ngFor="let v of asrtList" [nzLabel]=v.asrt_nm [nzValue]="v.asrt_id"></nz-option>

            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24">
        <nz-form-label>ULB</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="tnt_id" (ngModelChange)="ontntSelect($event)"
              [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select ULB to be changed">
              <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24">
        <nz-form-label>Vehicle Category</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="asrt_ctgry_id" (ngModelChange)="onctgrySelect($event)"
              [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select Vehicle Category">
              <nz-option *ngFor="let v of vehctgryLst" [nzValue]="v.asrt_ctgry_id" [nzLabel]="v.asrt_ctgry_nm">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24">
        <nz-form-label>Vehicle Group</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="asrt_grp_id" (ngModelChange)="onvehGrpSelect($event)"
              [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select Vehicle Group">
              <nz-option *ngFor="let v of vhcleGrp" [nzValue]="v.asrt_grp_id" [nzLabel]="v.asrt_grp_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24">
        <nz-form-label>Vehicle Status</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="asrt_sts_id" (ngModelChange)="onstatusChange($event)"
              [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select Vehicle Status">
              <nz-option *ngFor="let v of vehicleSts" [nzValue]="v.asrt_sts_id" [nzLabel]="v.asrt_sts_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

  <div class="btngrp">
    <button nz-button style="margin-right: 8px;" (click)="closeSideBar()" nzType="primary" nzDanger>Cancel</button>
    <button nz-button (click)="saveasgnmnt()" class="sbmtbtn">Change ULB</button>
  </div>
  </ng-container>
</nz-drawer>
<ds-loading *ngIf="showLdr"></ds-loading>