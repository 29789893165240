import { DatePipe } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from 'src/app/providers/api.service';
import * as L from 'leaflet';
import * as moment from 'moment';
import { el } from 'date-fns/locale';
@Component({
  selector: 'app-svm-trip-schedule',
  templateUrl: './svm-trip-schedule.component.html',
  styleUrls: ['./svm-trip-schedule.component.scss']
})
export class SvmTripScheduleComponent implements OnInit {
  usrDtls: any = [];
  tntLst: any = [];
  tnt_id: any;
  showLbl: boolean;
  lblNm: string = "ULB/Mandal";
  tnt_nm: any;
  clstRtsLst: any;
  svm_id: any;
  svmLst: any[];
  topfltrForm: FormGroup;
  shwRtlst: boolean;
  showMap: boolean;
  map: any;
  polylinePoints: any[];
  route_Polyline: any;
  startPBMarker: any;
  endPBMarker: any;
  showNoDtls: boolean;
  shwOne: boolean;
  shwTwo: boolean;
  shwThree: boolean;
  tripFnceForm: FormGroup;
  trnsfrPntslst: any[];
  frmfnceFltrOptns: any = [];
  frmtplst: any[];
  tofnceFltrOptns: any = [];
  fnceFltrOptns: any = [];
  filteredOptions: any = [];
  fncefilteredOptions: any = [];
  frm_fnce_grp_nm: any;
  strt_fnce_id: any;
  strt_fnce_nm: any;
  end_fnce_id: any;
  end_fnce_nm: any;
  tofncefilteredOptions: any = [];
  end_fnce_grp_nm: any;
  selctdtenant: any;
  opnSdebar: boolean;
  ShowAddFnce: boolean;
  routData: any = [];
  svmRteDtlsData: any;
  routeForm: any;
  routes: FormArray;
  addfncLst: any = [];
  timeFormat = 'HH:mm';
  shwTrp: boolean;
  arvl_ts = new Date();
  dprtr_ts = new Date();
  drgndLat;
  drgndLng;
  drgndLat1: any;
  drgndLng1: any;
  marker: any;
  drgndLng2: any;
  drgndLat2: any;
  marker1: any;
  myIcon: any;
  myIcon1: any;
  vehclesLst: any;
  svm_apfs_cd: any;
  asrt_id: any;
  ipForm: any;
  pts: FormArray;
  svmaddfncLst: any;
  fncenm: any;
  fnceNm: any;
  routesListAdd: any;
  strtFnceProp: any = [];
  endFnceProp: any = [];
  isEdit: boolean;
  validateForm: FormGroup;
  listOfControl = [];
  interfnceFltrOptns: any = [];
  masterpntData: any = [];
  shwIpadd: boolean;
  ipPtsArry: any = [];
  latlng: any;
  mapData: any[];
  polygon;
  ipLocations: any;
  addIPfncLst: any = [];
  ip_fnce_id: any;
  rte_nm: any;
  data = {
    routes: [
      {
        strt_fnce_id: '',
        strt_fnce_nm: '',
        end_fnce_id: '',
        end_fnce_nm: '',
        arvl_ts: moment('00:00', "HH:mm"),
        dprtr_ts: moment('00:00', "HH:mm"),
        points: [
          {
            ip_fnce_id: '',
            ip_fnce_nm: '',
            ip_fnce_lat: '',
            ip_fnce_lng: '',
          },
        ],
      },
    ],
  };
  ipResArray: any[];
  showLdr: boolean;
  columnDefs: any[];
  columnDefs2: any[];
  tableData: any = [];
  points: any = [];
  ip_fnce_nm: any;
  ip_fnce_lat: any;
  ip_fnce_lng: any;
  map2: any;
  end_fnce_lat: any;
  end_fnce_lng: any;
  strt_fnce_lat: any;
  strt_fnce_lng: any;
  schdStartMarker: any;
  schdEndMarker: any;
  ipPts: any = []; mapPts: any;
  lat: any;
  lng: any;
  showSchvlym: boolean;
  ipMarker: any;
  trpSchde_id: any;
  selectedData: any = [];
  shwEdtStrt: boolean;
  shwEdtEnd: boolean;
  tripTypeLst: any = [];
  trp_typ_id;
  constructor(private apiServ: ApiService, private fb: FormBuilder, private message: NzMessageService,
    public datepipe: DatePipe, private modal: NzModalService, private fb1: FormBuilder, private cd: ChangeDetectorRef) {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.usrDtls[0].tnts;
    this.tnt_id = this.tntLst[0].tnt_id;
    console.log(this.tntLst);
    if (this.tntLst.length > 1) {
      this.showLbl = false;
      this.tntLst.forEach(ele => {
        if (ele.urban_in == 1) {
          this.lblNm = 'ULB'
        } else {
          this.lblNm = 'Mandal';
        }
      });
    } else {
      this.showLbl = true;
      this.tnt_nm = this.tntLst[0].tnt_nm;
      if (this.tntLst[0].urban_in == 1) {
        this.lblNm = 'ULB'
      } else {
        this.lblNm = 'Mandal';
      }
    }
    if (this.usrDtls[0].clnt_id == 7) {
      this.showSchvlym = true;
    } else {
      this.showSchvlym = false;
      this.svm_id = 0;

    }
    this.routeForm = this.fb.group({
      routes: this.fb.array([]),
    });

    this.setroutes();
  }

  // addNewroutes(i) {
  //   let control = <FormArray>this.routeForm.controls.routes;
  //   control.controls.splice(i + 1, 0,

  //     this.fb.group({
  //       routes: [''],
  //       points: this.fb.array([]),
  //     })
  //   );
  // }
  // deleteroutes(index) {
  //   let control = <FormArray>this.routeForm.controls.routes;
  //   control.removeAt(index);
  // }

  addNewpoint(control) {
    control.push(
      this.fb.group({
        ip_fnce_id: [''],
      })
    );
  }

  deletepoint(control, index) {
    control.removeAt(index);
  }

  setroutes() {
    let control = <FormArray>this.routeForm.controls.routes;
    this.data.routes.forEach((x) => {
      control.push(
        this.fb.group({
          routes: [''],
          points: this.setpoints(x),
          shwEdtStrt: false,
          shwEdtEnd: false,


        })
      );
    });
  }

  setpoints(x) {
    let arr = new FormArray([]);
    x.points.forEach((y) => {
      arr.push(
        this.fb.group({
          ip_fnce_id: y.ip_fnce_id,
        })
      );
    });
    return arr;
  }
  ngOnInit(): void {
    this.topfltrForm = new FormGroup({
      tnt_id: new FormControl(''),
      // asrt_id: new FormControl(''),
      trp_typ_id: new FormControl(''),
      slctedSvm_id: new FormControl(''),
      slctedasrt: new FormControl(''),
    });
    this.tripFnceForm = new FormGroup({
      // trp_nu: new FormControl(''),
      strt_fnce_id: new FormControl(''),
      end_fnce_id: new FormControl(''),

    });
    // this.routeForm = this.fb.group({
    //   routes: this.fb.array([
    //   ], Validators.required),
    // });
    // console.log(this.routeForm)
    // this.ipForm = this.fb.group({
    //   pts: this.fb.array([], Validators.required),
    // });
    // this.addField();
    this.getTrpType();
    if (this.showSchvlym == true) {
      this.getSachivalaymList();
      // this.getasrtbySvm();
    } else {
      this.getVhclesData();
    }
    this.gettplst();
    setTimeout(() => {
      this.initialize();
    }, 1000);
  }
  getTrpType() {
    this.apiServ.get(`tripschedule/trips/schedule/dropdown/master/${this.tnt_id}`).subscribe((res) => {
      this.tripTypeLst = res['data'].tripTypeLst;
      this.trp_typ_id = this.tripTypeLst[0].trp_typ_id;
      this.onTrpTypSelected(this.trp_typ_id);
    })
  }
  onTrpTypSelected(evt) {
    console.log(evt)
    if (evt == 1000001) {
      this.getVhclesData();
      this.svm_id = 0;
      this.showSchvlym = false;
      this.topfltrForm.get('slctedSvm_id').setValue(0);
    } else {
      this.showSchvlym = true;
      this.getSachivalaymList();
    }
  }
  initialize = function () {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS ----------//
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.usrDtls[0].tnts;
    // console.log(this.tnt_id)
    if (this.tnt_id != 0) {
      for (var i = 0; i < this.tntLst.length; i++) {
        if (this.tntLst[i].tnt_id == this.tnt_id) {
          if (this.tntLst[i].lat && this.tntLst[i].lng) {
            this.drgndLat = this.tntLst[i].lat;
            this.drgndLng = this.tntLst[i].lng;
          } else {
            this.drgndLat = 15.912327;
            this.drgndLng = 79.739238;
          }

        }
      }
    } else {
      this.drgndLat = 15.912327;
      this.drgndLng = 79.739238;
    }

    try {

      this.map = L.map('map', {
        center: [this.drgndLat, this.drgndLng],
        zoom: 11,
        // fullscreenControl: {
        //   pseudoFullscreen: true // if true, fullscreen to page width and height
        // }
      });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {

        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: ''
      });

      tiles.addTo(this.map).getCenter();
    } catch (e) {
      // console.log("please check map Initialization of leaflet:" + e);
    }
    this.map.setView([this.drgndLat, this.drgndLng], 11);


    // console.log(this.mapData)
    if (this.mapData != undefined) {
      if (this.polygon) {
        this.map.removeLayer(this.polygon);
      }
      // else {
      this.polygon = L.polygon(this.mapData as [], { color: '#f17c7c' }).addTo(this.map);
      // zoom the map to the polygon
      this.map.fitBounds(this.polygon.getBounds());
      // }

    }


    // console.log(this.drgndLat1, this.drgndLng1)
    if (this.drgndLat1 && this.drgndLng1 && this.tripFnceForm.value.strt_fnce_id) {
      this.marker = L.marker([this.drgndLat1, this.drgndLng1], {
        icon: this.myIcon,
      }).addTo(this.map).bindTooltip(this.strt_fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
    }
    if (this.drgndLat2 && this.drgndLng2 && this.tripFnceForm.value.end_fnce_id) {
      this.marker1 = L.marker([this.drgndLat2, this.drgndLng2], {
        icon: this.myIcon1,
      }).addTo(this.map).bindTooltip(this.end_fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
    }

  }
  onTenantChange(tnt) {
    this.tnt_id = tnt;
    this.getTrpType();
    this.getSachivalaymList();
    this.gettplst();
    if (this.tnt_id != 0) {
      for (var i = 0; i < this.tntLst.length; i++) {
        if (this.tntLst[i].tnt_id == this.tnt_id) {
          if (this.tntLst[i].lat && this.tntLst[i].lng) {
            this.drgndLat = this.tntLst[i].lat;
            this.drgndLng = this.tntLst[i].lng;
          } else {
            this.drgndLat = 15.912327;
            this.drgndLng = 79.739238;
          }

        }
      }
    } else {
      this.drgndLat = 15.912327;
      this.drgndLng = 79.739238;
    }
    this.map.setView([this.drgndLat, this.drgndLng], 11);
    this.tripFnceForm.reset();
    this.routeForm.reset();
    this.ipForm.reset();
    this.getSvmTripsDtls();
  }
  getSachivalaymList() {
    this.svmLst = [];
    this.apiServ.get(`tripschedule/tenant/${this.tnt_id}/svmLst`).subscribe((res) => {
      if (res['status'] == 200) {
        this.svmLst = res['data'];
        if (this.svmLst.length > 0) {
          this.svm_id = this.svmLst[0].svm_id;
          this.svm_apfs_cd = this.svmLst[0].svm_apfss_cd;
          this.getasrtbySvm();
        }
      }
    }, (err) => {
      console.log("In errorr");
    })
  }
  onsvmgetSelected(svm_id) {
    this.svm_id = svm_id;
    this.svmLst.forEach(element => {
      if (element.svm_id == this.svm_id) {
        this.svm_apfs_cd = element.svm_apfss_cd;
      }
    });
    console.log(this.svm_apfs_cd)
    this.getasrtbySvm();
    this.getSachivalayamFenceData();
  }
  getVhclesData() {
    this.vehclesLst=[];
    var fltrData = [{
      selectedtenant: this.tnt_id,
      selectedvndr: 0,
      selectedctgry: 0
    }]
    this.apiServ.post(fltrData, 'drivers/vehicles/asgndvehicles').subscribe((res) => {
      if (res['status'] == 200) {
        this.vehclesLst = res['data'];
        if (this.vehclesLst.length > 0) {
          this.asrt_id = this.vehclesLst[0].asrt_id;
          this.getSvmTripsDtls();
        } else {
          this.asrt_id = 0;
        }

      }
    }, (err) => {
    });
  }
  getSachivalayamFenceData() {
    const rte = `external/owms/sachivalayams/fence/${this.svm_apfs_cd}`;

    this.apiServ.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        console.log(res['data'][0])
        if (res['data'][0] != undefined) {
          this.latlng = res['data'][0]['fence'].substring(res['data'][0]['fence'].indexOf("((") + 2, res['data'][0]['fence'].indexOf("))")).split(",");
          this.mapData = [];
          for (let i = 0; i < this.latlng.length; i++) {
            let data = this.latlng[i].split(" ").reverse();
            data = data.filter(e => {
              return e != "";
            })
            this.mapData.push(data);
          }
        }
        this.initialize();
      }

    });
  }

  getasrtbySvm() {
    this.vehclesLst=[];
    this.svmLst.forEach(element => {
      if (element.svm_id == this.svm_id) {
        this.svm_apfs_cd = element.svm_apfss_cd;
      }
    });
    if (this.svm_apfs_cd) {
      this.apiServ.get(`drivers/vehicles/bysvm/${this.svm_apfs_cd}`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vehclesLst = res['data'];
          if (this.vehclesLst.length > 0) {
            this.asrt_id = this.vehclesLst[0].asrt_id;
            this.getSvmTripsDtls();
          } else {
            this.asrt_id = 0;
          }
        }
      });
    }
  }
  onasrtgetSelected(value) {
    this.asrt_id = value;
    this.getSvmTripsDtls();

  }
  gettplst() {
    this.frmfnceFltrOptns = [];
    this.tofnceFltrOptns = [];
    this.fnceFltrOptns = [];
    this.apiServ.get(`tripschedule/trips/schedule/transferpoints/${this.tnt_id}`).subscribe((res) => {
      for (let i of res['data']) {
        this.tofnceFltrOptns.push(i);
        this.frmfnceFltrOptns.push(i);
        this.fnceFltrOptns.push(i);
        this.interfnceFltrOptns.push(i);

      }
      // console.log(this.frmfnceFltrOptns)
    });
  }
  opensideBar(key, val) {
    this.selctdtenant = this.tnt_id;
    if (key == "addFncePanel") {
      this.opnSdebar = true;
      this.ShowAddFnce = true;
    }
    if (key == "showRteMap") {
      this.opnSdebar = true;
    }
  }
  closeSider() {
    this.opnSdebar = false;
    this.gettplst();
  }
  frmfnceSelected(value) {
    console.log('called')
    this.strt_fnce_id = value;
    for (let i = 0; i < this.frmfnceFltrOptns.length; i++) {
      if (this.frmfnceFltrOptns[i].fnce_id == value) {
        this.frm_fnce_grp_nm = this.frmfnceFltrOptns[i].fnce_grp_nm;
        this.drgndLat1 = this.frmfnceFltrOptns[i].lat;
        this.drgndLng1 = this.frmfnceFltrOptns[i].lng;
        this.strt_fnce_nm = this.frmfnceFltrOptns[i].fnce_nm;
        this.strt_fnce_lat = this.frmfnceFltrOptns[i].lat;
        this.strt_fnce_lng = this.frmfnceFltrOptns[i].lng;
        console.log(this.strt_fnce_nm)
        if (this.isEdit == false) {
          if (this.marker) {
            this.map.removeLayer(this.marker);
          }
          this.myIcon = L.icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/location.png',
            iconSize: [33, 33],
            iconAnchor: [16, 28],
          });
          this.marker = L.marker([this.drgndLat1, this.drgndLng1], {
            icon: this.myIcon,
          }).addTo(this.map).bindTooltip(this.strt_fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
        }
        break;
      } else {
        this.frm_fnce_grp_nm = '';
      }
    }
    this.getTripDetails();

  }
  endfnceSelected(evt) {
    this.end_fnce_id = evt;
    for (let i = 0; i < this.tofnceFltrOptns.length; i++) {
      if (this.tofnceFltrOptns[i].fnce_id == evt) {
        this.end_fnce_grp_nm = this.tofnceFltrOptns[i].fnce_grp_nm;
        this.drgndLat2 = this.tofnceFltrOptns[i].lat;
        this.drgndLng2 = this.tofnceFltrOptns[i].lng;
        this.end_fnce_nm = this.tofnceFltrOptns[i].fnce_nm;
        this.end_fnce_lat = this.tofnceFltrOptns[i].lat;
        this.end_fnce_lng = this.tofnceFltrOptns[i].lng;
        if (this.isEdit == false) {

          if (this.marker1) {
            this.map.removeLayer(this.marker1)
          }
          this.myIcon1 = L.icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/location.png',
            iconSize: [33, 33],
            iconAnchor: [16, 28],
          });
          this.marker1 = L.marker([this.drgndLat2, this.drgndLng2], {
            icon: this.myIcon1,
            draggable: true,
          }).addTo(this.map).bindTooltip(this.end_fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
        }
        break;
      } else {
        this.end_fnce_grp_nm = ''
      }
    }
    this.getTripDetails();
  }
  getTripDetails() {
    // console.log('execcccccccccccccccccc')
    // console.log(this.svmRteDtlsData);
    if (this.selectedData) {
      this.selectedData.forEach(e => {
        e.get('shwEdtStrt').setValue(false);
        e.get('shwEdtEnd').setValue(false);
      })
    }
    if (this.tripFnceForm.value.strt_fnce_id && this.tripFnceForm.value.end_fnce_id) {
      this.shwTrp = true;
    } else {
      this.shwTrp = false;
    }
    if (this.svmRteDtlsData != undefined && this.svmRteDtlsData.length == 0) {
      this.routeForm.controls.routes = new FormArray([]);
      let control = <FormArray>this.routeForm.controls.routes;
      // console.log(control)
      control.push(
        this.fb.group({
          strt_fnce_id: this.strt_fnce_id,
          strt_fnce_nm: this.strt_fnce_nm,
          end_fnce_id: this.end_fnce_id,
          end_fnce_nm: this.end_fnce_nm,

          arvl_ts: moment('00:00', "HH:mm"),
          dprtr_ts: moment('00:00', "HH:mm"),
          strt_fnce_lat: this.strt_fnce_lat,
          strt_fnce_lng: this.strt_fnce_lng,
          end_fnce_lat: this.end_fnce_lat,
          end_fnce_lng: this.end_fnce_lng,
          points: new FormArray([this.fb.group({
            ip_fnce_id: this.ip_fnce_id,
            ip_fnce_nm: this.ip_fnce_nm,
            ip_fnce_lat: this.ip_fnce_lat,
            ip_fnce_lng: this.ip_fnce_lng
          })])
          , shwEdtStrt: false
          , shwEdtEnd: false,


        })
      )
      // this.ManageFenceControl(0);
      control.push(
        this.fb.group({
          strt_fnce_id: this.end_fnce_id,
          strt_fnce_nm: this.end_fnce_nm,
          end_fnce_id: this.end_fnce_id,
          end_fnce_nm: this.end_fnce_nm,
          arvl_ts: moment('00:00', "HH:mm"),
          dprtr_ts: moment('00:00', "HH:mm"),
          strt_fnce_lat: this.end_fnce_lat,
          strt_fnce_lng: this.end_fnce_lng,
          end_fnce_lat: this.end_fnce_lat,
          end_fnce_lng: this.end_fnce_lat,
          points: new FormArray([this.fb.group({
            ip_fnce_id: this.ip_fnce_id,
            ip_fnce_nm: this.ip_fnce_nm,
            ip_fnce_lat: this.ip_fnce_lat,
            ip_fnce_lng: this.ip_fnce_lng,
          })]),
          shwEdtStrt: false,
          shwEdtEnd: false,
        })
      )
      control.push(
        this.fb.group({
          strt_fnce_id: this.end_fnce_id,
          strt_fnce_nm: this.end_fnce_nm,
          end_fnce_id: this.strt_fnce_id,
          end_fnce_nm: this.strt_fnce_nm,
          arvl_ts: moment('00:00', "HH:mm"),
          dprtr_ts: moment('00:00', "HH:mm"),
          strt_fnce_lat: this.end_fnce_lat,
          strt_fnce_lng: this.end_fnce_lng,
          end_fnce_lat: this.strt_fnce_lat,
          end_fnce_lng: this.strt_fnce_lng,
          points: new FormArray([this.fb.group({
            ip_fnce_id: this.ip_fnce_id,
            ip_fnce_nm: this.ip_fnce_nm,
            ip_fnce_lat: this.ip_fnce_lat,
            ip_fnce_lng: this.ip_fnce_lng,
          })
          ]),
          shwEdtStrt: false,
          shwEdtEnd: false,
        })
      )
      this.addfncLst = control.controls;
    }
    else {
      this.routeForm.controls.routes = new FormArray([]);
      let control = <FormArray>this.routeForm.controls.routes;

      this.svmRteDtlsData.forEach(e => {
        let arr = new FormArray([]);
        e.points.forEach((y) => {
          arr.push(
            this.fb.group({
              ip_fnce_id: y.ip_fnce_id,
              trp_schde_id: y.trp_schde_id,
              ip_fnce_nm: y.ip_fnce_nm,
              ip_fnce_lat: y.ip_fnce_lat,
              ip_fnce_lng: y.ip_fnce_lng
            })
          );
        })
        control.push(

          this.fb.group({

            // routes: [{
            trp_schde_id: e.trp_schde_id,
            strt_fnce_id: e.strt_fnce_id,
            strt_fnce_nm: e.strt_fnce_nm,
            end_fnce_id: e.end_fnce_id,
            end_fnce_nm: e.end_fnce_nm,
            arvl_ts: moment(e.arvl_ts, "HH:mm"),
            dprtr_ts: moment(e.dprtr_ts, "HH:mm"),
            strt_fnce_lat: e.strt_fnce_lat,
            strt_fnce_lng: e.strt_fnce_lng,
            end_fnce_lat: e.end_fnce_lat,
            end_fnce_lng: e.end_fnce_lng,
            points: arr,
            shwEdtStrt: false,
            shwEdtEnd: false,
            // shwIpadd: false
          }),

        )

      });
      // console.log(control)
      this.addfncLst = control.controls;
      // console.log(this.addfncLst)
    }

    console.log(this.shwTrp)
  }


  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  addNewTrp() {

  }
  current = 0;

  index = 'zero-content';

  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    // if (this.isEdit == true) {
    //   this.current = 3;
    //   this.index = 'third-content';
    //   // if (this.addfncLst != undefined) {
    //   //   this.shwTrp = true;
    //   // } else {
    //   //   this.shwTrp = false;
    //   // }
    // } else {
    this.current += 1;
    this.changeContent();
    // if (this.addfncLst != undefined) {
    //   this.shwTrp = true;
    // } else {
    //   this.shwTrp = false;
    // }
    // }

  }

  done(): void {
    this.saveRoutes();

  }

  changeContent(): void {
    switch (this.current) {
      case 0: {
        this.index = 'zero-content';
        break;
      }
      case 1: {
        this.index = 'First-content';
        break;
      }
      case 2: {
        this.index = 'Second-content';
        break;
      }
      case 3: {
        this.index = 'third-content';
        break;
      }
      case 4: {
        this.index = 'fourth-content';
        this.getTableView();
        break;
      }
      default: {
        this.index = 'error';
      }
    }
    if (this.current == 1) {
      setTimeout(() => {
        this.initialize();
      }, 1000);
    } else if (this.current == 4) {
      setTimeout(() => {
        this.initialize2();
      }, 1000);
    }
  }
  getTableView() {
    this.tableData = [];
    this.points = [];
    var cnt = 0;
    for (let r of this.addfncLst) {
      r.value.sno = ++cnt;
      if (r.value.points[0].ip_fnce_id != undefined) {
        r.value.ip_cts = r.value.points.length;
        var cnt2 = 0;
        for (let p of r.value.points) {
          p.sno = ++cnt2;
        }
        this.points.push(r.value.points);
      } else {
        r.value.ip_cts = 0;
      }
      r.value.strt_tm = this.datepipe.transform(r.value.arvl_ts, 'HH:mm'),
        r.value.end_tm = this.datepipe.transform(r.value.dprtr_ts, "HH:mm")
      this.tableData.push(r.value);
      // this.tableData.points = r.value.points;
      // console.log(this.tableData);

      // this.tableData.points = [];
      // this.tableData.forEach(e => {
      //   e.value.sno = ++cnt2;

      // this.points=this.tableData
    }
    // console.log(this.points)
    this.columnDefs = [
      { headerName: 'Trip Number', field: 'sno', alignment: 'center', width: 70, filter: false, cellClass: 'pm-grid-number-cell', search: false },
      { headerName: 'Trip start Location', alignment: 'left', field: 'strt_fnce_nm', cellClass: 'pm-grid-number-cell' },
      { headerName: 'Trip End Location', alignment: 'left', field: 'end_fnce_nm', cellClass: 'pm-grid-number-cell' },
      { headerName: 'Trip Start Time', alignment: 'left', field: 'strt_tm', cellClass: 'pm-grid-number-cell' },
      { headerName: 'Trip End Time', alignment: 'left', field: 'end_tm', cellClass: 'pm-grid-number-cell' },
      { headerName: 'No of Intermediate Locations', alignment: 'left', field: 'ip_cts', cellClass: 'pm-grid-number-cell' },
    ];
    this.columnDefs2 = [
      { headerName: 'Intermediate Locations Number', field: 'sno', alignment: 'center', width: 120, filter: false, cellClass: 'pm-grid-number-cell', search: false },
      { headerName: 'Intermediate Locations', alignment: 'left', field: 'ip_fnce_nm', cellClass: 'pm-grid-number-cell' },
    ]
    // });
    // console.log(this.tableData.points)

  }
  viewMap(d) {
    console.log(d.data)
    this.mapPts = d.data;
    this.initialize2();
    // ----------trip Start Area (flag position) -- Leaflet OS ----------//
    if (this.schdStartMarker) {
      this.map2.removeLayer(this.schdStartMarker);
    }
    var startIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/startPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    if (this.mapPts.strt_fnce_lat && this.mapPts.strt_fnce_lng) {
      console.log([this.strt_fnce_lat, this.strt_fnce_lng])
      this.schdStartMarker = L.marker([this.mapPts.strt_fnce_lat, this.mapPts.strt_fnce_lng], {
        icon: startIcon
      }).addTo(this.map2);
    }

    // ----------trip END Area (flag position) -- Leaflet OS ----------//
    if (this.schdEndMarker) {
      this.map2.removeLayer(this.schdEndMarker);
    }
    var endIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/endPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    if (this.mapPts.end_fnce_lat && this.mapPts.end_fnce_lng) {
      this.schdEndMarker = L.marker([this.mapPts.end_fnce_lat, this.mapPts.end_fnce_lng], {
        icon: endIcon
      }).addTo(this.map2);
    }
    // plotting intermediate points
    if (this.ipPts) {
      for (var x = 0; x < this.ipPts.length; x++) {
        this.map2.removeLayer(this.ipPts[x]);
      }
      if (this.mapPts.points) {
        for (var i = 0; i < this.mapPts.points.length; i++) {
          if (this.mapPts.points[i].ip_fnce_lat && this.mapPts.points[i].ip_fnce_lng) {
            this.ipMarker = L.circleMarker([this.mapPts.points[i].ip_fnce_lat, this.mapPts.points[i].ip_fnce_lng], {
              radius: 6,
              fillColor: "#008000",
              color: "#008000",
              weight: 1,
              opacity: 1,
              fillOpacity: 1
            }).addTo(this.map2).bindTooltip(this.mapPts.points[i].ip_fnce_nm, { permanent: false, opacity: 0.7 }).openTooltip();

            this.ipPts.push(this.ipMarker);

          }

          // this.createMessage('success', 'Schedule Route added Successfully');
          // this.selectCategory(0, this.curDate);
        }
      } else {
        if (this.ipPts) {
          for (var x = 0; x < this.ipPts.length; x++) {
            this.map2.removeLayer(this.ipPts[x]);
          }
        }

      }
    }
  }
  initialize2() {
    try {

      this.map2 = L.map('map2', {
        center: [this.drgndLat, this.drgndLng],
        zoom: 11,
        // fullscreenControl: {
        //   pseudoFullscreen: true // if true, fullscreen to page width and height
        // }
      });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {

        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: ''
      });

      tiles.addTo(this.map2).getCenter();
    } catch (e) {
      // console.log("please check map Initialization of leaflet:" + e);
    }
    this.map2.setView([this.drgndLat, this.drgndLng], 11);



  }
  saveRoutes() {
    // console.log(this.routesListAdd)
    this.routData = [];
    var count = 0;
    var stmt;
    if (this.showSchvlym == true) {
      var str = this.svm_apfs_cd.toString();
      str = str.slice(5, 8)
      stmt = this.svm_id && this.asrt_id;
    } else {
      str = '';
      stmt = this.asrt_id;
      this.svm_id = 0;
    }
    console.log(this.addfncLst)
    for (let r of this.addfncLst) {
      count++;
      console.log(r.value.points);
      var pts = [];
      r.value.points.forEach(p => {
        if (p.ip_fnce_id != null) {
          pts.push(p)
        }
      });
      this.routData.push({
        "sqncNo": count,
        "trp_nu": count,
        "rte_nm": r.value.strt_fnce_nm + "-" + r.value.end_fnce_nm + "/" + str + "/" + count,
        "strt_fnce_id": r.value.strt_fnce_id,
        "end_fnce_id": r.value.end_fnce_id,
        "arvlTm": this.datepipe.transform(r.value.arvl_ts, 'HH:mm'),
        "DptrTm": this.datepipe.transform(r.value.dprtr_ts, 'HH:mm'),
        "tnt_id": this.tnt_id,
        "svm_id": this.svm_id,
        "asrt_id": this.asrt_id,
        "trp_typ_id": this.trp_typ_id,
        "ips": pts
      })
    }
    console.log(this.routData)

    if (stmt) {
      this.apiServ.post(this.routData, `tripschedule/sachivalayam/trips/addRte`).subscribe((res) => {
        if (res['status'] == 200) {
          this.createMessage('success', 'Route Added Successfully');
          this.routeForm.reset();
          this.tripFnceForm.reset();
          this.getSvmTripsDtls();
        } else {
          this.createMessage('warning', 'Incorrect Data');
        }
      });
    } else {
      this.createMessage('warning', 'Please select Sachivalayam and Vehicle');
    }
  }
  reset() {
    this.tripFnceForm.reset();
  }
  addRoute(indx, controls): void {
    this.addfncLst = controls.controls;
    let control = <FormArray>this.routeForm.controls.routes;
    control.controls.splice(indx + 1, 0,
      this.fb.group({
        strt_fnce_id: this.end_fnce_id,
        strt_fnce_nm: this.end_fnce_nm,
        end_fnce_id: this.end_fnce_id,
        end_fnce_nm: this.end_fnce_nm,
        arvl_ts: moment('00:00', "HH:mm"),
        dprtr_ts: moment('00:00', "HH:mm"),
        strt_fnce_lat: this.end_fnce_lat,
        strt_fnce_lng: this.end_fnce_lng,
        end_fnce_lat: this.end_fnce_lat,
        end_fnce_lng: this.end_fnce_lng,
        points: new FormArray([this.fb.group({
          ip_fnce_id: this.ip_fnce_id,
          ip_fnce_nm: this.ip_fnce_nm,
          ip_fnce_lat: this.ip_fnce_lat,
          ip_fnce_lng: this.ip_fnce_lng
        })]),
        shwEdtStrt: false,
        shwEdtEnd: false,

      })

    );
  }
  deleteRoute(control, index) {
    control.removeAt(index);
    this.addfncLst = control.controls
  }
  addIpinRoute(indx, controls): void {
    // let control = <FormArray>this.ipForm.controls.pts;
    // control.controls.splice(indx + 1, 0,
    //   this.fb.group({
    //     ip_fnce_id: this.ip_fnce_id,
    //   }));
    controls.push(
      this.fb.group({
        ip_fnce_id: this.ip_fnce_id,
        ip_fnce_nm: this.ip_fnce_nm,
        ip_fnce_lat: this.ip_fnce_lat,
        ip_fnce_lng: this.ip_fnce_lng,

      })
    );
    this.addIPfncLst = controls.controls;
  }
  deleteIpinRoute(control, index) {
    control.removeAt(index);
    this.addIPfncLst = control.controls;
    if(this.addIPfncLst.length==0){
      control.push(
        this.fb.group({
          ip_fnce_id: this.ip_fnce_id,
          ip_fnce_nm: this.ip_fnce_nm,
          ip_fnce_lat: this.ip_fnce_lat,
          ip_fnce_lng: this.ip_fnce_lng,
  
        })
      );
      this.addIPfncLst = control.controls;
    }
  }
  svmStrtfnceSelected(value, indx) {
    for (let i = 0; i < this.fnceFltrOptns.length; i++) {
      if (this.fnceFltrOptns[i].fnce_id == value) {
        this.fncefilteredOptions[indx] = { "fnce_id": this.fnceFltrOptns[i].fnce_id, "fnce_nm": this.fnceFltrOptns[i].fnce_nm, "fnce_grp_nm": this.fnceFltrOptns[i].fnce_grp_nm };
        this.fnceFltrOptns[indx].fnce_grp_nm = this.fnceFltrOptns[i].fnce_grp_nm;
        this.strt_fnce_nm = this.fnceFltrOptns[i].fnce_nm;
        this.strt_fnce_id = this.fnceFltrOptns[i].fnce_id;

        break;
      }
    }
  }
  svmEndfnceSelected(value, indx, fn) {
    for (let i = 0; i < this.fnceFltrOptns.length; i++) {
      if (this.fnceFltrOptns[i].fnce_id == value) {
        this.fncefilteredOptions[indx] = { "fnce_id": this.fnceFltrOptns[i].fnce_id, "fnce_nm": this.fnceFltrOptns[i].fnce_nm, "fnce_grp_nm": this.fnceFltrOptns[i].fnce_grp_nm };
        this.fnceFltrOptns[indx].fnce_grp_nm = this.fnceFltrOptns[i].fnce_grp_nm;
        this.end_fnce_nm = this.fnceFltrOptns[i].fnce_nm;
        this.end_fnce_id = this.fnceFltrOptns[i].fnce_id;
        this.lat = this.fnceFltrOptns[i].lat;
        this.lng = this.fnceFltrOptns[i].lng;

        break;
      }
    }
    this.getSvmTripDetails(this.routeForm.controls.routes, value, indx, this.end_fnce_nm, this.lat, this.lng);
  }
  getSvmTripDetails(a, val, ind, fnce_nm, lat, lng) {
    a.controls[ind + 1].get('strt_fnce_id').setValue(val);
    a.controls[ind + 1].get('strt_fnce_nm').setValue(fnce_nm);
    a.controls[ind].get('end_fnce_nm').setValue(fnce_nm);
    a.controls[ind + 1].get('strt_fnce_lat').setValue(lat);
    a.controls[ind + 1].get('strt_fnce_lng').setValue(lng);
    a.controls[ind].get('end_fnce_lat').setValue(lat);
    a.controls[ind].get('end_fnce_lng').setValue(lng);

  }
  onIpSelected(value, indx, control) {
    console.log(control.controls)
    // control.controls[indx].get('ip_fnce_id').setValue(value);
    for (let i = 0; i < this.interfnceFltrOptns.length; i++) {
      if (this.interfnceFltrOptns[i].fnce_id == value) {
        // this.interfnceFltrOptns[indx] = {
        //   "fnce_id": this.interfnceFltrOptns[i].fnce_id, "fnce_nm": this.interfnceFltrOptns[i].fnce_nm,
        //   "fnce_grp_nm": this.interfnceFltrOptns[i].fnce_grp_nm, "lat": this.interfnceFltrOptns[i].lat, "lng": this.interfnceFltrOptns[i].lng
        // };
        // this.ip_fnce_nm = ;
        // this.ip_fnce_id = this.interfnceFltrOptns[i].fnce_id;
        // this.ip_fnce_lat = this.interfnceFltrOptns[i].lat;
        // this.ip_fnce_lng = this.interfnceFltrOptns[i].lng;
        control.controls[indx].get('ip_fnce_nm').setValue(this.interfnceFltrOptns[i].fnce_nm);
        control.controls[indx].get('ip_fnce_lat').setValue(this.interfnceFltrOptns[i].lat);
        control.controls[indx].get('ip_fnce_lng').setValue(this.interfnceFltrOptns[i].lng);


        break;
      }


    }
  }
  strtedtime(value: Date, indx): void {
  }
  endtime(value: Date, indx): void {
  }
  getSvmTripsDtls() {
    this.tripFnceForm.reset();
    this.routeForm.reset();
    this.routeForm.controls.routes = new FormArray([]);
    this.svmRteDtlsData = [];
    this.isEdit = false;
    // this.showLdr=true;
    var postData = {
      svm_id: this.svm_id,
      tnt_id: this.tnt_id,
      asrt_id: this.asrt_id,
      trp_typ_id: this.trp_typ_id
    }
    console.log(postData)
    if (this.svm_id > -1 && this.asrt_id) {
      this.apiServ.post(postData, `tripschedule/sachivalayam/trips/getRteDtls`).subscribe((res) => {
        if (res['status'] == 200) {
          this.svmRteDtlsData = res['data'];
          // console.log(this.svmRteDtlsData.length)
          if (this.svmRteDtlsData.length > 0) {
            this.isEdit = true;
            // this.showLdr=false;
            // console.log(this.svmRteDtlsData);                                                                                                                                   'pgj
            let k = this.svmRteDtlsData.length - 1;
            this.strtFnceProp = { "fnce_id": this.svmRteDtlsData[0].strt_fnce_id, "fnce_nm": this.svmRteDtlsData[0].strt_fnce_nm, "lat": this.svmRteDtlsData[0].strt_fnce_lat, "lng": this.svmRteDtlsData[0].strt_fnce_lng };
            this.endFnceProp = { "fnce_id": this.svmRteDtlsData[k].strt_fnce_id, "fnce_nm": this.svmRteDtlsData[k].strt_fnce_nm, "lat": this.svmRteDtlsData[k].strt_fnce_lat, "lng": this.svmRteDtlsData[k].strt_fnce_lng };
            this.tripFnceForm.get('strt_fnce_id').setValue(this.svmRteDtlsData[0].strt_fnce_id);
            this.tripFnceForm.get('end_fnce_id').setValue(this.svmRteDtlsData[0].end_fnce_id);
            console.log(this.tripFnceForm.value);
            this.frmfnceSelected(this.svmRteDtlsData[0].strt_fnce_id);
            this.endfnceSelected(this.svmRteDtlsData[0].end_fnce_id);
            this.current = 3;
            this.changeContent();
          } else {
            this.isEdit = false;
            // this.showLdr = false;
            this.rte_nm = null;
            this.tripFnceForm.reset();
            this.routeForm.reset();
            this.current = 0;
            this.changeContent();
            // this.ipForm.reset();

          }
          if (this.tripFnceForm.value.strt_fnce_id && this.tripFnceForm.value.end_fnce_id) {
            this.shwTrp = true;
          } else {
            this.shwTrp = false;
          }

        }
      });
    } else {
      // this.createMessage('warning', 'please select sachivalayam and vehicle')
    }

  }
  showIpForm(controls, i, idx) {
    console.log(controls, i)
    this.masterpntData = controls.controls[i];

    controls.controls.forEach(e => {

      if (e.value.trp_schde_id == controls.controls[i].value.trp_schde_id) {
        e.value.shwIpadd = true;
        this.cd.detectChanges();
        // controls.controls[i].value.shwIpadd=true;
      } else {
        e.value.shwIpadd = false;
        // controls.controls[i].value.shwIpadd=false;
      }
    })
    // console.log(controls.controls[i].value)
  }

  showConfirmDelete(i, data) {
    console.log(data.controls[i].value)
    this.modal.confirm({
      nzTitle: 'Are you sure delete this Trip?',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => this.delete(data.controls[i].value),
      nzCancelText: 'No',
      nzOnCancel: () => this.cancel(),
    });
  }
  delete(s) {
    var data = {
      trp_schde_id: s.trp_schde_id
    }
    this.apiServ.post(data, `tripschedule/trips/schedule/trips/deletetrip`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Trip successfully Deleted');
        this.getSvmTripsDtls();
      }
    })

  }
  cancel(): void {
    this.message.info('Trip Not Deleted');
  }

  saveIps(points,rte) {
    // console.log(points.controls);
    this.ipPtsArry = [];
    var count = 0;

    this.addIPfncLst = points.controls;
    // console.log(this.addIPfncLst)

    this.trpSchde_id = this.addIPfncLst[0].value.trp_schde_id
    for (let r of this.addIPfncLst) {
      count++;
      if (r.value.ip_fnce_id != null) {
        this.ipPtsArry.push({
          'trp_schde_id': this.trpSchde_id,
          'fnce_sq_id': count,
          'fnce_id': r.value.ip_fnce_id,
          'tnt_id': this.tnt_id
        })
      }else{
        this.ipPtsArry=[{
          'trp_schde_id': rte.trp_schde_id.value,
          'fnce_id': 0,
          'tnt_id': this.tnt_id
        }]
      }


    }
    // console.log(this.ipPtsArry)
    this.apiServ.post(this.ipPtsArry, `tripschedule/sachivalayam/trips/addIntrmdtePts`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('success', 'Intermediate Locations Added Successfully');
        this.current = 3;
        this.changeContent();
        this.getSvmTripsDtls();
      } else {
        this.createMessage('warning', 'Incorrect Data');
      }
    });
  }
  showStrtEdit(i, data) {

    this.selectedData = data.controls;
    this.selectedData.forEach(e => {
      if (e.value.trp_schde_id == data.controls[i].value.trp_schde_id) {
        e.get('shwEdtStrt').setValue(true);
        // value.shwEdtStrt = true;
        this.cd.detectChanges();
      } else {
        // e.value.shwEdtStrt = false;
        e.get('shwEdtStrt').setValue(false);
      }
    });
  }
  showEndEdit(i, data) {

    this.selectedData = data.controls;
    this.selectedData.forEach(e => {
      if (e.value.trp_schde_id == data.controls[i].value.trp_schde_id) {
        e.get('shwEdtEnd').setValue(true);
        this.cd.detectChanges();
      } else {
        e.get('shwEdtEnd').setValue(false);
      }
    });
  }
  edtstrtedtime(evt, i, controls) {
    // console.log(controls.controls[i],show);
    console.log(this.selectedData)

    var data = {
      trp_schde_id: controls.controls[i].value.trp_schde_id,
      schdle_strt_tm: moment(evt, ["h:mm A"]).format("HH:mm"),
      key: 'strTm'
    }
    console.log(data);
    if (this.selectedData[i].value.shwEdtStrt == true) {
      this.updateCnfrmMdl('Start Time', data)
    }

  }
  edtEndtime(evt, i, controls) {
    // console.log(controls.controls[i],show);
    console.log(this.selectedData)

    var data = {
      trp_schde_id: controls.controls[i].value.trp_schde_id,
      schdle_end_tm: moment(evt, ["h:mm A"]).format("HH:mm"),
      key: 'strTm'
    }
    console.log(data);
    if (this.selectedData[i].value.shwEdtStrt == true) {
      this.updateCnfrmMdl('End Time', data)
    }

  }
  updateCnfrmMdl(ele, data) {
    this.modal.confirm({
      nzTitle: `Are you sure Do You Want To Change ${ele}?`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.showLdr = true;
        this.apiServ.post(data, `tripschedule/trips/schedule/trips/chngeVehcle`).subscribe((res) => {
          if (res['status'] == 200) {
            this.showLdr = false;
            this.createMessage('Success', `${ele} successfully Updated`);
            this.selectedData.forEach(e => {
              e.get('shwEdtStrt').setValue(false);
              e.get('shwEdtEnd').setValue(false);
            })
            this.getSvmTripsDtls();

          }
        })
      },
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.selectedData.forEach(e => {
          e.get('shwEdtStrt').setValue(false);
          e.get('shwEdtEnd').setValue(false);
        })
      },
    });
  }
}
