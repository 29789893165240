<div class="main">

    <!-- <div class="row"> -->
    <div id="lctnmap"></div>
<!-- </div> -->

    <div class="row">
        <div>Drag&nbsp;<img style="height: 18px;" src="/assets/images/markers/mapIcons/m/location.png">&nbsp;on Map to get Latitude and Longitude or Enter Latitude and Longitude</div> 
        <!-- <div class="row mg-b6">
            <nz-form-label class="lbltxt">Location name</nz-form-label>
            <div class="col-lg-12 col-md-12">
                <input type="text" nz-input placeholder="Location name" formControlName="fnce_nm" />
            </div>
        </div> -->

        <!--  <form nz-form [formGroup]="addFncForm" class="frmALgn"> -->
        <div class="row mg-b6 lblWdth">
            <nz-form-label class="lbltxt">Location name <span>*</span></nz-form-label>
            <div class="col-lg-12 col-md-12">
                <nz-form-item style="margin: 0 0 10px;">
                    <nz-form-control>
                        <input type="text" nz-input placeholder="Location name" [(ngModel)]="fnce_nm" />
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row mg-b6 lblWdth">
            <nz-form-label class="lbltxt">Select Location Group <span>*</span></nz-form-label>
            <div class="col-lg-12 col-md-12">
                <nz-form-item style="margin: 0 0 10px;">
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Location Group"
                        [(ngModel)]="fnce_grp">
                            <nz-option *ngFor="let fg of tpGrpLst" [nzValue]="fg.fnce_grp_id"
                                [nzLabel]="fg.fnce_grp_nm">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row mg-b6 lblWdth">
            <nz-form-label class="lbltxt">Latitude <span>*</span></nz-form-label>
            <div class="col-lg-12 col-md-12">
                <nz-form-item style="margin: 0 0 10px;">
                    <nz-form-control>
                        <input type="number" nz-input placeholder="Latitude"  [(ngModel)]=drgndLat (ngModelChange)="ondrag()"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row mg-b6 lblWdth">
            <nz-form-label class="lbltxt">Longitude <span>*</span></nz-form-label>
            <div class="col-lg-12 col-md-12">
                <nz-form-item style="margin: 0 0 10px;">
                    <nz-form-control>
                        <input type="text" nz-input placeholder="Longitude"  [(ngModel)]=drgndLng (ngModelChange)="ondrag()"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row mg-b6 lblWdth">
            <nz-form-label class="lbltxt">Radius (meters) <span>*</span></nz-form-label>
            <div class="col-lg-12 col-md-12">
                <nz-form-item style="margin: 0 0 10px;">
                    <nz-form-control>
                        <input type="number" nz-input placeholder="Radius" [(ngModel)]="rds" />
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    <!-- </form> -->
        <!-- <div *ngIf="addFncForm.status == 'INVALID' && shwErrMsg" class="text-center">
        Please fill required feilds
    </div> -->
        <!-- <div class="input-row" nz-row style="width: 100%;">
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn btnGrp"> <button nz-button
                    nzType="primary" class=" submit w-30-p save-button-color" (click)="addNewFnc()">SAVE</button>
                <button nz-button nzType="primary" (click)="reset()" color=""
                    class="w-30-p ml-24 reset-button-color">RESET</button>
            </div>
        </div> -->
        <div class="btngrp">            
            <button nz-button  (click)="addNewFnc()" class="sbmtbtn">SAVE</button>
            <button nz-button style="margin-right: 8px;" (click)="reset()" nzType="primary"
              nzDanger>RESET</button>
          </div>
    </div>
</div>
<!-- <div id="map"></div> -->
<!-- <script src="http://cdn.leafletjs.com/leaflet-0.7.3/leaflet.js"></script> Orginal: http://cdn.leafletjs.com/leaflet-0.7.3/leaflet.js -->
<!-- <script>
// var tileLayer = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
// attribution: ''
// });
//remember last position
var rememberLat = document.getElementById('latitude').value;
var rememberLong = document.getElementById('longitude').value;
if( !rememberLat || !rememberLong ) { rememberLat = 18.53; rememberLong = 73.85;}
// var map = new L.Map('map', {
// 'center': [rememberLat, rememberLong],
// 'zoom': 12,
// 'layers': [tileLayer]
// });
var marker = L.marker([rememberLat, rememberLong],{
draggable: true
}).addTo(map);
marker.on('dragend', function (e) {
updateLatLng(marker.getLatLng().lat, marker.getLatLng().lng);
});
map.on('click', function (e) {
marker.setLatLng(e.latlng);
updateLatLng(marker.getLatLng().lat, marker.getLatLng().lng);
});
function updateLatLng(lat,lng,reverse) {
if(reverse) {
marker.setLatLng([lat,lng]);
map.panTo([lat,lng]);
} else {
document.getElementById('latitude').value = marker.getLatLng().lat;
document.getElementById('longitude').value = marker.getLatLng().lng;
map.panTo([lat,lng]);
}
}
</script> -->
<!-- <link rel="stylesheet" href="http://cdn.leafletjs.com/leaflet-0.7.3/leaflet.css" /> original: http://cdn.leafletjs.com/leaflet-0.7.3/leaflet.css -->
<!-- <style>
/* html, body, #container, #map {
padding: 0;
margin: 0;
}
html, body, #map, #container {
height: 460px;
} */
#map{
    height: 30vh;
    width: 40vw;
    position: relative;
}
</style> -->