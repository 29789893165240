import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';

@Component({
  selector: 'app-svm-attendance',
  templateUrl: './svm-attendance.component.html',
  styleUrls: ['./svm-attendance.component.scss']
})
export class SvmAttendanceComponent implements OnChanges {
  @Input() svm;
  svmId: any;
  today: any = new Date().toISOString().slice(0, 10);
  svmEmpCrdCont: any=[];
  attnPer: any = 0;
  attnPer1: any = 100 - this.attnPer;
  mapData: any;
  latlng: any;
  map1: any;
  markerIcon1: { icon: any; };
  visible: boolean;
  svmEmpNtPrsnt: any;
  columnDefs1:any=[];
  svmSvnDysAttnEmp: any=[];
  chartsData: any;
  owms_svm_id: any;
  svm_apfs_cd: any;
  dateFormat = 'dd/MM/yyyy';
  slctdDt = new Date();
  // today = new Date();

  constructor(public apiSrv: AppsService,) { }

  // ngOnInit(): void {
  //   this.svmId=this.svm;
    
  // }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.svm.currentValue);
    this.svmId=changes.svm.currentValue.svm_id;
    this.owms_svm_id=changes.svm.currentValue.owms_svm_id;
    this.svm_apfs_cd=changes.svm.currentValue.svm_apfss_cd;
    // this.svm_apfs_cd=1064022;
    this.getSvmEmpCrdCount();
    this.getSachivalayamFenceData();
    this.getSvmNtPrsntEmp();
    this.getsvmSvnDysAttnEmp();

  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  onChange(e): void {
    this.slctdDt = e;
    this.getSvmNtPrsntEmp();
  }
  getSvmEmpCrdCount() {
    // const rte = `support/svm/svmEmpNtPrsnt`
    const rte = 'external/owms/sachivalayam/employee/absentee/count';
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      svm_id: 0,
      svm_apfs_cd:this.svm_apfs_cd,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.svmEmpCrdCont = res['data'];
          console.log(this.svmEmpCrdCont);
        //console.log(this.svmEmpCrdCont[0].prsnt_emp)
        //console.log(this.svmEmpCrdCont[0].tot_emp)
        this.attnPer = (this.svmEmpCrdCont[0].prsnt_emp / this.svmEmpCrdCont[0].tot_emp) * 100;
        //console.log(this.attnPer)
        this.attnPer = this.attnPer.toFixed(2);
        this.attnPer1 = 100 - this.attnPer;
        this.attnPer1 = this.attnPer1.toFixed(2);
        //console.log(this.attnPer, this.attnPer1);
        // this.barChartDataBig = [
        //   { data: [this.gatesPer], label: 'Gates Scanned %', stack: 'a' },
        //   { data: [this.gatesPer1], label: 'un Gates Scanned %', stack: 'a' },
        //   { data: [this.attnPer], label: 'Attendance Present %', stack: 'b' },
        //   { data: [this.attnPer1], label: 'Attendance Absent %', stack: 'b' },
        //   { data: [this.wemntPer], label: 'Weighment Collected %', stack: 'c' },
        //   { data: [this.wemntPer1], label: 'Weighment  not Collected %', stack: 'c' }
        // ]

      }
      else {
       
     }
    })
  }
  initializeMapOptions1() {
    //   console.log(house);
    //   console.log("In Mapppppp");
     
  setTimeout(() => {
    
 
    var map1 = L.map('map1', {
    })
    map1.remove();
    
  
    this.map1 = L.map('map1').setView([16.708785, 81.108350], 14);
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    //   maxZoom: 19,

    // }).addTo(this.map1);
    L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 19,
      subdomains:['mt0','mt1','mt2','mt3'],
      attribution: ''
    }).addTo(this.map1);
    
    this.markerIcon1 = {
      icon: L.icon({
        iconSize: [30, 30],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: `../../../../assets/images/app-store/map`
      })
    };
    var latlngs = [[17.000045, 81.798557], [17.001451, 81.802967], [17.001985, 81.807849],
    [16.999133, 81.801809], [17.000045, 81.798557]];

    var polygon = L.polygon(this.mapData as [], { color: '#f17c7c' }).addTo(this.map1);
    // zoom the map to the polygon
    this.map1.fitBounds(polygon.getBounds());
  
  }, 1000);

  }
  getSachivalayamFenceData(){
    console.log(this.svmId)
    const rte = `external/owms/sachivalayams/fence/${this.svm_apfs_cd}`;
    
    this.apiSrv.get(rte).subscribe((res) => {
      if(res['status']==200){
        console.log(res['data'][0])
        this.latlng=res['data'][0]['fence'].substring(res['data'][0]['fence'].indexOf("((")+2,res['data'][0]['fence'].indexOf("))")).split(",");
        this.mapData=[];
        for(let i=0;i<this.latlng.length;i++){
          let data=this.latlng[i].split(" ").reverse();
          data=data.filter(e=>{
            return e!="";
          })
          this.mapData.push(data);
        }
        
        this.initializeMapOptions1();

      }

    });
  }
  close1(): void {
    this.visible = false;
    this.map1.off();
    this.map1.remove();


  }
  getSvmNtPrsntEmp() {
    // const rte = "support/svm/svmEmpNtPrsntt";
    const rte = 'external/owms/sachivalayam/employee/absentees';
    let postdata = {
      from_dt:  moment(this.slctdDt).format('YYYY-MM-DD'),
      indicator: 3,
      svm_id: 0,
      svm_apfs_cd:this.svm_apfs_cd,
      to_dt:  moment(this.slctdDt).format('YYYY-MM-DD')
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.svmEmpNtPrsnt = res['data'];
        //   console.log(this.svmEmpNtPrsnt);
        for (let k = 0; k < this.svmEmpNtPrsnt.length; k++) {
          this.svmEmpNtPrsnt[k]['sno'] = k + 1;
        }

        this.columnDefs1 = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'Date', field: 'dt', alignment: 'center', filter: false },
          { headerName: 'Employee Name', field: 'emp_nm', alignment: 'left',filter: false },
          { headerName: 'Employee ID', alignment: 'center', field: 'emp_id' },
          { headerName: 'Mobile No', alignment: 'center',  field: 'mble_nu' },
          { headerName: 'Designation', field: 'dsgn_nm',alignment: 'left', hide: true },
          { headerName: 'Status', field: 'prsnt_sts',alignment: 'left', hide: true }

        ]

      }
      else {
      
     }
    })

  }
  getsvmSvnDysAttnEmp() {
    // const rte = "support/svm/svmSvnDysAttnEmp";
    const rte = "external/owms/sachivalayam/seven-days/attendance";
    let postdata = {
      from_dt: this.today,
      indicator: 3,
      svm_id: 0,
      svm_apfs_cd:this.svm_apfs_cd,
      to_dt: this.today
    }
    //console.log("post data",postdata)
    this.apiSrv.post(postdata, rte).subscribe((res) => {

      if (res['status'] == 200) {

        this.svmSvnDysAttnEmp = res['data'];
        for (let i = this.svmSvnDysAttnEmp.length - 1; i >= 0; i--) {
          this.svmSvnDysAttnEmp[i]['absnt_emp']=this.svmSvnDysAttnEmp[i].tot_emps - this.svmSvnDysAttnEmp[i].prsnt_emps;
          // .push({absnt_emp:this.svmSvnDysAttnEmp[i].tot_emps - this.svmSvnDysAttnEmp[i].prsnt_emps});
          //this.barXaxis1.push(this.svmSvnDysAttnEmp[i].dt_nm);
        }
          console.log(this.svmSvnDysAttnEmp);

        // for (let i = this.svmSvnDysAttnEmp.length - 1; i >= 0; i--) {
        //   this.barData11.push(this.svmSvnDysAttnEmp[i].tot_emps - this.svmSvnDysAttnEmp[i].prsnt_emps);
        //   this.barData12.push(this.svmSvnDysAttnEmp[i].prsnt_emps);
        //   //this.barXaxis1.push(this.svmSvnDysAttnEmp[i].dt_nm);
        // }

        // this.barChartData1 = [

        //   { data: [...this.barData12], label: 'Present Employees', stack: 'a' },
        //   { data: [...this.barData11], label: 'Absent Employees', stack: 'a' }

        // ];
        this.chartsData=this.svmSvnDysAttnEmp;
        let chart1 = am4core.create('attndnceChart', am4charts.XYChart);
        chart1.data = this.chartsData;
      
        let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis1.dataFields.category = 'dt_nm';
        categoryAxis1.renderer.grid.template.location = 0;
        categoryAxis1.renderer.minGridDistance = 5;
        categoryAxis1.renderer.labels.template.rotation = 0;
        categoryAxis1.renderer.labels.template.fontSize = 10;
        categoryAxis1.title.text = ` ----- Date  ----- `;
  
        let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
        valueAxis1.title.text = '-- Attendance -- ';
    
        // Create series
        function createSeries(field, name,clr) {
    
          // Set up series
          let series1 = chart1.series.push(new am4charts.ColumnSeries());
          series1.name = name;
          series1.dataFields.valueY = field;
          series1.dataFields.categoryX = 'dt_nm';
          series1.sequencedInterpolation = true;
          series1.fill = am4core.color(clr);
  
          // Make it stacked
          series1.stacked = true;
    
          // Configure columns
          series1.columns.template.width = am4core.percent(60);
          series1.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series1.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;
    
          return series1;
    
        }
        chart1.legend = new am4charts.Legend();
  
    
        createSeries('prsnt_emps', 'Present Employees','#185aad');
        createSeries('absnt_emp', 'Absent Employees','#e0cd28');
    
      }
      else {
       
     }
    })
  }
}
