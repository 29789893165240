<div class="dashboard">
    <div class="row">
        <!-- <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="map1" class="shadowcard svmdbrdgrph"> </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12"> -->
            <div class="row" style="margin-left:8px;">Integration with OWMS Employee Attendance</div>
            <nz-card class="shadowcard" style="width:100%; height:auto;">
                <ul class="cards">
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">PH Workers</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">{{svmEmpCrdCont[0]?.ph_prsnt === null ? "0" :
                                            svmEmpCrdCont[0]?.ph_prsnt}}
                                        </h1>
                                        <h1 class="card_sub_title">Present</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{svmEmpCrdCont[0]?.ph_tot === null ? "0" :svmEmpCrdCont[0]?.ph_tot}}
                                        </h1>
                                        <h1 class="card_sub_title">Total Employees</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg, #1f694b 0%,#257175 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Loaders</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">{{svmEmpCrdCont[0]?.ld_prsnt === null ? "0" :
                                            svmEmpCrdCont[0]?.ld_prsnt}}
                                        </h1>
                                        <h1 class="card_sub_title">Present</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{svmEmpCrdCont[0]?.ld_tot === null ? "0" :svmEmpCrdCont[0]?.ld_tot}}
                                        </h1>
                                        <h1 class="card_sub_title">Total Employees</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg,  #478197 0%,#246d77 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Supervisors</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">{{svmEmpCrdCont[0]?.sp_prsnt === null ? "0" :
                                            svmEmpCrdCont[0]?.sp_prsnt}}
                                        </h1>
                                        <h1 class="card_sub_title">Present</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{svmEmpCrdCont[0]?.sp_tot === null ? "0" :svmEmpCrdCont[0]?.sp_tot}}
                                        </h1>
                                        <h1 class="card_sub_title">Total Employees</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg, #774971  0%,#411c3c 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Drivers</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">{{svmEmpCrdCont[0]?.dr_prsnt === null ? "0" :
                                            svmEmpCrdCont[0]?.dr_prsnt}}
                                        </h1>
                                        <h1 class="card_sub_title">Present</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm">
                                            {{svmEmpCrdCont[0]?.dr_tot === null ? "0" :svmEmpCrdCont[0]?.dr_tot}}
                                        </h1>
                                        <h1 class="card_sub_title">Total Employees</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </nz-card>
        <!-- </div> -->

    </div>
    <div class="row bg-wp" style="margin-top: 8px;
    padding: 4px;">
    <div nz-row [nzGutter]="8" style="margin-bottom:10px;">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="shadowcard gtsgrph verScroll">
                <div class="row">
                    <div class="crd-gp-hrd col-lg-8 col-md-8" style="color: #2656ff;padding-left: 10px;
                    padding-top: 10px;
                    letter-spacing: 0px;">Employees Attendance</div> 
                    <div class="col-lg-4 col-md-4 mg-4">
                        <span>Date:</span>
                        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                            (ngModelChange)="onChange($event)"></nz-date-picker>
                    </div>
                </div>
               
                <div class="row gridTop" id="target">
                    <div class="col-lg-12 col-md-12 mgnb-2 p-8">
                        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="svmEmpNtPrsnt"
                            [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                            [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true"
                            [showBorders]="true" style="width:100%">
                            <dxo-paging [pageSize]="5"></dxo-paging>
                            <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                                [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                            </dxo-pager>
                            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                            </dxo-search-panel>
                            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                            <dxo-group-panel [visible]="true"></dxo-group-panel>
                            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                            <dxo-column-chooser [enabled]="true" mode="select">
                            </dxo-column-chooser>
                            <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                            <dxo-export [enabled]="true" fileName="Sachivalayam Data"></dxo-export>
                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                            <dxi-column *ngFor="let d of columnDefs1" [dataField]="d.field" [caption]="d.headerName"
                                [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                            </dxi-column>
                        </dx-data-grid>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="shadowcard gtsgrph">
                <p class="crd-gp-hrd" style="color: #2656ff;padding-left: 10px;
                        padding-top: 10px;
                        letter-spacing: 0px;">Attendance in Last 7 Days</p>
                <div style="display: block;" style="margin-top: 1.5%;">
                    <div id="attndnceChart"></div>

                </div>
            </div>
        </div>
    </div>
</div>

</div>