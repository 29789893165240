<ds-loading *ngIf="showLdr"></ds-loading>
<div class="pg pg-drvrdshbrd">
    <div class="row" style="width: 100%;">
        <div class="col-lg-8 col-md-8">
            <div class="row wrapper">
                <div id="slide">
                    <span>
                        <img src="../../../../../assets/images/driver.png">
                    </span>
                    <span class="crwDtl_crd">
                        <h1>Mr.{{crwDtls.crw_nm}}</h1>
                        <!-- <p>Adding Inteligence to your Freight</p> -->
                        <!-- <p> -->
                           <h2><span><i nz-icon [nzType]="'phone'" [nzTheme]="'outline'"></i>&nbsp;<b>Phone No:</b>&nbsp;{{(!crwDtls.crw_ph|| crwDtls.crw_ph == 0 || crwDtls.crw_ph == null) ? 'Mobile Number Not Provided':crwDtls.crw_ph}}</span></h2> 
                           <h2><span><i nz-icon [nzType]="'idcard'" [nzTheme]="'outline'"></i>&nbsp;<b>Licence No:</b>&nbsp;{{(!crwDtls.crw_lcnse_nu ||crwDtls.crw_lcnse_nu == "undefined" || crwDtls.crw_lcnse_nu == null) ? 'Licence Not Provided':crwDtls.crw_lcnse_nu}}</span></h2> 
                            <!-- <span><i nz-icon [nzType]="'calendar'" [nzTheme]="'outline'"></i>&nbsp;{{(!crw_doj ||crw_doj =="00-00-0000" || crw_doj == null) ? 'NA' : crw_doj}}</span> -->
                            <h2><span><i nz-icon [nzType]="'car'" [nzTheme]="'outline'"></i>&nbsp;<b>Vehicle No:</b>&nbsp;{{(!crwDtls.asrt_nm|| crwDtls.asrt_nm == 0 || crwDtls.asrt_nm == null) ? 'Vehicle Not Provided':crwDtls.asrt_nm}}<b *ngIf="crwDtls.asrt_sts_nm" [ngStyle]="{'background-color': crwDtls.bg_clr_cd, 'color': crwDtls.clr_cd}" style="margin-left: 3px;">&nbsp;{{crwDtls.asrt_sts_nm}}</b></span></h2> 
                            <h2 *ngIf="clntDtls[0].clnt_id==7"><span><i nz-icon [nzType]="'environment'" [nzTheme]="'outline'"></i>&nbsp;<b>Sachivalayam:</b>&nbsp;{{(!crwDtls.svm_nm|| crwDtls.svm_nm == 0 || crwDtls.svm_nm == null) ? 'Sachivalayam Not Provided':crwDtls.svm_nm}}</span></h2> 
                            
                        <!-- </p> -->
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 col-md-2"></div>
                <div class="col-lg-4 col-md-4 cl_card">
                    <i nz-icon nzType="car" nzTheme="outline"></i>
                    <h1 *ngIf="drvrTtlDtls && drvrTtlDtls.length>0">{{(!drvrTtlDtls[1][0].trp_cnt || drvrTtlDtls[1][0].trp_cnt== 0 || drvrTtlDtls[1][0].trp_cnt == null) ? 'NA': drvrTtlDtls[1][0].trp_cnt}}</h1>
                    <p>Today Total Trips</p>
                </div>
                <div class="col-lg-4 col-md-4 cl_card">
                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                    <h1 *ngIf="drvrTtlDtls && drvrTtlDtls.length>0">{{(!drvrTtlDtls[0][0].tot_dst || drvrTtlDtls[0][0].tot_dst== 0 || drvrTtlDtls[0][0].tot_dst == null) ? 'NA': drvrTtlDtls[0][0].tot_dst}}</h1>
                    <p>Today Total Distance</p>
                </div>
                <div class="col-lg-2 col-md-2"></div>
                <!-- <div class="col-lg-4 col-md-4 cl_card">
                    <i nz-icon nzType="question-circle" nzTheme="outline"></i>
                    <h1 *ngIf="drvrTtlDtls && drvrTtlDtls.length>0">{{(!drvrTtlDtls[0].fl_ltrs_ct ||drvrTtlDtls[0].fl_ltrs_ct == 0 || drvrTtlDtls[0].fl_ltrs_ct == null) ? 'NA':drvrTtlDtls[0].fl_ltrs_ct}}</h1>
                    <p>Total Fuel Consumption</p>
                </div> -->
            </div>
        </div>
        <div class="col-lg-4 col-md-4 card" style="padding: 0px;">
            <div id="driverMap"></div>
        </div>
    </div>
    <div class="row bg-w chart-card">
        <div class="col-lg-12 col-md-12 crd">
            <h1>Last 7days 24/7 Distance covered by driver</h1>
            <div id="hepachartdiv"></div>
           
        </div>
    </div>
    <div class="row bg-w chart-card">
        <div class="col-lg-6 col-md-6 w-50 crd">
            <h1>Last 7Days Distance Travelled</h1>
            <div id="lastSevenDaysDistanceChart"></div>
            <div *ngIf="drvrDtls && drvrDtls.length <= 0 " style="text-align: center;">
                Distance not found  for the vehicle assigned to the driver
            </div>
        </div>
        <div class="col-lg-6 col-md-6 w-50 crd">
            <h1>Last 7Days Trips Travelled</h1>
            <div>
                <div id="lastSevenDaysChart"></div>
            </div>
            <div *ngIf="drvrDtls && drvrDtls.length <= 0 " style="text-align: center;">
                No trips defined for the vehicle assigned to this driver
            </div>

        </div>
    </div>

    <div class="row bg-w chart-card">
        <div class="col-lg-6 col-md-6 w-50 crd bxshw_n">
            <h1>Operational Details In Last 7Days</h1>
            <div *ngIf="drvrOptnlDtls && drvrOptnlDtls.length>0">
                <table class="cstm_tbl">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Vehicle No.</th>
                            <th>Vehicle Category</th>
                            <th>Operational Hours</th>
                            <th>Travelled(km)</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let o of drvrOptnlDtls">
                            <td>{{o.dt}}</td>
                            <td>{{o.asrt_nm}}</td>
                            <td>{{o.asrt_ctgry_nm}}</td>
                            <td>{{o.oprtn_hrs_ct}}</td>
                            <td>{{o.dst}}</td>
                            <td *ngIf="o.operated == 1" style="color: #009688;">Operated</td>
                            <td *ngIf="o.operated == 0" style="color: #9c27b0;">Not-Operated</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row NtfctnCrd"  *ngIf="oprtnlhrsMsg">
                <h5 style="text-align: center;">{{oprtnlhrsMsg}}</h5>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 w-50 crd bxshw_n">
            <h1 style="padding-left: 20px;">Last 7Days Speed Violation</h1>
            <div class="row" *ngIf="drvrSpdDtls && drvrSpdDtls.length>0">
                <table class="cstm_tbl">
                    <thead>
                        <tr>
                            <th>Recorded Date</th>
                            <th>Vehicle No.</th>
                            <th>Vehicle Category</th>
                            <th>Speed(km)</th>
                            <th>Track Vehicle</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of drvrSpdDtls">
                            <td>{{t.dt}}</td>
                            <td>{{t.asrt_nm}}</td>
                            <td>{{t.asrt_ctgry_nm}}</td>
                            <td>{{t.speed}}</td>
                            <td><a href="https://www.google.com/maps/search/?api=1&query={{t.r_lat}},{{t.r_lng}}"  target="_blank"><i nz-icon nzType="node-index" nzTheme="outline"></i>Track</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row NtfctnCrd"  *ngIf="fuelDtaMsg">
                <h5 style="text-align: center;">{{fuelDtaMsg}}</h5>
            </div>
        </div>
    </div>

</div>