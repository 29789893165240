import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-ulbs',
  templateUrl: './ulbs.html',
  styleUrls: ['./ulbs.scss']
})
export class ULBsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  ulbLst: any;
  columnDefs;
  shwSidebar = false;
  ulbDt: any = {};
  ulbNm: string | null = null;
  shwULBAlrt = false;
  shwULBEditDiv = false;
  ulbId: any;
  sidebarHeading: string;
  shwULBDelDiv: boolean;
  shwLdg: boolean;
  ulbs = [];
  clntDtls: any;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.getDstrcts();
    this.getULBsLst();
  }

  getULBsLst() {
    this.ulbs = [];
    this.shwLdg = true;
    const rte = 'admin/ulbs/list';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      this.shwLdg = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          // console.log(this.clntDtls[0]);
          // let tnt = this.clntDtls[0].tnts[0].ulb_nm.split(" ");
          // tslint:disable-next-line:prefer-for-of
          // for (let u = 0; u < res['data'].length; u++){
          //   if (tnt[0] === res['data'][u].ulb_nm){
          //     this.ulbs.push(res['data'][u]);
          //   console.log(this.ulbs);
          //   }
          // }
          this.ulbLst = res['data'];

          // this.ulbLst.push(this.ulbs);
          // this.ulbLst = res['data'];
          // console.log(this.ulbLst);
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'ULB Name', field: 'ulb_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 280 },
            // { headerName: 'ULB Api Id', field: 'ulb_api_id', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            // { headerName: 'ULB Code', field: 'ulb_cd', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Lat', field: 'lat', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'Lng', field: 'lng', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100 },
            { headerName: 'District', field: 'dstrt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 200 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true, visible: false },
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    });
  }
  districts: any = [];
  getDstrcts() {
    this.apiSrv.get('/admin/districts').subscribe((res) => {
      this.districts = res['data'];
    })
  }
  filteredOptions: any = [];
  onChange(value): void {
    this.filteredOptions = this.districts.filter(option => option.dstrt_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getDstrtID(id) {
    this.ulbDt.dstrt_id = id;
  }
  onCellClick(opt, edtData) {
    this.ulbDt = {
      ulbId: edtData.data.ulb_id,
      ulbNm: edtData.data.ulb_nm,
      apiId: edtData.data.ulb_api_id,
      cd: edtData.data.ulb_cd,
      lat: edtData.data.lat,
      lng: edtData.data.lng,
      dstrct_id: edtData.data.dstrt_id,
      dstrct_nm: edtData.data.dstrt_nm
    }
    if (opt === 'edit') {
      this.shwULBEditDiv = true;
    }
    if (opt === 'delete') {
      this.shwULBEditDiv = false;
      this.shwULBDelDiv = true;
    }
    this.openSideBar(opt);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New ULB',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwULBEditDiv = true;
      this.shwULBDelDiv = false;
      this.sidebarHeading = 'Edit ULB';
    } else if (opt === 'delete') {
      this.shwULBEditDiv = false;
      this.shwULBDelDiv = true;
      this.sidebarHeading = 'Delete ULB';
    } else {
      this.shwULBEditDiv = false;
      this.shwULBDelDiv = false;
      this.ulbDt = {};
      this.sidebarHeading = 'Add New ULB';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  saveULB() {
    const rte = 'ulbs';
    this.apiSrv.create(this.ulbDt, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwULBAlrt = true;
        this.createMessage('success', 'ULB Successfully Added');
        this.getULBsLst();
      }
    });
  }

  saveEditULB() {
    //   console.log(this.ulbDt)
    const rte = 'admin/ulbs/'+this.ulbDt.ulbId;
    this.apiSrv.update(this.ulbDt, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwULBAlrt = true;
        this.createMessage('info', 'ULB Successfully Updated');
        this.getULBsLst();
      }
    });
  }

  saveDeleteULB() {
    // console.log('in del');
    const ulbDelData = {
      id: this.ulbDt.ulbId
    };

    const rte = 'ulbs/'+this.ulbDt.ulbId;
    this.apiSrv.delete( rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwULBAlrt = true;
        this.createMessage('warning', 'ULB Successfully Deleted');
        this.getULBsLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('ULB Not Deleted');
  }
}
