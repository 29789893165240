<div class="dashboard">
    <pg-header title="OWMS Dashboard" [bckbtn]="false" ticon="database"></pg-header>
    <div style="text-align: right;">
        <nz-date-picker nzFormat="dd-MM-yyyy"
        [nzDisabledDate]="disabledDate"
         [(ngModel)]="datePk" (ngModelChange)="onDtChange($event)" style="width:200px"></nz-date-picker>
    </div>
    <ds-loading *ngIf="showLdr"></ds-loading>
    <div class="row">
            <div class="col-md-6">
            <nz-card>
                <div class="gates-card-bg-color border-radius-3">
                    <p class="text-center font-size-18 font-weight-600 text-white  mt-0 mb-0">Gates Scanned</p>
                    <p class="text-center font-size-30 font-weight-600 text-white mt-0 mb-0">
                        {{cvrdCrdData.scnd_gts}}/{{cvrdCrdData.tot_gts}}</p>
                    <p class="text-center font-size-12 font-weight-600 text-white mt-0 mb-0">Scanned Gates vs Total
                        Gates</p>
                    <div>
                        <div class="m--space-10"></div>
                        <div class="progress">
                            <div class="progress-bar m-progress--sm prgsBrGte" role="progressbar"
                                [ngStyle]="getWdth(cvrdCrdData.tot_scnd_prcnt)" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                {{cvrdCrdData.tot_scnd_prcnt}}%
                            </div>
                        </div>
                        <div>
                            <p class="pdngRgt_4 ulbdshbrdOptns txtClrWhte ng-binding">{{cvrdCrdData.tot_scnd_prcnt}}%</p>
                        </div>
                    </div>
                </div>
                <div nz-row [nzGutter]="8"
                    style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);">
                    <div nz-col [nzSpan]="12" class="attnBdrRght">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Residential</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{cvrdCrdData?.hh_scnd}} /{{cvrdCrdData?.hh_tot}}</p>

                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">
                            Residential&Commercial</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{cvrdCrdData?.rc_scnd}} /{{cvrdCrdData?.rc_tot}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12" class="gtsBdrRght brdertop">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Apartments</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{cvrdCrdData?.a_scnd}} /{{cvrdCrdData?.a_tot}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                    <div nz-col [nzSpan]="12" class="brdertop">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Bulk Commercial
                        </p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{cvrdCrdData?.bc_scnd}} /{{cvrdCrdData?.bc_tot}}</p>
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Scanned vs Total
                            Gates</p>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mgnb-2 p-8" style="margin-top:3%">
                    <p class="crd-gp-hrd" style="color:#41abb3">Gates Scanned Last 7 Days </p>
                        <div style="display: block;" style="margin-top: 1.5%;">
                            <!-- <canvas baseChart class="hmechrt"
                              [datasets]="barChartData"
                              [labels]="barChartLabels"
                              [options]="barChartOptions"
                              [colors]="barChartColors"
                              [plugins]="barChartPlugins"
                              [legend]="barChartLegend"
                              [chartType]="barChartType">
                            </canvas> -->
                          </div>
                </div>

            </nz-card>
        </div>
        <div class="col-md-6">
            <nz-card>
                <div class="attendance-card-bg-color border-radius-3">
                    <p class="text-center font-size-18 font-weight-600 text-white  mt-0 mb-0">Attendance</p>
                    <p class="text-center font-size-30 font-weight-600 text-white mt-0 mb-0">
                        {{attndncCrdData?.prsnt_emp}}/{{attndncCrdData?.tot_emp}}</p>
                    <p class="text-center font-size-12 font-weight-600 text-white mt-0 mb-0">Total Present vs Total
                        Members</p>
                    <div>
                        <!-- <mat-progress-bar mode="determinate" value="4"></mat-progress-bar> -->
                        <div class="m--space-10"></div>
                        <div class="progress">
                            <div class="progress-bar m-progress--sm prgsBrGte" role="progressbar"
                                [ngStyle]="getAWdth(attdPer)" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">{{attdPer}}%
                            </div>
                        </div>
                        <div>
                            <p class="pdngRgt_4 ulbdshbrdOptns txtClrWhte ng-binding">{{attdPer}}%</p>
                        </div>


                    </div>
                </div>
                <div nz-row [nzGutter]="8"
                    style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 10%);">
                    <div nz-col [nzSpan]="12" class="attnBdrRght">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">PH Workers</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{attndncCrdData?.ph_prsnt}}/{{attndncCrdData?.ph_tot}}</p>
                        <!-- <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                    {{attndnceCrdRcvdData[0]?.ph_prsnt}}/{{attndnceCrdRcvdData[0]?.ph_tot}}</p> -->
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Present vs Work
                            Days</p>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Drivers</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{attndncCrdData?.dr_prsnt}}/{{attndncCrdData?.dr_tot}}</p>
                        <!-- <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                    {{attndnceCrdRcvdData[0]?.dr_prsnt}}/{{attndnceCrdRcvdData[0]?.dr_tot}}</p> -->
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Present vs Work
                            Days</p>
                    </div>
                    <div nz-col [nzSpan]="12" class="attnBdrRght brdertop">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Loaders</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{attndncCrdData?.ld_prsnt}}/{{attndncCrdData?.ld_tot}}</p>
                        <!-- <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                    {{attndnceCrdRcvdData[0]?.ld_prsnt}}/{{attndnceCrdRcvdData[0]?.ld_tot}}</p> -->
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Present vs Work
                            Days</p>
                    </div>
                    <div nz-col [nzSpan]="12" class="brdertop">
                        <p class="text-center font-size-14 font-weight-400 fuse-black-40-fg mt-2 mb-0">Supervisors</p>
                        <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                            {{attndncCrdData?.sp_prsnt}}/{{attndncCrdData?.sp_tot}}</p>
                        <!-- <p class="text-center font-size-24 font-weight-400 fuse-black-30-fg mt-0 mb-0">
                    {{attndnceCrdRcvdData[0]?.sp_prsnt}}/{{attndnceCrdRcvdData[0]?.sp_tot}}</p> -->
                        <p class="text-center font-size-10 font-weight-400 fuse-black-50-fg mt-0 mb-8">Present vs Work
                            Days</p>
                    </div>
                </div>
                  <div class="col-lg-12 col-md-12 mgnb-2 p-8" style="margin-top:3%">
                    <p class="crd-gp-hrd" style="color:#5f56bf">Attendance Last 7 Days</p>
                    <div style="display: block;"  style="margin-top: 1.5%;">
                        <!-- <canvas baseChart class="hmechrt"
                          [labels]="lineChartLabels"
                          [colors]="lineChartColors"
                          [options]="lineChartOptions"
                          [legend]="lineChartLegend"
                          [chartType]="lineChartType"
                          [plugins]="lineChartPlugins"
                          [datasets]="lineChartData">
                        </canvas> -->
                      </div>
                  </div>
            </nz-card>
        </div>
    </div>
    
    <div class="row">
        <nz-card style="margin: 1%;width: 100%;">
        <nz-tabset >
            
            <nz-tab nzTitle="Sachivalayam Data">
                <div class="col-md-12" *ngIf="usrAcsErrMsg == false">
                    <div (click)="createPdf()" *ngIf="USER_PERMISSIONS.insrt_in==1">
                      <i nz-icon nzType="file-pdf" nzTheme="twotone" class="header-icon" style=" position: absolute;
                          right: 275px;
                          top: 33px;
                          z-index: 1;
                          cursor: pointer;"></i>
                    </div>
                  </div>
                <div class="row gridTop" id="target" *ngIf="usrAcsErrMsg == false">
                    
                    <div class="col-lg-12 col-md-12 mgnb-2 p-8">
                    <dx-data-grid class="p-8" id="gridContainer" [dataSource]="svmTbleData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"  (onCellPrepared)="onCellPrepared1(columnDefs,$event)" (onCellClick)="onCellClick1($event)" [showColumnLines]="true"
                        [showRowLines]="true" [showBorders]="true" style="width:100%" >
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                        </dxo-pager>
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                        <dxo-column-chooser [enabled]="true" mode="select">
                        </dxo-column-chooser>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-export [enabled]="true" fileName="Sachivalayam Data"></dxo-export>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxi-column  dataField="sno" caption="Sno"
                            alignment="center" width="50" allowResizing="true">
                        </dxi-column>
                        <dxi-column  dataField="Date" caption="Date"
                            alignment="center" width="100" allowResizing="true">
                        </dxi-column>
                        <dxi-column  dataField="svm_nm" caption="Sachivalayam Name"
                            alignment="left" width="170" allowResizing="true">
                        </dxi-column>
                        <dxi-column caption="Scanned Gates" alignment="center">
                            <dxi-column dataField="Total_Gates" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="Scanned_Gates" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="Scanned_Percentage" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                        <dxi-column caption="Residential" alignment="center">
                            <dxi-column dataField="hh_tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="hh_scnd_gtes" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="hh_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                        <dxi-column caption="Commercial & Residential" alignment="center">
                            <dxi-column dataField="rc_tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="rc_scnd_gtes" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="rc_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                        <dxi-column caption="Apartment" alignment="center">
                            <dxi-column dataField="a_tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="a_scnd_gtes" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="a_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                        <dxi-column caption="Bulk Commercial" alignment="center">
                            <dxi-column dataField="bc_tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="bc_scnd_gtes" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="bc_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                    </dx-data-grid>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Micropocket Data">
                <div class="col-md-12" *ngIf="usrAcsErrMsg == false">
                    <div (click)="createPdf1()" *ngIf="USER_PERMISSIONS.insrt_in==1">
                      <i nz-icon nzType="file-pdf" nzTheme="twotone" class="header-icon" style=" position: absolute;
                          right: 275px;
                          top: 33px;
                          z-index: 1;
                          cursor: pointer;"></i>
                    </div>
                  </div>
                <div class="row gridTop" id="target1" *ngIf="usrAcsErrMsg == false">
                    
                    <div class="col-lg-12 col-md-12 mgnb-2 p-8">
                        <!-- [dataSource]="tcktsLst" -->
                    <dx-data-grid class="p-8" id="gridContainer1" [dataSource]="mcrpctTbleData" [remoteOperations]="false" [allowColumnReordering]="true"
                        [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true" [allowColumnResizing]="true"  (onCellPrepared)="onCellPrepared(columnDefs,$event)" (onCellClick)="onCellClick($event)" [showRowLines]="true"
                        [showBorders]="true" style="width:100%">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                        </dxo-pager>
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                        <dxo-export [enabled]="true" fileName="Micropocket Data"></dxo-export>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-column-chooser [enabled]="true" mode="select">
                        </dxo-column-chooser>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxi-column  dataField="sno" caption="Sno"
                            alignment="center" width="50" allowResizing="true">
                        </dxi-column>
                        <dxi-column  dataField="date" caption="Date"
                            alignment="center" width="100" allowResizing="true">
                        </dxi-column>
                        <dxi-column  dataField="mcrpt_nm" caption="Micropocket Name"
                            alignment="left" width="170" allowResizing="true">
                        </dxi-column>
                        <dxi-column caption="Scanned Gates" alignment="center">
                            <dxi-column dataField="tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="scnd_gts" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="tot_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                        <dxi-column caption="Residential" alignment="center">
                            <dxi-column dataField="hh_tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="hh_scnd_gtes" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="hh_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                        <dxi-column caption="Commercial & Residential" alignment="center">
                            <dxi-column dataField="rc_tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="rc_scnd_gtes" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="rc_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                        <dxi-column caption="Apartment" alignment="center">
                            <dxi-column dataField="a_tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="a_scnd_gtes" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="a_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                        <dxi-column caption="Bulk Commercial" alignment="center">
                            <dxi-column dataField="bc_tot_gtes" caption="Total" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="bc_scnd_gtes" caption="Scanned" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                            <dxi-column dataField="bc_scnd_prcnt" caption="Scanned %" alignment="center" width="100"
                                allowResizing="true">
                            </dxi-column>
                        </dxi-column>
                    </dx-data-grid>
                    </div>
                </div>
            </nz-tab>
          </nz-tabset>
        </nz-card>
      </div>

</div>