import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/providers/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as L from 'leaflet';
import * as moment from 'moment';

@Component({
  selector: 'app-schedule-trips',
  templateUrl: './schedule-trips.component.html',
  styleUrls: ['./schedule-trips.component.scss']
})
export class ScheduleTripsComponent implements OnInit {
  usrDtls: any;
  topfltrForm: FormGroup;
  tntLst: any;
  tnt_id;
  clstrLst: any;
  clstr_id: any;
  dsbladdNwTrpBtn: boolean = true;
  clstRtsLst: any = [];
  clstRtsLstDefs;
  timeFormat = 'h:mm a';
  fnceFltrForm: FormGroup;
  fncTypForm: FormGroup;
  addFncForm: FormGroup;
  routeForm: any;
  shwLdr: boolean;
  boolean = false;
  shwFnceFltr: boolean;
  updrtDtls: any;
  dsblgttrpdtlsBtn: boolean;
  strt_fnce_id: any;
  end_fnce_id: any;
  shwTripDtls: any;
  shwrtDtls: boolean;
  shwRtlst: boolean;
  AllMstrsData: any;
  tpGrpLst: any;
  trp_typ_id: any;
  mtrl_typ_id=0;
  shwErrMsg: boolean;
  isCollapsed = false;
  opnSdebar = false;
  trnsfrPntslst: any = [];
  frmfnceFltrOptns: any = [];
  tofnceFltrOptns: any = [];
  tripTypeLst: any;
  shwAddBtn: boolean = false;
  dsblSvRtBtn: boolean = false; addfncLst: any;
  index: any;
  errFlag: boolean;
  tperrFlag: boolean;
  errMsg: boolean;
  routData: any;
  strt_fnce_nm: string;
  end_fnce_nm: string;
  shwRouteMap: boolean;
  fnceFltrOptns: any = [];
  routes: FormArray;
  strt_tm: Date | null = null;
  end_tm: Date | null = null;
  arvl_ts: Date | null = null;
  dprtr_ts: Date | null = null;
  frmtplst: any = [];
  mcrpt_id: any;
  cntxtDtls: any = [];
  cntxts: any = []
  driversLst: any = [];
  assrtLst: any = [];
  r_time: Date | null = null;
  ShowAddFnce: boolean = false;
  showRte: boolean;
  map: any = [];
  polylinePoints: any;
  endPBMarker: any;
  startPBMarker: any;
  route_Polyline: any = [];
  selectedtenant;
  isAddTrip: boolean;
  vehiclesLst: any = [];
  vehiclesLst2: any = [];
  asrt_id: any;
  cntxtAreas: any;
  showMap: boolean = true;
  showNoDtls: boolean;
  isSelected: boolean = false;
  svmLst: any;
  end_fnce_grp_nm: any;
  frm_fnce_grp_nm: any;
  fnce_grp_nm: any;
  svms: any;
  showLbl: boolean;
  tnt_nm; lblNm;
  svm_id: any;
  svmDrpDwnList: any;
  selctdtenant;
  shwUpdtbtn: boolean;
  showSchvlym: boolean;
  selectedVhcle: any;
  slctd_trp_schd: any;
  selectedDriver: any;
  slctdstrt_tm: string;
  svm_apfs_cd: any;
  constructor(private apiServ: ApiService, private fb: FormBuilder, private message: NzMessageService, public datepipe: DatePipe, private modal: NzModalService) {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.usrDtls[0].tnts;
    this.tnt_id = this.tntLst[0].tnt_id;
    console.log(this.tntLst);
    if (this.usrDtls.clnt_id == 7) {
      this.showSchvlym = true;
    } else {
      this.showSchvlym = false;
      this.svm_id = 0;

    }
    if (this.tntLst.length > 1) {
      this.showLbl = false;
      this.tntLst.forEach(ele => {
        if (ele.urban_in == 1) {
          this.lblNm = 'ULB'
        } else {
          this.lblNm = 'Mandal';
        }
      });
    } else {
      this.showLbl = true;
      this.tnt_nm = this.tntLst[0].tnt_nm;
      if (this.tntLst[0].urban_in == 1) {
        this.lblNm = 'ULB'
      } else {
        this.lblNm = 'Mandal';
      }
    }

  }


  ngOnInit(): void {
    // this.initialize();
    // this.getTenantLst();
    this.getAllMstrData();
    this.getClusterCntxt();
    this.getSachivalaymList();
    // this.getVehiclesLst();
    this.topfltrForm = new FormGroup({
      tnt_id: new FormControl(''),
      // clstr_id: new FormControl(''),
      trp_typ_id: new FormControl(''),
      mtrl_typ_id: new FormControl(''),
      asrt_id: new FormControl(''),
      slctedSvm_id: new FormControl(''),
    });
    this.fnceFltrForm = new FormGroup({
      fnce_id: new FormControl('', Validators.required),
      end_fnce_id: new FormControl('', Validators.required),
      // svm_id: new FormControl('', Validators.required),
    });

    this.fncTypForm = new FormGroup({
      // trp_typ_id: new FormControl('', Validators.required),
      trp_nu: new FormControl('', Validators.required),
      crw_id: new FormControl(''),
      // asrt_id: new FormControl('', Validators.required),
      strt_tm: new FormControl(''),
      end_tm: new FormControl(''),
      // arvl_mrdn: new FormControl(''),
      // dprtr_mrdn: new FormControl(''),
      // cntx_id: new FormControl(''),
    });

    this.addFncForm = new FormGroup({
      fnce_nm: new FormControl('', Validators.required),
      lat: new FormControl('', Validators.required),
      lng: new FormControl('', Validators.required),
      fnce_grp: new FormControl('', Validators.required),
      rds: new FormControl('', Validators.required),
    });
    this.routeForm = this.fb.group({
      routes: this.fb.array([], Validators.required),
    })
  }
  // getTenantLst() {
  //   this.apiServ.get(`tripschedule/tenant/tenantLst`).subscribe((res) => {
  //     console.log(res)
  //     if (res['status'] == 200) {
  //       this.tntLst = res['data'];
  //       this.tnt_id=this.tntLst[0].tnt_id;
  //     }
  //   }, (err) => {
  //     console.log("In errorr");
  //   })
  // }
  onTenantChange(tnt) {
    this.tnt_id = tnt;
    this.getAllMstrData();
    this.getClusterCntxt();
    this.getSachivalaymList();
    // this.getclstrWseRoutLst();
    this.shwrtDtls = false;
    // if(this.tripTypeLst.length){
    //     this.trp_typ_id=this.tripTypeLst[0].trp_typ_id;
    //   }
  }
  getSachivalaymList() {
    this.svmDrpDwnList = [];
    this.svmLst = [];
    this.apiServ.get(`tripschedule/tenant/${this.tnt_id}/svmLst`).subscribe((res) => {
      if (res['status'] == 200) {
        this.svmLst = res['data'];
        this.svmDrpDwnList = res['data'];
        this.svmLst = res['data'];
        if (this.svmLst.length > 0) {
          this.svm_id = this.svmLst[0].svm_id;
          this.svm_apfs_cd = this.svmLst[0].svm_apfss_cd;
          this.getasrtbySvm();
        }
      }
    }, (err) => {
      console.log("In errorr");
    })
  }
  getasrtbySvm() {
    this.vehiclesLst = [];
    this.svmLst.forEach(element => {
      if (element.svm_id == this.svm_id) {
        this.svm_apfs_cd = element.svm_apfss_cd;
      }
    });
    if (this.svm_apfs_cd) {
      this.apiServ.get(`drivers/vehicles/bysvm/${this.svm_apfs_cd}`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vehiclesLst = res['data'];
          if (this.vehiclesLst.length > 0) {
            this.asrt_id = this.vehiclesLst[0].asrt_id;
          } else {
            this.asrt_id = 0;
          }
        }
      });
    }
  }
  // getClustersLst() {
  //   this.apiServ.get(`tripschedule/tenant/${this.tnt_id}/clusterLst`).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       this.clstrLst = res['data'];
  //     }
  //   }, (err) => {
  //     console.log("In errorr");
  //   })
  // }
  // getMcrptsLst() {
  //   this.apiServ.get(`tripschedule/tenant/${this.tnt_id}/mcrptLst`).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       this.clstrLst = res['data'];
  //     }
  //   }, (err) => {
  //     console.log("In errorr");
  //   })
  //   this.getclstrWseRoutLst();
  // }
  // // clstrSelected(value) {
  //   // console.log(this.topfltrForm.controls)
  //   this.clstr_id = value;
  //   this.dsbladdNwTrpBtn = false;
  //   this.getAllMstrData();
  //   this.getclstrWseRoutLst();
  //   this.getClusterCntxt();
  // }
  // mcrptSelected(value) {
  //   this.mcrpt_id = value;
  //   this.dsbladdNwTrpBtn = false;
  //   this.getAllMstrData();
  //   // this.getmcrptWseRoutLst();
  // }
  // ontrpTypeChnge(trpId) {
  //   this.trp_typ_id = trpId;
  //   // this.shwAddBtn = true;
  //   // if(this.trp_typ_id==1000000){
  //   //   this.getMcrptsLst();
  //   // }else{
  //   // this.getClustersLst();
  //   // this.getclstrWseRoutLst();
  //   this.getClusterCntxt();
  //   this.shwrtDtls = false;
  //   // }
  // }
  onmtrlTypeChnge(mtrl_typ) {
    this.mtrl_typ_id = mtrl_typ;
  }
  onVehicleSelected(ast_id) {
    this.asrt_id = ast_id;
    // this.getclstrWseRoutLst();
    this.getClusterCntxt();
    this.shwrtDtls = false;
    this.shwAddBtn = true;
  }
  onsvmgetSelected(svm_id) {
    this.svm_id = svm_id;
    if(this.svm_id>0){
      this.getasrtbySvm();
    }else{
      this.getVhclesData();
    }
  }
  // getVehiclesLst(){
  //   this.getAllMstrData();
  //   this.vehiclesLst=this.assrtLst;
  //   console.log(this.vehiclesLst);
  //   // this.asrt_id=this.vehiclesLst[0].asrt_id;
  // }
  getClusterCntxt() {
    this.apiServ.get(`tripschedule/trips/schedule/tenant/${this.tnt_id}/fnceGrps/fncscntxt`).subscribe((res) => {
      if (res['status'] == 200) {
        this.cntxtDtls = res['data'];
        // console.log('cntxttttttttttttttttttttttttttt', this.cntxtDtls)
      }
    }, (err) => {
      // console.log("In errorr");
    })

  }
  oncntxtChange(event) {
    this.cntxts = event;
  }
  onsvmSelect(event) {
    this.svms = event;
  }
  getclstrWseRoutLst() {
    // 475 GDV-C1
    // this.shwRtlst = true;
    // if(this.map){}
    // this.initialize();
    this.apiServ.get(`tripschedule/tenant/cluster/${this.tnt_id}/scheudles/routes/${this.trp_typ_id}/${this.asrt_id}/svm/${this.svm_id}`).subscribe((res) => {
      // this.apiServ.get(`tripschedule/tenant/cluster/475/scheudles/routes`).subscribe((res) => {
      if (res['status'] == 200) {
        this.clstRtsLst = res['data'];
        console.log(this.clstRtsLst)
        if (this.clstRtsLst.length > 0) {
          this.shwRtlst = true;
          this.showMap = true;
          // this.callmap(() => {
          //   console.log("calliiiiiiiiiiiiiiiiiiiiiiiiiiiiiing")
          // } )
          this.initialize();
        } else {
          this.shwRtlst = false;
          this.showMap = false;
        }
        for (let r of this.clstRtsLst) {
          r.shwRtLbl = "Show Route";
        }
      }

      if (this.clstRtsLst.length > 0 && this.shwRtlst == true) {
        this.showNoDtls = false;
      } else {
        this.showNoDtls = true;
      }
      this.shwFnceFltr = false;
      this.isAddTrip = false;
      this.shwrtDtls = false;
    }, (err) => {
      // console.log("In errorr");
    })
    // this.clstRtsLstDefs = [
    //   { headerName: 'Trip No', field: 'trp_sqnc_nu', alignment: 'center', width: 80, filter: false },
    //   { headerName: 'Route Name', alignment: 'center', width: 250, field: 'rte_nm' },
    //   { headerName: 'Sch. Start Time', alignment: 'center', width: 250, field: 'schdle_strt_tm' },
    //   { headerName: 'Sch. End Time', field: 'schdle_end_tm', width: 250, alignment: 'center' },
    //   { headerName: 'Actions', field: 'shwRtLbl', width: 300, alignment: 'center' }
    // ]
  }
  // getmcrptWseRoutLst() {

  // }
  // callmap(callback){
  //   this.initialize();
  //   callback();
  // }
  getrtList(rtDtls) {
    this.routeForm.controls.routes = new FormArray([]);
    let control = <FormArray>this.routeForm.controls.routes;
    this.shwLdr = true;
    this.isAddTrip = false;
    if (rtDtls.shwRtLbl == "Show Route") {
      this.shwrtDtls = true;
      this.shwFnceFltr = false;
    } else {
      this.shwrtDtls = false;
    }


    if (rtDtls.shwRtLbl == "Show Route") {
      for (let r of this.clstRtsLst) {
        if (r.trp_schde_id == rtDtls.trp_schde_id) {
          r.shwRtLbl = "Hide Route";
          this.shwUpdtbtn = true;
        } else {
          r.shwRtLbl = "Show Route";
        }
      }
      this.updrtDtls = rtDtls;
      // this.getAllMstrData();
      this.gettplst();
      var trp_schde_id = rtDtls.trp_schde_id;
      console.log(rtDtls)
      var strt_fnce_id = rtDtls.strt_fnce_id
      var end_fnce_id = rtDtls.end_fnce_id

      this.cntxts = [];
      var areas = [];
      this.apiServ.get(`tripschedule/trips/schedule/trips/fnces/${trp_schde_id}`).subscribe((res) => {

        this.shwTripDtls = res['data'];
        // this.shwLdr=false;
        // console.log(this.shwTripDtls);
        // console.log(this.shwTripDtls.echSchdTrpcntxtLst[0].fnce_id)
        // this.cntxtAreas='';
        if (this.shwTripDtls.echSchdTrpcntxtLst.length) {
          this.shwTripDtls.echSchdTrpcntxtLst.filter((k) => {
            this.cntxts.push(k.fnce_id)
          });
          // for(var a=0;a<this.shwTripDtls.echSchdTrpcntxtLst.length;a++){
          //   areas.push(this.shwTripDtls.echSchdTrpcntxtLst[a].fnce_nm)
          //   this.cntxtAreas=areas.join();
          // }
          // console.log(this.cntxtAreas)

        }
        if (this.shwTripDtls.echSchdTrpFnceLst.length > 0) {
          this.shwTripDtls.echSchdTrpFnceLst.forEach(t => {
            // let arvl_ts = (t.fnce_arvl_tm.split(' '))[0];
            // let arvl_mrdn;
            // let dprtr_mrdn;H
            // if ((t.fnce_arvl_tm.split(' '))[1])
            //   arvl_mrdn = (t.fnce_arvl_tm.split(' '))[1];
            // else arvl_mrdn = '';
            // let dprtr_ts = (t.fnce_dprte_tm.split(' '))[0];
            // if ((t.fnce_arvl_tm.split(' '))[1])
            //   dprtr_mrdn = (t.fnce_dprte_tm.split(' '))[1];
            // else dprtr_mrdn = '';
            // console.log(t)
            // console.log(t.fnce_arvl_tm, t.fnce_dprte_tm)
            let arvl_ts = moment(t.fnce_arvl_tm, ["h:mm A"]).format("HH:mm");

            // this.datepipe.transform(t.fnce_arvl_tm, 'YYYY-MM-DDThh:mm:ssTZD');
            let dprtr_ts = moment(t.fnce_dprte_tm, ["h:mm A"]).format("HH:mm");


            console.log(arvl_ts, dprtr_ts);

            control.push(this.fb.group({
              fnce_id: t.fnce_id,
              arvl_ts: t.fnce_arvl_tm,
              dprtr_ts: t.fnce_dprte_tm,
            }))
            // console.log(control)
          })
        } else {
          control.push(
            this.fb.group({
              fnce_id: strt_fnce_id,
              arvl_ts: [''],
              dprtr_ts: [''],
            })
          )

          control.push(this.fb.group({
            fnce_id: end_fnce_id,
            arvl_ts: [''],
            dprtr_ts: [''],
          })
          )
        }

        // let arvl_ts = (this.shwTripDtls.echSchdTrpAssLst[0].strt_tm.split(' '))[0];
        // let arvl_mrdn = (this.shwTripDtls.echSchdTrpAssLst[0].strt_tm.split(' '))[1];
        // let dprtr_ts = (this.shwTripDtls.echSchdTrpAssLst[0].end_tm.split(' '))[0];
        // let dprtr_mrdn = (this.shwTripDtls.echSchdTrpAssLst[0].end_tm.split(' '))[1];
        let arvl_ts = this.shwTripDtls.echSchdTrpAssLst[0].strt_tm;
        let dprtr_ts = this.shwTripDtls.echSchdTrpAssLst[0].end_tm;
        this.fncTypForm.get('trp_nu').setValue(this.shwTripDtls.echSchdTrpAssLst[0].trips);
        this.fncTypForm.get('crw_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].crw_id);
        // this.fncTypForm.get('asrt_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].asrt_id);
        this.fncTypForm.get('strt_tm').setValue(arvl_ts);
        this.fncTypForm.get('end_tm').setValue(dprtr_ts);

        this.shwLdr = false;
        this.dsblSvRtBtn = false;
        this.shwFnceFltr = false;
        // console.log(this.fncTypForm.value)
      });
      this.addfncLst = control.controls;
      // console.log(this.addfncLst)
    } else {
      for (let r of this.clstRtsLst) {
        if (r.trp_schde_id == rtDtls.trp_schde_id)
          r.shwRtLbl = "Show Route";
      }
      this.shwLdr = false;
    }
  }
  addNewTrp() {
    this.shwFnceFltr = true;
    this.dsblgttrpdtlsBtn = true;
    this.shwTripDtls = {};
    this.updrtDtls = {};
    this.isAddTrip = true;
    this.routeForm.controls.routes = new FormArray([]);
    this.shwAddBtn = false;
    this.shwRtlst = false;
    this.showMap = false;
    this.showNoDtls = false;
    this.getClusterCntxt();
    this.gettplst();

    // this.fncTypForm.get('asrt_id').setValue(this.asrt_id);
    // if (this.showSchvlym==true) {
    //   this.fnceFltrForm.get('svm_id').setValue(this.svm_id);
    // }

    // console.log(this.fncTypForm.value)
    if (this.clstRtsLst.length > 0) {
      for (let r of this.clstRtsLst) {
        r.shwRtLbl = "Show Route";
      }
    }

  }
  // closeTrp() {
  //   this.shwAddBtn = true;
  //   this.isAddTrip = false;
  //   this.shwFnceFltr = false;
  //   this.showMap = true;
  // }
  closeSider() {
    this.addFncForm.reset();
    this.opnSdebar = false;
  }
  showConfirm(s) {
    this.modal.confirm({
      nzTitle: 'Are you sure delete this Trip?',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => this.delete(s),
      nzCancelText: 'No',
      nzOnCancel: () => this.cancel(),
    });
  }

  delete(s) {
    // console.log(s)
    var data = {
      trp_schde_id: s.trp_schde_id
    }
    this.apiServ.post(data, `tripschedule/trips/schedule/trips/deletetrip`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Trip successfully Deleted');
        this.getclstrWseRoutLst();
      }
    })

  }
  cancel(): void {
    this.message.info('Trip Not Deleted');
  }
  resetAllForms() {
    this.fnceFltrForm.reset();
    this.fncTypForm.reset();
    this.routeForm.reset();
    this.shwrtDtls = false;
    this.dsblgttrpdtlsBtn = true;
    this.strt_fnce_id = null;
    this.strt_fnce_id = '';
    this.end_fnce_id = null;
    this.end_fnce_id = '';
  }
  getAllMstrData() {
    this.apiServ.get(`tripschedule/trips/schedule/dropdown/master/${this.tnt_id}`).subscribe((res) => {
      // console.log(res['data'])
      this.AllMstrsData = res['data'];
      this.tpGrpLst = res['data'].transfrPointLst;
      this.tripTypeLst = res['data'].tripTypeLst;
      this.driversLst = res['data'].driversLst;
      this.assrtLst = res['data'].assrtLst;
      this.vehiclesLst = this.assrtLst;
      this.vehiclesLst2 = this.assrtLst;

      // console.log(this.vehiclesLst);
      if (this.vehiclesLst.length) {
        // this.asrt_id=this.vehiclesLst[0].asrt_id;
        this.asrt_id = 0;
      }
      // if (this.tripTypeLst.length) {
      this.trp_typ_id = this.tripTypeLst[0].trp_typ_id;
      this.onTrpTypSelected(this.trp_typ_id);
      // }
    });
  }
  onTrpTypSelected(evt) {
    console.log(evt)
    this.vehiclesLst=[];
    if (evt == 1000001) {
      this.svm_id = 0;
      this.showSchvlym = false;
      this.topfltrForm.get('slctedSvm_id').setValue(0);
      // this.vehiclesLst = this.assrtLst;
      this.getVhclesData();
      this.trp_typ_id=0;
    } else {
      this.showSchvlym = true;
      this.getSachivalaymList();
    }
  }
  getVhclesData() {
    this.vehiclesLst=[];
    var fltrData = [{
      selectedtenant: this.tnt_id,
      selectedvndr: 0,
      selectedctgry: 0
    }]
    this.apiServ.post(fltrData, 'drivers/vehicles/asgndvehicles').subscribe((res) => {
      if (res['status'] == 200) {
        this.vehiclesLst = res['data'];
        if (this.vehiclesLst.length > 0) {
          this.asrt_id = this.vehiclesLst[0].asrt_id;
        } else {
          this.asrt_id = 0;
        }

      }
    }, (err) => {
    });
  }
  opensideBar(key, val) {
    this.shwErrMsg = false;
    // this.getAllMstrData();
    this.selctdtenant = this.tnt_id;
    if (key == "addFncePanel") {
      this.opnSdebar = true;
      this.ShowAddFnce = true;
      this.showRte = false;
    }
    if (key == "showRteMap") {
      this.opnSdebar = true;
      this.showRte = true;
      this.ShowAddFnce = false;
    }
  }
  initialize = function () {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS ----------//
    // try {

    //   this.map = L.map('map');
    //   const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     attribution: ''
    //   });

    //   tiles.addTo('map');
    //   this.map.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 13)

    // } catch (e) {
    //   console.log("please check map Initialization of leaflet:" + e);
    // }
    // this.showRte = true;
    // if(this.map != undefined){
    //   this.map = null;
    // } else{
    setTimeout(() => {

      try {

        this.map = L.map('map', {
          center: [this.usrDtls[0].lat, this.usrDtls[0].lng],
          zoom: 3
        });

        // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //   // maxZoom: 15,
        //   // minZoom: 3,
        //   attribution: ''
        // });
        const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
          // maxZoom: 15,
          // minZoom: 3,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: ''
        });

        tiles.addTo(this.map);
        this.map.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 13)
      } catch (e) {
        console.log("please check map Initialization of leaflet:" + e);
      }
    }, 300);
    // }

  }
  getRouteOnMap(val) {
    this.polylinePoints = [];
    if (val.rte_arc_lne_tx != undefined) {
      this.polylinePoints = JSON.parse(val.rte_arc_lne_tx);
    }
    if (this.route_Polyline) {
      this.map.removeLayer(this.route_Polyline);
    }
    if (this.startPBMarker) {
      this.map.removeLayer(this.startPBMarker);
    }

    if (this.endPBMarker) {
      this.map.removeLayer(this.endPBMarker);
    }
    if (this.polylinePoints == undefined || this.polylinePoints.length == 0) {
      this.createMessage('warning', 'No Polyline Added to the Trip.To add polyline visit wetrackon App')
    } else {
      // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
      var startIcon = new L.Icon({
        iconUrl: '/assets/images/markers/playback/startPoint.png',
        iconSize: [42, 45],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      this.startPBMarker = L.marker(this.polylinePoints[0], {
        icon: startIcon
      }).addTo(this.map);
      // ----------Fence END Area (flag position) -- Leaflet OS ----------//
      var endIcon = new L.Icon({
        iconUrl: '/assets/images/markers/playback/endPoint.png',
        iconSize: [42, 45],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      var k = this.polylinePoints.length - 1
      this.endPBMarker = L.marker(this.polylinePoints[k], {
        icon: endIcon
      }).addTo(this.map);
    }

    if (val.rte_arc_lne_tx != undefined) {
      this.route_Polyline = L.polyline(this.polylinePoints, {
        color: '#008000',
        weight: 5,
        lineCap: 'butt',
        opacity: 0.6,
        smoothFactor: 10.0,
        lineJoin: 'round'
      }).addTo(this.map);
      this.map.fitBounds(this.route_Polyline.getBounds());

    }

  }
  gettplst() {
    this.apiServ.get(`tripschedule/trips/schedule/transferpoints/${this.tnt_id}`).subscribe((res) => {

      this.trnsfrPntslst = [];
      this.frmtplst = [];
      this.frmfnceFltrOptns = [];
      this.tofnceFltrOptns = [];
      this.fnceFltrOptns = [];

      // console.log(res['data'])
      for (let i of res['data']) {
        // if (i.fnce_ctgry_id == 17) {
        this.trnsfrPntslst.push(i);
        // } else {
        this.frmtplst.push(i);
        this.tofnceFltrOptns.push(i);
        this.frmfnceFltrOptns.push(i);
        this.fnceFltrOptns.push(i);
        // }
        this.fnceFltrForm.get('fnce_id').setValue('');
        this.fnceFltrForm.get('end_fnce_id').setValue('');

      }
    });
  }

  filteredOptions: any = [];
  frmfnceSelected(value) {
    for (let i = 0; i < this.frmfnceFltrOptns.length; i++) {
      if (this.frmfnceFltrOptns[i].fnce_id == value.fnce_id) {
        this.filteredOptions = { "fnce_id": this.frmfnceFltrOptns[i].fnce_id, "fnce_nm": this.frmfnceFltrOptns[i].fnce_nm, "fnce_grp_nm": this.frmfnceFltrOptns[i].fnce_grp_nm };
        this.fncefilteredOptions[0] = { "fnce_id": this.frmfnceFltrOptns[i].fnce_id, "fnce_nm": this.frmfnceFltrOptns[i].fnce_nm, "fnce_grp_nm": this.frmfnceFltrOptns[i].fnce_grp_nm };
        this.frm_fnce_grp_nm = this.frmfnceFltrOptns[i].fnce_grp_nm;
        break;
      }
    }
    this.strt_fnce_id = value.fnce_id;
    this.strt_fnce_nm = value.fnce_nm;
    // console.log(this.filteredOptions);
    this.getTripDetails(this.routeForm.controls.routes);
  }

  fncefilteredOptions: any = [];
  fnceSelected(value, indx) {
    for (let i = 0; i < this.fnceFltrOptns.length; i++) {
      if (this.fnceFltrOptns[i].fnce_id == value) {
        this.fncefilteredOptions[indx] = { "fnce_id": this.fnceFltrOptns[i].fnce_id, "fnce_nm": this.fnceFltrOptns[i].fnce_nm, "fnce_grp_nm": this.fnceFltrOptns[i].fnce_grp_nm };
        this.fnceFltrOptns[indx].fnce_grp_nm = this.fnceFltrOptns[i].fnce_grp_nm;
        break;
      }
    }
  }

  tofncefilteredOptions: any = [];
  endfnceSelected(evt) {
    this.end_fnce_id = evt.fnce_id;
    this.end_fnce_nm = evt.fnce_nm;
    this.dsblgttrpdtlsBtn = false;
    for (let i = 0; i < this.tofnceFltrOptns.length; i++) {
      if (this.tofnceFltrOptns[i].fnce_id == evt.fnce_id) {
        this.tofncefilteredOptions = { "fnce_id": this.tofnceFltrOptns[i].fnce_id, "fnce_nm": this.tofnceFltrOptns[i].fnce_nm, "fnce_grp_nm": this.tofnceFltrOptns[i].fnce_grp_nm };
        this.fncefilteredOptions[this.fncefilteredOptions.length - 1] = { "fnce_id": this.tofnceFltrOptns[i].fnce_id, "fnce_nm": this.tofnceFltrOptns[i].fnce_nm, "fnce_grp_nm": this.tofnceFltrOptns[i].fnce_grp_nm };
        this.end_fnce_grp_nm = this.tofnceFltrOptns[i].fnce_grp_nm;
        break;
      }
    }
    this.getTripDetails(this.routeForm.controls.routes);
    // console.log(this.routeForm.controls.routes)
  }

  getTripDetails(a) {
    this.shwrtDtls = true;
    this.dsblgttrpdtlsBtn = true;
    this.dsblSvRtBtn = false;
    // this.getAllMstrData();
    this.routeForm.controls.routes = new FormArray([]);
    let control = <FormArray>this.routeForm.controls.routes;
    control.push(
      this.fb.group({
        fnce_id: this.strt_fnce_id,
        arvl_ts: [''],
        dprtr_ts: [''],
      })
    )
    // this.ManageFenceControl(0);

    control.push(
      this.fb.group({
        fnce_id: this.end_fnce_id,
        arvl_ts: [''],
        dprtr_ts: [''],
      })
    )
    // this.ManageFenceControl(1);
    this.addfncLst = control.controls;
    // console.log(this.addfncLst)
  }
  // validateTimeformate(time, indx, ind) {
  //   console.log(time, indx, ind)
  //   let timeData = { h1: '', h2: '', m1: '', m2: '', mr1: '', mr2: '' };
  //   let hour = (time.split(':'))[0]
  //   let min = (time.split(':'))[1]
  //   min = (min + '').length == 1 ? `0${min}` : min;
  //   hour = hour > 12 ? hour - 12 : hour;
  //   hour = (hour + '').length == 1 ? `0${hour}` : hour;
  //   if (indx != undefined) {
  //     this.index = indx;
  //     let control = <FormArray>this.routeForm.controls.routes;
  //     if (ind == 'arvl') {
  //       control.controls[indx].get('arvl_ts').setValue(hour + ":" + min);
  //     } else if (ind == 'dprtr') {
  //       control.controls[indx].get('dprtr_ts').setValue(hour + ":" + min);
  //     }
  //     timeData.h1 = (control.controls[indx].get('arvl_ts').value.split(':'))[0];
  //     timeData.h2 = (control.controls[indx].get('dprtr_ts').value.split(':'))[0];
  //     timeData.m1 = (control.controls[indx].get('arvl_ts').value.split(':'))[1];
  //     timeData.m2 = (control.controls[indx].get('dprtr_ts').value.split(':'))[1];
  //     timeData.mr1 = (control.controls[indx].get('arvl_mrdn').value);
  //     timeData.mr2 = (control.controls[indx].get('dprtr_mrdn').value);
  //   } else {
  //     if (ind == 'arvl') {
  //       this.fncTypForm.get('strt_tm').setValue(hour + ":" + min);
  //     } else if (ind == 'dprtr') {
  //       this.fncTypForm.get('end_tm').setValue(hour + ":" + min);
  //     }
  //     timeData.h1 = (this.fncTypForm.get('strt_tm').value.split(':'))[0];
  //     timeData.h2 = (this.fncTypForm.get('end_tm').value.split(':'))[0];
  //     timeData.m1 = (this.fncTypForm.get('strt_tm').value.split(':'))[1];
  //     timeData.m2 = (this.fncTypForm.get('end_tm').value.split(':'))[1];
  //     timeData.mr1 = (this.fncTypForm.get('arvl_mrdn').value);
  //     timeData.mr2 = (this.fncTypForm.get('dprtr_mrdn').value);
  //   }
  //   this.validMinMaxTimimgs(timeData, indx);
  // }
  // toggleMrdn(mrdn, indx, ind) {
  //   let timeData = { h1: '', h2: '', m1: '', m2: '', mr1: '', mr2: '' };
  //   let part = (mrdn == 'AM') ? 'PM' : 'AM'
  //   if (indx != undefined) {
  //     this.index = indx;
  //     let control = <FormArray>this.routeForm.controls.routes;
  //     if (ind == 'arvl')
  //       control.controls[indx].get('arvl_mrdn').setValue(part);
  //     else if (ind == 'dprtr')
  //       control.controls[indx].get('dprtr_mrdn').setValue(part);
  //     timeData.h1 = (control.controls[indx].get('arvl_ts').value.split(':'))[0];
  //     timeData.h2 = (control.controls[indx].get('dprtr_ts').value.split(':'))[0];
  //     timeData.m1 = (control.controls[indx].get('arvl_ts').value.split(':'))[1];
  //     timeData.m2 = (control.controls[indx].get('dprtr_ts').value.split(':'))[1];
  //     timeData.mr1 = (control.controls[indx].get('arvl_mrdn').value);
  //     timeData.mr2 = (control.controls[indx].get('dprtr_mrdn').value);
  //   } else {
  //     if (ind == 'arvl') {
  //       this.fncTypForm.get('arvl_mrdn').setValue(part);
  //     } else if (ind == 'dprtr') {
  //       this.fncTypForm.get('dprtr_mrdn').setValue(part);
  //     }
  //     timeData.h1 = (this.fncTypForm.get('strt_tm').value.split(':'))[0];
  //     timeData.h2 = (this.fncTypForm.get('end_tm').value.split(':'))[0];
  //     timeData.m1 = (this.fncTypForm.get('strt_tm').value.split(':'))[1];
  //     timeData.m2 = (this.fncTypForm.get('end_tm').value.split(':'))[1];
  //     timeData.mr1 = (this.fncTypForm.get('arvl_mrdn').value);
  //     timeData.mr2 = (this.fncTypForm.get('dprtr_mrdn').value);
  //   }
  //   this.validMinMaxTimimgs(timeData, indx);
  // }
  // validMinMaxTimimgs(timeData, indx) {
  //   let errFlag = false;
  //   if (timeData.h1 > timeData.h2) {
  //     if (timeData.mr1 == timeData.mr2 || timeData.mr1 == 'PM' && timeData.mr2 == 'AM') {
  //       errFlag = true;
  //     }
  //     else if (timeData.mr1 == 'AM' && timeData.mr2 == 'PM')
  //       errFlag = false;
  //   }
  //   else if (timeData.h1 < timeData.h2) {
  //     if (timeData.mr1 == timeData.mr2 || timeData.mr1 == 'AM' && timeData.mr2 == 'PM')
  //       errFlag = false;
  //     else if (timeData.mr1 == 'PM' && timeData.mr2 == 'AM')
  //       errFlag = true;
  //   } else if (timeData.h1 == timeData.h2) {
  //     if (timeData.m1 < timeData.m2) {
  //       if (timeData.mr1 == timeData.mr2)
  //         errFlag = false;
  //       else if (timeData.mr1 == 'PM' && timeData.mr2 == 'AM')
  //         errFlag = true;
  //       else if (timeData.mr1 == 'AM' && timeData.mr2 == 'PM')
  //         errFlag = false;
  //     } else if (timeData.m1 > timeData.m2) {
  //       if (timeData.mr1 == timeData.mr2)
  //         errFlag = true;
  //       else if (timeData.mr1 == 'PM' && timeData.mr2 == 'AM')
  //         errFlag = true;
  //       else if (timeData.mr1 == 'AM' && timeData.mr2 == 'PM')
  //         errFlag = false;
  //     }
  //   }
  //   if (indx != undefined) {
  //     this.errFlag = errFlag;
  //     this.tperrFlag = false;
  //   } else {
  //     this.tperrFlag = errFlag;
  //     this.errFlag = false;
  //   }
  // }
  savetripRoute() {
    // console.log(this.routeForm.controls.routes.value)
    if (this.routeForm.controls.routes.status == 'INVALID')
      this.shwErrMsg = true;
    // console.log(this.topfltrForm.value.asrt_id,this.fncTypForm.value,this.routeForm.controls.routes)
    if (this.topfltrForm.value.asrt_id!=null && 
      this.fncTypForm.value.end_tm!=null &&
      this.fncTypForm.value.strt_tm!=null &&
      this.fncTypForm.value.trp_nu!=null && this.routeForm.controls.routes.status == 'VALID') {
      this.errMsg = false;
      let flag = false;
      console.log(this.updrtDtls)
      if (!this.updrtDtls.rte_id || !this.updrtDtls.trp_schde_id) {
        this.clstRtsLst.forEach(val => {
          if (val.trp_nm == this.fncTypForm.value.trp_nu) {
            flag = true;
          }
        })
        if (flag) {
          this.errMsg = true;
          return;
        }
      }
      if (this.showSchvlym == false) {
        this.svms = [];
      }
      // let strt_hour = parseInt((this.fncTypForm.value.strt_tm.split(':'))[0]);
      // let strt_min = parseInt((this.fncTypForm.value.strt_tm.split(':'))[1]);
      // let end_hour = parseInt((this.fncTypForm.value.end_tm.split(':'))[0]);
      // let end_min = parseInt((this.fncTypForm.value.end_tm.split(':'))[1]);
      this.routData = {
        rteDtls: {
          "rte_id": "",
          "rte_ctgry_id": "",
          "rte_nm": this.strt_fnce_nm + "-" + this.end_fnce_nm,
          "strt_fnce_id": this.strt_fnce_id,
          "end_fnce_id": this.end_fnce_id,
          "tnt_id": this.tnt_id,
          "svm_id": this.svm_id,
        },
        

        asrtLst: {
          "asrt_id": this.topfltrForm.value.asrt_id,
          "crw_id": this.fncTypForm.value.crw_id,
          "strt_tm": this.datepipe.transform(this.fncTypForm.value.strt_tm, 'HH:mm'),
          "end_tm": this.datepipe.transform(this.fncTypForm.value.end_tm, 'HH:mm'),
          "trips": this.fncTypForm.value.trp_nu,
          "trp_typId": this.trp_typ_id,
          "mtrl_typ_id": this.mtrl_typ_id ? this.mtrl_typ_id : 0,
          "trp_schde_id": this.updrtDtls.trp_schde_id,
          "tnt_id": this.tnt_id
        },
        // cntxtLst: this.cntxts,
        cntxtLst: [],
        fnscLst: []
      }
      var count = 0;
      // console.log(this.addfncLst);
      for (let r of this.addfncLst) {
        count++;
        // let arvl_hour = parseInt((r.value.arvl_ts.split(':'))[0]);
        // let arvl_min = parseInt((r.value.arvl_ts.split(':'))[1]);
        // let dprtr_hour = parseInt((r.value.dprtr_ts.split(':'))[0]);
        // let dprtr_min = parseInt((r.value.dprtr_ts.split(':'))[1]);
        // console.log(r.value)
        this.routData.fnscLst.push({
          "sqncNo": count,
          "fnce_nm": r.value.fnce_nm,
          "fnce_id": r.value.fnce_id,
          "arvlTm": this.datepipe.transform(r.value.arvl_ts, 'HH:mm'),
          "DptrTm": this.datepipe.transform(r.value.dprtr_ts, 'HH:mm'),
          "cmtveTrvlTm": "",
          "avrgTrvlDstn": "",
          "avrgTrvlTm": "",
          "StpTm": "",
          "tnt_id": this.tnt_id,
          // 'pcr':r.value.pcr
        })
      }
      let operation;
      if (this.updrtDtls && this.updrtDtls.rte_id && this.updrtDtls.trp_schde_id) {
        operation = 'Updated';
        this.routData.rteDtls = {
          "rte_id": this.updrtDtls.rte_id,
          "rte_ctgry_id": 1,
          "rte_nm": this.updrtDtls.rte_nm,
          "strt_fnce_id": this.updrtDtls.strt_fnce_id,
          "end_fnce_id": this.updrtDtls.end_fnce_id,
          "tnt_id": this.tnt_id,
          "svm_id": this.svm_id,
        }
      } else {
        operation = 'Created';
        this.routData.rteDtls = {
          "rte_id": "",
          "rte_ctgry_id": "",
          "rte_nm": this.strt_fnce_nm + "-" + this.end_fnce_nm,
          "strt_fnce_id": this.strt_fnce_id,
          "end_fnce_id": this.end_fnce_id,
          "tnt_id": this.tnt_id,
          "svm_id": this.svm_id,
        }
      }
      console.log(this.routData);
      // console.log(this.arvl_ts)
      this.apiServ.post(this.routData, `tripschedule/trips/schedule/trpRouteDtl`).subscribe((res) => {
        if (res['status'] == 200) {
          this.dsblSvRtBtn = true;
          this.createMessage('success', 'Route Added Successfully');
          this.getclstrWseRoutLst();
        }
      })
    } else this.shwErrMsg = true;
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  getmapRoute(trpDtls) {
    this.shwrtDtls = false;
    this.shwRouteMap = true;
  }
  MoveUp(indx, data) {
    let control = <FormArray>this.routeForm.controls.routes;
    control.removeAt(indx);
    control.controls.splice(indx - 1, 0,
      this.fb.group({
        fnce_id: data.value.fnce_id,
        arvl_ts: data.value.arvl_ts,
        dprtr_ts: data.value.dprtr_ts,
      }));
    this.addfncLst = control.controls;
  }
  MoveDown(indx, data) {
    let control = <FormArray>this.routeForm.controls.routes;
    control.removeAt(indx);
    control.controls.splice(indx + 1, 0,
      this.fb.group({
        fnce_id: data.value.fnce_id,
        arvl_ts: this.datepipe.transform(data.value.arvl_ts, 'HH:mm'),
        dprtr_ts: this.datepipe.transform(data.value.dprtr_ts, 'HH:mm'),
      }));
    this.addfncLst = control.controls;
  }
  ManageFenceControl(index: number) {
    var arrayControl = <FormArray>this.routeForm.controls.routes;
    // console.log(index)
    // console.log(arrayControl.at(index));
    // console.log(this.fnceFltrOptns[index])
    // this.fnceFltrOptns[index] = arrayControl.at(index).get('fnce_id').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._Fncfilter(value))
    //   );
  }

  resetrouteForm() {
    this.routeForm.reset();
    this.dsblSvRtBtn = false;
    this.routeForm.controls.routes = new FormArray([]);
    let control = <FormArray>this.routeForm.controls.routes;
    if (this.updrtDtls && this.updrtDtls.rte_id && this.updrtDtls.trp_schde_id) {
      this.shwTripDtls.echSchdTrpFnceLst.forEach(t => {

        control.push(this.fb.group({
          fnce_id: t.fnce_id,
          arvl_ts: this.datepipe.transform(t.fnce_arvl_tm, 'HH:mm'),
          dprtr_ts: this.datepipe.transform(t.fnce_dprte_tm, 'HH:mm'),
        }))
      });
      var strt_tim_frmt = this.datepipe.transform(this.shwTripDtls.echSchdTrpAssLst[0].strt_tm, 'HH:mm');
      var ent_tim_frmt = this.datepipe.transform(this.shwTripDtls.echSchdTrpAssLst[0].end_tm, 'HH:mm');

      this.fncTypForm.get('trp_nu').setValue(this.shwTripDtls.echSchdTrpAssLst[0].trips);
      this.fncTypForm.get('crw_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].crw_id);
      this.fncTypForm.get('asrt_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].asrt_id);
      this.fncTypForm.get('strt_tm').setValue(strt_tim_frmt);
      this.fncTypForm.get('end_tm').setValue(ent_tim_frmt);
      this.dsblSvRtBtn = false;
      this.shwFnceFltr = false;
    }
    else {


      control.push(
        this.fb.group({
          fnce_id: this.strt_fnce_id,
          arvl_ts: [''],
          dprtr_ts: [''],
        })
      )

      control.push(this.fb.group({
        fnce_id: this.end_fnce_id,
        arvl_ts: [''],
        dprtr_ts: [''],
      })
      )
    }
    this.addfncLst = control.controls;
  }
  addRoute(indx, controls): void {
    // if (!this.dsblSvRtBtn) {
    // console.log(controls)
    this.addfncLst = controls.controls;
    let control = <FormArray>this.routeForm.controls.routes;
    control.controls.splice(indx + 1, 0,
      this.fb.group({
        fnce_id: [],
        arvl_ts: [''],
        dprtr_ts: [''],
      }));
    // this.ManageFenceControl(indx + 1);
    // } else return;
  }
  deleteRoute(control, index) {
    control.removeAt(index);
    this.addfncLst = control.controls
  }
  addNewFnc() {
    if (this.addFncForm.status == 'VALID') {
      let postData = {
        fncs_nm: this.addFncForm.value.fnce_nm,
        fncsGrpId: this.addFncForm.value.fnce_grp,
        lat: this.addFncForm.value.lat,
        lng: this.addFncForm.value.lng,
        tnt_id: this.tnt_id,
        rds: this.addFncForm.value.rds,
      }
      // console.log(this.addFncForm)
      // console.log(postData)
      this.apiServ.post(postData, 'tripschedule/trips/schedule/fnce').subscribe((res) => {
        if (res['status'] == 200) {
          this.dsblSvRtBtn = true;
          this.createMessage('success', 'Location Added Sucessfully');
          this.addFncForm.reset();
          this.gettplst();
          this.getClusterCntxt();
        }
      })
    } else this.shwErrMsg = true;
  }
  selectCrd(s) {
    console.log(s);
    console.log(this.clstRtsLst)
    this.clstRtsLst.forEach(ele => {
      if (ele.trp_schde_id != s.trp_schde_id) {
        ele.isSelected = false;
      } else {
        ele.isSelected = true;
      }
    });

  }
  updatetripRoute() {
    console.log(this.addfncLst);
    console.log(this.updrtDtls);
    // var strt_tm;
    // var end_tm;
    // if(this.updrtDtls.schdle_strt_tm){
    //   strt_tm=this.datepipe.transform(this.updrtDtls.schdle_strt_tm, 'HH:mm');
    // }else{
    //   strt_tm=""
    // }
    // if(this.updrtDtls.end_tm){
    //   end_tm=this.datepipe.transform(this.updrtDtls.schdle_end_tm, 'HH:mm');
    // }else{
    //   end_tm=""
    // }
    this.routData = {
      rteDtls: {
        "rte_id": this.updrtDtls.rte_id,
        "rte_ctgry_id": 1000001,
        "rte_nm": this.updrtDtls.strt_fnce_nm + "-" + this.updrtDtls.end_fnce_nm,
        "strt_fnce_id": this.updrtDtls.strt_fnce_id,
        "end_fnce_id": this.updrtDtls.end_fnce_id,
        "tnt_id": this.tnt_id,
        "svm_id": this.svm_id
      },
      

      asrtLst: {
        "asrt_id": this.topfltrForm.value.asrt_id,
        "crw_id": this.fncTypForm.value.crw_id,
        // "strt_tm": strt_tm,
        // "end_tm": end_tm,
        "trips": this.fncTypForm.value.trp_nu,
        "trp_typId": this.trp_typ_id,
        "mtrl_typ_id": this.mtrl_typ_id,
        "trp_schde_id": this.updrtDtls.trp_schde_id,
        "tnt_id": this.tnt_id
      },
      // cntxtLst: this.cntxts,
      cntxtLst: [],
      fnscLst: []

    }
    var count = 0;
    // console.log(this.addfncLst);
    for (let r of this.addfncLst) {
      count++;
      // let arvl_hour = parseInt((r.value.arvl_ts.split(':'))[0]);
      // let arvl_min = parseInt((r.value.arvl_ts.split(':'))[1]);
      // let dprtr_hour = parseInt((r.value.dprtr_ts.split(':'))[0]);
      // let dprtr_min = parseInt((r.value.dprtr_ts.split(':'))[1]);
      // console.log(r.value)
      this.routData.fnscLst.push({
        "sqncNo": count,
        "fnce_nm": r.value.fnce_nm,
        "fnce_id": r.value.fnce_id,
        // "arvlTm": this.datepipe.transform(r.value.arvl_ts, 'HH:mm'),
        // "DptrTm": this.datepipe.transform(r.value.dprtr_ts, 'HH:mm'),
        "cmtveTrvlTm": "",
        "avrgTrvlDstn": "",
        "avrgTrvlTm": "",
        "StpTm": "",
        "tnt_id": this.tnt_id,
        // 'pcr':r.value.pcr
      })
    }
    this.apiServ.post(this.routData, `tripschedule/trips/schedule/trpRouteDtl`).subscribe((res) => {
      if (res['status'] == 200) {
        this.dsblSvRtBtn = true;
        this.createMessage('success', 'Route Updated Successfully');
        this.getclstrWseRoutLst();
      }
    })
  }
  shwVhcleLst(s) {
    this.slctd_trp_schd = s.trp_schde_id;
    this.selectedVhcle = null;
    this.clstRtsLst.forEach(ele => {
      if (ele.trp_schde_id != s.trp_schde_id) {
        ele.shwVhcle = false;
      } else {
        ele.shwVhcle = true;
      }
    });
  }
  onselectVeh(veh) {
    this.selectedVhcle = veh;
    var data = {
      trp_schde_id: this.slctd_trp_schd,
      asrt_id: this.selectedVhcle,
      key: 'asrt'
    }
    this.updateCnfrmMdl('Vehicle', data)
  }
  closeSlct(s) {
    this.clstRtsLst.forEach(ele => {
      if (ele.trp_schde_id == s.trp_schde_id) {
        ele.shwVhcle = false;
        ele.shwdrvrEdt = false;
        ele.shwStrtTmEdt = false;
      }
    })
  }
  onDrvrEdt(s) {
    this.selectedDriver = s.crw_id ? s.crw_id : null;
    this.clstRtsLst.forEach(ele => {
      if (ele.trp_schde_id != s.trp_schde_id) {
        ele.shwdrvrEdt = false;
      } else {
        ele.shwdrvrEdt = true;
      }
    });
  }
  onselectDriver(drv, s) {
    this.selectedDriver = drv;
    var data = {
      trp_schde_id: s.trp_schde_id,
      crw_id: this.selectedDriver,
      key: 'crw'
    }
    this.updateCnfrmMdl('Driver', data)
  }
  updateCnfrmMdl(ele, data) {
    this.modal.confirm({
      nzTitle: `Are you sure Do You Want To Change ${ele}?`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.shwLdr = true;
        this.apiServ.post(data, `tripschedule/trips/schedule/trips/chngeVehcle`).subscribe((res) => {
          if (res['status'] == 200) {
            this.shwLdr = false;
            this.createMessage('Success', `${ele} successfully Updated`);
            this.asrt_id = this.selectedVhcle;
            this.getclstrWseRoutLst();
          }
        })
      },
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.closeSlct(data)
      },
    });
  }
  onStrtTmEdt(s) {
    this.slctdstrt_tm = 'Wed Jun 21 2023 ' + s.schdle_strt_tm + ' GMT+0530 (India Standard Time)';
    this.clstRtsLst.forEach(ele => {
      if (ele.trp_schde_id != s.trp_schde_id) {
        ele.shwStrtTmEdt = false;
      } else {
        ele.shwStrtTmEdt = true;
      }
    });
  }
  onselectStrtTime(tm, s) {
    this.slctdstrt_tm = moment(tm, ["h:mm A"]).format("HH:mm");
    var data = {
      trp_schde_id: s.trp_schde_id,
      schdle_strt_tm: this.slctdstrt_tm,
      key: 'strTm'
    }
    this.updateCnfrmMdl('Start Time', data)
  }
}
