import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzResultModule } from 'ng-zorro-antd/result';


// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
// import { FilterPipe } from './pipes/filter.pipe';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import {
    DxDataGridModule,
    DxTemplateModule,
} from 'devextreme-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PgHeaderComponent } from './components/pg-header/pg-header.component';
import { FiltergrpPipe } from './pipes/filtergrp.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterasrtPipe } from './pipes/filterasrt.pipe';
import { CommonHeaderComponent } from '../apps/pg-header/common-header/common-header.component';
// import { DsFormBuilderComponent } from './ds-form-builder/ds-form-builder.component';
// // import { Ng2SearchPipeModule } from 'ng2-search-filter';


@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NzIconModule, NzTableModule,
        // PerfectScrollbarModule,
        SearchPipe, FiltergrpPipe, NzResultModule,
        NzLayoutModule, NzGridModule, LoadingComponent, PgHeaderComponent, NzFormModule, FilterPipe, FilterasrtPipe,CommonHeaderComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NzIconModule, NzFormModule, NzCheckboxModule, NzInputModule,
        NzToolTipModule, NzSelectModule, NzResultModule, NzTableModule,
        // PerfectScrollbarModule,
        NzLayoutModule, NzGridModule, DxDataGridModule,
        DxTemplateModule,
        NzButtonModule,
    ],
    declarations: [
        SearchPipe,
        MessageDialogComponent,
        LoadingComponent,
        PgHeaderComponent,
        FiltergrpPipe,
        FilterPipe, FilterasrtPipe,CommonHeaderComponent
    ],
    providers: [
        ThemeConstantService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule { }
