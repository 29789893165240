<ds-loading *ngIf="showLdr"></ds-loading>
<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="vhclSldrvisible" nzPlacement="right"
  nzTitle="Vehicle Dashboard" (nzOnClose)="vhclSldrvisible = false" class="dashbSlider">
  <ng-container *nzDrawerContent>
  <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
  </ng-container>
</nz-drawer>

<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="trpSldrvisible" nzPlacement="right"
  nzTitle="Trip Dashboard" (nzOnClose)="trpSldrvisible = false" class="dashbSlider">
  <ng-container *nzDrawerContent>
  <app-trips-slider [trip]="selectedTrip" *ngIf="selectedTrip"></app-trips-slider>
  </ng-container>
</nz-drawer>

<nz-drawer [nzClosable]="true" [nzWidth]="500" [nzVisible]="wghmnts" nzPlacement="right" nzTitle="Add Weighment Details"
  (nzOnClose)="closervrlst()" *ngIf="wghmnts">
  <ng-container *nzDrawerContent>
  <form nz-form [formGroup]="wghmntForm" class="wghmntfrm">
    <div nz-row [nzGutter]="8">

      <div nz-col nzSpan="24">
        <nz-form-label>Vehicle Number</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <input nz-input formControlName="veh_nu" placeholder="Vehicle Number" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24">
        <nz-form-label>Gross Weight</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <input nz-input type="number" formControlName="grs_wght" (input)="calculate()" placeholder="Gross Weight" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24">
        <nz-form-label>Tare Weight</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <input nz-input type="number" formControlName="tr_wght" (input)="calculate()" placeholder="Tare Weight" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24">
        <nz-form-label>Net Weight</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <input nz-input formControlName="nt_wght" placeholder="Net Weight" readonly />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
  <div class="btngrp">
    <button nz-button style="margin-right: 8px;" (click)="closervrlst()" nzType="primary" nzDanger>Cancel</button>
    <button nz-button (click)="updateWeighmnt()" class="sbmtbtn" *ngIf="wghmntForm.status == 'VALID'">Submit</button>
  </div>
  </ng-container>
</nz-drawer>


<div class="page-header">
  <div class=" align-items-md-center">
      <div class="row hdrBar">
          <div class="col-lg-12 col-md-12 mg-a">
              <h1 *ngIf="clntDtls && clntDtls.length > 0">
                  <app-common-header [header_name]="header_label"></app-common-header>
              </h1>
          </div>

      </div>

  </div>
</div>
<div class="main row">
  <div class="vehDsbrd">

    <div class="row fltr">
      <div class="col-lg-3 col-md-3 col-sm-3 ">
        <p class="slctlbl">Select Date</p>
        <nz-date-picker class="slctBx" [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
          (ngModelChange)="onChange($event)"></nz-date-picker>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 " *ngIf="tntLst.length>1">
        <p class="slctlbl">Select ULB</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" nzPlaceHolder="Select ULB"
          (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
          <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
        </nz-select>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 " *ngIf="tntLst.length==1">
        <p class="slctlbl">ULB</p>
        <p class="disbledtxt">{{tntLst[0].tnt_nm}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 mg-a cl" *ngIf="ShowClap">
        <p class="slctlbl">Select Vehicle Vendor</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
          nzPlaceHolder="Select Vendor" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
        </nz-select>
      </div>
      <!-- <div class="col-lg-3 col-md-3 col-sm-3 mg-a cl">
                <p class="slctlbl">Select Vehicle Category</p>
                <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedCtgry" nzPlaceHolder="Select Category"
                    (ngModelChange)="selectCategory($event)" [ngModelOptions]="{standalone: true}">
                    <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                    <nz-option *ngFor="let c of vehCtgrys" [nzLabel]=c.asrt_ctgry_nm [nzValue]=c.asrt_ctgry_id></nz-option>
                </nz-select>
            </div> -->
      <div class="col-lg-3 col-md-3 col-sm-3 ">
        <p class="slctlbl">Select Vehicle</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedVehicle" nzPlaceHolder="Select Vehicle"
          (ngModelChange)="selectVehicle($event)" [ngModelOptions]="{standalone: true}">
          <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
          <nz-option *ngFor="let v of ttl_veh" [nzLabel]=v.asrt_nm [nzValue]=v.asrt_id></nz-option>
        </nz-select>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 ">
      </div>
    </div>

    <div class="row bg-wp">
      <div class="col-lg-9 col-md-9 col-sm-9">
        <h1 style="text-align:left;">Daily Trips Status {{curTblDate}}</h1>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6" style="text-align:center;">
            <button nz-button nzType="primary" class="addmltplBtn" (click)="goToprimaryTrps()">Add MultipleTrips</button>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6" style="text-align:center;">
            <button nz-button nzType="primary" class="addsnglBtn" (click)="goToSchduleIntrfc()">Add Single Trip</button>
          </div>
        </div>

      </div>

      <div class="ds-card grid tbl">
        <dx-data-grid class="p-8" [dataSource]="trpsdata" [remoteOperations]="false" [allowColumnReordering]="true"
          [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true"
          [showBorders]="true" style="width:100%" (onToolbarPreparing)="true" [wordWrapEnabled]="false"
          [columnAutoWidth]="true" (onCellPrepared)="onCellPrepared(tripsDatacolumnDefs,$event)"
          (onCellClick)="getSldr($event)" (onExporting)="onExporting($event)">
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [visible]="trpsdata && trpsdata.length>10" [showPageSizeSelector]="true"
            [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
          <dxo-filter-panel [visible]="false"></dxo-filter-panel>
          <dxo-group-panel [visible]="true"></dxo-group-panel>
          <dxo-grouping [contextMenuEnabled]="true"></dxo-grouping>
          <dxo-column-chooser [enabled]="true" mode="select">
          </dxo-column-chooser>
          <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Trips Travelled
                   Data"></dxo-export>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
          <dxo-summary>
            <dxi-group-item column="asrt_nm" name="Total Vehicles" summaryType="count" [alignByColumn]="true"
              [showInGroupFooter]="true"></dxi-group-item>
            <dxi-group-item column="trp_sqnc_nu" name="Total Trips" summaryType="count" [alignByColumn]="true"
              [showInGroupFooter]="true">
            </dxi-group-item>
            <dxi-group-item column="actl_trvl_dstne_ct" name="Total Distance" summaryType="sum" [alignByColumn]="true"
              [showInGroupFooter]="true"></dxi-group-item>
            <dxi-group-item column="actl_trvl_tm" name="Total Hours" summaryType="sum" [alignByColumn]="true"
              [showInGroupFooter]="true">
            </dxi-group-item>
          </dxo-summary>
          <dxi-column *ngFor="let f of tripsDatacolumnDefs" [visible]="!f.hide" [dataField]="f.field"
            [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true"
            [groupIndex]="f.groupIndex"></dxi-column>
          <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
            cellTemplate="editTemplate">
            <div *dxTemplate="let data of 'editTemplate'">
              <div class="tblEdtBtn" (click)="gotoPlaybck(data)">
                <i nz-icon nzType="node-index" nzTheme="outline"></i>
                <p>Playback</p>
              </div>
            </div>
          </dxi-column>
          <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="Weighment"
            cellTemplate="trpsdata">
            <div *dxTemplate="let data of 'trpsdata'">
              <div class="tblAddBtn" (click)="addWghmnt(data)">
                <p>Add Weighment</p>
              </div>
            </div>
          </dxi-column>


        </dx-data-grid>
      </div>
    </div>



  </div>


</div>