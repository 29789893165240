import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexStroke, ApexFill, ApexYAxis, ApexTitleSubtitle } from 'ng-apexcharts';
import { EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ActivatedRoute, Router } from '@angular/router';
export type ChartOptions = {
  series: any;
  chart: ApexChart;
  stroke: ApexStroke;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  colors: string[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
};

export type ChartOptionstwo = {
  wrkgrphdata 
  series: any;
  chart: ApexChart;
  stroke: ApexStroke;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  colors: string[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
};


export type ChartOptionsthree = {
  series: any;
  chart: ApexChart;
  stroke: ApexStroke;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  colors: string[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
};
// export type ChartOptions = {
//   series: any;
//   chart: ApexChart;
//   stroke: ApexStroke;
//   fill: ApexFill;
//   yaxis: ApexYAxis;
//   colors: string[];
//   title: ApexTitleSubtitle;
//   subtitle: ApexTitleSubtitle;
// };


@Component({
  selector: 'app-oms-dashbourd-cards',
  templateUrl: './oms-dashbourd-cards.component.html',
  styleUrls: ['./oms-dashbourd-cards.component.scss']
})
export class OmsDashbourdCardsComponent implements OnInit, OnChanges {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptionstwo: Partial<ChartOptionstwo>;
  public chartOptionsthree: Partial<ChartOptionsthree>;

  @Input() slctd_dstrt
  @Input() slctCrd
  @Output() slctdata = new EventEmitter();
  graphData: any;
  WorksCrdData: any;
  tripCrdData: any;
  expnsCrdData: any;
  emplyCrdData: any;

  formatter1 = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  slectdCrd: any = 'WORKS';
  slctdTrvldt: any = 3;
  slctdActdt: any = 3;
  slectdExpdt: any = 3;
  cardsdata: any;
  grphdata: any;
  expnsgrphdata: any;
  wrktrpdata: any;
  wrkgrphdata: any[];

  constructor(public apiServ: AppsService, private rout: ActivatedRoute,) {
    console.log(this.slctd_dstrt)

  }

  ngOnInit(): void {
    console.log(this.slctd_dstrt)
    this.last7dayaData();
    this.getCrdData()
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.slctd_dstrt.currentValue)
    if (changes.slctd_dstrt) {
      this.slctd_dstrt = changes.slctd_dstrt.currentValue
      this.getCrdData()
      this.last7dayaData()
      this.selctedcard(this.slctCrd.crd_hdr,this.slctCrd.clr_cd)
    }
  }



  SumINRFormater = (val: any) => {
    if (val) {
      if (val == 'Total:') {
        return val
      }
      return this.formatter1.format(parseFloat(val))
        ? this.formatter1.format(parseFloat(val))
        : 0;
    }

  };
  selctedcard(e, clr_cd) {
    console.log(e)
    this.slectdCrd = e
    const cardclr = {
      crd_hdr: e,
      clr_cd: clr_cd
    }
    this.slctdata.emit(cardclr)
  }

  randomizeArray(data: number[]): number[] {
    return data.map(item => Math.floor(Math.random() * 100));
  }

  generateDailyData(days, fld) {
    console.log(days)
    const daysArray: string[] = [];
    for (let i = 0; i < days.length; i++) {
      daysArray.push(days[i][fld]);
    }
    console.log(daysArray)

    
    return daysArray;
  }

  // Generate an array of days from 1 to 30
  getDaysArray(days) {
    const daysArray: string[] = [];
    for (let i = 0; i < days.length; i++) {
      daysArray.push(days[i].dt);
    }
    console.log(daysArray, 'daysArraydaysArray')
    return daysArray;
  }



  last7dayaData() {
    console.log('last7dayaDatalast7dayaDatalast7dayaDatalast7dayaDatalast7dayaData')
    this.apiServ.get(`apsfl/data/last7dayaData/${this.slctd_dstrt ? this.slctd_dstrt : 0}`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.graphData = res['data'][0];
        console.log(this.graphData)
this.getgraphs()


      }
    }
    )
  }
  getgraphs(){
    this.expnsgrphdata = []
    this.wrkgrphdata = []
    this.wrktrpdata =  []
        if (this.slctdActdt == 3) {
          this.wrkgrphdata = this.graphData.WorksGraphData[0]

          console.log(this.grphdata)
        }
        if (this.slctdActdt == 2) {
          this.wrkgrphdata = this.graphData.WorksGraphData[1]
        }
        if (this.slctdActdt == 1) {
          this.wrkgrphdata = this.graphData.WorksGraphData[2]
          console.log(this.grphdata)
        }



        if (this.slectdExpdt == 3) {
          this.expnsgrphdata = this.graphData.expnsGraphData[0]

          console.log(this.expnsgrphdata)
        }
        if (this.slectdExpdt == 2) {
          this.expnsgrphdata = this.graphData.expnsGraphData[1]
        }
        if (this.slectdExpdt == 1) {
          this.expnsgrphdata = this.graphData.expnsGraphData[2]
          
        }





        if (this.slctdTrvldt == 3) {
          this.wrktrpdata = this.graphData.tripGraphData[0]

          console.log(this.grphdata)
        }
        if (this.slctdTrvldt == 2) {
          this.wrktrpdata = this.graphData.tripGraphData[1]
        }
        if (this.slctdTrvldt == 1) {
          this.wrktrpdata = this.graphData.tripGraphData[2]
          console.log(this.grphdata)
        }







        // var count=1
        // this.graphData.forEach(dta => {
        //     this.grphs(dta,count)
        //     count++
        //   });
        this.chartOptions = {
          series: [{
            name: 'Completed',
            data: this.generateDailyData(this.wrkgrphdata, 'cmpltd_wrks')
          }],
          chart: {
            type: 'area',
            height: 80,
            sparkline: {
              enabled: true
            }
          },
          xaxis: {
            categories: this.getDaysArray(this.wrkgrphdata),  // Generate array of days from 1 to 30
            title: {
              text: 'Days'
            }
          },
          stroke: {
            curve: 'smooth',
            width: 1
          },
          fill: {
            opacity: 0.3
          },
          yaxis: {
            min: 0
          },
          colors: ['#544795'],
          // title: {
          //   text: '$235,312',
          //   offsetX: 0,
          //   style: {
          //     fontSize: '24px'
          //   }
          // },
          // subtitle: {
          //   text: 'Activities',
          //   offsetX: 0,
          //   style: {
          //     fontSize: '14px'
          //   }
          // }
        };
        this.chartOptionstwo = {
          series: [{
            name: 'Completed',
            data: this.generateDailyData(this.wrktrpdata, 'cmpltd_trps')
          }],
          chart: {
            type: 'area',
            height: 80,
            sparkline: {
              enabled: true
            }
          },
          xaxis: {
            categories: this.getDaysArray(this.wrktrpdata),  // Generate array of days from 1 to 30
            title: {
              text: 'Days'
            }
          },
          stroke: {
            curve: 'smooth',
            width: 1
          },
          fill: {
            opacity: 0.3
          },
          yaxis: {
            min: 0
          },
          colors: ['#3e8fb3'],
          // title: {
          //   text: '$235,312',
          //   offsetX: 0,
          //   style: {
          //     fontSize: '24px'
          //   }
          // },
          // subtitle: {
          //   text: 'Travel Registry',
          //   offsetX: 0,
          //   style: {
          //     fontSize: '14px'
          //   }
          // }
        };
        this.chartOptionsthree = {
          series: [{
            name: 'Expense Amount',
            data: this.generateDailyData(this.expnsgrphdata, 'tot_exp_at')
          }],
          chart: {
            type: 'area',
            height: 80,
            sparkline: {
              enabled: true
            }
          },
          xaxis: {
            categories: this.getDaysArray(this.expnsgrphdata),  // Generate array of days from 1 to 30
            title: {
              text: 'Days'
            }
          },
          stroke: {
            curve: 'stepline',
            width: 1
          },
          fill: {
            opacity: 0.3
          },
          yaxis: {
            min: 0,
            labels: {
              formatter: function (val: number) {
                return '₹' + val.toFixed(2);  // Adding Rupee symbol and formatting to 2 decimal places
              }
            }
          },
          colors: ['#d1861d'],
        };
        
      }

  


  getCrdData() {
    var postdata = {
      data: null
    }
    this.apiServ.post(postdata, `apsfl/data/cards/${this.slctd_dstrt ? this.slctd_dstrt : 0}`,).subscribe((res) => {
      if (res['status'] == 200) {
        var data = res['data'][0];


        console.log(data.expnsCrdData[0][0], 'tgggg')
        this.cardsdata = data

        if (this.slctdActdt == 3) {
          this.WorksCrdData = this.cardsdata.WorksCrdData[0][0]
        }
        else if (this.slctdActdt == 2) {
          this.WorksCrdData = this.cardsdata.WorksCrdData[1][0]
        }
        else if (this.slctdActdt == 1) {
          this.WorksCrdData = this.cardsdata.WorksCrdData[2][0]
        }

        if (this.slectdExpdt == 3) {
          this.expnsCrdData = this.cardsdata.expnsCrdData[0][0]


        }
        else if (this.slectdExpdt == 2) {
          this.expnsCrdData = this.cardsdata.expnsCrdData[1][0]
        }
        else if (this.slctdActdt == 1) {
          this.expnsCrdData = this.cardsdata.expnsCrdData[2][0]

        }




        if (this.slctdTrvldt == 3) {
          this.tripCrdData = this.cardsdata.tripCrdData[0][0]
          console.log(this.tripCrdData)


        }
        else if (this.slctdTrvldt == 2) {
          this.tripCrdData = this.cardsdata.tripCrdData[1][0]
        }
        else if (this.slctdTrvldt == 1) {
          this.tripCrdData = this.cardsdata.tripCrdData[2][0]

        }





        console.log(data)
      }

    })

  }


  tabsActdata = [
    {
      'tab_id': 1,
      'tab_nm': 'Overall',
      'tab_hndlr': 'overall'
    },
    {
      'tab_id': 2,
      'tab_nm': 'This Month',
      'tab_hndlr': 'crntmnt'

    },
    {
      'tab_id': 3,
      'tab_nm': 'Today',
      'tab_hndlr': 'crntdt'

    },

  ]

  tabsExpdata = [
    {
      'tab_id': 1,
      'tab_nm': 'Overall'
    },
    {
      'tab_id': 2,
      'tab_nm': 'This Month'
    },
    {
      'tab_id': 3,
      'tab_nm': 'Today'
    },

  ]
  tabsTrvlRgstrydata = [
    {
      'tab_id': 1,
      'tab_nm': 'Overall'
    },
    {
      'tab_id': 2,
      'tab_nm': 'This Month'
    },
    {
      'tab_id': 3,
      'tab_nm': 'Today'
    },

  ]

  slectdActTab(t) {


    this.WorksCrdData = []


    this.slctdActdt = t.tab_id


    console.log(this.cardsdata.WorksCrdData)

    if (this.slctdActdt == 3) {
      this.WorksCrdData = this.cardsdata.WorksCrdData[0][0]
    }
    else if (this.slctdActdt == 2) {
      this.WorksCrdData = this.cardsdata.WorksCrdData[1][0]
    }
    else if (this.slctdActdt == 1) {
      this.WorksCrdData = this.cardsdata.WorksCrdData[2][0]
    }
    this.getgraphs()





  }

  slectdTrvlRgstryTab(t) {
    this.tripCrdData = []
    this.slctdTrvldt = t.tab_id
    if (this.slctdTrvldt == 3) {
      this.tripCrdData = this.cardsdata.tripCrdData[0][0]
    


    }
    else if (this.slctdTrvldt == 2) {
      this.tripCrdData = this.cardsdata.tripCrdData[1][0]
    }
    else if (this.slctdTrvldt == 1) {
      this.tripCrdData = this.cardsdata.tripCrdData[2][0]

    }
    this.getgraphs()


  }
  slectdExpTab(t) {
    console.log(t)
    this.slectdExpdt = t.tab_id
    this.expnsCrdData = []
    if (this.slectdExpdt == 3) {
      this.expnsCrdData = this.cardsdata.expnsCrdData[0][0]

      console.log( this.expnsCrdData)


    }
    else if (this.slectdExpdt == 2) {
      this.expnsCrdData = this.cardsdata.expnsCrdData[1][0]
    }
    else if (this.slectdExpdt == 1) {
      console.log(this.cardsdata.expnsCrdData)
      console.log(this.cardsdata.expnsCrdData[2][0])
      this.expnsCrdData = this.cardsdata.expnsCrdData[2][0]

    }
    this.getgraphs()


  }
}
