<ds-loading *ngIf="showLdr"></ds-loading>

<div class="row hdrBar">
    <div class="col-lg-4 col-md-4 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0 && clntDtls[0].clnt_id==7">ULB Wise Coverage Status
             <span *ngIf="selectedtenantNm" class="tntTxt">({{selectedtenantNm}})</span></h1>
    </div>
    <div class="col-lg-3 col-md-3 mg-a" style="text-align: right;" *ngIf="tntLst.length>1">
        <span>Select ULB:</span>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
            [ngModelOptions]="{standalone: true}">
            <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
            <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
        </nz-select>
    </div>
    <div class="col-lg-3 col-md-3 mg-a" style="text-align: right;" *ngIf="tntLst.length==1">
        <span>ULB:</span>
        <span class="disbledtxt">{{tntLst[0].tnt_nm}}</span>
    </div>
    <div class="col-lg-3 col-md-3 mg-a">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
    <div class="col-lg-2 col-md-2 mg-a">
        <button nz-button nzType="default" (click)="goBack()" style="    box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
        color: #ffffff;
        font-weight: 500;
        background: #ff7272;
        float: right;
        border-radius: 4px;"><span style="color:#fff"><i nz-icon nzType="left"></i></span> <span style="color:#fff">Go back</span></button>
    </div>
</div>
<div class="main">
    <div class="row">
        <ul class="cards">
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/pick-up-truck.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                                <div class="cvrg_crd_cnt">{{d.TOT_VEHICLES === null ? "0" : d.TOT_VEHICLES}}</div>
                                <div class="cvrg_crd_lbl">Total Vehicles</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="    background: linear-gradient(135deg, #6b3a0b 0%,#ab5d4a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/rout.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #8e4d2d;"></div>
                                <div class="cvrg_crd_cnt">{{d.OPERATED === null ? "0" : d.OPERATED}} / {{d.TOTAL_TRIPS === null ? "0" : d.TOTAL_TRIPS}}<span style="font-size: 12px;"> ({{d.OPERATED*100/d.TOTAL_TRIPS | number:'1.2-2'}} %)</span></div>
                                <div class="cvrg_crd_lbl">Completed Trips/Total Trips</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="   background: linear-gradient(135deg, #665f03 0%,#817e26 100%);">
                    <div class="card_content">


                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/routes.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #6c660b;"></div>
                                <div class="cvrg_crd_cnt">{{d.trvl_dist === null ? "0" : d.trvl_dist}}</div>
                                <div class="cvrg_crd_lbl">Distance</div>
                            </div>

                        </div>


                    </div>
                </div>
            </li>


            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);">
                    <div class="card_content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/discount.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #28816b;"></div>
                                <div class="cvrg_crd_cnt"> {{avg_cvrg === null ? 0 : avg_cvrg}}</div>
                                <div class="cvrg_crd_lbl">Coverage %</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>



<div class="dxgridpdng ulbsgridcvrge" style="padding-top:0px">
    <h1 class="bdycntnthdr">ULB Trip Coverage Status</h1>
        <dx-data-grid id="gridContainer" class="p-8" [dataSource]="tripsData" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
            [wordWrapEnabled]="false" (onCellClick)="devCellclk(columnDefs,$event)"  
            (onToolbarPreparing)="true" (onCellPrepared)="onCellPrepared(columnDefs,$event)" [columnAutoWidth]="true" (onExporting)="onExporting1($event)">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxo-pager [visible]="tripsData.length>10" [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" [highlightSearchText]="true">
            </dxo-search-panel>
            <dxo-filter-panel [visible]="false"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="ulb wise operated Trips">
            </dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [fixed]="f.fixed"
                [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
            </dxi-column>
            <dxo-summary>
                <dxi-total-item *ngFor="let g of ttlColumnDefs " [column]="g.field"
                        [summaryType]="g.summaryType ? g.summaryType: ''"
                        [displayFormat]="g.crncy_in == 1 ? currencyINRFormater:roundingFormater" [alignment]="g.algnmnt"  [valueFormat]="g.format ? g.format : '' ">
                      </dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
</div>
<div class="row ">
    <div class="col-lg-6 col-md-6">
        <div class="dxgridpdng">
        <h1 class="bdycntnthdr">Coverage in Last 7 Days </h1>
        <div id="coverageChart"></div>
    </div>
    </div>
    <div class="col-lg-6 col-md-6">
        <div class="dxgridpdng znewsecvrges">
        <h1 class="bdycntnthdr">Top 10 Vehicle Coverages</h1>
            <dx-data-grid id="gridContainer" class="p-8" [dataSource]="toptripsData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="false"  [columnAutoWidth]="true" (onExporting)="onExporting2($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="false" [showPageSizeSelector]="false" [showInfo]="false"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" [highlightSearchText]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="Zone wise Top 10 Coverages">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of topTripscolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
            </dx-data-grid>
    </div>
    </div>
</div>
