<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" nzTitle="Vehicle Dashboard"
    (nzOnClose)="visible = false" class="dashbSlider">
    <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
</nz-drawer>
<div class="row hdrBar">
    <div class="col-lg-12 col-md-12 mg-a">
        <h1>Vehicle wise Billing</h1>
    </div>
</div>
<div class="row">
    <div class="col-lg-3 col-md-3 mg-a">
        <p class="slctlbl">ULB</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" nzPlaceHolder="Select ULB"
            (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}">
            <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
            <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
        </nz-select>
    </div>
    <div class="col-lg-3 col-md-3 mg-a">
        <p class="slctlbl">Vehicle Vendor</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
            nzPlaceHolder="Select Vehicle Vendor" [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
        </nz-select>
    </div>
    <div class="col-lg-3 col-md-3 mg-a">
        <p class="slctlbl">Year</p>
        <nz-select class="slctBx" [(ngModel)]="selectedYear" (ngModelChange)="onSelectMnthYr()"
            [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let y of years" [nzValue]="y" nzLabel="{{y}}"></nz-option>
        </nz-select>
    </div>

    <div class="col-lg-3 col-md-3 mg-a">
        <p class="slctlbl">Month</p>
        <nz-select class="slctBx" [(ngModel)]="selectedmonth" (ngModelChange)="onSelectMnthYr()"
            [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let m of months" [nzValue]="m.id" nzLabel="{{m.name}}"></nz-option>
        </nz-select>
    </div>
</div>
<div class="main">

    <div class="row"  *ngFor="let cnts of cardSummary">
        <ul class="cards">
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #49948a 0%,#137986 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Total Vehicles</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <!-- <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="car" nzTheme="outline"></i>
                                </h1>
                            </div>-->
                            <div class="col-lg-12 col-md-12">
                                <h1 class="card_sub_title">{{cnts.tot_veh}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card_g_ftr1">
                        <h1 class="card_sub_title textSmall" style="color: white;">(A)</h1>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #65548a 0%,#352e88 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Total Work Days</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <!-- <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="calendar" nzTheme="outline"></i>
                                </h1>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <h1 class="card_sub_title"></h1>
                            </div>
                        </div>
                        <div class="row crdHdr_sb_sm">
                            <div class="col-lg-6 col-md-6">
                                <h1 class= "textSmall">
                                    {{mnthnm}}
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title">{{daysInMonth}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card_g_ftr2">
                        <h1 class="card_sub_title textSmall" style="color: white;">(B)</h1>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #429749 0%,#62680b 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Expected Vehicle Work Days</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <!-- <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="carry-out" nzTheme="outline"></i>
                                </h1>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <h1 class="card_sub_title">{{cnts.exptd_wrkng_dy_ct}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card_g_ftr3">
                        <h1 class="card_sub_title textSmall" style="color: white;">(C = A * B)</h1>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #6e3f74 0%,#8a1a82 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Operational Vehicle Work Days</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <!-- <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="calendar" nzTheme="outline"></i>
                                </h1>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <h1 class="card_sub_title">{{cnts.oprtnl_wrkng_dy_ct}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card_g_ftr4">
                        <h1 class="card_sub_title textSmall" style="color: white;">(D)</h1>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #404185 0%,#3b2a64 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Non Operational Vehicle Work Days</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <!-- <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                                </h1>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <h1 class="card_sub_title">{{cnts.nt_oprtnl_wrkng_dy_ct}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card_g_ftr5">
                        <h1 class="card_sub_title textSmall" style="color: white;">(E)</h1>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="main">
 <!-- <div class="row" style="margin-bottom: 10px;margin-top: 10px;">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <nz-card nzTitle="Vehicle Wise Billing For {{mnthnm}} - {{selectedYear}}" class="tablecard">
            <div class="row gridTop  verScroll" style="height: 100vh;" id="target">
                <div style="width:100%;margin-right: 2px;"> -->
        <div class="row bg-wp">
            <h1>Vehicle Wise Billing For {{mnthnm}} - {{selectedYear}}</h1>
            <div class="ds-card grid tbl">
                <dx-data-grid id="gridContainer" [dataSource]="vehcleTble" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                (onToolbarPreparing)="true" [wordWrapEnabled]="true"(onExporting)="onExporting($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="vehcleTble.length>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 20, 30, 50]" [showInfo]="true">
                </dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                <dxo-export [enabled]="true" fileName="vehicle"></dxo-export>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [caption]="d.headerName"
                    [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100"
                    caption="View" cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotovehcleDashboard(data)"><i nz-icon nzType="car"
                                nzTheme="outline"></i></div>
                    </div>
                </dxi-column>
                <!-- <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100"
                    caption="Edit" cellTemplate="editTemplate2">
                    <div *dxTemplate="let data of 'editTemplate2'">
                        <div class="tblEdtBtn" (click)="gotovehclesPage(data)"><i nz-icon nzType="edit" nzTheme="outline"></i></div>
                    </div>
                </dxi-column> -->
                <dxo-summary>
                    <!-- <dxi-total-item *ngIf="f.summaryType" [column]="f.field" [summaryType]="f.summaryType"
                                    [displayFormat]="f.displayFormat" 
                                    [valueFormat]="f.smryValueFormat == 'true' ? {type:'currency',currency:'INR', precision:'2'} : ''"
                                    [alignment]="f.alignment" >
                                  </dxi-total-item> -->
                    <dxi-total-item column="asrt_nm" name="Total vehicles" summaryType="count"></dxi-total-item>
                    <dxi-total-item column="exptd_wrkng_dy_ct" name="Total Expected Vehicle Working Days"
                        summaryType="sum"></dxi-total-item>
                    <dxi-total-item column="oprtnl_wrkng_dy_ct" name="Total Operated Vehicles Working Days"
                        summaryType="sum"></dxi-total-item>
                    <dxi-total-item column="nt_oprtnl_wrkng_dy_ct" name="Total Not Operated Vehicles Working Days"
                        summaryType="sum"></dxi-total-item>
                    <dxi-total-item column="amnt_pyble_ct" name="Total Payable Amount" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}">
                    </dxi-total-item>
                </dxo-summary>
            </dx-data-grid>
         <!-- </div>
            </div>
        </nz-card> -->
            </div>
        </div>

    </div>
</div>