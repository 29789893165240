import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterasrt'
})
export class FilterasrtPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) return [];

    if (!searchText) return items;

    let args=searchText.toLowerCase()

    return items.filter((val)=>{
      return val['asrt_nm'].toLowerCase().includes(args)

    })

    // return this.searchItems(items, searchText.toLowerCase());


  }

  private searchItems(items: any[], searchText: string): any[] {
    let results = [];
    items.forEach(it => {
      const groupName = it.grp_nm ? it.grp_nm.toLowerCase() : '';
      if (groupName.includes(searchText)) {
        results.push(it);
      } else if (it.opts && Array.isArray(it.opts)) {
        let searchResults = this.searchItems(it.opts, searchText);
        if (searchResults.length > 0) {
          results.push({
            grp_nm: it.grp_nm,
            opts: searchResults
          });
        }
      }
    });
    return results;
  }
}