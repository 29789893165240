import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as moment from 'moment';

@Component({
  selector: 'app-driver-dashboard',
  templateUrl: './driver-dashboard.component.html',
  styleUrls: ['./driver-dashboard.component.scss']
})
export class DriverDashboardComponent implements OnInit {
  
  header_label = {
    name: 'Driver Dashboard'
  };

  showLdr = false;
  drvrDtls: any = [];
  shwdtlsErrMsg;
  drvrTtlDtls: any;
  init_lat = 17;
  init_lng = 81;
  shwLastweekErrMsg;
  today = new Date();
  curYr: any; crw_nm; crw_lcnse_nu; crw_doj; crw_ph; crw_asgndVeh; crw_vehSchvlm; crw_vehSts; drvrOptnlDtls;
  drvrFulDtls: any;
  drvrSpdDtls: any;
  ovrSpeedMsg: string;
  oprtnlhrsMsg: string;
  oprtnlhrs;
  fuelDtaMsg: string;
  drvrHrlyMsg: string;
  gtDta;
  tntLst: any = [];
  clntDtls: any;
  selectedtenant = 0;
  vendorList: any = [];
  selectedvndr = 0;
  selectedrvr;
  drvrslst: any;
  drvrnm: any;
  postData: {};
  userDtls: any;
  clap_in: number;
  ShowClap: boolean;
  showVndr: boolean;
  nodata: boolean;
  drvrHrlyDtls: any;
  shwChrt: boolean;
  crwDtls;
  constructor(public apiServ: AppsService) {

    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    // this.selectedtenant = this.tntLst[0].tnt_id;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    console.log(this.userDtls);
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
      this.selectedvndr = 0;
    }
  }

  ngOnInit() {
    this.curYr = moment(this.today).format('YYYY');
    console.log('In ----------------------------------- DAte', this.curYr);
    this.initialize();
    this.getDriversLst();
  }

  initialize = function () {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.driverMap = L.map('driverMap', {
        center: [this.init_lat, this.init_lng],
        zoom: 3
      });

      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: ''
      });

      tiles.addTo(this.driverMap);

    } catch (e) {
      // console.log("please check map Initialization of leaflet:" + e);
    }

  };
  getDriverdetails(drvrId): any {
    this.drvrDtls = [];
    this.showLdr = true;
    this.crw_nm = '';
    this.crw_lcnse_nu = ''
    this.crw_doj = '';
    this.crw_ph = '';
    this.crw_asgndVeh; this.crw_vehSchvlm; this.crw_vehSts;
    this.crwDtls = [];
    const rte = `drivers/getEachDriverDetails/${drvrId}`;
    this.apiServ.get(rte).subscribe((res) => {
      if (res['data'] && res['data'].length >= 0) {
        this.drvrDtls = res['data'];
        console.log(this.drvrDtls[0]);
        this.crwDtls = this.drvrDtls[0];
        this.showLdr = false;
      }
      else {
        this.showLdr = false;
        this.crw_nm = this.gtDta.driver.currentValue.crw_nm;
        this.crw_lcnse_nu = this.gtDta.driver.currentValue.crw_lcnse_nu;
        this.crw_doj = this.gtDta.driver.currentValue.doj;
        this.crw_ph = this.gtDta.driver.currentValue.crw_ph;
        this.shwdtlsErrMsg = 'No details found regarding to the selected Dirver'
      }
    }, (err) => {
      console.log(err);
    });
  }
  getTotalDtls(drvrId, tntId, vndrId): any {
    this.drvrTtlDtls = [];
    this.showLdr = true;
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    console.log(this.postData);
    const rte = `drivers/getTotalCnts`;
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrTtlDtls = res['data'];
      console.log(this.drvrTtlDtls);
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }

  getDriverhourlyDtls(drvrId, tntId, vndrId): any {
    this.drvrHrlyDtls = [];
    this.showLdr = true;
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    const rte = `drivers/driver/hourly_distance`;
    let chart = am4core.create("hepachartdiv", am4charts.XYChart);
    chart.data = [];

    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrHrlyDtls = res['data'];
      this.drvrHrlyDtls.forEach(obj => {
        if (obj.dst == null) {
          obj.dst = 0;
        }
        else {
          obj.dst = obj.dst
        }

      })
      console.log(this.drvrHrlyDtls);
      chart.data = this.drvrHrlyDtls;


      chart.maskBullets = true;

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      xAxis.dataFields.category = "trvl_hr";
      yAxis.dataFields.category = "trvl_dt";

      xAxis.title.text = "------Hour-------";
      yAxis.title.text = "------Date-------";

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 10;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "trvl_hr";
      series.dataFields.categoryY = "trvl_dt";
      series.dataFields.value = "dst";
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      var bgColor = new am4core.InterfaceColorSet().getFor("background");

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 0.2;
      columnTemplate.stroke = bgColor;
      columnTemplate.tooltipText = "{dst}";
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);
      columnTemplate.stroke = am4core.color("eff567");

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        min: am4core.color(bgColor),
        max: chart.colors.getIndex(0)
      });
      var legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.data = [{
        "name": "Distance Covered",
        "fill": am4core.color("#bec4f8")
      }];

      // series.heatRules.push({
      // 	target:columnTemplate, 
      // 	property:"fill", 
      // 	min:am4core.color("#f5f6fe"), 
      // 	max:am4core.color("#554abd"),
      // 	minValue: 2000,
      // 	maxValue: 5000
      //   });


      // heat legend
      var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
      heatLegend.width = am4core.percent(100);
      heatLegend.series = series;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
      heatLegend.valueAxis.renderer.minGridDistance = 30;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{dst}";
      valueLabel.label.fontSize = 15;

      // columnTemplate1.width = 60;
      columnTemplate.column.cornerRadiusTopLeft = 5;
      columnTemplate.column.cornerRadiusTopRight = 5;


      // heat legend behavior
      series.columns.template.events.on("over", function (event) {
        handleHover(event.target);
      })

      series.columns.template.events.on("hit", function (event) {
        handleHover(event.target);
      })

      function handleHover(column) {
        if (!isNaN(column.dataItem.value)) {
          heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
        }
        else {
          heatLegend.valueAxis.hideTooltip();
        }
      }

      series.columns.template.events.on("out", function (event) {
        heatLegend.valueAxis.hideTooltip();
      })









      if (this.drvrHrlyDtls.length == 0) {
        this.drvrHrlyMsg = 'Hourly Distance not found to this driver.';
      } else {
        this.drvrHrlyMsg = '';
      }
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }

  chrtVsblcnt = 0;

  getLst7Daysdtls(drvrId, tntId, vndrId): any {
    this.showLdr = true;
    let chart1 = am4core.create("lastSevenDaysDistanceChart", am4charts.XYChart);
    let chart2 = am4core.create("lastSevenDaysChart", am4charts.XYChart);
    chart1.data = [];
    chart2.data = [];
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    const rte = `drivers/getLast7DaysCnts`;
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrDtls = res['data'].results;
      console.log(this.drvrDtls);
      chart1.data = this.drvrDtls;
      chart2.data = this.drvrDtls;
      // chart2.legend = new am4charts.Legend();
      // if(this.drvrDtls[0].trp_ct!=0 || this.drvrDtls[1].trp_ct!=0  || this.drvrDtls[2].trp_ct!=0 || 
      //   this.drvrDtls[3].trp_ct!=0 || this.drvrDtls[4].trp_ct!=0 || this.drvrDtls[5].trp_ct!=0 || this.drvrDtls[6].trp_ct!=0 ){
      //     this.nodata=true;
      //   }else{
      //     this.nodata=false;
      //   }00000 
      if (res['data'].msg == 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
      console.log(this.nodata);
      this.showLdr = false;
      this.shwLastweekErrMsg = '';

      let categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "app_dt";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = ` ----- Date(${this.curYr}) ----- `;
      let valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = " ----- Trips Covered -----";
      valueAxis.cursorTooltipEnabled = false;
      let series = chart2.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "trp_ct";
      series.dataFields.categoryX = "app_dt";
      series.clustered = false;
      series.columns.template.width = am4core.percent(50);
      series.tooltipText = "Travelled Distance on {categoryX}: [bold]{valueY}[/]KM";
      series.columns.template.fill = am4core.color("#55ac73");
      series.columns.template.stroke = am4core.color("#55ac73");
      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{trp_ct}";
      valueLabel.label.fontSize = 15;
      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      chart2.cursor = new am4charts.XYCursor();
      chart2.cursor.lineX.disabled = true;
      chart2.cursor.lineY.disabled = true;




      let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis1.dataFields.category = "app_dt";
      categoryAxis1.renderer.grid.template.location = 0;
      categoryAxis1.renderer.minGridDistance = 30;
      categoryAxis1.title.text = ` ----- Date(${this.curYr}) ----- `;
      let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = " ----- Distance Covered -----";
      valueAxis1.cursorTooltipEnabled = false;
      let series2 = chart1.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = "tot_dst";
      series2.dataFields.categoryX = "app_dt";
      series2.clustered = false;
      series2.columns.template.width = am4core.percent(50);
      series2.tooltipText = "Travelled Distance on {categoryX}: [bold]{valueY}[/]KM";
      series2.columns.template.fill = am4core.color("#544aba");
      series2.columns.template.stroke = am4core.color("#544aba");
      let valueLabel2 = series2.bullets.push(new am4charts.LabelBullet());
      valueLabel2.label.text = "{tot_dst}";
      valueLabel2.label.fontSize = 15;
      let columnTemplate2 = series2.columns.template;
      columnTemplate2.strokeWidth = 1;
      chart1.cursor = new am4charts.XYCursor();
      chart1.cursor.lineX.disabled = true;
      chart1.cursor.lineY.disabled = true;

    }, (err) => {
      console.log(err);
    });
  }

  getOprtnlDtls(drvrId, tntId, vndrId): any {
    this.drvrOptnlDtls = [];
    this.showLdr = true;
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    const rte = `drivers/operational`;
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrOptnlDtls = res['data'];
      console.log(this.drvrOptnlDtls);
      if (this.drvrOptnlDtls.length == 0) {
        this.oprtnlhrsMsg = 'No Operational Details found to this driver.';
      } else {
        this.oprtnlhrsMsg = '';
      }
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }

  // getFuelDtls(drvrId): any {
  //   this.drvrFulDtls = [];
  //   this.showLdr = true;
  //   const rte = `drivers/fuel/${drvrId}`;
  //   this.apiServ.get(rte).subscribe((res) => {
  //         this.drvrFulDtls = res['data'];
  //         console.log(this.drvrFulDtls);
  //         if(this.drvrFulDtls.length == 0){
  //           this.fuelDtaMsg = 'Fuel Issued not found to this driver.';
  //         }else{
  //           this.fuelDtaMsg = '';
  //         }
  //         this.showLdr = false;
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }

  getSpeedDtls(drvrId, tntId, vndrId): any {
    this.drvrSpdDtls = [];
    this.showLdr = true;
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    const rte = `drivers/overspeed`;
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrSpdDtls = res['data'];
      console.log(this.drvrSpdDtls);
      if (this.drvrSpdDtls.length == 0) {
        this.fuelDtaMsg = 'Speed Violation not found to this driver.';
      } else {
        this.fuelDtaMsg = '';
      }
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }


  getVendors() {
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      this.apiServ.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vendorList = res['data'];
        }
      }, (err) => {
        console.log("In errorr");
      })
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
    } else {
      this.apiServ.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          // this.USER_PERMISSIONS = res['perm'][0];
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
          }
        }
      }, (err) => {
        console.log('In errorr');
      });
    }
  }


  getDriversLst = (drvr?) => {
    const fltrData = {
      selectedtenant: this.selectedtenant,
    };
    this.selectedrvr = drvr;
    console.log('this.selectedrvr', this.selectedrvr)
    if (this.selectedvndr) {
      // tslint:disable-next-line:no-string-literal
      fltrData['selectedvndr'] = this.selectedvndr;
    }
    this.showLdr = true;
    this.apiServ.post(fltrData, 'drivers/drivers').subscribe((res) => {
      this.drvrslst = res['data'];
      this.showLdr = false;
      console.log(this.drvrslst);

      if (this.selectedrvr) {
        console.log('in', this.selectedrvr)
        this.getDriverdetails(this.selectedrvr);
        this.getTotalDtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
        this.getLst7Daysdtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
        this.getOprtnlDtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
        // this.getFuelDtls(this.selectedrvr);
        this.getSpeedDtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
        this.getDriverhourlyDtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);


        // for(let i=0; i<this.drvrslst.length; i++){
        //   if(this.drvrslst[i].crw_id == this.selectedrvr){
        //     this.crw_nm = this.drvrslst[i].crw_nm;
        //     this.crw_lcnse_nu = this.drvrslst[i].crw_lcnse_nu;
        //     this.crw_doj = this.drvrslst[i].doj;
        //     this.crw_ph = this.drvrslst[i].crw_ph;
        //   }
        // }

      } else {
        console.log('else')

        this.getDriverdetails(this.drvrslst[0].crw_id);
        this.getTotalDtls(this.drvrslst[0].crw_id, this.drvrslst[0].tnt_id, this.drvrslst[0].vndr_id);
        this.getLst7Daysdtls(this.drvrslst[0].crw_id, this.drvrslst[0].tnt_id, this.drvrslst[0].vndr_id);
        this.getOprtnlDtls(this.drvrslst[0].crw_id, this.drvrslst[0].tnt_id, this.drvrslst[0].vndr_id);
        // this.getFuelDtls(this.drvrslst[0].crw_id);
        this.getSpeedDtls(this.drvrslst[0].crw_id, this.drvrslst[0].tnt_id, this.drvrslst[0].vndr_id);
        this.getDriverhourlyDtls(this.drvrslst[0].crw_id, this.drvrslst[0].tnt_id, this.drvrslst[0].vndr_id);

        // this.crw_nm = this.drvrslst[0].crw_nm;
        // this.crw_lcnse_nu = this.drvrslst[0].crw_lcnse_nu;
        // this.crw_doj = this.drvrslst[0].doj;
        // this.crw_ph = this.drvrslst[0].crw_ph;
      }


      //  this.drvrnm = this.drvrslst[0].crw_nm

      // this.drvrslst.forEach((element,index) => {
      //   element['clr_obj'] = this.colors[(index%this.colors.length)];
      // });
      // this.drvrslst.filter((k) => {
      //   k.chk_ind = true;
      // })

      // this.drvrslstcolumnDefs = [
      //   // { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
      //   { headerName: 'Driver Name', alignment: 'left',  field: 'crw_nm' },
      //   { headerName: 'Licence No', alignment: 'center',  field: 'crw_lcnse_nu' },
      //   { headerName: 'DOJ',   alignment: 'center',field:'doj' },
      //   { headerName: 'Mobile No', field: 'crw_ph',  alignment: 'center' }
      // ]

    }, (err) => {
      // console.log(err);
    });
  }

  ontntSelect(value) {
    this.selectedtenant = value;
    this.selectedvndr = 0;
    this.getVendors();
    this.getDriversLst();
  }

  onvndrSelect(value) {
    this.selectedvndr = value;
    this.getDriversLst();
  }
  ondrvrSelect(value) {
    this.selectedrvr = value;
    console.log(this.selectedrvr);
    this.getDriverdetails(this.selectedrvr);
    this.getTotalDtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
    this.getLst7Daysdtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
    this.getOprtnlDtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
    // this.getFuelDtls(this.selectedrvr);
    this.getSpeedDtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
    this.getDriverhourlyDtls(this.selectedrvr, this.selectedtenant, this.selectedvndr);
  }




}