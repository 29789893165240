import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-roles-premessions',
  templateUrl: './roles-premessions.component.html',
  styleUrls: ['./roles-premessions.component.scss']
})
export class RolesPremessionsComponent implements OnInit {
  loader;
  roledta;
  columnDefs;
  rlepermdta;
  splpermdta;
  permismdl;
  shwSidebar;
  sidebarHeading;
  deleteRle;
  finalData = [{ standard: [], special: [] }];
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  RolesForm: FormGroup;
  checkid;
  errorTpl;
  editClicked;
  updateData;
  account_validation_messages = {
    'rolename': [
      { type: 'required', message: 'Rolename is required' },
      { type: 'validUsername', message: 'Your Rolename has already been taken' },
      { type: 'cannotContainSpace', message: 'Please enter valid data spaces are not requried' }
    ]
  }
  constructor(public apiSrv: AppsService,private message: NzMessageService) { 
    this.getRolesWithPerm();
  }

  ngOnInit() {
    this.RolesForm = new FormGroup({
      rle_nm: new FormControl('', Validators.compose([
        this.validUsername,
        Validators.required,
        this.cannotContainSpace
      ])),
      rle_dscn_nm: new FormControl('', Validators.required),
    });
  }
  cannotContainSpace = (control: FormControl)  => {
    if((control.value as string).indexOf(' ') >= 0){
        return {cannotContainSpace: true}
    }

    return null;
}
  validUsername = (fc: FormControl) => { 
    console.log(fc)
    if(!this.roledta){
    }
   else if(this.roledta.length>1){
     if(!this.checkid){
      for(var i=0; i<this.roledta.length; i++){
        console.log(fc.value.toLowerCase(),this.roledta[i].rle_nm.toLowerCase())
        if (fc.value.toLowerCase() === this.roledta[i].rle_nm.toLowerCase() || fc.value.toLowerCase() === this.roledta[i].rle_nm.toLowerCase()+' ') {
          return ({ validUsername: true });
        } else {
        }
      }
     }
     else{
      for(var i=0; i<this.roledta.length; i++){
        if ((fc.value.toLowerCase() === this.roledta[i].rle_nm.toLowerCase() || fc.value.toLowerCase() === this.roledta[i].rle_nm.toLowerCase()+' ')&&(this.checkid.rle_id==this.roledta[i].rle_id)) {
          return null;
        } else if (fc.value.toLowerCase() === this.roledta[i].rle_nm.toLowerCase() || fc.value.toLowerCase() === this.roledta[i].rle_nm.toLowerCase()+' '){
          return ({ validUsername: true });
        }
      }

     }

    }
  }
  saveRole() {
    if (this.editClicked == false) {
      this.newentry();
    } else if (this.deleteRle == true) {
      this.delete(this.updateData);
    } else {
      this.update(this.updateData);
    }
  }
  newentry() {
    let rte = "auth2/admin/roles";
    let data = {
      rle_nm: this.RolesForm.value.rle_nm,
      rle_dscn_nm: this.RolesForm.value.rle_dscn_nm,
    };
    this.loader = true;
    this.apiSrv.create(data, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.loader = false;
        this.message.success('Role added sucessfully', {
          nzDuration: 2000
        });
        this.getRolesWithPerm();
        this.getRolePerms(res['data'].insertId);
         this.closeSideBar;
        this.permismdl = true;

      }
      else{
        this.loader = false;
      }
    }, (error) => {
      this.loader = false;
    });
  }
  delete(data) {
   console.log(data)
    let orgDelRte = `auth2/admin/roles/${data.data.rle_id}`;
    this.apiSrv.delete(orgDelRte).subscribe((res) => {
      if (res['status'] == 200) {
        this.message.success('Role deleted sucessfully', {
          nzDuration: 2000
        });
        this.closeSideBar();
        this.getRolesWithPerm();
      }
    })
  }
  update(data) {
    let rte = `auth2/admin/roles/${data.rle_id}`;

    let Roledata = {
      rle_nm: this.RolesForm.value.rle_nm,
      rle_dscn_nm: this.RolesForm.value.rle_dscn_nm,
    };
    this.apiSrv.update(Roledata, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.message.success('Role updated sucessfully', {
          nzDuration: 2000
        });
        this.closeSideBar();
        this.getRolesWithPerm();
      }
    }, (error) => {
    });
  }
  getRolesWithPerm() {
    this.loader=true;
    const rte = `auth2/admin/roles`;
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe((res) => {
      if(res['status']==200)
      {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
        this.loader=false;
        this.roledta = res['data'];
        this.columnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', cellClass: "pm-grid-number-cell", width: 60, height: 40 },
          { headerName: 'Role Name', field: 'rle_nm', alignment: 'left', cellClass: "pm-grid-number-cell", width: 200, height: 40, filter: true },
          { headerName: 'Role Description', field: 'rle_dscrn_tx', alignment: 'left', cellClass: "pm-grid-number-cell", width: 200, height: 40, filter: true },
          { headerName: 'Created User', field: 'name', alignment: 'left', cellClass: "pm-grid-number-cell", width: 200, height: 40, filter: true },
          { headerName: 'Created Date', field: 'dt_frmt', alignment: 'center', cellClass: "pm-grid-number-cell", width: 200, height: 40 },
          { headerName: 'No.of Role Users', field: 'ttl_usr_cnt', alignment: 'center', cellClass: "pm-grid-number-cell", width: 60, height: 40 },
        ];
      }
    }
    else {
      this.usrAcsErrMsg = true;
    }
    })
  }
  onToolbarPreparing(e,insrt) {
    if(insrt=='true'){
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Role',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  else{}
  }
  openSideBar(opt, data) {
    console.log(data.data)
    console.log(opt)
    if (opt == 'edit') {
      this.permismdl = false;
      this.shwSidebar = true;
      this.editClicked = true;
      this.sidebarHeading = "Edit Role";
      this.checkid = data.data
      this.RolesForm.get('rle_nm').setValue(data.data.rle_nm);
      this.RolesForm.get('rle_dscn_nm').setValue(data.data.rle_dscrn_tx);
      this.updateData = data.data;
    } else if (opt == 'Delete') {
 
    }
    else if(opt == 'new'){
      this.checkid='';
      this.permismdl = false;
      this.shwSidebar = true;
      this.editClicked = false;
      this.deleteRle = false;
      this.sidebarHeading = "Add New Role";
      this.RolesForm.get('rle_nm').setValue('');
      this.RolesForm.get('rle_dscn_nm').setValue('');
      this.updateData = data.data;
    }
    else if (opt == 'Edit Permissions') {
      this.getRolePerms(data.data.rle_id)
      this.shwSidebar = true;
      this.permismdl = true;
      this.sidebarHeading = "Edit Permissions";
    }
 
  }
  getRolePerms(rleid) {
    console.log(rleid)
    const rte = `auth2/admin/Permissions/${rleid}`;
    this.loader = true;
    this.apiSrv.get(rte).subscribe((res) => {
      this.loader = false;
      this.rlepermdta = res['data'];
      console.log(this.rlepermdta)
    })
  }
  
  closeSideBar(): void {
    console.log("in closed")
    this.shwSidebar = false;
  }
  createPermson() {
    this.finalData[0].standard = this.rlepermdta;
    const rte = `auth2/admin/Permissions`;
    this.loader = true;
    this.apiSrv.create(this.finalData, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.loader = false;
        this.message.success('Permissions added sucessfully', {
          nzDuration: 2000
        });
        this.closeSideBar();
        this.getRolesWithPerm();
      }
    }, (error) => {
    });

  }

}
