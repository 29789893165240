<div class="dashboard">
    <div class="row">Integration with CLAP user charges dashboard pending </div>
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="map1" class="shadowcard svmdbrdgrph"> </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
            <div nz-row class="dashboard-count-card" style="margin-bottom: 8px">
                <div nz-col [nzSpan]="6" class="attnBdrRght">
                    <p class="text-center1 font-size-12 fuse-black-40-fg mt-0 mb-0">WARD</p>
                    <p class="text-center2 font-size-20 fuse-black-30-fg mt-0 mb-0">
                        {{admnCts.wrd_ct || 0}}</p>
                </div>
                <div nz-col [nzSpan]="6" class="attnBdrRght">
                    <p class="text-center1 font-size-12 fuse-black-40-fg mt-0 mb-0">SACHIVALAYAM</p>
                    <p class="text-center2 font-size-20 fuse-black-30-fg mt-0 mb-0">
                        {{admnCts.svm_ct || 0}}</p>
                </div>
                <div nz-col [nzSpan]="6" class="attnBdrRght">
                    <p class="text-center1 font-size-12 fuse-black-40-fg mt-0 mb-0">MICROPOCKETS</p>
                    <p class="text-center2 font-size-20 fuse-black-30-fg mt-0 mb-0">
                        {{admnCts.mcrpt_ct || 0}}</p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center1 font-size-12 fuse-black-40-fg mt-0 mb-0">VOLUNTEERS</p>
                    <p class="text-center2 font-size-20 fuse-black-30-fg mt-0 mb-0">
                        {{admnCts.tu_ct || 0}}</p>
                </div>
            </div>
            <div class="cardTitle">TODAY COLLECTION VS TOTAL ARRIEARS</div>
            <div nz-row class="dashboard-count-card" style="margin-bottom: 8px">
                <div nz-col [nzSpan]="6" class="attnBdrRght">
                    <p class="text-center1 font-size-12 fuse-black-40-fg mt-0 mb-0">TODAY COLLECTION</p>
                    <p class="text-center2 font-size-20 fuse-black-30-fg mt-0 mb-0">
                        &#8377;{{invceSmryCts.pd_this_dt || 0}}</p>
                    <p class="text-center font-size-10 fuse-black-50-fg mt-0 mb-0">from <span class="font-size-12"
                            style="font-weight: 600;">{{invceSmryCts.tdy_pd_enty_ct}}</span> Entities</p>
                </div>
                <div nz-col [nzSpan]="6" class="attnBdrRght">
                    <p class="text-center1 font-size-12 fuse-black-40-fg mt-0 mb-0">THIS MONTH COLLECTION</p>
                    <p class="text-center2 font-size-20 fuse-black-30-fg mt-0 mb-0">
                        &#8377;{{invceSmryCts.pd_this_mm || 0}}</p>
                    <p class="text-center font-size-10 fuse-black-50-fg mt-0 mb-0">from <span class="font-size-12"
                            style="font-weight: 600;">{{invceSmryCts.mm_pd_enty_ct}}</span> Entities</p>
                </div>
                <div nz-col [nzSpan]="6" class="attnBdrRght">
                    <p class="text-center1 font-size-12 fuse-black-40-fg mt-0 mb-0">THIS MONTH DEMAND</p>
                    <p class="text-center2 font-size-20 fuse-black-30-fg mt-0 mb-0">
                        &#8377;{{invceSmryCts.dmd_this_mm || 0}}</p>
                </div>
                <div nz-col [nzSpan]="6">
                    <p class="text-center1 font-size-12 fuse-black-40-fg mt-0 mb-0">TOTAL ARRIEARS</p>
                    <p class="text-center2 font-size-20 fuse-black-30-fg mt-0 mb-0">
                        &#8377;{{invceSmryCts.tl_unpd_at || 0}}</p>
                </div>
            </div>
            <table>
                <tr>
                    <th rowspan="2" style="width: 7%;">S.no</th>
                    <th rowspan="2" style="width: 13%;">Category</th>
                    <th colspan="3" scope="colgroup">Demand Entries</th>
                    <th colspan="3" scope="colgroup">Amount</th>
                </tr>
                <tr>
                    <th scope="col" style="width: 13%;">Total</th>
                    <th scope="col" style="width: 14%;">Collected</th>
                    <th scope="col" style="width: 13%;">Pending</th>
                    <th scope="col" style="width: 13%;">Total</th>
                    <th scope="col" style="width: 14%;">Collected</th>
                    <th scope="col" style="width: 13%;">Pending</th>
                </tr>
                <tr *ngFor="let d of cardData;index as i">
                    <td>{{i+1}}</td>
                    <td>{{d.gte_ctgry_nm}}</td>
                    <td>{{d.tl_ct}}</td>
                    <td>{{d.pd_ct}}</td>
                    <td>{{d.nt_pd_ct}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{d.tl_at}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{d.tl_pd_at}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{d.tl_un_pd_at}}</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Total:</td>
                    <td>{{sumtl_ct}}</td>
                    <td>{{sumpd_ct}}</td>
                    <td>{{sumnt_pd_ct}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{sumtl_at}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{sumtl_pd_at}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{sumtl_un_pd_at}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row" style="margin-bottom: 10px;margin-top: 10px;">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <nz-card nzTitle="Not Collected ({{notClctd.length}})" class="gtsgrph">
                <div class="row gridTop  verScroll" style="height: 345px;" id="target">
                    <div style="width:100%;margin-right: 2px;">
                        <dx-data-grid id="gridContainer" [dataSource]="notClctd" [remoteOperations]="false"
                            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%">
                            <dxo-paging [pageSize]="5"></dxo-paging>
                            <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                                [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                            </dxo-pager>
                            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                            </dxo-search-panel>
                            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                            <dxo-group-panel [visible]="true"></dxo-group-panel>
                            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                            <dxo-column-chooser [enabled]="true" mode="select">
                            </dxo-column-chooser>
                            <!-- <dxo-scrolling mode="virtual"></dxo-scrolling> -->
                            <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                            <dxo-export [enabled]="true" fileName="Sachivalayam Data"></dxo-export>
                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                            <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [caption]="d.headerName"
                                [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                            </dxi-column>
                        </dx-data-grid>
                    </div>

                </div>
            </nz-card>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
            <nz-card nzTitle="Last One Month Collection" class="gtsgrph">
                <div style="display: block;width:100%;height:345px">
                    <div id="gatesChart"></div>

                </div>
            </nz-card>
        </div>
    </div>
    <div>
        <div class="dashboard-card">
            <div class="row border-bottom">
                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="card-heading p-8">Street Wise Houses Coverage on <span
                            style="color: #321da4;">{{mnthName}}</span></div>
                            <div class="row">Click on the &nbsp;<i nz-icon nzType="home" class="bg-residential" nzTheme="outline"></i> &nbsp;to get the collection details </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12">
                    <div class="row  p-8">
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <div style="padding: 6px;margin: 2px;" class="house-paid block mt-08prcnt"></div>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Gates Collected</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <div style="padding: 6px;margin: 2px;" class="house-not-paid block mt-08prcnt"></div>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Gates Not Collected</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <!-- <div class="bg-bulk-comrl block mt-08prcnt"></div> -->
                                <i nz-icon nzType="shop" nzTheme="outline" class="bg-bulk-comrl"
                                    style="padding: 6px;margin: 2px;height: 27px;"></i>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Commerical</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <!-- <div class="bg-apartment block mt-08prcnt"></div> -->
                                <i nz-icon nzType="database" nzTheme="outline" class="bg-apartment"
                                    style="padding: 6px;margin: 2px;height: 27px;"></i>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Apartment</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <!-- <div class="bg-residential block mt-08prcnt"></div> -->
                                <i nz-icon nzType="home" nzTheme="fill" class="bg-residential"
                                    style="padding: 6px;margin: 2px;height: 27px;"></i>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Houses in Slum</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="row setRow">
                                <!-- <div class="bg-cmrl-res block mt-08prcnt"></div> -->
                                <i nz-icon nzType="home" nzTheme="outline" class="bg-cmrl-res"
                                    style="padding: 6px;margin: 2px;height: 27px;"></i>
                                <div class="house-indicator-div mt-08prcnt">
                                    <div>Residential Household (Non-Slum)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <!-- <div id="monthbox"></div> -->
            </div>
            <div>
                <div class="row border-bottom" *ngFor="let c of gateWiseClctnData">
                    <div class="col-lg-3 col-md-3 col-12 street-name">
                        {{c.clstr_nm}}
                    </div>
                    <div class="col-lg-9 col-md-9 col-12 street-house-padding">
                        <div class="row">
                            <div *ngFor="let h of c.gates">
                                <i nz-icon nzType="home" nzTheme="outline"
                                    *ngIf="h.gte_ctgry_nm === 'Residential Household (Non-Slum)'"
                                    [ngClass]="{'color-green':h.pd_in === 1, 'color-red':h.pd_in === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential Household (Non-Slum)',
                                                       'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Houses in Slum','bg-bulk-comrl': h.gte_ctgry_nm ===  'Commercial'}"
                                    style="padding: 6px;margin: 2px;" (click)="open(h)"></i>
                                <i nz-icon nzType="database" nzTheme="outline" *ngIf="h.gte_ctgry_nm === 'Apartment'"
                                    [ngClass]="{'color-green':h.pd_in === 1, 'color-red':h.pd_in === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential Household (Non-Slum)',
                                                       'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Houses in Slum','bg-bulk-comrl': h.gte_ctgry_nm ===  'Commercial'}"
                                    style="padding: 6px;margin: 2px;" (click)="open(h)"></i>
                                <i nz-icon nzType="home" nzTheme="fill" *ngIf="h.gte_ctgry_nm === 'Houses in Slum'"
                                    [ngClass]="{'color-green':h.pd_in === 1, 'color-red':h.pd_in === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential Household (Non-Slum)',
                                                       'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Houses in Slum','bg-bulk-comrl': h.gte_ctgry_nm ===  'Commercial'}"
                                    style="padding: 6px;margin: 2px;" (click)="open(h)"></i>
                                <i nz-icon nzType="shop" nzTheme="outline" *ngIf="h.gte_ctgry_nm ===  'Commercial'"
                                    [ngClass]="{'color-green':h.pd_in === 1, 'color-red':h.pd_in === 0, 'bg-residential': h.gte_ctgry_nm === 'Residential Household (Non-Slum)',
                                                       'bg-apartment': h.gte_ctgry_nm === 'Apartment','bg-cmrl-res': h.gte_ctgry_nm === 'Houses in Slum','bg-bulk-comrl': h.gte_ctgry_nm ===  'Commercial'}"
                                    style="padding: 6px;margin: 2px;" (click)="open(h)"></i>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #drawerTemplate let-data let-drawerRef="drawerRef">
    value: {{ data?.value }}
    <br />
    <button nz-button nzType="primary" (click)="drawerRef.close()">close</button>
</ng-template>
<nz-drawer [nzClosable]="true" [nzWidth]="500" [nzVisible]="visible" nzTitle="House Info" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
    <nz-list>
        <nz-list-item nz-row style="background-color: #f6f1ff;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Customer Name</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{cstmr_nm}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Mobile Number</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{cstmrMbl}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: #f6f1ff;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Business Type</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{bsnsNm}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Door Number</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{gateNu}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: #f6f1ff;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Gate Category</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{gateCtgryNm}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Cluster Name</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{clstrNm}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: #f6f1ff;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Prevour Pending Amount</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">&#8377; {{prvceBlns}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: white;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Last Month Amount</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">&#8377; {{lstInvAmt}}</div>
        </nz-list-item>
        <nz-list-item nz-row style="background-color: #f6f1ff;color:black;">
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9">Collected Last Month</div>
            <div nz-col [nzSpan]="2">:</div>
            <div nz-col [nzSpan]="12">{{clctd}}</div>
        </nz-list-item>

        <nz-list-item nz-row>
            <div id="map"> </div>
        </nz-list-item>
    </nz-list>
    </ng-container>
</nz-drawer>