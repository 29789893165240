import { Component, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as moment from 'moment';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end
@Component({
  selector: 'app-mandal-dashboard',
  templateUrl: './mandal-dashboard.component.html',
  styleUrls: ['./mandal-dashboard.component.scss']
})
export class MandalDashboardComponent implements OnInit {
  chart: am4charts.XYChart;
  chart2: am4charts.XYChart;
  // chart3: am4charts.XYChart;
  // chart4: am4charts.XYChart;
  chart5: am4charts.XYChart;

  rtDvtdTripsDta: any;
  tripsTrvldDta: any;
  swprsWrkngTmDta: any;
  dateFormat = 'dd/MM/yyyy';
  monthFormat = 'yyyy/MM';

  rtDvtdTripscolumnDefs;
  tripsTrvldcolumnDefs;
  swprsWrkngTmcolumnDefs;
  // legend: am4charts.Legend;
  showLdr = false;
  tdyCoveredTrips: any;
  slctdDt = new Date();
  today = new Date();
  ttlDstncCnt: any;
  ttlTrpsCnt: any; prmryTrpsCnt: any; scndryTrpsCnt: any;
  curTblDate: string;
  ctgryWseCnts: any;
  curTmspDate: string;
  clntDtls: any;
  tntLst: any = [];
  selectedtenant;
  selectedtenantNm;
  curYr: any;
  shwzrwErr: boolean = false;
  shwScndryzrwErr: boolean = false;
  cnt_dtlscolumnDefs: any;
  cvrgDta: any;
  HsDta: any;
  postCntdata: any;
  cnt_dtls: any;
  target;
  trnsfrPnts; trnsfrStns; prcngPnts; mrfPnts;
  rdrctUlbDtls;
  visible = false;
  placement: NzDrawerPlacement = 'right';
  userDtls: any;
  clap_in: any;
  ShowClap: boolean = false;
  VndrwseopratedvehclesTble: any;
  VndrwseopratedvehclesTblecolumnDefs: any;
  dbrd_ky = 'ulb';
  vehclesTble: any = [];
  vehclesTblecolumnDefs: any = [];
  ulb_id;
  nodata:boolean;
  nodataMsg: string;
  urban_in;
  hdrLbl: string;
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.rdrctUlbDtls = [];
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.rdrctUlbDtls = this.TransfereService.getLoclData('ulbDta');
    console.log(this.rdrctUlbDtls);
    this.clntDtls[0].tnts.forEach(element => {
      if(element.urban_in==0){
        this.tntLst.push(element);
      }
      
    });
    console.log(this.tntLst)
    if (this.rdrctUlbDtls) {
      this.ontntSelect(this.rdrctUlbDtls.tnt_id);
    } else {
      this.selectedtenant = this.tntLst[0].tnt_id;
      this.selectedtenantNm = this.tntLst[0].tnt_dsply_nm;
      this.ulb_id = this.tntLst[0].cpy_ulb_id;
      this.urban_in=this.tntLst[0].urban_in;
      console.log(this.selectedtenant, this.selectedtenantNm);
    }
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
    if(this.urban_in==1){
      this.hdrLbl='ULB';
    }else{
      this.hdrLbl='Mandal';
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };

  selectedVehicle;
  close(): void {
    this.visible = false;
  }
  getVehDashSldr(evnt) {
    console.log(evnt)
    this.selectedVehicle = evnt.data;
    this.visible = true;

  }
  ontntSelect(value) {
    console.log(value);
    if (value == 0) {
      this.selectedtenant = 0;
      this.selectedtenantNm = "All ULB's Selected";
      this.ulb_id = 0;
    }
    for (let i = 0; i < this.tntLst.length; i++) {
      console.log('in');
      if (this.tntLst[i].tnt_id == value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
        this.selectedtenantNm = this.tntLst[i].tnt_dsply_nm;
        this.ulb_id = this.tntLst[i].cpy_ulb_id;
        this.urban_in=this.tntLst[i].urban_in;
        if(this.urban_in==1){
          this.hdrLbl='ULB';
        }else{
          this.hdrLbl='Mandal';
        }
      }
    }

    console.log(this.selectedtenantNm);
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, this.selectedtenant);
    this.get_totalDistanceCount(this.slctdDt, this.selectedtenant);
    this.get_totalTripsCount(this.slctdDt, this.selectedtenant);
    this.get_prmryTripsCount(this.slctdDt, this.selectedtenant);
    this.get_scndryTripsCount(this.slctdDt, this.selectedtenant);
    this.get_lst7DaysDistanceDta(this.slctdDt, this.selectedtenant);
    this.get_lst7DaysTrpsDta(this.slctdDt, this.selectedtenant);
    this.get_lst10Days_ntOprdTrpsDta(this.slctdDt, this.selectedtenant);
    this.getVehicleswiseData(this.slctdDt, this.selectedtenant);

  }
  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, this.selectedtenant);
    this.get_totalDistanceCount(this.slctdDt, this.selectedtenant);
    this.get_totalTripsCount(this.slctdDt, this.selectedtenant);
    this.get_prmryTripsCount(this.slctdDt, this.selectedtenant);
    this.get_scndryTripsCount(this.slctdDt, this.selectedtenant);
    this.get_lst7DaysDistanceDta(this.slctdDt, this.selectedtenant);
    this.get_lst7DaysTrpsDta(this.slctdDt, this.selectedtenant);
    this.get_lst10Days_ntOprdTrpsDta(this.slctdDt, this.selectedtenant);
    this.getVehicleswiseData(this.slctdDt, this.selectedtenant);

  }
  ngOnInit() {
    this.curYr = moment(this.today).format('YYYY');
    this.slctdDt = new Date();
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, this.selectedtenant);
    this.get_totalDistanceCount(this.slctdDt, this.selectedtenant);
    this.get_totalTripsCount(this.slctdDt, this.selectedtenant);
    this.get_prmryTripsCount(this.slctdDt, this.selectedtenant);
    this.get_scndryTripsCount(this.slctdDt, this.selectedtenant);
    this.get_lst7DaysDistanceDta(this.slctdDt, this.selectedtenant);
    this.get_lst7DaysTrpsDta(this.slctdDt, this.selectedtenant);
    this.get_lst10Days_ntOprdTrpsDta(this.slctdDt, this.selectedtenant);
  }

  curDate; postslctdDt;

  get_today_Ulb_CoveredTripsCount(funDt?, slctTnt?): any {
    this.tdyCoveredTrips = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/ulb_tdy/${this.dbrd_ky}`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.tdyCoveredTrips = res['data'];
          // console.log(this.tdyCoveredTrips)
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }

  get_totalDistanceCount(funDt?, slctTnt?): any {
    this.ttlDstncCnt = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
    const rte = `dashboard/enterprise/get_ttlDstnce/${this.dbrd_ky}`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.ttlDstncCnt = res['data'];
          // console.log(this.ttlDstncCnt)
        }
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }


  get_totalTripsCount(funDt?, slctTnt?): any {
    this.ttlTrpsCnt = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_totalTrips/${this.dbrd_ky}`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      var res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.ttlTrpsCnt = res['data'];
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }

  get_prmryTripsCount(funDt?, slctTnt?): any {
    this.prmryTrpsCnt = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_prmryTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      var res_data = res['data'];
      // console.log(res_data);
      if (res_data && res_data.length > 0) {
        this.prmryTrpsCnt = res['data'];
        console.log(this.prmryTrpsCnt);
        this.shwzrwErr = false;
      }
      else {
        this.shwzrwErr = true;
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }

  get_scndryTripsCount(funDt?, slctTnt?): any {
    this.scndryTrpsCnt = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_scndryTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      var res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.scndryTrpsCnt = res['data'];
        console.log(this.scndryTrpsCnt);
        this.shwScndryzrwErr = false;
      }
      else {
        this.shwScndryzrwErr = true;
      }
      this.showLdr = false;

    }, (err) => {
      // console.log(err);
    });
  }
  // lst7DayDstnc;
  get_lst7DaysDistanceDta(funDt?, slctTnt?): any {
    let chart = am4core.create("distanceTavelledChart", am4charts.XYChart);
    chart.data = [];
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_lst7DaysDistance`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      chart.data = res['data'];
      // this.lst7DayDstnc = this.chart.data;
      // console.log(chart.data);

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "dt";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = ` ----- Date(${this.curYr})----- `;
      // categoryAxis.renderer.labels.template.rotation = -45;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = " ----- Operated % ----- ";

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "opper";
      series.dataFields.categoryX = "dt";
      series.name = "Date";
      series.columns.template.tooltipText = "Distance travelled in {categoryX}: [bold]{valueY}[/]";
      series.columns.template.fillOpacity = .8;
      series.columns.template.fill = am4core.color("#afb42b");
      series.columns.template.stroke = am4core.color("#d4e157");
      let columnTemplate1 = series.columns.template;
      columnTemplate1.strokeWidth = 1;
      // columnTemplate1.strokeOpacity = 1;
      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{opper}";
      valueLabel.label.fontSize = 15;

      // columnTemplate1.width = 60;
      columnTemplate1.column.cornerRadiusTopLeft = 5;
      columnTemplate1.column.cornerRadiusTopRight = 5;
      columnTemplate1.strokeOpacity = 1;
    });
  }
  // lst7DayTrps;
  get_lst7DaysTrpsDta(funDt?, slctTnt?): any {
    // this.lst7DayTrps = [];
    let chart2 = am4core.create('ctgrytripsTavelledChart', am4charts.XYChart);
    let chart = am4core.create('chartdiv', am4charts.XYChart);
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_lst7DaysTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      chart2.data = res['data'].results;
      // this.heatChrt = res['data'];
      console.log(chart2.data);
      if (res['data'].msg == 0) {
        this.nodata=true;
        this.nodataMsg = 'No trips defined for the vehicle(s)';
      } else {
        this.nodata=false;
        this.nodataMsg = '';
      }
      let categoryAxis2 = chart2.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis2.dataFields.category = 'dt';
      categoryAxis2.renderer.grid.template.location = 0;
      categoryAxis2.renderer.minGridDistance = 10;
      categoryAxis2.title.text = ` ----- Date (${this.curYr}) ----- `;
      // categoryAxis2.renderer.labels.template.rotation = -45;

      let valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.renderer.inside = true;
      // valueAxis2.renderer.labels.template.disabled = true;
      // valueAxis2.min = 0;
      valueAxis2.title.text = ' -----  Trips % ----- ';

      // Create series
      function createSeries(field, name) {

        // Set up series
        let series2 = chart2.series.push(new am4charts.ColumnSeries());
        series2.name = name;
        series2.dataFields.valueY = field;
        series2.dataFields.categoryX = 'dt';
        series2.sequencedInterpolation = true;

        // Make it stacked
        series2.stacked = true;

        // Configure columns
        series2.columns.template.width = am4core.percent(60);
        series2.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
        // Add label
        let labelBullet = series2.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = '{valueY}';
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;

        return series2;

      }

      // createSeries('tot_trps', 'Total Trips');
      // createSeries('prmry_trps', 'Primary');
      // createSeries('scndry_trps', 'Secondary');
      // createSeries('othr_trps', 'Others');
      createSeries('trvl_per', 'Travelled %');
      // createSeries('fogAts_trps', 'Fog-Autos');
      // createSeries('swpngMchns_trps', 'Sweeping Machines');
      // Legend
      // chart2.legend = new am4charts.Legend();
    });
  }
  // lst10DaystrpsDta;
  get_lst10Days_ntOprdTrpsDta(funDt?, slctTnt?): any {
    // this.lst10DaystrpsDta = [];
    let chart5 = am4core.create("lst10DysNtoprdTrpsChart", am4charts.XYChart);
    chart5.data = []
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_lst10DaysNtOprdTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      chart5.data = res['data'];
      // console.log(chart5.data);

      let categoryAxis5 = chart5.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis5.dataFields.category = "dt";
      categoryAxis5.renderer.grid.template.location = 0;
      categoryAxis5.renderer.minGridDistance = 30;
      categoryAxis5.title.text = ` ----- Date(${this.curYr})----- `;
      // categoryAxis5.renderer.labels.template.rotation = -45;

      let valueAxis5 = chart5.yAxes.push(new am4charts.ValueAxis());
      valueAxis5.title.text = " ----- Trips ----- ";

      // Create series
      let series5 = chart5.series.push(new am4charts.ColumnSeries());
      series5.dataFields.valueY = "trps";
      series5.dataFields.categoryX = "dt";
      series5.name = "Category";
      series5.columns.template.tooltipText = "Not-operated in {categoryX}: [bold]{valueY}[/]";
      series5.columns.template.fillOpacity = .8;
      series5.columns.template.fill = am4core.color("#a573f2");
      series5.columns.template.stroke = am4core.color("#c7adefb0");
      let columnTemplate5 = series5.columns.template;
      columnTemplate5.strokeWidth = 1;
      let valueLabel = series5.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{trps}";
      valueLabel.label.fontSize = 15;

      // columnTemplate5.width = 60;
      columnTemplate5.column.cornerRadiusTopLeft = 5;
      columnTemplate5.column.cornerRadiusTopRight = 5;
      columnTemplate5.strokeOpacity = 1;

    });
  }


  // Add data

  gotoPlaybck(event) {
    this.TransfereService.setLoclData('plybckDta', event.row.data)
    this.route.navigate([`internal/play-back/list`]);
  }
  onCellPrepared(colDef, e) {

    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'Vehicle No') {
      e.cellElement.style.color = '#ff0000';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
      e.cellElement.style.background = 'rgba(243, 191, 176, 0.2784313725490196)';
      e.cellElement.style.backgroundClip = 'content-box';
      e.cellElement.style.cursor = 'pointer';
    }

  }

 

  getVehicleswiseData(funDt?, slctTnt?) {
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/getVehicleSts`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.vehclesTble = res['data'];
          this.vehclesTblecolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
            { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 140 },
            { headerName: 'Mandal', field: 'tnt_nm', alignment: 'left', width: 140 },
            { headerName: 'Village', field: 'asrt_grp_nm', alignment: 'left', width: 140 },
            { headerName: 'Vehicle Number', field: 'asrt_nm', alignment: 'left', width: 200 },
            { headerName: 'Vendor', field: 'vndr_nm', alignment: 'left', width: 200 },
            { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', width: 200 },
            { headerName: 'Sachivalayam Code', field: 'svm_apfs_cd', alignment: 'left',},
            { headerName: 'Driver Name', field: 'crw_nm', alignment: 'left',  },
            { headerName: 'Driver Contact', field: 'crw_ph', alignment: 'left',},
            // { headerName: 'Sanitary Secratory', field: 'ss_nm', alignment: 'left', width: 'auto' },
            // { headerName: 'Sanitary Secratory Contact', field: 'ss_cntct', alignment: 'left', width: 'auto' },
            // { headerName: 'Assigned Trips', field: 'asgnd_trps', alignment: 'center' },
            // { headerName: 'Today Travelled Trips', field: 'tdy_trvl_trps', alignment: 'center' },
            { headerName: 'Today Travelled Distance in Kms', field: 'tdy_trvl_dst', alignment: 'center' },
          ]
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
}