import { Component, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { AppsService } from 'src/app/shared/services/apps.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { Router } from '@angular/router';

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);
// Themes end
@Component({
  selector: 'app-desludging-dashboard',
  templateUrl: './desludging-dashboard.component.html',
  styleUrls: ['./desludging-dashboard.component.scss']
})
export class DesludgingDashboardComponent implements OnInit {
  ulbwseDsldgngTripsDta: any;
  chart: am4charts.XYChart;
  chart2: am4charts.XYChart;
  chart3: am4charts.XYChart;
  showLdr =  false;
  tdyCoveredTrips : any;
  ulbwseDsldgngTripscolumnDefs;
  ttlDstncCnt: any;
  ttlTrpsCnt: any;
  dateFormat = 'yyyy/MM/dd';
  curTblDate: string;
  slctdDt = new Date();curDate;postslctdDt;
  clntDtls: any;
  today = new Date();

  constructor(public apiServ: AppsService,public TransfereService: TransfereService,public route: Router) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
  }

  ngOnInit() {
    this.slctdDt=new Date();
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.get_today_CoveredTripsCount();
    this.get_totalDistanceCount();
    this.get_totalTripsCount();
    this.get_ulbWiseCounts(this.slctdDt);
    this.get_lst7DaysDistanceDta();
    this.get_lst7DaysTrpsDta();
    this.chart3 = am4core.create("chartdiv", am4charts.XYChart);

    // Create axes




    // Heat Map -----------------------------------------------------------------------------



    this.chart3.maskBullets = false;

    let xAxis = this.chart3.xAxes.push(new am4charts.CategoryAxis());
    let yAxis = this.chart3.yAxes.push(new am4charts.CategoryAxis());

    xAxis.dataFields.category = "cnt";
    yAxis.dataFields.category = "veh_typ";

    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 40;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 30;

    let series5 = this.chart3.series.push(new am4charts.ColumnSeries());
    series5.dataFields.categoryX = "cnt";
    series5.dataFields.categoryY = "veh_typ";
    series5.dataFields.value = "cvrd";
    series5.sequencedInterpolation = true;
    series5.defaultState.transitionDuration = 3000;

    let bgColor = new am4core.InterfaceColorSet().getFor("background");

    let columnTemplate = series5.columns.template;
    columnTemplate.strokeWidth = 1;
    columnTemplate.strokeOpacity = 0.2;
    columnTemplate.stroke = bgColor;
    columnTemplate.tooltipText = "{cnt}, {veh_typ}: {cvrd.workingValue.formatNumber('#.')}";
    columnTemplate.width = am4core.percent(100);
    columnTemplate.height = am4core.percent(100);

    series5.heatRules.push({
      target: columnTemplate,
      property: "fill",
      min: am4core.color(bgColor),
      max: this.chart3.colors.getIndex(0)
    });

    // heat legend
    let heatLegend = this.chart3.bottomAxesContainer.createChild(am4charts.HeatLegend);
    heatLegend.width = am4core.percent(100);
    heatLegend.series = series5;
    heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
    heatLegend.valueAxis.renderer.minGridDistance = 30;

    // heat legend behavior
    series5.columns.template.events.on("over", function (event) {
      handleHover(event.target);
    })

    series5.columns.template.events.on("hit", function (event) {
      handleHover(event.target);
    })

    function handleHover(column) {
      if (!isNaN(column.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
      }
      else {
        heatLegend.valueAxis.hideTooltip();
      }
    }

    series5.columns.template.events.on("out", function (event) {
      heatLegend.valueAxis.hideTooltip();
    })

    this.chart3.data = [
      {
        "veh_typ": "04-06-2021",
        "cnt": '',
        "cvrd": 200
      },
      {
        "veh_typ": "03-06-2021",
        "cnt": '',
        "cvrd": 500
      },
      {
        "veh_typ": "02-06-2021",
        "cnt": '',
        "cvrd": 700
      },
      {
        "veh_typ": "01-06-2021",
        "cnt": '',
        "cvrd": 350
      }
    ];

    // Heat Map End -----------------------------------------------------------------------------





  }

  onChange(e): void {
    console.log('onChange: ',e);
    this.slctdDt = e;
    console.log('slctdDt: ', this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    console.log(this.curDate);
    this.get_today_CoveredTripsCount(this.slctdDt);
    this.get_totalDistanceCount(this.slctdDt);
    this.get_totalTripsCount(this.slctdDt);
    this.get_lst7DaysDistanceDta(this.slctdDt);
    this.get_lst7DaysTrpsDta(this.slctdDt);
  }
  
  get_today_CoveredTripsCount(funDt?): any {
    this.tdyCoveredTrips = []
    this.showLdr = true;
    this.slctdDt=new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate
    }
    let rte = `dashboard/enterprise/get_today_CoveredTripsCount`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.tdyCoveredTrips = res['data'];
          console.log(this.tdyCoveredTrips)
        }
        this.showLdr = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  get_totalDistanceCount(funDt?): any {
    this.ttlDstncCnt = []
    this.showLdr = true;
    this.slctdDt=new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate
    }
    let rte = `dashboard/enterprise/get_totalDistance`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      this.showLdr = false;
        this.ttlDstncCnt = res['data'];
        console.log(this.ttlDstncCnt)
      }, (err) => {
        console.log(err);
      });
  }

  get_totalTripsCount(funDt?): any {
    this.ttlTrpsCnt = []
    this.showLdr = true;
    this.slctdDt=new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate
    }
    let rte = `dashboard/enterprise/get_totalTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      this.showLdr = false;
        this.ttlTrpsCnt = res['data'];
        console.log(this.ttlTrpsCnt);
      }, (err) => {
        console.log(err);
      });
  }

  get_ulbWiseCounts(funDt?): any {
    this.ulbwseDsldgngTripsDta = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in : 0
    }
    const rte = `dashboard/enterprise/get_ulbwiseDta`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        let res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.ulbwseDsldgngTripsDta = res['data'];
          console.log(this.ulbwseDsldgngTripsDta);
          this.ulbwseDsldgngTripscolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false,cellClass: 'pm-grid-number-cell', search: false },
            { headerName: 'Date', field: 'frmtd_dt', alignment: 'center', width: 130, filter: false,cellClass: 'pm-grid-number-cell', search: false },
            { headerName: 'ULB Name', alignment: 'left', width: 250, field: 'tnt_nm',cellClass: 'pm-grid-number-cell' },
            { headerName: 'Total Vehicles', alignment: 'center', width: 150, field: 'asrt_cnt',cellClass: 'pm-grid-number-cell' },
            { headerName: 'Total Trips', alignment: 'center', width: 150, field: 'totl',cellClass: 'pm-grid-number-cell' },
            { headerName: 'Operated Trips', field: 'oprtd', width: 150, alignment: 'center',cellClass: 'pm-grid-number-cell' },
            { headerName: 'Yet to start', field: 'yet_to_strt', width: 150, alignment: 'center',cellClass: 'pm-grid-number-cell' },
            { headerName: 'Not-Operated Trips', field: 'nt_oprtd', width: 150, alignment: 'center',cellClass: 'pm-grid-number-cell' },
            { headerName: 'Travelled Distance', field: 'tvl_dstnc', width: 150, alignment: 'center',cellClass: 'pm-grid-number-cell' },
            { headerName: 'Travelled Hours', field: 'trvl_hr', width: 150, alignment: 'center',cellClass: 'pm-grid-number-cell' },
            { headerName: 'Route Deviated', field: 'rd_cnt', width: 150, alignment: 'center',cellClass: 'pm-grid-number-cell' }
          ]
        }
        this.showLdr = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  get_lst7DaysDistanceDta(funDt?): any {
    let chart = am4core.create("distanceTavelledChart", am4charts.XYChart);
    chart.data  = []
    this.slctdDt=new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate
    }
    let rte = `dashboard/enterprise/get_lst7DaysDistance`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
       chart.data = res['data'];
      console.log(chart.data);
      let categoryAxis =  chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "dt";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = " ----- Date ----- ";
  
      let valueAxis =  chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = " ----- Distance ----- ";
  
      // Create series
      let series =  chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "dstnc";
      series.dataFields.categoryX = "dt";
      series.name = "Date";
      series.columns.template.tooltipText = "Distance travelled in {categoryX}: [bold]{valueY}[/]";
      series.columns.template.fillOpacity = .8;
      series.columns.template.fill = am4core.color("#f4a9be");
      series.columns.template.stroke = am4core.color("#f4a9be");
      let columnTemplate1 = series.columns.template;
      columnTemplate1.strokeWidth = 2;
      columnTemplate1.strokeOpacity = 1;
    
    });
  }
  
  get_lst7DaysTrpsDta(funDt?): any {
    let chart2 = am4core.create("tripsTavelledChart", am4charts.XYChart);
    chart2.data  = []

    this.slctdDt=new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate
    }
    let rte = `dashboard/enterprise/get_lst7DaysTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
    chart2.data = res['data'];
    console.log(chart2.data);
    let categoryAxis2 = chart2.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis2.dataFields.category = "dt";
    categoryAxis2.renderer.grid.template.location = 0;
    categoryAxis2.renderer.minGridDistance = 30;
    categoryAxis2.title.text = " ----- Date ----- ";

    let valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = " ----- Trips ----- ";

    // Create series
    let series2 = chart2.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "trps";
    series2.dataFields.categoryX = "dt";
    series2.name = "Date";
    series2.columns.template.tooltipText = "Trips travelled in {categoryX}: [bold]{valueY}[/]";
    series2.columns.template.fillOpacity = .8;
    series2.columns.template.fill = am4core.color("#a9dcf4");
    series2.columns.template.stroke = am4core.color("#a9dcf4");
    let columnTemplate2 = series2.columns.template;
    columnTemplate2.strokeWidth = 2;
    columnTemplate2.strokeOpacity = 1;
    
    });
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };


  gotoUlb(event) {
    console.log(event.row.data);
    this.TransfereService.setLoclData('ulbDta', event.row.data)
    this.route.navigate([`internal/dashboard/ulb-dashboard`]);
  }
  onCellPrepared(colDef, e) {
      
    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'ULB Name') {
      e.cellElement.style.color = '#ff0000';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
       e.cellElement.style.background = 'rgba(243, 191, 176, 0.2784313725490196)';
       e.cellElement.style.backgroundClip = 'content-box';
       e.cellElement.style.cursor = 'pointer';
    }
  
  }
}
