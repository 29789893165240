<div class="page-header">
    <div class=" align-items-md-center">


        <div class="row hdrBar">
            <div class="col-lg-6 col-md-6">
                <h1>
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
            <!-- <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;" >
                                <span class="slctDteLbl">Select Date:</span>
                                <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                                    (ngModelChange)="onChange($event)" class="dte_picker"></nz-date-picker>
                
                
                            </div> -->
            <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;">
                <span class="slctDteLbl">Select District:</span>

                <nz-select class="slctBx" style="width: 200px;    text-align: left;" nzShowSearch
                    [(ngModel)]="slctd_dstrt" (ngModelChange)="getDstrwiseData($event)"
                    [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select a district">

                    <nz-option *ngFor="let v of dstrtdata" [nzValue]="v.tnt_id" [nzLabel]="v.tnt_nm"></nz-option>
                </nz-select>

                <span class="stdsbrdBtn" (click)="gotoDstrctDshbrd()"> Go to State Dashboard</span>

            </div>
        </div>
    </div>
</div>

<div class="apsfl_dshbrd">



    <app-oms-dashbourd-cards [slctd_dstrt]="slctd_dstrt" [slctCrd]="slctdCrd"
        (slctdata)="selctedCatrd($event)"></app-oms-dashbourd-cards>



    <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;">
        <div class="col-lg-12 col-md-12 mgnb-2 p-8 ">
            <dx-data-grid class="p-8" id="gridContainer" [dataSource]="gridData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%" (onContentReady)="onContentReady($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                        </dxo-pager> -->
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                <dxo-column-chooser [enabled]="false" mode="select">
                </dxo-column-chooser>
                <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                <dxo-export [enabled]="true" fileName=""></dxo-export>
                <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
                <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [visible]="d.visible"
                    [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                    [groupIndex]="d.groupIndex" [format]="d.format">
                </dxi-column>
                <!-- <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoWorkProfile(data)">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>View Profile</p>
                        </div>
                    </div>
                </dxi-column> -->
                <dxi-column *ngIf="tb_id=='1'"  [showInColumnChooser]="true" [visible]="true" type="buttons" [showWhenGrouped]="false"
                    width="100" caption="View" cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div  class="tblEdtBtn" (click)="gotoProfile(data,'work')">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>View Profile</p>
                        </div>
                    </div>
                </dxi-column>
                <dxo-summary>
                    <dxi-total-item column="cstmr_efctd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                    </dxi-total-item>
                    <dxi-total-item column="no_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                    </dxi-total-item>
                    <dxi-total-item column="asgn_emps" summaryType="sum" [displayFormat]="SumINRFormater">
                    </dxi-total-item>

                    <dxi-total-item column="wrk_incld_expns" summaryType="sum" [displayFormat]="SumINRFormater">
                    </dxi-total-item>
                    <dxi-total-item column="amt" summaryType="sum" [displayFormat]="sumcurrencyINRFormater">
                    </dxi-total-item>
                    <dxi-total-item column="ttl" summaryType="sum" [displayFormat]="SumINRFormater">
                    </dxi-total-item>
                    <dxi-total-item column="inprg" summaryType="sum" [displayFormat]="SumINRFormater">
                    </dxi-total-item>
                    <dxi-total-item column="cmpltd" summaryType="sum" [displayFormat]="SumINRFormater">
                    </dxi-total-item>


                    <dxi-group-item column="cstmr_efctd_ct" summaryType="sum" alignByColumn="true">
                    </dxi-group-item>
                    <dxi-group-item column="asgn_emps" summaryType="sum" alignByColumn="true">
                    </dxi-group-item>
                </dxo-summary>
            </dx-data-grid>
        </div>




    </div>

    <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;" *ngIf="tb_id=='1'">
        <div class="col-lg-12 col-md-12 mgnb-2 p-8 ">
            <dx-data-grid class="p-8" id="gridContainer" [dataSource]="EmployData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                        </dxo-pager> -->
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                <dxo-column-chooser [enabled]="false" mode="select">
                </dxo-column-chooser>
                <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                <dxo-export [enabled]="true" fileName=""></dxo-export>
                <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
                <dxi-column *ngFor="let d of columnEmplyDefs" [dataField]="d.field" [visible]="d.visible"
                    [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                    [groupIndex]="d.groupIndex">
                </dxi-column>
                <dxi-column *ngIf="tb_id=='1'" [showInColumnChooser]="false" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn"  (click)="gotoProfile(data,'employ')">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>View Profile</p>
                        </div>
                    </div>
                </dxi-column>

            </dx-data-grid>
        </div>




    </div>




    <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="1500" [nzVisible]="sidenav"
        [nzTitle]="drwerTitle" (nzOnClose)="closeSideBar()" nzWrapClassName = "wrkPrfleDrawer">
        <ng-container *nzDrawerContent>
            <div *ngIf="appCond==1">
    <app-wrok-profile [wrkData]="wrkData"></app-wrok-profile>
            </div>
        

            <div class="apsfl_dshbrd" *ngIf="appCond==2">
            <app-emplye-prfle [wrkData]="wrkData"></app-emplye-prfle>
            </div>
           
        </ng-container>
    </nz-drawer>
</div>