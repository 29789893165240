import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-wards',
  templateUrl: './wards.html',
  styleUrls: ['./wards.scss']
})
export class WardsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  wrdLst: any;
  columnDefs;
  shwSidebar = false;
  wrdNm: string | null = null;
  shwWrdAlrt = false;
  shwWrdEditDiv = false;
  wrdId: any;
  sidebarHeading: string;
  shwWrdDelDiv: boolean;
  shwLdg: boolean;
  ulbs: any = [];
  wrdData: any = {};
  getuid: any;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    // this.getWrdsLst();
    this.getUlbs();
  }

  getUlbs() {
    const rte = 'admin/ulbs/list';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.ulbs = res['data'];
          this.getuid = this.ulbs[0].ulb_id;
          this.getWrdsLst();
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }

    });
  }
  filteredOptions: any = [];
  onChange(value): void {
    this.filteredOptions = this.ulbs.filter(option => option.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getULBID(id) {
    this.wrdData.ulb_id = id;
  }
  getWrdsLst() {
    this.shwLdg = true;
    const rte = `admin/wards/list/${this.getuid}`;
    // const rte = 'wards/list';
    this.apiSrv.get(rte).subscribe(res => {
      this.wrdLst = res['data'];
      this.shwLdg = false;
      this.columnDefs = [
        { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
        { headerName: 'ULB Name', field: 'ulb_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 170 },
        { headerName: 'Ward Name', field: 'wrd_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 265 },
        { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
        { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
        { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
        { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
      ];
    });
  }

  onCellClick(opt, edtData) {
    if (opt === 'edit') {
      this.shwWrdEditDiv = true;
      // this.wrdNm = edtData.data.wrd_nm;
      // this.wrdId = edtData.data.wrd_id;
      this.wrdData = edtData.data;
      this.getUlbs();
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwWrdEditDiv = false;
      this.shwWrdDelDiv = true;
      // this.wrdId = edtData.data.wrd_id;
      // this.wrdNm = edtData.data.wrd_nm;
      this.wrdData = edtData.data;
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Ward',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwWrdEditDiv = true;
      this.shwWrdDelDiv = false;
      this.sidebarHeading = 'Edit Ward';
    } else if (opt === 'delete') {
      this.shwWrdEditDiv = false;
      this.shwWrdDelDiv = true;
      this.sidebarHeading = 'Delete Ward';
    } else {
      this.shwWrdEditDiv = false;
      this.shwWrdDelDiv = false;
      // this.wrdNm = '';
      this.wrdData = {};
      this.sidebarHeading = 'Add New Ward';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }
  saveWrd() {
    // const wrdData = {
    //   name: this.wrdNm,
    // };

    const rte = 'admin/wards';
    this.apiSrv.create(this.wrdData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwWrdAlrt = true;
        this.createMessage('success', 'Ward Successfully Added');
        this.getWrdsLst();
      }
    });
  }

  saveEditWrd() {
    const wrdEdtData = {
      id: this.wrdData.wrd_id,
      name: this.wrdData.wrd_nm,
      ulb_id: this.wrdData.ulb_id
    };

    const rte = 'admin/wards/'+this.wrdData.wrd_id;
    this.apiSrv.update(wrdEdtData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwWrdAlrt = true;
        this.createMessage('info', 'Ward Successfully Updated');
        this.getWrdsLst();
      }
    });
  }

  saveDeleteWrd() {
    // console.log('in del');
    const wrdDelData = {
      id: this.wrdData.wrd_id,
      ulb_id: this.wrdData.ulb_id
    };

    const rte = 'admin/wards/'+this.wrdData.wrd_id;
    this.apiSrv.delete(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwWrdAlrt = true;
        this.createMessage('warning', 'Ward Successfully Deleted');
        this.getWrdsLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('Ward Not Deleted');
  }
}
