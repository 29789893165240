import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api.service';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';


@Component({
  selector: 'app-vendor-dashboard',
  templateUrl: './vendor-dashboard.component.html',
  styleUrls: ['./vendor-dashboard.component.scss']
})
export class VendorDashboardComponent implements OnInit {
  date;
  selectedmonth;
  years = [];
  selectedYear;
  currentyear;
  months = [];
  year;
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  mnthnm;
  mnthcrnt;
  vendorList: any = [];
  selectedvndr;
  tntLst: any = [];
  tnt_id;
  selectedtenant;
  clntDtls: any;
  cardSummary: any = [];
  daysInMonth: number;
  mnthName;
  vehcleTble: any = [];
  columnDefs: any = [];
  grdLst: any = [];
  tasksDataSource: DataSource;
  tasks: any[];
  @Input() key: number;
  shwSidebar: boolean = false;
  sidebarHeading: any;
  vehiclesLst: any = [];
  vehiclesData: any = [];
  columnDefs1: any = [];
  asrt_spre_in;
  shwEditOpts: boolean;
  statuses: any = [];
  vehMode: any = [];
  vehdisplayID: any;
  userDtls: any;
  showVndr: boolean = false;
  constructor(private apiServ: ApiService, private router: Router, private message: NzMessageService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.clntDtls)
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = 0;this.selectedvndr=0;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    console.log(this.userDtls)
  }
  ngOnInit(): void {
    this.date = new Date();

    this.year = this.date.getFullYear();
    var mnthTdy = this.date.getMonth() + 1;
    // this.mnthnm = this.monthNames[(new Date()).getMonth() - 1];
    this.mnthcrnt = this.monthNames[(new Date()).getMonth()];
    this.currentyear = (new Date()).getFullYear();
    var count = this.currentyear - 2016;
    for (var i = 0; i <= count; i++) {
      let yr = this.currentyear - i;
      this.years.push(yr);
    }
    this.selectedYear = this.currentyear;
    this.selectedmonth = this.date.getMonth() + 1;
    // this.selectedvndr = 0;
    this.daysInMonth = new Date(this.selectedYear, this.selectedmonth, 0).getDate();
    this.mnthnm = this.monthNames[this.selectedmonth - 1];
    // this.getTntlst();
    this.getMnth();
    this.getVendors();
    this.getCardsData();
    this.getVehiclewiseBilling();
  }
  ontntSelect(value) {
    this.selectedtenant = value;
    // this.getVendors();
    this.getCardsData();
    this.getVehiclewiseBilling();
  }
  getMnth() {
    var mnthTdy = this.date.getMonth() + 1;
    var month = [
      {
        "id": 1,
        "name": "January"
      },
      {
        "id": 2,
        "name": "February"
      },
      {
        "id": 3,
        "name": "March"
      },
      {
        "id": 4,
        "name": "April"
      },
      {
        "id": 5,
        "name": "May"
      },
      {
        "id": 6,
        "name": "June"
      },
      {
        "id": 7,
        "name": "July"
      },
      {
        "id": 8,
        "name": "August"
      },
      {
        "id": 9,
        "name": "September"
      },
      {
        "id": 10,
        "name": "October"
      },
      {
        "id": 11,
        "name": "November"
      },
      {
        "id": 12,
        "name": "December"
      }
    ]
    if (this.selectedYear == this.year) {
      this.months = []
      month.filter((k) => {
        if (k.id <= mnthTdy) {
          this.months.push(k);
        }
      })
    }
    else {
      this.months = []
      this.months = month
    }
    var mnthTdy = this.date.getMonth() + 1;

  }
  onSelectMnthYr() {
    console.log(this.selectedYear);
    console.log(this.selectedmonth);
    this.daysInMonth = new Date(this.selectedYear, this.selectedmonth, 0).getDate();
    this.mnthnm = this.monthNames[this.selectedmonth - 1];
    this.getCardsData();
    this.getVehiclewiseBilling();

  }
  getVendors() {
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      this.apiServ.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vendorList = res['data'];
          this.selectedvndr = this.vendorList[0].vndr_id;
          this.getCardsData();
          this.getVehiclewiseBilling();
        }
      }, (err) => {
        console.log("In errorr");
      })
    } else {
      this.showVndr = false;
      this.apiServ.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vendorList = res['data'];
          this.selectedvndr = this.vendorList[0].vndr_id;
          this.getTenants();
          this.getCardsData();
          this.getVehiclewiseBilling();
        }
      }, (err) => {
        console.log("In errorr");
      })
    }

  }
  onvndrSelect(value) {
    this.selectedvndr = value;
    this.getTenants();
    this.getCardsData();
    this.getVehiclewiseBilling();
  }
  getTenants() {
    this.tntLst=[];
    this.apiServ.get(`billing/vendor-wise/tenants/byvndrId/${this.selectedvndr}`).subscribe((res) => {
      console.log(res);
      if (res['status'] == 200) {
        this.tntLst = res['data'];

      }
    });
  }
  getCardsData() {
    this.apiServ.get(`billing/vendor-wise/cards/summary/${this.selectedvndr}/${this.selectedtenant}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.cardSummary = res['data'];
        var formatter = new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
        })
        var counter1 = 0
        this.cardSummary.filter((k) => {
          counter1 = 0;
          k['amnt_pyble_ct'] = formatter.format(k.amnt_pyble_ct);
        });
      }
    });
  }
  getVehiclewiseBilling() {
    this.columnDefs = [];
    this.vehcleTble = [];
    this.apiServ.get(`billing/vendor-wise/table/summary/${this.selectedvndr}/${this.selectedtenant}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.vehcleTble = res['data'];
        this.grdLst = this.vehcleTble;
        let counter = 0;
        let counter1 = 0;
        var formatter = new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
        })
        this.grdLst.filter((k) => {
          counter1 = 0;
          // k['sno'] = ++counter;
          // k['amnt_pyble_ct'] = formatter.format(k.amnt_pyble_ct);
        });
        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB Name', field: 'tnt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Vehicle Vendor', field: 'vndr_nm', alignment: 'left', width: 150, filter: true, sortable: true, },
          { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'center',},
          { headerName: 'Spare Vehicles', field: 'spare_veh', alignment: 'center',  },
          { headerName: 'Vehicles Online', field: 'onl_cnt', alignment: 'center',  },
          { headerName: 'vehicles Offline', field: 'off_cnt', alignment: 'center', },
          { headerName: 'Operated Vehicles', field: 'opr_cnt', alignment: 'center',},
          { headerName: 'Not Operated Vehicles', field: 'not_opr_cnt', alignment: 'center', },
          // { headerName: 'e) Payable Amount (c*d) ₹', field: 'amnt_pyble_ct', alignment: 'right', width: 330, displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: true, },

        ]
      }
    });
  }
  gotovehcleDashboard(data) {
    // this.router.navigate(['/internal/enterprise/get_SnglevehTrpsLst'], {
    console.log(data);
    this.router.navigate(['/internal/dashboard/vehicle-dashboard'], {
      queryParams: {
        asrt_id: JSON.stringify(data.data.asrt_id),
      }
    });
  }
  getAsrts() {
    this.tasks = [{
      "ID": 1,
      "Subject": "Prepare 2013 Financial",
      "StartDate": "2013/01/15",
      "DueDate": "2013/01/31",
      "Status": "Completed",
      "Priority": "High",
      "Completion": 100,
      "EmployeeID": 1000303
    },]
    this.tasksDataSource = new DataSource({
      store: new ArrayStore({
        data: this.tasks,
        key: "asrt_id"
      }),
      filter: ["EmployeeID", "=", this.key]
    })
  }
  completedValue(rowData) {
    return rowData.Status == "Completed";
  }
  showVehicles(data, id) {
    this.vehiclesLst = [];
    var vndr_id = data.data.vndr_id;
    var tnt_id = data.data.tnt_id;
    this.apiServ.get(`billing/vendor-wise/vehicles/list/${tnt_id}/${vndr_id}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.vehiclesLst = res['data'];
        this.vehdisplayID = id;
        if (id == 'all') {
          this.vehiclesData = [];
          var opt = "All Vehicles";
          this.vehiclesData = this.vehiclesLst;
          let counter = 0;
          let counter1 = 0;
          this.vehiclesLst.filter((k) => {
            counter1 = 0;
            k['sno'] = ++counter;
            if (k['asrt_spre'] == 'SPARE') {
              this.asrt_spre_in = 1;
              k['Mark'] = "Mark as Regular";
            } else {
              this.asrt_spre_in = 0;
              k['Mark'] = "Mark as Spare";
            }
          });
        } else {
          this.vehiclesData = [];
          var opt = "Offline Vehicles";
          let counter = 0;
          let counter1 = 0;
          this.vehiclesLst.filter((k) => {
            counter1 = 0;
            if (k['asrt_sts'] == 'OFFLINE') {
              this.vehiclesData.push(k);
              k['sno'] = ++counter;

            }
          });
        }
        this.columnDefs1 = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB Name', field: 'tnt_nm', alignment: 'left', width: 120, filter: true, sortable: true, },
          { headerName: 'Vehicle Registration Number', field: 'asrt_nm', alignment: 'left', width: 180, filter: true, sortable: true, },
          { headerName: 'Chasis Number', field: 'asrt_chses_nu', alignment: 'left', width: 180, filter: true, sortable: true, },
          { headerName: 'IMEI Number', field: 'dev_imei_nu', alignment: 'left', width: 180, },
          { headerName: 'Data Last Received On', field: 'data_rcvd_on', alignment: 'left', width: 200, },
          // { headerName: 'Vehicle Mode', field: 'asrt_spre', alignment: 'right', width: 100, },
          // { headerName: 'Update', field: 'Mark', alignment: 'right', width: 100, },

        ]
        this.openSideBar(opt, data);

      }

    })

  }
  openSideBar(opt, data) {
    this.shwSidebar = true;
    this.sidebarHeading = opt;
    console.log(opt);
    console.log(data);
    this.getDropdownData(data);

  }
  closeSideBar() {
    this.shwSidebar = false;
    this.columnDefs1 = [];
    this.vehiclesLst = [];

  }
  showMarkedVehicle(data) {
    console.log(data)
  }

  onCellPrepared(colDef, e) {
    this.onCellClick(colDef, e);
  }
  onCellClick(colDef, e) {
    console.log(colDef);
    console.log(e);
  }
  showOptions(evt) {
    console.log(evt.data);
    this.shwEditOpts = true;
  }
  getDropdownData(data): any {
    var tnt_id = data.data.tnt_id;
    this.apiServ.get(`billing/vendor-wise/vehicles/status/${tnt_id}`).subscribe((res) => { this.statuses = res['data']; 
    console.log(this.statuses)
    this.statuses.filter((k) => {
      if (k['asrt_glbl_sts_id'] == 3) {
        k['Disabled'] = false;
      }
      else {
        k['Disabled'] = true;
      }
    })});
    this.vehMode = [{
      asrt_spre_in: 1,
      asrt_spre_nme: "Spare"
    },
    {
      asrt_spre_in: 0,
      asrt_spre_nme: "Regular"
    }
    ]
  }
  onExporting(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Vendor Vehicles Data.xlsx');
      });
    });
  }
  changeStatus(data) {
    console.log(data.data)
    this.apiServ.put({ status_id: data.data.asrt_sts_id }, `billing/vendor-wise/vehicle/ ${data.data.tnt_id} /${data.data.asrt_id}/statuschnge`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Vehicle Status Successfully Updated');
        this.showVehicles(data, this.vehdisplayID);
      }
    });
  }
  changeMode(data) {
    console.log(data.data)
    this.apiServ.put({ asrt_spre_in: data.data.asrt_spre_in }, `billing/vendor-wise/vehicle/${data.data.tnt_id}/${data.data.asrt_id}/modechnge`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Vehicle Mode Successfully Updated');
        this.showVehicles(data, this.vehdisplayID);
      }
    });
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
}
