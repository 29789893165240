import { Component, OnInit ,ViewChild} from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-grama-ward-sachivalyam-dashboard',
  templateUrl: './grama-ward-sachivalyam-dashboard.component.html',
  styleUrls: ['./grama-ward-sachivalyam-dashboard.component.scss']
})
export class GramaWardSachivalyamDashboardComponent implements OnInit {
  today = new Date();
  strtdate = this.today;
  enddate = this.today;
  dateRange = [this.strtdate, this.enddate];
  dateFormat = 'dd-MM-yyyy';
  listOfCurrentDptPageData: any = [];
  dptWseTbleData: any = [];
  svmWseTbleData: any = []
  listOfCurrentSvmPageData: any = [];
  srvcWseTbleData: any = [];
  listOfCurrentSrvcPageData: any = [];
  depLoader: boolean = true;
  servLoader: boolean = true;
  svmLoader: boolean = true;
  dptWseTbleDataClone: any[];
  svmWseTbleDataClone: any[];
  srvcWseTbleDataClone: any[];
  sortSerReqFn = (a, b) => a.SER_REQUESTED - b.SER_REQUESTED;
  sortOpenWthSla = (a, b) => a.OPEN_WITHIN_SLA - b.OPEN_WITHIN_SLA;
  sortOpenByndSla = (a, b) => a.OPEN_BEYOND_SLA - b.OPEN_BEYOND_SLA;
  sortOpenByndSlaPer = (a, b) => a.OPEN_BEYOND_SLA_PER - b.OPEN_BEYOND_SLA_PER;
  sortClsWthSla = (a, b) => a.CLOSED_WITHIN_SLA - b.CLOSED_WITHIN_SLA;
  sortClsWthSlaPer = (a, b) => a.CLOSED_WITHIN_SLA_PER - b.CLOSED_WITHIN_SLA_PER;
  sortClsByndSla = (a, b) => a.CLOSED_BEYOND_SLA - b.CLOSED_BEYOND_SLA;
  sortClsByndSlaPer = (a, b) => a.CLOSED_BEYOND_SLA_PER - b.CLOSED_BEYOND_SLA_PER;
  dptWseTbleDatatotSrvReq: any = 0;
  dptWseTbleDatatotWtSla: any = 0;
  dptWseTbleDatatotBySla: any = 0;
  dptWseTbleDatatotClsWtSla: any = 0;
  dptWseTbleDatatotClsBySla: any = 0;
  srvcWseTbleDatatotSrvReq: any = 0;
  srvcWseTbleDatatotWtSla: any = 0;
  srvcWseTbleDatatotBySla: any = 0;
  srvcWseTbleDatatotClsWtSla: any = 0;
  srvcWseTbleDatatotClsBySla: any = 0;
  svmWseTbleDatatotSrvReq: any = 0;
  svmWseTbleDatatotWtSla: any = 0;
  svmWseTbleDatatotBySla: any = 0;
  svmWseTbleDatatotClsWtSla: any = 0;
  svmWseTbleDatatotClsBySla: any = 0;
  @ViewChild('fullScreen') divRef;

  onCurrentDptPageDataChange(listOfCurrentDptPageData): void {
    this.listOfCurrentDptPageData = listOfCurrentDptPageData;
  }
  onCurrentSvmPageDataChange(listOfCurrentSvmPageData): void {
    this.listOfCurrentSvmPageData = listOfCurrentSvmPageData;
  }
  onCurrentSrvcPageDataChange(listOfCurrentSrvcPageData): void {
    this.listOfCurrentSrvcPageData = listOfCurrentSrvcPageData;
  }

  constructor(private apiSrv: AppsService) {
    this.getDepartWiseData();
    this.getSrvcWiseData();
    this.geSvmWiseData();
  }

  ngOnInit() {
    this.divRef = document.documentElement;
  }
  onDtChange(e) {
    //   console.log(this.strtdate);
    //   console.log(this.enddate);
    //   console.log(e);
    this.strtdate = new Date(e[0]);
    this.enddate = new Date(e[1]);

    //this.strtdate=new Date(e[0]);
    //this.enddate=e[1];
    this.getDepartWiseData();
    this.getSrvcWiseData();
    this.geSvmWiseData();
  }

  getDepartWiseData() {
    this.depLoader = true;
    let rte = `public/web/dashboard/sachivalayam/services`;
    let postData = {
      "strt_dt": this.strtdate,
      "end_dt": this.enddate
    };
    //   console.log(postData);
    this.apiSrv.post(postData, rte).subscribe(res => {
      //   //   console.log(res);
      this.dptWseTbleData = res['data'];
      for (let i = 0; i < this.dptWseTbleData.length; i++) {
        this.dptWseTbleData[i]['sno'] = i + 1
        this.dptWseTbleDatatotSrvReq += this.dptWseTbleData[i]['SER_REQUESTED'];
        this.dptWseTbleDatatotWtSla += this.dptWseTbleData[i]['OPEN_WITHIN_SLA']
        this.dptWseTbleDatatotBySla += this.dptWseTbleData[i]['OPEN_BEYOND_SLA']
        this.dptWseTbleDatatotClsWtSla += this.dptWseTbleData[i]['CLOSED_WITHIN_SLA']
        this.dptWseTbleDatatotClsBySla += this.dptWseTbleData[i]['CLOSED_BEYOND_SLA']
      }
      this.dptWseTbleDataClone = [...this.dptWseTbleData];
      this.depLoader = false;
    })

  }
  getSrvcWiseData() {
    this.servLoader = true;
    let rte = `public/web/dashboard/sachivalayam/departments`;
    let postData = {
      "strt_dt": this.strtdate,
      "end_dt": this.enddate
    };
    //   console.log(postData);
    this.apiSrv.post(postData, rte).subscribe(res => {
      //   //   console.log(res);
      this.srvcWseTbleData = res['data'];
      for (let i = 0; i < this.srvcWseTbleData.length; i++) {
        this.srvcWseTbleData[i]['sno'] = i + 1
        this.srvcWseTbleDatatotSrvReq += this.srvcWseTbleData[i]['SER_REQUESTED'];
        this.srvcWseTbleDatatotWtSla += this.srvcWseTbleData[i]['OPEN_WITHIN_SLA']
        this.srvcWseTbleDatatotBySla += this.srvcWseTbleData[i]['OPEN_BEYOND_SLA']
        this.srvcWseTbleDatatotClsWtSla += this.srvcWseTbleData[i]['CLOSED_WITHIN_SLA']
        this.srvcWseTbleDatatotClsBySla += this.srvcWseTbleData[i]['CLOSED_BEYOND_SLA']
      }
      this.servLoader = false;
      this.srvcWseTbleDataClone = [...this.srvcWseTbleData]
    })

  }
  geSvmWiseData() {
    this.svmLoader = true;
    let rte = `public/web/dashboard/sachivalayam/secretariat`;
    let postData = {
      "strt_dt": this.strtdate,
      "end_dt": this.enddate
    };
    //   console.log(postData);
    this.apiSrv.post(postData, rte).subscribe(res => {
      //   //   console.log(res);
      this.svmWseTbleData = res['data'];
      for (let i = 0; i < this.svmWseTbleData.length; i++) {
        this.svmWseTbleData[i]['sno'] = i + 1
        this.svmWseTbleDatatotSrvReq += this.svmWseTbleData[i]['SER_REQUESTED'];
        this.svmWseTbleDatatotWtSla += this.svmWseTbleData[i]['OPEN_WITHIN_SLA']
        this.svmWseTbleDatatotBySla += this.svmWseTbleData[i]['OPEN_BEYOND_SLA']
        this.svmWseTbleDatatotClsWtSla += this.svmWseTbleData[i]['CLOSED_WITHIN_SLA']
        this.svmWseTbleDatatotClsBySla += this.svmWseTbleData[i]['CLOSED_BEYOND_SLA']
      }
      this.svmLoader = false;
      this.svmWseTbleDataClone = [...this.svmWseTbleData];
    })

  }

  numSort(data, dataNm, field) {

    //   console.log(dataNm,field);

    this.svmWseTbleData = data.sort((a, b) => {
      return a.SER_REQUESTED - b.SER_REQUESTED;
    })

  }

  initializeItems() {
    this.dptWseTbleData = this.dptWseTbleDataClone
  }
  initializeItemsSvm() {
    this.svmWseTbleData = this.svmWseTbleDataClone
  }
  initializeItemsSrv() {
    this.srvcWseTbleData = this.srvcWseTbleDataClone
  }

  getItems(ev: any) {
    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.dptWseTbleData = this.dptWseTbleDataClone.filter((item) => {
        return (item.SD_NAME.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    } else {

    }
  }

  getItemsSvm(ev: any) {
    // Reset items back to all of the items
    this.initializeItemsSvm();

    // set val to the value of the searchbar
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.svmWseTbleData = this.svmWseTbleDataClone.filter((item) => {
        return (item.SECRETARIAT_NAME.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    } else {

    }
  }

  getItemsSrv(ev: any) {
    // Reset items back to all of the items
    this.initializeItemsSrv();

    // set val to the value of the searchbar
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.srvcWseTbleData = this.srvcWseTbleDataClone.filter((item) => {
        return (item.URL_DESCRIPTION.toLowerCase().indexOf(val.toLowerCase()) > -1 || item.DEPT_NAME.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    } else {

    }
  }
  openFullscreen() {
    // Use this.divRef.nativeElement here to request fullscreen
    const elem = this.divRef.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  // sortFn(a: DataItem, b: DataItem) => a.name.localeCompare(b.name)
}
