<!-- <div>
    <i nz-icon nzType="idcard" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">Roles</b>
</div> -->

<pg-header title="Roles" ticon="idcard" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">

    <div class="ds-card grid">
        <dx-data-grid id="gridContainer" [dataSource]="usrRlesLst" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
            [showBorders]="true" style="width:100%" [allowColumnResizing]="true">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
            </dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
            <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true" fileName="LMO Details"></dxo-export>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
                [width]="f.width" [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
                [allowHeaderFiltering]="f.filter"></dxi-column>
        </dx-data-grid>
    </div>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
    <nz-result nzStatus="error" nzTitle="Access Denied"
        nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
        <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
    </nz-result>
</div>