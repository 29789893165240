<ds-loading *ngIf="showLdr"></ds-loading>
<div class="row hdrBar">
    <div class="col-lg-8 col-md-8 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">Clean Andhra Pradesh(CLAP)</h1>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
</div>
<div class="main">
    <div class="row">
        <div class="row bg-wp" style="padding: 2px 10px 2px 20px; margin: 0px 10px; width: 100%;">
            <h1 class="hdrTxt_sm hdrSdLbls" style="text-align: center;text-transform: capitalize;">GRAMEEN</h1>
            <ul class="cards">
                <li class="cards_item" *ngFor="let d of tdyCoveredDstnce">
                    <div class="card_g" style="background:linear-gradient(135deg,  #63badb 0%,#35a1af 100%);"
                        (click)="cardFive()" [ngClass]="crdFive == true ? 'active_class' : 'normalclass'">
                        <div class="card_content">
                            <div class="row">
                                <h1 class="crdHdr">Vehicles</h1>
                            </div>
                            <div class="row">
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">{{d.actve_rural_ttl === null ? "0" : d.actve_rural_ttl}}
                                    </h1>
                                    <h1 class="card_sub_title">Active</h1>
                                </div>
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.inact_rural_ttl === null ? "0" : d.inact_rural_ttl}}
                                    </h1>
                                    <h1 class="card_sub_title">Spare</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="cards_item" *ngFor="let d of tdyCoveredDstnce">
                    <div class="card_g" style="background:linear-gradient(135deg, #7f75e5 0%,#4e49b9 100%);"
                        (click)="cardSix()" [ngClass]="crdSix == true ? 'active_class' : 'normalclass'">
                        <div class="card_content">
                            <div class="row">
                                <h1 class="crdHdr">Operational Status</h1>
                            </div>
                            <div class="row">
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">{{d.r_operated === null ? "0" : d.r_operated}}
                                    </h1>
                                    <h1 class="card_sub_title">Operated</h1>

                                </div>
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.r_opper === null ? "0" : d.r_opper}} %
                                    </h1>
                                    <h1 class="card_sub_title">Operated %</h1>
                                </div>
                            </div>
                            <div class="row">
                                <p class="crdFtr">VEHICLES TRAVELLED > 500 Meters IS CONSIDERED AS OPERATED</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="cards_item" *ngFor="let d of vhclsTrvld">
                    <div class="card_g" style="background:linear-gradient(135deg, #9267d9 0%,#683eb5 100%);"
                        (click)="cardSeven()" [ngClass]="crdSeven == true ? 'active_class' : 'normalclass'">
                        <div class="card_content">
                            <div class="row">
                                <h1 class="crdHdr">Vehicles Travelled</h1>
                            </div>
                            <div class="row">
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm"> {{d.rrl_tdydst_abv30 === null ? "0" : d.rrl_tdydst_abv30}}
                                    </h1>
                                    <h1 class="card_sub_title">
                                        >= 30km</h1>
                                </div>
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.rrl_tdydst_blw30 === null ? "0" : d.rrl_tdydst_blw30}}
                                    </h1>
                                    <h1 class="card_sub_title">
                                        < 30km</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="cards_item" *ngFor="let d of tdyCoveredDstnce">
                    <div class="card_g" style="background:linear-gradient(135deg, #63b5f5 0%,#449ee5 100%);"
                        (click)="cardEight()" [ngClass]="crdEight == true ? 'active_class' : 'normalclass'">
                        <div class="card_content">
                            <div class="row">
                                <h1 class="crdHdr">Distance covered</h1>
                            </div>
                            <div class="row">
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.r_dst === null ? 0 : d.r_dst}}
                                    </h1>
                                    <h1 class="card_sub_title">Total Distance</h1>
                                </div>
                                <div class="col card_prt">
                                    <h1 class="crdHdr_sb_sm">
                                        {{d.r_avg_dst === null ? 0 : d.r_avg_dst}}
                                    </h1>
                                    <h1 class="card_sub_title">Average</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row bg-wp" *ngIf="crdFive==true">
        <h1>District Wise vehicles Status (RURAL)</h1>
        <div id="ruralVehiclesChart"></div>
    </div>
    <div class="row bg-wp" *ngIf="crdSix==true">
        <h1>District Wise vehicles Operational Status (RURAL)</h1>
        <div id="ruraloperStatusChart"></div>
    </div>
    <div class="row bg-wp" *ngIf="crdSeven==true">
        <h1>District Wise Travelled Vehicles (RURAL)</h1>
        <div id="ruraltrvlVhcleChart"></div>
    </div>
    <div class="row bg-wp">
        <h1>District Wise Vehicles Summary</h1>
        <div class="ds-card grid tbl">
            <dx-data-grid class="p-8" [dataSource]="dstrctTripsDta" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                (onToolbarPreparing)="true" (onCellPrepared)="onCellPrepared(ulbTripscolumnDefs,$event)"
                [wordWrapEnabled]="true">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="ispagination" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="District Trips Data">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-summary>
                    <dxi-total-item column="tot_veh" summaryType="sum" name="Total Vehicles">
                    </dxi-total-item>
                    <dxi-total-item column="online_veh" summaryType="sum" name="Online">
                    </dxi-total-item>
                    <dxi-total-item column="offline_veh" summaryType="sum" name="Offline">
                    </dxi-total-item>
                    <dxi-group-item column="tnt_dsply_nm" name="Total Tenants" summaryType="count"
                        [alignByColumn]="true" [showInGroupFooter]="true">
                    </dxi-group-item>
                    <dxi-group-item column="totl_trps" name="Total Trips" summaryType="count" [alignByColumn]="true"
                        [showInGroupFooter]="true">
                    </dxi-group-item>
                    <dxi-total-item column="yet_to_strt" summaryType="sum" name="Total">
                    </dxi-total-item>
                    <dxi-total-item column="running" summaryType="sum" name="Running">
                    </dxi-total-item>
                    <dxi-total-item column="closed" summaryType="sum" name="Closed">
                    </dxi-total-item>
                </dxo-summary>
                <dxi-column *ngFor="let f of ulbTripscolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoUlb(data)">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>Quick View</p>
                        </div>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>

    <div class="row bg-wp" *ngIf="ShowClap">
        <h1>Vendor wise operated vehicles and operated %</h1>
        <div class="ds-card tbl grid">

            <dx-data-grid class="p-8" [dataSource]="ulbVndrwseopratedTripsTble" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="true">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="ulbVndrwseopratedTripsTble>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="District wise operated Trips">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of ulbVndrwseopratedTripsTblecolumnDefs" [visible]="!f.hide"
                    [dataField]="f.field" [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width"
                    [allowResizing]="true">
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>

</div>