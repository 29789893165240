import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-house-status',
  templateUrl: './house-status.component.html',
  styleUrls: ['./house-status.component.scss']
})
export class HouseStatusComponent implements OnInit {

  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  hsestsLst: any;
  columnDefs;
  shwSidebar = false;
  hsestsNm: string | null = null;
  shwstsAlrt = false;
  shwEditDiv = false;
  hsestsId: any;
  sidebarHeading: string;
  shwDelDiv: boolean;
  shwLdg: boolean;

  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.getHsestsLst();
  }

  getHsestsLst() {
    this.shwLdg = true;
    const rte = 'admin/house/status';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
          this.shwLdg = false;
          if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.hsestsLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'House Status Name', field: 'hse_sts_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 265 },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    }, (err) => {
      this.shwLdg = false;
    });
  }

  onCellClick(opt, edtData) {
    if (opt === 'edit') {
      this.shwEditDiv = true;
      this.hsestsNm = edtData.data.hse_sts_nm;
      this.hsestsId = edtData.data.hse_sts_id;
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwEditDiv = false;
      this.shwDelDiv = true;
      this.hsestsId = edtData.data.hse_sts_id;
      this.hsestsNm = edtData.data.hse_sts_nm;
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New House Status',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwEditDiv = true;
      this.shwDelDiv = false;
      this.sidebarHeading = 'Edit House Status';
    } else if (opt === 'delete') {
      this.shwEditDiv = false;
      this.shwDelDiv = true;
      this.sidebarHeading = 'Delete House Status';
    } else {
      this.shwEditDiv = false;
      this.shwDelDiv = false;
      this.hsestsNm = '';
      this.sidebarHeading = 'Add New House Status';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  oncancel(): void {
    this.shwSidebar = false;
  }

  saveNew() {
    const hsestsData = {
      name: this.hsestsNm
    };

    const rte = 'admin/house/status';
    this.apiSrv.create(hsestsData, rte).subscribe(res => {
      //   //   console.log(res)
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('success', 'House Status Successfully Added');
        this.getHsestsLst();
      }
    });
  }

  saveEdit() {
    const hseEdtData = {
      id: this.hsestsId,
      name: this.hsestsNm
    };

    const rte = 'admin/house/status/'+this.hsestsId;
    this.apiSrv.update(hseEdtData, rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('info', 'House Status Successfully Updated');
        this.getHsestsLst();
      }
    });
  }

  saveDelete() {
    const hseDelData = {
      id: this.hsestsId
    };

    const rte = 'admin/house/status/'+this.hsestsId;
    this.apiSrv.delete( rte).subscribe(res => {
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwstsAlrt = true;
        this.createMessage('warning', 'House Status Successfully Deleted');
        this.getHsestsLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.nzMessageService.info('House Status Not Deleted');
  }

}
