import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  dprtLst: any;
  columnDefs;
  shwSidebar = false;
  dprtNm: string | null = null;
  shwDprtAlrt = false;
  shwDprtEditDiv = false;
  dprtId: any;
  sidebarHeading: string;
  shwDprtDelDiv: boolean;
  shwLdg: boolean;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private message: NzMessageService,
    private nzMessageService: NzMessageService) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.getDprtsLst();
  }

  getDprtsLst() {
    this.shwLdg = true;
    const rte = 'org/departments';
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
          this.shwLdg = false;
          if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.dprtLst = res['data'];
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, filter: false },
            { headerName: 'Department Name', field: 'dprt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 400, sortable: true, filter: true },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true }
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

      }
    }, err => {
      this.shwLdg = false;
    });
  }

  onCellClick(opt, edtData) {
    if (opt === 'edit') {
      this.shwDprtEditDiv = true;
      this.dprtNm = edtData.data.dprt_nm;
      this.dprtId = edtData.data.dprt_id;
      this.openSideBar(opt);
    }
    if (opt === 'delete') {
      this.shwDprtEditDiv = false;
      this.shwDprtDelDiv = true;
      this.dprtId = edtData.data.dprt_id;
      this.dprtNm = edtData.data.dprt_nm;
      this.openSideBar(opt);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Department',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwDprtEditDiv = true;
      this.shwDprtDelDiv = false;
      this.sidebarHeading = 'Edit Department';
    } else if (opt === 'delete') {
      this.shwDprtEditDiv = false;
      this.shwDprtDelDiv = true;
      this.sidebarHeading = 'Delete Department';
    } else {
      this.shwDprtEditDiv = false;
      this.shwDprtDelDiv = false;
      this.dprtNm = '';
      this.sidebarHeading = 'Add New Department';
    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
  }
  saveDprt() {
    const dprtData = {
      name: this.dprtNm
    };

    const rte = 'org/departments';
    this.apiSrv.create(dprtData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwDprtAlrt = true;
        this.createMessage('success', 'Department Successfully Added');
        this.getDprtsLst();
      }
    });
  }

  saveEditDprt() {
    const dprtEdtData = {
      name: this.dprtNm
    };

    const rte = 'org/departments/'+ this.dprtId;
    this.apiSrv.update(dprtEdtData, rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwDprtAlrt = true;
        this.createMessage('info', 'Department Successfully Updated');
        this.getDprtsLst();
      }
    });
  }

  saveDeleteDprt() {
    // console.log('in del');
    const dprtDelData = {
      id: this.dprtId
    };

    const rte = 'org/departments/'+this.dprtId;
    this.apiSrv.delete(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      if (res['status'] === 200) {
        this.shwSidebar = false;
        this.shwDprtAlrt = true;
        this.createMessage('warning', 'Department Successfully Deleted');
        this.getDprtsLst();
      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  cancel(): void {
    this.createMessage('info', 'Department Not Deleted');
  }

  cancelbtn() {
    this.shwSidebar = false;
  }

}
