import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppsService } from 'src/app/shared/services/apps.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-trip-operations',
  templateUrl: './trip-operations.component.html',
  styleUrls: ['./trip-operations.component.scss']
})
export class TripOperationsComponent implements OnInit {
  clntDtls: any;
  tntLst: any = [];
  selectedtenant: any;
  userDtls: any;
  dateFormat = 'dd/MM/yyyy';
  slctdDt = new Date();
  curDate;
  showLdr: boolean;
  vhcleslst: any = [];
  selectedVehicle: any;
  today = new Date();
  tripDtls: any = [];
  columnDefs: any = [];
  constructor(private apiSrvc: AppsService, private message: NzMessageService,) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
  }

  ngOnInit(): void {
    this.slctdDt = new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.getVehicles();
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');

  }
  ontntSelect(value) {
    if (value === 0) {
      this.selectedtenant = 0;
    }
    for (let i = 0; i < this.tntLst.length; i++) {
      if (this.tntLst[i].tnt_id === value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
      }
    }
    this.getVehicles();

  }
  getVehicles() {
    var fltrData = [{
      selectedtenant: this.selectedtenant,
      selectedvndr: 0,
      selectedctgry: 0
    }]
    this.showLdr = true;
    this.apiSrvc.post(fltrData, 'drivers/vehicles/asgndvehicles').subscribe((res) => {
      if (res['status'] == 200) {
        this.vhcleslst = res['data'];
        this.selectedVehicle = this.vhcleslst[0].asrt_id;
        this.showLdr = false;
        this.getTripDetails();

      }
    });
  }
  onvehcleSelect(evt) {
    this.selectedVehicle = evt;
    this.getTripDetails();
  }
  getTripDetails() {
    var postData = {
      tnt_id: this.selectedtenant,
      asrt_id: this.selectedVehicle,
      dt: this.curDate
    }
    this.showLdr = true;

    if (this.selectedVehicle) {
      this.apiSrvc.post(postData, `tripschedule/vehicles/tripsDtls`).subscribe((res) => {
        if (res['status'] == 200) {
          this.tripDtls = res['data'];
          console.log(this.tripDtls)
          this.showLdr = false;
          var counter = 0;
          this.tripDtls.filter((k) => {
            k['sno'] = ++counter;
            if (k['trp_type_id'] == 1000001) {
              k['trp_typ_nm'] = 'Secondary';
            } else {
              k['trp_typ_nm'] = 'Primary';
            }
          });
          this.columnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false, cellClass: 'pm-grid-number-cell', search: false },
            { headerName: 'Vehicle Registration Number', alignment: 'left', width: 'auto', field: 'asrt_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'center', cellClass: 'pm-grid-number-cell',filter: true, },
            { headerName: 'Driver', alignment: 'center',  field: 'crw_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Trip No.', field: 'trp_sqnc_nu', width: 'auto', alignment: 'center', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Route', field: 'rte_nm', alignment: 'center', cellClass: 'pm-grid-number-cell',filter: true, groupIndex: '0', },
            { headerName: 'Route', field: 'rte_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true, },
            { headerName: 'Trip Type', field: 'trp_typ_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true },
            { headerName: 'From', alignment: 'left', field: 'strt_fnce_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'To', field: 'end_fnce_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Schedule Start', alignment: 'center', field: 'sch_strt_tm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Schedule End', field: 'sch_end_tm', alignment: 'center', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Actual Start', alignment: 'center', field: 'actl_strt_ts', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Actual End', field: 'actl_end_ts', alignment: 'center', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Trip Status', field: 'trp_status',  alignment: 'center', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Travelled Distance', alignment: 'center', field: 'actl_trvl_dstne_ct', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Travelled Time', field: 'actl_trvl_tm',  alignment: 'center', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Route Deviation', field: 'rt_dev', alignment: 'center', cellClass: 'pm-grid-number-cell' },
            // { headerName: 'Polyline', field: 'ply_ln_oprtn', alignment: 'center', cellClass: 'pm-grid-number-cell' },


          ]
        }else{
          this.showLdr = false;
        }
      })
    }
  }
  onCellPrepared(colDef, e) { }
  onCellClick(evnt) { }
  clearTrip(data){
    // closeTrip
    var postData = {
      tnt_id: this.selectedtenant,
      asrt_id: this.selectedVehicle,
      dt: this.curDate,
      trp_schde_id:data.data.trp_schde_id,
      trp_rn_id:data.data.trp_rn_id
    }
    this.showLdr = true;

    if (this.selectedVehicle) {
      this.apiSrvc.post(postData, `tripschedule/tripsDtls/clearTrip`).subscribe((res) => {
        if (res['status'] == 200) {
        this.showLdr = false;
        }

      })
    }
  }
  assgnVehicle(data){}
  startTrip(data){
    console.log(data)

  }
}
