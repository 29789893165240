import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api.service';
import { Router } from '@angular/router';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  date;
  selectedmonth;
  years = [];
  selectedYear;
  currentyear;
  months = [];
  year;
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  mnthnm;
  mnthcrnt;
  vendorList: any = [];
  selectedvndr;
  cardSummary: any = [];
  daysInMonth: number;
  mnthName;
  vehcleTble: any = [];
  columnDefs: any = [];
  grdLst: any = [];
  tntLst: any = [];
  tnt_id;
  selectedtenant;
  clntDtls: any;
  selectedVehicle: any;
  visible = false;
  userDtls: any;
  showVndr: boolean=false;
  constructor(private apiServ: ApiService, private router: Router) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.clntDtls)
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.userDtls=JSON.parse(localStorage.getItem('wto_w_user'));

  }

  ngOnInit(): void {
    this.date = new Date();

    this.year = this.date.getFullYear();
    var mnthTdy = this.date.getMonth() + 1;
    // this.mnthnm = this.monthNames[(new Date()).getMonth() - 1];
    this.mnthcrnt = this.monthNames[(new Date()).getMonth()];
    this.currentyear = (new Date()).getFullYear();
    var count = this.currentyear - 2016;
    for (var i = 0; i <= count; i++) {
      let yr = this.currentyear - i;
      this.years.push(yr);
    }
    this.selectedYear = this.currentyear
    this.selectedmonth = this.date.getMonth() + 1;
    // this.selectedvndr = 1;
    this.daysInMonth = new Date(this.selectedYear, this.selectedmonth, 0).getDate();
    this.mnthnm = this.monthNames[this.selectedmonth - 1];
    // this.getTntlst();
    this.getMnth();
    this.getVendors();
    this.getCardsData();
    this.getVehiclewiseBilling();
  }
  // getTntlst() {
  //   this.apiServ.get(`billing/vehicle-wise/tenant/tenantLst`).subscribe((res) => {
  //     console.log(res)
  //     if (res['status'] == 200) {
  //       this.tntLst = res['data'];
  //       // this.selectedtenant=this.tntLst[0].tnt_id;
  //     }
  //   }, (err) => {
  //     console.log("In errorr");
  //   })
  // }
  ontntSelect(value) {
    this.selectedtenant = value;
    this.getVendors();
    this.getCardsData();
    this.getVehiclewiseBilling();
    // this.selectedvndr=this.vendorList[0].vndr_id;
  }
  getMnth() {
    var mnthTdy = this.date.getMonth() + 1;
    var month = [
      {
        "id": 1,
        "name": "January"
      },
      {
        "id": 2,
        "name": "February"
      },
      {
        "id": 3,
        "name": "March"
      },
      {
        "id": 4,
        "name": "April"
      },
      {
        "id": 5,
        "name": "May"
      },
      {
        "id": 6,
        "name": "June"
      },
      {
        "id": 7,
        "name": "July"
      },
      {
        "id": 8,
        "name": "August"
      },
      {
        "id": 9,
        "name": "September"
      },
      {
        "id": 10,
        "name": "October"
      },
      {
        "id": 11,
        "name": "November"
      },
      {
        "id": 12,
        "name": "December"
      }
    ]
    if (this.selectedYear == this.year) {
      this.months = []
      month.filter((k) => {
        if (k.id <= mnthTdy) {
          this.months.push(k);
        }
      })
    }
    else {
      this.months = []
      this.months = month
    }
    var mnthTdy = this.date.getMonth() + 1;

  }
  onSelectMnthYr() {
    console.log(this.selectedYear);
    console.log(this.selectedmonth);
    this.daysInMonth = new Date(this.selectedYear, this.selectedmonth, 0).getDate();
    this.mnthnm = this.monthNames[this.selectedmonth - 1];
    this.getCardsData();
    this.getVehiclewiseBilling();

  }
  getVendors() {
    if(this.userDtls.dsgns_id== 1000032){
      this.selectedvndr=this.userDtls.vndr_id;
      this.showVndr=true;
      this.apiServ.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vendorList = res['data'];
        }
      }, (err) => {
        console.log("In errorr");
      })
    }else{
    this.apiServ.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.vendorList = res['data'];
        if(this.vendorList.length>-1){
          this.selectedvndr = this.vendorList[0].vndr_id;
        }
        this.getCardsData();
        this.getVehiclewiseBilling();
      }
    }, (err) => {
      console.log("In errorr");
    });
  }
  }
  onvndrSelect(value) {
    this.selectedvndr = value;
    this.getCardsData();
    this.getVehiclewiseBilling();
  }
  getCardsData() {
    this.apiServ.get(`billing/vehicle-wise/cards/summary/${this.selectedmonth}/${this.selectedYear}/${this.selectedvndr}/${this.selectedtenant}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.cardSummary = res['data'];
        var formatter = new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
        })
        var counter1 = 0
        this.cardSummary.filter((k) => {
          counter1 = 0;
          k['amnt_pyble_ct'] = formatter.format(k.amnt_pyble_ct);
        });
      }
    });
  }
  getVehiclewiseBilling() {
    this.columnDefs = [];
    this.vehcleTble = [];
    this.apiServ.get(`billing/vehicle-wise/table/summary/${this.selectedmonth}/${this.selectedYear}/${this.selectedvndr}/${this.selectedtenant}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.vehcleTble = res['data'];
        this.grdLst = this.vehcleTble;
        let counter = 0;
        let counter1 = 0;
        var formatter = new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
        })
        this.grdLst.filter((k) => {
          counter1 = 0;
          // k['sno'] = ++counter;
          // k['amnt_pyble_ct'] = formatter.format(k.amnt_pyble_ct);
        });
        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB Name', field: 'tnt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
          { headerName: 'Vehicle Name', field: 'asrt_nm', alignment: 'left', width: 120, filter: true, sortable: true, },
          { headerName: 'Vehicle Vendor', field: 'vndr_nm', alignment: 'left', width: 120, filter: true, sortable: true, },
          { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'left', filter: true, sortable: true, },
          { headerName: 'a) Expected Vehicle Work Days', field: 'exptd_wrkng_dy_ct', alignment: 'center',  displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: false, },
          { headerName: 'b) Non Operational Vehicle Work Days', field: 'nt_oprtnl_wrkng_dy_ct', alignment: 'center',  displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: false, },
          { headerName: 'c) Operational Vehicle Work Days (a-b)', field: 'oprtnl_wrkng_dy_ct', alignment: 'center',  displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: false, },
          // { headerName: 'd) Cost Per Day', field: 'cst_pr_dy_ct', alignment: 'right', width: 160, displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: true, },
          // { headerName: 'e) Payable Amount  ₹ (c*d)', field: 'amnt_pyble_ct', alignment: 'right', width: 160, displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: true, },

        ]
      }
    });
  }
  gotovehcleDashboard(data) {
    // this.router.navigate(['/internal/enterprise/get_SnglevehTrpsLst'], {
    console.log(data);
    this.selectedVehicle = data.data;
    this.visible = true;
    // this.router.navigate(['/internal/dashboard/vehicle-dashboard'], {
    //   queryParams: {
    //     asrt_id: JSON.stringify(data.data.asrt_id),
    //   }
    // });
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'vehicle.xlsx');
      });
    });
  }

  gotovehclesPage(evt) {
    console.log(evt);
    var paramData = {
      asrt_id: evt.data.asrt_id,
      clnt_id: evt.data.clnt_id,
      tnt_id: evt.data.tnt_id,
      vndr_id: this.selectedvndr,
      asrt_ctgry_id: evt.data.asrt_ctgry_id,
      pg_ky: 'billing'
    }
    this.router.navigate(['/internal/ams_vehicles/vehicles'], {
      queryParams: {
        data: JSON.stringify(paramData),
      }
    });
  }
}
