<pg-header title="Wards" ticon="database" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">
  <div>
    <div class="ds-card grid">
      <ds-loading *ngIf="shwLdg"></ds-loading>
      <dx-data-grid id="gridContainer" [dataSource]="wrdLst" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true"
        style="width:100%" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
        <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true" fileName="LMO Details"></dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [width]="f.width" [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
          [allowHeaderFiltering]="f.filter"></dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" type="buttons" [width]="100" caption="Edit"
          cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="cursor-pointer tblEdtBtn" (click)="onCellClick('edit',data)">
              Edit
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons" [width]="100" caption="Delete"
          cellTemplate="delTemplate">
          <div *dxTemplate="let data of 'delTemplate'">
            <div class="cursor-pointer tblDelBtn" (click)="onCellClick('delete',data)">
              Delete
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>

  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
    (nzOnClose)="closeSideBar()">
    <div class="mcrpckMrgn">
      <div class="inptLbl">Ward Name</div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input type="text" nz-input [(ngModel)]="wrdData.wrd_nm" placeholder="please enter ward name" />
      </nz-input-group>
    </div>
    <div class="mcrpckMrgn">
      <div class="inptLbl">ULB</div>
      <input placeholder="Select ulb" nz-input [(ngModel)]="wrdData.ulb_nm" (ngModelChange)="onChange($event)"
        [nzAutocomplete]="auto" />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let d of ulbs" [nzValue]="d.ulb_nm" (click)="getULBID(d.ulb_id);">{{d.ulb_nm}}
        </nz-auto-option>
      </nz-autocomplete>
    </div>
    <ng-template #inputClearTpl><i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
        *ngIf="wrdData.wrd_nm" (click)="wrdData = {}"></i></ng-template>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveWrd()"
      *ngIf="shwWrdEditDiv==false && shwWrdDelDiv==false">Submit</button>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEditWrd()"
      *ngIf="shwWrdEditDiv==true && shwWrdDelDiv==false">Update</button>
    <button nz-button nzType="primary" class="sbmtBtm" *ngIf="shwWrdEditDiv==false && shwWrdDelDiv==true" nz-popconfirm
      nzPopconfirmTitle="Are you sure delete this Ward?" nzPopconfirmPlacement="bottom" (nzOnConfirm)="saveDeleteWrd()"
      (nzOnCancel)="cancel()">Delete</button>
    <button nz-button nzType="default" class="cnclBtn" (click)="oncancel()" *ngIf="shwSidebar">Cancel</button>
  </nz-drawer>
  <!-- </div>
    </div>
</div> -->
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
  </nz-result>
</div>