<ds-loading *ngIf="showLdr"></ds-loading>
<div class="row ">
    <div class="col-lg-8 col-md-8">
        <div class="row hdrBar">
            <div class="col-lg-8 col-md-8 mg-8" *ngIf="!tenantId">
                <h1 *ngIf="clntDtls && clntDtls.length > 0">Operational Dashboard
                    <span *ngIf="selectedtenantNm" class="tntTxt">({{selectedtenantNm}})</span>
                </h1>
            </div>
            <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;" *ngIf="tntLst.length>1">
                <span>Select ULB:</span>
                <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
                    [ngModelOptions]="{standalone: true}">
                    <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                    <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;" *ngIf="tntLst.length==1">
                <span>ULB:</span>
                <span class="disbledtxt">{{tntLst[0].tnt_nm}}</span>
            </div>

        </div>
    </div>
    <div class="col-lg-4 col-md-4">
        <div class="main">
            <div class="row">
                <!-- <div class="row"> -->
                <!-- <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedAnlys" (ngModelChange)="getAnalysis($event)"
            nzPlaceHolder="Select" [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let s of selcdAnalysis" [nzLabel]="s.anl_nm" [nzValue]="s.anl_id"></nz-option>
        </nz-select> -->
                <ul class="cards">
                    <li class="cards_item3" *ngFor="let d of VhcleCtnts">
                        <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Vehicles</h1>
                                </div>
                                <div class="row">
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm" (click)="getsort('', '')">{{d.total === null ? "0" :
                                            d.total}}
                                        </h1>
                                        <h1 class="card_sub_title">Total</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm" (click)="getsort(1,'veh')">{{d.ttl_clp === null ? "0" :
                                            d.ttl_clp}}
                                        </h1>
                                        <h1 class="card_sub_title">Primary</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm" (click)="getsort(2,'veh')">
                                            {{d.ttl_non_clp === null ? "0" : d.ttl_non_clp}}
                                        </h1>
                                        <h1 class="card_sub_title">Secondary</h1>
                                    </div>
                                    <div class="col card_prt">
                                        <h1 class="crdHdr_sb_sm" (click)="getsort(null,'veh')">
                                            {{d.othrs === null ? "0" : d.othrs}}
                                        </h1>
                                        <h1 class="card_sub_title">Others</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="main">
    <div class="row">
        <ul class="cards brdr_btm"><span class="hdr_lbl"></span>
            <li class="cards_item1 hdrTxt">Trips Travelled</li>
            <li class="cards_item hdrTxt">Vehicle Status</li>
        </ul>
    </div>
    <div class="row">

        <ul class="cards brdr_btm"><span class="hdr_lbl">
                Total
            </span>
            <li class="cards_item1" *ngIf="rdCnts && rdCnts.length>0">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">All Trips</h1>
                        </div> -->
                        <div class="row ">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(null,'trips')">{{rdCnts[0].ttl_trps ===
                                    null ?
                                    "0" :
                                    rdCnts[0].ttl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(null,'trvl')">{{rdCnts[0].trvl_ttl_trps
                                    ===
                                    null ? "0" :
                                    rdCnts[0].trvl_ttl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Travelled Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(null,'rd')">{{rdCnts[0].ttl_rd_trps ===
                                    null ?
                                    "0" :
                                    rdCnts[0].ttl_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Route Deviated Trips</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of ntStrtdVhcles">
                <div class="card_g" style="background:linear-gradient(135deg, #7f75e5 0%,#4e49b9 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Vehicles Status</h1>
                        </div> -->
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm cls_hglt" (click)="getsort(1,'sts')">{{d.started_veh === null ?
                                    "0" : d.started_veh}}
                                </h1>
                                <h1 class="card_sub_title">Total Started</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(7,'sts')">{{d.early_strt === null ? "0" :
                                    d.early_strt}}
                                </h1>
                                <h1 class="card_sub_title">On Time</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(2,'sts')">
                                    {{d.dly_lt_hlf_hr === null ? "0" : d.dly_lt_hlf_hr}}
                                </h1>
                                <h1 class="card_sub_title">
                                    < 30 mins delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(3,'sts')">
                                    {{d.dly_lt_one_hr === null ? "0" : d.dly_lt_one_hr}}
                                </h1>
                                <h1 class="card_sub_title">30-60 min delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(4,'sts')">
                                    {{d.dly_lt_tw_hr === null ? "0" : d.dly_lt_tw_hr}}
                                </h1>
                                <h1 class="card_sub_title">1-2 hr delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(5,'sts')">
                                    {{d.dly_gt_tw_hr === null ? "0" : d.dly_gt_tw_hr}}
                                </h1>
                                <h1 class="card_sub_title">>2 hr delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(6,'sts')">
                                    {{d.not_started_veh === null ? "0" : d.not_started_veh}}
                                </h1>
                                <h1 class="card_sub_title">At Home location</h1>
                            </div>
                        </div>

                    </div>
                </div>
            </li>
        </ul>
        <ul class="cards brdr_btm"><span class="hdr_lbl">Primary</span>
            <li class="cards_item1" *ngIf="rdCnts && rdCnts.length>0">
                <div class="card_g" style="background:linear-gradient(135deg, #1f694b 0%,#257175 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Primary Trips</h1>
                        </div> -->
                        <div class="row ">


                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(1,'trips')">{{rdCnts[0].prmry_trps ===
                                    null ?
                                    "0" :
                                    rdCnts[0].prmry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(1,'trvl')">{{rdCnts[0].trvl_prmry_trps ===
                                    null ? "0" :
                                    rdCnts[0].trvl_prmry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Travelled Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(1,'rd')">{{rdCnts[0].prmry_rd_trps ===
                                    null ?
                                    "0" :
                                    rdCnts[0].prmry_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Route Deviated Trips</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of ntStrtdVhcles">
                <div class="card_g" style="background:linear-gradient(135deg, #1f694b 0%,#257175 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Vehicles Status (Primary)</h1>
                        </div> -->
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm cls_hglt" (click)="getsort(1,'psts')">{{d.prmry_started_veh ===
                                    null ?
                                    "0" : d.prmry_started_veh}}
                                </h1>
                                <h1 class="card_sub_title">Total Started</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(7,'psts')">{{d.prmry_early_strt === null ? "0"
                                    :
                                    d.prmry_early_strt}}
                                </h1>
                                <h1 class="card_sub_title">On Time</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(2,'psts')">
                                    {{d.prmry_dly_lt_hlf_hr === null ? "0" : d.prmry_dly_lt_hlf_hr}}
                                </h1>
                                <h1 class="card_sub_title">
                                    < 30 mins delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(3,'psts')">
                                    {{d.prmry_dly_lt_one_hr === null ? "0" : d.prmry_dly_lt_one_hr}}
                                </h1>
                                <h1 class="card_sub_title">30-60 min delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(4,'psts')">
                                    {{d.prmry_dly_lt_tw_hr === null ? "0" : d.prmry_dly_lt_tw_hr}}
                                </h1>
                                <h1 class="card_sub_title">1-2 hr delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(5,'psts')">
                                    {{d.prmry_dly_gt_tw_hr === null ? "0" : d.prmry_dly_gt_tw_hr}}
                                </h1>
                                <h1 class="card_sub_title">>2 hr delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(6,'psts')">
                                    {{d.prmry_not_started_veh === null ? "0" : d.prmry_not_started_veh}}
                                </h1>
                                <h1 class="card_sub_title">At Home location</h1>
                            </div>
                        </div>

                    </div>
                </div>
            </li>
        </ul>
        <ul class="cards brdr_btm"><span class="hdr_lbl">secondary</span>
            <li class="cards_item1" *ngIf="rdCnts && rdCnts.length>0">
                <div class="card_g" style="background:linear-gradient(135deg, #557025 0%,#275513 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Secondary Trips</h1>
                        </div> -->
                        <div class="row ">



                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(0,'trips')">{{rdCnts[0].scndry_trps ===
                                    null ?
                                    "0" :
                                    rdCnts[0].scndry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(0,'trvl')">{{rdCnts[0].trvl_scndry_trps
                                    ===
                                    null ? "0" :
                                    rdCnts[0].trvl_scndry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Travelled Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(0,'rd')">{{rdCnts[0].scndry_rd_trps ===
                                    null ?
                                    "0" :
                                    rdCnts[0].scndry_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Route Deviated</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of ntStrtdVhcles">
                <div class="card_g" style="background:linear-gradient(135deg, #557025 0%,#275513 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Vehicles Status (Secondary)</h1>
                        </div> -->
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm cls_hglt" (click)="getsort(1,'ssts')">{{d.scndry_started_veh ===
                                    null ?
                                    "0" : d.scndry_started_veh}}
                                </h1>
                                <h1 class="card_sub_title">Total Started</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(7,'ssts')">{{d.scndry_early_strt === null ?
                                    "0" :
                                    d.scndry_early_strt}}
                                </h1>
                                <h1 class="card_sub_title">On Time</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(2,'ssts')">
                                    {{d.scndry_dly_lt_hlf_hr === null ? "0" : d.scndry_dly_lt_hlf_hr}}
                                </h1>
                                <h1 class="card_sub_title">
                                    < 30 mins delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(3,'ssts')">
                                    {{d.scndry_dly_lt_one_hr === null ? "0" : d.scndry_dly_lt_one_hr}}
                                </h1>
                                <h1 class="card_sub_title">30-60 min delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(4,'ssts')">
                                    {{d.scndry_dly_lt_tw_hr === null ? "0" : d.scndry_dly_lt_tw_hr}}
                                </h1>
                                <h1 class="card_sub_title">1-2 hr delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(5,'ssts')">
                                    {{d.scndry_dly_gt_tw_hr === null ? "0" : d.scndry_dly_gt_tw_hr}}
                                </h1>
                                <h1 class="card_sub_title">>2 hr delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(6,'ssts')">
                                    {{d.scndry_not_started_veh === null ? "0" : d.scndry_not_started_veh}}
                                </h1>
                                <h1 class="card_sub_title">At Home location</h1>
                            </div>
                        </div>

                    </div>
                </div>
            </li>

        </ul>
        <ul class="cards brdr_btm"><span class="hdr_lbl">Other</span>
            <li class="cards_item1" *ngIf="rdCnts && rdCnts.length>0">
                <div class="card_g" style="background:linear-gradient(135deg, #803334 0%,#771c4a 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Other Trips</h1>
                        </div> -->
                        <div class="row ">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(3,'trips')">{{rdCnts[0].othr_trps ===  null ? "0" : rdCnts[0].othr_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(3,'trvl')">{{rdCnts[0].trvl_othr_trps === null ? "0" : rdCnts[0].trvl_othr_trps}}
                                </h1>
                                <h1 class="card_sub_title">Travelled Trips</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getTripSort(3,'rd')">{{rdCnts[0].othr_rd_trps === null ? "0" : rdCnts[0].othr_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Route Deviated Trips</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of ntStrtdVhcles">
                <div class="card_g" style="background: linear-gradient(135deg, #9b26ae 0%,#631a70 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Vehicles Status (Others)</h1>
                        </div> -->
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm cls_hglt" (click)="getsort(1,'osts')">{{d.othr_started_veh ===
                                    null ?
                                    "0" : d.othr_started_veh}}
                                </h1>
                                <h1 class="card_sub_title">Total Started</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(7,'osts')">{{d.othr_early_strt === null ? "0"
                                    :
                                    d.othr_early_strt}}
                                </h1>
                                <h1 class="card_sub_title">On Time</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(2,'osts')">
                                    {{d.othr_dly_lt_hlf_hr === null ? "0" : d.othr_dly_lt_hlf_hr}}
                                </h1>
                                <h1 class="card_sub_title">
                                    < 30 mins delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(3,'osts')">
                                    {{d.othr_dly_lt_one_hr === null ? "0" : d.othr_dly_lt_one_hr}}
                                </h1>
                                <h1 class="card_sub_title">30-60 min delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(4,'osts')">
                                    {{d.othr_dly_lt_tw_hr === null ? "0" : d.othr_dly_lt_tw_hr}}
                                </h1>
                                <h1 class="card_sub_title">1-2 hr delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(5,'osts')">
                                    {{d.othr_dly_gt_tw_hr === null ? "0" : d.othr_dly_gt_tw_hr}}
                                </h1>
                                <h1 class="card_sub_title">>2 hr delay</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(6,'osts')">
                                    {{d.othr_not_started_veh === null ? "0" : d.othr_not_started_veh}}
                                </h1>
                                <h1 class="card_sub_title">At Home location</h1>
                            </div>
                        </div>

                    </div>
                </div>
            </li>

        </ul>

    </div>

    <div class="row bg-wp" *ngIf="showVhcles">
        <h1>Vehicle Details ({{tripsTrvldDta.length}})</h1>

        <div class="ds-card grid tbl">
            <dx-data-grid class="p-8" [dataSource]="tripsTrvldDta" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="true" (onToolbarPreparing)="true"
                (onCellPrepared)="onCellPrepared(tripsTrvldcolumnDefs,$event)"
                (onCellClick)="devCellclk(tripsTrvldcolumnDefs,$event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="tripsTrvldDta.length>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Trips Travelled
               Data"></dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of tripsTrvldcolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoPlaybck(data)" *ngIf="data">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>Playback</p>
                        </div>
                    </div>
                </dxi-column>
                <dxo-summary>
                    <dxi-group-item column="asrt_nm" name="Total Vehicles" summaryType="count" [alignByColumn]="true"
                        [showInGroupFooter]="true"></dxi-group-item>
                    <dxi-group-item column="trp_sqnc_nu" name="Total Trips" summaryType="count" [alignByColumn]="true"
                        [showInGroupFooter]="true">
                    </dxi-group-item>
                    <dxi-group-item column="actl_trvl_dstne_ct" name="Total Distance" summaryType="sum"
                        [alignByColumn]="true" [showInGroupFooter]="true"></dxi-group-item>
                    <dxi-group-item column="actl_trvl_tm" name="Total Hours" summaryType="sum" [alignByColumn]="true"
                        [showInGroupFooter]="true">
                    </dxi-group-item>
                </dxo-summary>
            </dx-data-grid>
        </div>
    </div>
    <div class="row bg-wp" *ngIf="!showVhcles">
        <h1>Trip Details ({{vehTrpRteLst.length}})</h1>

        <div class="ds-card grid tbl">
            <dx-data-grid class="p-8" [dataSource]="vehTrpRteLst" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="true" (onToolbarPreparing)="true"
                (onCellPrepared)="onCellPrepared2(tripsTrvldRoutecolumnDefs,$event)"
                (onCellClick)="devCellclk2(tripsTrvldRoutecolumnDefs,$event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="vehTrpRteLst.length>10" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Trips Travelled
                   Data"></dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of tripsTrvldRoutecolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>
</div>

<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="trpSldrvisible" nzPlacement="right"
    nzTitle="Trip Dashboard" (nzOnClose)="trpSldrvisible = false" class="dashbSlider">
    <ng-container *nzDrawerContent>
    <app-trips-slider [trip]="selectedTrip" *ngIf="selectedTrip"></app-trips-slider>
    </ng-container>
</nz-drawer>
<nz-drawer [nzWidth]="'90%'" [nzClosable]="true" [nzVisible]="visibleDriverSldr" nzPlacement="right"
    nzTitle="Driver Dashboard" (nzOnClose)="visibleDriverSldr = false" class="dashbSlider">
    <ng-container *nzDrawerContent>
    <app-driver-slider [driver]="selectedDriver" *ngIf="selectedDriver"></app-driver-slider>
    </ng-container>
</nz-drawer>
<nz-drawer [nzWidth]="'80%'" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" nzTitle="Vehicle Dashboard"
    (nzOnClose)="visible = false" class="dashbSlider">
    <ng-container *nzDrawerContent>
    <app-vehicle-slider [vechile]="selectedVehicle" *ngIf="selectedVehicle"></app-vehicle-slider>
    </ng-container>
</nz-drawer>