import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-assign-device',
  templateUrl: './assign-device.component.html',
  styleUrls: ['./assign-device.component.scss']
})
export class AssignDeviceComponent implements OnInit {
  clntDtls: any;
  tntLst: any = [];
  selectedtenant: any;
  vendorList: any = [];
  selectedvndr: any;
  tableSummary: any = [];
  columnDefs: any = [];
  slctTntDv: boolean;
  asrt_id: any;
  vehicleDta: any;
  opensldr: boolean;
  dvceTypeLst: any;
  dvceCtgryLst: any;
  devicedata: any = {};
  sidebartitle: string;
  dvceData: any = [];
  showAdd: boolean = false;
  dvceId: any;
  imei_nu: any;
  vehcleTble: any=[];
  vehsliderData: any;
  dvce_id: any;
  userDtls: any;
  clap_in: any;
  ShowClap: boolean;
  showVndr: boolean;
  showLdr: boolean;
  constructor(private apiSrvc: AppsService, private message: NzMessageService,) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.clntDtls)
    this.tntLst = this.clntDtls[0].tnts;
    if (this.tntLst.length < 2) {
      this.slctTntDv = false;
      this.selectedtenant = this.tntLst[0].tnt_id;
    } else {
      this.slctTntDv = true;
      this.selectedtenant = this.tntLst[0].tnt_id;
    }
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
      // this.selectedvndr = 0;
    }else{
      this.selectedvndr=0;
    }
  }

  ngOnInit(): void {
    this.getVendors();
    this.getvehicles();
    this.getdvcetypeCtgry();
    this.getDevces();
    // this.getAsgnedVehiclesList();

  }
  // getVendors() {
  //   console.log(this.userDtls.dsgns_id)
  //   if (this.userDtls.dsgns_id == 1000032) {
  //     this.selectedvndr = this.userDtls.vndr_id;
  //     this.showVndr = true;
  //     if (this.selectedvndr && this.showVndr) {
  //       this.apiSrvc.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
  //         // console.log(res)
  //         if (res['status'] == 200) {
  //           this.vendorList = res['data'];
  //         }
  //       }, (err) => {
  //         // console.log("In errorr");
  //       })
  //     }
  //   } else if (this.clap_in == 0) {
  //     this.vendorList = [];
  //     this.selectedvndr = 0;
  //     // this.getAsgnedVehiclesList();

  //   } else {
  //     this.apiSrvc.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
  //       if (res['status'] === 200) {
  //         this.vendorList = res['data'];
  //         // this.USER_PERMISSIONS = res['perm'][0];
  //         this.selectedvndr = this.vendorList[0].vndr_id;
  //         this.getAsgnedVehiclesList();

  //       }
  //     }, (err) => {
  //       // // console.log('In errorr');
  //     });
  //   }
  // }
  getVendors() {
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      if (this.selectedvndr && this.showVndr) {
        this.apiSrvc.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
          if (res['status'] == 200) {
            this.vendorList = res['data'];
            this.selectedvndr = this.vendorList[0].vndr_id;
          this.getvehicles();
          }
        }, (err) => {
        })
      }
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
      this.selectedvndr = 0;
      this.getvehicles();

    } else {
      this.apiSrvc.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          this.selectedvndr = this.vendorList[0].vndr_id;
          this.getvehicles();
        }
      }, (err) => {
      });
    }
  }
  ontntSelect(value) {
    this.selectedtenant = value;
    this.getVendors();
    this.getvehicles();
    this.getDevces();
    // this.getAsgnedVehiclesList();

  }
  onvndrSelect(value) {
    this.selectedvndr = value;
    console.log(value)
    this.getvehicles();
    this.getDevces();
    // this.getAsgnedVehiclesList();


  }
  onvehicleselect(value) {
    this.asrt_id = value;
  }
  getvehicles() {
    var fltrData = [{
      selectedtenant: this.selectedtenant,
      selectedvndr: this.selectedvndr,
      selectedctgry: 0
    }]
    this.showLdr = true;
    this.apiSrvc.post(fltrData, 'drivers/vehicles/asgndvehicles').subscribe((res) => {
      if (res['status'] === 200) {
        this.showLdr=false;
        this.vehicleDta = res['data'];
        this.vehcleTble=res['data'];
        this.columnDefs = [
                  { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
                  { headerName: 'ULB Name', field: 'tnt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
                  { headerName: 'Vehicle Name', field: 'asrt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
                  { headerName: 'Vehicle Vendor', field: 'vndr_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
                  { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
                  { headerName: 'Device IMEI Number', field: 'dev_imei_nu', alignment: 'left', width: 160, filter: true, sortable: true, },
        
                ]
        console.log(this.vehicleDta)

      }
    });
  }
  getDevces() {
    this.apiSrvc.get(`drivers/vehicles/devices/${this.selectedtenant}`).subscribe((res) => {
      if (res['status'] === 200) {
        this.dvceData = res['data'];
        console.log(this.dvceData)
      }
    }, (err) => {
      console.log('In errorr');
    });
  }
  openSlider(opt,data) {
    this.opensldr = true;
    if (opt == 'add') {
      this.sidebartitle = 'Add Device';
      this.showAdd = true;
    } else {
      this.sidebartitle = 'Assign Device';
      this.showAdd = false;
      this.vehsliderData=data.data;
      console.log(this.vehsliderData)
      this.asrt_id=this.vehsliderData.asrt_id;
      this.dvce_id=this.vehsliderData.dvce_id;
    }
  }
  closeSideBar() {
    this.opensldr = false;
    this.devicedata = {};
    this.vehsliderData={};
  }
  getdvcetypeCtgry() {
    this.apiSrvc.get(`drivers/vehicle/device/types/categories/list`).subscribe((res) => {
      if (res['status'] === 200) {
        this.dvceTypeLst = res['data'].dvceTypelst;
        this.dvceCtgryLst = res['data'].dvceCtgrylst;
        console.log(res)
      }
    }, (err) => {
    });
  }
  SubmitDetails() {
    var postData = {
      dvce_nm: this.devicedata.dvce_nm,
      dvce_type_id: this.devicedata.dvce_type_id,
      dvce_ctgry_id: this.devicedata.dvce_ctgry_id,
      imei_nu: this.devicedata.imei_nu,
      mble_nu: this.devicedata.mble_nu,
      tnt_id: this.selectedtenant
    }
    this.apiSrvc.post(postData, `drivers/vehicles/Master/Device/update`).subscribe((res) => {
      console.log(res);
      if (res['status'] == 200) {
        this.createMessage("Success", "Device Added Sucessfully");
        this.getDevces();
      }
    }, (err) => {
    });

  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  ondvceselect(val) {
    this.dvceId = val;
    for (var i = 0; i < this.dvceData.length; i++) {
      if (this.dvceData[i].dvce_id == this.dvceId) {
        this.imei_nu = this.dvceData[i].imei_nu
      }
    }
  }
  assignDevice() {
    var postData = {
      dvce_id: this.dvceId,
      asrt_id: this.asrt_id,
      tnt_id: this.selectedtenant,
      imei_nu: this.imei_nu
    }
    console.log(postData)
    this.apiSrvc.post(postData, `drivers/vehicles/Master/Device/assign`).subscribe((res) => {
      console.log(res);
      if (res['status'] == 200 && res['data'].cmntx == 'asgnd') {
        this.createMessage("Success", "Device Assigned to a new vehicle Sucessfully");
      } else {
        this.createMessage("Success", "Device Assigned Sucessfully");
      }
      this.getvehicles();
    }, (err) => {
    });
  }

  // getAsgnedVehiclesList(){
  //   this.apiSrvc.get(`drivers/vehicles/master/asgnedList/${this.selectedvndr}/${this.selectedtenant}`).subscribe((res) => {
  //     if (res['status'] === 200) {
  //       this.vehcleTble=res['data'];
  //       this.columnDefs = [
  //         { headerName: 'Sno', field: 'sno', alignment: 'center', width: 50, filter: false },
  //         { headerName: 'ULB Name', field: 'tnt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
  //         { headerName: 'Vehicle Name', field: 'asrt_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
  //         { headerName: 'Vehicle Vendor', field: 'vndr_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
  //         { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', alignment: 'left', width: 160, filter: true, sortable: true, },
  //         { headerName: 'Device IMEI Number', field: 'dev_imei_nu', alignment: 'left', width: 160, filter: true, sortable: true, },

  //       ]
  //     }
  //   }, (err) => {
  //   });
  // }
}
