import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuardService  {
  usrDtls: any;

  constructor(public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    if (this.usrDtls && this.usrDtls.usr_id) {
      return true;
    }
    else {
      this.router.navigate(['/internal']);
      return false;
    }
  }
}