<ds-loading *ngIf="showLdr"></ds-loading>


<div class="row hdrBar">
    <div class="col-lg-4 col-md-4 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0 && clntDtls[0].clnt_id==7">Trip Wise Coverage Status
            <span *ngIf="selectedtenantNm" class="tntTxt">-{{selectedtenantNm}}-{{selectedSvm}}</span>
        </h1>
    </div>
    <div class="col-lg-3 col-md-3 mg-a">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
    <div class="col-lg-3 col-md-3 mg-a" *ngIf="vehclesLst.length>1">
        <span>Select Vehicle:</span>
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select Vehicle"
            (ngModelChange)="onasrtgetSelected($event)" [(ngModel)]="selectedVehicle">
            <nz-option *ngFor="let a of vehclesLst" [nzValue]="a.asrt_id" [nzLabel]="a.asrt_nm">
            </nz-option>
        </nz-select>
    </div>
    <div class="col-lg-3 col-md-3 mg-a" *ngIf="vehclesLst.length==1">
        <span>Selected Vehicle:</span>
        <span class="disbledtxt">{{vehclesLst[0].asrt_nm}}</span>
    </div>

    <div class="col-lg-2 col-md-2 mg-a">
        <button nz-button nzType="default" (click)="goBack()" style="    box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
        color: #ffffff;
        font-weight: 500;
        background: #ff7272;
        float: right;
        border-radius: 4px;"><span style="color:#fff"><i nz-icon nzType="left"></i></span> <span style="color:#fff">Go back</span></button>
    </div>
</div>
<!-- <div class="main">
    <div class="row">
        <ul class="cards">
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Total Vehicles</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.TOT_VEHICLES === null ? "0" : d.TOT_VEHICLES}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #bfc355 0%,#858822 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Operated Trips/Total Trips</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.OPERATED === null ? "0" : d.OPERATED}} / {{d.TOTAL_TRIPS
                                    === null ? "0" : d.TOTAL_TRIPS}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Coverage %</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.coverage_per === null ? 0 : d.coverage_per}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div> -->

<div class="main">
    <div class="row">
        <ul class="cards">
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Total Vehicles</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.TOT_VEHICLES === null ? "0" : d.TOT_VEHICLES}}
                                </h1>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/pick-up-truck.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                                <div class="cvrg_crd_cnt">{{d.TOT_VEHICLES === null ? "0" : d.TOT_VEHICLES}}</div>
                                <div class="cvrg_crd_lbl">Total Vehicles</div>
                            </div>
                            <!-- <div class="col-md-3 rgtpdng">
                                <div class="cvrg_crd_img">
                                    <img src="/assets/images/vehicle.png">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="    background: linear-gradient(135deg, #6b3a0b 0%,#ab5d4a 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Operated Trips/Total Trips</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.OPERATED === null ? "0" : d.OPERATED}} / {{d.TOTAL_TRIPS === null ? "0" : d.TOTAL_TRIPS}}
                                </h1>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/routes.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #8e4d2d;"></div>
                                <div class="cvrg_crd_cnt">{{d.OPERATED === null ? "0" : d.OPERATED}} / {{d.TOTAL_TRIPS
                                    === null ? "0" : d.TOTAL_TRIPS}}<span style="font-size: 12px;"> ({{d.op_per}}
                                        %)</span></div>
                                <div class="cvrg_crd_lbl">Operated Trips/Total Trips</div>
                            </div>
                            <!-- <div class="col-md-3 rgtpdng">
                                <div class="cvrg_crd_img">
                                    <img src="/assets/images/trip.png">
                                </div>
                            </div> -->
                        </div>


                    </div>
                </div>
            </li>

            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="   background: linear-gradient(135deg, #665f03 0%,#817e26 100%);">
                    <div class="card_content">


                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/rout.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #6c660b;"></div>
                                <div class="cvrg_crd_cnt">{{d.ROUTE_DEVIATED === null ? "0" : d.ROUTE_DEVIATED}} </div>
                                <div class="cvrg_crd_lbl">Route Deviated</div>
                            </div>

                        </div>


                    </div>
                </div>
            </li>


            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Coverage %</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.coverage_per === null ? 0 : d.coverage_per}}
                                </h1>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/discount.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #28816b;"></div>
                                <div class="cvrg_crd_cnt"> {{d.coverage_per === null ? 0 : d.coverage_per}}</div>
                                <div class="cvrg_crd_lbl">Coverage %</div>
                            </div>
                            <!-- <div class="col-md-3 rgtpdng">
                                <div class="cvrg_crd_img">
                                    <img src="/assets/images/percentage.png" style="padding:20px">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>

            <li class="cards_item" >
                <div class="card_g" style="background:linear-gradient(135deg, #477085 0%,#34587a 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Total Vehicles</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.TOT_VEHICLES === null ? "0" : d.TOT_VEHICLES}}
                                </h1>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/bus.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                                <div class="cvrg_crd_cnt">{{dstCnt === null ? "0" : dstCnt}} Km</div>
                                <div class="cvrg_crd_lbl">Total Distance</div>
                            </div>
                            <!-- <div class="col-md-3 rgtpdng">
                                <div class="cvrg_crd_img">
                                    <img src="/assets/images/vehicle.png">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>

<div class="dxgridpdng svmcvrgedshbrd" style="padding-top:0px">
    <h1 class="bdycntnthdr ">Ward Secretariat wise Trip Coverage Status</h1>
    <!-- <div class=" tbl grid "> -->

    <dx-data-grid class="p-8" id="gridContainer" [dataSource]="tripsData" [remoteOperations]="false"
        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%" [wordWrapEnabled]="false"
        (onToolbarPreparing)="true" [columnAutoWidth]="true" (onExporting)="onExporting($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [visible]="tripsData.length>10" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
            [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
        </dxo-search-panel>
        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="Ward Secretariat wise Trip Coverage Status">
        </dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <!-- <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
            cellTemplate="editTemplate">
            <div *dxTemplate="let data of 'editTemplate'">
                <div class="tblEdtBtn" (click)="getPlayback(data)">
                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                    <p>Playback</p>
                </div>
            </div>
        </dxi-column> -->
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
            [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
        </dxi-column>
    </dx-data-grid>
    <!-- </div> -->
</div>
<div #pdfContent>
    <div class="row bg-wp maphgt">
        <div class="col-lg-12 col-md-12">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <label nz-checkbox [(ngModel)]="shwCvrge" (ngModelChange)="onChkCoverage($event)">Coverage</label>
                    <label nz-checkbox [(ngModel)]="shwplyback" (ngModelChange)="onChkPlyback($event)">Playback</label>
                </div>
                <!-- <span nz-icon nzType="check-square" nzTheme="outline" *ngIf="shwCvrge" class="chkdCls"></span> -->
                <!-- <div class="col-md-2 col-lg-2" *ngIf="!shwCvrge">
                    <button nz-button nzType="default" class="plyBckBtn" (click)="getPlayback()">Playback</button>
                </div> -->
                <div class="col-lg-4 col-md-4">
                    <h1 class="bdycntnthdr " *ngIf="shwCvrge">Coverage of {{slctdVehNm}}</h1>
                    <h1 class="bdycntnthdr " *ngIf="!shwCvrge">Playback of {{slctdVehNm}}</h1>
                </div>
                
                <div class="col-lg-4 col-md-4">
                    <div class="mapdslctnDtls" *ngIf="shwCvrge">
                        <div class="travelruts">
                            <div class="grenbx"></div>
                            <div class="redbxlbl">Covered Area</div>
                        </div>
                        <div class="scheduleruts">
                            <div class="redbx"></div>
                            <div class="redbxlbl"> Not Covered Area</div>
                        </div>
                    </div>
                    <div *ngIf="!shwCvrge">
                        <div class="mapdslctnDtls">
                            <div class="travelruts">
                                <div class="grenbx"></div>
                                <div class="redbxlbl">Schedule Route</div>
                            </div>
                            <div class="scheduleruts">
                                <div class="redbx"></div>
                                <div class="redbxlbl"> Travelled Route</div>
                            </div>
        
                            <div class="trvlruts">
                                <!-- <span style="color: #ae1d1d;
                                font-size: 1.5rem;" 
                                nz-icon nzType="file-pdf" nzTheme="outline" (click)="downloadPDF()"></span> -->
                            </div>
                        </div>
                        <!-- <div class="row" *ngIf="eachTrpData">
                            <table id="tripTble">
                                <tr>
                                    <th>Sno</th>
                                    <th>Trip</th>
                                    <th>Source</th>
                                    <th>Destination</th>
                                    <th>Playback Start Time</th>
                                    <th>Playback End Time</th>
        
                                </tr>
                                <tr>
                                    <td>{{eachTrpData.sno}}</td>
                                    <td>TRIP - {{eachTrpData.TRIP_NUM}}</td>
                                    <td>{{eachTrpData.SOURCE}}</td>
                                    <td>{{eachTrpData.DESTINATION}}</td>
                                    <td>{{eachTrpData.plyBk_strt_ts}}</td>
                                    <td>{{eachTrpData.plyBk_end_ts}}</td>
                                </tr>
                            </table>
                        </div> -->
                </div>
                </div>
                
            </div>
            
            

            <div id="map"></div>
        </div>
    </div>
</div>