
<div class="page-header">
    <div class=" align-items-md-center">
<div class="row hdrBar">
    <div class="col-lg-6 col-md-6 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0" >
            <app-common-header [header_name] ="header_label" ></app-common-header>
        </h1>
    </div>
    <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;" *ngIf="tntLst.length>1">
        <span>Select ULB:</span>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
        [ngModelOptions]="{standalone: true}">
        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
        <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
    </nz-select>


    </div>

    <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;" *ngIf="tntLst && tntLst.length==1">
        <span>ULB:</span>
        <span class="disbledtxt" style="background: #fff;">{{tntLst[0].tnt_nm}}</span>
    </div>
</div>
</div>
</div>










<!-- <div class="fltrbg">
    <div class="row">

        <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst.length>1">
            <p class="slctlbl">ULB</p>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
                [ngModelOptions]="{standalone: true}">
                <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
            </nz-select>
        </div>
        <div class="col-lg-2 col-md-2 crdCl" *ngIf="tntLst && tntLst.length==1">
            <p class="slctlbl">ULB</p>
            <p class="slctlbl dsblTxt">{{tntLst[0].tnt_nm}}</p>
        </div>
    </div>
</div> -->
<div style="padding: 0px 10px;">

    <div class="ds-card grid tbl dwntbl vhclsCmpnt">
        <dx-data-grid class="p-8" [dataSource]="vhcleslst" [remoteOperations]="false" [allowColumnReordering]="true"
            [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true"
            [showBorders]="true" style="width:100%" (onToolbarPreparing)="true" (onExporting)="onExporting($event)">
            <dxo-paging [pageSize]="10" [enabled]="true"></dxo-paging>
            <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                [showInfo]="true"></dxo-pager>

            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-filter-panel [visible]="false"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Sachivalayam assigned Vehicles Data">
            </dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxi-column *ngFor="let v of vhclslstcolumnDefs" [visible]="!v.hide" [dataField]="v.field"
                [caption]="v.headerName" [alignment]="v.alignment" [width]="v.width" [allowResizing]="true">
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="150" caption="change Sachivalayam"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="openSlider(data);"><i nz-icon nzType="car" nzTheme="outline"></i>
                    </div>
                </div>
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="150" caption="remove Sachivalayam"
                cellTemplate="editTemplate2">
                <div *dxTemplate="let data of 'editTemplate2'">
                    <div class="tblEdtBtn" (click)="deletePopup(data);"><i nz-icon nzType="car" nzTheme="outline"></i>
                    </div>
                </div>
            </dxi-column>
        </dx-data-grid>
    </div>
</div>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="400" [nzVisible]="opensldr"
    nzTitle="Change Sachivalayam" (nzOnClose)="closeSideBar()">
    <ng-container *nzDrawerContent>
    <form nz-form class="drvrDtlsfrm">
        <div nz-row [nzGutter]="8">
            <div nz-col nzSpan="24">
                <nz-form-label>Vehicle</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehicle" [(ngModel)]="asrt_id"
                      [ngModelOptions]="{standalone: true}" nzDisabled>
                      <nz-option *ngFor="let v of asrtList" [nzLabel]=v.asrt_nm [nzValue]="v.asrt_id"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            <div nz-col nzSpan="24">
                <nz-form-label>Sachivalayam</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select class="slctBx" nzShowSearch [(ngModel)]="svm_id"
                            (ngModelChange)="onsvmSelect($event)" [ngModelOptions]="{standalone: true}"
                            nzPlaceHolder="Select Sachivalayam to be changed">
                            <nz-option *ngFor="let s of svmLst" [nzValue]="s.svm_id" [nzLabel]="s.svm_nm"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
    
        </div>
    </form>

    <div class="btngrp">
        <button nz-button style="margin-right: 8px;" (click)="closeSideBar()" nzType="primary" nzDanger>Cancel</button>
        <button nz-button (click)="saveasgnmnt()" class="sbmtbtn">Change Sachivalayam</button>
    </div>
    </ng-container>
</nz-drawer>