import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-add-vechile',
  templateUrl: './add-vechile.component.html',
  styleUrls: ['./add-vechile.component.scss']
})
export class AddVechileComponent implements OnInit {
  vechile_lst: any;
  clnts: any;
  addVchleFrm = false;
  addVhcldtlsform: FormGroup;
  selectedclnt;
  selectedTnt
  tnts: any;
  columnDefs: any;
  mstrDta: any;
  sdbarHdr: string;
  isCheckedButton = false
  editform: boolean;
  asrt_id: any;
  asrt_ctgry_nm: any;
  asrt_grp_nm: any;
  dvceTypeLst: any;
  dvceCtgryLst: any;
  mble_nu: any
  dvcimei_nu: any
  dvce_ctgry_id: any
  dvce_type_id: any
  dvce_nm: any
  addDvceFrm = false;
  addvechleBtn = false;
  crnt_dvce_id: any;
  vchlDtaBtn = false;
  checked = false;
  igntn_in = 0;
  usdImei = false;
  tnt_nm: any;
  asrt_nm: any;
  sim_nu: any;
  dvcImei: any;
  unAsgnVeh: any;
  clnt_dtls: any;
  tenant: any;
  cndtn: boolean;
  edtUlb: any;
  slct_clnt;
  auto;
  constructor(public apiServ: AppsService, private modal: NzModalService, private message: NzMessageService, private formBuilder: FormBuilder) {
    this.clnt_dtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.selectedclnt = this.clnt_dtls[0].clnt_id
    this.tnts = this.clnt_dtls[0].tnts
    console.log(this.clnt_dtls,)
  }

  ngOnInit(): void {
    this.getCLnt();
    this.getdvcetypeCtgry()
    this.addVhcldtlsform = new FormGroup({
      clnt: new FormControl('', [Validators.required]),
      tnt: new FormControl('', [Validators.required]),
      veh_nu: new FormControl('', [Validators.required]),
      imei_nu: new FormControl('', [Validators.required]),
      veh_ctgry: new FormControl('', [Validators.required]),
      veh_grp: new FormControl('', [Validators.required]),
      veh_sts: new FormControl('', [Validators.required]),
      icn_pth: new FormControl('', [Validators.required]),
      veh_typ: new FormControl('', [Validators.required]),
      hme_fnce: new FormControl(''),
      ULB: new FormControl('')
    })
  }

  getVechiles() {
    this.apiServ.get(`drivers/add/Vehcile/${this.selectedclnt}/${this.selectedTnt}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.vechile_lst = res['data'];
        console.log(this.vechile_lst);
      }
    }
      , (err) => {
        console.log(err)
      });
    this.columnDefs = [
      { headerName: 'S.No', field: 'sno', width: 80 },

      { headerName: 'Tenant Name', field: 'tnt_nm', hide: false },
      { headerName: 'Vehicle', field: 'asrt_nm', hide: false },
      { headerName: 'IMEI Number', field: 'dev_imei_nu', hide: false },
      { headerName: 'Vehicle Category', field: 'asrt_ctgry_nm', hide: false },
      { headerName: 'Vehicle Group', field: 'asrt_grp_nm', hide: false },
    ]
  }
  getCLnt() {
    this.apiServ.get(`drivers/add/clntdtls`).subscribe((res) => {
      if (res['status'] == 200) {
        this.clnts = res['data']
      }
    }, (err) => {
      console.log(err)
    })
  }
  onToolbarPreparing(e) {
    console.log(e)
    e.toolbarOptions.items.unshift(

      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'plus',
          text: 'Add New Device',
          onClick: this.addDvce.bind(this, 'new'),
          bindingOptions: {
            'disabled': 'isEmailButtonDisabled',
          }
        }
      }, {
      location: 'after',
      widget: 'dxButton',

      options: {
        icon: 'plus',
        text: 'Add New Vehcile',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    },

    )

  }
  addDvce(param, val) {
    console.log(param, val)
    if (this.addvechleBtn) {
      this.addDvceFrm = true
    }
    else {
      this.createMessage('Error ', 'Please Select Client and Tenant')
    }

  }
  openSideBar(edt, val) {
    this.edtUlb=null;
    this.getMstrDta()
    console.log(edt, val)
    if (this.addvechleBtn) {
      if (edt === 'new') {
        this.editform = false
        this.sdbarHdr = 'Add New Vechile'
        this.addVchleFrm = true
        this.addVhcldtlsform.get('veh_nu').setValue('');
        this.addVhcldtlsform.get('imei_nu').setValue('');
        this.addVhcldtlsform.get('veh_ctgry').setValue('');
        this.addVhcldtlsform.get('veh_grp').setValue('');
        this.addVhcldtlsform.get('veh_sts').setValue('');
        this.addVhcldtlsform.get('icn_pth').setValue('');
        this.addVhcldtlsform.get('veh_typ').setValue('');
        this.addVhcldtlsform.get('hme_fnce').setValue('');
      }
      else if (edt === 'Edit') {
        this.checked = val.igntn_cnct_in == 1 ? true : false
        this.sdbarHdr = 'Edit Vechile'
        this.addVchleFrm = true;
        this.editform = true
        this.asrt_id = val.asrt_id
        this.asrt_ctgry_nm = val.asrt_ctgry_nm;
        this.asrt_grp_nm = val.asrt_grp_nm;
        this.dvcImei=val.dev_imei_nu
        this.addVhcldtlsform.get('veh_nu').setValue(val.asrt_nm);
        this.addVhcldtlsform.get('imei_nu').setValue(val.dvce_id);
        this.addVhcldtlsform.get('veh_ctgry').setValue(val.asrt_ctgry_id);
        this.addVhcldtlsform.get('veh_grp').setValue(val.asrt_grp_id);
        this.addVhcldtlsform.get('veh_sts').setValue(val.asrt_sts_id);
        this.addVhcldtlsform.get('ULB').setValue(val.tnt_id)
        this.addVhcldtlsform.get('icn_pth').setValue(val.icon_path);
        this.addVhcldtlsform.get('hme_fnce').setValue(val.hme_fnce_id);
        this.addVhcldtlsform.get('veh_typ').setValue(`${val.clap_in}`);
      }
    }
    else {
      this.createMessage('Error ', 'Please Select Client and Tenant')
    }


  }
  close() {
    this.addVchleFrm = false
    // this.addvechleBtn=false
    this.usdImei = false
  }
  // slct_clnt(value) {
  //   console.log(value)
  //   this.selectedclnt = value;
  //   this.getTnts();
  //   if (this.addvechleBtn) {
  //     this.getVechiles();
  //   }

  // }
  slct_tnt(val) {
    console.log(val, this.addvechleBtn);
    this.selectedTnt = val;
    this.vchlDtaBtn = true
    if (this.addvechleBtn) {

      this.getVechiles();
    }
    this.getMstrDta();


  }
  // getTnts() {
  //   console.log('kkkkk')
  //   this.apiServ.get(`drivers/add/tntDtls/${this.selectedclnt}`).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       this.tnts = res['data'];
  //       console.log(this.tnts)
  //     }
  //   }, (err) => {
  //     console.log(err)
  //   })
  // }
  getAsrtDtls() {
    console.log(this.selectedTnt, this.selectedclnt)
    this.getVechiles();
    this.addvechleBtn = true


  }

  showDeleteConfirm(data): void {
    console.log(data)
    this.modal.confirm({
      nzTitle: 'Are you sure delete this vehicle?',
      nzContent: `<b style="color: red;">${data.asrt_nm}</b>`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.delAsrt(data.asrt_id),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }
  getMstrDta() {
    this.apiServ.get(`drivers/add/masterData/${this.selectedclnt}/${this.edtUlb?this.edtUlb:this.selectedTnt}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.mstrDta = res['data'];
        console.log(this.mstrDta)
      }
    }, (err) => {
      console.log(err)
    })
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  delAsrt(asrt) {
    console.log(asrt)
    let postData = {
      asrt_id: asrt
    }
    this.apiServ.post(postData, `drivers/del/vechile`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Vehicle successfully Deleted');
      }
      this.getVechiles();
    })
  }
  addVechile() {
    console.log(this.editform)
    if (this.addVhcldtlsform.valid) {
      if (this.editform) {
        var postData = {
          asrt_id: this.asrt_id,
          asrt_ctgry_nm: this.asrt_ctgry_nm,
          asrt_grp_nm: this.asrt_grp_nm,
          clnt_id: this.addVhcldtlsform.value.clnt,
          tnt_id: this.addVhcldtlsform.value.tnt,
          asrt_nm: this.addVhcldtlsform.value.veh_nu,
          clap_in: this.addVhcldtlsform.value.veh_typ,
          dev_imei_nu: this.dvcImei,
          crnt_dvce_id:this.addVhcldtlsform.value.imei_nu,
          igntn_cnct_in: this.igntn_in,
          asrt_ctgry_id: this.addVhcldtlsform.value.veh_ctgry,
          asrt_grp_id: this.addVhcldtlsform.value.veh_grp,
          asrt_sts_id: this.addVhcldtlsform.value.veh_sts,
          icon_path: this.addVhcldtlsform.value.icn_pth,
          hme_fnce_id: this.addVhcldtlsform.value.hme_fnce,
          edit_ulb:this.edtUlb
        }
        console.log(postData)
        this.apiServ.post(postData, `drivers/Update/vechile/data`).subscribe((res) => {
          if (res['status'] == 200) {
            this.createMessage('Success', 'Vehicle successfully Edited');
          }
          this.getVechiles();
          this.close()
        }, (err) => {
          console.log(err)
        })

      }
      else {
        var data = {
          crnt_dvce_id: this.crnt_dvce_id,
          asrt_ctgry_nm: this.asrt_ctgry_nm,
          asrt_grp_nm: this.asrt_grp_nm,
          clnt_id: this.addVhcldtlsform.value.clnt,
          tnt_id: this.addVhcldtlsform.value.tnt,
          asrt_nm: this.addVhcldtlsform.value.veh_nu,
          clap_in: this.addVhcldtlsform.value.veh_typ,
          dev_imei_nu: this.dvcImei,
          igntn_cnct_in: this.igntn_in,
          asrt_ctgry_id: this.addVhcldtlsform.value.veh_ctgry,
          asrt_grp_id: this.addVhcldtlsform.value.veh_grp,
          asrt_sts_id: this.addVhcldtlsform.value.veh_sts,
          icon_path: this.addVhcldtlsform.value.icn_pth,
          hme_fnce_id: this.addVhcldtlsform.value.hme_fnce
        }
        console.log(data)
        this.apiServ.post(data, `drivers/add/NewVechileToDB`).subscribe((res) => {
          if (res['status'] == 200) {
            this.createMessage('Success', 'Vehicle successfully Added');
            this.close();
            this.getVechiles();

          }
        }, (err) => {
          this.createMessage('Error!', `${err}`)
          console.log(err)
        })

      }
    }
    else {
      this.createMessage("Error", "Please enter all the mandatory fields")
    }

  }
  ctgrtSlct(e) {
    console.log(e);
    this.mstrDta.ctgry.forEach((val) => {
      if (val.asrt_ctgry_id == e) {
        this.asrt_ctgry_nm = val.asrt_ctgry_nm
        console.log(this.asrt_ctgry_nm)
      }
    })
  }
  grpSlct(grp) {
    this.mstrDta.Groups.forEach((val) => {
      if (val.asrt_grp_id == grp) {
        this.asrt_grp_nm = val.asrt_grp_nm
        console.log(this.asrt_grp_nm)
      }
    })
  }
  ImeiSlct(im) {
    this.cndtn = false
    this.mstrDta.Imei.forEach((val) => {
      if (val.dvce_id == im) {
        this.dvcImei = val.imei_nu
        this.crnt_dvce_id = im
      }
    })
    console.log(this.dvcImei)
    this.apiServ.get(`drivers/check/Imei/${this.dvcImei}`).subscribe((res) => {
      if (res['data'].length == 0) {
        // this.mstrDta.Imei.forEach((val) => {
        //   if (val.imei_nu == im) {
        //     this.crnt_dvce_id = val.dvce_id
        //     console.log(this.crnt_dvce_id)

        //   }
        // })
        this.usdImei = false
      }
      else {
        this.tenant = res['data'][0].tnt_id
        this.tnt_nm = res['data'][0].tnt_nm;
        this.asrt_nm = res['data'][0].asrt_nm;
        this.unAsgnVeh = res['data'][0].asrt_id
        console.log(res['data'], this.usdImei)
        this.usdImei = true
        console.log(this.tnts, this.tenant)
        this.tnts.forEach((val) => {
          if (val.tnt_id == this.tenant) {
            this.cndtn = true
            console.log(val.tnt_id, this.tenant)
          }

        })
        console.log(this.cndtn)
      }
    })
    console.log(im)

  }
  getdvcetypeCtgry() {
    this.apiServ.get(`drivers/vehicle/device/types/categories/list`).subscribe((res) => {
      if (res['status'] === 200) {
        this.dvceTypeLst = res['data'].dvceTypelst;
        this.dvceCtgryLst = res['data'].dvceCtgrylst;
        console.log(res)
      }
    }, (err) => {
    });
  }

  addDevice() {
    var postData = {
      dvce_nm: this.dvce_nm,
      dvce_type_id: this.dvce_type_id,
      dvce_ctgry_id: this.dvce_ctgry_id,
      imei_nu: this.dvcimei_nu,
      mble_nu: this.mble_nu,
      clnt_id: this.selectedclnt,
      tnt_id: this.selectedTnt,
      sim_nu: this.sim_nu
    }
    console.log(postData)
    this.apiServ.post(postData, `drivers/vehicles/Master/Device/update`).subscribe((res) => {
      console.log(res);
      if (res['status'] == 200) {
        this.createMessage("Success", "Device Added Sucessfully");
        this.getMstrDta();
        this.closedvcFrm();
        this.dvce_nm = '';
        this.dvce_type_id = null;
        this.dvce_ctgry_id = null;
        this.dvcimei_nu = '';
        this.mble_nu = '';
        this.sim_nu = ''
      }
    }, (err) => {
      console.log(err)
    });

  }
  closedvcFrm() {
    this.addDvceFrm = false;

  }
  ign_ckd(e) {
    console.log(e)
    this.igntn_in = e ? 1 : 0
    console.log(this.igntn_in)
  }
  UnasgnDvce() {
    console.log(this.unAsgnVeh)
    const postdata = {
      asrt_id: this.unAsgnVeh
    }
    this.apiServ.post(postdata, `drivers/Unassign/Device`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Sucess', 'Device Unassigned Sucessfully');
        this.addVhcldtlsform.get('imei_nu').setValue('');
      }
    }, (err) => {
      console.log(err)
    })
  }
  cnfrmDvcUnAssgn(): void {
    this.modal.confirm({
      nzTitle: 'Are you sure unassign  this device?',
      nzContent: '<b style="color: red;"></b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.UnasgnDvce(),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }
  editUlb(ulb) {
    console.log(ulb);
    this.edtUlb = ulb
    this.addVhcldtlsform.get('veh_ctgry').setValue('');
    this.addVhcldtlsform.get('veh_grp').setValue('');
    this.getMstrDta()
  }
}
