
<div class="page-header">
  <div class="row align-items-md-center"> 
          <div class="col-md-6">
                  <!-- <div class="media m-v-10">
                          <i nz-icon nzType="file" nzTheme="twotone" class="header-icon"></i>&nbsp;
                          <div class="media-body m-l-15 header-border">
                                  <h6 class="mb-0 header-label">Report Builder</h6>
                          </div>
                  </div> -->
                  <div class="row hdrBar">
                    <div class="col-lg-12 col-md-12 mg-a">
                        <h1>Report Builder</h1>
                    </div>
                </div>
          </div>
  </div>
</div>



<div >
  <!-- <div class="row header-border">
    <div class="col-lg-12 col-md-12">
      <div>
        <i nz-icon nzType="file" nzTheme="twotone" class="header-icon"></i>
        &nbsp;<b
          class="header-label">Report Builder</b>
      </div>
    </div>
  </div> -->
<!-- <ds-loading *ngIf="loader == true"></ds-loading> -->
<div class="input-row" nz-row>
  <div nzFlex nzFlex.gt-lg="6%" nzFlex.gt-md="6%" nzFlex.gt-sm="6%" nzFlex.gt-xs="6%"
    style="margin-top: 1%;margin-left: 3%;">
    <button nz-button nzType="primary" (click)="back()" *ngIf="showCmRpts == true">Back</button>
  </div>
  <!-- <div nzFlex nzFlex.gt-lg="74%" nzFlex.gt-md="74%" nzFlex.gt-sm="74%" nzFlex.gt-xs="74%"></div> -->
  <div nz-col nzXs="4" nzSm="6" nzMd="6" nzLg="6" nzXl="6" nzOffset="16">
    <div>
      <button nz-button nzType="primary" (click)="custRpts()" class="cstmRprtBtn gtDtls"
        *ngIf="showCmRpts == false;">View Existing
        Report</button>
    </div>
  </div>
  <div nzFlex nzFlex.gt-lg="4%" nzFlex.gt-md="4%" nzFlex.gt-sm="4%" nzFlex.gt-xs="4%"></div>
</div>
<div class="ds-card usrcrteCrd">
  <form nz-form class="c-form" *ngIf="showCmRpts == false" style="padding: 3%;">
    <div nz-row>
      <p class="bdlngCss"><b>
          <!-- <mat-icon>call_to_action</mat-icon> -->
          <i nz-icon nzType="step-backward" nzTheme="outline">Report Details</i>
        </b> </p>
    </div>
    <!-- <div class="input-row" nz-row>



      <div nz-col nzXs="20" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
        <div class="form-label">Report Name</div>
        <input type="text" nz-input [(ngModel)]="report_name" (ngModelChange)="onReportChange();" [ngModelOptions]="{standalone: true}" />
      </div>
      <div nz-col nzXs="20" nzSm="10" nzMd="10" nzLg="10" nzXl="10" nzOffset="2">
        <div class="form-label">Description Text</div>
        <input type="text" nz-input [(ngModel)]="descrp_txt" [ngModelOptions]="{standalone: true}" />
      </div>

    </div> -->

    <div nz-row [nzGutter]="16" class="input-row">
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="12">
        <div class="form-label">Report Name</div>
        <input type="text" nz-input [(ngModel)]="report_name"  placeholder="Enter report name" (ngModelChange)="onReportChange();" [ngModelOptions]="{standalone: true}" />
      </div>
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="12">
        <div class="form-label">Description Text</div>
        <input type="text" nz-input [(ngModel)]="descrp_txt"  placeholder="Enter description" [ngModelOptions]="{standalone: true}" />
      </div>
 
    </div>




    <!-- <div class="input-row" nz-row>
      <div nz-col nzXs="20" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
        <div class="form-label">Select Group</div>
        <nz-select  [(ngModel)]="selectGrp" nzPlaceHolder="Choose group" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let g of grplist" [nzValue]="g.grp_id" [nzLabel]="g.grp_nm" >{{g.grp_nm}}
          </nz-option>
        </nz-select>
      </div>
      <div nz-col nzXs="20" nzSm="10" nzMd="10" nzLg="10" nzXl="10" nzOffset="2">
        <div class="form-label">Select Category</div>
        <nz-select [(ngModel)]="selectCtgy" nzPlaceHolder="Choose category" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let c of ctgrylist" [nzValue]="c.rpt_ctgry_id" [nzLabel]="c.rpt_ctgry_nm">{{c.rpt_ctgry_nm}}
          </nz-option>
        </nz-select>
      </div>

    </div> -->

    <div nz-row [nzGutter]="16" class="input-row">
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="12">
        <div class="form-label">Select Group</div>
        <nz-select  [(ngModel)]="selectGrp" nzPlaceHolder="Choose group" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let g of grplist" [nzValue]="g.grp_id" [nzLabel]="g.grp_nm" >{{g.grp_nm}}
          </nz-option>
        </nz-select>
      </div>
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="12">
        <div class="form-label">Select Category</div>
        <nz-select [(ngModel)]="selectCtgy" nzPlaceHolder="Choose category" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let c of ctgrylist" [nzValue]="c.rpt_ctgry_id" [nzLabel]="c.rpt_ctgry_nm">{{c.rpt_ctgry_nm}}
          </nz-option>
        </nz-select>
      </div>
 
    </div>



     <div nz-row>
      <p class="bdlngCss"><b>
          <i nz-icon nzType="download" nzTheme="outline">For Report</i>
      </b> </p>
    </div>



    <div nz-row [nzGutter]="16" class="input-row">
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="4">
        <div class="form-label">Excel</div>

        <nz-select [(ngModel)]="selectexcel" (ngModelChange)="getexcel(selectexcel)" nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
          <nz-option nzValue="1" nzLabel="Yes"> Yes </nz-option>
          <nz-option nzValue="0" nzLabel="No"> No </nz-option>

        </nz-select>
      </div>
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="4"  *ngIf="excelflenm">
        <div class="form-label">Excel Filename</div>
        <input type="text" nz-input [(ngModel)]="excelname" [ngModelOptions]="{standalone: true}" />
       </div>
      <!-- </div> -->
     
     
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="4">
        <div class="form-label">Pdf</div>
          <nz-select  [(ngModel)]="selectPdf" (ngModelChange)="getPdf(selectPdf)" nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
            <nz-option nzValue="1" nzLabel="Yes"> Yes </nz-option>
            <nz-option nzValue="0" nzLabel="No"> No </nz-option>
          </nz-select>
      </div>
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="4" *ngIf="pdfflenm">
        <div class="form-label">Pdf Filename</div>
          <input type="text" nz-input [(ngModel)]="pdfname" [ngModelOptions]="{standalone: true}" />
      </div>

      <div nz-col class="gutter-row buldrfrm" [nzSpan]="4">
        <div class="form-label">Pagination</div>
        <input type="text" nz-input [(ngModel)]="pgnation" [ngModelOptions]="{standalone: true}"/>
      </div>
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="4">
        <div class="form-label">Standard Variables</div>

        <nz-select [(ngModel)]="selectStandVrbles" nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let s of stanedVrblsList" [nzValue]="s.vrble_id" [nzLabel]="s.vrble_nm">{{s.vrble_nm}}
          </nz-option>
        </nz-select>
      </div>
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="4">
        <div class="form-label">DB Connection</div>

        <nz-select [(ngModel)]="selectDbConctors" nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let db of connectionList" [nzValue]="db.cntcn_id" [nzLabel]="db.cntcn_nm">{{db.cntcn_nm}}
          </nz-option>
        </nz-select>
      </div>
    </div>








    <!-- <div class="input-row" nz-row nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4" >
      <div nz-row nzLayout.xs="column" nzFlexFill>

        <div nzFlex="20" nzFlex.xs="100" class="px-8">
          <div>
          <div class="form-label">Excel</div>

          <nz-select [(ngModel)]="selectexcel" (ngModelChange)="getexcel(selectexcel)" nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
            <nz-option nzValue="1" nzLabel="Yes"> Yes </nz-option>
            <nz-option nzValue="0" nzLabel="No"> No </nz-option>

          </nz-select>
        </div>
      </div>
        
        <div nzFlex class="px-8"  *ngIf="excelflenm" nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4" >
          <div>
          <div class="form-label">Excel Filename</div>
          <input type="text" nz-input [(ngModel)]="excelname" [ngModelOptions]="{standalone: true}" />
         </div>
        </div>

        <div nzFlex="20" nzFlex.xs="100" nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4"  >
          <div>
          <div class="form-label">Pdf</div>
          <nz-select  [(ngModel)]="selectPdf" (ngModelChange)="getPdf(selectPdf)" nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
            <nz-option nzValue="1" nzLabel="Yes"> Yes </nz-option>
            <nz-option nzValue="0" nzLabel="No"> No </nz-option>
          </nz-select>
          </div>
        </div>

        <div nzFlex="20" nzFlex.xs="100" class="px-8" *ngIf="pdfflenm"nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
          <div>
          <div class="form-label">Pdf Filename</div>
          <input type="text" nz-input [(ngModel)]="pdfname" [ngModelOptions]="{standalone: true}" />
         </div>
        </div>

        <div nzFlex="20" nzFlex.xs="100" class="px-8"nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4" style="margin-right: 10px;">
          <div>
          <div class="form-label">Pagination</div>
          <input type="text" nz-input [(ngModel)]="pgnation" [ngModelOptions]="{standalone: true}"/>
         </div>
        </div>

        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4" >
          <div>
          <div class="form-label">Standard Variables</div>

          <nz-select [(ngModel)]="selectStandVrbles" nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let s of stanedVrblsList" [nzValue]="s.vrble_id" [nzLabel]="s.vrble_nm">{{s.vrble_nm}}
            </nz-option>
          </nz-select>
          </div>
        </div>

      </div>
    </div> -->

    
    
    
    
    <div nz-row>
      <p class="bdlngCss"><b>
          <!-- <mat-icon>ballot</mat-icon> -->
          <i nz-icon nzType="filter" nzTheme="outline">Filter Details</i>

        </b> </p>
    </div>


    <!-- <div class="input-row" nz-row >
          <div nz-col nzFlex="1 1 200px"> 
          <div class="form-label">Add Filters</div>
         <nz-select [(ngModel)]="selectFltrs" nzPlaceHolder="Choose" nzMode="multiple" style ="width : 100%" [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let f of fltrlist" [nzValue]="f.fltr_id" [nzLabel]="f.fltr_nm">{{f.fltr_nm}}
            </nz-option>
          </nz-select>
           </div>
        <div nz-col nzFlex="1 2 300px" nzOffset="1" style="margin-top: 35px;margin-left: 10px;">
        <button nz-button nzType="primary" (click)="addfltrs(varble,dta)" ><span>+</span>ADD
          FILTERS</button>
          </div>
      </div> -->
      <div nz-row [nzGutter]="16" class="input-row">
        <div nz-col class="gutter-row buldrfrm" [nzSpan]="12">
          <div class="form-label">Add Filters</div>
         <nz-select [(ngModel)]="selectFltrs" nzPlaceHolder="Choose" nzMode="multiple"  [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let f of fltrlist" [nzValue]="f.fltr_id" [nzLabel]="f.fltr_nm">{{f.fltr_nm}}
            </nz-option>
          </nz-select>
        </div>
        <div nz-col class="gutter-row buldrfrm" [nzSpan]="12">
          <button nz-button nzType="primary" (click)="addfltrs(varble,dta)" style="margin-top: 35px;"><span>+</span>ADD
            FILTERS</button>
        </div>
      </div>



    <div *ngFor="let item of dataft">
      <div nz-row nzLayout>
        <div nzFlex="50" nzFlex.xs="100">
          <p class="lblcs">{{item.name}}
            <span class="psbtn" (click)="addtxtbox(item)">+</span>
          </p>
        </div>
      </div>
      <div class="tBle">
        <table>
          <tr>
            <th>Label Text</th>
            <th>Default Value</th>
            <th>Sequence Id</th>
            <th>Variable Text</th>
            <th>Accept</th>
            <th>Accept description</th>
            <th colspan="2">Swap</th>
            <th>Action</th>

          </tr>
          <tr *ngFor="let itm of item.inputbox let i=index">
            <td>
              <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline" class="px-8"
                style="font-size:13px">
                <input matInput placeholder="Placeholder" type="text" [(ngModel)]="itm.inputnm"
                  [nzOptions]="{standalone: true}" required>
              </mat-form-field> -->
              <input type="text" nz-input [(ngModel)]="itm.inputnm" [ngModelOptions]="{standalone: true}"/>

            </td>
            <td>
              <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline" class="px-8"
                style="font-size:13px">
                <input matInput placeholder="Placeholder" type="text" [(ngModel)]="itm.default"
                  [nzOptions]="{standalone: true}" required>
              </mat-form-field> -->
              <input type="text" nz-input [(ngModel)]="itm.default" [ngModelOptions]="{standalone: true}"/>

            </td>

            <td>
              <label>
                <span class="lbtvlue sqvlcs">{{i+1}}</span>
              </label>
            </td>
            <td>
              <label>
                <span class="lbtvlue" style="margin-left: 3%;font-size: 14px;">{{item.name}}_{{i}}</span>
              </label>

            </td>

            <td>
              <!-- <mat-checkbox [(ngModel)]="itm.accept" [nzOptions]="{standalone: true}"></mat-checkbox> -->
              <!-- <label nz-checkbox [(ngModel)]="itm.accept">Checkbox</label> -->
              <label nz-checkbox [(ngModel)]="itm.accept" [ngModelOptions]="{standalone: true}"></label>
            </td>

            <td>
              <input type="text" nz-input [(ngModel)]="itm.acceptdes" [ngModelOptions]="{standalone: true}"/>

            </td>

            <td>
              <div *ngIf="i !=0">
                <div (click)="upward(item,itm,i)" class="arrws">
                  <i nz-icon nzType="up" nzTheme="outline"></i>
                </div>
              </div>
            </td>

            <td>
              <div *ngIf="i+1 != item.inputbox.length">
                <div (click)="downwward(item,itm,i)" class="arrws">
                  <i nz-icon nzType="down" nzTheme="outline"></i>
                </div>
              </div>
            </td>

            <td>
              <div (click)="delete(item,itm,i)">
                <!-- <mat-icon style="font-size: 28px;cursor: pointer; color: red;">delete_outline</mat-icon> -->
                <i nz-icon style="font-size: 28px;cursor: pointer; color: red;" nzType="delete" nzTheme="outline"></i>
              </div>
            </td>

          </tr>
        </table>
      </div>
    </div>

    <div nz-row>
      <p class="bdlngCss"><b>
          <!-- <mat-icon>assignment</mat-icon> -->
          <i nz-icon nzType="edit" nzTheme="outline"> Enter Your Query </i>

        </b> </p>
    </div>

    <div nz-row [nzGutter]="16" class="input-row">
      <div nz-col class="gutter-row buldrfrm" [nzSpan]="24">
        <textarea nz-input name="query" style="height: 300px;" [(ngModel)]="query" [ngModelOptions]="{standalone: true}"></textarea>
      </div>
    </div>

    <div nz-row nzXs="10" nzSm="10" nzMd="10" nzLg="10" nzXl="10" nzOffset="20">
      <div nzFlex></div>
      <div nzFlex></div>
      <div nzFlex></div>
      <div nzFlex>
        <div nz-row nzXs="10" nzSm="10" nzMd="10" nzLg="10" nzXl="10" nzOffset="20">

        <nz-button-group>
          <button nz-button nzType="default"  (click)="onSubmit()" *ngIf="shwSubmtBtn" class="submt-btn">Submit</button>
          <button nz-button nzType="default" (click)="onUpdate()" *ngIf="shwUpdtBtn" class="updt-btn">Update</button>
          <button nz-button nzType="default" class="cncle-btn">Cancel</button>

        </nz-button-group>
        <!-- <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
          <mat-button-toggle (click)="onSubmit()" *ngIf="shwSubmtBtn" class="sbmtBtn">Submit</mat-button-toggle>
          <mat-button-toggle (click)="onUpdate()" *ngIf="shwUpdtBtn" class="updtBtn">Update</mat-button-toggle>
          <mat-button-toggle class="cnclBtn">Cancel</mat-button-toggle>
        </mat-button-toggle-group> -->
      </div>
      </div>
    </div>
    <div>
      <div>
        <div nz-row>
          <p class="bdlngCss"><b>
              <!-- <mat-icon>chrome_reader_mode</mat-icon>Columns Details -->
              <i nz-icon nzType="read" nzTheme="outline">Columns Details</i>
            </b> </p>
        </div>
      </div>

      <div class="custom_ds_table">
        <table>
          <thead>
            <tr>
              <th>Sno</th>
              <th>Column name</th>
              <th>Column Sequence</th>
              <th>Display name</th>
              <th>Visible On Report</th>
              <th>Add filter</th>
              <th>Width</th>
              <th>datatype</th>
              <th>Reference another report</th>
              <th>Report Url</th>
              <th>Report passing parameters</th>
              <th>Report Filters parameters</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let clumns of columnnames let i=index">
              <td>{{i+1}}</td>
              <td>{{clumns.type}}</td>
              <td>
                <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline"
                  style="font-size:13px">
                  <input matInput placeholder="Placeholder" type="text" [(ngModel)]="clumns.csequence"
                    [nzOptions]="{standalone: true}" required>
                </mat-form-field> -->

                <input type="text" nz-input [(ngModel)]="clumns.csequence" [ngModelOptions]="{standalone: true}" />

              </td>
              <td>
                <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline"
                  style="font-size:13px">
                  <input matInput placeholder="Placeholder" type="text" [(ngModel)]="clumns.displaynm"
                    [nzOptions]="{standalone: true}" required>
                </mat-form-field> -->

                <input type="text" nz-input [(ngModel)]="clumns.displaynm" [ngModelOptions]="{standalone: true}" />

              </td>
              <td>
                <!-- <mat-checkbox [(ngModel)]="clumns.visibleonrpt" [nzOptions]="{standalone: true}" required
                  class="vsbleRprt"> -->
                <!-- <nz-checkbox-group class="vsbleRprt" [(ngModel)]="clumns.visibleonrpt" [ngModelOptions]="{standalone: true}"></nz-checkbox-group> -->
                <label nz-checkbox class="vsbleRprt" [(ngModel)]="clumns.visibleonrpt" name="vsblonrprt" [ngModelOptions]="{standalone: true}"></label>
              </td>
              <td>
                <!-- <mat-checkbox [(ngModel)]="clumns.filterin" [nzOptions]="{standalone: true}" required
                  class="vsbleRprt"></mat-checkbox> -->
                <!-- <nz-checkbox-group class="vsbleRprt" [(ngModel)]="clumns.filterin" [ngModelOptions]="{standalone: true}"></nz-checkbox-group> -->
                <label nz-checkbox class="vsbleRprt" [(ngModel)]="clumns.filterin" name="fltrin" [ngModelOptions]="{standalone: true}" ></label>
              </td>
              <td>
                <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline"
                  style="font-size:13px">
                  <input matInput placeholder="Placeholder" type="text" [(ngModel)]="clumns.width"
                    [nzOptions]="{standalone: true}" required>
                </mat-form-field> -->

                <input type="text" nz-input [(ngModel)]="clumns.width" [ngModelOptions]="{standalone: true}" />
                <!-- [nzOptions]="{standalone: true}" /> -->

              </td>
              <td>
                <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline"
                  style="font-size:13px">
                  <input matInput placeholder="Placeholder" type="text" [(ngModel)]="clumns.datatype"
                    [nzOptions]="{standalone: true}" required>
                </mat-form-field> -->

                <input type="text" nz-input [(ngModel)]="clumns.datatype" [ngModelOptions]="{standalone: true}" />
                <!-- [nzOptions]="{standalone: true}" /> -->

              </td>
              <td>
                <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline"
                  style="font-size:13px">
                  <input matInput placeholder="Placeholder" type="text" [(ngModel)]="clumns.refanrpt"
                    [nzOptions]="{standalone: true}" required>
                </mat-form-field> -->

                <input type="text" nz-input [(ngModel)]="clumns.refanrpt" [ngModelOptions]="{standalone: true}"/>
                <!-- [nzOptions]="{standalone: true}" /> -->

              </td>
              <td>
                <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline"
                  style="font-size:13px">
                  <input matInput placeholder="Placeholder" type="text" [(ngModel)]="clumns.rpturl"
                    [nzOptions]="{standalone: true}" required>
                </mat-form-field> -->

                <input type="text" nz-input [(ngModel)]="clumns.rpturl" [ngModelOptions]="{standalone: true}" />
                <!-- [nzOptions]="{standalone: true}" /> -->

              </td>
              <td>
                <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline"
                  style="font-size:13px">
                  <input matInput placeholder="Placeholder" type="text" [(ngModel)]="clumns.rptparameters"
                    [nzOptions]="{standalone: true}" required>
                </mat-form-field> -->

                <input type="text" nz-input [(ngModel)]="clumns.rptparameters" [ngModelOptions]="{standalone: true}"/>
                <!-- [nzOptions]="{standalone: true}" /> -->

              </td>
              <td>
                <!-- <mat-form-field class="example-full-width lngCs ltlngwdth frmcsAl" appearance="outline"
                  style="font-size:13px">
                  <input matInput placeholder="Placeholder" type="text" [(ngModel)]="clumns.rptfilterparameters"
                    [nzOptions]="{standalone: true}" required>
                </mat-form-field> -->

                <input type="text" nz-input [(ngModel)]="clumns.rptfilterparameters" [ngModelOptions]="{standalone: true}" />
                <!-- [nzOptions]="{standalone: true}" /> -->

              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="margin-top: 1%;">
        <div>
          <button nz-button nzType="primary" class="clumnscss gtDtls" (click)="svcolumnsdata(columnnames)"
            *ngIf="shwSaveClms">Save Columns Data</button>
          <button nz-button nzType="primary" class="clumnscss gtDtls" (click)="upcolumnsdata(columnnames)"
            *ngIf="shwUpdClms">Update Columns Data</button>
        </div>
      </div>
    </div>
  </form>

  <div nz-row class="gridTop" *ngIf="showCmRpts == true"> 
    <div class="ds-card grid" *ngIf="(permissions.slct_in==1)?true:false" >
      <dx-data-grid id="gridContainer" [dataSource]="csmtList" [remoteOperations]="false"
        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
        [showBorders]="true" (onRowClick)="startEdit($event)" style="width:100%">
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="(permissions.exprt_in==1)?true:false" [allowExportSelectedData]="true" fileName="test">
        </dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [alignment]="f.cellStyle" [width]="f.width" [groupIndex]="f.groupIndex">
        </dxi-column>
        <dxo-summary>
          <dxi-group-item column="rpt_nm" [summaryType]="count" displayFormat="{0} Reports">
          </dxi-group-item>
        </dxo-summary>
      </dx-data-grid>
    </div>
    
    <div *ngIf="(permissions.slct_in==1)?false:true" class="permMsg">{{mainMessage}}</div>
  
</div>
</div>
</div>