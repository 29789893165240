import { Component, OnInit } from '@angular/core';
import { AppsService } from '../../../shared/services/apps.service';

@Component({
  selector: 'app-userfee-rep',
  templateUrl: './userfee-rep.component.html',
  styleUrls: ['./userfee-rep.component.css']
})
export class UserfeeRepComponent implements OnInit {

  usrfeedtls : [{fn_date: 0,recept_no: 0,wrd_nu: 0,nme: 0,cstmr_ctgry_id: 0,ctgry_nm: 0,ofcr_nm: 0,amnt: 0,mble_ph: 0,address: 0,lctn_txt: 0,chalana_no: 0,chalana_dt: 0,chalana_id: 0,hse_nu: 0,micro_pkt: 0}];
  clnt_id = 7;
  displayfilters = false ;
  filterslabel = "Show Filters";
  searchData = '';

  constructor(private appsServ: AppsService) { }

  ngOnInit(): void {

    
  }

  shw_fltrs(){
    this.displayfilters = !this.displayfilters
    if (this.displayfilters) { this.filterslabel = "Hide Filters" }
		else this.filterslabel = "Show Filters"
  }


  getData(){
    var data = {
      frmdt: "",
      todt: "",
      wrd: "",
      phno: "",
      agnt: "",
      ctgry: "",
      clnt_id: this.clnt_id
    }

    const prfleRte = `reports/usrFee`;
    this.appsServ.post(data ,prfleRte).subscribe((res) => {
      this.usrfeedtls = res['data'];
    // console.log(this.usrfeedtls);
    }, (error) => {
    // console.log(error);
    });
  }

}
