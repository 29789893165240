<ds-loading *ngIf="showLdr"></ds-loading>
<div class="row hdrBar">
    <div class="col-lg-12 col-md-12 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">Trips Operations</h1>
    </div>
</div>
<div class="main">

    <div class="row">
        <div class="col-lg-3 col-md-3">
            <div class="drpLbl">Select Date</div>
            <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt" style="width:100%;"
                (ngModelChange)="onChange($event)"></nz-date-picker>
        </div>
        <div class="col-lg-3 col-md-3" *ngIf="tntLst.length>1">
            <div class="drpLbl">Select ULB</div>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
                [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
            </nz-select>
        </div>
        <div class="col-lg-3 col-md-3" *ngIf="tntLst.length==1">
            <div class="drpLbl">ULB</div>
            <div class="slctBx disbledtxt">{{tntLst[0].tnt_nm}}</div>
        </div>
        <div class="col-lg-3 col-md-3">
            <div class="drpLbl">Select Vehicle</div>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedVehicle" (ngModelChange)="onvehcleSelect($event)" nzPlaceHolder="Select Vehicle"
                [ngModelOptions]="{standalone: true}" style="width:100%;">
                <nz-option *ngFor="let v of vhcleslst" [nzLabel]=v.asrt_nm [nzValue]="v.asrt_id" ></nz-option>
            </nz-select>
        </div>
    </div>
</div>
<div class="row bg-wp">
    <div class="col-lg-8 col-md-8 col-sm-8">
      <h1 style="text-align:left;">Trips {{curDate}}</h1>
    </div>
    
   
    <div class="ds-card grid tbl">
      <dx-data-grid class="p-8" [dataSource]="tripDtls" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true" [showRowLines]="true"
        [showBorders]="true" style="width:100%" (onToolbarPreparing)="true"  
        [wordWrapEnabled]="true" [columnAutoWidth]="true"
        (onCellPrepared)="onCellPrepared(columnDefs,$event)" (onCellClick)="onCellClick($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [visible]="tripDtls && tripDtls.length>10" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
          [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="false"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [contextMenuEnabled]="true"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Trips Travelled
                 Data"></dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
        <!-- <dxo-summary>
          <dxi-group-item column="asrt_nm" name="Total Vehicles" summaryType="count" [alignByColumn]="true"
            [showInGroupFooter]="true"></dxi-group-item>
          <dxi-group-item column="trp_sqnc_nu" name="Total Trips" summaryType="count" [alignByColumn]="true"
            [showInGroupFooter]="true">
          </dxi-group-item>
          <dxi-group-item column="actl_trvl_dstne_ct" name="Total Distance" summaryType="sum" [alignByColumn]="true"
            [showInGroupFooter]="true"></dxi-group-item>
          <dxi-group-item column="actl_trvl_tm" name="Total Hours" summaryType="sum" [alignByColumn]="true"
            [showInGroupFooter]="true">
          </dxi-group-item>
        </dxo-summary> -->
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field"
          [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true"
          [groupIndex]="f.groupIndex"></dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="Clear Trip"
          cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="tblEdtBtn" (click)="clearTrip(data)">
              <p>Clear Trip</p>
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="Assign Vehicle"
          cellTemplate="trpsdata">
          <div *dxTemplate="let data of 'trpsdata'">
            <div class="tblAddBtn" (click)="assgnVehicle(data)">
              <p>Assign</p>
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="Start Trip"
        cellTemplate="trpsdata1">
        <div *dxTemplate="let data of 'trpsdata1'">
          <div class="tblAddBtn" (click)="startTrip(data)">
            <p>start</p>
          </div>
        </div>
      </dxi-column>
        
      </dx-data-grid>
    </div>
  </div>