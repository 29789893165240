<ds-loading *ngIf="showLdr"></ds-loading>
<div class="row hdrBar">
    <div class="col-lg-8 col-md-8 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">Solid waste management Dashboard</h1>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <div class="inptCls">
            <span>Primary<input class="prmry" disabled="true"></span>
            <span>Secondary<input class="scndry" disabled="true"></span>
        </div>
    </div>
</div>
<br>
<div class="main">
    <!-- <i nz-icon nzType="arrow-up" nzTheme="outline"></i> -->
    <div class="row crdsFixedHts" *ngIf="cvrgDta && cvrgDta.length>0">

        <!-- <div class="crdPdng col-lg-1 col-md-1"></div> -->
        <div class="crdPdng col-lg-12 col-md-12 mg-a">
            <div class="row">
                <ul class="cards">
                    <li class="cards_item wdt-11"></li>
                    <li class="cards_item">
                        <div class="card_g" style="background:linear-gradient(135deg, #40d99d 0%,#3bb2b8 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Transfer Points</h1>
                                </div>
                                <div class="row crdHdr_sb">
                                    <div class="crdPdng col-lg-6 col-md-6">
                                        <h1>
                                            <i nz-icon nzType="branches" nzTheme="outline"></i>
                                        </h1>
                                    </div>
                                    <div class="crdPdng col-lg-6 col-md-6">
                                        <h1 class="card_sub_title" (click)="getDtls(trnsfrPnts,'t');scroll(target);">
                                            {{trnsfrPnts.tot_fnce}}</h1>
                                    </div>
                                </div>
                                <div class="row crdHdr_sb_sm">
                                    <div class="crdPdng col-lg-8 col-md-8">
                                        <h1>
                                            Covered
                                        </h1>
                                    </div>
                                    <div class="crdPdng col-lg-4 col-md-4">
                                        <h1 class="card_sub_title" (click)="getDtls(trnsfrPnts,'c');scroll(target);">
                                            {{trnsfrPnts.cvrd_ct}}</h1>
                                    </div>
                                </div>
                                <div class="row crdHdr_sb_sm">
                                    <div class="crdPdng col-lg-8 col-md-8">
                                        <h1>
                                            Not-Covered
                                        </h1>
                                    </div>
                                    <div class="crdPdng col-lg-4 col-md-4">
                                        <h1 class="card_sub_title" (click)="getDtls(trnsfrPnts,'nc');scroll(target);">
                                            {{trnsfrPnts.tot_fnce - trnsfrPnts.cvrd_ct}}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="row">

                <ul class="cards">
                    <li class="cards_item">
                        <ul class="topArrow">
                            <li class="topTxtSpn">
                                <h4>20</h4>
                                <p>Total</p>
                            </li>
                            <li class="topTxtSpn">
                                <h4>50</h4>
                                <p>Running</p>
                            </li>
                            <li class="topTxtSpn">
                                <h4>80</h4>
                                <p>Completed</p>
                            </li>
                            <li>
                                <!-- <span class="topTxtSpn">105
                                        <p style="color: #0a2edc;">Primary</p>
                                    </span> -->
                                <span class="arrow"></span>
                                <!-- <img src="assets/images/logo/tp.png"> -->
                            </li>
                        </ul>
                        <div class="card_g" style="background:linear-gradient(135deg, #ab89f7 0%,#7367f0 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Houses</h1>
                                </div>
                                <div class="row crdHdr_sb">
                                    <div class="crdPdng col-lg-6 col-md-6">
                                        <h1>
                                            <i nz-icon nzType="home" nzTheme="outline"></i>
                                        </h1>
                                    </div>
                                    <div class="crdPdng col-lg-6 col-md-6">
                                        <h1 class="card_sub_title">{{HsDta.tot_gtes}}</h1>
                                    </div>
                                </div>
                                <div class="row crdHdr_sb_sm">
                                    <div class="crdPdng col-lg-8 col-md-8">
                                        <h1>
                                            Scanned Gates
                                        </h1>
                                    </div>
                                    <div class="crdPdng col-lg-4 col-md-4">
                                        <h1 class="card_sub_title">{{HsDta.tot_scnd_gtes}}</h1>
                                    </div>
                                </div>
                                <div class="row crdHdr_sb_sm">
                                    <div class="crdPdng col-lg-8 col-md-8">
                                        <h1>
                                            Not-Scanned Gates
                                        </h1>
                                    </div>
                                    <div class="crdPdng col-lg-4 col-md-4">
                                        <h1 class="card_sub_title">{{HsDta.tot_gtes - HsDta.tot_scnd_gtes}}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <ul class="rightArrow_prmry">
                            <li class="rgtTxtSpn">
                                <h4>8</h4>
                                <p>Total</p>
                            </li>
                            <li class="rgtTxtSpn">
                                <h4>5</h4>
                                <p>Running</p>
                            </li>
                            <li class="rgtTxtSpn">
                                <h4>3</h4>
                                <p>Completed</p>
                            </li>
                            <li>
                               <span class="rgtTxtSpn">8
                                        <p style="color: #0a2edc;">Primary</p>
                                    </span>
                                <span class="arrow"></span>
                               <img src="assets/images/logo/rt_w.png">
                            </li>
                        </ul> -->
                    </li>
                    <li class="cards_item wdt-10">
                        <ul class="rightArrow_prmryTp">
                            <li class="rgtTpTxtArw">
                                <!-- <span class="rgtTxtSpn">2
                                        <p style="color:#da0fd8">Secondary</p>
                                    </span> -->
                                <span class="Tparrow"></span>
                            </li>
                            <!-- <li class="rgtTpTxtSpn">
                                <h4>2</h4>
                                <p>Total</p>
                            </li>
                            <li class="rgtTpTxtSpn">
                                <h4>0</h4>
                                <p>Running</p>
                            </li>
                            <li class="rgtTpTxtSpn">
                                <h4>2</h4>
                                <p>Completed</p>
                            </li> -->
                        </ul>
                    </li>
                    <li class="cards_item lft-13">
                        <ul class="btmArrow">
                            <li>
                                <!-- <span class="btmTxtSpn">20
                                        <p style="color:#da0fd8">Secondary</p>
                                    </span> -->
                                <span class="arrow"></span>
                                <!-- <img src="assets/images/logo/bt_p.png"> -->
                            </li>
                            <li class="btmTxtSpn">
                                <h4>20</h4>
                                <p>Total</p>
                            </li>
                            <li class="btmTxtSpn">
                                <h4>12</h4>
                                <p>Running</p>
                            </li>
                            <li class="btmTxtSpn">
                                <h4>8</h4>
                                <p>Completed</p>
                            </li>
                        </ul>
                        <div class="card_g" style="background:linear-gradient(135deg, #f36265 0%,#b42b70 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Transfer Stations</h1>
                                </div>
                                <div class="row crdHdr_sb">
                                    <div class="txtCntr">
                                            <h3 class="card_sub_title"
                                                (click)="getDtls(trnsfrStns,'t');scroll(target);">
                                                {{trnsfrStns.tot_fnce}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="cards_item wdt-10">
                        <ul class="rightArrow_scndryTp">
                            <li class="rgtTpTxtArw">
                                <!-- <span class="rgtTxtSpn">2
                                        <p style="color:#da0fd8">Secondary</p>
                                    </span> -->
                                <span class="Tparrow"></span>
                            </li>
                            <!-- <li class="rgtTpTxtSpn">
                                <h4>2</h4>
                                <p>Total</p>
                            </li>
                            <li class="rgtTpTxtSpn">
                                <h4>0</h4>
                                <p>Running</p>
                            </li>
                            <li class="rgtTpTxtSpn">
                                <h4>2</h4>
                                <p>Completed</p>
                            </li> -->
                        </ul>
                        <ul class="rightArrow_scndryBt">
                            <li class="rgtBtTxtArw">
                                <!-- <span class="rgtTxtSpn">2
                                        <p style="color:#da0fd8">Secondary</p>
                                    </span> -->
                                <span class="Btarrow"></span>
                                <!-- <img src="assets/images/logo/rt_p.png"> -->

                            </li>
                            <!-- <li class="rgtBtTxtSpn">
                                <h4>2</h4>
                                <p>Total</p>
                            </li>
                            <li class="rgtBtTxtSpn">
                                <h4>1</h4>
                                <p>Running</p>
                            </li>
                            <li class="rgtBtTxtSpn">
                                <h4>1</h4>
                                <p>Completed</p>
                            </li> -->
                        </ul>
                    </li>
                    <li class="cards_item lft-13">

                        <div class="card_g smCrd_m" style="background:linear-gradient(135deg, #f6ab84 0%,#f38381 100%);">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Wet Processing Areas</h1>
                                </div>
                                <div class="row crdHdr_sb">
                                    <div class="crdPdng col-lg-6 col-md-6">
                                        <h1>
                                            <i nz-icon nzType="home" nzTheme="outline"></i>
                                        </h1>
                                    </div>
                                    <div class="crdPdng col-lg-6 col-md-6">
                                        <h1 class="card_sub_title" (click)="getDtls(prcngPnts,'t');scroll(target);">
                                            {{prcngPnts.tot_fnce}}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <ul class="topArrow">
                                <li class="topTxtSpn">
                                    <h4>20</h4>
                                    <p>Total</p>
                                </li>
                                <li class="topTxtSpn">
                                    <h4>50</h4>
                                    <p>Running</p>
                                </li>
                                <li class="topTxtSpn">
                                    <h4>80</h4>
                                    <p>Completed</p>
                                </li>
                            </ul> -->

                        <div class="card_g" style="background:linear-gradient(135deg, #b1ea4d 0%,#459522 100%)">
                            <div class="card_content">
                                <div class="row">
                                    <h1 class="crdHdr">Dry Processing Areas</h1>
                                </div>
                                <div class="row crdHdr_sb">
                                    <div class="crdPdng col-lg-6 col-md-6">
                                        <h1>
                                            <i nz-icon nzType="home" nzTheme="outline"></i>
                                        </h1>
                                    </div>
                                    <div class="crdPdng col-lg-6 col-md-6">
                                        <h1 class="card_sub_title" (click)="getDtls(mrfPnts,'t');scroll(target);">
                                            {{mrfPnts.tot_fnce}}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <ul class="rightArrow_prmry">
                                <li class="rgtTxtSpn">
                                    <h4>8</h4>
                                    <p>Total</p>
                                </li>
                                <li class="rgtTxtSpn">
                                    <h4>5</h4>
                                    <p>Running</p>
                                </li>
                                <li class="rgtTxtSpn">
                                    <h4>3</h4>
                                    <p>Completed</p>
                                </li>
                            </ul> -->
                    </li>


                </ul>
            </div>
        </div>
    </div>
    <br><br>
    <div class="row bg-wp" #target *ngIf="cnt_dtls && cnt_dtls.length>0">
        <h1>{{cnt_dtls[0].fnce_grp_nm}}&nbsp;<b *ngIf="cnt_dtls[0].cvrt_ct == 0">Not Covered</b><b
                *ngIf="cnt_dtls[0].cvrt_ct > 0">Covered</b>Details</h1>
        <div class="ds-card grid tbl">
            <dx-data-grid class="p-8" [dataSource]="cnt_dtls" [remoteOperations]="false"   [allowColumnReordering]="true"
                [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true"
                [showRowLines]="true" [showBorders]="true" style="width:100%" (onToolbarPreparing)="true">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                    [showInfo]="true"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="ULB Trips Data"></dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of cnt_dtlscolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons" [width]="100" caption="Edit"
                    cellTemplate="editTemplate">
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="false" type="buttons" [width]="100"
                    caption="Delete" cellTemplate="delTemplate">
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>


</div>