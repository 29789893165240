<!-- <div>
    <i nz-icon nzType="idcard" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">Report Profile</b>
  </div> -->

<pg-header title="Report Profile" ticon="idcard" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">

  <div class="ds-card grid">
    <dx-data-grid id="gridContainer" [dataSource]="rprtprflLst" [remoteOperations]="false"
      [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
      [showBorders]="true" style="width:100%" [allowColumnResizing]="true"
      (onToolbarPreparing)="(USER_PERMISSIONS.insrt_in==1)?onToolbarPreparing($event):false">
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
      <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="LMO Details"></dxo-export>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
        [width]="f.width" [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
        [allowHeaderFiltering]="f.filter"></dxi-column>
      <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false"
        [width]="100" caption="Edit" cellTemplate="editTemplate">
        <div *dxTemplate="let data of 'editTemplate'">
          <div class="tblEdtBtn" (click)="onEdit2(data)">
            Edit
          </div>
        </div>
      </dxi-column>
      <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons"
        [width]="100" caption="Delete" cellTemplate="delTemplate">
        <div *dxTemplate="let data of 'delTemplate'">
          <div class="tblDelBtn" (click)="onDelete2(data)">
            Delete
          </div>
        </div>
      </dxi-column>
    </dx-data-grid>
  </div>
  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
    (nzOnClose)="closeSideBar()">
    <form nz-form [formGroup]="rprtprflForm">
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="rprtprflMrgn">
          <div class="inptLbl">Report Profile Name</div>
          <input type="text" nz-input formControlName="prfle_nm" />
        </div>

      </div>
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="rprtprflMrgn">
          <div class="inptLbl">Report Profile Discription</div>
          <input type="text" nz-input formControlName="prfle_dscrn_tx" />
        </div>
      </div>
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="rprtprflMrgn">
          <div class="inptLbl">Select Component Name</div>
          <nz-select style="width:100%" nzPlaceHolder="Select Component Name" formControlName="cmpnt_nm">
            <nz-option *ngFor="let c of cmpnt_lst" [nzValue]="c.cmpnt_id" [nzLabel]="c.cmpnt_nm">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </form>
    <div class="ds-card grid">
      <div class="stpoptsmainhng">
        Report Options
      </div>

      <ul class="stpopts" nz-list [nzDataSource]="rptoptnlst" nzBordered>
        <div>

          <li nz-list-item *ngFor="let m of rptoptnlst" nzNoFlex>
            <!-- <ul nz-list-item-actions>
              <nz-list-item-action>
                <label nz-checkbox [(ngModel)]="m.isActive" (ngModelChange)="ischeked($event,m)"></label>
              </nz-list-item-action>
            </ul> -->
            <div class="input-row" nz-row>
              <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
                {{ m.rpt_nm }}
              </div>
              <div nz-col nzXs="2" nzSm="2" nzMd="2" nzLg="2" nzXl="2">
                <!-- <ul nz-list-item-actions>
              <nz-list-item-action> -->
                <label nz-checkbox [(ngModel)]="m.isActive" (ngModelChange)="ischeked($event,m)"></label>
              </div>
            </div>
            <!-- </nz-list-item-action> -->
            <!-- </ul> -->
          </li>
        </div>
      </ul>
    </div>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="saverptprfl()"
      *ngIf="shwschvlymEditDiv==false && shwschvlymDelDiv==false">Submit</button>
    <button nz-button nzType="primary" class="sbmtBtm" (click)="updtrptprfl()"
      *ngIf="shwschvlymEditDiv==true && shwschvlymDelDiv==false">Update</button>

    <button nz-button nzType="primary" nzDanger class="cnclBtn" (click)="closeSideBar()">Cancel</button>
  </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
    <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
  </nz-result>
</div>