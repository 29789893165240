<ds-loading *ngIf="showLdr"></ds-loading>
<div class="dashbrd2">
    <div class="flex justify-between space-y-3 mb-0 flex-col dsbrdHdr">
        <div class="p-15 bg-[#4F46BA] rounded-xl w-full pg-head"
            style="    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);padding: 8px !important;     background-color: #232e25;">
            <div class="row">
                <div class="col-md-4">
                    <p class="text-base font-semibold text-white mrgn_btm"
                        style="font-weight: bold;color: #fbe178 !important; margin-top:5px;    font-size: 20px;"> <span
                            nz-icon nzType="dashboard" nzTheme="outline" style="margin-right: 4px;"></span> Dashboard
                    </p>
                </div>
                <div class="col-md-4">

                    <div class="container">
                        <div class="selector">
                            <div class="selector-item">
                                <input type="radio" id="radio3" value="0" (change)="getradioval($event)" name="selector"
                                    class="selector-item_radio" checked>
                                <label for="radio3" class="selector-item_label">Urban</label>
                            </div>
                            <div class="selector-item">
                                <input type="radio" id="radio2" value="1" (change)="getradioval($event)" name="selector"
                                    class="selector-item_radio">
                                <label for="radio2" class="selector-item_label">Rural</label>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 brdcrumstyles">
            <nz-breadcrumb>
                <nz-breadcrumb-item (click)="reset()">
                    <span nz-icon nzType="home"></span>
                    <span> AP (Urban)</span>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item *ngIf="dbrd_lvl > 0" (click)="dist1()">
                    <a>
                        <!-- <span nz-icon nzType="user"></span> -->
                        <span>District :{{slctDistnm}}</span>
                    </a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item *ngIf="dbrd_lvl==1">ULB :{{slctulbnm}}</nz-breadcrumb-item>
                <nz-breadcrumb-item *ngIf="dbrd_lvl==2">Sachivalyam :{{slctdSVMNm}}</nz-breadcrumb-item>
                <button nz-button *ngIf="dbrd_lvl>0" (click)="backto()" class="bckbtn">Back</button>
            </nz-breadcrumb>

        </div>
        <!--  *ngIf="radioValue==0" -->
        <div>
            <div class="dashbrdcrds">
                <div class="row">
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-12 cstmWdthcrd allvchle" (click)="getvehicles(0)">
                                <div class="row"  [ngClass]="selectedCtgry == 0 ? 'bg-css1' : 'dfltCrd1' "
                                style="border-radius: 6px;">
                                    <div class="col-md-9" >
                                        <div class="stpcnt1 ">All Vehicles</div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="stpcnt1 " style="float:right;">{{ttl_cnt}}</div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-md-6 cstmWdthcrd rrlCrds" *ngFor="let v of crdSmryData"
                                (click)="getvehicles(v)">
                                <div [style.background]="v.clr_cd"
                                    [ngClass]="v.asrt_ctgry_cd == selectedCtgry ? 'bg-css' : 'dfltCrd' "
                                    style="border-radius: 6px;    ">

                                    <div class="row">

                                        <div class="col-md-9">
                                            <div class="stpcnt vhcleCnt">{{v.ct}}</div>
                                            <div class=" vhclelbl"> {{v.asrt_ctgry_nm}}s</div>

                                        </div>
                                        <div class="col-md-3 centreTxt">
                                            <div class="vhcles_icons">
                                                <img src="assets/images/sac/{{v.icon_path}}.png" class="vhcleIcns">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 pdngnone">
                        <div class="bgwhite" [style.background]="grid_backd ? grid_backd : '#419db9'">
                            <dx-data-grid id="grid" class="gridHeight" [dataSource]="rowData" [remoteOperations]="false"
                                [allowColumnReordering]="true" [rowAlternationEnabled]="false" [showColumnLines]="true"
                                [showRowLines]="true" [showBorders]="true"
                                style="width:100%;    max-width: 100%!important;" [allowColumnResizing]="true"
                                [columnWidth]="auto" [wordWrapEnabled]="true" (onCellPrepared)="onCellPrepared($event)"
                                (onCellClick)="getUpdtDtls1($event)" (onExporting)="onExporting($event)">
                                <dxo-paging [pageSize]="20"></dxo-paging>

                                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 30, 50, 100]"
                                    [showInfo]="true">
                                </dxo-pager>
                                <!-- <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling> -->
                                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                                </dxo-search-panel>
                                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                                <dxo-group-panel [visible]="true"></dxo-group-panel>
                                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                                <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
                                <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="gis_prcl List">
                                </dxo-export>
                                <dxo-sorting mode="single">
                                    <!-- or "multiple" | "none" -->
                                </dxo-sorting>
                                <dxo-filter-row [visible]="false"></dxo-filter-row>
                                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                                <!-- [width]="f.field == 'sno' ?80:200" -->
                                <dxi-column *ngFor="let f of columnDefs" [visible]="f.visible" [dataField]="f.field"
                                    [sortOrder]="f.sortOrder" [caption]="f.headerName" [width]="f.width"
                                    [alignment]="f.alignment" [format]="this.currencyINRFormater" [allowResizing]="true"
                                    [allowFiltering]="f.filter" [allowHeaderFiltering]="f.filter"
                                    [showInColumnChooser]="f.Chooser">

                                    <dxi-column *ngFor="let c of f.children" [visible]="c.visible" [dataField]="c.field"
                                        [format]="this.currencyINRFormater" [caption]="c.headerName" [width]="c.width"
                                        [alignment]="c.alignment" [allowResizing]="true" [allowFiltering]="c.filter"
                                        [allowHeaderFiltering]="c.filter">
                                    </dxi-column>

                                </dxi-column>

                                <dxo-summary [calculateCustomSummary]="calculateSummary">
                                    <dxi-total-item *ngFor="let f of columnDefs" [column]="f.field" 
                                        [summaryType]="f.summaryType"
                                        [displayFormat]="f.field == 'sno'  ?'Total':f.displayFormat">
                                        <dxi-total-item *ngFor="let c of f.children" [column]="c.field"
                                            [summaryType]="c.summaryType"
                                            [displayFormat]=" c.displayFormat">
                                        </dxi-total-item>
                                    </dxi-total-item>

                                </dxo-summary>



                            </dx-data-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="radioValue==1">
            <div class="dashbrdcrds">
            </div>
        </div> -->

        <!-- <app-urban-district *ngIf="radioValue == 'U'"></app-urban-district>
        <app-rural-district *ngIf="radioValue == 'R'"></app-rural-district> -->
    </div>
</div>