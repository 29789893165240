<!-- <div>
    <i nz-icon nzType="database" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">State</b>
</div> -->
<pg-header title="State" ticon="database" bckbtn=true bckurl="/setup"></pg-header>
<div *ngIf="usrAcsErrMsg == false">
    <div>
        <div class="ds-card grid">
            <ds-loading *ngIf="shwLdg"></ds-loading>
            <dx-data-grid id="gridContainer" [dataSource]="stateLst" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
                [showRowLines]="true" [showBorders]="true" style="width:100%" [allowColumnResizing]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                </dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
                <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true" fileName="LMO Details"></dxo-export>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [width]="f.width" [alignment]="f.alignment" [allowResizing]="true"
                    [allowFiltering]="f.filter" [allowHeaderFiltering]="f.filter"></dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.updt_in==1)?true:false" type="buttons" [width]="100" caption="Edit"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="cursor-pointer tblEdtBtn" (click)="onCellClick('edit',data)">
                            Edit
                        </div>
                    </div>
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons" [width]="100" caption="Delete"
                    cellTemplate="delTemplate">
                    <div *dxTemplate="let data of 'delTemplate'">
                        <div class="cursor-pointer tblDelBtn" (click)="onCellClick('delete',data)">
                            Delete
                        </div>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>

    <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
        [nzMaskClosable]="false" [nzWidth]="350" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading"
        (nzOnClose)="closeSideBar()">

        <div class="inptLbl">State Name</div>
        <nz-input-group [nzSuffix]="inputClearTpl">
            <input type="text" nz-input [(ngModel)]="stateNm" placeholder="please enter State name" />
        </nz-input-group>
        <ng-template #inputClearTpl><i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
                *ngIf="stateNm" (click)="stateNm = null"></i></ng-template>
        <button nz-button nzType="primary" class="sbmtBtm" (click)="savemndl()"
            *ngIf="shwstateEditDiv==false && shwstateDelDiv==false">Submit</button>
        <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEditstate()"
            *ngIf="shwstateEditDiv==true && shwstateDelDiv==false">Update</button>
        <button nz-button nzType="primary" class="sbmtBtm" *ngIf="shwstateEditDiv==false && shwstateDelDiv==true"
            nz-popconfirm nzPopconfirmTitle="Are you sure delete this ?" nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="saveDeletestate()" (nzOnCancel)="cancel()">Delete</button>
        <button nz-button nzType="default" class="cnclBtn" (click)="oncancel()" *ngIf="shwSidebar">Cancel</button>

    </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
    <nz-result nzStatus="error" nzTitle="Access Denied"
        nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
        <!-- <div nz-result-extra>
                <button nz-button nzType="primary" (click)="bckToHm()">Back to home</button>
            </div> -->
    </nz-result>
</div>