import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as _ from 'lodash';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-sac-dashboard',
  templateUrl: './sac-dashboard.component.html',
  styleUrls: ['./sac-dashboard.component.scss']
})
export class SacDashboardComponent implements OnInit {
  radioValue = 0;
  slctdDt = new Date();
  slctdTnt;
  dbrd_lvl = 0;
  dbrd_ky = 'urban';
  showLdr: boolean;
  crdSmryData: any;
  rowData: any = [];
  columnDefs: any = [];
  selectedCtgry = 0;
  grid_backd: string;
  slctdDstrct: any;
  slctDistnm: void;
  slctulbnm: any;
  ttl_cnt = 0;
  slctdSvm: any;
  slctdSVMNm: any;

  auto;
  reset;
  tnt: void;
  constructor(public apiServ: AppsService,) { }

  ngOnInit(): void {
    this.getDbrdData();
    console.log(this.dbrd_lvl)
  }
  getradioval(e) {
    console.log( e.target.value)
    this.dbrd_lvl=0
    this.radioValue = e.target.value;
    if (this.dbrd_lvl == 0) {
      this.selectedCtgry = 0;
    }
    this.getDbrdData();

  }
  getDbrdData() {
    console.log(this.radioValue)
    if (this.radioValue == 0) {
      this.dbrd_ky = 'urban'
    } else {
      this.dbrd_ky = 'rural'
    }
    console.log(this.dbrd_ky)
    this.getSmryCnts();
    this.getTblSmry();
  }
  getSmryCnts() {
    let orgnlArry = [{
      asrt_ctgry_nm: "CLAP Auto",
      clr_cd: "linear-gradient(#ffe479, #fffef8)",
      icon_path: "garbage-truck",
      ct: 0
    },
    {
      asrt_ctgry_nm: "BIG SWEEPERS",
      clr_cd: "linear-gradient(#deaa74, #ffffff)",
      icon_path: "bigsweeper",
      ct: 0
    },
    {
      asrt_ctgry_nm: "LARGE COMPACTOR",
      clr_cd: "linear-gradient(#6dffe3, #ffffff)",
      icon_path: "compactor",
      ct: 0
    },
    {
      asrt_ctgry_nm: "MINI SWEEPER",
      clr_cd: "linear-gradient(#8a9afb, #ffffff)",
      icon_path: "minisweeper",
      ct: 0
    },
    {
      asrt_ctgry_nm: "SKID STEER LOADER",
      clr_cd: "linear-gradient(#6dffe3, #ffffff)",
      icon_path: "streetloader",
      ct: 0
    },
    {
      asrt_ctgry_nm: "SMALL COMPACTOR",
      clr_cd: "linear-gradient(#e18afb, #ffffff)",
      icon_path: "compactor",
      ct: 0
    },
    {
      asrt_ctgry_nm: "Tractor",
      clr_cd: "linear-gradient(#72efff, #ffffff)",
      icon_path: "tractor",
      ct: 0
    },
    ]
    this.crdSmryData = [];
    this.ttl_cnt = 0
    var postslctdDt = {
      dt: moment(this.slctdDt).format('YYYY-MM-DD'),
      slctd_tnt: this.slctdTnt,
      dbrd_lvl: this.dbrd_lvl,
      asrt_cd: this.selectedCtgry ? this.selectedCtgry : 0,
      dstrct_id: this.slctdDstrct ? this.slctdDstrct : 0,
      svm_id:this.slctdSvm ? this.slctdSvm:0
    }
    console.log(postslctdDt)
    this.showLdr = true;
    let rte = `dashboard/enterprise/sacVeh/getCtgryCounts/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.crdSmryData = res['data'];
        // console.log(this.crdSmryData)
        this.showLdr = false;
        // this.ttl_cnt=0
        for (let count of this.crdSmryData) {

          this.ttl_cnt = this.ttl_cnt + count.ct
        }
        let found = false
        for (let j = 0; j < orgnlArry.length; j++) {
          let found = false;
          for (let i = 0; i < this.crdSmryData.length; i++) {
            if (this.crdSmryData[i].asrt_ctgry_nm === orgnlArry[j].asrt_ctgry_nm) {
              console.log(this.crdSmryData[i].asrt_ctgry_nm);
              found = true;
              break;
            }
          }

          if (!found) {
            this.crdSmryData.push({
              asrt_ctgry_nm: orgnlArry[j].asrt_ctgry_nm,
              ct: 0,
              clr_cd: orgnlArry[j].clr_cd,
              icon_path: orgnlArry[j].icon_path


            });
          }
        }

        console.log(this.ttl_cnt);
        console.log(this.crdSmryData)
        // this.selectedCtgry = this.crdSmryData[0].asrt_ctgry_cd;
        this.getTblSmry();
      }
    }, (err) => {
      // console.log(err);
    });

  }
  getTblSmry() {
    this.showLdr = true;
    this.columnDefs = [];
    this.rowData = [];
    var postslctdDt = {
      dt: moment(this.slctdDt).format('YYYY-MM-DD'),
      slctd_tnt: this.slctdTnt,
      tnt_nm :this.tnt,
      dbrd_lvl: this.dbrd_lvl,
      asrt_cd: this.selectedCtgry ? this.selectedCtgry : 0,
      dstrct_id: this.slctdDstrct ? this.slctdDstrct : 0,
      svm_id:this.slctdSvm ? this.slctdSvm:0
    }
    console.log(postslctdDt)
    let rte = `dashboard/enterprise/sacVeh/getSummary/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.rowData = res['data'];
        console.log(this.dbrd_lvl,this.dbrd_ky)
        this.showLdr = false;

        if (this.dbrd_lvl == 0) {
          if(this.dbrd_ky == 'urban'){
            this.columnDefs = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false  },
              { headerName: 'District', field: 'dstrct_nm', alignment: 'left', filter: false, cellClass: 'pm-grid-number-cell', search: true, summaryType: "custom", displayFormat: "{0}", },
              { headerName: 'ULB', alignment: 'left', width: 150, field: 'tnt_nm', cellClass: 'pm-grid-number-cell', summaryType: "custom", displayFormat: "{0}", },
              { headerName: 'Total Vehicles', alignment: 'center', field: 'ttl', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: "{0}", },
              { headerName: 'Operated Vehicles', alignment: 'left', field: 'operated', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: "{0}", },
              { headerName: 'Non-Operated Vehicles', alignment: 'left', field: 'nt_opveh', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: "{0}", },
            ]
          }
        else{
          this.columnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false  },
            { headerName: 'District', field: 'dstrct_nm', alignment: 'left', filter: false, cellClass: 'pm-grid-number-cell', search: true, summaryType: "custom", displayFormat: "{0}", },
            { headerName: 'Mandal', alignment: 'left', width: 150, field: 'tnt_nm', cellClass: 'pm-grid-number-cell', summaryType: "custom", displayFormat: "{0}", },
            { headerName: 'Total Vehicles', alignment: 'center', field: 'ttl', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: "{0}", },
              { headerName: 'Operated Vehicles', alignment: 'left', field: 'operated', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: "{0}", },
              { headerName: 'Non-Operated Vehicles', alignment: 'left', field: 'nt_opveh', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: "{0}", },
          ]
        }
        
        }
        else if (this.dbrd_lvl == 1) {
          if (this.dbrd_ky == 'urban') {            
            this.columnDefs = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false ,summaryType: "custom", displayFormat: "{0}", },
              { headerName: 'Sachivalayam Name', alignment: 'left', width: 150, field: 'snm_nm', cellClass: 'pm-grid-number-cell', summaryType: "custom", displayFormat: "{0}", },
              { headerName: 'Total Vehicles', alignment: 'center', field: 'ct', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: " {0}", },
              { headerName: 'Operated Vehicles', alignment: 'center', field: 'op_veh', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: " {0}", },
              { headerName: 'Not-Operated Vehicles', alignment: 'center', field: 'nt_opveh', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: " {0}", },
              { headerName: 'Total Trips', alignment: 'left', field: 'trips', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
              { headerName: 'Operated Trips', alignment: 'left', field: 'op_trips', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
              { headerName: 'Not Operated Trips', alignment: 'left', field: 'nt_optd', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
            ]
          }
          else {
            this.columnDefs = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false ,summaryType: "custom", displayFormat: "{0}", },
              { headerName: 'Grama Panchayat', alignment: 'left', width: 150, field: 'asrt_grp_nm', cellClass: 'pm-grid-number-cell', summaryType: "custom", displayFormat: "{0}", },
              { headerName: 'Vehicle Number', alignment: 'left', width: 150, field: 'asrt_nm', cellClass: 'pm-grid-number-cell', summaryType: "custom", displayFormat: "{0}", },
              { headerName: 'Total Vehicles', alignment: 'center', field: 'ct', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: " {0}", },
              { headerName: 'Operated Vehicles', alignment: 'center', field: 'op_veh', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: " {0}", },
              { headerName: 'Not-Operated Vehicles', alignment: 'center', field: 'nt_opveh', cellClass: 'pm-grid-number-cell', summaryType: "sum", displayFormat: " {0}", },
              { headerName: 'Total Trips', alignment: 'left', field: 'trips', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
              { headerName: 'Operated Trips', alignment: 'left', field: 'op_trips', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
              { headerName: 'Not Operated Trips', alignment: 'left', field: 'nt_optd', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
            ]
          }
        }
        else if (this.dbrd_lvl == 2) {
          console.log(this.rowData)
          this.columnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false ,summaryType: "custom", displayFormat: "{0}", },
            { headerName: 'Vehicle', alignment: 'left', field: 'asrt_nm', cellClass: 'pm-grid-number-cell', search: true, summaryType: "custom", displayFormat: "{0}", },
            { headerName: 'Total Trips', alignment: 'left', field: 'trips', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
            { headerName: 'Operated Trips', alignment: 'left', field: 'op_trips', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
            { headerName: 'Not Operated Trips', alignment: 'left', field: 'nt_optd', cellClass: 'pm-grid-number-cell', search: true, summaryType: "sum", displayFormat: "{0}", },
  
          ]
        }
      }
    }, (err) => {
      // console.log(err);
    });
  }
  onCellPrepared(e) {
    if (
      e.column.dataField == "tnt_nm" ||
      e.column.dataField == "snm_nm"
    ) {

      if (e.data) {
        e.cellElement.style.color = "rgb(39, 153, 234)";
        e.cellElement.style.cursor = `pointer`;
        e.cellElement.style.fontWeight = `500`;
      }

    }
    if (e.column.sortOrder) {
      if (e.column.dataField == "ct") {
        if (e.column.sortOrder == "asc") {
          this.desc(e);
        } else {
          this.asc(e);
        }
      }
    }

    // console.log(e)
  }
  asc(e) {
    if (e.value) {

      e.cellElement.bgColor = "#d5fa95";


    } else if (e.value == 0) {

      e.cellElement.bgColor = "#c92236";

    }
  }
  desc(e) {
    if (e.value) {

      e.cellElement.bgColor = "#fcdfdf";


    } else if (e.value == 0) {
      e.cellElement.bgColor = "#c92236";
    }
  }
  exportSettings = {
    enabled: true,
    fileName: 'CHK_FRM_RPT'
  };

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'gis_prcl List.xlsx');
      });
    });
  }

  
  getUpdtDtls1(e) {
    // this.ttl_cnt = 0
    console.log(e);
    var dataGrid = e.component;

    // Clear the search panel text
    dataGrid.option("searchPanel.text", "");
    if (e.data && e.column.dataField == 'tnt_nm') {
      this.dbrd_lvl = 1;
      this.slctDistnm = e.data.dstrct_nm
      this.slctulbnm = e.data.tnt_nm
      this.slctdTnt = e.data.tnt_id
      this.tnt = e.data.tnt_nm
      this.getDbrdData();
    }
    else if (e.data && e.column.dataField == 'snm_nm') {
      this.dbrd_lvl = 2;
      this.slctulbnm = e.data.tnt_nm
      this.slctdTnt = e.data.tnt_id
      this.slctdSVMNm = e.data.svm_nm
      this.slctdSvm= e.data.svm_id
      this.getDbrdData();
    }

  }
  getvehicles(v) {
    console.log(v,'v')
    console.log(v.ct,'v.ct')
    if(v.ct !==0){
      console.log('ppppp')
      this.selectedCtgry = v.asrt_ctgry_cd ? v.asrt_ctgry_cd : 0
      this.grid_backd = v.clr_cd
      this.getTblSmry();
      // this.getSmryCnts()
    }
     
   else{
    this.grid_backd = v.clr_cd;
    this.rowData=[]
    this.columnDefs=[]
   }
   

  }
  calculateSummary(options) {


  }
  backto() {
    if (this.dbrd_lvl == 1) {
      this.selectedCtgry = 0
    }

    this.dbrd_lvl = this.dbrd_lvl - 1;
    this.getSmryCnts();
    this.getTblSmry();
  }
}
