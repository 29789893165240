<div class="row hdrBar">
    <div class="col-lg-8 col-md-8 mg-a">
        <h1>Vendor Dashboard</h1>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;"></div>
</div>
<div class="row">
    <div class="col-lg-4 col-md-4 mg-a">
        <p class="slctlbl">Vehicle Vendor</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
            nzPlaceHolder="Select Vehicle Vendor" [ngModelOptions]="{standalone: true}">
            <!-- <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option> -->
            <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
        </nz-select>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" *ngIf="tntLst && tntLst.length>1">
        <p class="slctlbl">ULB</p>
        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
            nzPlaceHolder="Select ULB" [ngModelOptions]="{standalone: true}">
            <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
            <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
        </nz-select>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" *ngIf="tntLst && tntLst.length==1">
        <p class="slctlbl">ULB</p>
        <p class="slctlbl dsblTxt">{{tntLst[0].tnt_nm}}</p>
    </div>
    <div class="col-lg-4 col-md-4 mg-a">
    </div>
    <!-- <div class="col-lg-3 col-md-3 mg-a">
        <p class="slctlbl">Year</p>
        <nz-select class="slctBx" [(ngModel)]="selectedYear" (ngModelChange)="onSelectMnthYr()"
            [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let y of years" [nzValue]="y" nzLabel="{{y}}"></nz-option>
        </nz-select>
    </div>

    <div class="col-lg-3 col-md-3 mg-a">
        <p class="slctlbl">Month</p>
        <nz-select class="slctBx" [(ngModel)]="selectedmonth" (ngModelChange)="onSelectMnthYr()"
            [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let m of months" [nzValue]="m.id" nzLabel="{{m.name}}"></nz-option>
        </nz-select>
    </div> -->
</div>
<div class="main">

    <div class="row">
        <ul class="cards" *ngFor="let c of cardSummary">
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #816bb1 0%,#5149aa 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Vehicles</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{c.tot_veh === null ? 0 : c.tot_veh}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{c.ulbs_ct === null ? 0 : c.ulbs_ct}}
                                </h1>
                                <h1 class="card_sub_title"> ULB(s)</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item ">
                <div class="card_g" style="background:linear-gradient(135deg, #58d162 0%,#585e0d 100%);">

                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Vehicles Mode</h1>
                        </div>
                        <!-- <div class="row crdHdr_sb">
                            <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="car" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title"></h1>
                            </div>
                        </div> -->
                       <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{c.usd_veh == null ? "0" : c.usd_veh}}
                                </h1>
                                <h1 class="card_sub_title">Regular Vehicles</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{c.spare_veh == null ? "0" : c.spare_veh}}
                                </h1>
                                <h1 class="card_sub_title">Spare Vehicles</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #3a536b 0%,#145580 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Vehicles Status</h1>
                        </div>
                        <!-- <div class="row crdHdr_sb">
                            <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="car" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title"></h1>
                            </div>
                        </div> -->
                       <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{c.onl_cnt == null ? "0" : c.onl_cnt}}
                                </h1>
                                <h1 class="card_sub_title">Online</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{c.off_cnt == null ? "0" : c.off_cnt}}
                                </h1>
                                <h1 class="card_sub_title">Offline</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg,#ac4977  0%,#c24459 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Total Drivers</h1>
                        </div>
                        <!-- <div class="row crdHdr_sb">
                            <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="man" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title">{{c.drvr_cnt == null ? "0" : c.drvr_cnt}}</h1>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{c.drvr_cnt == null ? "0" : c.drvr_cnt}}
                                </h1>
                                <h1 class="card_sub_title">Drivers</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="main">

        <div class="row bg-wp">
            <h1>Vendor Vehicles Data</h1>
            <div class="ds-card grid tbl">
                <dx-data-grid id="gridContainer" [dataSource]="vehcleTble" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                    [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                    [wordWrapEnabled]="true" (onExporting)="onExporting($event)">
                    <!-- [masterDetail]="{ enabled: true, template: 'detail' }" -->
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [visible]="vehcleTble.length>10" [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 20, 30, 50]" [showInfo]="true">
                    </dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                    </dxo-search-panel>
                    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                    <dxo-group-panel [visible]="true"></dxo-group-panel>
                    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                    <dxo-column-chooser [enabled]="true" mode="select">
                    </dxo-column-chooser>
                    <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                    <dxo-export [enabled]="true" fileName="vendor Vehicles Data"></dxo-export>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [caption]="d.headerName"
                        [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                    </dxi-column>
                    <dxi-column [showInColumnChooser]="false" [visible]="true" type="vehicleButtons" [width]="100"
                        caption="View" cellTemplate="editTemplate">
                        <div *dxTemplate="let data of 'editTemplate'">
                            <div class="tblEdtBtn" (click)="showVehicles(data,'all')">vehicles</div>
                        </div>
                    </dxi-column>
                    <dxi-column [showInColumnChooser]="false" [visible]="true" type="offlinesButtons" [width]="100"
                        caption="View" cellTemplate="editTemplate2">
                        <div *dxTemplate="let data of 'editTemplate2'">
                            <div class="tblEdtBtn" (click)="showVehicles(data,'off')">Offlines</div>
                        </div>
                    </dxi-column>


                </dx-data-grid>
            </div>
        </div>
        <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
            [nzMaskClosable]="false" [nzWidth]="1100" [nzVisible]="shwSidebar" class="sdr"(nzOnClose)="closeSideBar();">
            <ng-container *nzDrawerContent>
            <div class="row hdr">
                <div class="col-lg-10 col-md-10">
                    <h2 class="sdHdr"> {{sidebarHeading}}</h2>
                </div>
                <div class="col-lg-2 col-md-2 mg-a" (click)="closeSideBar();" style="text-align:right;">
                    <i nz-icon nzType="close-circle" nzTheme="outline" style="font-size: 20px;"></i>
                </div>
            </div>
            <div class="row bg-wp">
                <div class="ds-card grid tbl">
                    <dx-data-grid id="gridContainer" [dataSource]="vehiclesData" [remoteOperations]="true"
                        [showBorders]="true" [remoteOperations]="false" [allowColumnReordering]="true"
                        [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showColumnLines]="true"
                        [showRowLines]="true" [showBorders]="true" [wordWrapEnabled]="true" (onExporting)="onExporting($event)">
                        <dxo-search-panel [visible]="true" [width]="180" placeholder="Search..."></dxo-search-panel>
                        <dxo-export [enabled]="true" fileName="vendor All Vehicles Data"></dxo-export>
                        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 30, 50]"
                            [showInfo]="true"></dxo-pager>
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxi-column *ngFor="let d of columnDefs1" [dataField]="d.field" [caption]="d.headerName"
                            [alignment]="d.alignment" [width]="d.width" allowResizing="true">
                        </dxi-column>
                        <dxi-column [showInColumnChooser]="false" [visible]="true" type="statusButtons" [width]="130"
                            caption="Vehicle Status" cellTemplate="editTemplate3">
                            <div *dxTemplate="let data of 'editTemplate3'">{{Disabled}}
                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Status" style="width: 100%;"
                                    [(ngModel)]="data.data.asrt_sts_id" (ngModelChange)="changeStatus(data)" [nzDisabled]="Disabled==true">
                                    <nz-option [nzLabel]="c.asrt_sts_nm" [nzValue]="c.asrt_sts_id"
                                        *ngFor="let c of statuses">
                                    </nz-option>
                                </nz-select>
                            </div>
                        </dxi-column>
                        <dxi-column [showInColumnChooser]="false" [visible]="true" type="modeButtons" [width]="120"
                            caption="Vehicle Mode" cellTemplate="editTemplate4">
                            <div *dxTemplate="let data of 'editTemplate4'">
                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Mode" style="width: 100%;"
                                    [(ngModel)]="data.data.asrt_spre_in" (ngModelChange)="changeMode(data)">
                                    <nz-option [nzLabel]="c.asrt_spre_nme" [nzValue]="c.asrt_spre_in"
                                        *ngFor="let c of vehMode">
                                    </nz-option>
                                </nz-select>
                            </div>
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </div>
            </ng-container>
        </nz-drawer>
    </div>
</div>