<ds-loading *ngIf="showLdr"></ds-loading>
<div class="row hdrBar">
    <div class="col-lg-12 col-md-12 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">{{tntNm}} ULB</h1>
    </div>
</div>
<div class="main">
    <!-- <nz-radio-group class="btnsgrp" [(ngModel)]="actvtbleNm" nzButtonStyle="solid">
        <label nz-radio-button nzType="primary" nzValue="crdOne" class="svmBtn" (click)="cardOne()">ATTENDANCE</label>
        <label nz-radio-button nzType="primary" nzValue="crdTwo" class="svmBtn" (click)="cardTwo()">VEHICLE
            TRACKING</label>
        <label nz-radio-button nzType="primary" nzValue="crdThree" class="svmBtn"
            (click)="cardThree()">WEIGHMENT</label>
    </nz-radio-group>
<div class="row bg-wp" *ngIf="crdOne==true">
        <app-ulb-attendace [tenant]="selectedtenant"></app-ulb-attendace>
    </div>
    <div class="row" *ngIf="crdTwo==true">
        <app-analysis-dashboard [tenant]="selectedtenant"></app-analysis-dashboard>
    </div>
    <div class="row bg-wp" *ngIf="crdThree==true">
        <app-weighment-dashboard [tenant]="selectedtenant" style="width: 100%;"></app-weighment-dashboard>
    </div> -->
        <nz-tabset nzType="card">
            
            <nz-tab class="bg-wp" nzTitle="ATTENDANCE"><app-ulb-attendace [tenant]="selectedtenant"></app-ulb-attendace></nz-tab>
            <nz-tab class="bg-wp" nzTitle="VEHICLES"><app-analysis-dashboard
                    [tenant]="selectedtenant"></app-analysis-dashboard></nz-tab>
            <nz-tab class="bg-wp" nzTitle="WEIGHMENT"><app-weighment-dashboard
                    [tenant]="selectedtenant"></app-weighment-dashboard></nz-tab>
        </nz-tabset>

</div>