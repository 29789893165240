import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';
import * as L from 'leaflet';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DrawEvents, featureGroup, FeatureGroup, icon, latLng, tileLayer } from 'leaflet';
import { circle, geoJSON, MapOptions, Layer, marker, Marker, polygon } from 'leaflet';

import { LeafletLayersDemoModel } from 'src/app/apps/maps/map-base/layers.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import 'src/app/apps/maps/leaflet-ruler.js';
import { truncateWithEllipsis } from '@amcharts/amcharts4/.internal/core/utils/Utils';
// import '../lib/leaflet-search/leaflet-search.js'

@Component({
  selector: 'app-trip-routes-dashboard',
  templateUrl: './trip-routes-dashboard.component.html',
  styleUrls: ['./trip-routes-dashboard.component.scss']
})
export class TripRoutesDashboardComponent implements OnInit {
  header_label ={
    name:'Trips Dashboardss'
  }
  dateFormat = 'dd/MM/yyyy';
  monthFormat = 'yyyy/MM';
  selectedValue = null;
  showLdr = false; vehCtgrys;
  wrds; postslctdDt;
  selectedCtgry = 0;
  slctdWrd = 0;
  slctdDt = new Date(); curDate; curTblDate; rteDta; rdCnts;
  vehTrpLst: any;
  clntDtls: any;
  today = new Date();
  routemap: any = [];
  route_Polyline: any = [];
  tntLst: any = [];
  selectedtenant = 0;
  searchText;
  schdStartPBMarker: any = [];
  schdEndPBMarker: any = [];
  startPBMarker1: any;
  endPBMarker1: any;
  polylinePoints: any;
  actl_Polyline: any;
  ipMarker: any;
  ipIcon = new L.Icon({
    iconUrl: '/assets/images/markers/mapIcons/m/colctn_pnt_nt_cvrd_m.png',
    iconSize: [42, 45],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  ipPts: any = [];
  mrkerPts: any;
  rtrstrng: string;
  shwAddBtn: boolean;
  rte_id: any;
  rte_nm: any;
  trp_sqnc_nu: any;
  fncDtls: any;
  cvrdIps: any=[];
  ipMarker1: any;
  strngarc: string;
  showsetRte: boolean;
  vehDupTrpLst: any = [];
  trp_schde_id: any;
  shwNodata: boolean;
  rte_plyln_id: any;
  vendorList: any[];
  selectedvndr: any;
  userDtls: any;
  showVndr: boolean = false;
  clap_in: any;
  ShowClap: boolean = false;
  wardLst: any;
  wrdfncepolygon: L.Polygon<any>;
  wrdplypoint: any = [];
  schdStartMarker: any;
  schdEndMarker: any;
  // map polygon edit variables
  data = {
    toolbar: []
  }
  that;
  map: any;
  optionsSpec: any = {
    layers: [{ url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', attribution: 'Open Street Map' }],
    zoom: 5,
    center: [46.879966, -121.726909]
  };

  LAYER_OSM = {
    id: 'openstreetmap',
    name: 'Open Street Map',
    enabled: true,
    layer: tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: 'Open Street Map'
    })
  };

  LAYER_COUNTRIES = {
    id: 'Countries',
    name: 'Countries',
    enabled: true,
    layer: tileLayer('http://{s}.tiles.mapbox.com/v3/milkator.press_freedom/{z}/{x}/{y}.png', {
      maxZoom: 5,
      attribution: 'Map data &copy; 2013 Natural Earth'
    })
  };

  settings = {
    popover: { "sec_title": "", "sec_dscn": "" }
  }
  isVisible: boolean;
  rteSaveData: any;
  OnHoverOption(optn) {
    // console.log("In OnHoverOption")
    // console.log(optn)
    this.settings.popover.sec_dscn = optn.dscn_tx
    this.settings.popover.sec_title = optn.optn_nm
  }

  map_stng: any = {
    "fnce_grp_hndlr_tx": "fences"
    , "show_sidebar": false
    , "show_detail_sidebar": false
    , "show_drill_sidebar": false
    , "show_grid": false
    , "show_build_sidebar": false
    , "undo_flag": false
    , "show_coordinates": true

  };
  map_data: any = {
    "sidebar_hdng": ""
    , "sidebar_detail_hdng": ""
    , "sidebar_drill_hdng": ""
    , "selected_toolbar_hndr_tx": ""
    , "fences_groups": []
    , "fence_list": []
    , "map_data.mrkers_list_list": []
  }
  edit_fence: any = {
    "fnce_type": "",
    "crtd_fnce_type": "",
    "fnce_nm": "",
    "dscn_tx": "",
    "imge_url_tx": "",
    Opacity: "30",
    "FillOpacity": "30",
    "stroke": 2,
    "weight": 2,
    "dashArray": '2,2',
    "color": '#8c90f2',
    "fill": true,
    "fillColor": '#8c90f2',
    "stroke_type": "DIRECTED_ROUTE",
    "marker_id": 1,
    "marker_nm": "RED_MARKER",
    "marker_icn_url": "007-placeholder.svg",
    "lat": null,
    "lng": null,
    "latlng_array": [],
    "radius": null
  }
  fnce_drawer = {
    "circleDrawer": null,
    "polylineDrawer": null,
    "polygonDrawer": null,
    "markerDrawer": null
  };
  master_data: any = {
    marker_icons: []
  }
  layers: Layer[];
  model = new LeafletLayersDemoModel(
    [this.LAYER_OSM],
    this.LAYER_OSM.id,

  );
  leafletOptions = {
    center: latLng(17.004393, 81.783325),
    zoom: 15,
    maxZoom: 19,

  }



  centerMap(e) {
    console.log("e.latlng")
    console.log(e.latlng)
    console.log("this.options.center")
    console.log(this["options"].center)
    this["options"].center = e.latlng;
    console.log(this)
    //this.map.panTo(e.latlng);
  }

  zoomIn(e) {
    this.map.zoomIn();
  }

  zoomOut(e) {
    this.map.zoomOut();
  }
  editablLayers = new FeatureGroup();

  constructor(public apiServ: AppsService, private message: NzMessageService, private modal: NzModalService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = this.tntLst[0].tnt_id;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    // console.log(this.userDtls);
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
      this.selectedvndr = 0;
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  ngOnInit() {
    this.initialize();
    this.slctdDt = new Date();
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.get_vehCategories();
    this.selectCategory(0, this.curDate);
    this.get_wards();
    this.selectWard(0, this.curDate);
    this.get_rtDvtdTrips(this.curDate);
    this.getVendors();
  }
  ontntSelect(value) {
    console.log(value);
    if (value === 0) {
      this.selectedtenant = 0;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tntLst.length; i++) {
      if (this.tntLst[i].tnt_id === value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
      }
    }
    this.get_rtDvtdTrips();
  }

  initialize = function () {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.usrDtls);
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.routemap = L.map('routeDevtmap', {
        center: [this.usrDtls[0].lat, this.usrDtls[0].lng],
        zoom: 3,
      });

      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   // maxZoom: 15,
      //   // minZoom: 3,
      //   attribution: ''
      // });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: ''
      });
      tiles.addTo(this.routemap);
      this.routemap.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 13);

    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
    // this.setVehicleMarkers();
  }
  get_vehCategories(): any {
    this.showLdr = true;
    this.apiServ.get(`dashboard/enterprise/get_vehCtgrys`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.vehCtgrys = res['data'];
          console.log(this.vehCtgrys)
        }
        this.showLdr = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  get_wards(): any {
    this.showLdr = true;
    this.apiServ.get(`drivers/vehicles/wards/${this.selectedtenant}`).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.wrds = res['data'];
          console.log(this.wrds)
        }
        this.showLdr = false;
      }
    }, (err) => {
      console.log(err);
    });

  }

  get_rtDvtdTrips(funDt?): any {
    this.showLdr = true;
    this.slctdDt = funDt;
    console.log(this.slctdDt);
    console.log(this.selectedtenant);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      rte_dev_in: 0,
      selectedtenant: this.selectedtenant,
      vndr_id: this.selectedvndr,
      wrd_id: this.slctdWrd
    };
    console.log(this.postslctdDt);
    let rte = `dashboard/enterprise/get_rdTripsCnt`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        this.showLdr = false;
        if (res_data && res_data.length > 0) {
          this.rdCnts = res['data'];
          console.log(this.rdCnts)
        }
      }
    }, (err) => {
      console.log(err);
    });
  }



  onChange(e): void {
    console.log('onChange: ', e);
    this.slctdDt = e;
    console.log('slctdDt: ', this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    console.log(this.curDate, this.selectedCtgry);
    this.selectCategory(0, this.curDate);
    this.selectWard(0, this.curDate);
    this.get_rtDvtdTrips(this.curDate);
  }

  selectCategory(evnt, dt?) {
    this.showLdr = true;
    this.vehTrpLst = [];
    this.vehDupTrpLst = [];
    console.log(evnt, this.curDate);
    this.selectedCtgry = evnt;
    this.postslctdDt = {
      dt: this.curDate,
      id: evnt,
      rte_dev_in: 0,
      tnt_id: this.selectedtenant,
      vndr_id: this.selectedvndr,
      wrd_id: this.slctdWrd,
      strt_fnce_id:0,
      end_fnce_id:0,
    };
    console.log(this.postslctdDt);
    const rte = `dashboard/enterprise/get_vehTrpsLst`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.shwNodata = false;
          this.vehTrpLst = res['data'];
          this.showLdr = false;

          this.vehDupTrpLst = this.vehTrpLst;
          console.log(this.vehTrpLst);
          // this.getTripMap(this.vehTrpLst[0],this.slctdDt);
          // tslint:disable-next-line:prefer-for-of
          for (let v = 0; v < this.vehTrpLst.length; v++) {
            this.vehTrpLst[v].grp_nm = this.vehTrpLst[v].asrt_nm;
          }
        } else {
          this.showLdr = false;
          this.shwNodata = true;
        }

      }
    }, (err) => {
      console.log(err);
    });
  }

  selectWard(evnt, dt?) {
    this.showLdr = true;
    this.vehTrpLst = [];
    this.vehDupTrpLst = [];
    console.log(evnt, this.curDate);
    this.slctdWrd = evnt;
    this.postslctdDt = {
      dt: this.curDate,
      id: this.selectedCtgry,
      rte_dev_in: 0,
      tnt_id: this.selectedtenant,
      vndr_id: this.selectedvndr,
      wrd_id: evnt,
      strt_fnce_id:0,
      end_fnce_id:0,
    };
    console.log(this.postslctdDt)
    const rte = `dashboard/enterprise/get_vehTrpsLst`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        console.log(res_data)
        if (res_data && res_data.length > 0) {
          this.showLdr = false;
          this.shwNodata = false;
          this.vehTrpLst = res['data'];
          this.vehDupTrpLst = this.vehTrpLst;
          console.log(this.vehTrpLst);
          // this.getTripMap(this.vehTrpLst[0],this.slctdDt);
          // tslint:disable-next-line:prefer-for-of
          for (let v = 0; v < this.vehTrpLst.length; v++) {
            this.vehTrpLst[v].grp_nm = this.vehTrpLst[v].wrd_nm;

          }
        } else {
          this.showLdr = false;
          this.shwNodata = true;
        }

      }
    }, (err) => {
      console.log(err);
    });
  }

  getTripMap(c, dt) {
    this.rte_nm = '';
    this.showLdr = true;
    console.log(c, dt);
    console.log(this.schdStartPBMarker)
    this.rte_nm = c.rte_nm;
    this.trp_sqnc_nu = c.trp_sqnc_nu;
    this.trp_schde_id = c.trp_schde_id;
    this.rte_plyln_id = c.rte_plyln_id;
    if (this.schdStartMarker) {
      this.routemap.removeLayer(this.schdStartMarker);
    }

    if (this.schdEndMarker) {
      this.routemap.removeLayer(this.schdEndMarker);
    }

    // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
    var startIcon = new L.Icon({
      iconUrl: '/assets/images/markers/mapIcons/m/location.png',
      iconSize: [33, 33],
      iconAnchor: [16, 28],
    });
    this.schdStartMarker = L.marker([c.strt_fnce_lat, c.strt_fnce_lng], {
      icon: startIcon
    }).addTo(this.routemap).bindTooltip(c.strt_fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();

    // ----------Fence END Area (flag position) -- Leaflet OS ----------//
    var endIcon = new L.Icon({
      iconUrl: '/assets/images/markers/mapIcons/m/location.png',
      iconSize: [33, 33],
      iconAnchor: [16, 28],
    });

    this.schdEndMarker = L.marker([c.end_fnce_lat, c.end_fnce_lng], {
      icon: endIcon
    }).addTo(this.routemap).bindTooltip(c.end_fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();

    this.vehTrpLst.forEach(ele => {
      ele.shwAddBtn = false;
      this.showsetRte = false;
      if (ele.trp_schde_id != c.trp_schde_id) {
        ele.isSelected = false;
      } else {
        ele.isSelected = true;
      }
    });
    this.getWrdbyAsrt(c.asrt_id)
    if (this.route_Polyline != undefined) {
      this.routemap.removeLayer(this.route_Polyline);
    }



    // });

    if (c.rte_arc_lne_tx && c.rte_arc_lne_tx != null) {
      this.polylinePoints = JSON.parse(c.rte_arc_lne_tx);
    } else {
      this.showLdr = false;
      // this.createMessage('warning', 'No Polyline Added to the Trip.To add polyline visit wetrackon App')
    }
    if (this.schdStartPBMarker) {
      this.routemap.removeLayer(this.schdStartPBMarker);
    }

    if (this.schdEndPBMarker) {
      this.routemap.removeLayer(this.schdEndPBMarker);
    }
    // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
    var startIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/startPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    if (this.polylinePoints && this.polylinePoints[0]) {
      console.log(this.polylinePoints[0])
      this.schdStartPBMarker = L.marker(this.polylinePoints[0], {
        icon: startIcon
      }).addTo(this.routemap);
    }

    // ----------Fence END Area (flag position) -- Leaflet OS ----------//
    var endIcon = new L.Icon({
      iconUrl: '/assets/images/markers/playback/endPoint.png',
      iconSize: [42, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    if (this.polylinePoints) {
      var k = this.polylinePoints.length - 1
      if (this.polylinePoints[k]) {
        this.schdEndPBMarker = L.marker(this.polylinePoints[k], {
          icon: endIcon
        }).addTo(this.routemap);
      }
    }


    if (c.rte_arc_lne_tx) {
      this.route_Polyline = L.polyline(JSON.parse(c.rte_arc_lne_tx), {
        color: '#008000',
        weight: 5,
        lineCap: 'butt',
        opacity: 0.6,
        smoothFactor: 10.0,
        lineJoin: 'round'
      }).addTo(this.routemap);
      this.routemap.fitBounds(this.route_Polyline.getBounds());
    }
    console.log(c.ipPts)
    this.getCvrdFncs(c);
    if (c.start_tm != '0000-00-00 00:00:00' && c.end_tm != '0000-00-00 00:00:00') {
      this.curDate = moment(dt).format('YYYY-MM-DD');
      this.rte_id = c.rte_id;
      this.postslctdDt = {
        dt: this.curDate,
        data: c
      }
      // Getting travelled route line arc
      const rte = `dashboard/enterprise/get_tripTrvlRoute`;
      this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
        this.showLdr = true;

        if (res['status'] == 200) {
          var res_data = res['data'];
          if (res_data && res_data.length > 0) {
            this.showLdr = false;
            this.rteDta = res['data'];
            console.log(this.rteDta)
            var restr = '';
            this.rtrstrng = '';
            var arcrestr = '';

            var tmp;
            for (var i = 0; i < this.rteDta.length; i++) {
              restr += (restr == "") ? "[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]" : ",[" + this.rteDta[i].lat + "," + this.rteDta[i].lng + "]";
              this.rtrstrng = restr;
              arcrestr += (arcrestr == "") ? this.rteDta[i].lng + " " + this.rteDta[i].lat : "," + this.rteDta[i].lng + " " + this.rteDta[i].lat;

            }

            var lstrng = arcrestr.split("[").join("");
            var lstrng2 = lstrng.split("]").join("");
            this.strngarc = lstrng2;
            // console.log("LINESTRING(" + lstrng2 + ")");

            this.vehTrpLst.forEach(ele => {
              if (ele.rte_id == c.rte_id && this.rtrstrng.length > 0 && ele.trp_rn_id == c.trp_rn_id) {
                ele.shwAddBtn = true;
                // if(ele.shwAddBtn==true){
                this.showsetRte = true;
                // }else{
                //   this.showsetRte=false;
                // }           
              } else {
                ele.shwAddBtn = false;
                this.showsetRte = false;
              }

            });
            // if (restr) {
            //   this.shwAddBtn = true;
            // } else {
            //   this.shwAddBtn = false;
            // }
            // console.log("[" + restr + "]");
            if (this.actl_Polyline) {
              this.routemap.removeLayer(this.actl_Polyline);
            }
            if (this.startPBMarker1) {
              this.routemap.removeLayer(this.startPBMarker1);
            }
            if (this.endPBMarker1) {
              this.routemap.removeLayer(this.endPBMarker1);
            }
            // ----------Fence Start Area (flag position) -- Leaflet OS ----------//
            var startIcon1 = new L.Icon({
              iconUrl: '/assets/images/markers/playback/startPointbrwn.png',
              iconSize: [42, 45],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41]
            });
            this.startPBMarker1 = L.marker([this.rteDta[0].lat, this.rteDta[0].lng], {
              icon: startIcon1
            }).addTo(this.routemap);
            // ----------Fence END Area (flag position) -- Leaflet OS ----------//
            var endIcon1 = new L.Icon({
              iconUrl: '/assets/images/markers/playback/endPointbrwn.png',
              iconSize: [42, 45],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41]
            });
            var k1 = this.rteDta.length - 1
            this.endPBMarker1 = L.marker(this.rteDta[k1], {
              icon: endIcon1
            }).addTo(this.routemap);


            this.actl_Polyline = L.polyline(JSON.parse("[" + restr + "]"), {
              color: 'red',
              weight: 4,
              lineCap: 'butt',
              opacity: 0.5,
              smoothFactor: 10.0,
              lineJoin: 'round'
            }).addTo(this.routemap);
            this.routemap.fitBounds(this.actl_Polyline.getBounds());


          } else if (res_data.length == 0) {
            this.showLdr = false;
            if (this.actl_Polyline) {
              this.routemap.removeLayer(this.actl_Polyline);
            }
            if (this.startPBMarker1) {
              this.routemap.removeLayer(this.startPBMarker1);
            }
            if (this.endPBMarker1) {
              this.routemap.removeLayer(this.endPBMarker1);
            }
            this.createMessage('warning', 'Travelled Route not found!!!!');

          }
          else {
            this.showLdr = false;
            if (this.actl_Polyline) {
              this.routemap.removeLayer(this.actl_Polyline);
            }
            if (this.startPBMarker1) {
              this.routemap.removeLayer(this.startPBMarker1);
            }
            if (this.endPBMarker1) {
              this.routemap.removeLayer(this.endPBMarker1);
            }
            // this.createMessage('warning', 'No data found!!!!');
          }
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.createMessage('warning', 'Travelled Route not found!!!!');

    }

  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  addRoute() {
    var postData = {
      dt: this.curDate,
      rte_id: this.rte_id,
      rte_nm: this.rte_nm,
      rte: '[' + this.rtrstrng + ']',
      tnt_id: this.selectedtenant,
      trp_sqnc_nu: this.trp_sqnc_nu,
      rte_ln: "LINESTRING(" + this.strngarc + ")",
      trp_schde_id: this.trp_schde_id,
      rte_plyln_id: this.rte_plyln_id
    }
    console.log(postData)
    if (this.rtrstrng != undefined) {
      const rte1 = `dashboard/enterprise/addPolylne`;
      this.apiServ.post(postData, rte1).subscribe((res) => {
        if (res['status'] == 200) {
          this.createMessage('success', 'Schedule Route added Successfully and New route will reflect from tomorrow');
          this.selectCategory(0, this.curDate);
        }
      });
    }
  }
  getCvrdFncs(c) {
    if (this.ipPts) {
      for (var x = 0; x < this.ipPts.length; x++) {
        this.routemap.removeLayer(this.ipPts[x]);
      }
    }
    if (this.cvrdIps) {
      for (var y = 0; y < this.cvrdIps.length; y++) {
        this.routemap.removeLayer(this.cvrdIps[y]);
      }
    }

    var postData = {
      asrt_id: c.asrt_id,
      rte_id: c.rte_id,
      tnt_id: this.selectedtenant
    }
    const rte = `tripschedule/sachivalayam/trips/get_cvrd_fncs`;
    this.apiServ.post(postData, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.fncDtls = res['data'];
        if (this.fncDtls) {
          for (var i = 0; i < this.fncDtls.length; i++) {
            if (this.fncDtls[i].lat && this.fncDtls[i].lng) {
              if (this.fncDtls[i].fnce_cvr_sts == 1) {
                this.ipMarker = L.circleMarker([this.fncDtls[i].lat, this.fncDtls[i].lng], {
                  radius: 6,
                  fillColor: "#008000",
                  color: "#008000",
                  weight: 1,
                  opacity: 1,
                  fillOpacity: 1
                }).addTo(this.routemap).bindTooltip(this.fncDtls[i].fnce_nm, { permanent: false, opacity: 0.7 }).openTooltip();;

                this.ipPts.push(this.ipMarker);
              } else {
                this.ipMarker1 = L.circleMarker([this.fncDtls[i].lat, this.fncDtls[i].lng], {
                  radius: 6,
                  fillColor: "#e85339",
                  color: "#e85339",
                  weight: 1,
                  opacity: 1,
                  fillOpacity: 1
                }).addTo(this.routemap).bindTooltip(this.fncDtls[i].fnce_nm, { permanent: false, opacity: 0.7 }).openTooltip();;

                this.cvrdIps.push(this.ipMarker1);
              }
            }

            // this.createMessage('success', 'Schedule Route added Successfully');
            // this.selectCategory(0, this.curDate);
          }
        } else {
          if (this.ipPts) {
            for (var x = 0; x < this.ipPts.length; x++) {
              this.routemap.removeLayer(this.ipPts[x]);
            }
          }
          if (this.cvrdIps) {
            for (var y = 0; y < this.cvrdIps.length; y++) {
              this.routemap.removeLayer(this.cvrdIps[y]);
            }
          }
        }

      }
    });

  }
  getsort(id, type) {
    this.vehTrpLst = [];
    if (id == 1) {
      if (type == 'rd') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000 && e.rd_in == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'trvl') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000 && e.trvl_sts == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'nt') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000 && e.trvl_sts == 0) {
            this.vehTrpLst.push(e)
          }

        });
      }
      else {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000000) {
            this.vehTrpLst.push(e)
          }

        });
      }

    } else if (id == 0) {
      if (type == 'rd') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001 && e.rd_in == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'trvl') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001 && e.trvl_sts == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'nt') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001 && e.trvl_sts == 0) {
            this.vehTrpLst.push(e)
          }

        });
      } else {
        this.vehDupTrpLst.forEach(e => {
          if (e.trp_type_id == 1000001) {
            this.vehTrpLst.push(e)
          }

        });
      }
    } else {
      if (type == 'rd') {
        this.vehDupTrpLst.forEach(e => {
          if (e.rd_in == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'trvl') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trvl_sts == 1) {
            this.vehTrpLst.push(e)
          }

        });
      } else if (type == 'nt') {
        this.vehDupTrpLst.forEach(e => {
          if (e.trvl_sts == 0) {
            this.vehTrpLst.push(e)
          }

        });
      } else {
        this.vehTrpLst = this.vehDupTrpLst;
      }
    }
  }
  getVendors() {
    console.log(this.userDtls.dsgns_id)
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      if (this.selectedvndr && this.showVndr) {
        this.apiServ.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
          // console.log(res)
          if (res['status'] == 200) {
            this.vendorList = res['data'];
          }
        }, (err) => {
          // console.log("In errorr");
        })
      }
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
      this.selectedvndr = 0;
      this.selectCategory(0, this.curDate);

    } else {
      this.apiServ.get(`billing/vehicle-wise/vendors/list/${this.selectedtenant}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          // this.USER_PERMISSIONS = res['perm'][0];
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
          }
          this.selectCategory(0, this.curDate);
        }
      }, (err) => {
        // // console.log('In errorr');
      });
    }
  }
  onvndrSelect(value) {
    this.selectedvndr = value;
    this.selectCategory(0, this.curDate);
    this.get_rtDvtdTrips();

  }
  getWrdbyAsrt(asrt_id) {
    if (this.wrdplypoint) {
      for (var k = 0; k < this.wrdplypoint.length; k++) {
        this.routemap.removeLayer(this.wrdplypoint[k].ply);
      }
    }
    this.apiServ.get(`playback/wards/byVhcle/${asrt_id}`).subscribe((res) => {
      if (res['status'] === 200) {
        this.wardLst = res['data'];
        console.log(this.wardLst)
        for (var l = 0; l < this.wardLst.length; l++)
          if (this.wardLst[l].fnce_arc_lne_tx) {
            this.wrdfncepolygon = L.polygon(JSON.parse(this.wardLst[l].fnce_arc_lne_tx) as []
              , { color: this.wardLst[l].fncs_clr_cd }).addTo(this.routemap).bindTooltip(this.wardLst[l].wrd_nm, { permanent: true, opacity: 0.7 }).openTooltip();
            this.wrdplypoint.push({ ply: this.wrdfncepolygon, fnce_id: this.wardLst[l].fnce_id });
            this.routemap.fitBounds(this.wrdfncepolygon.getBounds());
          }
      }
    }, (err) => {
      console.log('In errorr');
    });

  }

  editRoute(c, val) {
    // this.enablePolygon();
    this.map_stng.show_grid = false;
    this.map_stng.show_sidebar = false;
    this.isVisible = true;
    this.rteSaveData = c;
    console.log(val)
    if (val == 'edt') {
      console.log('in editttttttttttttttt')
      this.rteSaveData.isEdit = true;
    } else {
      this.rteSaveData.isEdit = false;
    }
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }




  drawnItems: FeatureGroup = featureGroup();
  drawOptions = {
    position: 'topright',
    draw: {
      polyline: {
        shapeOptions: {
          color: 'red',
          weight: 2
        }
      },

      // circlemarker: false
      // , marker: false
      // //, polygon: false
      // //, polyline: false
      // , rectangle: false
      // , circle: false
    },
    edit: {
      featureGroup: this.drawnItems,
      remove: false
    }
  };

  // drawLocal: any = {
  // 	draw: {
  // 		toolbar: {
  // 			buttons: {
  // 				polygon: 'Draw an awesome polygon!'
  // 			}
  // 		}
  // 	}
  // };
 
  onMapReady(map: any) {
    console.log("onMapReady")
    this.map = map;
    // this.pointMap();
    this.that = this;
    const that: any = this;
    const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      minZoom: 3,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: ''
    });
    tiles.addTo(this.map);
    console.log(this.clntDtls)
    this.map.setView([this.clntDtls[0].lat, this.clntDtls[0].lng], 13);
    this.map.pm.addControls({
        position: 'topleft',
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: true,
        drawRectangle: false,
        drawPolygon: false,
        editMode: true,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        drawMarker: false
      });
    
 

    
  
    this.map.on(L.Draw.Event.CREATED, function (e) {
      console.log("Map draw event -------- " + e.layerType);
      console.log(Object.keys(e.layer._latlngs).length);
      console.log(typeof (e.layer._latlngs));
      Object.keys(e.layer._latlngs).forEach(key => {
        console.log(key, "Lat", e.layer._latlngs[key].lat);
        console.log(key, "Lng", e.layer._latlngs[key].lng);
      });

      // console.log(e.layer._latlngs)
      // if(e.layerType=='polyline'){
      // 	console.log("No of points in polyline :: "+e.layer._latlng.length());
      // 	for(var i=0;i<e.layer._latlng.length();i++){
      // 		console.log("Pint lat::"+e.layer._latlng[i].lat)
      // 	}
      // }
      // that.setMarkerDtls(e.layer);
      that.edit_fence.fnce_type = "Polyline";
      that.edit_fence.crtd_fnce_type = that.edit_fence.fnce_type;
      that.edit_fence.fnce_type = '';
      // if (that.edit_fence.crtd_fnce_type === 'Marker') {
      // 	that.edit_fence.lat = e.layer._latlng.lat;
      // 	that.edit_fence.lng = e.layer._latlng.lng;
      // } else {
      // 	if (that.edit_fence.crtd_fnce_type !== 'Circle') {
      // 		that.map_stng.undo_flag = true;
      // 	}
      // 	that.edit_fence.latlng_array = e.layer._latlngs;
      // }

      // var type = e.layerType;
      // console.log("e.layer._latlng--->");
      // console.log(e.layer._latlng.lat)
      // console.log(e.layer._latlng.lng)


      // if (this.rteSaveData.isEdit == true) {
      //   // this.map.on(L.Draw.Event.EDITED, (e: any) => {
      //   //   console.log(e);
      //     var line_arcArry = [];
      //   line_arcArry = JSON.parse(this.rteSaveData.rte_arc_lne_tx)
      //   // // var y;
      //   for (var x = 0; x < line_arcArry.length; x++) {

      //     e.layer._latlngs[x].lat = line_arcArry[x][0];
      //     e.layer._latlngs[x].lng = line_arcArry[x][1];

      //   }
      //   // console.log(e.layer._latlngs)

      //   // })
      //   // var polylinePoints = line_arcArry
        
      //   // var polyline = L.polyline(polylinePoints).addTo(this.map);
        
      
      // }
   
      const layer: any = (e as DrawEvents.Created).layer;
      this.editablLayers.addLayer(layer);
      console.log("layer.layer  :: ");
      console.log(JSON.parse(layer));
      this.drawnItems.addLayer(layer);
    });



    this.showCoordinates();

    L.control.ruler({
      lengthUnit: {                 // You can use custom length units. Default unit is kilometers.
        display: 'km',              // This is the display value will be shown on the screen. Example: 'meters'
        decimal: 4,                 // Distance result will be fixed to this value. 
        factor: null,               // This value will be used to convert from kilometers. Example: 1000 (from kilometers to meters)  
        label: 'Distance:'
      },
      angleUnit: {
        display: '&deg;',           // This is the display value will be shown on the screen. Example: 'Gradian'
        decimal: 2,                 // Bearing result will be fixed to this value.
        factor: null,                // This option is required to customize angle unit. Specify solid angle value for angle unit. Example: 400 (for gradian).
        label: 'Bearing:'
      }
    }).addTo(this.map);


  }

  showCoordinates() {
    const Coordinates = L.Control.extend({
      onAdd: map => {
        const container = L.DomUtil.create("div");
        map.addEventListener("mousemove", e => {
          container.innerHTML = `
				<h6>Latitude  ${e.latlng.lat.toFixed(6)} <br>Longitude  ${e.latlng.lng.toFixed(6)}</h6>
				`;
        });
        return container;
      }
    });
    this.map.addControl(new Coordinates({ position: "topleft" }));
  }

  apply() {

    // Get the active base layer
    const baseLayer = this.model.baseLayers.find((l: any) => (l.id === this.model.baseLayer));

    // Get all the active overlay layers
    const newLayers = this.model.overlayLayers
      .filter((l: any) => l.enabled)
      .map((l: any) => l.layer);
    newLayers.unshift(baseLayer.layer);

    this.layers = newLayers;

    return false;
  }


  deleteLastVertex() {
    // L.Draw.Polyline.deleteLastVertex()
    console.log("In deleteLastVertex ======>>>")
    if (this.edit_fence.fnce_nm === 'Polyline' || this.edit_fence.fnce_nm === 'Polygon') {
      this.fnce_drawer.polylineDrawer.deleteLastVertex();
    }
  }
  editPolyline() {
    console.log("In editPolyline == >  In editPolyline()")
    // let drawEditToolbar = new L.EditToolbar.Edit(this.map, {
    // 	featureGroup: this.drawOptions.featureGroup,
    // 	selectedPathOptions:  this.drawOptions.edit.selectedPathOptions
    // })
    // drawEditToolbar.enable()
  }
  // getPloygonType() {
  // 	// console.log(this.edit_fence.fnce_type)
  // 	// console.log(this.edit_fence.crtd_fnce_type)
  // 	if (this.edit_fence.fnce_type === "") {
  // 		return this.edit_fence.fnce_type
  // 	}
  // 	else {
  // 		return this.edit_fence.crtd_fnce_type
  // 	}
  // }
  saveFence() {
    console.log("In saveFence ====> creating fence ");
    console.log(this.edit_fence);



    this.clearFence();
  }
  clearFence() {
    console.log("In clearFence ===> clear fence ");
    this.edit_fence = {
      "fnce_type": "", "crtd_fnce_type": '',
      "fnce_nm": "", "dscn_tx": "", "imge_url_tx": "", Opacity: "30",
      "FillOpacity": "30", "stroke": 2, "weight": 2, "dashArray": '2,2',
      "color": '#8c90f2', "fill": true,
      "fillColor": '#8c90f2', "stroke_type": "DIRECTED_ROUTE",
      "marker_id": 1, "marker_nm": "RED_MARKER", "marker_icn_url": "007-placeholder.svg",
      "lat": null, "lng": null, "latlng_array": [], "radius": null
    }
  }




  public onDrawCreated(e: any) {
    console.log(e.layer);
    console.log(e.layer.options.color);
    console.log(e.layer.options.clickable);
    console.log(e.layer.options.fill);
    console.log(e.layer.options.opacity);
    console.log(e.layer.options.stroke);
    console.log(e.layer.options.weight);

  console.log(e.layer._latlngs)

    // console.log(e.layer._latlngs.lat);
    // console.log(e.layer._latlngs.lat);
    var restr = '';
    this.rtrstrng = '';
    var arcrestr = '';

    // e.layer._latlngs=JSON.parse(this.rteSaveData.rte_arc_lne_tx);
    // else{
    var latLngData = e.layer._latlngs
    // console.log(e);
    for (var i = 0; i < latLngData.length; i++) {
      restr += (restr == "") ? "[" + latLngData[i].lat + "," + latLngData[i].lng + "]" : ",[" + latLngData[i].lat + "," + latLngData[i].lng + "]";
      this.rtrstrng = restr;
      arcrestr += (arcrestr == "") ? latLngData[i].lng + " " + latLngData[i].lat : "," + latLngData[i].lng + " " + latLngData[i].lat;

    }

    var lstrng = arcrestr.split("[").join("");
    var lstrng2 = lstrng.split("]").join("");
    this.strngarc = lstrng2;
    // }


    // console.log(this.strngarc)
    // this.addRoute();
    console.log('onDrawCreated =====> Draw Created Event!');
    Object.keys(e.layer._latlngs).forEach(key => {
      console.log(key, "CREATED==>Lat", e.layer._latlngs[key].lat);
      console.log(key, "CREATED==>Lng", e.layer._latlngs[key].lng);
    });
    console.log(e)
    // **************** for Route Edit *****************************//
    //   if (this.rteSaveData.isEdit == true) {
    //     var line_arcArry = [];
    //   line_arcArry = JSON.parse(this.rteSaveData.rte_arc_lne_tx)
    //   console.log(line_arcArry)
    //   for (var x = 0; x < line_arcArry.length; x++) {
    //     e.layer._latlngs[x].lat = line_arcArry[x][0];
    //     e.layer._latlngs[x].lng = line_arcArry[x][1];
    //   }
    //   console.log(e.layer._latlngs)

    // }
        // **************** for Route Edit *****************************//

    const layer = (e as DrawEvents.Created).layer;
    this.drawnItems.addLayer(layer);
    this.saveRouteAlert(this.strngarc, this.rtrstrng)

  }
  saveRouteAlert(strngarc, rtrstrng) {
    var data = {
      strngarc: strngarc,
      rtrstrng: rtrstrng
    }
    this.modal.confirm({
      nzTitle: '<i>Do you Want to Save this Route?</i>',
      // nzContent: '<b>Some descriptions</b>',
      nzOnOk: () => this.addnwRoute(data)
    });

  }
  addnwRoute(plyData) {
    console.log(plyData)
    console.log(this.rteSaveData)
    var postData = {
      dt: moment(this.slctdDt).format('YYYY-MM-DD'),
      rte_id: this.rteSaveData.rte_id,
      rte_nm: this.rteSaveData.rte_nm,
      rte: '[' + plyData.rtrstrng + ']',
      tnt_id: this.selectedtenant,
      trp_sqnc_nu: this.rteSaveData.trp_sqnc_nu,
      rte_ln: "LINESTRING(" + plyData.strngarc + ")",
      trp_schde_id: this.rteSaveData.trp_schde_id,
      rte_plyln_id: this.rteSaveData.rte_plyln_id
    }
    console.log(postData)
    if (this.rtrstrng != undefined) {
      const rte1 = `dashboard/enterprise/addPolylne`;
      this.apiServ.post(postData, rte1).subscribe((res) => {
        if (res['status'] == 200) {
          this.createMessage('success', 'Schedule Route added Successfully and New route will reflect from tomorrow');
          this.selectCategory(0, this.curDate);
        }
      });
    }
  }
  public onDrawStart(e: any) {
    console.log(e);
    console.log('onDrawStart ======> Draw Started Event!');
    // Object.keys(e.sourceTarget._layers[286]._latlngs).forEach(key => {
      // console.log(key, "CREATED==>Lat", e.layer._latlngs[key].lat);
      // console.log(key, "CREATED==>Lng", e.layer._latlngs[key].lng);
      // console.log(e.sourceTarget,'lattttttttttttlnggggggggggggg')

    // });


    this.edit_fence.lat = null;
    this.edit_fence.lng = null;
    this.edit_fence.latlng_array = [];
    this.map_stng.undo_flag = true;
   
  }

  enablePolyline() {
    console.log('enablePolyline ======>  In enablePolyline');
    this.fnce_drawer.polylineDrawer = new L.Draw.Polyline(this.map, {
      shapeOptions: {
        color: this.edit_fence.color,
        opacity: Math.round(this.edit_fence.opacity / 100),
        stroke: this.edit_fence.stroke,
        dashArray: this.edit_fence.dashArray,
        fill: false
        //  ,weight: this.edit_fence.stroke
      }
    });
    this.fnce_drawer.polylineDrawer.enable();
  }
  addPolyline(p_latlngs: any, p_optns: any, p_route_path: any) {
    console.log(p_latlngs)
    const optns: any = { color: 'black', fill: false, fillColor: 'green', fillOpacity: 0.5, opacity: 1, stroke: 2, weight: 2, dashArray: '2,2' };

    if (!(p_optns.color === undefined)) { optns.color = p_optns.color; }
    if (!(p_optns.fill === undefined)) { optns.fill = p_optns.fill; }
    if (!(p_optns.fillColor === undefined)) { optns.fillColor = p_optns.fillColor; }
    if (!(p_optns.fillOpacity === undefined)) { optns.fillOpacity = p_optns.fillOpacity / 100; }
    if (!(p_optns.opacity === undefined)) { optns.opacity = p_optns.opacity / 100; }
    if (!(p_optns.stroke === undefined)) { optns.stroke = p_optns.stroke; }
    if (!(p_optns.weight === undefined)) { optns.weight = p_optns.weight; }
    if (!(p_optns.dashArray === undefined)) { optns.dashArray = p_optns.dashArray; }

    const latlngs: [number, number][] = p_latlngs;
    const l_ploly: any = L.polyline(latlngs, optns);

    // Direction in red
    if (p_route_path === 'DIRECTED_ROUTE') {
      l_ploly.setText('  \u25BA  ', { repeat: true, offset: 3, attributes: { fill: 'red' } });
    }
    // Railway track
    if (p_route_path === 'RAILWAY_TRACK') {
      optns.weight = 10;
      optns.color = 'grey';
      l_ploly.setText('| ', { repeat: true, offset: 3, attributes: { fill: 'black', 'font-weight': 'bold', 'font-size': '10' } });
    }

    this.drawnItems.addLayer(l_ploly);
  }
}
