import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
  isFolded: boolean ;
  isSideNavDark: boolean;
  isExpand: boolean;
  selectedHeaderColor: string;
  setup;
  shwLdg: boolean;
  searchText = '';
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, public router: Router) { }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getsetupdata();
  }

  getsetupdata(): void {
    this.shwLdg = true;
    const rte = 'auth2/admin/setup/profiles';
    this.apiSrv.get(rte).subscribe(res => {

      // console.log(res);
      this.shwLdg = false;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < res['data'].length; i++){
        // tslint:disable-next-line:no-string-literal
        res['data'][i]['grp_nm'] = res['data'][i].stp_grp_nm;

        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < res['data'][i].opts.length; j++){
          // tslint:disable-next-line:no-string-literal
          res['data'][i].opts[j]['opt_nm'] = res['data'][i].opts[j].stp_opt_nm;
      }
    }

      // tslint:disable-next-line:no-string-literal
      this.setup = res['data'];
    // console.log(this.setup);
    });
  }

  gotoStpOptPge(rte){
  console.log(rte);
    this.router.navigate([rte]);
  }
}
