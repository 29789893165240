import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-svm-dashboard',
  templateUrl: './svm-dashboard.component.html',
  styleUrls: ['./svm-dashboard.component.scss']
})
export class SvmDashboardComponent implements OnInit {
  clntDtls: any;
  crdOne: boolean = true; crdTwo: boolean = false; crdThree: boolean = false; crdFour: boolean = false;
  svm_id;
  svmNm;
  actvtbleNm;
  svmLst: any=[];
  userDtls: any;
  usr_svm_id: any;
  owms_svm_id: any;
  cpay_svm_id: any;
  ulb_svm_id: any;
  selectedsvm: any;
  svmData: any;
  showLdr;
  svm_apfs_cd;
  slctdsvmCode;
  rdrctSvmDtls;
  constructor(public apiSrv: AppsService,public TransfereService: TransfereService, public route: Router) {
    this.rdrctSvmDtls = [];

    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.rdrctSvmDtls = this.TransfereService.getLoclData('svmDta');
    console.log(this.rdrctSvmDtls)

    if (this.rdrctSvmDtls) {
      this.usr_svm_id=this.rdrctSvmDtls.svm_id;
        this.svm_apfs_cd=this.rdrctSvmDtls.svm_apfss_cd;
        this.selectedsvm=this.svm_apfs_cd;
        this.getsvmLst(this.svm_apfs_cd);

    } else {
      if(this.clntDtls[0].svm_id){
        this.usr_svm_id=this.clntDtls[0].svm_id;
        this.svm_apfs_cd=this.clntDtls[0].svm_apfs_cd;
        this.selectedsvm=this.svm_apfs_cd;
        this.getsvmLst(this.svm_apfs_cd);
      }
    }

  }

  ngOnInit(): void {
    // this.selectedsvm=this.usr_svm_id;
    this.selectedsvm=this.svm_apfs_cd;
    // this.cardOne();
    this.actvtbleNm = 'crdThree';
    this.cardThree();
  }
  goBack() {
    this.route.navigate([`internal/dashboard/sachivalayam-dashboard`]);
  }
  getsvmLst(svm_cd) {
    const rte = `external/owms/sachivalayams/dtls/${svm_cd}`;
    this.apiSrv.get(rte).subscribe((res) => {
      if(res['status']==200){
        this.svmLst=res['data'];
        console.log(this.svmLst)
        this.svm_id= this.svmLst[0].svm_id;
        this.svmNm=this.svmLst[0].svm_nm;
        this.owms_svm_id=this.svmLst[0].owms_svm_id;
        this.cpay_svm_id=this.svmLst[0].cpay_svm_id;
        this.ulb_svm_id=this.svmLst[0].ulb_svm_id;
        this.svm_apfs_cd=this.svmLst[0].svm_apfss_cd;
      }
      this.svmData=this.svmLst[0];
      console.log(this.svmData)
    });
  }
  onsvmSelect(id){
    // this.selectedsvm=id;
    this.selectedsvm=id;
    console.log(this.selectedsvm)
    this.getsvmLst(id);
  }
  cardOne() {
    this.actvtbleNm = 'crdOne';
    this.crdOne = true; this.crdTwo = false; this.crdThree = false; this.crdFour = false;
  }
  cardTwo() {
    this.actvtbleNm = 'crdTwo';
    this.crdOne = false; this.crdTwo = true; this.crdThree = false; this.crdFour = false;
  }
  cardThree() {
    this.actvtbleNm = 'crdThree';
    this.crdOne = false; this.crdTwo = false; this.crdThree = true; this.crdFour = false;
  }
  cardFour() {
    this.actvtbleNm = 'crdFour';
    this.crdOne = false; this.crdTwo = false; this.crdThree = false; this.crdFour = true;
  }
}
