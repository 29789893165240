<ds-loading *ngIf="showLdr"></ds-loading>

<div class="row hdrBar">
    <div class="col-lg-8 col-md-8 mg-a">
        <h1 *ngIf="clntDtls && clntDtls.length > 0">Desludging Dashboard</h1>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <span>Select Date:</span>
        <nz-date-picker   [nzDisabledDate]="disabledDate"   [nzFormat]="dateFormat" [(ngModel)]="slctdDt" (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
</div>


<div class="main">
    <!-- <h1>Desludging Dashboard</h1> -->
    <div class="row">
            <ul class="cards">
                <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                    <div class="card_g" style="background:linear-gradient(135deg, #ab89f7 0%,#7367f0 100%);">
                        <div class="card_content">
                            <div class="row">
                                <h1 class="crdHdr">Total Vehicles</h1>
                            </div>
                            <div class="row crdHdr_sb">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                        <i nz-icon nzType="car" nzTheme="outline"></i>
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h1 class="card_sub_title">{{d.ttl}}</h1>
                                </div>
                            </div>
                            <div class="row crdHdr_sb_sm">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                        Opearted
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h1 class="card_sub_title">{{d.operated === null ? "NA" : d.operated}}</h1>
                                </div>
                            </div>
                            <div class="row crdHdr_sb_sm">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                       Not-Operated
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h1 class="card_sub_title">{{d.notoperated}}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="cards_item" *ngFor="let d of ttlTrpsCnt">
                    <div class="card_g" style="background:linear-gradient(135deg, #40d99d 0%,#3bb2b8 100%);">
                        <div class="card_content">
                            <div class="row">
                                <h1 class="crdHdr">Trips Travelled</h1>
                            </div>
                            <div class="row crdHdr_sb">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                        <i nz-icon nzType="car" nzTheme="outline"></i>
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <!-- <h1 class="card_sub_title">{{d.tdy_trps + d.ysd_trps}}</h1> -->
                                </div>
                            </div>
                            <div class="row crdHdr_sb_sm">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                        Today
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h1 class="card_sub_title">{{d.tdy_trps}}</h1>
                                </div>
                            </div>
                            <div class="row crdHdr_sb_sm">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                       Yesterday
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h1 class="card_sub_title">{{d.ysd_trps}}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="cards_item" *ngFor="let d of ttlDstncCnt">
                    <div class="card_g" style="background:linear-gradient(135deg, #f6ab84 0%,#f38381 100%);">
                        <div class="card_content">
                            <div class="row">
                                <h1 class="crdHdr">Distance Travelled (km)</h1>
                            </div>
                            <div class="row crdHdr_sb">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                        <i nz-icon nzType="node-index" nzTheme="outline"></i>
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <!-- <h1 class="card_sub_title">{{d.tdy_dstnc + d.ysdy_dstnc}}</h1> -->
                                </div>
                            </div>
                            <div class="row crdHdr_sb_sm">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                        Today (km)
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h1 class="card_sub_title">{{d.tdy_dstnc}}</h1>
                                </div>
                            </div>
                            <div class="row crdHdr_sb_sm">
                                <div class="col-lg-6 col-md-6">
                                    <h1>
                                       Yesterday (km)
                                    </h1>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h1 class="card_sub_title">{{d.ysdy_dstnc}}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
           
    </div>
    
    <div class="row bg-wp">
        <h1>ULB Wise Desludging Trips</h1>
        <div class="ds-card grid tbl">
            <dx-data-grid class="p-8" [dataSource]="ulbwseDsldgngTripsDta" [remoteOperations]="false"  
              [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
              [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
              (onToolbarPreparing)="true" (onCellPrepared)="onCellPrepared(ulbwseDsldgngTripscolumnDefs,$event)">
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                [showInfo]="true"></dxo-pager>
              <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
              <dxo-filter-panel [visible]="false"></dxo-filter-panel>
              <dxo-group-panel [visible]="true"></dxo-group-panel>
              <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
              <dxo-column-chooser [enabled]="true" mode="select">
              </dxo-column-chooser>
              <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="ULB Trips Data"></dxo-export>
              <dxo-filter-row [visible]="false"></dxo-filter-row>
              <dxo-summary>
                <dxi-total-item column="tot_veh" summaryType="sum"  name="Total"></dxi-total-item>
                <dxi-group-item column="tnt_nm" name="Total Tenants" summaryType="count" [alignByColumn]="true"
                  [showInGroupFooter]="true"></dxi-group-item>
                <dxi-group-item column="tot_trps" name="Total Trips" summaryType="count" [alignByColumn]="true"
                  [showInGroupFooter]="true">
                </dxi-group-item>
                <dxi-group-item column="tvl_dstnc" name="Total Distance" summaryType="sum" [alignByColumn]="true"
                  [showInGroupFooter]="true"></dxi-group-item>
                  <dxi-group-item column="trvl_hr" name="Total Hours" summaryType="sum" [alignByColumn]="true"
                  [showInGroupFooter]="true">
                </dxi-group-item>
              </dxo-summary>
              <dxi-column *ngFor="let f of ulbwseDsldgngTripscolumnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
                [alignment]="f.alignment" [width]="f.width" [allowResizing]="true"></dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View" cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoUlb(data)">Quick View</div>
                    </div>
              </dxi-column>
            </dx-data-grid>
          </div>
        </div>
    
    <div class="row bg-w">
        <h1 style="padding-left: 20px;">Heat Map (Last 7Days Trips Travelled)</h1>
        <div id="chartdiv"></div>
    </div>
    <div class="row bg-w">
        <div class="col-lg-6 col-md-6">
            <h1>Distance Travelled Last 7Days</h1>
            <div id="distanceTavelledChart"></div>
        </div>
        <div class="col-lg-6 col-md-6">
            <h1>Trips Travelled Last 7Days</h1>
            <div id="tripsTavelledChart"></div>
        </div>
    </div>
  
</div>