import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as moment from 'moment';

@Component({
  selector: 'app-driver-slider',
  templateUrl: './driver-slider.component.html',
  styleUrls: ['./driver-slider.component.scss']
})
export class DriverSliderComponent implements OnChanges {
  @Input() driver;
  showLdr = false;
  drvrDtls;
  shwdtlsErrMsg;
  drvrTtlDtls: any;
  init_lat = 17;
  init_lng = 81;
  shwLastweekErrMsg;
  today = new Date();
  curYr: any; crw_nm; crw_lcnse_nu; crw_doj; crw_ph; drvrOptnlDtls;
  drvrFulDtls: any;
  drvrSpdDtls: any;
  ovrSpeedMsg: string;
  oprtnlhrsMsg: string;
  fuelDtaMsg: string;
  gtDta; driverMap;
  oprtnlhrs;
  postData: { selectedrvr: any; selectedtenant: any; selectedvndr: any; };
  crwDtls: any = {};
  driverMapMarker: any;
  vndr_id: any;
  tnt_id: any;
  userDtls:any;
  clntDtls:any;
  constructor(public apiServ: AppsService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));

   }

  ngOnChanges(changes: SimpleChanges) {
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    console.log('In ----------------------------------- Driver', changes.driver.currentValue);
    this.curYr = moment(this.today).format('YYYY');
    console.log('In ----------------------------------- DAte', this.curYr);
    this.gtDta = changes;
    console.log(changes.driver.currentValue.crw_id)
    const rte = `drivers/getEachDriverDetails/${changes.driver.currentValue.crw_id}`;
    this.apiServ.get(rte).subscribe((res) => {
      if (res['status'] ==200) {
        this.drvrDtls = res['data'];
        console.log(this.drvrDtls[0]);
        this.crwDtls = {
          crw_nm: this.drvrDtls[0].crw_nm,
          crw_ph: this.drvrDtls[0].crw_ph,
          crw_lcnse_nu: this.drvrDtls[0].crw_lcnse_nu,
          crw_doj: this.drvrDtls[0].doj,
          asrt_nm: this.drvrDtls[0].asrt_nm,
          asrt_sts_nm: this.drvrDtls[0].asrt_sts_nm,
          bg_clr_cd: this.drvrDtls[0].bg_clr_cd,
          clr_cd: this.drvrDtls[0].clr_cd,
          svm_nm: this.drvrDtls[0].svm_nm,
        }
        console.log(this.crwDtls);
        // this.crwDtls = this.drvrDtls[0];
        this.showLdr = false;

        if (this.drvrDtls[0].asrtglblId == "1") {
          if (this.drvrDtls[0].spd_ct <= '5') {
            var icon = "_p_m.png";
          } else {
            var icon = "_r_m.png";
          }
        } else {
          var icon = "_o_m.png";
        }
        var vehIcon = new L.Icon({
          iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.drvrDtls[0].icon_path + icon,
          iconSize: [30, null],
          iconAnchor: [10, 5],
          popupAnchor: [5, -20],
          shaowSize: [35, 35]
        });
        if (this.drvrDtls[0].lat && this.drvrDtls[0].lng) {
          this.driverMapMarker = L.marker([this.drvrDtls[0].lat, this.drvrDtls[0].lng], {
            icon: vehIcon
          }).addTo(this.driverMap);
          this.driverMap.setView([this.drvrDtls[0].lat, this.drvrDtls[0].lng], 13)

          // var bounds = [];
          // bounds.push([this.drvrDtls[0].lat,this.drvrDtls[0].lng]);
          // this.driverMap.fitBounds(bounds);
          var coords = L.latLng([this.drvrDtls[0].lat, this.drvrDtls[0].lng]);
          L.popup().setLatLng(coords).setContent(this.drvrDtls[0].asrt_nm).openOn(this.driverMap);

        }
      }
      // else {
      //   this.showLdr = false;
      //   console.log(this.gtDta.driver.currentValue);
      //   this.crwDtls = {
      //     crw_nm: this.gtDta.driver.currentValue.crw_nm,
      //     crw_ph: this.gtDta.driver.currentValue.crw_ph,
      //     crw_lcnse_nu: this.gtDta.driver.currentValue.crw_lcnse_nu,
      //     crw_doj: this.gtDta.driver.currentValue.doj,
      //     asrt_nm: this.gtDta.driver.currentValue.asrt_nm,
      //     asrt_sts_nm: this.gtDta.driver.currentValue.asrt_sts_nm,
      //     bg_clr_cd: this.gtDta.driver.currentValue.bg_clr_cd,
      //     clr_cd: this.gtDta.driver.currentValue.clr_cd,
      //     svm_nm: this.gtDta.driver.currentValue.svm_nm,
      //   }
      //   this.shwdtlsErrMsg = 'No details found regarding to the selected Dirver';

      //   if (this.gtDta.driver.currentValue == "1") {
      //     // if (this.drvrDtls[0].spd_ct <= '5') {
      //     //   var icon = "_p_m.png";
      //     // } else {
      //     //   var icon = "_r_m.png";
      //     // }
      //   } else {
      //     var icon = "_o_m.png";
      //   }
      //   var vehIcon = new L.Icon({
      //     iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.gtDta.driver.currentValue.icon_path + icon,
      //     iconSize: [30, null],
      //     iconAnchor: [10, 5],
      //     popupAnchor: [5, -20],
      //     shaowSize: [35, 35]
      //   });
      //   if(this.gtDta.driver.currentValue.lat &&  this.gtDta.driver.currentValue.lng){
      //   this.driverMapMarker = L.marker([this.gtDta.driver.currentValue.lat, this.gtDta.driver.currentValue.lng], {
      //     icon: vehIcon
      //   }).addTo(this.driverMap);

      //   // var bounds = [];
      //   // bounds.push([this.gtDta.driver.currentValue.lat,this.gtDta.driver.currentValue.lng]);
      //   // this.driverMap.fitBounds(bounds);
      //   this.driverMap.setView([this.gtDta.driver.currentValue.lat, this.gtDta.driver.currentValue.lng], 13)
      //   var coords = L.latLng([this.gtDta.driver.currentValue.lat, this.gtDta.driver.currentValue.lng]);
      //   L.popup().setLatLng(coords).setContent(this.gtDta.driver.currentValue.asrt_nm + "-" + this.gtDta.driver.currentValue.asrt_ctgry_nm + " </br> " + this.gtDta.driver.currentValue.asrt_grp_nm).openOn(this.driverMap);
      // }
      // }
      if(this.drvrDtls[0].vndr_id){
        this.vndr_id=this.drvrDtls[0].vndr_id;
      }else{
        this.vndr_id=0;
      }
      if(this.drvrDtls[0].tnt_id){
        this.tnt_id=this.drvrDtls[0].tnt_id;
      }else{
        this.tnt_id=0;
      }


      this.getDriverdetails(changes.driver.currentValue.crw_id);
      this.getTotalDtls(changes.driver.currentValue.crw_id, this.tnt_id, this.vndr_id);
      this.getLst7Daysdtls(changes.driver.currentValue.crw_id,this.tnt_id, this.vndr_id);
      this.getOprtnlDtls(changes.driver.currentValue.crw_id, this.tnt_id, this.vndr_id);
  
      // this.getFuelDtls(changes.driver.currentValue.crw_id);
      this.getSpeedDtls(changes.driver.currentValue.crw_id,this.tnt_id, this.vndr_id);
      this.getDriverhourlyDtls(changes.driver.currentValue.crw_id,this.tnt_id, this.vndr_id);
     



    }, (err) => {
      console.log(err);
    });
    // this.crw_nm = changes.driver.currentValue.crw_nm;
    // this.crw_lcnse_nu = changes.driver.currentValue.crw_lcnse_nu;
    // this.crw_doj = changes.driver.currentValue.doj;
    // this.crw_ph = changes.driver.currentValue.crw_ph;
    // this.crwDtls = {
    //   crw_nm: changes.driver.currentValue.crw_nm,
    //   crw_ph: changes.driver.currentValue.crw_ph,
    //   crw_lcnse_nu: changes.driver.currentValue.crw_lcnse_nu,
    //   crw_doj: changes.driver.currentValue.doj,
    //   asrt_nm: changes.driver.currentValue.asrt_nm,
    //   asrt_sts_nm: changes.driver.currentValue.asrt_sts_nm,
    //   bg_clr_cd: changes.driver.currentValue.bg_clr_cd,
    //   clr_cd: changes.driver.currentValue.clr_cd,
    //   svm_nm: changes.driver.currentValue.svm_nm,
    // }
 this.initialize();

  }

  initialize = function () {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.driverMap = L.map('driverMap', {
        center: [this.init_lat, this.init_lng],
        zoom: 14
      });
      console.log(this.init_lat, this.init_lng);
      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   maxZoom: 18,
      //   minZoom: 12,
      //   attribution: ''
      // });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        minZoom: 3,
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: ''      });

      tiles.addTo(this.driverMap);

    } catch (e) {
      // console.log("please check map Initialization of leaflet:" + e);
    }

  };

  getDriverdetails(drvrId): any {
    if (this.driverMapMarker) {
      this.driverMap.removeControl(this.driverMapMarker);
      this.driverMap.removeLayer(this.driverMapMarker);
    }
    this.showLdr = true;
    const rte = `drivers/getEachDriverDetails/${drvrId}`;
    this.apiServ.get(rte).subscribe((res) => {
      if (res['data'] && res['data'].length < 0) {
        this.drvrDtls = res['data'];
        console.log(this.drvrDtls[0]);
        this.crwDtls = {
          crw_nm: this.drvrDtls[0].crw_nm,
          crw_ph: this.drvrDtls[0].crw_ph,
          crw_lcnse_nu: this.drvrDtls[0].crw_lcnse_nu,
          crw_doj: this.drvrDtls[0].doj,
          asrt_nm: this.drvrDtls[0].asrt_nm,
          asrt_sts_nm: this.drvrDtls[0].asrt_sts_nm,
          bg_clr_cd: this.drvrDtls[0].bg_clr_cd,
          clr_cd: this.drvrDtls[0].clr_cd,
          svm_nm: this.drvrDtls[0].svm_nm,
        }
        console.log(this.crwDtls);
        // this.crwDtls = this.drvrDtls[0];
        this.showLdr = false;

        if (this.drvrDtls[0].asrtglblId == "1") {
          if (this.drvrDtls[0].spd_ct <= '5') {
            var icon = "_p_m.png";
          } else {
            var icon = "_r_m.png";
          }
        } else {
          var icon = "_o_m.png";
        }
        var vehIcon = new L.Icon({
          iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.drvrDtls[0].icon_path + icon,
          iconSize: [30, null],
          iconAnchor: [10, 5],
          popupAnchor: [5, -20],
          shaowSize: [35, 35]
        });
        if (this.drvrDtls[0].lat && this.drvrDtls[0].lng) {
          this.driverMapMarker = L.marker([this.drvrDtls[0].lat, this.drvrDtls[0].lng], {
            icon: vehIcon
          }).addTo(this.driverMap);
          this.driverMap.setView([this.drvrDtls[0].lat, this.drvrDtls[0].lng], 13)

          // var bounds = [];
          // bounds.push([this.drvrDtls[0].lat,this.drvrDtls[0].lng]);
          // this.driverMap.fitBounds(bounds);
          var coords = L.latLng([this.drvrDtls[0].lat, this.drvrDtls[0].lng]);
          L.popup().setLatLng(coords).setContent(this.drvrDtls[0].asrt_nm ).openOn(this.driverMap);

        }
      }
      // else {
      //   this.showLdr = false;
      //   console.log(this.gtDta.driver.currentValue);
      //   this.crwDtls = {
      //     crw_nm: this.gtDta.driver.currentValue.crw_nm,
      //     crw_ph: this.gtDta.driver.currentValue.crw_ph,
      //     crw_lcnse_nu: this.gtDta.driver.currentValue.crw_lcnse_nu,
      //     crw_doj: this.gtDta.driver.currentValue.doj,
      //     asrt_nm: this.gtDta.driver.currentValue.asrt_nm,
      //     asrt_sts_nm: this.gtDta.driver.currentValue.asrt_sts_nm,
      //     bg_clr_cd: this.gtDta.driver.currentValue.bg_clr_cd,
      //     clr_cd: this.gtDta.driver.currentValue.clr_cd,
      //     svm_nm: this.gtDta.driver.currentValue.svm_nm,
      //   }
      //   this.shwdtlsErrMsg = 'No details found regarding to the selected Dirver';

      //   if (this.gtDta.driver.currentValue == "1") {
      //     // if (this.drvrDtls[0].spd_ct <= '5') {
      //     //   var icon = "_p_m.png";
      //     // } else {
      //     //   var icon = "_r_m.png";
      //     // }
      //   } else {
      //     var icon = "_o_m.png";
      //   }
      //   var vehIcon = new L.Icon({
      //     iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.gtDta.driver.currentValue.icon_path + icon,
      //     iconSize: [30, null],
      //     iconAnchor: [10, 5],
      //     popupAnchor: [5, -20],
      //     shaowSize: [35, 35]
      //   });
      //   if(this.gtDta.driver.currentValue.lat &&  this.gtDta.driver.currentValue.lng){
      //   this.driverMapMarker = L.marker([this.gtDta.driver.currentValue.lat, this.gtDta.driver.currentValue.lng], {
      //     icon: vehIcon
      //   }).addTo(this.driverMap);

      //   // var bounds = [];
      //   // bounds.push([this.gtDta.driver.currentValue.lat,this.gtDta.driver.currentValue.lng]);
      //   // this.driverMap.fitBounds(bounds);
      //   this.driverMap.setView([this.gtDta.driver.currentValue.lat, this.gtDta.driver.currentValue.lng], 13)
      //   var coords = L.latLng([this.gtDta.driver.currentValue.lat, this.gtDta.driver.currentValue.lng]);
      //   L.popup().setLatLng(coords).setContent(this.gtDta.driver.currentValue.asrt_nm + "-" + this.gtDta.driver.currentValue.asrt_ctgry_nm + " </br> " + this.gtDta.driver.currentValue.asrt_grp_nm).openOn(this.driverMap);
      // }
      // }







    }, (err) => {
      console.log(err);
    });
  }
  drvrHrlyDtls; drvrHrlyMsg;
  getDriverhourlyDtls(drvrId, tntId, vndrId): any {
    this.drvrHrlyDtls = [];
    this.showLdr = true;
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    const rte = `drivers/driver/hourly_distance`;
    let chart = am4core.create("hepachartdiv", am4charts.XYChart);
    chart.data = [];

    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrHrlyDtls = res['data'];
      this.drvrHrlyDtls.forEach(obj => {
        if (obj.dst == null) {
          obj.dst = 0;
        }
        else {
          obj.dst = obj.dst
        }

      })
      console.log(this.drvrHrlyDtls);
      chart.data = this.drvrHrlyDtls;


      chart.maskBullets = true;

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      xAxis.dataFields.category = "trvl_hr";
      yAxis.dataFields.category = "trvl_dt";

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 30;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "trvl_hr";
      series.dataFields.categoryY = "trvl_dt";
      series.dataFields.value = "dst";
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      var bgColor = new am4core.InterfaceColorSet().getFor("background");

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 0.2;
      columnTemplate.stroke = bgColor;
      columnTemplate.tooltipText = "{dst}";
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        min: am4core.color(bgColor),
        max: chart.colors.getIndex(0)
      });
      var legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.data = [{
        "name": "Distance Covered",
        "fill": am4core.color("#bec4f8")
      }];

      // series.heatRules.push({
      // 	target:columnTemplate, 
      // 	property:"fill", 
      // 	min:am4core.color("#f5f6fe"), 
      // 	max:am4core.color("#554abd"),
      // 	minValue: 2000,
      // 	maxValue: 5000
      //   });


      // heat legend
      var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
      heatLegend.width = am4core.percent(100);
      heatLegend.series = series;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
      heatLegend.valueAxis.renderer.minGridDistance = 30;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{dst}";
      valueLabel.label.fontSize = 15;

      // columnTemplate1.width = 60;
      columnTemplate.column.cornerRadiusTopLeft = 5;
      columnTemplate.column.cornerRadiusTopRight = 5;


      // heat legend behavior
      series.columns.template.events.on("over", function (event) {
        handleHover(event.target);
      })

      series.columns.template.events.on("hit", function (event) {
        handleHover(event.target);
      })

      function handleHover(column) {
        if (!isNaN(column.dataItem.value)) {
          heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
        }
        else {
          heatLegend.valueAxis.hideTooltip();
        }
      }

      series.columns.template.events.on("out", function (event) {
        heatLegend.valueAxis.hideTooltip();
      })









      if (this.drvrHrlyDtls.length == 0) {
        this.drvrHrlyMsg = 'Hourly Distance not found to this driver.';
      } else {
        this.drvrHrlyMsg = '';
      }
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }

  getTotalDtls(drvrId, tntId, vndrId): any {
    this.drvrTtlDtls = [];
    this.showLdr = true;
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    console.log(this.postData);
    const rte = `drivers/getTotalCnts`;
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrTtlDtls = res['data'];
      console.log(this.drvrTtlDtls);
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }
  nodata: boolean;

  getLst7Daysdtls(drvrId, tntId, vndrId): any {
    this.showLdr = true;
    let chart1 = am4core.create("lastSevenDaysDistanceChart", am4charts.XYChart);
    let chart2 = am4core.create("lastSevenDaysChart", am4charts.XYChart);
    chart1.data = [];
    chart2.data = [];
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    const rte = `drivers/getLast7DaysCnts`;
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrDtls = res['data']['results'];
      console.log(this.drvrDtls);
      chart1.data = this.drvrDtls;
      chart2.data = this.drvrDtls;
      this.showLdr = false;
      if (res['data'].msg == 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
      this.shwLastweekErrMsg = '';

      let categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "app_dt";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = ` ----- Date(${this.curYr}) ----- `;
      let valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = " ----- Trips Covered -----";
      valueAxis.cursorTooltipEnabled = false;
      let series = chart2.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "trp_ct";
      series.dataFields.categoryX = "app_dt";
      series.clustered = false;
      series.columns.template.width = am4core.percent(50);
      series.tooltipText = "Travelled Distance on {categoryX}: [bold]{valueY}[/]KM";
      series.columns.template.fill = am4core.color("#55ac73");
      series.columns.template.stroke = am4core.color("#55ac73");
      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{trp_ct}";
      valueLabel.label.fontSize = 15;
      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      chart2.cursor = new am4charts.XYCursor();
      chart2.cursor.lineX.disabled = true;
      chart2.cursor.lineY.disabled = true;




      let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis1.dataFields.category = "app_dt";
      categoryAxis1.renderer.grid.template.location = 0;
      categoryAxis1.renderer.minGridDistance = 30;
      categoryAxis1.title.text = ` ----- Date(${this.curYr}) ----- `;
      let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = " ----- Distance Covered -----";
      valueAxis1.cursorTooltipEnabled = false;
      let series2 = chart1.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = "tot_dst";
      series2.dataFields.categoryX = "app_dt";
      series2.clustered = false;
      series2.columns.template.width = am4core.percent(50);
      series2.tooltipText = "Travelled Distance on {categoryX}: [bold]{valueY}[/]KM";
      series2.columns.template.fill = am4core.color("#544aba");
      series2.columns.template.stroke = am4core.color("#544aba");
      let valueLabel2 = series2.bullets.push(new am4charts.LabelBullet());
      valueLabel2.label.text = "{tot_dst}";
      valueLabel2.label.fontSize = 15;
      let columnTemplate2 = series2.columns.template;
      columnTemplate2.strokeWidth = 1;
      chart1.cursor = new am4charts.XYCursor();
      chart1.cursor.lineX.disabled = true;
      chart1.cursor.lineY.disabled = true;


    }, (err) => {
      console.log(err);
    });
  }

  getOprtnlDtls(drvrId, tntId, vndrId): any {
    this.drvrOptnlDtls = [];
    this.showLdr = true;
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    const rte = `drivers/operational`;
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrOptnlDtls = res['data'];
      console.log(this.drvrOptnlDtls);
      if (this.drvrOptnlDtls.length == 0) {
        this.oprtnlhrsMsg = 'No Operational Details found to this driver.';
      } else {
        this.oprtnlhrsMsg = '';
      }
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }

  // getFuelDtls(drvrId): any {
  //   this.showLdr = true;
  //   const rte = `drivers/fuel/${drvrId}`;
  //   this.apiServ.get(rte).subscribe((res) => {
  //         this.drvrFulDtls = res['data'];
  //         console.log(this.drvrFulDtls);
  //         if(this.drvrFulDtls.length == 0){
  //           this.fuelDtaMsg = 'Fuel Issued not found to this driver.';
  //         }else{
  //           this.fuelDtaMsg = '';
  //         }
  //         this.showLdr = false;
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }

  getSpeedDtls(drvrId, tntId, vndrId): any {
    this.drvrSpdDtls = [];
    this.showLdr = true;
    this.postData = {
      selectedrvr: drvrId,
      selectedtenant: tntId,
      selectedvndr: vndrId
    }
    const rte = `drivers/overspeed`;
    this.apiServ.post(this.postData, rte).subscribe((res) => {
      this.drvrSpdDtls = res['data'];
      console.log(this.drvrSpdDtls);
      if (this.drvrSpdDtls.length == 0) {
        this.fuelDtaMsg = 'Speed Violation not found to this driver.';
      } else {
        this.fuelDtaMsg = '';
      }
      this.showLdr = false;
    }, (err) => {
      console.log(err);
    });
  }

}
