import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss']
})
export class MyTicketsComponent implements OnInit {

  isCollapsed = false;

  constructor() { }

  ngOnInit() {
  }

  getEvent($event): void {
 //   console.log($event);
    // this.title = $event;
    // if(this.title === 'Created to me'){
    //   this.getMyTcktsFn({rsd_by: this.usrdtls.usr_id,sts_txt:'new'});
    // }
    // else if(this.title === 'Assigned to me'){
    //   this.getMyTcktsFn({asgnd_to: this.usrdtls.usr_id});
    // }
    // else if(this.title === 'New'){
    //   this.getMyTcktsFn({rsd_by: this.usrdtls.usr_id, sts_txt:'new'});
    // }
    // console.log(this.title);
    // this.tickets = this.sideFltrTckts;
    // let fnlTckts = [];

    // for (let i = 0; i < this.tickets.length; i++){
    //   if (this.title == this.tickets[i].sts_txt){
    //     fnlTckts.push(this.tickets[i]);
    //   }
    // }
    // this.getFltrTcktsLst(fnlTckts);
  }

}
