<div *ngIf="!(shwmsg)" class="page-header">
  <div class="row align-items-md-center">
    <div class="col-md-10">
      <div class="row hdrBar">
        <div *ngIf="fltrsdata.length" class="col-lg-12 col-md-12 mg-a">
          <h1>{{fltrsdata[0].rpt_nm}}</h1>
          <span class="text-gray font-size-13">{{fltrsdata[0].rpt_desc}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-2" style="margin: auto;">
      <div class="text-md-right m-v-10">
        <button nz-button nzType="default" (click)="goBack()" style="box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
          color: #1969cd;
          font-weight: 500;
          float: right;"><i nz-icon nzType="left"></i> Go back</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="!(shwmsg) && fltrsdata.length">
  <div class="filter-card" *ngIf="fltrsdata && fltrsdata[0].fltr_id != null">
    <div class="input-row" nz-row nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
      <div nz-row nzLayout.xs="column" nzFlexFill>

        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 31 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 31 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 31 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 31 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 31 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 31 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 31 )
        || ( fltrsdata[7] && fltrsdata[7].fltr_id == 31 )|| ( fltrsdata[8] && fltrsdata[8].fltr_id == 31 ) || ( fltrsdata[9] && fltrsdata[9].fltr_id == 31 )
        || ( fltrsdata[10] && fltrsdata[10].fltr_id == 31 ))">
          <div class="filter-box">
            <div class="form-label">District</div>
            <nz-select nzShowSearch [(ngModel)]="slctdDstrct" nzPlaceHolder="Choose" [nzMaxTagCount]="1"
              (ngModelChange)="onCnstyChange($event)" [ngModelOptions]="{standalone: true}" style="width : 90%">
              <!-- <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option> -->
              <nz-option *ngFor="let d of dstrtLst" [nzValue]="d.dstrct_id" [nzLabel]="d.dstrct_nm">
                {{d.dstrct_nm}}
              </nz-option>
            </nz-select>
          </div>
        </div>

        <div nzFlex="30" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 26 ) || 
            ( fltrsdata[1] && fltrsdata[1].fltr_id == 26 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 26 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 26 )
            || ( fltrsdata[4] && fltrsdata[4].fltr_id == 26 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 26 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 26 ) 
            || ( fltrsdata[7] && fltrsdata[7].fltr_id == 26 ) )">
          <div class="filter-box">
            <div class="form-label">Select Vendor</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="selectVndr" nzPlaceHolder="Choose"
              (ngModelChange)="onVndrChange($event)" [ngModelOptions]="{standalone: true}"
              nzErrorTip="Vendor is required!">
              <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
              <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
            </nz-select>
          </div>
        </div>

        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 24 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 24 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 24 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 24 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 24 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 24 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 24 ) 
        || ( fltrsdata[7] && fltrsdata[7].fltr_id == 24 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 24 ) ) && showUlbFltr==true">
          <div class="filter-box">
            <div class="form-label">Select ULB</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="selectedtenant" nzPlaceHolder="Choose" [nzMaxTagCount]="1"
              [ngModelOptions]="{standalone: true}" style="width : 90%" (ngModelChange)="onULBChange($event)">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let u of ulbLst" [nzValue]="u.tnt_id" [nzLabel]="u.tnt_nm">{{u.tnt_nm}}
              </nz-option>
            </nz-select>
          </div>
        </div>


        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 2000004 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 2000004 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 2000004 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 2000004 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 2000004 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 2000004 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 2000004 ) 
        || ( fltrsdata[7] && fltrsdata[7].fltr_id == 2000004 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 2000004 ) )">
          <div class="filter-box">
            <div class="form-label">Select Constituency</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="slctCnsty" nzPlaceHolder="Choose Constituency"
              [nzMaxTagCount]="1" [ngModelOptions]="{standalone: true}" style="width : 90%"
              (ngModelChange)="onPlngStnChange($event)">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let c of cnstyList" [nzValue]="c.cnsty_id" [nzLabel]="c.cnsty_nm">{{c.cnsty_nm}}
              </nz-option>
            </nz-select>
          </div>
        </div>


        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 2000005 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 2000005 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 2000005 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 2000005 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 2000005 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 2000005 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 2000005 ) 
        || ( fltrsdata[7] && fltrsdata[7].fltr_id == 2000005 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 2000005 ) )">
          <div class="filter-box">
            <div class="form-label">Select Polling Station</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="slctPlngStn" nzPlaceHolder="Choose Polling Station"
              [nzMaxTagCount]="1" [ngModelOptions]="{standalone: true}" style="width : 90%">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let p of plngStnList" [nzValue]="p.plng_stn_id"
                [nzLabel]="p.plng_stn_nm">{{p.plng_stn_nm}}
              </nz-option>
            </nz-select>
          </div>
        </div>


        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 24 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 24 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 24 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 24 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 24 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 24 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 24 ) 
        || ( fltrsdata[7] && fltrsdata[7].fltr_id == 24 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 24 )  ) && showUlbFltr!=true">
          <div class="filter-box">
            <div class="form-label">ULB</div>
            <div class="form-label disbledtxt">{{ulbLst[0].tnt_nm}}</div>
          </div>
        </div>





        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 5 ) || 
    ( fltrsdata[1] && fltrsdata[1].fltr_id == 5 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 5 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 5 )
    || ( fltrsdata[4] && fltrsdata[4].fltr_id == 5 ) )">
          <div class="filter-box">
            <div class="form-label">Select Ward</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="selectWrd" (ngModelChange)="getSprtUsrLst($event)"
              nzPlaceHolder="Choose" [nzMaxTagCount]="1" [ngModelOptions]="{standalone: true}" style="width : 90%">
              <nz-option *ngFor="let w of wardsLst" [nzValue]="w.wrd_id" [nzLabel]="w.wrd_nm">{{w.wrd_nm}}
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 16 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 16 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 16 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 16 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 16 ) )">
          <div class="filter-box">
            <div class="form-label">Select Employe</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="selectUsr" nzPlaceHolder="Choose" [nzMaxTagCount]="1"
              [ngModelOptions]="{standalone: true}" style="width : 90%">
              <nz-option *ngFor="let w of usrLst" [nzValue]="w.usr_id" [nzLabel]="w.usr_nm">{{w.usr_nm}}
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 17 ) || 
    ( fltrsdata[1] && fltrsdata[1].fltr_id == 17 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 17 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 17 )
    || ( fltrsdata[4] && fltrsdata[4].fltr_id == 17 ) )">
          <div class="filter-box" style="    margin: 0 17px 0px 0;">
            <div class="form-label">Enter Delay Days</div>
            <input type="number" placeholder="Days" nz-input [(ngModel)]="dlyDys" />
          </div>
        </div>


        <div nzFlex="40" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 6 ) || 
    ( fltrsdata[1] && fltrsdata[1].fltr_id == 6 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 6 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 6 )
    || ( fltrsdata[4] && fltrsdata[4].fltr_id == 6 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 6 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 6 )
    || ( fltrsdata[7] && fltrsdata[7].fltr_id == 6 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 6 ) || ( fltrsdata[0] && fltrsdata[0].fltr_id == 4 ) || 
    ( fltrsdata[1] && fltrsdata[1].fltr_id == 4 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 4 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 4 )
    || ( fltrsdata[4] && fltrsdata[4].fltr_id == 4 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 4 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 4 ) 
    || ( fltrsdata[7] && fltrsdata[7].fltr_id == 4 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 4 )) ">
          <div class="filter-box" style="width: 100%;">
            <div class="form-label">Date Range<span class="required-color">*</span></div>
            <nz-range-picker [(ngModel)]="range" [nzFormat]="dateFormat"></nz-range-picker>
          </div>
        </div>

        <div nzFlex="30" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 29 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 29 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 29 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 29 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 29 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 29 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 29 )
        || ( fltrsdata[7] && fltrsdata[7].fltr_id == 29 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 29 )) ">
          <div class="filter-box" style="width: 100%;">
            <div class="form-label">Date and Time<span class="required-color">*</span></div>
            <!-- <nz-date-picker nzShowTime nzFormat="yyyy-MM-dd HH:mm:ss" [(ngModel)]="dateTime" (ngModelChange)="onChange($event)"></nz-date-picker> -->

            <nz-range-picker [nzShowTime]="{ nzFormat: 'HH:mm:ss' }" [(ngModel)]="dateTime"
              nzFormat="yyyy-MM-dd HH:mm:ss"></nz-range-picker>

          </div>
        </div>

        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 8 ) || 
    ( fltrsdata[1] && fltrsdata[1].fltr_id == 8 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 8 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 8 )
    || ( fltrsdata[4] && fltrsdata[4].fltr_id == 8 ) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 8 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 8 ) 
    || ( fltrsdata[7] && fltrsdata[7].fltr_id == 8 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 8 ))">
          <div class="filter-box">
            <div class="form-label">Select Month</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="slctdmnth" nzPlaceHolder="Choose"
              [ngModelOptions]="{standalone: true}" nzErrorTip="Month is required!">
              <nz-option *ngFor="let mn of months" [nzValue]="mn.mnth_id" [nzLabel]="mn.mnth_nm"> </nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 7 ) || 
    ( fltrsdata[1] && fltrsdata[1].fltr_id == 7 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 7 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 7 )
    || ( fltrsdata[4] && fltrsdata[4].fltr_id == 7 ) )">
          <div class="filter-box">
            <div class="form-label">Select Year</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="slctdyear" nzPlaceHolder="Choose"
              [ngModelOptions]="{standalone: true}" nzErrorTip="Year is required!">
              <nz-option *ngFor="let yr of year" [nzValue]="yr.yr_id" [nzLabel]="yr.yr_nm"> </nz-option>
            </nz-select>
          </div>
        </div>

        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 2000003 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 2000003 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 2000003 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 2000003 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 2000003 )||( fltrsdata[5] && fltrsdata[5].fltr_id == 2000003 ) || 
        ( fltrsdata[6] && fltrsdata[6].fltr_id == 2000003 ) || ( fltrsdata[7] && fltrsdata[7].fltr_id == 2000003 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 2000003 )
        || ( fltrsdata[9] && fltrsdata[9].fltr_id == 2000003 )  )">
          <div class="filter-box">
            <div class="form-label">Select Time<span class="required-color">*</span></div>
            <nz-time-picker [(ngModel)]="time" [nzFormat]="timeFormat"></nz-time-picker>
          </div>
        </div>



        <div nzFlex="20" nzFlex.xs="100" class="px-8"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 28 ) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 28 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 28 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 28 )
        || ( fltrsdata[4] && fltrsdata[4].fltr_id == 28 )|| ( fltrsdata[5] && fltrsdata[5].fltr_id == 28 ) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 28 )
        || ( fltrsdata[7] && fltrsdata[7].fltr_id == 28 ) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 28 )|| ( fltrsdata[9] && fltrsdata[9].fltr_id == 28 ) )">
          <div class="filter-box">
            <div class="form-label">Select Category</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="selectalrtCat" nzPlaceHolder="Choose" [nzMaxTagCount]="1"
              [ngModelOptions]="{standalone: true}" style="width : 90%">
              <nz-option *ngFor="let c of alrtCatLst" [nzValue]="c.alert_cat_id" [nzLabel]="c.alert_cat_cd">
                {{c.alert_cat_cd}}
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2" *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 32) || 
              ( fltrsdata[1] && fltrsdata[1].fltr_id == 32) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 32) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 32)
              || ( fltrsdata[4] && fltrsdata[4].fltr_id == 32) || ( fltrsdata[5] && fltrsdata[5].fltr_id == 32) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 32)
              || ( fltrsdata[7] && fltrsdata[7].fltr_id == 32) || ( fltrsdata[8] && fltrsdata[8].fltr_id == 32))">
          <div class="filter-box">
            <div class="form-label">Select Vehicle Category</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="selectedCtgry" nzPlaceHolder="Choose"
              [ngModelOptions]="{standalone: true}" nzErrorTip="Vehicle is required!">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let v of asrtCtgryLst" [nzValue]="v.asrt_ctgry_id"
                [nzLabel]="v.asrt_ctgry_nm"></nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 27 ) || 
                ( fltrsdata[1] && fltrsdata[1].fltr_id == 27 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 27 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 27 )
                || ( fltrsdata[4] && fltrsdata[4].fltr_id == 27 )   || ( fltrsdata[5] && fltrsdata[5].fltr_id == 27 )   || ( fltrsdata[6] && fltrsdata[6].fltr_id == 27 )
                || ( fltrsdata[7] && fltrsdata[7].fltr_id == 27 )  || ( fltrsdata[8] && fltrsdata[8].fltr_id == 27 ) )">
          <div class="filter-box">
            <div class="form-label">Select Vehicle</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="selectedasrt" nzPlaceHolder="Choose"
              [ngModelOptions]="{standalone: true}" nzErrorTip="Vehicle is required!">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let v of asrtList" [nzValue]="v.asrt_id" [nzLabel]="v.asrt_nm"></nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 30  && fltrsdata[0].fltr_sqnce_id%2==1) || 
              ( fltrsdata[1] && fltrsdata[1].fltr_id == 30  && fltrsdata[1].fltr_sqnce_id%2==1) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 30  && fltrsdata[2].fltr_sqnce_id%2==1) 
              || ( fltrsdata[3] && fltrsdata[3].fltr_id == 30  && fltrsdata[3].fltr_sqnce_id%2==1) || ( fltrsdata[4] && fltrsdata[4].fltr_id == 30  && fltrsdata[4].fltr_sqnce_id%2==1)
              || ( fltrsdata[5] && fltrsdata[5].fltr_id == 30  && fltrsdata[5].fltr_sqnce_id%2==1) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 30  && fltrsdata[6].fltr_sqnce_id%2==1)
              || ( fltrsdata[7] && fltrsdata[7].fltr_id == 30  && fltrsdata[7].fltr_sqnce_id%2==1)  || ( fltrsdata[8] && fltrsdata[8].fltr_id == 30  && fltrsdata[8].fltr_sqnce_id%2==1))">
          <div class="filter-box">
            <div class="form-label">Select Start Location</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="strtLoc" nzPlaceHolder="Choose"
              [ngModelOptions]="{standalone: true}" nzErrorTip="Start Location is required!">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let v of tripLoctns" [nzValue]="v.strt_fnce_id" [nzLabel]="v.strt_fnce_nm"></nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 30  && fltrsdata[0].fltr_sqnce_id%2==0) || 
        ( fltrsdata[1] && fltrsdata[1].fltr_id == 30  && fltrsdata[1].fltr_sqnce_id%2==0) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 30  && fltrsdata[2].fltr_sqnce_id%2==0) 
        || ( fltrsdata[3] && fltrsdata[3].fltr_id == 30  && fltrsdata[3].fltr_sqnce_id%2==0) || ( fltrsdata[4] && fltrsdata[4].fltr_id == 30  && fltrsdata[4].fltr_sqnce_id%2==0)
        || ( fltrsdata[5] && fltrsdata[5].fltr_id == 30  && fltrsdata[5].fltr_sqnce_id%2==0) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 30  && fltrsdata[6].fltr_sqnce_id%2==0)
        || ( fltrsdata[7] && fltrsdata[7].fltr_id == 30  && fltrsdata[7].fltr_sqnce_id%2==0)  || ( fltrsdata[8] && fltrsdata[8].fltr_id == 30  && fltrsdata[8].fltr_sqnce_id%2==0))">
          <div class="filter-box">
            <div class="form-label">Select End Location</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="endLoc" nzPlaceHolder="Choose"
              [ngModelOptions]="{standalone: true}" nzErrorTip="End Location is required!">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let v of tripEndLoctns" [nzValue]="v.end_fnce_id"
                [nzLabel]="v.end_fnce_nm"></nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 30  && fltrsdata[0].fltr_sqnce_id%2==0) || 
      ( fltrsdata[1] && fltrsdata[1].fltr_id == 30  && fltrsdata[1].fltr_sqnce_id%2==0) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 30  && fltrsdata[2].fltr_sqnce_id%2==0) 
      || ( fltrsdata[3] && fltrsdata[3].fltr_id == 30  && fltrsdata[3].fltr_sqnce_id%2==0) || ( fltrsdata[4] && fltrsdata[4].fltr_id == 30  && fltrsdata[4].fltr_sqnce_id%2==0)
      || ( fltrsdata[5] && fltrsdata[5].fltr_id == 30  && fltrsdata[5].fltr_sqnce_id%2==0) || ( fltrsdata[6] && fltrsdata[6].fltr_id == 30  && fltrsdata[6].fltr_sqnce_id%2==0)
      || ( fltrsdata[7] && fltrsdata[7].fltr_id == 30  && fltrsdata[7].fltr_sqnce_id%2==0)  || ( fltrsdata[8] && fltrsdata[8].fltr_id == 30  && fltrsdata[8].fltr_sqnce_id%2==0))">
          <div class="filter-box">
            <div class="form-label">Select End Location</div>
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="endLoc" nzPlaceHolder="Choose"
              [ngModelOptions]="{standalone: true}" nzErrorTip="End Location is required!">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let v of tripEndLoctns" [nzValue]="v.end_fnce_id"
                [nzLabel]="v.end_fnce_nm"></nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 2000006 ) || 
            ( fltrsdata[1] && fltrsdata[1].fltr_id == 2000006 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 2000006 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 2000006 )
            || ( fltrsdata[4] && fltrsdata[4].fltr_id == 2000006 )   || ( fltrsdata[5] && fltrsdata[5].fltr_id == 2000006 )   || ( fltrsdata[6] && fltrsdata[6].fltr_id == 2000006 )
            || ( fltrsdata[7] && fltrsdata[7].fltr_id == 2000006 )  || ( fltrsdata[8] && fltrsdata[8].fltr_id == 2000006 ) )">
          <div class="filter-box">
            <div class="form-label ">Select Vehicle</div>
            <nz-select [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
              nzPlaceHolder="Please select" [(ngModel)]="selectedvehcls" style="width:300px;margin-right:15px">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let v of asrtList" [nzValue]="v.asrt_id" [nzLabel]="v.asrt_nm"></nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="2"
          *ngIf="(( fltrsdata[0] && fltrsdata[0].fltr_id == 3000000 ) || 
          ( fltrsdata[1] && fltrsdata[1].fltr_id == 3000000 ) || ( fltrsdata[2] && fltrsdata[2].fltr_id == 3000000 ) || ( fltrsdata[3] && fltrsdata[3].fltr_id == 3000000 )
          || ( fltrsdata[4] && fltrsdata[4].fltr_id == 3000000 )   || ( fltrsdata[5] && fltrsdata[5].fltr_id == 3000000 )   || ( fltrsdata[6] && fltrsdata[6].fltr_id == 3000000 )
          || ( fltrsdata[7] && fltrsdata[7].fltr_id == 3000000 )  || ( fltrsdata[8] && fltrsdata[8].fltr_id == 2000006 ) )">
          <div class="filter-box">
            <div class="form-label ">Select Vehicle Group</div>
            <nz-select nzPlaceHolder="Please select" [(ngModel)]="group" style="width:300px;margin-right:15px">
              <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
              <nz-option *ngFor="let v of asrtGrps" [nzValue]="v.asrt_grp_id" [nzLabel]="v.asrt_grp_nm"></nz-option>
            </nz-select>
          </div>
        </div>
        <div nzFlex="20" nzFlex.xs="100" class="px-8" nzOffset="4" style="    margin-top: 18px;
      margin-left: 109px;">
          <button nz-button nzType="primary" (click)="getRports()">Get Data</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!shwmsg && !loader && rpt_id==184" class="row align-items-md-center">
  <div class="col-md-12">
    <div style="position: absolute;
      right: 400px;
      top: 14px;
      z-index: 1;
      border-radius: 5px;
      color: black;
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
      padding: 7px;">O:&nbsp;<span>Operated</span>&nbsp;&nbsp;&nbsp;N:&nbsp;<span>Not Operated</span>
    </div>
  </div>
</div>
<div *ngIf="!shwmsg && !loader" class="row align-items-md-center">
  <div class="col-md-12">
    <div (click)="createPdf()">
      <i nz-icon nzType="file-pdf" style="position: absolute;
      right: 275px;
      top: 14px;
      z-index: 1;
      border-radius: 5px;
      color: black;
      cursor: pointer;
      font-size: 20px;
      border: 1px solid #dddddd;
      padding: 7px;"></i>

    </div>
  </div>

</div>


<div *ngIf="shwmsg">
  <nz-result nzStatus="error" nzTitle="Access Denied" nzSubTitle="You Don't Have Access To View This Report">
    <div nz-result-extra>
      <button nz-button nzType="primary" (click)="gotorpt()">Go to reports</button>
    </div>
  </nz-result>
</div>
<div *ngIf="rpt_id==2000011" class="row">
  <div *ngFor="let s of asrtStsList"><span style="padding: 4px;margin: 4px;"> {{s.asr_sts_clr_cd}} : {{s.asrt_sts_nm}}
    </span></div>
</div>
<div *ngIf="!(shwmsg) && fltrsdata.length && rpt_id!=1000020" nz-row class="gridTop bluhdrgrid">
  <ds-loading *ngIf="loader"></ds-loading>
  <div class="ds-card grid">
    <dx-data-grid id="gridContainer" [dataSource]="rowData" [remoteOperations]="false" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
      [wordWrapEnabled]="true" [showRowLines]="true" [showBorders]="true" (onCellClick)="devCellclk(columnDefs,$event)"
      style="width:100%" (onCellPrepared)="onCellPrepared(columnDefs,$event)" (onExporting)="onExporting($event)">
      <dxo-paging [pageSize]="fltrsdata[0].pagination"></dxo-paging>
      <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
        [showInfo]="true">
      </dxo-pager>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false"></dxo-search-panel>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
      <dxo-column-chooser [enabled]="true" mode="select">
      </dxo-column-chooser>
      <dxo-export [enabled]="fltrsdata[0].excel_in ==  1 ? true : false" [allowExportSelectedData]="false"
        [fileName]="fltrsdata[0].excel_nm"></dxo-export>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxi-column *ngFor="let f of columnDefs" [allowFiltering]="f.filter" [allowHeaderFiltering]="f.filter"
        [allowHiding]="true" [visible]="f.visible == 1" [dataField]="f.field" [caption]="f.headerName"
        format="fixedPoint" [width]="f.width"
        [format]="f.smryValueFormat == 'true' ?{type: 'fixedPoint', precision: 2} : ''" [groupIndex]="f.groupIndex"
        [alignment]="f.algnmnt" [dataType]="f.datatype" [allowResizing]="true">
      </dxi-column>

      <dxo-summary>
          <dxi-total-item *ngFor="let f of columnDefs" [summaryType]="f.summaryType?f.summaryType:''"
          [column]="f.field" [valueFormat]="f.smryValueFormat == 'true' ? {type: 'fixedPoint', precision: 2} : ''"
          [displayFormat]="f.displayFormat" [showInGroupFooter]="f.showInGroupFooter==1?'true':'false'">
          </dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </div>
</div>
<div *ngIf="rpt_id==1000020" nz-row class="gridTop">
  <ds-loading *ngIf="loader"></ds-loading>
  <div class="ds-card grid">
    <dx-data-grid id="gridContainer" [dataSource]="rowData" [remoteOperations]="false" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
      [wordWrapEnabled]="true" [showRowLines]="true" [showBorders]="true" (onCellClick)="devCellclk(columnDefs,$event)"
      style="width:100%" (onCellPrepared)="onCellPrepared(columnDefs,$event)">
      <dxo-paging [pageSize]="fltrsdata[0].pagination"></dxo-paging>
      <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
        [showInfo]="true">
      </dxo-pager>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false"></dxo-search-panel>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
      <dxo-column-chooser [enabled]="true" mode="select">
      </dxo-column-chooser>
      <dxo-export [enabled]="fltrsdata[0].excel_in ==  1 ? true : false" [allowExportSelectedData]="false"
        [fileName]="fltrsdata[0].excel_nm"></dxo-export>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxi-column *ngFor="let f of columnDefs" [allowFiltering]="f.filter" [allowHeaderFiltering]="f.filter"
        [allowHiding]="true" [visible]="f.visible == 1" [dataField]="f.field" [caption]="f.headerName"
        format="fixedPoint" [width]="f.width"
        [format]="f.smryValueFormat == 'true' ? {type:'currency',currency:'INR', precision:'2'} : ''"
        [groupIndex]="f.groupIndex" [alignment]="f.algnmnt" [dataType]="f.datatype" [allowResizing]="true">
      </dxi-column>
      <dxi-column [showInColumnChooser]="false" type="buttons" [width]="100" caption="Image Before Cleaning"
        cellTemplate="prfleTemplate1">
        <div *dxTemplate="let data of 'prfleTemplate1'">
          <div class="cursor-pointer tblPrfleBtn" (click)="getimage(data)">
            <span nz-icon nzType="picture" nzTheme="outline"></span>
          </div>
        </div>
      </dxi-column>
      <dxi-column [showInColumnChooser]="false" type="buttons" [width]="100" caption="Image After Cleaning"
        cellTemplate="prfleTemplate2">
        <div *dxTemplate="let data of 'prfleTemplate2'">
          <div class="cursor-pointer tblPrfleBtn" (click)="getimage2(data)">
            <span nz-icon nzType="picture" nzTheme="outline"></span>
          </div>
        </div>
      </dxi-column>
      <dxo-summary>
        <dxi-total-item *ngFor="let f of columnDefs" [summaryType]="f.summaryType?f.summaryType:''"
        [column]="f.field" [valueFormat]="f.smryValueFormat == 'true' ? {type: 'fixedPoint', precision: 2} : ''"
        [displayFormat]="f.displayFormat" [showInGroupFooter]="f.showInGroupFooter==1?'true':'false'">
        </dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </div>
</div>