import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  isFolded: any;
  isSideNavDark: any;
  selectedHeaderColor: any;
  isExpand: any;
  usrsLst: any;
  columnDefs;
  shwSidebar: boolean;
  shwUsrEditDiv: boolean;
  shwUsrDelDiv: boolean;
  sidebarHeading: string;
  usrDtlsFrm: FormGroup;
  imageUrl: any;
  editFile: boolean;
  removeUpload: boolean;
  dprtLst: any;
  dsgnsLst: any;
  orgnsLst: any;
  rlesLst: any;
  fnlUsrImg: any;
  permissions: any;
  slctdUsr: any;
  ermsg: boolean = false;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  invldPwd: boolean = false;
  constructor(private themeService: ThemeConstantService, public apiSrv: AppsService, private fb: FormBuilder,
    private message: NzMessageService) {
    const permTxt = 'users';
    const prmeRte = `user/permissions/${permTxt}`;
    this.apiSrv.get(prmeRte).subscribe((res) => {
      // tslint:disable-next-line:no-string-literal
      this.permissions = res['data'][0];
    });
  }

  ngOnInit() {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.usrDtlsFrm = this.fb.group({
      fst_nm: new FormControl(''),
      lst_nm: new FormControl(''),
      email: new FormControl(''),
      mobile: new FormControl(''),
      desgn: new FormControl(''),
      dprt: new FormControl(''),
      orgn: new FormControl(''),
      role: new FormControl(''),
      usernm: new FormControl(''),
      pwd: new FormControl(''),
      cnfrmpwd: [null, [this.confirmationValidator]],
      usr_msg_in: new FormControl('')
    });
    this.getUsers();
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value === this.usrDtlsFrm.controls.pwd.value) {
      return { error: false };
    } else if (control.value !== this.usrDtlsFrm.controls.pwd.value) {
      return { confirm: true, error: true };
    } else {
      return {};
    }
  }

  getUsers() {
    const rte = 'auth2/admin/users';
    let ct = 0;
    this.usrAcsErrMsg = false;
    this.apiSrv.get(rte).subscribe(res => {
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          this.getUsrDsgns();
          this.getUsrDprts();
          this.getUsrOrgns();
          this.getUsrRles();
          this.usrsLst = res['data'];
          this.usrsLst.filter(k => {
            k['sno'] = ++ct;
          });
          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
            { headerName: 'Name', field: 'fst_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Mobile No', field: 'mobile_nu', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Organisation', field: 'orgn_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Department', field: 'dprt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Designation', field: 'dsgns_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 150, sortable: true, filter: true }
          ];
        } else {
          this.usrAcsErrMsg = true;
        }
      }
    });
  }

  getUsrDsgns() {
    const rte = 'org/designations';
    this.apiSrv.get(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      this.dsgnsLst = res['data'];
    });
  }

  getUsrDprts() {
    const rte = 'org/departments';
    this.apiSrv.get(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      this.dprtLst = res['data'];
    });
  }

  getUsrOrgns() {
    const rte = 'admin/organizations';
    this.apiSrv.get(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      this.orgnsLst = res['data'];
    });
  }

  getUsrRles() {
    const rte = 'auth2/admin/roles';
    this.apiSrv.get(rte).subscribe(res => {
      // tslint:disable-next-line:no-string-literal
      this.rlesLst = res['data'];
    });
  }

  updateConfirmValidator(): void {
    this.invldPwd = false;
    var pswd_rgx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
    if (!pswd_rgx.test(this.usrDtlsFrm.value.pwd)) {
      this.invldPwd = true;
    }
    /** wait for refresh value */
    Promise.resolve().then(() => this.usrDtlsFrm.controls.cnfrmpwd.updateValueAndValidity());
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New User',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt, data) {
    this.shwSidebar = true;
    if (opt === 'edit') {
      this.shwUsrEditDiv = true;
      this.shwUsrDelDiv = false;
      this.slctdUsr = data.data.usr_id;
      this.sidebarHeading = 'Edit User';
      this.imageUrl = data.data.usr_imge_url_tx;
      this.usrDtlsFrm.get('fst_nm').setValue(data.data.fst_nm);
      this.usrDtlsFrm.get('lst_nm').setValue(data.data.lst_nm);
      this.usrDtlsFrm.get('email').setValue(data.data.eml_tx);
      this.usrDtlsFrm.get('mobile').setValue(data.data.mobile_nu);
      this.usrDtlsFrm.get('desgn').setValue(data.data.dsgns_id);
      this.usrDtlsFrm.get('dprt').setValue(data.data.dprt_id);
      this.usrDtlsFrm.get('orgn').setValue(data.data.orgn_id);
      this.usrDtlsFrm.get('role').setValue(data.data.rle_id);
      this.usrDtlsFrm.get('usernm').disable();
      this.usrDtlsFrm.get('pwd').disable();
      // this.usrDtlsFrm.get('cnfrmpwd').setValue(data.data.pwd);
      this.usrDtlsFrm.get('usr_msg_in').setValue(data.data.usr_msg_in);

    } else if (opt === 'delete') {
      this.slctdUsr = data.data.usr_id;
      this.shwUsrEditDiv = false;
      this.shwUsrDelDiv = true;
      this.sidebarHeading = 'Delete User';
    } else {
      this.slctdUsr = '';
      this.shwUsrEditDiv = false;
      this.shwUsrDelDiv = false;
      this.sidebarHeading = 'Add New User';
    }
  }

  uploadFile(event) {
    this.fnlUsrImg = [];
    const reader = new FileReader(); // HTML5 FileReader API
    const file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.fnlUsrImg.push(reader.result);
        // this.prflimageUrl = reader.result;
        this.usrDtlsFrm.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      };
      // ChangeDetectorRef since file is loading outside the zone
      // this.cd.markForCheck();
    }
  }
  phnoKeyUp() {
    console.log(this.usrDtlsFrm.value.mobile);
    if (!this.usrDtlsFrm.value.mobile.toString().match(/^[6789]\d*$/) || this.usrDtlsFrm.value.mobile.toString().length != 10) {
      this.ermsg = true;
    }
    else {
      this.ermsg = false;
    }
  }
  saveUserData() {

    if (this.slctdUsr === '') {
      if (this.usrDtlsFrm.value.fst_nm != undefined && this.usrDtlsFrm.value.fst_nm != '' &&
        this.usrDtlsFrm.value.mobile != undefined && this.usrDtlsFrm.value.mobile != '' && this.ermsg == false &&
        this.usrDtlsFrm.value.role != undefined && this.usrDtlsFrm.value.role != '' &&
        this.usrDtlsFrm.value.usernm != undefined && this.usrDtlsFrm.value.usernm != '' &&
        this.usrDtlsFrm.value.pwd != undefined && this.usrDtlsFrm.value.pwd != '') {
        console.log("Go to Submit");
        for (const i in this.usrDtlsFrm.controls) {
          this.usrDtlsFrm.controls[i].markAsDirty();
          this.usrDtlsFrm.controls[i].updateValueAndValidity();
        }

        const fnlUsrData = {
          fst_nm: this.usrDtlsFrm.value.fst_nm,
          lst_nm: this.usrDtlsFrm.value.lst_nm,
          eml_tx: this.usrDtlsFrm.value.email,
          mobile_nu: this.usrDtlsFrm.value.mobile,
          rle_id: this.usrDtlsFrm.value.role,
          orgn_id: this.usrDtlsFrm.value.orgn?this.usrDtlsFrm.value.orgn:0,
          dsgns_id: this.usrDtlsFrm.value.desgn?this.usrDtlsFrm.value.desgn:0,
          dprt_id: this.usrDtlsFrm.value.dprt,
          usr_nm: this.usrDtlsFrm.value.usernm,
          pwd: this.usrDtlsFrm.value.pwd,
          img: this.imageUrl
        };
        const rte = 'auth2/admin/users';
        console.log(fnlUsrData);
        this.apiSrv.create(fnlUsrData, rte).subscribe(res => {
          console.log(res);
          if (res['status'] === 200) {
            this.message.success('New User details Sucessfully Added', {
              nzDuration: 2000
            });
            this.getUsers();
            this.closeSideBar();
          }
        });

      } else {
        console.log("DONt Go to Submit");
        this.message.info('Please Enter Required Details');
      }

    } else {
      let newImg;
      if (this.shwUsrEditDiv === true) {
        if (this.imageUrl !== null) {
          const chckImg = this.imageUrl.split(':');
          if (chckImg[0] === 'data') {
            newImg = true;
          }
          if (chckImg[0] === 'https') {
            newImg = false;
          }
        }
        // tslint:disable-next-line:variable-name
        const edit_fnlUsrData = {
          fst_nm: this.usrDtlsFrm.value.fst_nm,
          lst_nm: this.usrDtlsFrm.value.lst_nm,
          eml_tx: this.usrDtlsFrm.value.email,
          mobile_nu: this.usrDtlsFrm.value.mobile,
          rle_id: this.usrDtlsFrm.value.role,
          orgn_id: this.usrDtlsFrm.value.orgn,
          dsgns_id: this.usrDtlsFrm.value.desgn,
          dprt_id: this.usrDtlsFrm.value.dprt,
          img: this.imageUrl === undefined ? '' : this.imageUrl,
          isNwImg: newImg === undefined ? false : newImg
        };
        // console.log(edit_fnlUsrData);
        // return;
        const rte = `auth2/admin/users/${this.slctdUsr}`;
        this.apiSrv.update(edit_fnlUsrData, rte).subscribe(res => {
          if (res['status'] === 200) {
            this.message.success('User details Sucessfully Updated', {
              nzDuration: 2000
            });
            this.getUsers();
            this.closeSideBar();
          }
        });
      } else {
        const data = {};
        // console.log(this.slctdUsr);
        // return;
        const rte = `auth2/admin/users/${this.slctdUsr}`;
        this.apiSrv.delete( rte).subscribe(res => {
          if (res['status'] === 200) {
            this.message.success('User details Sucessfully Deleted', {
              nzDuration: 2000
            });
            this.getUsers();
            this.closeSideBar();
          }
        });
      }
    }
  }

  cancel(): void {
    this.message.info('User Not Deleted');
  }

  closeSideBar(): void {
    this.shwSidebar = false;
  }
}
