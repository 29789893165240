import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { MessageDialogComponent } from '../../../shared/components/message-dialog/message-dialog.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
// import {ToastrService } from 'ngx-toastr';
// import { NzNotificationService } from 'ng-zorro-antd';


import { Location } from '@angular/common';

@Component({
  selector: 'app-asset-agreements',
  templateUrl: './asset-agreements.component.html',
  styleUrls: ['./asset-agreements.component.scss']
})
export class AssetAgreementsComponent implements OnInit {

  constructor(private fb: FormBuilder,private themeService: ThemeConstantService, public apiSrv: AppsService, public atmSrv: AtomServiceService, private message: NzMessageService, private modal: NzModalService, 
   
    private location: Location,private nzMessageService: NzMessageService) { }
  shwschvlymDelDiv
  shwschvlymEditDiv
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs
  mainMessage
  loader
  gridData
  shwSidebar
  sidebarHeading
  AgreementTypeForm
  shwLdng
  editdata
  delData
  delete :boolean =false;
  ngOnInit() {
    this.AgreementTypeForm = new FormGroup({
      agrmt_type_nm: new FormControl('')
    });
    this.getGridData();
  }
  getGridData()
  {
    // console.log('grid data called')
    this.mainMessage = null;
    this.loader = true;
    this.usrAcsErrMsg = false;
    this.apiSrv.get('asset/tracking/agreement-types').subscribe((res) => {
      console.log(res)
      this.loader = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          // console.log(res['data'])
          // this.user.permissions = (res['perm'] === undefined) ? this.user.permissions : res['perm'][0];
          if (res['data'].length === 0) { this.mainMessage = 'No entries found in the database.'; }

          this.gridData = res['data'];
          // console.log(this.gridData)

          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 60, sortable: true, filter: false },
            { headerName: 'Agreement Type Name', field: 'agrmt_type_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },            
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true }
          ];
          // else if (res['status'] === 404) {
          //   let permTxt = 'Desginations Creation';
          //   let prmeRte = `user/permissions/${permTxt}`;
          //   this.crdsrv.get(prmeRte).subscribe((prmres) => {
          //   // console.log(prmres['data']);

          //   });
          //   // this.user.permissions = { 'slct_in': 0, 'insrt_in': 0, 'updt_in': 0, 'dlte_in': 0, 'exprt_in': 0 };
          // }
        } else {
          this.usrAcsErrMsg = true;
        }
      }else {
       
      //   this.toastr.error( res["message"], '', {
      //    timeOut: 10000,
      //    positionClass: 'toast-top-right',
      //  });
      this.message.error(res['message'], {
        nzDuration: 2000
      });
     }
    }, (err) => {
      this.loader = false;
    });
  }
  onToolbarPreparing(e) {
    // console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Agreement Type',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(opt, data)
  {
    this.shwSidebar = true;
    this.AgreementTypeForm.value.agrmt_type_nm='';
    this.AgreementTypeForm.value.dscn_tx='';
    if(opt == 'edit')
    {
      this.shwschvlymEditDiv = true;
      this.shwschvlymDelDiv = false;
      this.sidebarHeading = 'Edit Agreement Type';
      this.AgreementTypeForm.get('agrmt_type_nm').setValue(data.agrmt_type_nm)
    }
    else if(opt == 'delete')
    {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = true;
      this.sidebarHeading = 'Delete Agreement Type';
    }
    else
    {
      this.shwschvlymEditDiv=false
      this.shwschvlymDelDiv=false
      this.sidebarHeading = 'Add New Agreement Type';
      this.delete=false
     
    }
  }
  closeSideBar():void
  {
    this.shwSidebar = false;
  }
  goBack()
  {
    this.location.back();
  }
  saveDeleteAgreementType(){
    this.shwLdng=true
    this.apiSrv.delete(`asset/tracking/agreement-type/${this.delData.agrmt_type_id}`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {
        // this.toastr.success('Agreement Type Deleted Successfully', '', {
        //   timeOut: 3000,
        // });
        this.message.success('Agreement Type Deleted Successfully', {
          nzDuration: 2000
        });
        this.cancel()
      } else {
        // this.toastr.error(res['message'], '', {
        //   timeOut: 3000
        // });
        this.message.error(res['message'], {
          nzDuration: 2000
        });
      }
    }, (err) => {
      this.shwLdng = false;
      // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
      //   timeOut: 3000
      // });
      this.message.error('Something Went Wrong. Please contact the administrator', {
        nzDuration: 2000
      });
    })
  }
  saveEditAgreementType()
  {
    let data={
      agrmt_type_nm:this.AgreementTypeForm.value.agrmt_type_nm
    }
    console.log(this.editdata)
    console.log(data)
    if(data.agrmt_type_nm !="")
    {
      this.shwLdng=true
      this.apiSrv.update(data, `asset/tracking/agreement-type/${this.editdata.agrmt_type_id}`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Agreement Type Updated Successfully', '', {
          //   timeOut: 3000,
          // });
          this.message.success('Agreement Type Updated Successfully', {
            nzDuration: 2000
          });
          this.cancel()
        } else {
          this.message.error(res['message'], {
            nzDuration: 2000
          });
        }
      }, (err) => {
        this.shwLdng = false;
        this.message.error('Something Went Wrong. Please contact the administrator', {
          nzDuration: 2000
        });
      })
    }
    else
    {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
      this.message.error('Please Enter Required Fields Correctly', {
        nzDuration: 2000
      });
    }
  }
  saveAgreementType()
  {
    console.log(this.AgreementTypeForm.value)
    let data={
      agrmt_type_nm:this.AgreementTypeForm.value.agrmt_type_nm,
    }
    console.log(data)
    if(data.agrmt_type_nm !="")
    {
      this.shwLdng=true
      this.apiSrv.create(data, `asset/tracking/agreement-type`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Agreement Type Created Successfully', '', {
          //   timeOut: 3000,
          // });
          this.message.success('Agreement Type Created Successfully', {
            nzDuration: 2000
          });
          this.cancel()
        } else {
          this.message.error(res['message'], {
            nzDuration: 2000
          });
        }
      }, (err) => {
        this.shwLdng = false;
        // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
        //   timeOut: 3000
        // });
        this.message.error('Something Went Wrong. Please contact the administrator', {
          nzDuration: 2000
        });
      })
    }
    else
    {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
      this.message.error('Please Enter Required Fields Correctly', {
        nzDuration: 2000
      });
    }
    console.log(data)
  }
  onDelete2(event)
  {
    this.delete = true;
    this.delData=event.data
    this.openSideBar("delete", this.delData);
  }
  onEdit2(event)
  {
    this.delete=false
    this.editdata = event.data;
    this.openSideBar("edit", this.editdata);
  }
  cancel()
  {
    this.AgreementTypeForm.reset()
    this.shwSidebar=false;
    this.getGridData();
  }
  oncancel(): void {
    this.nzMessageService.info('Agreement Type Not Deleted');
  }
}
