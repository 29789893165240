import { Component, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import * as moment from 'moment';
import { AppsService } from 'src/app/shared/services/apps.service';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';


/* Chart code */
// Themes begin
am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);
// Themes end
@Component({ 
  selector: 'app-urban-dashboard',
  templateUrl: './urban-dashboard.component.html',
  styleUrls: ['./urban-dashboard.component.scss']
})
export class UrbanDashboardComponent implements OnInit {


  header_label = {
    name: 'Clean Andhra Pradesh(CLAP)',
    dtepkrlbl:true
  };


  chart: am4charts.XYChart;
  chart2: am4charts.XYChart;
  chart3: am4charts.XYChart;
  ulbTripsDta: any;
  tpPrfmnceUlbDta: any;
  lstPrfmnceUlbDta: any; 
  dateFormat = 'yyyy/MM/dd';
  ulblable;
  ulbTripscolumnDefs;
  tpPrfmnceUlbcolumnDefs;
  lstPrfmnceUlbcolumnDefs;
  slctdDt = new Date();
  today = new Date();
  curDate;
  curTblDate;
  ttlDstncCnt: any;
  ttlTrpsCnt: any; prmryTrpsCnt: any; scndryTrpsCnt: any;
  showLdr = false;
  postslctdDt;
  ctgryWseCnts: any;
  // legend: am4charts.Legend;
  curTmspDate: string;
  clntDtls: any;
  chartHeatData = [];
  heatChrt: any;
  curYr: any;
  shwzrwErr: boolean = false;
  shwScndryzrwErr: boolean = false;
  tdyCoveredTrips: any = [];
  ThrtyKmbelowVehcles: any = [];
  ThrtyKmbelowVehclescolumnDefs: any = [];
  ulbwseopratedTripsTble: any;
  ulbwseopratedTripsTblecolumnDefs: any = [];
  ulbVndrwseopratedTripsTble: any;
  ulbVndrwseopratedTripsTblecolumnDefs: any = [];
  ispagination: boolean = false;
  ispagination2: boolean = false;
  ispagination1: boolean = false;
  crdOne: boolean = true; crdTwo: boolean = false; crdThree: boolean = false; crdFour: boolean = false;
  urbn_in: number;
  chartsData: any;
  userDtls: any;
  clap_in: any;
  ShowClap: boolean = false;
  dbrd_ky = 'urban';
  smryClmns: any[];
  // tslint:disable-next-line:no-shadowed-variable
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };


  onChange(e): void {
    // console.log('onChange: ',e);
    this.slctdDt = e;
    // console.log('slctdDt: ', this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    // console.log(this.curDate);
    this.get_totalDistanceCount(this.slctdDt);
    this.get_totalTripsCount(this.slctdDt);
    this.get_prmryTripsCount(this.slctdDt);
    this.get_scndryTripsCount(this.slctdDt);
    // this.get_lst7DaysTrpsDta(this.slctdDt);
    // this.get_lst7DaysDistanceDta(this.slctdDt);
    this.get_ctgrywisecounts(this.slctdDt);
    this.get_ulbWsedta(this.slctdDt);
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, 0);
    this.getbelowThirtyKmVehicles(this.slctdDt);
    this.getVehiclesOperatedcntsULBwise(this.slctdDt)
    this.getVehiclesOperatedcntsVndrULBwise(this.slctdDt);

  }


  ngOnInit() {
    this.curYr = moment(this.today).format('YYYY');
    // console.log('Current Year ******************************************* ',this.curYr);
    this.slctdDt = new Date();
    this.curTblDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.get_totalDistanceCount(this.slctdDt);
    this.get_totalTripsCount(this.slctdDt);
    this.get_prmryTripsCount(this.slctdDt);
    this.get_scndryTripsCount(this.slctdDt);
    // this.get_lst7DaysTrpsDta(this.slctdDt);
    // this.get_lst7DaysDistanceDta(this.slctdDt);
    this.get_ctgrywisecounts(this.slctdDt);
    this.get_ulbWsedta(this.slctdDt);
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, 0);
    this.getbelowThirtyKmVehicles(this.slctdDt);
    this.getVehiclesOperatedcntsULBwise(this.slctdDt)
    this.getVehiclesOperatedcntsVndrULBwise(this.slctdDt);

    // this.chart = am4core.create('tripsTrackedChart', am4charts.XYChart);




    // function create1Series(value, name) {
    //   let series = this.chart.series.push(new am4charts.ColumnSeries())
    //   series.dataFields.valueY = value
    //   series.dataFields.categoryX = 'c'
    //   series.name = name

    //   let bullet = series.bullets.push(new am4charts.LabelBullet())
    //   bullet.interactionsEnabled = false
    //   bullet.dy = 30;
    //   bullet.label.text = '{valueY}'
    //   bullet.label.fill = am4core.color('#ffffff')

    //   return series;
    // }
    // Add data
    this.getChrtsData(1);
    this.cardOne();
  }

  get_totalDistanceCount(funDt?): any {
    this.ttlDstncCnt = [];
    // this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      slctd_tnt: 0 //tnt_in: 0 #change
    }
    const rte = `dashboard/enterprise/get_ttlDstnce/${this.dbrd_ky}`; 
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        let res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.ttlDstncCnt = res['data'];
          console.log(this.ttlDstncCnt,"----------ttlDstncCnt-----------") //#change
        }
        // this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  get_today_Ulb_CoveredTripsCount(funDt?, slctTnt?): any {
    this.tdyCoveredTrips = [];
    // this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/ulb_tdy/${this.dbrd_ky}`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.tdyCoveredTrips = res['data'];
          console.log(this.tdyCoveredTrips)
        }
        // this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }

  get_totalTripsCount(funDt?): any {
    this.ttlTrpsCnt = [];
    // this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 0
    }
    let rte = `dashboard/enterprise/ttlTrips/${this.dbrd_ky}`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.ttlTrpsCnt = res['data'];
        // console.log(this.ttlTrpsCnt)
      }
      // this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }

  get_prmryTripsCount(funDt?): any {
    this.prmryTrpsCnt = [];
    // this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 0
    }
    let rte = `dashboard/enterprise/get_prmryTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.prmryTrpsCnt = res['data'];
        console.log(this.prmryTrpsCnt);
        this.shwzrwErr = false;
      }
      else {
        this.shwzrwErr = true;
      }
      // this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }

  get_scndryTripsCount(funDt?): any {
    this.scndryTrpsCnt = [];
    // this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 0
    }
    let rte = `dashboard/enterprise/get_scndryTrips`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.scndryTrpsCnt = res['data'];
        console.log(this.scndryTrpsCnt);
        this.shwScndryzrwErr = false;
      }
      else {
        this.shwScndryzrwErr = true;
      }
      // this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }


  get_ulbWsedta(funDt?): any {
    this.ulbTripsDta = [];
    // this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    // console.log(this.curDate);
    this.postslctdDt = {
      dt: this.curDate,
      tnt_in: 0
    }
    const rte = `dashboard/enterprise/dta_ulb/${this.dbrd_ky}`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        let res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.ulbTripsDta = res['data'];
          console.log(this.ulbTripsDta);
          if(this.ulbTripsDta.length<=8 && this.ulbTripsDta.length>1){
             this.ulblable = "Zone"
          }else{
            this.ulblable = "ULB Name"
          }
          this.ulbTripscolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false , summaryType: false},
            { headerName: 'District', alignment: 'left', width: 150, field: 'dstrct_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: this.ulblable, alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center', summaryType: 'sum' },
            { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center', summaryType: 'sum' },
            { headerName: 'Operated %', field: 'opper', alignment: 'center', summaryType: 'avg', format: { type: 'fixedPoint', precision: 2 } },
            { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Travelled Trips', alignment: 'center', field: 'trvld_trps', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Trips %', alignment: 'center', field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell', summaryType: 'avg', format: { type: 'fixedPoint', precision: 2 } },
            { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Running', field: 'running', alignment: 'center', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Route Deviated', field: 'rd_cnt', alignment: 'center', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', summaryType: 'sum' }

          ]
          this.smryClmns= [
           
            { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center', summaryType: 'sum' },
            { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center', summaryType: 'sum' },
            { headerName: 'Operated %', field: 'opper', alignment: 'center', summaryType: 'avg', format: { type: 'fixedPoint', precision: 2 } },
            { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Travelled Trips', alignment: 'center', field: 'trvld_trps', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Trips %', alignment: 'center', field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell', summaryType: 'avg', format: { type: 'fixedPoint', precision: 2 } },
            { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Running', field: 'running', alignment: 'center', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Route Deviated', field: 'rd_cnt', alignment: 'center', cellClass: 'pm-grid-number-cell', summaryType: 'sum' },
            { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', summaryType: 'sum' }
          ]
        }

        // this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }

  get_ctgrywisecounts(funDt?): any {
    this.ctgryWseCnts = [];
    // this.showLdr = true;
    this.slctdDt = funDt;
    // console.log(this.slctdDt);
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curTmspDate = moment(this.slctdDt).format('YYYY-MM-DD HH:mm:ss');
    // console.log(this.curDate,this.curTmspDate);
    this.postslctdDt = {
      dt: this.curDate,
      dtsp: this.curTmspDate,
      tnt_in: 0
    }
    let rte = `dashboard/enterprise/get_ctgryWiseCnts`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        let res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.ctgryWseCnts = res['data'];
          // console.log(this.ctgryWseCnts);
        }
        if (res_data.length > 10) {
          this.ispagination = true;
        }
        // this.showLdr = false;
      }

    }, (err) => {
      // console.log(err);
    });
  }
  gotoUlb(event) {
    console.log(event.row.data);
    this.TransfereService.setLoclData('ulbDta', event.row.data)
    this.route.navigate([`internal/dashboard/ulb-dashboard`]);
  }
  onCellPrepared(colDef, e) {

    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'ULB Name') {
      e.cellElement.style.color = '#ff0000';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
      e.cellElement.style.background = 'rgba(243, 191, 176, 0.2784313725490196)';
      e.cellElement.style.backgroundClip = 'content-box';
      e.cellElement.style.cursor = 'pointer';
    }

  }
  getbelowThirtyKmVehicles(funDt?) {
    this.slctdDt = funDt;
    // this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    let rte = `dashboard/enterprise/get_below_ThirtyKm_Vehicles/${this.curDate}`;
    this.apiServ.get(rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.ThrtyKmbelowVehcles = res['data'];
        this.ThrtyKmbelowVehclescolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
          { headerName: 'ULB Name', alignment: 'left', width: 160, field: 'tnt_nm' },
          { headerName: 'Travelled Below 30 kms', field: 'les_cnt', width: 120, alignment: 'center' },
        ]
      }
      // this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  getVehiclesOperatedcntsULBwise(funDt?) {
    this.slctdDt = funDt;
    // this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');

    this.postslctdDt = {
      dt: this.curDate,
      slctd_tnt: 0
    }
    let rte = `dashboard/enterprise/get_ulbwise_operated_Vehicles`;
    this.apiServ.post(this.postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.ulbwseopratedTripsTble = res['data'];
          let counter = 0;
          this.ulbwseopratedTripsTble.filter((k) => {
            k['sno'] = ++counter;
          });
          this.ulbwseopratedTripsTblecolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
            { headerName: 'ULB Name', alignment: 'left', width: 180, field: 'tnt_nm' },
            { headerName: 'Total Vehicles', field: 'ttl', width: 180, alignment: 'center' },
            { headerName: 'Operated Vehicles', field: 'operated', width: 180, alignment: 'center' },
            { headerName: 'Non Operated Vehicles', field: 'notoperated', width: 180, alignment: 'center' },
            { headerName: 'Operated%', field: 'opper', width: 180, alignment: 'center' },
          ]
        }
        if (res_data.length > 10) {
          this.ispagination1 = true;
        }
        // this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  getVehiclesOperatedcntsVndrULBwise(funDt?) {
    this.slctdDt = funDt;
    // this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    let rte = `dashboard/enterprise/get_vndrwise_operated_Vehicles/${this.curDate}/${this.dbrd_ky}`;
    this.apiServ.get(rte).subscribe((res) => {
      let res_data = res['data'];
      if (res_data && res_data.length > 0) {
        this.ulbVndrwseopratedTripsTble = res['data'];
        // let counter = 0;
        //   this.ulbVndrwseopratedTripsTble.filter((k) => {
        //     k['sno'] = ++counter;
        //   });
        this.ulbVndrwseopratedTripsTblecolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
          { headerName: 'Zone', alignment: 'left', width: 90, field: 'zone_nm' },
          { headerName: 'Vendor Name', alignment: 'left', width: 140, field: 'vndr_nm' },
          // { headerName: 'ULB Name', alignment: 'left', width: 200, field: 'tnt_nm' },
          { headerName: 'No of Locations', alignment: 'center', field: 'ulb_ct' },
          { headerName: 'Total Vehicles', field: 'ttl', alignment: 'center' },
          { headerName: 'Online Vehicles', field: 'online_ct', alignment: 'center' },
          { headerName: 'Offline Vehicles', field: 'offline_ct', alignment: 'center' },
          { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
          { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
          { headerName: 'Operated %', field: 'op_prct', alignment: 'center' },
        ]
      }

      // this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  cardOne() {
    this.crdOne = true; this.crdTwo = false; this.crdThree = false; this.crdFour = false;
    this.urbn_in = 1;
    this.getChrtsData(1);
  }
  cardTwo() {
    this.crdOne = false; this.crdTwo = true; this.crdThree = false; this.crdFour = false;
    this.urbn_in = 1;
    this.getChrtsData(1);

  }
  cardThree() {
    this.crdOne = false; this.crdTwo = false; this.crdThree = true; this.crdFour = false;
    this.urbn_in = 1;
    this.getChrtsData(1);

  }
  cardFour() {
    this.crdOne = false; this.crdTwo = false; this.crdThree = false; this.crdFour = true;
    this.urbn_in = 1;
    this.getChrtsData(1);

  }

  getChrtsData(u_in) {
    this.showLdr = true;
    let rte = `dashboard/enterprise/state/ulb-charts-data/${u_in}/${this.dbrd_ky}`;
    this.apiServ.get(rte).subscribe((res) => {
      let res_data = res['data'];
      this.chartsData = res['data'];
      this.showLdr = false;
      if (this.crdOne == true) {
        let chart1 = am4core.create('urbanVehiclesChart', am4charts.XYChart);
        chart1.data = this.chartsData;

        let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis1.dataFields.category = 'tnt_nm';
        categoryAxis1.renderer.grid.template.location = 0;
        categoryAxis1.renderer.minGridDistance = 10;
        categoryAxis1.renderer.labels.template.rotation = -90;
        categoryAxis1.renderer.labels.template.fontSize = 12;
        categoryAxis1.renderer.labels.template.dx = -23;

        categoryAxis1.title.text = ` ----- ULBs  ----- `;

        let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
        valueAxis1.title.text = '-- Vehicles -- ';

        // Create series
        function createSeries(field, name, clr) {

          // Set up series
          let series1 = chart1.series.push(new am4charts.ColumnSeries());
          series1.name = name;
          series1.dataFields.valueY = field;
          series1.dataFields.categoryX = 'tnt_nm';
          series1.sequencedInterpolation = true;
          series1.fill = am4core.color(clr);

          // Make it stacked
          series1.stacked = true;

          // Configure columns
          series1.columns.template.width = am4core.percent(60);
          series1.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series1.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;

          return series1;

        }
        chart1.legend = new am4charts.Legend();
        createSeries('actve_urbn_ttl', 'Active Vehicles', '#185aad');
        createSeries('inact_urban_ttl', 'Spare Vehicles', '#ed844e');

      }
      if (this.crdTwo == true) {
        let chart2 = am4core.create('urbanoperStatusChart', am4charts.XYChart);
        chart2.data = this.chartsData;

        let categoryAxis2 = chart2.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis2.dataFields.category = 'tnt_nm';
        categoryAxis2.renderer.grid.template.location = 0;
        categoryAxis2.renderer.minGridDistance = 10;
        categoryAxis2.renderer.labels.template.rotation = -90;
        categoryAxis2.renderer.labels.template.fontSize = 12;
        categoryAxis2.renderer.labels.template.dx = -23;

        categoryAxis2.title.text = ` ----- ULBs  ----- `;

        let valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.title.text = '-- Vehicles -- ';

        // Create series
        function createSeries(field, name, clr) {

          // Set up series
          let series2 = chart2.series.push(new am4charts.ColumnSeries());
          series2.name = name;
          series2.dataFields.valueY = field;
          series2.dataFields.categoryX = 'tnt_nm';
          series2.sequencedInterpolation = true;
          series2.fill = am4core.color(clr);

          // Make it stacked
          series2.stacked = true;

          // Configure columns
          series2.columns.template.width = am4core.percent(60);
          series2.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series2.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          // .fontcolor('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;

          return series2;

        }
        chart2.legend = new am4charts.Legend();
        createSeries('u_operated', 'Operated Vehicles', '#fddd33');
        createSeries('u_notoperated', 'Not Operated Vehicles', '#2751bf');
      }
      if (this.crdThree == true) {
        let chart3 = am4core.create('urbantrvlVhcleChart', am4charts.XYChart);
        chart3.data = this.chartsData;

        let categoryAxis3 = chart3.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis3.dataFields.category = 'tnt_nm';
        categoryAxis3.renderer.grid.template.location = 0;
        categoryAxis3.renderer.minGridDistance = 10;
        categoryAxis3.renderer.labels.template.rotation = -90;
        categoryAxis3.renderer.labels.template.fontSize = 12;
        categoryAxis3.renderer.labels.template.dx = -23;

        categoryAxis3.title.text = ` ----- ULBs  ----- `;

        let valueAxis3 = chart3.yAxes.push(new am4charts.ValueAxis());
        valueAxis3.title.text = '-- Vehicles -- ';

        // Create series
        function createSeries(field, name, clr) {

          // Set up series
          let series3 = chart3.series.push(new am4charts.ColumnSeries());
          series3.name = name;
          series3.dataFields.valueY = field;
          series3.dataFields.categoryX = 'tnt_nm';
          series3.sequencedInterpolation = true;
          series3.fill = am4core.color(clr);

          // Make it stacked
          series3.stacked = true;

          // Configure columns
          series3.columns.template.width = am4core.percent(60);
          series3.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series3.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;

          return series3;

        }
        chart3.legend = new am4charts.Legend();
        createSeries('urbn_tdydst_abv30', '>= 30 kms', '#29989d');
        createSeries('urbn_tdydst_blw30', '< 30 Kms', '#d9809c');

      }
      if (this.crdFour == true) {
        let chart4 = am4core.create('urbantripCvrdChart', am4charts.XYChart);
        chart4.data = this.chartsData;

        let categoryAxis4 = chart4.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis4.dataFields.category = 'tnt_nm';
        categoryAxis4.renderer.grid.template.location = 0;
        categoryAxis4.renderer.minGridDistance = 10;
        categoryAxis4.renderer.labels.template.rotation = -90;
        categoryAxis4.renderer.labels.template.fontSize = 12;
        categoryAxis4.renderer.labels.template.dx = -23;

        categoryAxis4.title.text = ` ----- ULBs  ----- `;

        let valueAxis4 = chart4.yAxes.push(new am4charts.ValueAxis());
        valueAxis4.title.text = '-- Vehicles -- ';

        // Create series
        function createSeries(field, name,clr) {

          // Set up series
          let series4 = chart4.series.push(new am4charts.ColumnSeries());
          series4.name = name;
          series4.dataFields.valueY = field;
          series4.dataFields.categoryX = 'tnt_nm';
          series4.sequencedInterpolation = true;
          series4.fill = am4core.color(clr);

          // Make it stacked
          series4.stacked = true;

          // Configure columns
          series4.columns.template.width = am4core.percent(60);
          series4.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
          // Add label
          let labelBullet = series4.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = '[bold]{valueY}';
          labelBullet.label.fill = am4core.color('#ffff');
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;

          return series4;

        }
        // chart4.legend = new am4charts.Legend();
        createSeries('trvl_prcntg', 'Trips Travelled %', '#29989d');
           
        // createSeries('urbn_tdydst_blw30', '< 30 Kms');

      }

    }, (err) => {
      // console.log(err);
    });
  }

  onExporting1(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ULB Trips Data.xlsx');
      });
    });
  }

  onExporting2(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ulb wise operated Trips.xlsx');
      });
    });
  }

}

