import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class ApiService {

  USER_PERMISSIONS = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };

  constructor(private http: HttpClient) { }

  // ----------------------------------
  create(postdata, rte) {
    return this.http.post(`/${rte}`, { data: postdata });
  }
  post(postdata, rte) {
    return this.http.post(`/${rte}`, postdata);
  }
  get(rte) {
    return this.http.get(`/${rte}`);
  }

  update(postdata, rte) {
    return this.http.put(`/${rte}`, { data: postdata });
  }

  put(postdata, rte) {
    return this.http.put(`/${rte}`, { data: postdata });
  }

  delete(rte) {
    return this.http.delete(`/${rte}`);
  }

}
