<!-- <nz-layout> -->
<nz-layout>
  <nz-content>
    <div class="row hdrBar">
      <div class="col-lg-12 col-md-12 mg-a">
        <h1>Single Trip Schedule Interface</h1>
      </div>
    </div>
    <div class="ds-card grid frmSec">
      <form nz-form [formGroup]="topfltrForm">
        <div class="row">

          <div class="col-lg-2 col-md-2" *ngIf="!showLbl">
            <nz-form-label>Select {{lblNm}}</nz-form-label>
            <nz-form-item>

              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please select ULB" formControlName="tnt_id"
                  (ngModelChange)="onTenantChange($event)" [(ngModel)]="tnt_id">
                  <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-2 col-md-2" *ngIf="showLbl">
            <nz-form-label>{{lblNm}}</nz-form-label>
            <p class="slctlbl dsblTxt">{{tntLst[0].tnt_nm}}</p>
          </div>
          <div class="col-lg-2 col-md-2">
            <nz-form-label>Select Trip Type</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select Trip Type"
                  formControlName="trp_typ_id" (ngModelChange)="onTrpTypSelected($event)" [(ngModel)]="trp_typ_id">
                  <nz-option *ngFor="let a of tripTypeLst" [nzValue]="a.trp_typ_id" [nzLabel]="a.trp_typ_nm">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-2 col-md-2" *ngIf="showSchvlym">
            <nz-form-label>Select Sachivalayam</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select sachivalayam"
                  formControlName="slctedSvm_id" (ngModelChange)="onsvmgetSelected($event)" [(ngModel)]="svm_id">
                  <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                  <nz-option *ngFor="let a of svmDrpDwnList" [nzValue]="a.svm_id" [nzLabel]="a.svm_nm">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-2 col-md-2">
            <nz-form-label>Select Vehicle</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select Vehicle" formControlName="asrt_id"
                  (ngModelChange)="onVehicleSelected($event)" [(ngModel)]="asrt_id">
                  <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                  <nz-option *ngFor="let a of vehiclesLst" [nzValue]="a.asrt_id" [nzLabel]="a.asrt_nm">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
         
          <div class="col-lg-2 col-md-2" *ngIf="!showSchvlym">
            <nz-form-label>Select carry material</nz-form-label>
            <nz-form-item>

              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select material Type"
                  formControlName="mtrl_typ_id" (ngModelChange)="onmtrlTypeChnge($event)" [(ngModel)]="mtrl_typ_id">
                  <!-- <nz-option *ngFor="let typ of tripTypeLst" [nzValue]="typ.trp_typ_id" [nzLabel]="typ.trp_typ_nm">
                    </nz-option> -->
                  <nz-option [nzLabel]="'ALL'" [nzValue]="0"></nz-option>
                  <nz-option [nzLabel]="'Dry Waste'" [nzValue]="1"></nz-option>
                  <nz-option [nzLabel]="'Wet Waste'" [nzValue]="2"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="row">
              <div class="col-lg-5 col-md-5">
                <button nz-button nzType="primary" class="getTrips" (click)="resetAllForms();getclstrWseRoutLst();">
                  Get Trips</button>
              </div>
              <div class="col-lg-7 col-md-7" *ngIf="shwRtlst == true || showNoDtls == true">
                <button nz-button nzType="primary" class="getTrips" (click)="addNewTrp();resetAllForms()">
                  Add New Trip</button>
              </div>
            </div>
          </div>


          <!-- <div class="col-lg-3 col-md-3 mg-a" *ngIf="shwAddBtn==true">
              <button nz-button nzType="primary" class="sbmtBtm" (click)="addNewTrp();resetAllForms()"
                style="width: 70%;">
                ADD NEW TRIP</button>
            </div>
            <div class="col-lg-3 col-md-3 mg-a" *ngIf="shwAddBtn!=true">
              <button nz-button nzType="primary" class="sbmtBtm" (click)="closeTrp();resetAllForms()"
                style="width: 70%;">
                Show Map</button>
            </div> -->
        </div>
        <!-- <div class="row"> <span style="font-weight:600;color:green;">&nbsp;* &nbsp;</span> <span
              style="font-weight:bold">Secondary trips</span> &nbsp;are the trips in which garbage is carried to
            DumpYard
          </div> -->
      </form>
      <!-- <div class="row bg-wp" *ngIf="shwRtlst == true && clstRtsLst.length ">
          <div class="col-md-12 tblscroll">
            <table>
              <thead>
                <tr>
                  <th>Trip No</th>
                  <th>Route Name</th>
                  <th>Schedule Start Time</th>
                  <th>Schedule End Time</th>
                  <th colspan="2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of clstRtsLst;">
                  <td>{{s.trp_sqnc_nu}}</td>
                  <td>{{s.rte_nm}}</td>
                  <td>{{s.schdle_strt_tm}}</td>
                  <td>{{s.schdle_end_tm}}</td>
                  <td>
                    <div (click)="getrtList(s);opensideBar('showRteMap',s)" *ngIf="s.shwRtLbl != 'Hide Route'">
                      <p style="margin: 0px; cursor: pointer;"><i nz-icon nzType="rise" nzTheme="outline" style="font-size: 20px;
                      font-weight: 500;
                      color: #009688;"></i></p>
                      <p style="margin: 0px; cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    color: #009688;
                    line-height: 10px;
                    letter-spacing: .4px;">Show Route</p>
                    </div>
                    <div (click)="getrtList(s)" *ngIf="s.shwRtLbl == 'Hide Route'">
                      <p style="margin: 0px; cursor: pointer;"><i nz-icon nzType="rise" nzTheme="outline" style="font-size: 20px;
                      font-weight: 500;
                      color: #757575;"></i></p>
                      <p style="margin: 0px; cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    color: #757575;
                    line-height: 10px;
                    letter-spacing: .4px;">Hide Route</p>
                    </div>
                  </td>
                  <td>
                    <div (click)="showConfirm(s)">
                      <p style="margin: 0px; cursor: pointer;"><i nz-icon nzType="delete" nzTheme="outline" style="font-size: 20px;
                      font-weight: 500;
                      color: #ff9800;"></i></p>
                      <p style="margin: 0px; cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    color: #ff9800;
                    line-height: 10px;
                    letter-spacing: .4px;">Delete</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div> -->


    </div>

    <!-- <div class="row bg-wp" *ngIf="shwRtlst == false && !clstRtsLst.length"> -->
    <div class="row bg-wp" *ngIf="showNoDtls == true">
      <div class="col-lg-9 col-md-9 ">
        <h6 class="text-center">No routes are added for selected vehicle(s)</h6>
      </div>
      <!-- <div class="col-lg-3 col-md-3"> <button nz-button nzType="primary" class="sbmtBtm"
            (click)="addNewTrp();resetAllForms()" style="width: 70%;">
            ADD NEW TRIP</button> </div> -->
    </div>
    <!-- <div class="bg-wp" *ngIf="showMap == true && shwRtlst == true && clstRtsLst.length>0"> -->
    <div class="bg-wp" *ngIf="shwRtlst == true">
      <div class="row" style="width: 100%;height: 600px;overflow: hidden;margin: 0px;">
        <div class="col-lg-4 col-md-4 htscrl">
          <div *ngFor="let s of clstRtsLst;">

            <div class="serchCard" [ngClass]="s.isSelected == true ? 'card_Selected' : 'card_not_selected'">
              <div class="row hdrcrd" (click)="selectCrd(s);">
                <div class="col-md-9" style="padding: 0px;">
                  <p class="serchCardLeftTxt srchCrdHdr">{{s.asrt_nm === null ? 'No vehicle Assigned to Trip' :
                    s.asrt_nm}}
                    <span *ngIf="s.asrt_ctgry_nm">( {{s.asrt_ctgry_nm}} )</span>
                  </p>
                </div>
                <div class="col-md-3" style="padding: 0px;">
                  <!-- <p class="serchCardLeftTxt srchCrdHdr" style=" font-size: 13px;
                    font-weight: 500;
                    line-height: 19px;
                    color: #3f51b5;">{{s.asrt_ctgry_nm}}</p> -->
                  <div class="row">
                    <div class="col-md-3 brdr_both">
                      <p class="serchCardLeftTxt"
                        style="font-size: 14px; font-weight:bold;text-align: center; margin:auto;color: #000000;cursor: pointer;"
                        (click)="getRouteOnMap(s)">
                        <a title="Schedule Route" style="color:black"><i nz-icon nzType="rise" nzTheme="outline"
                            class="crdIcns"></i></a>
                      </p>
                    </div>
                    <div class="col-md-3 brdr_rgt">
                      <p class="serchCardLeftTxt"
                        style="font-size: 14px;text-align: center; margin: auto;color: #000000;cursor: pointer;"
                        (click)="getrtList(s)" *ngIf="s.shwRtLbl != 'Hide Route'">
                        <a title="Edit Schedule" style="color:black"><i nz-icon nzType="edit" nzTheme="fill"
                            class="crdIcns"></i></a>
                      </p>
                      <p class="serchCardLeftTxt"
                        style="font-size: 14px;text-align: center; margin: auto;color: #000000;cursor: pointer;"
                        (click)="getrtList(s)" *ngIf="s.shwRtLbl == 'Hide Route'">
                        <i nz-icon nzType="minus-circle" nzTheme="fill" class="crdIcns"></i>
                      </p>
                    </div>
                    <div class="col-md-3 brdr_rgt">
                      <p class="serchCardLeftTxt"
                        style="font-size: 14px;text-align: center; margin: auto;color: #000000;cursor: pointer;"
                        (click)="showConfirm(s)">
                        <a title="Delete" style="color:black"><i nz-icon nzType="delete" nzTheme="fill"
                            class="crdIcns"></i></a>
                      </p>
                    </div>
                    <div class="col-md-3">
                      <p class="serchCardLeftTxt"
                        style="font-size: 14px;text-align: center; margin: auto;color: #000000;cursor: pointer;"
                        (click)="shwVhcleLst(s);">
                        <a title="Assign Vehicle" style="color:black"><i nz-icon nzType="car" nzTheme="fill"
                            class="crdIcns"></i></a>
                      </p>
                    </div>
                  </div>

                </div>
              </div>
              <div class="row srchcardcntnt">
                <div class="col-lg-12 col-md-12">
                  <!-- <div class="row serchCardPdng" style="padding-top:5px ;"> -->
                  <!-- <div class="col-lg-7 col-md-7 col"> -->
                  <!-- <p class="serchCardLeftTxt srchCrdHdr">{{s.asrt_nm}}</p> -->
                  <!-- <p class="serchCardLeftTxt srchCrdHdr" style="    font-size: 12px;
                        font-weight: 500;
                        line-height: 8px;
                        color: #3f51b5;">{{s.asrt_ctgry_nm}}</p> -->
                  <!-- </div> -->
                  <!-- <div class="col-lg-5 col-md-5 col">
                        <p class="serchCardLeftTxt srchCrdHdr" style="    font-size: 12px;
                        font-weight: 500;
                        line-height: 8px;
                        color: #3f51b5;">{{s.asrt_ctgry_nm}}</p>
                      </div> -->
                  <!-- </div> -->
                  <div class="row serchCardPdng" style="padding-top:4px ;">
                    <div class="col-lg-3 col-md-3 col">
                      <p class="serchCardLeftTxt">Trip No</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col">
                      <p class="serchCardRightTxt" style="text-align: center;">:</p>
                    </div>
                    <div class="col-lg-8 col-md-8 col">
                      <p class="serchCardRightTxt">{{s.trp_sqnc_nu}}</p>
                    </div>
                  </div>
                  <div class="row serchCardPdng" style="padding-top:4px ;">
                    <div class="col-lg-3 col-md-3 col">
                      <p class="serchCardLeftTxt">Driver Name</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col">
                      <p class="serchCardRightTxt" style="text-align: center;">:</p>
                    </div>
                    <div class="col-lg-7 col-md-7 col" *ngIf="!s.shwdrvrEdt">
                      <p class="serchCardRightTxt">{{s.crw_nm}}</p>
                    </div>
                    <div class="col-lg-7 col-md-7 col" *ngIf="s.shwdrvrEdt">
                      <p class="serchCardRightTxt">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Driver Name"
                          (ngModelChange)="onselectDriver($event,s)" [(ngModel)]="selectedDriver" style="width:100%;">
                          <nz-option *ngFor="let crw of driversLst" [nzValue]="crw.crw_id" [nzLabel]="crw.crw_nm">
                          </nz-option>
                        </nz-select>
                      </p>
                    </div>
                    <div class="col-lg-1 col-md-1 col">
                      <p class="serchCardRightTxt" (click)="onDrvrEdt(s);"><i nz-icon nzType="edit"
                          nzTheme="outline"></i></p>
                    </div>
                  </div>
                  <div class="row serchCardPdng" style="padding-top:4px ;">
                    <div class="col-lg-3 col-md-3 col">
                      <p class="serchCardLeftTxt">From Location</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col">
                      <p class="serchCardRightTxt" style="text-align: center;">:</p>
                    </div>
                    <div class="col-lg-8 col-md-8 col">
                      <p class="serchCardRightTxt">{{s.strt_fnce_nm}}</p>
                    </div>
                  </div>
                  <div class="row serchCardPdng" style="padding-top:4px ;">
                    <div class="col-lg-3 col-md-3 col">
                      <p class="serchCardLeftTxt">To Location</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col">
                      <p class="serchCardRightTxt" style="text-align: center;">:</p>
                    </div>
                    <div class="col-lg-8 col-md-8 col">
                      <p class="serchCardRightTxt">{{s.end_fnce_nm}}</p>
                    </div>
                  </div>
                  <div class="row serchCardPdng" style="padding-top:4px ;">
                    <div class="col-lg-3 col-md-3 col">
                      <p class="serchCardLeftTxt">Trip Type</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col">
                      <p class="serchCardRightTxt" style="text-align: center;">:</p>
                    </div>
                    <div class="col-lg-8 col-md-8 col">
                      <p class="serchCardRightTxt">{{s.trp_typ_nm}}</p>
                    </div>
                  </div>
                  <div>
                    <div class="row serchCardPdng" style="padding-top:10px ;">
                      <div class="col-lg-3 col-md-3 col">
                        <p class="serchCardLeftTxt">Schedule Start</p>
                      </div>
                      <div class="col-lg-1 col-md-1 col">
                        <p class="serchCardRightTxt" style="text-align: center;">:</p>
                      </div>
                      <div class="col-lg-7 col-md-7 col" *ngIf="!s.shwStrtTmEdt">
                        <p class="serchCardRightTxt">{{s.schdle_strt_tm}}</p>
                      </div>
                      <div class="col-lg-7 col-md-7 col" *ngIf="s.shwStrtTmEdt">
                        <p class="serchCardRightTxt">
                          <nz-time-picker nzShowTime (ngModelChange)="onselectStrtTime($event,s)"
                            [(ngModel)]="slctdstrt_tm" [nzUse12Hours]="true" [nzFormat]="timeFormat">
                          </nz-time-picker>
                        </p>
                      </div>
                      <div class="col-lg-1 col-md-1 col">
                        <p class="serchCardRightTxt" (click)="onStrtTmEdt(s);"><i nz-icon nzType="edit"
                            nzTheme="outline"></i></p>
                      </div>
                    </div>
                    <div class="row serchCardPdng" style="padding-top:10px;">
                      <div class="col-lg-3 col-md-3 col">
                        <p class="serchCardLeftTxt">Schedule End</p>
                      </div>
                      <div class="col-lg-1 col-md-1 col">
                        <p class="serchCardRightTxt" style="text-align: center;">:</p>
                      </div>
                      <div class="col-lg-8 col-md-8 col">
                        <p class="serchCardRightTxt">{{s.schdle_end_tm}}</p>
                      </div>
                    </div>
                    <div class="row serchCardPdng" style="padding-top:4px ;" *ngIf="s.svm_nms && showSchvlym">
                      <div class="col-lg-3 col-md-3 col">
                        <p class="serchCardLeftTxt">Sachivalayam</p>
                      </div>
                      <div class="col-lg-1 col-md-1 col">
                        <p class="serchCardRightTxt" style="text-align: center;">:</p>
                      </div>
                      <div class="col-lg-8 col-md-8 col">
                        <p class="serchCardRightTxt">{{s.svm_nms}}</p>
                      </div>
                    </div>
                    <!-- <div class="row serchCardPdng" style="padding-top:4px ;" *ngIf="s.cntxtAreas">
                      <div class="col-lg-3 col-md-3 col">
                        <p class="serchCardLeftTxt">contexts</p>
                      </div>
                      <div class="col-lg-1 col-md-1 col">
                        <p class="serchCardRightTxt" style="text-align: center;">:</p>
                      </div>
                      <div class="col-lg-8 col-md-8 col">
                        <p class="serchCardRightTxt">{{s.cntxtAreas}}</p>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- <div class="col-lg-1 col-md-1"
                    style="margin: auto;padding: auto;cursor: pointer;border-left: rgb(209, 202, 202) dotted 1px;">
                    <p class="serchCardLeftTxt"
                      style=" font-size: 20px;font-weight:bold;text-align: center; margin:auto;color: #009688;"
                      (click)="getRouteOnMap(s)">
                      <i nz-icon nzType="rise" nzTheme="outline"></i>
                    </p>
                    <p class="serchCardLeftTxt" style=" font-size: 20px;text-align: center; margin: auto;color: #489600;"
                      (click)="getrtList(s)" *ngIf="s.shwRtLbl != 'Hide Route'">
                      <i nz-icon nzType="edit" nzTheme="fill"></i>
                    </p>
                    <p class="serchCardLeftTxt" style=" font-size: 20px;text-align: center; margin: auto;color: #489600;"
                      (click)="getrtList(s)" *ngIf="s.shwRtLbl == 'Hide Route'">
                      <i nz-icon nzType="minus-circle" nzTheme="fill"></i>
                    </p>
                    <p class="serchCardLeftTxt" style=" font-size: 20px;text-align: center; margin: auto;color: #964800;"
                      (click)="showConfirm(s)">
                      <i nz-icon nzType="delete" nzTheme="fill"></i>
                    </p>
                  </div> -->
              </div>


            </div>
            <div class="row serchCardveh" *ngIf="s.shwVhcle">
              <div class="col-lg-10 col-md-10">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select Vehicle"
                  (ngModelChange)="onselectVeh($event)" [(ngModel)]="selectedVhcle" style="width:100%;">
                  <nz-option *ngFor="let a of vehiclesLst2" [nzValue]="a.asrt_id" [nzLabel]="a.asrt_nm">
                  </nz-option>
                </nz-select>
              </div>
              <div class="col-lg-2 col-md-2">
                <i nz-icon nzType="close-circle" nzTheme="outline" (click)="closeSlct(s)"></i>
              </div>



            </div>
          </div>

        </div>
        <div class="col-lg-8 col-md-8 htscrl">
          <div id="map"></div>
        </div>
      </div>
    </div>
    <div [ngClass]="shwFnceFltr || isAddTrip || shwrtDtls ? 'bg-wp':'' ">
      <div class="frmSec seprtorcls" *ngIf="shwFnceFltr">
        <form nz-form [formGroup]="fnceFltrForm" class="pt-16">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="row" style="margin-bottom: 10px;">
                <div class="col-lg-10 col-md-10">
                  <nz-form-label style="text-align: center;">Select Trip Starting Location</nz-form-label>
                  <!-- <input type="text" placeholder="Select Source" nz-input formControlName="fnce_id"
                (ngModelChange)="frmfnceSelected($event)" [nzAutocomplete]="frmFnceauto" />
              <nz-autocomplete #frmFnceauto>
                <nz-auto-option *ngFor="let f of frmfnceFltrOptns" [nzValue]="f" [nzLabel]="f.fnce_nm">
                  {{f.fnce_nm}}</nz-auto-option>
              </nz-autocomplete> -->
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Trip Starting Location"
                    formControlName="fnce_id" (ngModelChange)="frmfnceSelected($event)" style="width: 100%;">
                    <nz-option *ngFor="let f of frmfnceFltrOptns" [nzValue]="f" [nzLabel]="f.fnce_nm">
                    </nz-option>
                  </nz-select>
                  <div>{{frm_fnce_grp_nm}}</div>
                </div>
                <!-- <div class="col-lg-2 col-md-2 mg-a" (click)="opensideBar('addFncePanel','')">
                    <i nz-icon nzType="plus-square" nzTheme="outline" class="plsBtn"></i>
                  </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="row" style="margin-bottom: 10px;">
                <div class="col-lg-10 col-md-10">
                  <nz-form-label style="text-align: center;">Select Trip Ending Location</nz-form-label>
                  <!-- <input type="text" placeholder="Select Destination" nz-input formControlName="end_fnce_id"
                (ngModelChange)="endfnceSelected($event)" [nzAutocomplete]="toFnceauto" />
              <nz-autocomplete #toFnceauto>
                <nz-auto-option *ngFor="let f of tofnceFltrOptns " [nzValue]="f" [nzLabel]="f.fnce_nm">
                  {{f.fnce_nm}}</nz-auto-option>
              </nz-autocomplete> -->
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Trip Ending Location"
                    formControlName="end_fnce_id" (ngModelChange)="endfnceSelected($event)" style="width: 100%;">
                    <nz-option *ngFor="let f of tofnceFltrOptns" [nzValue]="f" [nzLabel]="f.fnce_nm">
                    </nz-option>
                  </nz-select>
                  <div>{{end_fnce_grp_nm}}</div>
                </div>
                <!-- <div class="col-lg-2 col-md-2 mg-a" (click)="opensideBar('addFncePanel','')">
                    <i nz-icon nzType="plus-square" nzTheme="outline" class="plsBtn"></i>
                  </div> -->
              </div>
            </div>
            <!-- <div class="col-lg-4 col-md-4" *ngIf="showSchvlym">
              <nz-form-label style="text-align: center;">Select Sachivalayam</nz-form-label>
              <nz-select nzMode="multiple" nzShowSearch nzAllowClear nzPlaceHolder="Select Sachivalayam"
                formControlName="svm_id" (ngModelChange)="onsvmSelect($event)" style="width: 100%;">
                <nz-option *ngFor="let s of svmLst" [nzValue]="s.svm_id" [nzLabel]="s.svm_nm">
                </nz-option>
              </nz-select>
            </div> -->
          </div>
          <div class="row">
            <a (click)="opensideBar('addFncePanel','')">Click here</a> &nbsp;to add loctions if your prefered location
            is not there in locations list.
          </div>
          <div class="row">
            A location point is said to be covered by a vehicle when the vehicle stays around the location point
            within 40
            meters of radius
          </div>
        </form>
      </div>
      <div class="frmSec seprtorcls" *ngIf="isAddTrip">
        <form nz-form [formGroup]="fncTypForm">
          <div class="row text-center mat-caption">
            <div class="col-lg-3 col-md-3">
              <nz-form-label>Trip Number</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <input type="number" nz-input placeholder="Trip Number" formControlName="trp_nu" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-3 col-md-3">
              <nz-form-label>Select Driver</nz-form-label>
              <nz-form-item>

                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Driver Name" formControlName="crw_id">
                    <nz-option *ngFor="let crw of driversLst" [nzValue]="crw.crw_id" [nzLabel]="crw.crw_nm">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <!-- <div class="col-lg-4 col-md-4">
              <nz-form-label>Select Vehicle Registration Number</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Vehicle Number" formControlName="asrt_id">
                    <nz-option *ngFor="let asrt of assrtLst" [nzValue]="asrt.asrt_id" [nzLabel]="asrt.asrt_nm">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div> -->
          <!-- </div>
          <div class="row text-center mat-caption"> -->

            <!-- <div class="col-lg-4 col-md-4" *ngIf="trp_typ_id==1000003 || trp_typ_id==1000002"> -->
            <div class="col-lg-3 col-md-3">
              <nz-form-label>Trip Schedule Start Time</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-time-picker nzShowTime formControlName="strt_tm" [nzUse12Hours]="true" [nzFormat]="timeFormat">
                  </nz-time-picker>
                </nz-form-control>
              </nz-form-item>
              <!-- <span class="col-lg-4 col-md-4">{{fncTypForm.get('arvl_mrdn').value}}</span> -->
            </div>
            <div class="col-lg-3 col-md-3">
              <nz-form-label>Trip Schedule End Time</nz-form-label>
              <nz-form-item>
                <nz-form-control>
                  <nz-time-picker nzShowTime formControlName="end_tm" [nzUse12Hours]="true" [nzFormat]="timeFormat">
                  </nz-time-picker>
                </nz-form-control>
              </nz-form-item>
              <!-- <span class="col-lg-4 col-md-4">{{fncTypForm.get('dprtr_mrdn').value}}</span> -->
            </div>
            <!-- <div class="col-lg-4 col-md-4">
              <nz-form-label>Select context Details</nz-form-label>
              <nz-form-item>

                <nz-form-control>
                  <nz-select nzAllowClear nzShowSearch nzMode="multiple" (ngModelChange)="oncntxtChange($event)"
                    [(ngModel)]="cntxts" nzPlaceHolder="Select context Details" formControlName="cntx_id">
                    <nz-option-group *ngFor="let cn of cntxtDtls" [nzLabel]="cn.fnce_grp_nm" class="lbel-class">
                      <nz-option *ngFor="let fnc of cn.fnce_grp" [nzValue]="fnc.fnce_id" [nzLabel]="fnc.fnce_nm">
                      </nz-option>
                    </nz-option-group>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>
          <!-- <div class="row">
            To add more relevance you can add borders of ward/<div *ngIf="showSchvlym">sachivalayam/micropocket/</div>
            ULB to the map by selecting the
            Context Details.
          </div> -->
          <div class="row" *ngIf="!cntxtDtls.length > 0">
            Please contact&nbsp;<a>support@dreamstep.in </a> &nbsp; for displaying ward/<div *ngIf="showSchvlym">
              sachivalayam/micropocket/</div>/ULB
            borders in Playback and Livetrack
          </div>

        </form>
        <div *ngIf="fncTypForm.status == 'INVALID' && shwErrMsg" class="text-center">
          Please fill required feilds
        </div>
        <div *ngIf="fncTypForm.status == 'VALID' && !shwErrMsg && tperrFlag && index == undefined" class="text-center">
          Please enter valid timings and departure time should be Greater than arrival time
        </div>
        <!-- <p *ngIf="errMsg" class="mat-error text-center" style="font-size: 12px;">
          Trip with Number - {{fncTypForm.get('trp_nu').value}} already exist
        </p> -->
      </div>
      <div class="frmSec seprtorcls" *ngIf="shwrtDtls">
        <!-- <div class="row" class="subTblHdr text-center">
            <div class="col-lg-2 col-md-2">Sno</div>
            <div class="col-lg-2 col-md-2">Transfer Point Name</div>
            <div class="col-lg-2 col-md-2">Schedule Arrival Time</div>
            <div class="col-lg-2 col-md-2">Schedule Departure Time</div>
            <div class="col-lg-2 col-md-2">Actions</div>
          </div> -->
        <!-- <b>filteredOptions:</b> {{filteredOptions|json}}<br>
          <b>tofncefilteredOptions:</b> {{tofncefilteredOptions|json}}<br>
          <b>fncefilteredOptions: </b>{{fncefilteredOptions|json}} -->
        <!-- <nz-time-picker [(ngModel)]="r_time" [nzUse12Hours]="true" (ngModelChange)="r_log($event)" nzFormat="h:mm a"></nz-time-picker> -->
        <form nz-form [formGroup]="routeForm">
          <div class="row">
            <div formArrayName="routes" class="fulwdth">
              <div class="row" *ngFor="let r of routeForm.get('routes').controls; let i = index" [formGroupName]="i">
                <!-- <div [formGroupName]="i" > -->
                <div class="pt-12">{{i+1}} </div>
                <div class="col-lg-3 col-md-3">
                  <nz-form-label *ngIf="i==0">Trip Starting Location</nz-form-label>
                  <nz-form-label *ngIf="i>0 && i < routeForm.get('routes').controls.length-1">Trip Intermediate Stop
                  </nz-form-label>
                  <nz-form-label *ngIf="i == routeForm.get('routes').controls.length-1">Trip Ending Location
                  </nz-form-label>
                  <nz-form-item>
                    <nz-form-control>
                      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Stop" formControlName="fnce_id"
                        (ngModelChange)="fnceSelected($event,i)">
                        <nz-option *ngFor="let f of fnceFltrOptns" [nzValue]="f.fnce_id" [nzLabel]="f.fnce_nm">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <div *ngIf="i==0" style="margin-top: -18px;">{{frm_fnce_grp_nm}}</div>
                  <div *ngIf="i>0 && i < routeForm.get('routes').controls.length-1" style="margin-top: -18px;">
                    {{fnce_grp_nm}}
                  </div>
                  <div *ngIf="i == routeForm.get('routes').controls.length-1" style="margin-top: -18px;">
                    {{end_fnce_grp_nm}}
                  </div>
                </div>
                <div class="col-lg-3 col-md-3" *ngIf="trp_typ_id==1000003">
                  <nz-form-label>Schedule Arrival Time</nz-form-label>
                  <nz-form-item>
                    <nz-form-control>
                      <nz-time-picker nzShowTime formControlName="arvl_ts" [nzFormat]="timeFormat"
                        [(ngModel)]="arvl_ts">
                      </nz-time-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div class="col-lg-3 col-md-3" *ngIf="trp_typ_id==1000003 ">
                  <nz-form-label>Schedule Departure Time</nz-form-label>
                  <nz-form-item>
                    <nz-form-control>
                      <nz-time-picker nzShowTime formControlName="dprtr_ts" [nzFormat]="timeFormat"
                        [(ngModel)]="dprtr_ts">
                      </nz-time-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>


                <div class="col-lg-1 col-md-1" (click)="addRoute(i,routeForm.controls.routes);"
                  *ngIf="i < routeForm.get('routes').controls.length-1">
                  <i nz-icon nzType="plus-square" nzTheme="outline" class="plsBtn mg-tp"></i>
                </div>
                <div class="col-lg-1 col-md-1" (click)="deleteRoute(routeForm.controls.routes, i)"
                  *ngIf="i>0 && i < routeForm.get('routes').controls.length-1">
                  <i nz-icon nzType="delete" nzTheme="outline" class="delBtn mg-tp"></i>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          Click&nbsp; <i nz-icon nzType="plus-square" nzTheme="outline" class="plsBtn"></i>&nbsp; to&nbsp;<span
            style="font-weight:bold">Add</span> &nbsp; an intermediate location between Trip Start and End locations
        </div>
        <div class="row">
          Click&nbsp; <i nz-icon nzType="delete" nzTheme="outline" class="delBtn"></i> &nbsp; to&nbsp;<span
            style="font-weight:bold">Delete</span> &nbsp; an intermediate location between Trip Start and End
          locations
        </div>
        <div *ngIf="fncTypForm.status == 'VALID' && (shwErrMsg && routeForm.controls.routes.status == 'INVALID')"
          class="text-center">
          Please fill required feilds
        </div>
        <div class="input-row" nz-row>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn btnGrp">
            <button nz-button nzType="primary" class="sbmtbtn" (click)="savetripRoute()"
              *ngIf="isAddTrip==true && !shwUpdtbtn">Save</button>
            <button nz-button nzType="primary" class="sbmtbtn" (click)="updatetripRoute()"
              *ngIf="shwUpdtbtn">Update</button>

            <!-- </div>
            <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" class="text-right mat-caption pr-4"> -->
            <button nz-button nzType="primary" (click)="resetrouteForm()">Reset</button>

          </div>
        </div>

      </div>
    </div>
    <ds-loading *ngIf="shwLdr"></ds-loading>

  </nz-content>
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" *ngIf="opnSdebar == true" class="sdr">
    <div *ngIf="ShowAddFnce == true">
      <div class="row hdr">
        <div class="col-lg-10 col-md-10">
          <h1 class="sdrttl"> Add Location</h1>
        </div>
        <div class="col-lg-2 col-md-2 mg-a" (click)="closeSider();">
          <i nz-icon nzType="close-circle" nzTheme="outline" class="clseicn"></i>
        </div>
      </div>
      <app-location-add [tntId]="selctdtenant" *ngIf="tnt_id"></app-location-add>

      <!-- <form nz-form [formGroup]="addFncForm">
            <div class="row mg-b6">
              <nz-form-label class="lbltxt">Location name</nz-form-label>
              <div class="col-lg-12 col-md-12">
                <nz-form-item>
                  <nz-form-control>
                    <input type="text" nz-input placeholder="Location name" formControlName="fnce_nm" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div class="row mg-b6">
              <nz-form-label class="lbltxt">Select Location Group</nz-form-label>
              <div class="col-lg-12 col-md-12">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Location Group" formControlName="fnce_grp">
                      <nz-option *ngFor="let fg of tpGrpLst" [nzValue]="fg.fnce_grp_id" [nzLabel]="fg.fnce_grp_nm">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div class="row mg-b6">
              <nz-form-label class="lbltxt">Latitude</nz-form-label>
              <div class="col-lg-12 col-md-12">
                <nz-form-item>
                  <nz-form-control>
                    <input type="number" nz-input placeholder="Latitude" formControlName="lat" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div class="row mg-b6">
              <nz-form-label class="lbltxt">Longitude</nz-form-label>
              <div class="col-lg-12 col-md-12">
                <nz-form-item>
                  <nz-form-control>
                    <input type="number" nz-input placeholder="Longitude" formControlName="lng" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div class="row mg-b6">
              <nz-form-label class="lbltxt">Radius (m)</nz-form-label>
              <div class="col-lg-12 col-md-12">
                <nz-form-item>
                  <nz-form-control>
                    <input type="number" nz-input placeholder="Radius" formControlName="rds" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </form>
          <div *ngIf="addFncForm.status == 'INVALID' && shwErrMsg" class="text-center">
            Please fill required feilds
          </div>
          <div class="input-row" nz-row>
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn btnGrp"> <button nz-button
                nzType="primary" class=" submit w-30-p save-button-color" (click)="addNewFnc()">SAVE</button>
              <button nz-button nzType="primary" (click)="addFncForm.reset()" color=""
                class="w-30-p ml-24 reset-button-color">RESET</button>
            </div>
          </div> -->
    </div>
    <!-- <div class="col-lg-2 col-md-2 mg-a" (click)="closeSider();">
        <i nz-icon nzType="close-circle" nzTheme="outline" class="clseicn"></i>
      </div> -->
    <!-- <div *ngIf="showRte == true">
        <div id="map"> </div>
    </div> -->
  </nz-sider>
</nz-layout>

<!-- </nz-layout> -->