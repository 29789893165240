import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-vehicle-groups',
  templateUrl: './vehicle-groups.component.html',
  styleUrls: ['./vehicle-groups.component.scss']
})
export class VehicleGroupsComponent implements OnInit {

  addDvce: any;
  ctgry_lst: any;
  columnDefs: ({ headerName: string; field: string; alignment: string; cellClass: string; width: number; sortable: boolean; filter: boolean; } | { headerName: string; field: string; alignment: string; cellClass: string; width?: undefined; sortable?: undefined; filter?: undefined; } | { headerName: string; field: string; alignment: string; cellClass: string; sortable: boolean; filter: boolean; width?: undefined; })[];
  ctgryForm: boolean;
  sdbarHdr = 'add category'
  ctgry_nm: any;
  ctgry_cd: any;
  sdBrHdr: any;
  edt: boolean;
  ctgry_id: any;
  constructor(public apiSrv: AppsService, private modal: NzModalService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.getctgry()
  }

  getctgry() {
    this.apiSrv.get(`asset/tracking/vehicle/categories`).subscribe((res) => {
      if (res['status'] == 200) {
        this.ctgry_lst = res['data'];
        console.log(this.ctgry_lst);
        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 60, sortable: true, filter: false },
          { headerName: 'Vehicle Category Name', field: 'asrt_ctgry_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
          { headerName: 'Vehicle Category Code', field: 'asrt_ctgry_cd', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
        ];
      }

    }, (err) => {
      console.log(err)
    })

  }
  onToolbarPreparing(e) {
    console.log(e)
    e.toolbarOptions.items.unshift(

      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'plus',
          text: 'Add New Category',
          onClick: this.openSideBar.bind(this, 'new'),
          bindingOptions: {
            'disabled': 'isEmailButtonDisabled',
          }
        }
      }
    )

  }
  // addCtgry(){
  //  this.ctgryForm = true;
  // }
  close() {
    this.ctgryForm = false;
  }
  openSideBar(edt, val) {
    console.log(val)
    if (edt === 'new') {
      this.edt=false
      this.sdBrHdr = 'Add Category'
      this.ctgryForm = true
      this.sdbarHdr = 'Add New Vechile'
      this.ctgry_nm = null
      this.ctgry_cd = null
    }
    else if (edt === 'Edit') {
      this.edt=true
      this.sdBrHdr = 'Edit Category'
      this.ctgryForm = true;
      this.ctgry_nm = val.asrt_ctgry_nm
      this.ctgry_cd = val.asrt_ctgry_cd
      this.ctgry_id=val.asrt_ctgry_id
    }
  }
  addCategory() {
     var postData = {
      ctgry_nm:this.ctgry_nm,
      ctgry_cd:this.ctgry_cd,
      ctgry_id:this.ctgry_id
     }
    console.log(this.ctgry_nm, this.ctgry_cd)
    if(this.edt){
      this.apiSrv.post(postData, `asset/tracking/vehicle/edtCategorie`).subscribe((res) => {
        if (res['status'] == 200) {
          this.createMessage('Success', 'Vehicle Category successfully Edited');
        }
        this.getctgry()
        this.close()
      }, (err) => {
        console.log(err)
      })
    }
    else{
      this.apiSrv.post(postData, `asset/tracking/vehicle/insrtCategorie`).subscribe((res) => {
        if (res['status'] == 200) {
          this.createMessage('Success', 'Vehicle Category successfully Edited');
        }
        this.getctgry()
        this.close()
      }, (err) => {
        console.log(err)
      })
    }
  }

  showDeleteConfirm(data) {
    console.log(data)
    this.modal.confirm({
      nzTitle: 'Are you sure delete this Category?',
      nzContent: `<b style="color: red;">${data.asrt_ctgry_nm}</b>`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.delAsrt(data.asrt_ctgry_id),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }

  delAsrt(del) {
    console.log(del)
    let postData = {
      asrt_ctgry_id: del
    }
    this.apiSrv.post(postData, `asset/tracking/vehicle/delCategorie`).subscribe((res) => {
      if (res['status'] == 200) {
        this.createMessage('Success', 'Vehicle successfully Deleted');
      }
      this.getctgry()
    })
  }
}


